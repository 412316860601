import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {useEffect, FC, useRef} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {store, useAppDispatch, useAppSelector} from 'source/store';

import {Option, Select} from 'library/components/select';
import {Text} from 'library/components/text';

import {
  selectRoomShorts,
  selectMassEditModalFilters,
  selectOpenedRooms,
  selectRatesPrices,
  setMassEditModalFilters,
  initialState,
  selectEditRooms,
  setOpenedRooms,
  selectIsLoading,
} from 'slices/calendar/store';
import {
  getCurrentOptionByValue,
  buildGuestNumberOptions,
  buildRateOptions,
  buildRoomOptions,
  deleteRoomFromEdited,
} from 'slices/calendar/lib/helpers/rooms';
import {toValueOrEmptyArray} from 'source/utilities/array';
import {SelectCheckbox} from 'library/components/select-checkbox';
import {getGuestNumberFilterValues} from 'slices/calendar/lib/helpers/ui';
import {
  ALLOWED_OPENED_ROOMS_COUNT,
  DEFAULT_GUEST_NUMBER,
  DEFAULT_RATE_SELECT,
} from 'slices/calendar/lib/constants';
import {showWarnMessage} from 'source/utilities/exceptions/business';

const Wrapper = styled.div<{withoutRateFilters: boolean}>`
  margin-bottom: 10px;
  position: relative;
  padding: 0 22px;

  @media (max-width: 800px) {
    padding: 0 10px;
  }

  ${({withoutRateFilters}) =>
    withoutRateFilters &&
    css`
      padding: 0;

      @media (max-width: 800px) {
        padding: 0;
      }
    `}
`;

const Row = styled.div<{columns?: number}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  width: 100%;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
`;

const StyledSelect = styled(Select)`
  font-size: 14px;

  input {
    font-size: 14px;
    padding: 15px;
  }
`;

const StyledSelectCheckbox = styled(SelectCheckbox)`
  font-size: 14px;

  input {
    font-size: 14px;
    padding: 15px;
  }
`;

const RowTitle = styled(Text)<{isFirst?: boolean}>`
  min-width: max-content;
  display: flex;
  margin-right: ${({isFirst}) => (isFirst ? '0px' : '22px')};
`;

interface IProps {
  className?: string;
  withoutRateFilters?: boolean;
}

export const CalendarMassEditFilters: FC<IProps> = ({
  className,
  withoutRateFilters = false,
}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('calendar');
  const {roomId, rateId, guestNumber} = useAppSelector(
    selectMassEditModalFilters,
  );
  const loading = useAppSelector(selectIsLoading);
  const openedRooms = useAppSelector(selectOpenedRooms);
  const editedRooms = useAppSelector(selectEditRooms);
  const roomShorts = useAppSelector(selectRoomShorts);
  const ratesPrices = useAppSelector(selectRatesPrices);
  const currentRoomRatePrices = toValueOrEmptyArray(
    ratesPrices?.[roomId]?.ratesPrices,
  );
  const previousRoomId = useRef<number | null>(null);

  // Опции для селекта
  const roomOptions = buildRoomOptions(roomShorts);
  const rateOptions = buildRateOptions(currentRoomRatePrices);
  const guestNumberOptions = buildGuestNumberOptions(
    rateId,
    currentRoomRatePrices,
  );

  const handleChangeRoomOption = (option: Option) => {
    const openedRoomsArray = Object.keys(openedRooms);
    const isRoomAlreadyLoaded = openedRoomsArray.find(
      (openedRoomId) => Number(openedRoomId) === option.value,
    );

    // Если рум уже открытый, проверки и действия ниже не выполняем, а сразу выбираем рум
    if (isRoomAlreadyLoaded) {
      dispatch(
        setMassEditModalFilters({
          rateId,
          guestNumber,
          roomId: option.value,
        }),
      );

      previousRoomId.current = roomId;

      return;
    }

    const editedRoomsArray = Object.keys(editedRooms);
    // Лимит на кол-во вкладок - 3. Если во всех 3 есть изменения - просим сохранить
    if (editedRoomsArray.length === ALLOWED_OPENED_ROOMS_COUNT) {
      showWarnMessage(t('room_range.save_any_room_changes'));
      return;
    }

    // Если открытых номеров === макс, закрываем номер без изменений и открываем новый
    // Если нет - просто открываем новый номер
    if (openedRoomsArray.length === ALLOWED_OPENED_ROOMS_COUNT) {
      const notEditedRoomId = openedRoomsArray.find(
        (roomId) => !editedRoomsArray.includes(roomId),
      );

      if (!notEditedRoomId) {
        showWarnMessage(t('room_range.save_any_room_changes'));
        return;
      }

      // Удаляем из объекта закрываемый рум
      const newOpenedRooms = deleteRoomFromEdited(openedRooms, notEditedRoomId);

      dispatch(setOpenedRooms({...newOpenedRooms, [option.value]: true}));
    } else {
      dispatch(setOpenedRooms({...openedRooms, [option.value]: true}));
    }

    dispatch(
      setMassEditModalFilters({
        rateId,
        guestNumber,
        roomId: option.value,
      }),
    );
    previousRoomId.current = roomId;
  };

  const handleSetGuestNumber = (flag: boolean, option: Option) => {
    // Вытаскивает напрямую из стейта, потому что данная функция мемоизирована
    // Она будет возвращать все что находится выше, только то, что было при инициализации данной стрелочной функции
    const {guestNumber} = store.getState().calendar.massEditModalFilters;

    const clonedGuestNumber = guestNumber ? [...guestNumber] : [];

    const updatedGuestNumber = flag
      ? [...clonedGuestNumber, option.value]
      : clonedGuestNumber.filter((floor) => floor !== option.value);

    dispatch(
      setMassEditModalFilters({
        rateId,
        guestNumber: updatedGuestNumber,
        roomId,
      }),
    );
  };

  useEffect(() => {
    // При изменении комнаты выбирается первый тариф и guestNumber из списка, т.к. у комнат они разные
    // При первом открытии не должен срабатывать, поэтому используем предыдущую руму
    if (
      !loading &&
      previousRoomId.current &&
      previousRoomId.current !== roomId
    ) {
      dispatch(
        setMassEditModalFilters({
          roomId,
          guestNumber: initialState.massEditModalFilters.guestNumber,
          rateId: rateOptions[0]?.value || DEFAULT_RATE_SELECT,
        }),
      );
    }
  }, [roomId, loading]);

  useEffect(() => {
    previousRoomId.current = roomId;
  }, []);

  return (
    <Wrapper className={className} withoutRateFilters={withoutRateFilters}>
      <Row>
        <RowTitle size="XS">{t('room_range.filters.room')}</RowTitle>
        <StyledSelect
          value={getCurrentOptionByValue(roomOptions, roomId)}
          options={roomOptions}
          setValue={handleChangeRoomOption}
        />
      </Row>
      {!withoutRateFilters && (
        <>
          <Row>
            <RowTitle size="XS">{t('room_range.filters.rate')}</RowTitle>
            <StyledSelect
              value={
                getCurrentOptionByValue(rateOptions, rateId) ||
                DEFAULT_GUEST_NUMBER
              }
              options={rateOptions}
              setValue={(option) =>
                dispatch(
                  setMassEditModalFilters({
                    rateId: option.value,
                    guestNumber,
                    roomId,
                  }),
                )
              }
            />
          </Row>
          <Row>
            <RowTitle size="XS">
              {t('room_range.filters.guest_number')}
            </RowTitle>
            <StyledSelectCheckbox
              values={
                getGuestNumberFilterValues(guestNumber) || DEFAULT_RATE_SELECT
              }
              options={guestNumberOptions}
              setValue={handleSetGuestNumber}
            />
          </Row>
        </>
      )}
    </Wrapper>
  );
};

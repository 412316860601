import {GetServerSideProps} from 'next';
import {Translate} from 'next-translate';
import {andThen, assoc, call, path, pipeWith} from 'ramda';
import {BalancePageProperties} from 'slices/balance/lib/types';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {
  handleException,
  showException,
  showSuccessMessage,
} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {clientHeaders, serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {ApplicationProperties} from 'source/utilities/ui';
import {
  OrganizationsListResponse,
  WithdrawalsCreateRequest,
} from 'types/api-scheme';

export const getBalancePageProperties: GetServerSideProps<
  BalancePageProperties
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const userAgent = context.req.headers['user-agent'];

  const requestOrganizations = () =>
    api.organizations
      .getOrganizations({query: '', headers})
      .then(path<OrganizationsListResponse['data']>([1, 'data']));

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          const organizations = await requestOrganizations();

          const filteredProperties = removeUndefined({
            ...properties,
            organizations: organizations?.items,
            initialPagination: organizations?.pagination,
          });

          return assoc('props', filteredProperties, {});
        } catch {
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const withdrawBalance = async (
  t: Translate,
  organizationId: WithdrawalsCreateRequest['organization_id'],
  amount: WithdrawalsCreateRequest['amount'],
) =>
  api.withdrawals
    .withdraw({
      headers: clientHeaders(),
      payload: {
        organizationId,
        amount,
      },
    })
    .then(() =>
      showSuccessMessage(t('balance_operations_table.notify.success')),
    )
    .catch((error) => {
      const parsedError = error[1];

      showException(
        parsedError.message || t('balance_operations_table.notify.went_wrong'),
      );
    });

export const loadMoreOrganizations = async (
  nextPage: number,
  onSuccess: (response: ExistingRequisites, nextPage: number) => void,
) =>
  api.organizations
    .getOrganizations({query: '', page: nextPage})
    .then(path<OrganizationsListResponse['data']>([1, 'data']))
    .then((response) => {
      if (response) {
        onSuccess(response, nextPage);
      }
    });

import {DragEndEvent} from '@dnd-kit/core';
import styled from '@emotion/styled';
import {FC} from 'react';
import {Dispatcher} from 'types/utilities';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Loader} from './loader';
import {SortableListMobile} from './sortable-list-mobile';
import {SortableImageMobile} from './sortable-image-mobile';
import {DragButton} from './drag-button';
import {SortableListDesktop} from './sortable-list-desktop';
import {SortableImageDesktop} from './sortable-image-desktop';

const CardsWrapper = styled.ul`
  position: relative;
`;

interface Props {
  images?: Images;
  roomId?: number;
  selectedImages: number[];
  isGalleryLoading: boolean;
  onImageSelect: (image: Image) => void;
  onImageDragEnd: (event: DragEndEvent) => void;
  onMainImageChange: (imageId: number, url: string) => Promise<void>;
  onDeleteComplete: (imageId: number) => void;
  onDeleteImage: (
    imageId: number,
    onComplete: () => void,
    setLoading: Dispatcher<boolean>
  ) => void;
  isMainImage: (imageId: number, roomId?: number) => boolean;
}

const ImagesList: FC<Props> = ({
  images,
  selectedImages,
  roomId,
  isGalleryLoading,
  onImageSelect,
  onImageDragEnd,
  onMainImageChange,
  onDeleteComplete,
  onDeleteImage,
  isMainImage,
}) => {
  const isTouch = useDeviceDetection('touch');

  if (!images) {
    return null;
  }

  return (
    <CardsWrapper>
      <Loader loading={isGalleryLoading} />
      {isTouch ? (
        <SortableListMobile
          images={images}
          onImageDragEnd={onImageDragEnd}
          renderItem={(image) => (
            <SortableImageMobile
              id={image.id}
              image={image}
              isSelected={selectedImages.includes(image.id)}
              onImageSelect={() => onImageSelect(image)}
              onDeleteComplete={onDeleteComplete}
              onMainImageChange={onMainImageChange}
              onDeleteImage={onDeleteImage}
              isMainImage={isMainImage}
              roomId={roomId}
            >
              <DragButton />
            </SortableImageMobile>
          )}
        />
      ) : (
        <SortableListDesktop
          images={images}
          onImageDragEnd={onImageDragEnd}
          renderItem={(image) => (
            <SortableImageDesktop
              key={image.id}
              id={image.id}
              image={image}
              isSelected={selectedImages.includes(image.id)}
              onImageSelect={() => onImageSelect(image)}
              onDeleteComplete={onDeleteComplete}
              onMainImageChange={onMainImageChange}
              onDeleteImage={onDeleteImage}
              isMainImage={isMainImage}
              roomId={roomId}
            />
          )}
        />
      )}
    </CardsWrapper>
  );
};

export default ImagesList;

import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {FC, useState, ChangeEvent} from 'react';

import {Button} from 'library/components/button';
import {Input} from 'library/components/input';
import {Text} from 'library/components/text';

const Wrapper = styled.div`
  padding: 8px;
  width: 240px;
`;

const StyledInput = styled(Input)`
  width: 140px;

  input {
    padding: 15px;
    font-size: 14px;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const StepButton = styled.div`
  background: ${({theme}) => theme.palette.primaryAccent};
  width: 26px;
  height: 26px;
  color: ${({theme}) => theme.palette.fontAccent};
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  cursor: pointer;
  user-select: none;
`;

const StyledText = styled(Text)`
  margin-top: 10px;
`;

const Submit = styled(Button)`
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  text-transform: uppercase;
`;

interface RoomsNumberPopupContentProps {
  maxFreeRooms: number;
  onApply: (value: number) => void;
  initialValue: number;
}

export const RoomsNumberPopupContent: FC<RoomsNumberPopupContentProps> = ({
  maxFreeRooms,
  onApply,
  initialValue,
}) => {
  const [value, setValue] = useState(initialValue);
  const {t} = useTranslation('components');

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = Number(event.target.value);

    if (parsedValue <= maxFreeRooms) {
      setValue(parsedValue);
    }
  };

  return (
    <Wrapper>
      <StepWrapper>
        <StepButton
          onClick={() => setValue((value) => (value > 0 ? value - 1 : 0))}
        >
          -
        </StepButton>
        <StyledInput
          type="number"
          label={null}
          value={`${value}`}
          onChange={handleChangeInput}
        />

        <StepButton
          onClick={() =>
            setValue((value) =>
              value === maxFreeRooms ? maxFreeRooms : value + 1,
            )
          }
        >
          +
        </StepButton>
      </StepWrapper>
      <StyledText size="XS">
        {t('max')} {maxFreeRooms}
      </StyledText>
      <Submit
        disabled={initialValue === value}
        onClick={() => {
          onApply(value);
        }}
      >
        {t('apply')}
      </Submit>
    </Wrapper>
  );
};

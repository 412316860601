export const CheckIcon: React.FC<IconProps> = ({style, className, color}) => (
  <svg
    style={style}
    className={className}
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.40239 7.69927L10.9915 1.11009"
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M4.40237 7.69724L1.40234 4.69727"
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';
import {LandmarksState, LandmarksStateLandmark} from './lib/types';
import {updateToggledLandmark} from './lib/helpers';

interface ILandmarksState {
  landmarks: HotelLandmarks;
  nearLandmarks: LandmarksState;
  stateLandmarks: LandmarksState;
}

const initialState: ILandmarksState = {
  landmarks: [],
  nearLandmarks: [],
  stateLandmarks: [],
};

const landmarksSlice = createSlice({
  name: 'landmarks',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setLandmarks: (state, action: PayloadAction<HotelLandmarks>) => {
      state.landmarks = action.payload;
    },
    setNearLandmarks: (state, action: PayloadAction<LandmarksState>) => {
      state.nearLandmarks = action.payload;
    },
    setStateLandmarks: (state, action: PayloadAction<LandmarksState>) => {
      state.stateLandmarks = action.payload;
    },
    onSwitchLandmark: (
      state,
      action: PayloadAction<LandmarksStateLandmark>,
    ) => {
      const updatedLandmark = updateToggledLandmark(
        state.stateLandmarks,
        action.payload,
      );
      const storeLandmark = updatedLandmark
        .flatMap((landmarks) => landmarks.landmarks)
        .filter((landmark) => landmark.enabled)
        .map((item) => ({
          landmark_id: item.id,
          landmark_group_id: item.landmark_group_id,
        }));

      state.landmarks = storeLandmark;
      state.stateLandmarks = updatedLandmark;
    },
  },
});

export const selectLandmarks = (state: RootState) => state.landmarks.landmarks;

export const selectNearLandmarks = (state: RootState) =>
  state.landmarks.nearLandmarks;

export const selectStateLandmarks = (state: RootState) =>
  state.landmarks.stateLandmarks;

export const {
  reset,
  setLandmarks,
  setNearLandmarks,
  setStateLandmarks,
  onSwitchLandmark,
} = landmarksSlice.actions;

export default landmarksSlice.reducer;

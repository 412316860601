import styled from '@emotion/styled';
import {ActionModal} from 'library/components/action-modal';
import {Button} from 'library/components/button';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC, MouseEvent, MouseEventHandler, useContext} from 'react';
import {HotelContext} from 'source/context/hotel';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Dispatcher} from 'types/utilities';

const Wrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const StyledButton = styled(Button)`
  margin: 20px 0px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 730px) {
    flex-direction: column;
    align-items: start;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  height: min-content;
  max-width: 100%;
  background: none;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: inherit;
  transition: all 0.4s;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
`;

interface Props {
  isGalleryLoading: boolean;
  images?: Images;
  selectedImages: number[];
  setGalleryLoading: LoadingDispatch;
  setIsUploading: Dispatcher<boolean>;
  maxCount: number;
  onSelect: (images: Images) => void;
  onUnselect: () => void;
  onDeleteComplete: () => void;
  onDelete: (
    selectedImages: number[],
    onComplete: () => void,
    setLoading: Dispatcher<boolean>,
  ) => void;
}

const ActionButtons: FC<Props> = ({
  images,
  selectedImages,
  isGalleryLoading,
  setGalleryLoading,
  setIsUploading,
  maxCount,
  onSelect,
  onUnselect,
  onDeleteComplete,
  onDelete,
}) => {
  const {t} = useTranslation();
  const mobile = useDeviceDetection('mobile');
  const [hotel] = useContext(HotelContext);

  const onSelectAction = () => {
    if (images) {
      onSelect(images);
    }
  };

  const onUnselectAction = () => {
    onUnselect();
  };

  const onDeleteAction = (
    close: MouseEventHandler<HTMLButtonElement> | undefined,
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    const onComplete = () => {
      onDeleteComplete();
    };

    if (hotel?.id && selectedImages.length > 0) {
      onDelete(selectedImages, onComplete, setGalleryLoading);
    }
    if (close) {
      close(event);
    }
  };

  return (
    <ButtonsWrapper>
      <StyledButton
        onClick={() => setIsUploading((previous) => !previous)}
        disabled={images ? images?.length >= maxCount : false}
      >
        {t('photos:upload_photo_button')}
      </StyledButton>
      <Wrapper>
        <ActionButton
          disabled={images?.length === selectedImages.length}
          onClick={onSelectAction}
        >
          <Text size={mobile ? 'S' : 'M'}>
            {t('photos:select_action_button')}
          </Text>
        </ActionButton>
        <ActionButton
          disabled={selectedImages.length === 0}
          onClick={onUnselectAction}
        >
          <Text size={mobile ? 'S' : 'M'}>
            {t('photos:unselect_action_button')}
          </Text>
        </ActionButton>
        <ActionModal
          maxWidth={440}
          title={t('photos:should_images_delete')}
          action={(close, event) => onDeleteAction(close, event)}
          actionText={t('components:delete')}
        >
          <ActionButton
            disabled={selectedImages.length === 0 || isGalleryLoading}
          >
            <Text size={mobile ? 'S' : 'M'}>
              {t('photos:delete_action_button')}
            </Text>
          </ActionButton>
        </ActionModal>
      </Wrapper>
    </ButtonsWrapper>
  );
};

export default ActionButtons;

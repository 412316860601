import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';
import {UppyHotelFile} from 'types/logic';
import {PhotosStore} from './lib/types';
import {DEFAULT_PHOTOS_STATE} from './lib/constants';

const initialState: PhotosStore = DEFAULT_PHOTOS_STATE;

export const roomPhotosSlice = createSlice({
  name: 'roomPhotos',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setPhotos: (state, action: PayloadAction<Images>) => {
      state.images = action.payload;
    },
    setSelectedPhotos: (state, action: PayloadAction<number[]>) => {
      state.selectedImages = action.payload;
    },
    sortImages: (state) => {
      state.images = state.images?.sort((a, b) => a.order - b.order);
    },
    setFiles: (state, action: PayloadAction<UppyHotelFile[]>) => {
      state.files = action.payload;
    },
  },
});

export const {reset, setPhotos, setSelectedPhotos, setFiles, sortImages} =
  roomPhotosSlice.actions;

export const selectPhotos = (state: RootState) => state.roomPhotos;

export default roomPhotosSlice.reducer;

import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {blinkAnimation} from 'slices/calendar/lib/helpers/ui';
import {TableButton} from '../../table-button';

export const DataButton = styled(TableButton)<{
  grey?: boolean;
  yellow?: boolean;
  green?: boolean;
  changed?: boolean;
  selected?: boolean;
  isShadow?: boolean;
}>`
  ${({isShadow}) =>
    isShadow &&
    css`
      opacity: 0.5;
    `}

  ${({theme, selected}) =>
    selected &&
    css`
      color: ${theme.palette.fontPrimary};
      border-color: ${theme.palette.border_4};
      font-weight: 700;
      animation: ${blinkAnimation} 0.1s;
    `}
  ${({theme, changed}) =>
    changed &&
    css`
      background-color: ${theme.palette.activeBackground};
    `};
  ${({theme, grey}) =>
    grey &&
    css`
      background-color: ${theme.palette.notAvailableCell};
    `}
  ${({theme, yellow}) =>
    yellow &&
    css`
      background-color: ${theme.palette.yellow};
    `};
  ${({theme, green}) =>
    green &&
    css`
      background-color: ${theme.palette.availableCell};
    `};
`;

import {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith} from 'ramda';
import {CreateHotelierObjectPageProperties} from 'slices/create_hotelier_object/lib/types';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {serverHeaders} from 'source/utilities/network/http';
import {removeUndefined} from 'source/utilities/object';
import {ApplicationProperties} from 'source/utilities/ui';

export const getCreateHotelierObjectPageProperties: GetServerSideProps<
  CreateHotelierObjectPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const getDirectoriesHotelTypes = () =>
    api.directories.getHotelTypes({headers});

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        const hotelTypes = await getDirectoriesHotelTypes();

        const filteredProperties = removeUndefined({
          ...properties,
          directories: {
            hotelTypes,
          },
        });

        return assoc('props', filteredProperties, {});
      },
    ]),
  ).catch(handleException);
};

import {FC} from 'react';

export const PencilIcon: FC<IconProps> = ({style, className, color}) => (
  <svg
    style={style}
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.695 2.305C16.849 1.468 15.715 1 14.527 1C13.348 1 12.205 1.468 11.368 2.305L9.685 3.997L2.107 11.566C1.972 11.71 1.882 11.89 1.855 12.079L1.009 17.974C0.991 18.1 1 18.226 1.036 18.352C1.072 18.478 1.135 18.595 1.225 18.685C1.306 18.784 1.414 18.865 1.531 18.919C1.648 18.973 1.774 19 1.9 19C1.945 19 1.99 19 2.026 18.991L7.921 18.145C8.119 18.118 8.29 18.028 8.434 17.893L17.695 8.632C18.532 7.795 19 6.661 19 5.473C19 4.285 18.532 3.151 17.695 2.305ZM7.372 16.408L2.962 17.038L3.592 12.628L10.315 5.905L14.095 9.676L7.372 16.408ZM16.417 7.363L15.373 8.407L11.593 4.627L12.637 3.583C13.15 3.097 13.825 2.827 14.527 2.827C15.229 2.827 15.913 3.097 16.417 3.583C16.921 4.087 17.2 4.762 17.2 5.473C17.2 6.184 16.921 6.859 16.417 7.363Z"
      fill={color || '#FF5876'}
      stroke={color || '#FF5876'}
      strokeWidth="0.17"
      strokeMiterlimit="22.9256"
    />
  </svg>
);

import {ToastMessageWithLink} from 'library/components/toast-message-with-link';
import {ToastWithDescription} from 'library/components/toast-with-description';
import getT from 'next-translate/getT';
import {cond, objOf, path, pathOr, pipe, prop} from 'ramda';
import {toast, ToastOptions} from 'react-toastify';
import {FieldsErrors} from 'slices/rates';
import {theme} from 'source/utilities/global-style';
import {isClient, isServer} from 'source/utilities/guards/environment';
import {
  BusinessException,
  ExceptionTypes,
  isApiException,
  isBusinessException,
  isRedirectException,
  isUnhandledException,
} from 'source/utilities/guards/exceptions';
import {ELanguage} from 'source/utilities/language';
import {removeAllEmptyValues} from 'source/utilities/object';
import {formatErrorMessage} from 'source/utilities/strings';
import {handleApiException, handleRedirectException} from './network';
import {ErrorCodeLinkOptionsType} from './types';

export const showSuccessMessage = (message: string) => {
  if (isClient()) {
    toast.success(message);
  }
};

export const showMessageWithDescription = (
  message: string,
  description: string,
) => {
  if (isClient()) {
    toast.info(ToastWithDescription({message, description}), {
      icon: false,
      progressStyle: {
        background: theme.palette.primaryAccent,
      },
    });
  }
};

export const showWarnMessage = (message: string) => {
  if (isClient()) {
    toast.warn(message);
  }
};

export const showMessageWithLink = async (message: string, link: string) => {
  if (isClient()) {
    toast.info(await ToastMessageWithLink({message, link}), {
      icon: false,
      progressStyle: {
        background: theme.palette.primaryAccent,
      },
    });
  }
};

export const showException = (message: string, options?: ToastOptions) => {
  if (isClient()) {
    getT(ELanguage.RU, 'errors').then((t) => {
      toast.error<string>(t(message), options);
    });
  }

  if (isServer()) {
    console.error(message);
  }
};

export const handleFieldError = async (errors: FieldsErrors) => {
  const error = errors[0];
  const message = pathOr('', ['message'], error);
  const field = pathOr('', ['field'], error);
  const translate = await getT(ELanguage.RU, 'errors');

  if (!message && field) {
    return translate('unknown');
  }

  return formatErrorMessage(message, translate(`fields.${field}`));
};

export const handleResponseErrors = (
  response: object,
  options?: ErrorCodeLinkOptionsType,
) => {
  const cleanResponse = removeAllEmptyValues(response);
  const firstError = path<object>([0, 0], Object.values(cleanResponse));
  const isErrorWithMessage = firstError && 'message' in firstError;
  const isErrorCodeWithLink =
    isErrorWithMessage &&
    options &&
    'code' in firstError &&
    firstError.code === options.code;

  if (
    cleanResponse &&
    'fields' in cleanResponse &&
    Array.isArray(cleanResponse.fields)
  ) {
    handleFieldError(cleanResponse.fields).then(showException);
    return;
  }

  if (isErrorWithMessage) {
    const message = pathOr('', ['message'], firstError);

    const description = pathOr<string>(
      '',
      ['errors', 0, 'message'],
      firstError,
    );

    if (isErrorCodeWithLink) {
      showMessageWithLink(message, options.link);

      return;
    }

    showMessageWithDescription(message, description);
  }
};

export const throwUnhandledException = (reason: any) => {
  throw reason;
};

export const throwBusinessException =
  (message: string) =>
  <T>(value: T) => {
    throw {type: ExceptionTypes.Business, message};
  };

export const incorrectServiceId = throwBusinessException(
  "Hotel id doesn't recieved properly",
);

export const incorrectRegistrationId = throwBusinessException(
  "Registration id doesn't recieved properly",
);

export const incorrectHotelId = throwBusinessException(
  "Hotel id doesn't recieved properly",
);

export const incorrectUser = throwBusinessException(
  "User doesn't recieved properly",
);

export const incorrectMyHotels = throwBusinessException(
  "Hotels don't recieved properly",
);

export const incorrectMyHotelsLength = throwBusinessException(
  'Hotels length equals 0',
);

export const handleUnhandledException = (exception: any) => {
  showException('unknown');

  throw JSON.stringify(exception, null, 2);
};

export const handleBusinessException = (exception: BusinessException) => {
  showException(exception.message);

  throw exception;
};

/**
 * @description - Используется для того, что бы вытащить props из catch, и передать его
 * @description - Пример: если не нужен редирект при checkUser, который возвращает redirect
 */
export const handlePropsException = pipe(prop('props'), objOf('props'));

export const handleException = cond([
  [isRedirectException, handleRedirectException],
  [isApiException, handleApiException],
  [isBusinessException, handleBusinessException],
  [isUnhandledException, handleUnhandledException],
]);

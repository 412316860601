import {Option} from 'library/components/select';
import {SelectCheckbox} from 'library/components/select-checkbox';
import React, {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {
  getRoomFloorsCountOptions,
  getRoomFloorsValues,
} from 'slices/add-room/lib/helpers';
import {selectFloors, setFloors} from 'slices/add-room/store';
import {store, useAppSelector} from 'source/store';

const Floors = () => {
  const dispatch = useDispatch();

  const floors = useAppSelector(selectFloors);

  const roomsFloorsOptions = useMemo(() => getRoomFloorsCountOptions(), []);

  const handleSetValue = (flag: boolean, option: Option) => {
    // Вытаскивает напрямую из стейта, потому что данная функция мемоизирована
    // Она будет возвращать все что находится выше, только то, что было при инициализации данной стрелочной функции
    const currentFloors = store.getState().room.floors;

    const cloneFloors = currentFloors ? [...currentFloors] : [];

    const updateFloors = flag
      ? [...cloneFloors, option.value]
      : cloneFloors.filter((floor) => floor !== option.value);

    dispatch(setFloors(updateFloors));
  };

  return (
    <SelectCheckbox
      values={getRoomFloorsValues(floors)}
      options={roomsFloorsOptions}
      setValue={handleSetValue}
    />
  );
};

export default Floors;

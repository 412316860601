import styled from '@emotion/styled';
import {
  useRef,
  WheelEvent,
  useContext,
  useCallback,
  useEffect,
  UIEvent,
} from 'react';

import {CalendarRefContext} from 'source/context/calendar-ref';
import {useAppDispatch, useAppSelector} from 'source/store';
import {isClient} from 'source/utilities/guards/environment';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {
  SCROLL_DEFAULT_SET_VALUE,
  SCROLL_HEADER_RANGE_VALUE,
  DATES_SCROLL_CLASSNAME,
  SCROLL_CLASSNAME,
  CELL_GAP,
  MONTH_CLICK_HORIZONTAL_OFFSET,
} from 'slices/calendar/lib/constants';
import {buildSelectedDaysFromMonthDate} from 'slices/calendar/lib/helpers/dates';
import {
  handleAppropriateScroll,
  isColumnSelected,
  onHeaderScroll,
} from 'slices/calendar/lib/helpers/ui';
import {
  resetSelectedDates,
  setIsDatesLimitExceeded,
  selectDaysDates,
  selectMonthsDates,
  selectSelectedCell,
  setSelectedDates,
  setIsBeforeDatesLimitExceeded,
  selectSelectedDates,
} from 'slices/calendar/store';

import {css} from '@emotion/react';
import {ScrollContext} from 'source/context/scroll';
import {useDebounce} from 'source/utilities/hooks/use-debounce';
import {UpdateSwitcher} from '../update-switcher';
import {DisabledRatesSwitcher} from '../disabled-rates-switcher';
import {TodayCell} from './today-cell';
import {LoadMoreButton} from '../load-more-button';
import {MonthCell} from './month-cell';
import {CalendarButton} from './calendar-button';
import {DateCell} from './date-cell';
import {LoadBeforeButton} from '../load-before-button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${CELL_GAP}px;
  padding-top: 10px;

  z-index: 1;
  background-color: ${({theme}) => theme.palette.fontAccent};
  white-space: nowrap;
  text-align: center;
`;

const DatesWrapper = styled.div`
  padding-top: 5px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  background-color: ${({theme}) => theme.palette.fontAccent};
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  margin-bottom: 20px;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: ${CELL_GAP}px;
`;

const TodayRow = styled(Row)`
  gap: ${CELL_GAP}px;
`;

const MonthRow = styled.div<{isTouch: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${CELL_GAP}px;
  padding: 5px;
  overflow-x: scroll;
  overflow-y: hidden;

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.palette.primaryCircuit};
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({theme}) => theme.palette.matterhorn};
  }

  ${({isTouch}) =>
    isTouch &&
    css`
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    `}
`;

const DatesRow = styled.div<{isTouch: boolean}>`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  gap: ${CELL_GAP}px;
  padding: 0 5px 5px 5px;
  overflow-x: scroll;
  overflow-y: hidden;

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.palette.primaryCircuit};
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({theme}) => theme.palette.matterhorn};
  }

  ${({isTouch}) =>
    isTouch &&
    css`
      scroll-behavior: smooth;
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    `}
`;

export const Header = () => {
  const dispatch = useAppDispatch();
  const [calendarRef] = useContext(CalendarRefContext);
  const [scroll, setScroll] = useContext(ScrollContext);

  const datesScrollRef = useRef<HTMLDivElement>(null);
  const monthScrollRef = useRef<HTMLDivElement>(null);
  const mobile = useDeviceDetection('mobile');
  const isTouch = useDeviceDetection('touch');

  const selectedCell = useAppSelector(selectSelectedCell);
  const selectedDates = useAppSelector(selectSelectedDates);

  const daysDates = useAppSelector(selectDaysDates);
  const monthDates = useAppSelector(selectMonthsDates);

  const handleChangeScrollValue = (value: number, withMonth?: boolean) => {
    const datesScrollbar = datesScrollRef?.current;

    if (datesScrollbar) {
      datesScrollbar.scrollLeft += value;
    }

    if (!withMonth) {
      return;
    }

    const monthScrollbar = monthScrollRef?.current;

    if (monthScrollbar) {
      monthScrollbar.scrollLeft += value;
    }
  };

  const handleSetScroll = (value: number) => {
    setScroll(value);
  };
  const debouncedScroll = useDebounce(handleSetScroll, 500);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    handleAppropriateScroll(
      event.target,
      isTouch,
      (scrollLeft) => debouncedScroll(scrollLeft),
      (scrollLeft) => onHeaderScroll(scrollLeft, calendarRef),
    );
  };

  const onMonthClick = (date: Date) => {
    const newDates = buildSelectedDaysFromMonthDate(date);
    dispatch(setSelectedDates(newDates));
    dispatch(setIsDatesLimitExceeded(false));
    dispatch(setIsBeforeDatesLimitExceeded(false));
    handleChangeScrollValue(SCROLL_DEFAULT_SET_VALUE);
  };

  const handleResetSelectedDates = useCallback(() => {
    handleChangeScrollValue(SCROLL_DEFAULT_SET_VALUE, true);
    dispatch(resetSelectedDates());
    dispatch(setIsDatesLimitExceeded(false));
    dispatch(setIsBeforeDatesLimitExceeded(true));
  }, []);

  const handleDateClick = useCallback(
    (date: Date) => {
      onMonthClick(date);
    },
    [calendarRef, scroll],
  );

  const onHeaderWheel = (event: WheelEvent) => {
    const isUp = event.deltaY < 0;
    handleChangeScrollValue(
      isUp ? SCROLL_HEADER_RANGE_VALUE : -SCROLL_HEADER_RANGE_VALUE,
    );
  };

  const stopBodyScroll = (flag: boolean) => {
    if (isTouch) {
      return;
    }

    if (isClient()) {
      const {body} = document;
      body.style.overflow = flag ? 'hidden' : 'auto';
    }
  };

  useEffect(() => {
    if (isTouch) {
      onHeaderScroll(scroll, calendarRef);
    }
  }, [scroll]);

  useEffect(() => {
    onHeaderScroll(MONTH_CLICK_HORIZONTAL_OFFSET, calendarRef);
  }, [selectedDates]);

  return (
    <>
      <Wrapper>
        {!mobile && (
          <>
            <Row>
              <UpdateSwitcher />
            </Row>
            <Row>
              <DisabledRatesSwitcher />
            </Row>
          </>
        )}

        <TodayRow>
          <TodayCell onResetSelectedDates={handleResetSelectedDates} />

          <MonthRow ref={monthScrollRef} isTouch={isTouch}>
            {monthDates.map((monthDate) => (
              <MonthCell
                key={monthDate.id}
                onMonthClick={handleDateClick}
                date={monthDate.date}
              />
            ))}
          </MonthRow>
        </TodayRow>
      </Wrapper>
      <DatesWrapper>
        <Row>
          <CalendarButton />
          {!isTouch && (
            <>
              <LoadBeforeButton />
              <LoadMoreButton />
            </>
          )}

          <DatesRow
            isTouch={isTouch}
            ref={datesScrollRef}
            className={`${DATES_SCROLL_CLASSNAME} ${SCROLL_CLASSNAME}`}
            onScroll={handleScroll}
            onWheel={onHeaderWheel}
            onMouseEnter={() => stopBodyScroll(true)}
            onMouseLeave={() => stopBodyScroll(false)}
          >
            {daysDates?.map((dayDate, index) => (
              <DateCell
                key={dayDate.id}
                selected={isColumnSelected(index, selectedCell)}
                date={dayDate.date}
              />
            ))}
          </DatesRow>
        </Row>
      </DatesWrapper>
    </>
  );
};

import styled from '@emotion/styled';
import {Headline} from 'library/components/headline';
import useTranslation from 'next-translate/useTranslation';
import {FC, useContext, useEffect} from 'react';
import {MAX_HOTEL_IMAGES, MIN_HOTEL_IMAGES} from 'slices/photos/lib/constants';
import {reset, selectPhotos, setPhotos, sortImages} from 'slices/photos/store';
import {HotelContext} from 'source/context/hotel';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Photos} from 'source/components/photos-dashboard/photos';
import {showSuccessMessage} from 'source/utilities/exceptions/business';
import {
  isMainImage,
  onDeleteSelectedComplete,
  onFileDeleteComplete,
  onFilesUpdate,
  onImageDelete,
  onImageUpload,
  onMainImageChange,
  onMoveImages,
  onRemoveImage,
  onReorderImages,
  onSelect,
  onSelectImage,
  onUnselect,
  transformHotelImages,
} from './lib/helpers';
import {deleteImages} from './network';

const Wrapper = styled.div``;

const PhotoWrapper = styled.div`
  margin-top: 10px;
`;

interface Props {
  images?: HotelImages;
}

const Content: FC<Props> = ({images}) => {
  const [hotel, setHotel] = useContext(HotelContext);
  const {t} = useTranslation('photos');

  const mobile = useDeviceDetection('mobile');

  const dispatch = useAppDispatch();
  const photosState = useAppSelector(selectPhotos);

  useEffect(() => {
    dispatch(reset());

    if (images) {
      dispatch(setPhotos(transformHotelImages(images)));
      dispatch(sortImages());
    }
  }, [images, hotel?.id]);

  return (
    <>
      <Headline level={mobile ? 'M' : 'XL'}>
        {t('headline', {
          count: photosState.images?.length || 0,
          maxCount: MAX_HOTEL_IMAGES,
        })}
      </Headline>

      <Wrapper>
        <PhotoWrapper>
          <Photos
            images={photosState.images || []}
            selectedImages={photosState.selectedImages || []}
            minPhotos={MIN_HOTEL_IMAGES}
            maxPhotos={MAX_HOTEL_IMAGES}
            onDeleteComplete={onFileDeleteComplete}
            onDeleteSelectedComplete={onDeleteSelectedComplete}
            onRemoveImage={onRemoveImage}
            onMoveImages={onMoveImages}
            onSelect={onSelect}
            onSelectImage={onSelectImage}
            onUnselect={onUnselect}
            isMainImage={(imageId) => isMainImage(imageId, hotel)}
            onFileDeleteComplete={onFileDeleteComplete}
            onFilesUpdate={onFilesUpdate}
            onImageUpload={onImageUpload}
            onReorderImages={(
              activeId,
              preHotelImageId,
              images,
              activeIndex,
              overIndex
            ) =>
              onReorderImages(
                activeId,
                preHotelImageId,
                images,
                activeIndex,
                overIndex,
                hotel
              )
            }
            onMainImageChange={async (imageId) =>
              onMainImageChange(
                imageId,
                () => showSuccessMessage(t('main_image_successfull_change')),
                setHotel,
                hotel
              )
            }
            onFileDelete={(imageId, onComplete, setLoading) =>
              onImageDelete(imageId, onComplete, setLoading, hotel)
            }
            onDeleteImage={(imageId, onComplete, setLoading) =>
              onImageDelete(imageId, onComplete, setLoading, hotel)
            }
            onDeleteSelected={(selectedImages, onComplete, setLoading) =>
              deleteImages(selectedImages, onComplete, setLoading, hotel)
            }
          />
        </PhotoWrapper>
      </Wrapper>
    </>
  );
};

export default Content;

import {ApplicationProperties} from 'source/utilities/ui';
import {
  DirectoriesDepositPaymentTypesListResponse,
  DirectoriesPaymentMethodsListResponse,
  DirectoriesPetsAllowedTypeListResponse,
  DirectoriesTaxesAndFeesCalculatingListResponse,
  DirectoriesTaxesAndFeesListResponse,
} from 'types/api-scheme';

export type PoliciesDirectories = {
  paymentsMethods?: DirectoriesPaymentMethodsListResponse['data'];
  petsAllowedType?: DirectoriesPetsAllowedTypeListResponse['data'];
  depositPaymentTypes?: DirectoriesDepositPaymentTypesListResponse['data'];
  taxesAndFees?: DirectoriesTaxesAndFeesListResponse['data'];
  taxesAndFeesCalculating?: DirectoriesTaxesAndFeesCalculatingListResponse['data'];
};

export interface PoliciesPageProperties extends ApplicationProperties {
  hotel?: Hotel;
  directories?: PoliciesDirectories;
}

export const enum EAccessWithAnimalType {
  IMPOSSIBLE = 1,
}

export enum REGISTRATION_FEE_FOREIGN_CITIZENS_AMOUNT {
  MIN = 0,
  MAX = 1000,
}

export enum DEPOSIT_AMOUNT {
  MIN = 0,
  MAX = 1000,
}

export type RequiredTabsType = 'check-in-out' | 'payments' | 'with-animals';

export enum ERequiredTabs {
  CHECK_IN_OUT = 'check-in-out',
  PAYMENTS = 'payments',
  WITH_ANIMALS = 'with-animals',
}

export enum ECalculationPoliciesType {
  ForAccommodation = 2,
  PerPersonPerStay = 3,
  PerDayOfStay = 4,
  PerPersonPerDay = 5,
}

export type RequiredPolicies = Required<Policies>;

// placement
export type PlacementTime = RequiredPolicies['placement_time'];
export type PlacementDescription = PlacementTime['description'];
export type CheckInStart = PlacementTime['checkin_start'];
export type CheckInEnd = PlacementTime['checkin_end'];
export type CheckOutStart = PlacementTime['checkout_start'];
export type CheckOutEnd = PlacementTime['checkout_end'];

// pets-allowed
export type PetsAllowed = RequiredPolicies['pets_allowed'];
export type PetsType = UpdateType<
  Required<PetsAllowed>['type'],
  'value',
  Required<PetsAllowed>['type']['value'] | -1
>;

export type PetsAllowedDescription = PetsAllowed['description'];

// deposit
export type Deposit = RequiredPolicies['security_deposit'];
export type DepositPaymentType = UpdateType<
  Required<Deposit>['type'],
  'value',
  Required<Deposit>['type']['value'] | -1
>;

// taxes-and-fees
export type TaxesAndFees = RequiredPolicies['taxes_and_fees'];
export type TaxesAndFeesItem = Required<TaxesAndFees[number]>;
export type TaxesAndFeesType = TaxesAndFeesItem['tax_and_fee_type'];
export type TaxesAndFeesCalculation = UpdateType<
  Required<TaxesAndFeesItem>['calculation_type'],
  'value',
  Required<TaxesAndFeesItem>['calculation_type']['value'] | -1
>;

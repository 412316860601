import {request} from 'library/api';
import {path} from 'ramda';
import {url} from 'source/utilities/network/url';
import {HotelsRatingsDetailResponse} from 'types/api-scheme';

type GetHotelRatingsContext = {
  hotelId: number;
  headers: Headers;
};
const getHotelRatings = ({hotelId, headers}: GetHotelRatingsContext) =>
  request<HotelsRatingsDetailResponse>(url(`hotels/${hotelId}/ratings`), {
    method: 'GET',
    headers,
  }).then(path<HotelsRatingsDetailResponse['data']>([1, 'data']));

export const ratingsApi = {
  get: getHotelRatings,
};

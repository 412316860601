import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {getInputStatus} from 'library/utilities/get-input-status';
import useTranslation from 'next-translate/useTranslation';
import type {ChangeEvent, Ref, RefObject, KeyboardEvent} from 'react';
import {
  forwardRef,
  memo,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {Icon} from 'source/components/icon';
import {Text} from '../text';

const ERROR_STATUSES = new Set(['required', 'unformatted', 'not_equal']);

interface StyledProperties {
  status: ControlStatus;
  cursor?: string;
  transparent?: boolean;
  padding?: string;
  textCenter?: boolean;
}

enum EDecimalsDividers {
  DOT = '.',
  COMMA = ',',
}

const EXCLUDED_NUMBER_SYMBOLS = new Set(['+', '-', 'e', 'E']);

const Wrapper = styled.div<StyledProperties>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

export const Label = styled.label`
  width: fit-content;
  cursor: pointer;
`;

export const Control = styled.input<StyledProperties>`
  padding: ${({padding}) => padding || '20px 40px 20px 20px'};
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  border-style: solid;
  cursor: ${({cursor}) => `${cursor} !important`};
  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.4;
    `}
  border-width: 2px;
  border-radius: 6px;
  background-color: ${({transparent, theme}) =>
    transparent ? 'transparent' : theme.palette.border_3};
  border-color: ${({status}) =>
    ERROR_STATUSES.has(status) ? 'red' : 'transparent'};
  text-overflow: ellipsis;

  ::placeholder {
    color: ${({theme}) => theme.palette.fontSecondary};
  }

  ${({textCenter}) =>
    textCenter &&
    css`
      text-align: center;
    `}
`;

const ControlWrapper = styled.div`
  position: relative;
  width: inherit;
  max-width: inherit;
  max-height: inherit;
  height: inherit;
`;

const Cross = styled.div<{disabled?: boolean}>`
  position: absolute;
  right: 12px;
  top: 40%;
  cursor: pointer;
  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.4;
    `}
`;

const IconWrapper = styled.div<{
  disabled?: boolean;
  hasClickHandleExist: boolean;
}>`
  position: absolute;
  right: 12px;
  top: 35%;
  width: 20px;
  height: 20px;
  cursor: ${({hasClickHandleExist}) =>
    hasClickHandleExist ? 'pointer' : 'auto'};
  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.4;
    `}
`;

const Counter = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
  position: absolute;
  bottom: 3px;
  right: 13px;
`;

export const Message = styled.span<StyledProperties>`
  color: red;
`;

const rendered = (reference: RefObject<HTMLInputElement>) =>
  Boolean(reference.current);

export interface InputRef {
  focus: () => void;
}

interface InputProps extends InputProperties {
  forwardedRef?: Ref<InputRef>;
}

const Component = forwardRef<InputRef, InputProps>(
  (
    {
      className,
      label,
      force,
      required,
      requiredMessage,
      pattern,
      gap,
      patternMessage,
      onCrossClick,
      errorMessage,
      forcedMessage,
      value,
      cursor,
      disabled,
      maxCount,
      type,
      transparent,
      onChange,
      padding,
      textCenter,
      icon,
      shouldEqualValue,
      shouldEqualMessage,
      iconOnClick,
      iconWidth = 20,
      iconHeight = 20,
      noDecimals,
      resetIsChange,
      ...properties
    },
    forwardedRef,
  ) => {
    const [isChange, setIsChange] = useState<boolean>(false);
    const reference = useRef<HTMLInputElement>(null);
    const id = useId();
    const isNeedToPreventDecimals = type === 'number' && noDecimals;

    useImperativeHandle(
      forwardedRef,
      () => ({
        focus: () => {
          if (reference.current) {
            reference.current.focus();
          }
        },
      }),
      [reference.current],
    );
    const status = getInputStatus(
      rendered(reference),
      isChange,
      force,
      value ?? '',
      required,
      pattern,
      shouldEqualValue,
    );
    const {t} = useTranslation('components');

    const isValueExceedingMax = (event: ChangeEvent<HTMLInputElement>) => {
      if (maxCount && value) {
        const convertEventValue =
          type === 'number'
            ? Number(event.target.value)
            : event.target.value.length;
        const convertValue = type === 'number' ? Number(value) : value.length;
        const isValueExceedingMax =
          maxCount < convertEventValue && convertValue > 0;
        const canDelete = convertEventValue < convertValue;
        return isValueExceedingMax && !canDelete;
      }
    };

    const handleDecimalsCheck = (event: KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === EDecimalsDividers.DOT ||
        event.key === EDecimalsDividers.COMMA
      ) {
        event.preventDefault();
      }
    };

    const handleNumberCheck = (event: KeyboardEvent<HTMLInputElement>) => {
      if (EXCLUDED_NUMBER_SYMBOLS.has(event.key)) {
        event.preventDefault();
      }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange && !isValueExceedingMax(event)) {
        onChange(event);
      }
      setIsChange(true);
    };

    const handleOnWheel = (event: Event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (type === 'number') {
        handleNumberCheck(event);
      }
      if (isNeedToPreventDecimals) {
        handleDecimalsCheck(event);
      }
    };

    useEffect(() => {
      if (resetIsChange) {
        setIsChange(false);
      }
    }, [resetIsChange]);

    useEffect(() => {
      const element = reference.current;

      if (type === 'number' && element) {
        element.addEventListener('wheel', handleOnWheel);
      }

      return () => element?.removeEventListener('wheel', handleOnWheel);
    }, []);

    return (
      <Wrapper role="group" className={className} status={status}>
        {label && (
          <Label style={{marginBottom: gap}} htmlFor={id}>
            {label}
          </Label>
        )}
        <ControlWrapper>
          <Control
            id={id}
            ref={reference}
            required={required}
            transparent={transparent}
            pattern={pattern}
            cursor={cursor}
            value={value}
            status={status}
            disabled={disabled}
            autoComplete="off"
            type={type}
            padding={padding}
            textCenter={textCenter}
            {...properties}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          {onCrossClick && value?.length ? (
            <Cross disabled={disabled} onClick={onCrossClick}>
              <Icon name="input-cross" height={16} width={16} />
            </Cross>
          ) : null}
          {icon ? (
            <IconWrapper
              disabled={disabled}
              onClick={iconOnClick}
              hasClickHandleExist={typeof iconOnClick === 'function'}
            >
              <Icon name={icon} width={iconWidth} height={iconHeight} />
            </IconWrapper>
          ) : null}
          {maxCount && value ? (
            <Counter size="XS">
              {type === 'number' ? value : value?.length}/{maxCount}
            </Counter>
          ) : null}
        </ControlWrapper>

        {required && status === 'required' && (
          <Message status={status}>{requiredMessage || t('required')}</Message>
        )}
        {pattern && status === 'unformatted' && (
          <Message status={status}>
            {errorMessage ||
              (patternMessage
                ? t('incorrect_format_example', {patternMessage})
                : t('incorrect_format'))}
          </Message>
        )}
        {forcedMessage && <Message status={status}>{forcedMessage}</Message>}
        {status === 'not_equal' && (
          <Message status={status}>
            {shouldEqualMessage || t('not_equal')}
          </Message>
        )}
      </Wrapper>
    );
  },
);

Component.displayName = 'Input';

export const Input = memo(Component, (previous, next) => {
  const keys: (keyof InputProperties)[] = [
    'value',
    'disabled',
    'placeholder',
    'onChange',
    'force',
    'forcedMessage',
    'icon',
    'resetIsChange',
  ];
  const equals = (key: keyof InputProperties) => previous[key] === next[key];

  return keys.every(equals);
});

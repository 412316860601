import Head from 'next/head';
import {FC, ReactNode} from 'react';

export interface SeoProviderConfig {
  title?: string;
  keywords?: string;
  description?: string;
  author?: string;
  addRobots?: boolean;
}

interface SeoProviderProps {
  config: SeoProviderConfig;
  children?: ReactNode;
}

export const SeoProvider: FC<SeoProviderProps> = ({config, children}) => {
  return (
    <>
      <Head>
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta name="keywords" content={config.keywords} />
        <meta name="author" content={config.author} />
        <meta name="googlebot" content="notranslate" />
        <meta name="google" content="notranslate" />
        {config.addRobots && <meta name="robots" content="noindex,nofollow" />}
      </Head>
      {children}
    </>
  );
};

import React, {FC, ReactNode} from 'react';
import styled from '@emotion/styled';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {SortableContext} from '@dnd-kit/sortable';

const Wrapper = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  --gap: 20px;
  --columns: 4;
  margin-top: 24px;
  list-style: none;

  @media (max-width: 1200px) {
    --columns: 3;
  }

  @media (max-width: 900px) {
    --columns: 2;
  }
  @media (max-width: 480px) {
    --columns: 1;
  }

  li {
    width: calc(
      (100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
    );
  }
`;

interface Props {
  onImageDragEnd: (event: DragEndEvent) => void;
  images: Images;
  renderItem(image: Image): ReactNode;
}

export const SortableListDesktop: FC<Props> = ({
  onImageDragEnd,
  images,
  renderItem,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor)
  );
  return (
    <Wrapper>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onImageDragEnd}
      >
        <SortableContext items={images?.map((item: Image) => item.id)}>
          {images
            ? images.map((image) => (
                <React.Fragment key={image.id}>
                  {renderItem(image)}
                </React.Fragment>
              ))
            : null}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};

export const BottomArrowIconBig: React.FC<IconProps> = ({
  style,
  className,
  color,
}) => (
  <svg
    style={style}
    className={className}
    width="23"
    height="14"
    viewBox="0 0 23 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2.06459L11.4662 12M11.5338 11.9354L21 2"
      stroke={color || '#FF5876'}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const BottomArrowIconSmall: React.FC<IconProps> = ({
  style,
  className,
  color,
}) => (
  <svg
    style={style}
    className={className}
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.03568L6.48043 6.51611M6.51957 6.48048L12 1.00005"
      stroke={color || '#FF5876'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

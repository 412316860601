import styled from '@emotion/styled';

import {InputRef} from 'library/components/input';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {forwardRef, memo, useEffect, useState} from 'react';

import BnovoProvider from 'source/components/bnovo-provider';
import {useAppSelector} from 'source/store';
import {
  buildRoomCategory,
  getPossibleRoomTypes,
  getPossibleSubCategories,
  getTranslate,
} from '../lib/helpers';
import {ClientRoomCategory, RoomCategories} from '../lib/types';
import {
  selectRoomCategoryId,
  selectRoomId,
  selectRoomParentCategoryIds,
} from '../store';
import {AreaInput} from './inputs/area-input';
import {BabyBedSwitch} from './inputs/baby-bed-switch';
import {BathroomsSelect} from './inputs/bathrooms-select';
import {BedroomsSelect} from './inputs/bedrooms-select';
import {CategorySelect} from './inputs/category-select';
import {FloorsSelect} from './inputs/floors-select';
import {GuestNumberInput} from './inputs/guest-number-input';

import {NameInput} from './inputs/name-input';
import {RoomsNumberInput} from './inputs/rooms-number-input';
import {SubCategorySelect} from './inputs/subcategory-select';
import {TypeSelect} from './inputs/type-select';
import {RoomList} from './room-list';

const Wrapper = styled.div`
  width: 100%;
  padding-top: 22px;
  @media (max-width: 480px) {
    padding-top: 0;
  }
`;

const Title = styled(Text)`
  letter-spacing: 0.03em;
  margin-bottom: 21px;
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const InputsWrapper = styled.div<{addMargin?: boolean}>`
  display: grid;
  grid-template-columns: repeat(2, 440px);
  margin-bottom: ${({addMargin}) => addMargin && '20px'};
  & > div {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    display: inline-block;
    width: 440px;
    vertical-align: top;
    min-height: 130px;
  }
  column-gap: 25px;
  @media (max-width: 930px) {
    grid-template-columns: repeat(1, 440px);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    & > div {
      width: 100%;
    }
  }
`;

const CategoryWrapper = styled(InputsWrapper)`
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    & > div:nth-child(2) {
      order: 3;
    }
    & > div:nth-child(3) {
      order: 2;
    }
    & > div:nth-child(4) {
      order: 4;
    }
  }
`;

interface RoomDescriptionsProps {
  roomCategories?: RoomCategories;
  bedTypes?: BedTypes;
  translateKey: string;
  isIntegrationPage: boolean;
  resetIsChange: boolean;
  type: 'add-room' | 'edit-room';
}

const RoomDescriptionsComponent = forwardRef<InputRef, RoomDescriptionsProps>(
  (
    {
      type,
      roomCategories,
      bedTypes,
      translateKey,
      isIntegrationPage,
      resetIsChange,
    },
    inputRef,
  ) => {
    const {t} = useTranslation(translateKey);
    const roomParentCategoryIds = useAppSelector(selectRoomParentCategoryIds);
    const roomCategoryId = useAppSelector(selectRoomCategoryId);
    const roomId = useAppSelector(selectRoomId);

    const [roomCategory, setRoomCategory] = useState<ClientRoomCategory>({
      ...buildRoomCategory(
        roomCategoryId,
        roomParentCategoryIds,
        roomCategories,
      ),
    });
    const [possibleRoomTypes, setPossibleRoomTypes] = useState<RoomCategories>(
      [],
    );
    const [possibleSubCategories, setPossibleSubCategories] =
      useState<RoomCategories>([]);

    useEffect(() => {
      const updatedPossibleRoomTypes = getPossibleRoomTypes(
        roomCategory.category,
        roomCategories,
      );

      setPossibleRoomTypes(updatedPossibleRoomTypes);

      const updatedPossibleSubCategories = getPossibleSubCategories(
        roomCategory.category,
        roomCategory.roomType,
        roomCategories,
      );

      setPossibleSubCategories(updatedPossibleSubCategories);
    }, [roomCategory, roomCategories]);

    useEffect(() => {
      setRoomCategory({
        ...buildRoomCategory(
          roomCategoryId,
          roomParentCategoryIds,
          roomCategories,
        ),
      });
    }, [roomId]);

    return (
      <Wrapper>
        <Title size="boldS">{getTranslate(t, 'name_title')}</Title>

        <CategoryWrapper addMargin>
          <NameInput
            translateKey={translateKey}
            ref={inputRef}
            resetIsChange={resetIsChange}
            disableAutoFocus={isIntegrationPage}
          />
          <TypeSelect
            translateKey={translateKey}
            possibleRoomTypes={possibleRoomTypes}
            roomCategory={roomCategory}
            roomCategories={roomCategories}
            setRoomCategory={setRoomCategory}
          />
          <CategorySelect
            roomCategory={roomCategory}
            roomCategories={roomCategories}
            translateKey={translateKey}
            setRoomCategory={setRoomCategory}
          />
          <SubCategorySelect
            roomCategory={roomCategory}
            translateKey={translateKey}
            setRoomCategory={setRoomCategory}
            possibleSubCategories={possibleSubCategories}
          />
        </CategoryWrapper>

        <Title size="boldS">{getTranslate(t, 'room_info_title')}</Title>

        <InputsWrapper addMargin>
          <AreaInput
            translateKey={translateKey}
            resetIsChange={resetIsChange}
          />
          {type === 'add-room' ? (
            <RoomsNumberInput
              translateKey={translateKey}
              resetIsChange={resetIsChange}
            />
          ) : (
            <BnovoProvider>
              <RoomsNumberInput
                translateKey={translateKey}
                resetIsChange={resetIsChange}
              />
            </BnovoProvider>
          )}
          <BedroomsSelect translateKey={translateKey} />
          <GuestNumberInput
            translateKey={translateKey}
            resetIsChange={resetIsChange}
          />
          <BathroomsSelect translateKey={translateKey} />
          <FloorsSelect translateKey={translateKey} />
        </InputsWrapper>

        <BabyBedSwitch translateKey={translateKey} />

        <RoomList bedTypes={bedTypes} translateKey={translateKey} />
      </Wrapper>
    );
  },
);

RoomDescriptionsComponent.displayName = 'RoomDescriptionsComponent';

export const RoomDescriptions = memo(RoomDescriptionsComponent);

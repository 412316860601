import styled from '@emotion/styled';
import {NavigationLink} from 'library/components/navigation-link';
import {SubText} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {selectBalance} from 'slices/common';
import {useAppSelector} from 'source/store';
import {Routes} from 'source/utilities/network/url';

const BalanceWrapper = styled.div`
  margin-top: 4px;
`;

const StyledSubtext = styled(SubText)`
  word-break: break-word;
`;

const StyledNavigationLink = styled(NavigationLink)`
  display: inline-block;
  p {
    text-decoration: underline;
  }
`;

const UserBalance = () => {
  const {t} = useTranslation('common');
  const balance = useAppSelector(selectBalance);

  if (!balance) {
    return null;
  }

  return (
    <BalanceWrapper>
      <StyledNavigationLink href={Routes.balance}>
        {t('routing:balance')}
      </StyledNavigationLink>
      <StyledSubtext size="S">
        {t('balance_amount', {
          amount: balance.amount,
          currency: t('units_payments.rub'),
        })}
      </StyledSubtext>
    </BalanceWrapper>
  );
};

export default UserBalance;

import {StatusBadgeObject} from './types';

export const DEFAULT_VALUE_FOR_MODERATION_ID = -1;

export const STATUS_BADGE_OBJECT: StatusBadgeObject = {
  1: {
    textColor: '#FFFFFF',
    backgroundColor: '#FF5876',
    label: 'moderation_requested',
  },
  2: {
    textColor: '#FFFFFF',
    backgroundColor: '#1e9e1e',
    label: 'moderation_approved',
  },
  3: {
    textColor: '#FFFFFF',
    backgroundColor: '#FF4949',
    label: 'moderation_declined',
  },
};

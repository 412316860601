import styled from '@emotion/styled';
import Uppy from '@uppy/core';
import XHR from '@uppy/xhr-upload';
import {Dashboard} from '@uppy/react';
import {Button} from 'library/components/button';
import useTranslation from 'next-translate/useTranslation';
import {FC, useContext, useEffect, useMemo, useState} from 'react';
import {HotelContext} from 'source/context/hotel';
import {
  DEFAULT_COUNT_IMAGES,
  LOCAL_MAX_TOTAL_FILE_SIZE,
} from 'source/utilities/constants/logic';
import {MAX_TOTAL_FILE_SIZE} from 'source/utilities/guards/environment';
import {isImage} from 'source/utilities/guards/uppy';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {uppyHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {UppyHotelFileType} from 'types/logic';
import {Dispatcher} from 'types/utilities';
import {PreviewCard} from './preview-card';
import UploadProgress from './upload-progress';

const Wrapper = styled.div`
  .uppy-Dashboard-files {
    display: none;
  }

  .uppy-Dashboard-innerWrap {
    justify-content: space-between;
  }
`;

const StyledButton = styled(Button)`
  margin: 20px 0px;
`;

const PreviewImages = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 30px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const getUppy = (
  imagesCount: number,
  singularText: string,
  pluralText: string,
  maxImages: number,
) => {
  return new Uppy({
    meta: {type: 'image'},
    locale: {
      strings: {
        youCanOnlyUploadX: {
          0: singularText,
          1: pluralText,
        },
      },
    },
    restrictions: {
      maxFileSize: MAX_TOTAL_FILE_SIZE
        ? +MAX_TOTAL_FILE_SIZE
        : LOCAL_MAX_TOTAL_FILE_SIZE,
      maxNumberOfFiles: maxImages - imagesCount,
      allowedFileTypes: ['.jpg', '.jpeg', '.png'],
    },
    autoProceed: true,
  }).use(XHR, {endpoint: ''});
};

interface Props {
  setIsUploading: Dispatcher<boolean>;
  images: Images;
  maxCount: number;
  roomId?: number;
  onImageUpload: (images: Images) => void;
  onFileDeleteComplete: (imageId?: number) => void;
  onFilesUpdate: (files: UppyHotelFileType[]) => void;
  onFileDelete: (
    imageId: number,
    onComplete: () => void,
    setLoading: Dispatcher<boolean>,
  ) => void;
}

const Preview: FC<Props> = ({
  setIsUploading,
  images,
  maxCount,
  roomId,
  onImageUpload,
  onFileDelete,
  onFilesUpdate,
  onFileDeleteComplete,
}) => {
  const {t} = useTranslation('photos');
  const [hotel] = useContext(HotelContext);
  const mobile = useDeviceDetection('mobile');
  const [loading, setLoading] = useState(false);
  const [isUppyRebuild, setIsUppyRebuild] = useState(false);
  const [uploadImages, setUploadImages] = useState<Images>([]);

  const countPhotos = maxCount - (images?.length || DEFAULT_COUNT_IMAGES);
  const startedAndCompletedCount = uploadImages?.length || DEFAULT_COUNT_IMAGES;

  const uppy = useMemo(
    () =>
      getUppy(
        images?.length || 0,
        t('count_photos_one', {
          count: countPhotos,
        }),
        t('count_photos', {
          count: countPhotos,
        }),
        maxCount,
      ),
    [isUppyRebuild],
  );

  const updateFiles = () => {
    onFilesUpdate(uppy.getFiles() as UppyHotelFileType[]);
  };

  const deleteUploadedImage = (imageId: Image['id']) => {
    const onComplete = () => {
      onFileDeleteComplete(imageId);
    };
    if (hotel?.id && imageId) {
      onFileDelete(imageId, onComplete, setLoading);
    }
    setUploadImages((previous) =>
      previous.filter((image) => image.id !== imageId),
    );
  };

  const doneButtonHandler = () => {
    setIsUppyRebuild((previous) => !previous);
    setIsUploading((previous) => !previous);
  };

  const backToGallery = () => {
    setIsUploading((previous) => !previous);
  };

  uppy.off('complete', null!).on('complete', (result) => {
    const uploadedImages = result.successful[0].response?.body.data;

    if (Array.isArray(uploadedImages) && uploadedImages.every(isImage)) {
      onImageUpload([...uploadedImages]);
      setUploadImages((previous) => [...previous, ...uploadedImages]);
    }
  });
  uppy.on('file-added', updateFiles);
  uppy.on('file-removed', updateFiles);
  uppy.on('upload-progress', updateFiles);
  uppy.on('upload-success', updateFiles);
  uppy.on('upload-error', updateFiles);

  useEffect(() => {
    const options = {
      formData: true,
      fieldName: 'images[]',
      bundle: true,
      headers: uppyHeaders(),
    };
    if (roomId) {
      uppy.setMeta({room_id: roomId});
      uppy?.getPlugin('XHRUpload')?.setOptions({
        endpoint: url(`hotels/${hotel?.id}/rooms/${roomId}/images`),
        ...options,
      });
    } else {
      uppy?.getPlugin('XHRUpload')?.setOptions({
        endpoint: url(`hotels/${hotel?.id}/images`),
        ...options,
      });
    }

    return () => {
      uppy.off('file-added', updateFiles);
      uppy.off('file-removed', updateFiles);
      uppy.off('upload-progress', updateFiles);
      uppy.off('upload-success', updateFiles);
      uppy.off('upload-error', updateFiles);
      uppy.cancelAll();
    };
  }, [hotel?.id, isUppyRebuild]);

  useEffect(() => {
    if (images.length === maxCount) {
      setIsUploading((previous) => !previous);
    }
  }, [images.length]);

  return (
    <Wrapper>
      <StyledButton onClick={backToGallery}>
        {t('back_to_gallery_button')}
      </StyledButton>
      <Dashboard
        uppy={uppy}
        width="100%"
        height="156px"
        proudlyDisplayPoweredByUppy={false}
        doneButtonHandler={doneButtonHandler}
        locale={{
          strings: {
            addMore: t('addMore'),
            addingMoreFiles: t('addingMoreFiles'),
            done: t('done'),
            back: t('back'),
            uploadComplete: t('uploadComplete'),
            dropPasteFiles: '%{browseFiles}',
            browseFiles: mobile ? t('browse_files_mobile') : t('browse_files'),
            uploadFailed: t('uploadFailed'),
            retry: t('retry'),
            cancel: t('cancel'),
          },
        }}
      />
      <UploadProgress
        isUploadStarted={uppy.getObjectOfFilesPerState().isUploadStarted}
        isUploadInProgress={uppy.getObjectOfFilesPerState().isUploadInProgress}
        uploadStartedCount={startedAndCompletedCount}
        completedCount={startedAndCompletedCount}
        erroredCount={uppy.getObjectOfFilesPerState().erroredFiles.length}
      />
      <PreviewImages>
        {uploadImages?.map(({id, url}) => (
          <PreviewCard
            key={id}
            url={url}
            id={id}
            deleteUploadedImage={deleteUploadedImage}
          />
        ))}
      </PreviewImages>
    </Wrapper>
  );
};

export default Preview;

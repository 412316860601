import {FC, createContext, useState} from 'react';

export type CalendarRefState = NullableState<HTMLDivElement>;

export const CalendarRefContext = createContext<CalendarRefState>([
  null,
  () => {},
]);

export const CalendarRefProvider: FC<
  ServerlessProviderProperties<CalendarRefState>
> = ({...properties}) => {
  const state = useState<ContextValue<CalendarRefState>>(null);

  return <CalendarRefContext.Provider value={state} {...properties} />;
};

import styled from '@emotion/styled';
import {FC, ReactNode} from 'react';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Tooltip} from '../tooltip';
import {Question} from './question';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  position: relative;
  width: fit-content;
`;

const QuestionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface HintProps {
  children?: ReactNode;
  className?: string;
  hintOnQuestion?: boolean;
  text: string;
  maxWidth?: number;
  minWidth?: number;
}

export const Hint: FC<HintProps> = ({
  children,
  className,
  hintOnQuestion,
  text,
  maxWidth,
  minWidth,
}) => {
  const mobile = useDeviceDetection('mobile');

  return hintOnQuestion ? (
    <Wrapper className={className}>
      <Tooltip
        on="click"
        position="top left"
        trigger={
          <QuestionWrapper>
            <Question />
          </QuestionWrapper>
        }
        mobileTopModal={mobile}
        minWidth={minWidth}
        maxWidth={maxWidth}
      >
        {text}
      </Tooltip>
      {children}
    </Wrapper>
  ) : (
    <Tooltip
      on="click"
      position="top left"
      mobileTopModal={mobile}
      trigger={
        <Wrapper className={className}>
          <QuestionWrapper>
            <Question />
          </QuestionWrapper>
          {children}
        </Wrapper>
      }
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      {text}
    </Tooltip>
  );
};

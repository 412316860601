import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {addDays, isAfter, isWithinInterval} from 'date-fns';
import {useState, useMemo, FC, useEffect, Dispatch} from 'react';
import {DateRangeProps, RangeKeyDict} from 'react-date-range';
import useTranslation from 'next-translate/useTranslation';

import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {useAppDispatch, useAppSelector} from 'source/store';
import {
  addCurrentYearDays,
  parseBackendDateFormat,
} from 'source/utilities/dates';
import {Text} from 'library/components/text';

import {
  selectRangeSelectCells,
  setUpdatedCells,
  selectUpdatedCells,
  resetRangeSelectCells,
  selectRatesPrices,
  selectMassEditModalFilters,
} from 'slices/calendar/store';
import {buildPricesDateRange} from 'slices/calendar/lib/helpers/dates';
import {
  RANGE_KEY,
  DEFAULT_RATE_PERIOD,
  MIN_PRICE_VALUE,
} from 'slices/calendar/lib/constants';
import {flatten, isEmpty, isNil} from 'ramda';
import {showWarnMessage} from 'source/utilities/exceptions/business';
import {buildDateCellId} from 'slices/calendar/lib/helpers/ui';
import {CalendarMassEditFilters} from '../calendar-mass-edit-filters';

import {ActionButtons} from '../action-buttons';
import {CalendarMassEditPicker} from '../calendar-mass-edit-picker';
import {AllPriceInputRow} from './all-price-input-row';

const Wrapper = styled.div<{isTouch: boolean}>`
  height: 45vh;
  min-height: 45vh;
  overflow-y: scroll;
  padding-top: 20px;
  width: 100%;

  ${({isTouch}) =>
    isTouch &&
    css`
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
    `}
`;

const ButtonsWrapper = styled.div`
  padding: 10px 0;
  @media (max-width: 600px) {
    padding: 10px 0 20px 0;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const UnavailableRateText = styled(Text)`
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledCalendarMassEditPicker = styled(CalendarMassEditPicker)`
  margin-bottom: 33px;
`;

interface IProps {
  onCancel: () => void;
  onRangeChange?: (range: RangeKeyDict) => void;
  currentRange: {
    from: Date;
    to: Date;
  };
  setIsOpen: Dispatch<boolean>;
}

export const ModalRangeCellsContent: FC<IProps> = ({
  onRangeChange,
  onCancel,
  currentRange,
  setIsOpen,
}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('calendar');
  const today = new Date();
  const rangeSelectCells = useAppSelector(selectRangeSelectCells);
  const ratesPrices = useAppSelector(selectRatesPrices);
  const updatedCells = useAppSelector(selectUpdatedCells);
  const isTouch = useDeviceDetection('touch');
  const initialPriceRangeDate = buildPricesDateRange(rangeSelectCells.prices);

  const [priceInputValue, setPriceInputValue] = useState('');
  const [ranges, setRanges] = useState<DateRangeProps['ranges']>([
    {...initialPriceRangeDate, key: RANGE_KEY},
  ]);

  const {guestNumber, rateId, roomId} = useAppSelector(
    selectMassEditModalFilters,
  );

  const {from, to} = useMemo(() => {
    // Период действия выбранного тарифа
    const currentRate = ratesPrices[roomId]?.ratesPrices.find(
      (ratePrice) => ratePrice.rate.id === rateId,
    );

    if (currentRate) {
      const {from, to} = currentRate.rate.period;

      return {
        from: from ? parseBackendDateFormat(from) : null,
        to: to ? parseBackendDateFormat(to) : null,
      };
    }

    return DEFAULT_RATE_PERIOD;
  }, [ratesPrices[rateId], rateId, roomId]);

  const maxEndDate = addCurrentYearDays(today);
  // Проверяет чтобы конец действия тарифа не был задним числом,
  // а начало периода не начиналось после года от текущей даты
  const isRatePeriodEnded = !!(to && isAfter(today, to));
  const isRatePeriodStartsAfterYear = !!(from && isAfter(from, maxEndDate));
  const isRatePeriodUnavailable =
    isRatePeriodEnded || isRatePeriodStartsAfterYear;

  const handleApply = () => {
    if (!ranges) {
      return;
    }

    // Добавляет в массив updatedCells измененные ячейки
    if (isEmpty(guestNumber)) {
      showWarnMessage(t('choose_guest_number'));
      return;
    }

    if (Number(priceInputValue) < MIN_PRICE_VALUE) {
      showWarnMessage(t('enter_price_value'));
      return;
    }

    setIsOpen(false);
    const currentRate = ratesPrices[roomId].ratesPrices.find(
      (ratePrice) => rateId === ratePrice.rate.id,
    );

    if (!currentRate) {
      return;
    }

    const {from, to} = currentRange;
    const filteredByGuestsNumber = currentRate.by_guests_number.filter(
      (element) => guestNumber.includes(element.guests_number),
    );

    const filteredByDates = filteredByGuestsNumber.map((element) => {
      const filteredByDatePrices = element.prices.filter((price) =>
        isWithinInterval(new Date(price.date), {
          start: from,
          end: addDays(to, 1),
        }),
      );

      return {...element, prices: filteredByDatePrices};
    });

    const {startDate} = ranges[0];

    if (startDate) {
      // По id ищем ячейку даты (первую из диапазона изменений) и скроллим к ней
      const updatedDateCellElement = document.querySelector(
        buildDateCellId(startDate, true),
      );

      if (updatedDateCellElement) {
        updatedDateCellElement.scrollIntoView({
          inline: 'center',
          block: 'nearest',
        });
      }
    }

    const parsedData = filteredByDates.map((element) => {
      return element.prices
        .filter((price) => !isNil(price.price))
        .map((price) => {
          return {
            roomId,
            rate: rateId,
            price: Number(priceInputValue),
            date: price.date,
            isDatePrice: true,
            guestNumber: element.guests_number,
          };
        });
    });

    const newUpdatedCells = {
      ...updatedCells,
      [roomId]: flatten(parsedData),
    };

    dispatch(setUpdatedCells(newUpdatedCells));
    dispatch(resetRangeSelectCells());
  };

  useEffect(() => {
    // Актуализация пикера при переключении между вкладками
    const {from, to} = currentRange;

    setRanges([{startDate: from, endDate: to, key: RANGE_KEY}]);
  }, [currentRange]);

  if (isRatePeriodUnavailable) {
    return (
      <>
        <Wrapper isTouch={isTouch}>
          {isRatePeriodEnded && (
            <UnavailableRateText size="M">
              {t('room_range.rate_period_ended')}
            </UnavailableRateText>
          )}

          {isRatePeriodStartsAfterYear && (
            <UnavailableRateText size="M">
              {t('room_range.rate_period_starts_after_year')}
            </UnavailableRateText>
          )}

          <CalendarMassEditFilters />
        </Wrapper>
        <ButtonsWrapper>
          <ActionButtons
            isEdit
            type="apply"
            disabled
            onSave={handleApply}
            onCancel={onCancel}
          />
        </ButtonsWrapper>
      </>
    );
  }

  return (
    <>
      <Wrapper isTouch={isTouch}>
        <StyledCalendarMassEditPicker
          ranges={ranges}
          setRanges={setRanges}
          onRangeChange={onRangeChange}
        />

        <CalendarMassEditFilters />
        <AllPriceInputRow
          value={priceInputValue}
          onChange={(event) => setPriceInputValue(event.target.value)}
        />
      </Wrapper>
      <ButtonsWrapper>
        <ActionButtons
          isEdit
          type="apply"
          onSave={handleApply}
          onCancel={onCancel}
        />
      </ButtonsWrapper>
    </>
  );
};

import {request} from 'library/api';
import {path} from 'ramda';
import {DEFAULT_PAGE} from 'source/utilities/constants/logic';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  WithdrawalsCreateRequest,
  WithdrawalsCreateResponse,
  WithdrawalsListResponse,
} from 'types/api-scheme';

type GetWithdrawalsContext = {
  headers?: Headers;
  page?: number;
  organizationId: WithdrawalsCreateRequest['organization_id'];
};

const getWithdrawals = ({
  headers,
  organizationId,
  page = DEFAULT_PAGE,
}: GetWithdrawalsContext) =>
  request<WithdrawalsListResponse>(
    url('withdrawals', {
      page: String(page),
      'organization_ids[]': String(organizationId),
    }),
    {
      method: 'GET',
      headers: headers || clientHeaders(),
    },
  ).then(path<WithdrawalsListResponse['data']>([1, 'data']));

type WithdrawContext = {
  headers?: Headers;
  payload: {
    amount: WithdrawalsCreateRequest['amount'];
    organizationId: WithdrawalsCreateRequest['organization_id'];
  };
};

const withdraw = ({
  headers,
  payload: {amount, organizationId},
}: WithdrawContext) =>
  request<WithdrawalsCreateResponse>(url('withdrawals'), {
    method: 'POST',
    headers: headers || clientHeaders(),
    body: JSON.stringify({
      amount,
      organization_id: organizationId,
    }),
  }).then(path<WithdrawalsCreateResponse['data']>([1, 'data']));

export const withdrawalsApi = {
  get: getWithdrawals,
  withdraw,
};

import {useTheme} from '@emotion/react';
import {FC} from 'react';

export const DoubleArrowIcon: FC<IconProps> = ({style, className, color}) => {
  const theme = useTheme();
  return (
    <svg
      style={style}
      className={className}
      width="17"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.725 1 16 6.979m-.04.042L9.683 13M1.725 1 8 6.979m-.04.042L1.683 13"
        stroke={color || theme.palette.primaryAccent}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

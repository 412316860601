import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {ButtonHTMLAttributes, FC} from 'react';
import {CELL_WIDTH} from 'slices/calendar/lib/constants';

type Variant = 'primary' | 'secondary';

const StyledButton = styled.button<{variant: Variant}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${CELL_WIDTH}px;
  height: 45px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.palette.border};
  border-radius: 20px;
  background-color: ${({theme}) => theme.palette.defaultBackground};
  transition: 200ms ease-in-out;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${({theme, variant}) => {
    switch (variant) {
      case 'secondary': {
        return css`
          border-color: ${theme.palette.fontPrimary};
          background-color: ${theme.palette.fontPrimary};
        `;
      }
      default: {
        return null;
      }
    }
  }}
`;

type ButtonProps = {
  variant?: Variant;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const FilterButton: FC<ButtonProps> = ({
  variant = 'primary',
  ...attributes
}) => <StyledButton type="button" variant={variant} {...attributes} />;

import {PASSWORD_INPUT_TYPE, PASSWORD_ICON_TYPE} from './type';

export const getNewPasswordInputType = (previousType: PASSWORD_INPUT_TYPE) =>
  previousType === PASSWORD_INPUT_TYPE.PASSWORD
    ? PASSWORD_INPUT_TYPE.TEXT
    : PASSWORD_INPUT_TYPE.PASSWORD;

export const getPasswordInputIcon = (inputType: PASSWORD_INPUT_TYPE) =>
  inputType === PASSWORD_INPUT_TYPE.PASSWORD
    ? PASSWORD_ICON_TYPE.PASSWORD
    : PASSWORD_ICON_TYPE.TEXT;

import styled from '@emotion/styled';
import {ButtonHTMLAttributes, FC} from 'react';

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  height: min-content;
  max-width: 100%;
  background: none;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: inherit;
  transition: all 0.4s;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
`;

type Props = {} & ButtonHTMLAttributes<HTMLButtonElement>;

export const TextButton: FC<Props> = ({...properties}) => (
  <Wrapper {...properties} />
);

import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {memo} from 'react';

import {useAppSelector, useAppDispatch} from 'source/store';
import {checkIsEmptyObject} from 'source/utilities/object';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';
import {DoubleArrowIcon} from 'library/icons/double-arrow-icon';

import {
  getLoadButtonTextKey,
  slideAnimation,
} from 'slices/calendar/lib/helpers/ui';
import {
  selectEditRooms,
  selectIsDatesLimitExceeded,
  selectIsLoading,
  setIsNeedLoadMoreDates,
} from 'slices/calendar/store';
import {ScrollDirectionType} from 'slices/calendar/lib/types';

const IconWrapper = styled.div<{disabled: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;
  position: absolute;
  right: 30px;
  top: 5px;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  opacity: 0.8;
  cursor: pointer;
  background-color: ${({theme, disabled}) =>
    disabled ? theme.palette.fontPrimary : theme.palette.fontAccent};
  color: ${({theme, disabled}) =>
    disabled ? theme.palette.fontAccent : theme.palette.fontPrimary};

  border: 1px solid ${({theme}) => theme.palette.fontPrimary};
  border-radius: 100px;
  animation: ${slideAnimation} 1.5s linear infinite;
`;

const LoadMoreButtonComponent = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('calendar');
  const isTouch = useDeviceDetection('touch');
  const editRooms = useAppSelector(selectEditRooms);
  const isDatesLimitExceeded = useAppSelector(selectIsDatesLimitExceeded);
  const isNoEditedRooms = checkIsEmptyObject(editRooms);
  const loading = useAppSelector(selectIsLoading);

  const isLoadMoreDataAvailable = isNoEditedRooms && !loading;

  const buttonTranslateKey = getLoadButtonTextKey(
    isDatesLimitExceeded,
    isNoEditedRooms,
    ScrollDirectionType.RIGHT,
  );

  const handleClickLoadMore = () => {
    dispatch(setIsNeedLoadMoreDates(true));
  };

  return (
    <>
      {!isDatesLimitExceeded && (
        <Tooltip
          on={isTouch ? 'click' : 'hover'}
          position="bottom right"
          minWidth={270}
          maxWidth={300}
          mobileTopModal={isTouch}
          trigger={
            <IconWrapper
              disabled={!isLoadMoreDataAvailable}
              onClick={handleClickLoadMore}
              onTouchEnd={handleClickLoadMore}
            >
              {isLoadMoreDataAvailable ? <DoubleArrowIcon /> : <CloseIcon />}
            </IconWrapper>
          }
        >
          <Text size="XS">{t(buttonTranslateKey)}</Text>
        </Tooltip>
      )}
    </>
  );
};

export const LoadMoreButton = memo(LoadMoreButtonComponent);

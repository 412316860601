import {FC} from 'react';
import {useTheme} from '@emotion/react';

export const CancelIcon: FC<IconProps> = ({style, className, color}) => {
  const theme = useTheme();
  return (
    <svg
      style={style}
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 512 512"
    >
      <path
        fill={color || theme.palette.matterhorn}
        d="M232.5 1.1c-50 5.1-94.6 23-134.5 53.7C78.5 69.8 53.2 98 38.6 121c-6.3 9.9-18.6 35.1-23 47.1-4.9 13.5-10.2 34-12.8 49.9-3.1 19.7-3.2 55.6 0 75.5 4.5 28.5 11.4 50.1 24.2 76 24.9 50.6 64.9 90.6 115.5 115.5 18.7 9.3 30.2 13.6 48.6 18.5 80.9 21.2 166.8 1.3 231.3-53.8l9.1-7.8.5 14.3c.6 16.3 2 20.8 8.5 27.3 6.9 6.8 10.8 7.9 29.2 8.3 18.6.4 24.4-.7 31.4-6.1 2.3-1.7 5.6-5.7 7.3-8.7l3.1-5.5.3-75.6c.2-75.4.2-75.7-1.9-81.5-2.8-7.5-8.8-13.5-16.3-16.3-5.8-2.1-6.1-2.1-81.5-1.9l-75.6.3-5.5 3.1c-3 1.7-7 5-8.7 7.3-5.4 7-6.5 12.8-6.1 31.5.3 15.8.4 16.4 3.3 22.1 1.9 3.7 4.7 7.2 7.3 9.2 7.2 5.5 11.9 6.3 36.1 6.3h21.5l-4.9 5.1c-6.5 6.6-20.1 17.4-29.2 23.2-20.2 12.9-44.6 22-69.3 25.8-11 1.7-39.1 1.7-50 0-77.9-12.2-136.7-70.7-149.1-148.6-.7-4.4-1.3-15.9-1.3-25.5 0-18.4 1-27.5 4.9-43 10.8-42.9 37.7-80.3 75.1-104.6C211.4 75.5 274.9 71.1 330 96.7c19.3 8.9 35.4 20.4 51.5 36.7 16.4 16.6 25.5 30.1 36.6 54.5 7.1 15.6 12.6 22.2 22.4 27 5.4 2.7 8.3 3.4 14.9 3.8 17.5 1.1 31.4-7.3 38.9-23.6 4-8.8 4.3-20.2.8-30.1-3.5-10-13.5-30.3-20.3-41.5C433.5 56.2 366.4 12.9 286.5 2c-11.1-1.5-43.1-2.1-54-.9z"
      />
      <path
        fill={color || theme.palette.matterhorn}
        d="M168.3 154.1c-5.9 2.9-17.9 15.8-19.4 20.7-2.6 8.9-3 8.4 34.9 46.4l34.7 34.8-34.2 34.2c-37.7 37.9-38 38.3-35.4 47 1.5 4.9 14.2 18.7 19.6 21.2 4.2 2 9.8 2 13.9.1 1.7-.8 18.2-16.6 36.6-35l33.5-33.5 19.6 19.6 19.6 19.6.6-8c1-13.2 6.2-24.5 15.8-34l4.3-4.3-13.2-13.2-13.2-13.2 33.1-33c18.2-18.2 33.9-34.7 35-36.7 4.2-8.2 1.8-14.8-9.5-25.5-8.9-8.5-13.2-10.3-19.9-8.4-3.6 1.1-9.2 6.3-38.5 35.4L252 222.5l-34.3-34.2c-18.8-18.8-35.5-34.7-37-35.3-4.2-1.5-7.7-1.2-12.4 1.1z"
      />
    </svg>
  );
};

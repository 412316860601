/* eslint-disable camelcase */

import styled from '@emotion/styled';
import {FC, memo} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {formatCancellation} from 'source/utilities/business';
import {Tooltip} from 'library/components/tooltip';

import {DayDatesType} from 'slices/calendar/lib/types';
import {CELL_WIDTH} from 'slices/calendar/lib/constants';
import {DataButton} from '../data-button';

const DataCell = styled.div`
  min-width: ${CELL_WIDTH}px;
`;

const TooltipTrigger = styled.div`
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  &-content {
    font-size: 14px;
  }
`;

interface IProps {
  cancellation_fine: CalendarCancellationFine;
  dayDate: DayDatesType[number];
}

const CancellationCellComponent: FC<IProps> = ({
  cancellation_fine,
  dayDate,
}) => {
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation();

  return (
    <DataCell key={dayDate.id}>
      <StyledTooltip
        on={mobile ? 'click' : 'hover'}
        minWidth={mobile ? 270 : 100}
        maxWidth={300}
        mobileTopModal={mobile}
        position="top center"
        trigger={
          <TooltipTrigger>
            <DataButton grey>
              {cancellation_fine
                ? formatCancellation(cancellation_fine, t)
                : '?'}
            </DataButton>
          </TooltipTrigger>
        }
      >
        {cancellation_fine ? formatCancellation(cancellation_fine, t) : '?'}
      </StyledTooltip>
    </DataCell>
  );
};

export const CancellationCell = memo(CancellationCellComponent);

import {useTheme} from '@emotion/react';
import {FC} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {useAppDispatch, useAppSelector} from 'source/store';
import {getCountOption, getCountOptions} from 'source/utilities/object';

import {LabeledComponent} from 'library/components/labeled-component';
import {Select} from 'library/components/select';

import {selectRoomBedrooms, setBedrooms} from '../../store';
import {getLabelTranslate} from '../../lib/helpers';

interface IProps {
  translateKey: string;
}

export const BedroomsSelect: FC<IProps> = ({translateKey}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {t} = useTranslation(translateKey);
  const bedRooms = useAppSelector(selectRoomBedrooms);

  const bedRoomsOptions = getCountOptions(1, 10);

  return (
    <LabeledComponent
      color={theme.palette.fontSecondary}
      size="S"
      gap={10}
      text={getLabelTranslate(t, 'bedrooms')}
    >
      <Select
        value={getCountOption(bedRooms)}
        options={bedRoomsOptions}
        setValue={(option) => {
          dispatch(setBedrooms(option.value));
        }}
      />
    </LabeledComponent>
  );
};

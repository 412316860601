import Script from 'next/script';
import {ROISTAT_ID} from 'source/utilities/guards/environment';

export const RoistatScript = () => {
  return (
    <Script
      id="roistat"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
                (function(w, d, s, h, id) {
                    w.roistatProjectId = id; w.roistatHost = h;
                    var p = d.location.protocol == "https:" ? "https://" : "http://";
                    var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
                    var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
                })(window, document, 'script', 'cloud.roistat.com', '${ROISTAT_ID}');
            `,
      }}
    />
  );
};

import type {Redirect} from 'next';

export const url = (
  path: string,
  query?: ConstructorParameters<typeof URLSearchParams>[0],
  base = process.env.NEXT_PUBLIC_API,
) => {
  if (!base) {
    throw new Error('Specify "NEXT_PUBLIC_API" within your .env please');
  }
  const result = new URL(path, base);
  result.search = new URLSearchParams(query).toString();

  return result;
};

export enum Routes {
  home = '/',
  registration = '/registration',
  authorization = '/authorization',
  manage = '/manage',
  serverErrorPage = '/500',
  notFoundPage = '/404',
  bookings = '/bookings',
  profile = '/manage/profile',
  createObject = '/manage/create',
  balance = '/balance',
  balanceDetails = '/balance/[organization_slug]',
}

export const redirects: Record<keyof typeof Routes, Redirect> = {
  home: {
    destination: Routes.home,
    permanent: false,
  },
  registration: {
    destination: Routes.registration,
    permanent: false,
  },
  authorization: {
    destination: Routes.authorization,
    permanent: false,
  },
  manage: {
    destination: Routes.manage,
    permanent: false,
  },
  serverErrorPage: {
    destination: Routes.serverErrorPage,
    permanent: false,
  },
  notFoundPage: {
    destination: Routes.notFoundPage,
    permanent: false,
  },
  bookings: {
    destination: Routes.bookings,
    permanent: false,
  },
  profile: {
    destination: Routes.profile,
    permanent: false,
  },
  createObject: {
    destination: Routes.createObject,
    permanent: false,
  },
  balance: {
    destination: Routes.balance,
    permanent: false,
  },
  balanceDetails: {
    destination: Routes.balanceDetails,
    permanent: false,
  },
};

import {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith} from 'ramda';
import {BookingPageProperties} from 'slices/booking/lib/types';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {serverHeaders} from 'source/utilities/network/http';
import {parseNumberQueryParameter} from 'source/utilities/network/router';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';

export const getBookingPageProperties: GetServerSideProps<
  BookingPageProperties
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const bookingId = transformToNumber(context?.params?.booking_slug);
  const hotelId = parseNumberQueryParameter(context?.query.hotel_id);
  const requestBooking = () =>
    api.booking.get({
      bookingId,
      headers,
      hotelId,
    });

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(bookingId);
          checkIdValidity(hotelId);
          const booking = await requestBooking();
          const filteredProperties = removeUndefined({
            ...properties,
            booking,
          });

          return assoc('props', filteredProperties, {});
        } catch {
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

import {useContext} from 'react';
import {UtilitiesContext} from 'source/context/utilities';
import {isMobile, isTablet} from 'source/utilities/ui';

type Device = 'mobile' | 'tablet' | 'touch';

export const useDeviceDetection = (device: Device) => {
  const [utilities] = useContext(UtilitiesContext);
  if (device === 'touch') {
    return isMobile(utilities) || isTablet(utilities);
  }
  if (device === 'mobile') {
    return isMobile(utilities);
  }
  if (device === 'tablet') {
    return isTablet(utilities);
  }
  return false;
};

import {request} from 'library/api';
import {path} from 'ramda';
import {url} from 'source/utilities/network/url';
import {BookingsListResponse} from 'types/api-scheme';

export type BookingsParameters = {
  main_id?: string;
  hotel_id?: string;
  last_name?: string;
  created_at_from?: string;
  created_at_to?: string;
  checkin_from?: string;
  checkin_to?: string;
  checkout_from?: string;
  checkout_to?: string;
  status?: string;
  sort?: BookingSortKeys;
  sort_direction?: BookingSortDirectionKeys;
  payment_type?: string;
};

type GetBookingsContext = {
  page: string;
  headers?: Headers;
  parameters?: BookingsParameters;
};
const getBookings = ({page, headers, parameters}: GetBookingsContext) =>
  request<BookingsListResponse>(url('bookings', {page, ...parameters}), {
    method: 'GET',
    headers,
  }).then(path<BookingsListResponse>([1]));

export const bookingsApi = {
  get: getBookings,
};

import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {SubText, Text} from 'library/components/text';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import {DEFAULT_BALANCE} from 'slices/balance/lib/constants';
import {getAmountColor, getHoldAmountColor} from 'slices/balance/lib/utils';
import {selectBalance} from 'slices/common';
import {useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const BalanceInformationWrapper = styled.div`
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const BalanceInformation = () => {
  const {t} = useTranslation();
  const balance = useAppSelector(selectBalance);
  const theme = useTheme();
  const isMobile = useDeviceDetection('mobile');
  const textSize = isMobile ? 'S' : 'M';
  const totalAvailableAmount = balance?.amount || DEFAULT_BALANCE;
  const totalHoldAmount = balance?.amountHold || DEFAULT_BALANCE;

  return (
    <BalanceInformationWrapper>
      <Text size={textSize}>
        <Trans
          i18nKey="balance:balance_information.amount_available"
          values={{
            amount: totalAvailableAmount,
            currency: t('common:units_payments.rub'),
          }}
          components={[
            <SubText
              color={getAmountColor(theme, totalAvailableAmount)}
              size={textSize}
              key={0}
            />,
          ]}
        />
      </Text>
      <Text size={textSize}>
        <Trans
          i18nKey="balance:balance_information.amount_hold"
          values={{
            amount: totalHoldAmount,
            currency: t('common:units_payments.rub'),
          }}
          components={[
            <SubText
              color={getHoldAmountColor(theme, totalHoldAmount)}
              size={textSize}
              key={0}
            />,
          ]}
        />
      </Text>
    </BalanceInformationWrapper>
  );
};

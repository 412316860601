import {FC, createContext, useState} from 'react';

export type HotelState = NullableState<Hotel>;

export const HotelContext = createContext<HotelState>([null, () => {}]);

export const HotelProvider: FC<ServerlessProviderProperties<HotelState>> = ({
  ...properties
}) => {
  const state = useState<ContextValue<HotelState>>(null);

  return <HotelContext.Provider value={state} {...properties} />;
};

import styled from '@emotion/styled';
import {ChangeEvent, FC} from 'react';

import {Input} from 'library/components/input';
import useTranslation from 'next-translate/useTranslation';
import {Text} from 'library/components/text';

const InputWrapper = styled.div`
  padding: 0 22px;

  @media (max-width: 800px) {
    padding: 0 10px;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  width: 100%;

  @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;

  input {
    font-size: 14px;
    padding: 15px;
  }
`;

interface IProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AllPriceInputRow: FC<IProps> = ({value, onChange}) => {
  const {t} = useTranslation('calendar');

  return (
    <InputWrapper>
      <Text size="XS">{t('room_range.price')}</Text>
      <StyledInput value={value} type="number" onChange={onChange} />
    </InputWrapper>
  );
};

import {useTheme} from '@emotion/react';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';

import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';

import {useAppDispatch, useAppSelector} from 'source/store';

import {patterns} from 'source/utilities/patterns';
import {MAX_AREA_VALUE} from 'slices/add-room/lib/constants';
import {selectRoomArea, setArea} from '../../store';
import {
  getLabelTranslate,
  getNumberFromInputEvent,
  getPlaceholderTranslate,
  getTranslate,
} from '../../lib/helpers';

interface IProps {
  translateKey: string;
  resetIsChange: boolean;
}

export const AreaInput: FC<IProps> = ({translateKey, resetIsChange}) => {
  const theme = useTheme();
  const area = useAppSelector(selectRoomArea);
  const dispatch = useAppDispatch();
  const {t} = useTranslation(translateKey);

  return (
    <LabeledComponent
      color={theme.palette.fontSecondary}
      size="S"
      gap={10}
      text={getLabelTranslate(t, 'area')}
      subText={t('required')}
      subTextSize="XS"
      subTextColor={theme.palette.danger}
      textAlign="end"
    >
      <Input
        placeholder={getPlaceholderTranslate(t, 'area')}
        value={String(area)}
        required
        resetIsChange={resetIsChange}
        pattern={patterns.compatible('positiveNumber')}
        errorMessage={getTranslate(t, 'enter_number_greater_zero')}
        maxCount={MAX_AREA_VALUE}
        onChange={(event) => dispatch(setArea(getNumberFromInputEvent(event)))}
        label={null}
        type="number"
      />
    </LabeledComponent>
  );
};

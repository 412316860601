import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {BalancesTotalListResponse} from 'types/api-scheme';

const getBalance = (headers?: Headers) =>
  request<BalancesTotalListResponse>(url('balances/total'), {
    method: 'GET',
    headers: headers || clientHeaders(),
  }).then(path<BalancesTotalListResponse['data']>([1, 'data']));

export const balanceApi = {
  get: getBalance,
};

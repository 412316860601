import {
  NotificationChannelIcons,
  NotificationChannelType,
} from 'slices/profile/ui/notification-channels/types';

export const NOTIFICATION_CHANNEL_ICONS: NotificationChannelIcons = {
  [NotificationChannelType.EMAIL]: 'email',
  [NotificationChannelType.TELEGRAM]: 'telegram',
};

export const DURATION_POLLING_UPDATE_NOTIFICATION_CHANNELS = 5000;

import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useSyncExternalStore,
} from 'react';

import {BehaviorSubject} from 'rxjs';

export interface ApplicationLocalStore {
  hasShowDisableRate: boolean;
}

export const initialSessionStore: ApplicationLocalStore = {
  hasShowDisableRate: true,
};

const key = 'applicationStore';

const safelyReadStore = () => {
  try {
    const maybeStore = localStorage.getItem(key);

    if (maybeStore) {
      const value = JSON.parse(maybeStore);

      return value as ApplicationLocalStore;
    }

    return initialSessionStore;
  } catch {
    return initialSessionStore;
  }
};

const safelyWriteStore = (store: ApplicationLocalStore) => {
  try {
    const snapshot = JSON.stringify(store);

    localStorage.setItem(key, snapshot);
  } catch (error) {
    throw error;
  }
};

const createLocalStore = () => {
  const reference = useRef(
    new BehaviorSubject<ApplicationLocalStore>(initialSessionStore),
  );

  const get = useCallback(() => reference.current.value, []);

  const set = useCallback(
    (setter: (store: ApplicationLocalStore) => ApplicationLocalStore) => {
      const next = setter(reference.current.value);

      safelyWriteStore(next);

      reference.current.next(next);

      return next;
    },
    [],
  );

  const subscribe = useCallback((subscriber: () => void) => {
    const subscription = reference.current.subscribe(subscriber);

    return () => subscription.unsubscribe();
  }, []);

  return {
    get,
    set,
    subscribe,
  };
};

const LocalStoreContext = createContext<ReturnType<
  typeof createLocalStore
> | null>(null);

export const LocalStoreProvider: FC<{children: ReactNode}> = ({children}) => (
  <LocalStoreContext.Provider value={createLocalStore()}>
    {children}
  </LocalStoreContext.Provider>
);

export const useLocalStore = <Selected extends unknown = ApplicationLocalStore>(
  selector?: (store: ApplicationLocalStore) => Selected,
) => {
  const store = useContext(LocalStoreContext);

  if (!store) {
    throw new Error('Store is not defined!');
  }

  const pick = useCallback(
    () => (selector ? selector(store.get()) : store.get()) as Selected,
    [selector],
  );

  const state = useSyncExternalStore(store.subscribe, pick, pick);

  useEffect(() => {
    store.set(() => safelyReadStore());
  }, []);

  return [state, store.set] as const;
};

export type LocalStoreDispatch = ReturnType<typeof createLocalStore>['set'];

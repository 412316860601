export enum ModerationStatusEnum {
  REQUESTED = 1,
  APPROVED = 2,
  DECLINED = 3,
  // TODO - изменить название, узнав у бэка за что этот тип отвечает
  UNKNOWN = 4,
}

export enum ModerationFieldsEnum {
  DESCRIPTION = 'description',
}

export type StatusBadgeObject = {
  [name: number]: {
    textColor: string;
    backgroundColor: string;
    label: string;
  };
};

import DOMPurify from 'isomorphic-dompurify';
import type {GetServerSideProps} from 'next';
import {
  andThen,
  assoc,
  assocPath,
  call,
  identity,
  ifElse,
  pipeWith,
  when,
} from 'ramda';
import {FormEvent} from 'react';
import {ModerationFieldsEnum} from 'slices/moderation';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {HotelStatusEnum} from 'source/utilities/business';
import {
  handleException,
  handleResponseErrors,
  incorrectMyHotels,
} from 'source/utilities/exceptions/business';
import {throwRedirectException} from 'source/utilities/exceptions/network';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {isUnhandledException} from 'source/utilities/guards/exceptions';
import {ELanguage} from 'source/utilities/language';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {isValidHotelId} from 'source/utilities/guards/business';
import {GeneralInfoPageProperties} from './lib/types';

export const getGeneralInfoPageProperties: GetServerSideProps<
  GeneralInfoPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestHotelModerationLast = () =>
    api.moderation.getLast({hotelId, headers});

  const requestRates = () => api.rates.get({hotelId, headers});

  const requestModerationDescriptionList = () =>
    api.moderation.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, hotelModerationLast, moderationDescriptionList, rates] =
            await Promise.all([
              requestHotel(),
              requestHotelModerationLast(),
              requestModerationDescriptionList(),
              requestRates(),
            ]);

          const result = removeUndefined({
            ...properties,
            hotel,
            hotelModerationLast,
            moderationDescriptionList,
            rates,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const handleHotelUpdate =
  (
    hotel: Hotel,
    formdata: UpdateHotelFormData,
    onComplete: () => void,
    ...dispatchers: [LoadingDispatch, HotelDispatch]
  ) =>
  (event: FormEvent<HTMLFormElement>) => {
    const [setLoading, setHotel] = dispatchers;

    event.preventDefault();

    setLoading(true);

    api.hotel
      .update({hotelId: hotel.id, payload: formdata})
      .then(ifElse(isValidHotelId, identity, incorrectMyHotels))
      .then((hotel) => {
        setHotel(hotel);
      })
      .then(onComplete)
      .catch(handleException)
      .finally(() => setLoading(false));
  };

export const changeHotelStatus = (
  hotel: Hotel,
  status: HotelStatusEnum,
  setHotel: HotelDispatch,
) => {
  api.hotel
    .updateStatus({hotelId: hotel.id, payload: {new_status: status}})
    .then((response) => {
      const isSuccess = response && 'value' in response && 'title' in response;

      if (isSuccess) {
        setHotel(assocPath(['status', 'value'], response.value, hotel));
      }

      if (!isSuccess && response) {
        handleResponseErrors(response);
      }
    })
    .catch(handleException);
};

export const sendModerationDescription = (
  hotel: Hotel,
  text: string,
  onComplete: (moderationField?: ModerationField) => void,
  ...dispatchers: [LoadingDispatch]
) => {
  const [setLoading] = dispatchers;

  setLoading(true);

  api.moderation
    .create({
      hotelId: hotel.id,
      payload: {
        field_type: ModerationFieldsEnum.DESCRIPTION,
        user_comment: DOMPurify.sanitize(text),
        locale: ELanguage.RU,
      },
    })
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {type Dispatch, type FC, type SetStateAction} from 'react';
import {useAppDispatch} from 'source/store';
import {setRequisitesLabel} from '../store';

const Item = styled.li`
  padding: 4px 8px;
  gap: 8px;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  p {
    transition: color 0.3s ease-in-out;
  }

  :hover {
    background-color: ${({theme}) => theme.palette.activeBackground};

    p {
      color: ${({theme}) => theme.palette.fontPrimary};
    }
  }
`;

interface ResultProperties {
  organizations: ExistingRequisitesItem[];
  setSelected: Dispatch<SetStateAction<string>>;
  onOrganizationClick: (organization: ExistingRequisitesItem) => void;
}

export const OrganizationsSearchResult: FC<ResultProperties> = ({
  organizations,
  setSelected,
  onOrganizationClick,
}) => {
  const {t} = useTranslation('requisites');
  const dispatch = useAppDispatch();

  const handleClick = (organization: ExistingRequisitesItem) => {
    onOrganizationClick(organization);
    setSelected(organization.name);
    dispatch(setRequisitesLabel(organization.name));
  };

  if (organizations.length === 0) {
    return null;
  }

  return (
    <>
      {organizations.map((organization) => (
        <Item key={organization.id} onClick={() => handleClick(organization)}>
          <Text size="S">{organization.name}</Text>
          <Text size="XS">{organization.type?.title}</Text>
          <Text size="XS">{t('list.inn', {inn: organization.inn})}</Text>
        </Item>
      ))}
    </>
  );
};

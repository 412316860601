import styled from '@emotion/styled';
import React, {FC} from 'react';
import PinInput from 'react-pin-input';

const PinInputWrapper = styled.div`
  .pincode-input-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;

    .pincode-input-text {
      background-color: ${({theme}) => theme.palette.border_3} !important;
      border-style: solid !important;
      border-width: 2px !important;
      border-radius: 6px;
      border-color: transparent !important;
      font-size: 16px;
      font-weight: 500;
      width: 100% !important;
    }

    .pincode-input-text:focus {
      border-color: ${({theme}) => theme.palette.outlineFocused} !important;
    }

    .pincode-input-text:disabled {
      cursor: not-allowed !important;
      opacity: 0.4;
    }
  }
`;

interface Props {
  length: number;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const MultiInputField: FC<Props> = ({length, onChange, disabled}) => {
  return (
    <PinInputWrapper>
      <PinInput
        disabled={disabled}
        length={length}
        type="numeric"
        onChange={onChange}
      />
    </PinInputWrapper>
  );
};

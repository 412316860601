export const MainImageIcon: React.FC<IconProps> = ({
  style,
  className,
  color,
}) => (
  <svg
    style={style}
    className={className}
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="17"
      cy="17"
      r="16"
      fill={color || '#FF5876'}
      stroke={color || '#FF5876'}
      strokeWidth="2"
    />
    <path
      d="M25.9088 14.1386C25.7975 13.7983 25.4922 13.5566 25.1309 13.5244L20.2243 13.084L18.2841 8.5946C18.141 8.26559 17.8152 8.05261 17.4532 8.05261C17.0912 8.05261 16.7654 8.26559 16.6223 8.59537L14.6821 13.084L9.77466 13.5244C9.41409 13.5574 9.10954 13.7983 8.99761 14.1386C8.88568 14.4789 8.98905 14.8521 9.26182 15.0874L12.9707 18.3029L11.877 23.0654C11.797 23.4156 11.9345 23.7776 12.2284 23.9876C12.3864 24.1004 12.5712 24.1579 12.7576 24.1579C12.9183 24.1579 13.0777 24.115 13.2207 24.0304L17.4532 21.5297L21.6841 24.0304C21.9937 24.2145 22.384 24.1978 22.6772 23.9876C22.9713 23.7769 23.1086 23.4148 23.0286 23.0654L21.9349 18.3029L25.6438 15.0881C25.9166 14.8521 26.0207 14.4795 25.9088 14.1386Z"
      fill="white"
    />
  </svg>
)

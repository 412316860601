import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import {FC} from 'react';

const outerRingColor = '#FFEBEB';
const innerRingColor = '#FF5876';

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const CircleLoader = styled.div<{
  size: number;
  outerRingWidth: number;
  innerRingWidth: number;
}>`
  position: relative;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;

  div {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: ${({size}) => size}px;
    height: ${({size}) => size}px;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-style: solid;
  }

  div:nth-of-type(1) {
    border-width: ${({outerRingWidth}) => outerRingWidth}px;
    border-color: ${outerRingColor} transparent transparent transparent;
    z-index: 2;
  }

  div:nth-of-type(2) {
    top: 4px;
    left: 4px;
    width: ${({size}) => size - 8}px;
    height: ${({size}) => size - 8}px;
    border-width: ${({innerRingWidth}) => innerRingWidth}px;
    border-color: ${innerRingColor} transparent transparent transparent;
    animation-delay: -0.3s;
    z-index: 3;
  }

  div:nth-of-type(3) {
    border-width: ${({outerRingWidth}) => outerRingWidth}px;
    border-color: ${outerRingColor} ${outerRingColor} ${outerRingColor}
      ${outerRingColor};
    animation-delay: -0.9s;
    z-index: 2;
  }
`;

const styles = {
  main: {
    outerRingWidth: 9,
    innerRingWidth: 3,
  },
  thin: {
    outerRingWidth: 5,
    innerRingWidth: 2,
  },
};

export type LoaderVariant = keyof typeof styles;

interface LoaderProps {
  size?: number;
  variant?: LoaderVariant;
  className?: string;
}

export const CircularLoader: FC<LoaderProps> = ({size, variant, className}) => {
  const {outerRingWidth, innerRingWidth} = variant
    ? styles[variant]
    : styles.main;
  return (
    <CircleLoader
      className={className}
      outerRingWidth={outerRingWidth}
      innerRingWidth={innerRingWidth}
      size={size || 64}
    >
      <div />
      <div />
      <div />
    </CircleLoader>
  );
};

import {keyframes} from '@emotion/react';

import {clone} from 'ramda';
import {UIEvent} from 'react';

import {Translate} from 'next-translate';
import {Option} from 'library/components/select';
import {isBoolean} from 'source/utilities/guards/types';
import {getBackendDateFormat} from 'source/utilities/dates';
import {
  SCROLL_CLASSNAME,
  DATES_SCROLL_CLASSNAME,
  HORIZONTAL_INFINITE_SCROLL_OFFSET,
  HIGHLIGHT_ROOMS_AVAILABILITY_NUMBER,
} from '../constants';
import {
  ELoadBeforeTranslateKey,
  ELoadMoreTranslateKey,
  MassEditModalFiltersType,
  RowsName,
  ScrollDirectionType,
  SelectedCell,
  UpdatedCellsType,
  UpdatedCellType,
} from '../types';

export const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const slideAnimation = keyframes`
  0% {
    transform: translatex(0);
  }
  50% {
    transform: translatex(10px);
  }
  100% {
    transform: translatex(0);
  }
`;

export const onHeaderScroll = (
  scrollLeft: number,
  calendarRef: HTMLDivElement | null,
): void => {
  if (!calendarRef) {
    return;
  }

  const scrollbars = calendarRef.querySelectorAll(`.${SCROLL_CLASSNAME}`);

  // eslint-disable-next-line unicorn/no-array-for-each, no-return-assign, no-param-reassign
  scrollbars.forEach((scrollbar) => (scrollbar.scrollLeft = scrollLeft));
};

export const handleAppropriateScroll = (
  eventTarget: EventTarget,
  isTouch: boolean,
  touchHandler: (scrollLeft: number) => void,
  desktopHandler: (scrollLeft: number) => void,
) => {
  if (!(eventTarget instanceof HTMLElement)) {
    return;
  }

  if (isTouch) {
    touchHandler(eventTarget.scrollLeft);
  } else {
    desktopHandler(eventTarget.scrollLeft);
  }
};

export const isHorizontalScrollEnd = (
  scroll: UIEvent<HTMLDivElement>,
): boolean => {
  const target = scroll.target as HTMLDivElement;

  return (
    target.scrollWidth - target.clientWidth <=
    target.scrollLeft + HORIZONTAL_INFINITE_SCROLL_OFFSET
  );
};

export const isHorizontalScrollStart = (
  scroll: UIEvent<HTMLDivElement>,
): boolean => {
  const target = scroll.target as HTMLDivElement;
  return target.scrollLeft === 0;
};

export const setInitialScrollsPosition = (
  calendarRef: HTMLDivElement | null,
): void => {
  if (!calendarRef) {
    return;
  }

  const datesScrollbar = calendarRef.querySelector(
    `.${DATES_SCROLL_CLASSNAME}`,
  );
  const scrollbars = calendarRef.querySelectorAll(`.${SCROLL_CLASSNAME}`);

  if (datesScrollbar) {
    // eslint-disable-next-line unicorn/no-array-for-each
    scrollbars.forEach(
      // eslint-disable-next-line no-param-reassign, no-return-assign
      (scrollbar) => (scrollbar.scrollLeft = datesScrollbar.scrollLeft),
    );
  }
};

export const isColumnSelected = (
  column: number,
  selectedCell: SelectedCell,
): boolean => column === selectedCell.column;

export const isRowSelected = (
  room: number,
  row: RowsName,
  selectedCell: SelectedCell,
): boolean => room === selectedCell.room && row === selectedCell.row;

export const isCellSelected = (
  room: number,
  column: number,
  row: RowsName,
  selectedCell: SelectedCell,
): boolean =>
  isColumnSelected(column, selectedCell) &&
  isRowSelected(room, row, selectedCell);

export const isRateRowSelected = (
  room: number,
  row: RowsName,
  rate: number,
  selectedCell: SelectedCell,
): boolean =>
  isRowSelected(room, row, selectedCell) && rate === selectedCell.rate;

export const isDateSelected = (
  date: string,
  selectedCell: SelectedCell,
): boolean => date === selectedCell.date;

export const isRateCellUpdated = (
  buildCell: SelectedCell,
  selectedCell: UpdatedCellType,
): boolean => {
  return (
    buildCell.room === selectedCell.room &&
    buildCell.rate === selectedCell.rate &&
    buildCell.date === selectedCell.date &&
    buildCell.guestNumber === selectedCell.guestNumber
  );
};

export const isRateCellSelected = (
  room: number,
  column: number,
  row: RowsName,
  rate: number,
  selectedCell: SelectedCell,
): boolean =>
  isColumnSelected(column, selectedCell) &&
  isRateRowSelected(room, row, rate, selectedCell);

export const isRateCellRangeExist = (
  fromColumn: SelectedCell['column'],
  toColumn: SelectedCell['column'],
  currentColumn: SelectedCell['column'],
  selectGuestNumber: number,
  currentGuestNumber: number,
  selectRate: number,
  cellRate: number,
): boolean =>
  fromColumn < currentColumn &&
  toColumn >= currentColumn &&
  selectGuestNumber === currentGuestNumber &&
  selectRate === cellRate;

export const buildResetedCells = (
  roomId: number,
  updatedCells: UpdatedCellsType,
): UpdatedCellsType => {
  const newUpdatedCells = {...updatedCells};
  delete newUpdatedCells[roomId];

  return newUpdatedCells;
};

export const buildNewSubmitCells = (
  previousCells: UpdatedCellsType,
  editedCells: UpdatedCellType[],
  room: number,
  rateId: number,
): UpdatedCellsType => {
  // Изменяет массив измененных ячеек - проверяет есть ли ячейка и изменяет её, если нет - добавляет
  const newUpdatedCells = clone(previousCells);

  if (!newUpdatedCells[room]) {
    newUpdatedCells[room] = [];
  }

  editedCells.forEach((element) => {
    const index = newUpdatedCells[room].findIndex((cell) => {
      return (
        element.guestNumber === cell.guestNumber &&
        rateId === cell.rate &&
        element.date === cell.date
      );
    });

    if (index === -1) {
      newUpdatedCells[room].push({
        ...element,
        rate: rateId,
        room,
      });
    } else {
      newUpdatedCells[room][index] = {
        ...newUpdatedCells[room][index],
        price: element.price,
      };
    }
  });

  return newUpdatedCells;
};

export const buildSubmitCellsMapByRate = (
  submitCells: UpdatedCellType[],
): UpdatedCellsType => {
  return submitCells.reduce(
    (accumulator: {[key: string]: UpdatedCellType[]}, item) => {
      const itemRate = String(item.rate);

      if (!accumulator[itemRate]) {
        accumulator[itemRate] = [];
      }

      accumulator[itemRate] = [...accumulator[itemRate], item];

      return accumulator;
    },
    {},
  );
};

export const getLoadButtonTextKey = (
  isLimitExceeded: boolean,
  isNoEditedRooms: boolean,
  direction: ScrollDirectionType,
): ELoadMoreTranslateKey | ELoadBeforeTranslateKey => {
  const AppropriateTranslateKeyEnum =
    direction === ScrollDirectionType.LEFT
      ? ELoadBeforeTranslateKey
      : ELoadMoreTranslateKey;

  if (isLimitExceeded) {
    return AppropriateTranslateKeyEnum.LIMIT_EXCEEDED;
  }

  if (isNoEditedRooms) {
    return AppropriateTranslateKeyEnum.SCROLL_OR_PRESS;
  }

  return AppropriateTranslateKeyEnum.EDIT_OR_CANCEL;
};

export const getConstraintLabelKey = (
  t: Translate,
  hasConstraint: boolean,
  stay?: CalendarRatesConstraintsStay,
) => {
  if (!hasConstraint) {
    return t('calendar:constraint_date_no');
  }

  if (stay?.day?.min_days) {
    return t('components:days', {
      count: stay?.day?.min_days,
    });
  }

  return t('calendar:constraint_date_exist');
};

export const getIntegrationConstraintLabelKey = (
  t: Translate,
  hasBnovoIntegration: boolean,
  hasRealtyIntegration: boolean,
  bnovoConstraint: CalendarBnovoConstraint | null,
  realtyConstraint: CalendarRealtyConstraint | null,
): string => {
  if (hasBnovoIntegration) {
    return t(
      bnovoConstraint
        ? 'calendar:constraint_date_exist'
        : 'calendar:constraint_date_no',
    );
  }

  if (hasRealtyIntegration) {
    return t(
      realtyConstraint
        ? 'calendar:constraint_date_exist'
        : 'calendar:constraint_date_no',
    );
  }

  return t('calendar:constraint_date_no');
};

export const shouldDisableRestrictionButton = (
  hasBnovoIntegration: boolean,
  hasRealtyIntegration: boolean,
  hasConstraint: boolean,
  bnovoConstraint: CalendarBnovoConstraint | null,
  realtyConstraint: CalendarRealtyConstraint | null,
) => {
  if (hasBnovoIntegration) {
    return !bnovoConstraint;
  }

  if (hasRealtyIntegration) {
    return !realtyConstraint;
  }

  return !hasConstraint;
};

export const getGuestNumberFilterValues = (
  guestNumbers: MassEditModalFiltersType['guestNumber'],
): Option[] => {
  if (!Array.isArray(guestNumbers)) {
    return [];
  }

  return guestNumbers.map((guestNumber) => {
    return {label: `${guestNumber}`, value: guestNumber};
  });
};

export const checkHasAvailableRooms = (
  availableRoomsNumber: number,
  customRoomsAvailability: number,
  hasFree: CalendarDateAvailabilities[number]['has_free'],
) => {
  // on Realty integration
  if (isBoolean(hasFree)) {
    return hasFree;
  }

  if (customRoomsAvailability > 0) {
    return customRoomsAvailability > HIGHLIGHT_ROOMS_AVAILABILITY_NUMBER;
  }

  return availableRoomsNumber > HIGHLIGHT_ROOMS_AVAILABILITY_NUMBER;
};

export const buildDateCellId = (date: Date, withHashtag?: boolean): string =>
  `${withHashtag ? '#' : ''}date-cell_${getBackendDateFormat(date)}`;

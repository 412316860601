import styled from '@emotion/styled';
import {FC} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {NavigationLink} from 'library/components/navigation-link';
import {BurgerMobile, BurgerTablet, Logo} from '../../ui';
import {SupportPopup} from '../../popups/support';

const anchors: Anchor[] = [
  {
    title: 'registration',
    href: '/registration',
  },
  {
    title: 'authorization',
    href: '/authorization',
  },
];

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BurgerNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 12px;
`;

const PopupButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const UnauthorizedScenario: FC<{mobile: boolean; tablet: boolean}> = ({
  mobile,
  tablet,
}) => {
  const {t} = useTranslation('routing');

  const navigation = anchors.map(({title, href}) => (
    <NavigationLink key={title} href={href}>
      {t(title)}
    </NavigationLink>
  ));

  if (mobile) {
    return (
      <StyledContent>
        <Logo />
        <PopupButtonsWrapper>
          <SupportPopup />
          <BurgerMobile>
            <BurgerNavigation>{navigation}</BurgerNavigation>
          </BurgerMobile>
        </PopupButtonsWrapper>
      </StyledContent>
    );
  }

  if (tablet) {
    return (
      <StyledContent>
        <Logo />
        <PopupButtonsWrapper>
          <SupportPopup />
          <BurgerTablet>
            <BurgerNavigation>{navigation}</BurgerNavigation>
          </BurgerTablet>
        </PopupButtonsWrapper>
      </StyledContent>
    );
  }

  return (
    <StyledContent>
      <Logo />
      <PopupButtonsWrapper>
        <SupportPopup />
        <Navigation>{navigation}</Navigation>
      </PopupButtonsWrapper>
    </StyledContent>
  );
};

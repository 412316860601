import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';

export interface StoreBalance {
  amount: number;
  amountHold: number;
}

interface CommonStore {
  balance: StoreBalance | null;
}

const initialState: CommonStore = {
  balance: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setBalance: (state, action: PayloadAction<StoreBalance | null>) => {
      state.balance = action.payload;
    },
  },
});

export const selectBalance = (state: RootState) => {
  return state.common.balance;
};

export const {setBalance, reset} = commonSlice.actions;

export default commonSlice.reducer;

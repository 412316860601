import {differenceInDays} from 'date-fns';
import {Translate, TranslationQuery} from 'next-translate';
import {DEFAULT_RATE_NAME_IN_BOOKING_DETAILS} from 'slices/booking/lib/constants';
import {GuestType} from 'slices/booking/lib/types';
import {getViewMonthsNameFromBackendDate} from 'source/utilities/dates';
import {transliterate} from 'source/utilities/strings';

export const getHeaderTranslate = (
  t: Translate,
  key: string,
  parameters?: TranslationQuery,
) => t(`header_information.${key}`, parameters);

export const getHeaderLabeledTranslate = (t: Translate, key: string) =>
  getHeaderTranslate(t, `labeled.${key}`);

export const getHeaderTableHeadTranslate = (t: Translate, key: string) =>
  getHeaderTranslate(t, `table_head.${key}`);

export const getInformationAboutCheckDate = (
  checkin: string,
  checkout: string,
  translate: Translate,
) => {
  const from = getViewMonthsNameFromBackendDate(checkin);

  const to = getViewMonthsNameFromBackendDate(checkout);

  const nightCount = differenceInDays(new Date(checkout), new Date(checkin));

  return `${from} - ${to}, ${translate('card_information.night', {
    count: nightCount,
  })}`;
};

export const getMainGuestInformation = (guests: BookingGuests) => {
  const mainGuest =
    guests.find((guest) => guest.type.value === GuestType.MAIN) || guests[0];

  return `# ${transliterate(
    `${mainGuest.first_name} ${mainGuest.last_name}`,
  ).toUpperCase()} - (${mainGuest?.type.title})`;
};

export const getGuestDetailInformation = (
  sequence: number,
  guest: BookingGuest,
) =>
  `#${sequence} ${transliterate(
    `${guest.first_name} ${guest.last_name}`,
  ).toUpperCase()} - ${guest.type.title}`;

export const parseBookingObject = (booking: Booking) => {
  return {
    roomName: booking.room_name,
    guests: booking.guests,
    hotelName: booking.hotel.name,
    checkin: booking.checkin,
    checkout: booking.checkout,
    currency: booking.currency,
    prices: booking.our_booking?.prices || null,
    vat: booking.our_booking?.vat,
    commission: booking.our_booking?.commission_amount || null,
    createdAt: booking.created_at,
    freeCancellationBefore: booking.free_cancellation_before,
    cancellationPolicy: booking.our_booking?.cancellation_policy || null,
    amount: booking.amount,
    amountOnSpot: booking.our_booking?.amount_on_spot || null,
    paymentMethod: booking.payment_type,
    status: booking.status,
    hotelierStatus: booking.our_booking?.hotelier_status || null,
    hotelierComment: booking.our_booking?.hotelier_status_comment || null,
    paymentVerification: booking.payment_verification,
    ourBooking: booking.our_booking,
    rateName:
      booking.our_booking?.rate_name || DEFAULT_RATE_NAME_IN_BOOKING_DETAILS,
  };
};

import {ERequiredTabs} from './types';

export const REQUIRED_TABS = [
  ERequiredTabs.CHECK_IN_OUT,
  ERequiredTabs.PAYMENTS,
  ERequiredTabs.WITH_ANIMALS,
];

export const INITIAL_CALCULATION_FROM_DAYS = 1;
export const INITIAL_TAX_AMOUNT = 0;
export const MAX_CALCULATION_FROM_DAYS = 255;
export const MAX_TAX_AMOUNT = 10000000;
export const END_OF_THE_DAY = '00:00';
export const INITIAL_TIME_OPTION_INDEX = 12;
export const POLICIES_BACKEND_FORMAT = 'HH:mm';

import {FC, createContext, useState} from 'react';

import {LegalFormEnum} from 'source/utilities/business';

export type RequisitesState = State<RequisitesFormData>;

export const initialState = {
  id: -1,
  type: LegalFormEnum.IndividualEntrepreneur,
  name: '',
  account: '',
  inn: '',
  bik: '',
  address: '',
  kpp: '',
};

export const RequisitesContext = createContext<RequisitesState>([
  initialState,
  () => {},
]);

export const RequisitesProvider: FC<
  ServerlessProviderProperties<RequisitesState>
> = ({...properties}) => {
  const state = useState<ContextValue<RequisitesState>>(initialState);

  return <RequisitesContext.Provider value={state} {...properties} />;
};

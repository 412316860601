import styled from '@emotion/styled';
import {Calendar, CalendarProps} from 'library/components/calendar';
import {FC, useState} from 'react';
import Popup from 'reactjs-popup';
import {Icon} from 'source/components/icon';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Input} from '../input';

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledInput = styled(Input)`
  & input {
    user-select: none;
    pointer-events: none;
    padding-right: 30px;
  }
`;

const CalendarIconWrapper = styled.div`
  transition: 300ms;
  position: absolute;
  right: 12px;
  top: 35%;
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: fit-content !important;
    padding: 8px 10px;
    background: ${({theme}) => theme.palette.defaultBackground};
    border: ${({theme}) => theme.palette.border};
    box-shadow: ${({theme}) => theme.palette.boxShadow} !important;
    border-radius: 6px !important;
    box-sizing: border-box;
    color: ${({theme}) => theme.palette.fontDefault};
  }
`;

interface DateInputProps {
  value: string;
  setValue: CalendarProps['setDates'];
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

export const DateInput: FC<DateInputProps> = ({
  value,
  setValue,
  placeholder,
  disabled,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const mobile = useDeviceDetection('mobile');
  return (
    <Wrapper className={className}>
      <StyledPopup
        arrow={false}
        position="bottom center"
        modal={mobile}
        defaultOpen={false}
        offsetY={10}
        closeOnDocumentClick
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          setIsOpen(false);
        }}
        trigger={
          <InputWrapper
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!disabled) {
                setIsOpen(true);
              }
            }}
          >
            <StyledInput
              placeholder={placeholder}
              label=""
              type="text"
              disabled={disabled}
              cursor="pointer"
              autoComplete="off"
              value={value}
              onChange={() => {}}
            />
            <CalendarIconWrapper>
              <Icon name="calendar" width={22} height={22} />
            </CalendarIconWrapper>
          </InputWrapper>
        }
      >
        <Calendar
          setDates={setValue}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </StyledPopup>
    </Wrapper>
  );
};

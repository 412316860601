import {FC} from 'react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';

import {Text} from 'library/components/text';

// 13.11 пока скрыто
// const anchors = [
//   {
//     title: 'verified-hotels',
//     href: '/verified-hotels',
//   },
// ];

const Wrapper = styled.footer`
  width: 100%;
  height: fit-content;
  align-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px 20px 20px 20px;
`;

export const Footer: FC = () => {
  const {t} = useTranslation('routing');

  return (
    <Wrapper>
      {/* 
      // 13.11 пока скрыто
      {anchors.map((anchor) => {
        return (
          <NavigationLink key={anchor.title} href={anchor.href}>
            {t(anchor.title)}
          </NavigationLink>
        );
      })} */}

      <Text size="M">{t('copyright', {year: new Date().getFullYear()})}</Text>
    </Wrapper>
  );
};

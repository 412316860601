import {useTheme} from '@emotion/react';
import {Dispatch, FC, SetStateAction, memo} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {LabeledComponent} from 'library/components/labeled-component';
import {Select} from 'library/components/select';

import {useAppDispatch} from 'source/store';
import {UNDEFINED_CATEGORY} from '../../lib/constants';
import {setCategory} from '../../store';
import {
  getLabelTranslate,
  getSubCategoriesOptions,
  getSubCategoryOption,
} from '../../lib/helpers';
import {ClientRoomCategory, RoomCategories} from '../../lib/types';

interface IProps {
  translateKey: string;
  roomCategory: ClientRoomCategory;
  setRoomCategory: Dispatch<SetStateAction<ClientRoomCategory>>;
  possibleSubCategories: RoomCategories;
  disabled?: boolean;
}

export const SubCategorySelect: FC<IProps> = ({
  roomCategory,
  translateKey,
  setRoomCategory,
  possibleSubCategories,
  disabled = false,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {t} = useTranslation(translateKey);
  const subCategoryOptions = getSubCategoriesOptions(possibleSubCategories);

  return (
    <div>
      {roomCategory.subCategory === UNDEFINED_CATEGORY ||
      !roomCategory.subCategory ? null : (
        <LabeledComponent
          color={theme.palette.fontSecondary}
          size="S"
          gap={10}
          text={getLabelTranslate(t, 'subcategory')}
        >
          <Select
            value={getSubCategoryOption(
              roomCategory.subCategory,
              possibleSubCategories,
            )}
            options={subCategoryOptions}
            setValue={(option) => {
              dispatch(setCategory(option.value));
              setRoomCategory({...roomCategory, subCategory: option.value});
            }}
            disabled={disabled}
          />
        </LabeledComponent>
      )}
    </div>
  );
};

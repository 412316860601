import styled from '@emotion/styled';
import {FC, useEffect, useRef} from 'react';

import {RoomCategories, RoomContent} from 'slices/add-room';
import {
  DirectoriesBedTypesListResponse,
  DirectoriesRoomFacilityGroupsListResponse,
  HotelsRealtyIntegrationsCreateRequest,
} from 'types/api-scheme';

const Wrapper = styled.div``;

const StyledRoomContent = styled(RoomContent)`
  margin: 40px 0;
`;

interface IProps {
  roomCategories?: RoomCategories;
  bedTypes?: DirectoriesBedTypesListResponse['data'];
  facilities?: DirectoriesRoomFacilityGroupsListResponse['data'];
  room: Room | null;
  onSave: (room: HotelsRealtyIntegrationsCreateRequest) => void;
  loading: boolean;
  realtyIntegrations?: RealtyIntegration[];
}

export const RealtyAddNewRoomIntegrations: FC<IProps> = ({
  roomCategories,
  bedTypes,
  facilities,
  room,
  onSave,
  loading,
  realtyIntegrations = [],
}) => {
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (formRef?.current && room) {
      formRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, [room]);

  return (
    <Wrapper ref={formRef}>
      <StyledRoomContent
        roomCategories={roomCategories}
        bedTypes={bedTypes}
        facilities={facilities}
        room={room || undefined}
        translateKey="integration"
        submitButtonLabel="room.add_room"
        onSave={onSave}
        withouTitle
        parentLoading={loading}
        isIntegrationPage
        type="add-room"
        disableScrollOnOpen={realtyIntegrations.length > 0}
      />
    </Wrapper>
  );
};

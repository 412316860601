import React, {useContext, useEffect, useMemo, useState} from 'react';
import type {CSSProperties, PropsWithChildren} from 'react';
import styled from '@emotion/styled';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {HotelContext} from 'source/context/hotel';
import {useSelector} from 'react-redux';
import {selectRooms} from 'slices/rooms/store';
import {Dispatcher} from 'types/utilities';
// import {useTravelLineIntegration} from 'source/utilities/hooks/use-is-travel-line-integration';
import {ImageCard} from './image-card';
import {SortableItemContext} from './drag-button';

const SortableWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  background-color: ${({theme}) => theme.palette.defaultBackground};
  box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
    0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box;
  list-style: none;
  color: ${({theme}) => theme.palette.primaryCircuit};
  font-weight: 400;
  font-size: 1rem;
  font-family: sans-serif;
`;

interface Props {
  id: number;
  roomId?: number;
  isMainImage: (imageId: number, roomId?: number) => boolean;
  image: Image;
  isSelected: boolean;
  onImageSelect: () => void;
  onMainImageChange: (imageId: number, url: string) => Promise<void>;
  onDeleteComplete: (imageId: number) => void;
  onDeleteImage: (
    imageId: number,
    onComplete: () => void,
    setLoading: Dispatcher<boolean>
  ) => void;
}

export const SortableImageMobile = ({
  children,
  id,
  roomId,
  isMainImage,
  image,
  isSelected,
  onImageSelect,
  onMainImageChange,
  onDeleteComplete,
  onDeleteImage,
}: PropsWithChildren<Props>) => {
  const [loading, setLoading] = useState(false);
  const [hotel] = useContext(HotelContext);
  // const [hasTravelLineIntegration] = useTravelLineIntegration();
  const rooms = useSelector(selectRooms);
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({id});
  const [isMain, setIsMain] = useState(false);

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const setMainImage = () => {
    if (image.id) {
      setLoading(true);
      onMainImageChange(image.id, image.url).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setIsMain(isMainImage(id, roomId));
  }, [rooms, hotel?.main_image?.id]);

  const onDelete = (imageId: number) => {
    const onComplete = () => {
      onDeleteComplete(imageId);
    };
    if (hotel?.id) {
      onDeleteImage(image.id, onComplete, setLoading);
    }
  };

  return (
    <SortableItemContext.Provider value={context}>
      <SortableWrapper ref={setNodeRef} style={style}>
        <ImageCard
          // TODO: временно отключено
          // disabled={hasTravelLineIntegration}
          image={image}
          loading={loading}
          isSelected={isSelected}
          isMainImage={isMain}
          setMainImage={setMainImage}
          onDeleteImage={onDelete}
          onImageSelect={onImageSelect}
        />
        {children}
      </SortableWrapper>
    </SortableItemContext.Provider>
  );
};

import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';
import {useContext} from 'react';
import {Icon} from 'source/components/icon';
import {HotelContext} from 'source/context/hotel';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({theme}) => theme.palette.fontSecondary};
  max-width: 210px;
`;

const InformationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

const IconWrapper = styled.div``;

const HotelButton = () => {
  const [hotel] = useContext(HotelContext);

  return (
    <StyledButton>
      <Tooltip
        on="hover"
        position="bottom left"
        trigger={
          <InformationWrapper>
            <StyledText size="S">{hotel?.name || ''}</StyledText>
            <StyledText size="S">{`ID: (${hotel?.id || ''})`}</StyledText>
          </InformationWrapper>
        }
      >
        {hotel?.name || ''}
      </Tooltip>
      <IconWrapper>
        <Icon name="user-icon" width={30} height={30} />
      </IconWrapper>
    </StyledButton>
  );
};

export default HotelButton;

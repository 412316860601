import {FC} from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import {Text} from 'library/components/text';
import {Button} from 'library/components/button';
import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: 14px;
`;

const TooltipRow = styled.div`
  word-break: break-word;
`;

const StyledDeleteButton = styled(Button)`
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
`;

const StyledText = styled(Text)`
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px 10px;
  border: ${({theme}) => `1px solid ${theme.palette.border}`};
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: transparent;
  color: ${({theme}) => theme.palette.fontPrimary};
  padding: 0px;
  border: 0px;
`;

interface IProps {
  title: string;
  constraint: ClientCalendarRoomConstraints[number];
  onDelete: () => void;
  onClose: () => void;
}
const RoomConstraintTooltipContent: FC<IProps> = ({
  title,
  constraint,
  onDelete,
  onClose,
}) => {
  const {t} = useTranslation('calendar');

  return (
    <ContentWrapper>
      <CloseButton onClick={onClose} hoverOpacity>
        <CloseIcon />
      </CloseButton>
      <TooltipRow>
        {t('calendar:room_range.tooltip.reason', {
          reason: title.toLocaleLowerCase(),
        })}
      </TooltipRow>
      <TooltipRow>
        {t('calendar:room_range.tooltip.rooms_count', {
          count:
            constraint.rooms_closed_number ||
            t('calendar:room_range.tooltip.all'),
        })}
      </TooltipRow>
      <TooltipRow>
        {t('calendar:room_range.tooltip.from_to', {
          from: constraint.from,
          to: constraint.to,
        })}
      </TooltipRow>
      {constraint.comment ? (
        <TooltipRow>
          {t('calendar:room_range.tooltip.comment', {
            text: constraint.comment,
          })}
        </TooltipRow>
      ) : null}
      {constraint.id ? (
        <StyledDeleteButton onClick={onDelete} hoverOpacity>
          {t('delete')}
        </StyledDeleteButton>
      ) : (
        <StyledText size="XS">
          {t('calendar:room_range.tooltip.delete_unsaved_constraint')}
        </StyledText>
      )}
    </ContentWrapper>
  );
};

export default RoomConstraintTooltipContent;

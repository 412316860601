import {css} from '@emotion/react';

export const styles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'Noto Sans Mono';
    font-weight: 600;
    src: url('/fonts/noto/bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Noto Sans Mono';
    font-weight: 400;
    src: url('/fonts/noto/medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Noto Sans Mono';
    font-weight: 300;
    src: url('/fonts/noto/light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Noto Sans Mono';
    font-weight: 300;
    src: url('/fonts/noto/regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Geometria';
    font-weight: 600;
    src: url('/fonts/geometria/bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Geometria';
    font-weight: 400;
    src: url('/fonts/geometria/medium.woff') format('woff');
  }

  @font-face {
    font-family: 'Geometria';
    font-weight: 300;
    src: url('/fonts/geometria/light.woff') format('woff');
  }

  @font-face {
    font-family: 'Oswald';
    font-weight: 500;
    src: url('/fonts/oswald/medium.ttf');
  }

  @font-face {
    font-family: 'Oswald';
    font-weight: 400;
    src: url('/fonts/oswald/regular.ttf');
  }

  html {
    font-family: 'Geometria', monospace;
  }

  html,
  body,
  #__next {
    min-height: 100vh;
  }

  body {
    overflow-x: hidden !important;
  }

  * {
    outline: none;
  }

  #__next {
    display: grid;
    grid-template-rows: min-content;
  }

  .Toastify {
    position: absolute;
  }

  img {
    width: 100%;
    max-width: 100%;
    background: #f5f6f8;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const palette = {
  defaultBackground: '#FFFFFF',
  primaryBackground: '#FFFFFF',
  darkerBackground: '#F8F9FB',
  activeBackground: '#ebebeb',
  backgroundDanger: '#FFEBEB',
  notAvailable: '#cccccc',
  matterhorn: '#4e4e4e',
  primaryCircuit: '#888888',
  secondaryBackground: '#cfc4c2',
  modalBackground: 'rgba(246,246,246,0.9)',
  modalFilledBackground: 'rgba(246,246,246)',
  modalMobileBackground: 'rgba(0, 0, 0, .2)',
  action: '#1982C4',
  borderBlue: '#0057D2',
  primaryAccent: '#FF5876',
  primaryAccentDark: '#d6576e',
  primaryAccentDarker: '#a25462',
  success: '#1e9e1e',
  wating: '#ae7d0a',
  yellow: '#fff176',
  fontInfo: 'rgb(30, 158, 30)',
  fontDefault: '#5B5B6B',
  fontSecondary: '#9292AA',
  fontInvert: '#E6E6E6',
  fontDanger: '#FF4949',
  fontAccent: '#FFFFFF',
  fontPrimary: '#FF5876',
  fontAvailable: '#000000',
  outlineHover: '#ff587633',
  border: '#EBEBEB',
  border_2: '#F0F0F0',
  border_3: '#F3F3F3',
  border_4: '#FF5876',
  border_5: '#F6F6F6',
  danger: '#FA4B46',
  outlineFocused: '#ff58767d',
  boxShadow: '0px 4px 20px rgba(220, 220, 220, 0.7)',
  cardShadowColor: 'rgba(220, 220, 220, 0.4)',
  hoverBackground: 'rgba(0, 0, 0, 0.03)',
  hoverBackground_08: 'rgba(0, 0, 0, 0.08)',
  loadingBackground: 'rgba(255, 255, 255, 0.7)',
  lightGreen: '#6cf57c',
  availableCell: '#ADD582',
  notAvailableCell: '#E0E0E0',
  dragBackground: '#4d4b4b5e',
  scrollTopButton: '#ee6378',
};

export const content = {
  bound: '1440px',
};

export const paddings = {
  xl: '75px',
  large: '30px',
  medium: '26px',
  main: '20px',
  subMain: '16px',
  half: '12px',
  mainAndHalf: '24px',
  double: '32px',
  quarter: '8px',
};

export const paddingsMobile = {
  xl: '75px',
  large: '20px',
  medium: '15px',
  main: '20px',
  subMain: '16px',
  mainAndHalf: '24px',
  half: '12px',
  quarter: '8px',
  double: '32px',
};

const font = {
  heading1: '44px',
  heading2: '26px',
  main: '20px',
  small: '16px',
};

export const fontMobile: typeof font = {
  heading1: '20px',
  heading2: '20px',
  main: '16px',
  small: '14px',
};

type FontFamilyKey = 'OSWALD' | 'GEOMETRICA_MEDIUM' | 'NOTO_SANS_MONO';

const fontFamily: {[key in FontFamilyKey]: string} = {
  OSWALD: 'Oswald, sans-serif',
  GEOMETRICA_MEDIUM: 'Geometria-Medium',
  NOTO_SANS_MONO: 'Noto Sans Mono',
};
export type PaddingKeys =
  | 'large'
  | 'medium'
  | 'main'
  | 'half'
  | 'quarter'
  | 'subMain'
  | 'double'
  | 'xl';
export type FontKeys = keyof typeof font;
export interface ITheme {
  palette: typeof palette;
  content: typeof content;
  paddings: typeof paddings;
  font: typeof font;
  fontFamily: typeof fontFamily;
}

export const theme: ITheme = {paddings, content, font, palette, fontFamily};

import styled from '@emotion/styled';
import {BalanceInformation} from 'slices/balance/ui/balance-information';
import {BalanceOrganizationsTable} from 'slices/balance/ui/balance-organizations-table';
import {BalanceWithdrawal} from 'slices/balance/ui/balance-withdrawal';
import {LoadMoreButton} from './ui/load-more-button';

const Wrapper = styled.div`
  margin-top: 40px;
`;

export const Content = () => {
  return (
    <Wrapper>
      <BalanceInformation />
      <BalanceWithdrawal />
      <BalanceOrganizationsTable />
      <LoadMoreButton />
    </Wrapper>
  );
};

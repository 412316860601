import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';
import {LegalFormEnum} from 'source/utilities/business';
import {INITIAL_REQUISITES_ID, initialRequisites} from './lib/constants';

interface IRequisitesState {
  requisites: Requisites;
  requisitesLabel: string;
  existingRequisites?: ExistingRequisites | null;
  file: Blob | null;
  isLoading: boolean;
  legalForm: number;
  uploadingRequisites: boolean;
  requisitesExists: boolean;
}

const initialState: IRequisitesState = {
  requisites: initialRequisites,
  requisitesLabel: '',
  existingRequisites: null,
  file: null,
  isLoading: true,
  legalForm: LegalFormEnum.IndividualEntrepreneur,
  uploadingRequisites: false,
  requisitesExists: false,
};

export const requisitesSlice = createSlice({
  name: 'requisites',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setRequisitesLabel: (state, action: PayloadAction<string>) => {
      state.requisitesLabel = action.payload;
    },
    setRequisites: (
      state,
      action: PayloadAction<IRequisitesState['requisites']>,
    ) => {
      state.requisites = action.payload;
    },
    setExistingRequisites: (
      state,
      action: PayloadAction<IRequisitesState['existingRequisites']>,
    ) => {
      state.existingRequisites = action.payload;
    },
    setRequisitesExists: (
      state,
      action: PayloadAction<IRequisitesState['requisitesExists']>,
    ) => {
      state.requisitesExists = action.payload;
    },
    setLegalForm: (
      state,
      action: PayloadAction<IRequisitesState['legalForm']>,
    ) => {
      state.legalForm = action.payload;
    },
    setFile: (state, action: PayloadAction<IRequisitesState['file']>) => {
      state.file = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<IRequisitesState['isLoading']>,
    ) => {
      state.isLoading = action.payload;
    },
    setUploadingRequisites: (
      state,
      action: PayloadAction<IRequisitesState['uploadingRequisites']>,
    ) => {
      state.uploadingRequisites = action.payload;
    },
    setVerificationPaymentURL: (
      state,
      action: PayloadAction<
        Required<Requisites>['verification_payment']['payment_url']
      >,
    ) => {
      const verificationPayment = state.requisites.verification_payment;
      if (verificationPayment) {
        verificationPayment.payment_url = action.payload;
      }
    },
  },
});

export const {
  reset,
  setRequisites,
  setExistingRequisites,
  setRequisitesLabel,
  setLegalForm,
  setRequisitesExists,
  setFile,
  setLoading,
  setVerificationPaymentURL,
  setUploadingRequisites,
} = requisitesSlice.actions;

export const selectExistingRequisitesInfo = (state: RootState) => {
  return {
    requisitesExist: state.requisites.requisitesExists,
    isRequisitesChosen:
      state.requisites.requisites.id !== INITIAL_REQUISITES_ID,
    uploadingRequisites: state.requisites.uploadingRequisites,
    requisitesLabel: state.requisites.requisitesLabel,
  };
};

export const selectRequisitesFormInfo = (state: RootState) => {
  return {
    requisites: state.requisites.requisites,
    requisitesExists: state.requisites.requisitesExists,
    isRequisitesChosen:
      state.requisites.requisites.id !== INITIAL_REQUISITES_ID,
    legalForm: state.requisites.legalForm,
    uploadingRequisites: state.requisites.uploadingRequisites,
  };
};

export const selectInvoiceInfo = (state: RootState) => {
  return {
    requisites: state.requisites.requisites,
    file: state.requisites.file,
    isLoading: state.requisites.isLoading,
    requisitesExists: state.requisites.requisitesExists,
    legalForm: state.requisites.legalForm,
  };
};

export const selectVerificationPayment = (state: RootState) => {
  return {
    verification_payment: state.requisites.requisites.verification_payment,
    requisitesExists: state.requisites.requisitesExists,
  };
};

export default requisitesSlice.reducer;

import {DEFAULT_DAYS_VALUE} from 'slices/rate/lib/constants';
import {EDaysOfWeek, EUnitRateType, RateConstraintsStay} from './types';

export const MOCK_RATE_CONSTRAINS_STAY: Exclude<
  RateConstraintsStay,
  undefined
> = {
  id: -1,
  day: {
    id: -1,
    created_at: '',
    updated_at: '',
    max_days: DEFAULT_DAYS_VALUE,
    min_days: DEFAULT_DAYS_VALUE,
    days_of_week: [],
  },
  type: {
    value: 1,
    title: '',
  },
  periods: [],
  created_at: '',
  updated_at: '',
};

export const MOCK_RATE_CONSTRAINS_OFFER = {
  id: -1,
  day: {
    id: 1,
    created_at: '',
    updated_at: '',
    max_days: DEFAULT_DAYS_VALUE,
    min_days: DEFAULT_DAYS_VALUE,
    days_of_week: [],
  },
  type: {
    value: 2,
    title: '',
  },
  periods: [],
  created_at: '',
  updated_at: '',
};

export const LIMITATIONS_DAYS_OF_WEEK = [
  {
    title: `limitation.abbreviated_days.${EDaysOfWeek.Monday}`,
    value: EDaysOfWeek.Monday,
  },
  {
    title: `limitation.abbreviated_days.${EDaysOfWeek.Tuesday}`,
    value: EDaysOfWeek.Tuesday,
  },
  {
    title: `limitation.abbreviated_days.${EDaysOfWeek.Wednesday}`,
    value: EDaysOfWeek.Wednesday,
  },
  {
    title: `limitation.abbreviated_days.${EDaysOfWeek.Thursday}`,
    value: EDaysOfWeek.Thursday,
  },
  {
    title: `limitation.abbreviated_days.${EDaysOfWeek.Friday}`,
    value: EDaysOfWeek.Friday,
  },
  {
    title: `limitation.abbreviated_days.${EDaysOfWeek.Saturday}`,
    value: EDaysOfWeek.Saturday,
  },
  {
    title: `limitation.abbreviated_days.${EDaysOfWeek.Sunday}`,
    value: EDaysOfWeek.Sunday,
  },
];

export const CONDITIONS_PAYMENTS_METHODS_MOCK = [
  {
    value: EUnitRateType.Currency,
    label: 'rate:common.money',
  },
  {
    value: EUnitRateType.Percent,
    label: 'rate:common.percent_of_stay',
  },
  // TODO - вернуть в случае если понадобится добавить выбор количества ночей, для штрафа
  // {
  //   value: EUnitRateType.Night,
  //   label: 'rate:common.night',
  // },
];

export const RATE_INITIAL_STATE: Rate = {
  id: -1,
  name: '',
  room_ids: [],
  cancellation_type: {value: 1, title: ''},
  constraints: {
    stay: {
      id: -1,
      day: {
        id: -1,
        max_days: DEFAULT_DAYS_VALUE,
        min_days: DEFAULT_DAYS_VALUE,
        created_at: '',
        updated_at: '',
        days_of_week: [],
      },
      created_at: '',
      updated_at: '',
      periods: [],
      type: {
        value: 1,
        title: '',
      },
    },
    offer: {
      id: -1,
      day: {
        id: -1,
        max_days: -1,
        min_days: -1,
        created_at: '',
        updated_at: '',
        days_of_week: [],
      },
      created_at: '',
      updated_at: '',
      periods: [],
      type: {
        value: 1,
        title: '',
      },
    },
  },
  created_at: '',
  updated_at: '',
  period: {},
  payment_settings: {
    required_prepayment_unit_type: {
      value: CONDITIONS_PAYMENTS_METHODS_MOCK[0].value,
      title: CONDITIONS_PAYMENTS_METHODS_MOCK[0].label,
    },
    created_at: '',
    updated_at: '',
    id: -1,
    required_prepayment_amount: 0,
    required_card_warranty: false,
  },
  cancellation_fine: {
    id: -1,
    days: 1,
    created_at: '',
    type: 1,
    updated_at: '',
    value: 1000,
    unit_type: {
      value: EUnitRateType.Currency,
      title: '',
    },
  },
  parent_id: null,
  enabled: false,
  untimely_check_percents: {
    checkin: {
      id: -1,
      created_at: '',
      updated_at: '',
      percent: -1,
      type: {
        value: 1,
        title: '',
      },
      calculation_type: {
        value: 1,
        title: '',
      },
    },
    checkout: {
      id: -1,
      created_at: '',
      updated_at: '',
      percent: -1,
      type: {
        value: 1,
        title: '',
      },
      calculation_type: {
        value: 1,
        title: '',
      },
    },
  },
  untimely_check_price_type: {
    value: 1,
    title: '',
  },
  constraint_exclude_rooms: [],
  parent_constraints_type: {
    value: 1,
    title: '',
  },
  discount: {
    id: -1,
    created_at: '',
    updated_at: '',
    type: {
      value: 1,
      title: '',
    },
    values: [],
  },
  extra_guests_prices: [],
  prices: [],
  services: {
    meal: {
      hotel_service_id: -1,
      price_type: 1,
    },
    others: [],
  },
};

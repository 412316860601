import {request} from 'library/api';
import {path} from 'ramda';
import {url} from 'source/utilities/network/url';
import {clientHeaders} from 'source/utilities/network/http';
import {getQueryParameterFromBoolean} from 'source/utilities/network/router';
import {
  HotelsServicesCreateResponse,
  HotelsServicesDetailResponse,
  HotelsServicesCreateRequest,
} from 'types/api-scheme';

type GetServicesContext = {
  hotelId: number;
  headers?: Headers;
  isFree?: boolean;
};
const getServices = ({hotelId, headers, isFree}: GetServicesContext) =>
  request<HotelsServicesDetailResponse>(
    url(`hotels/${hotelId}/services`, {
      ...getQueryParameterFromBoolean('is_free', isFree),
    }),
    {
      method: 'GET',
      headers: headers || clientHeaders(),
    },
  ).then(path<HotelsServicesDetailResponse['data']>([1, 'data']));

type CreateServiceContext = {
  hotelId: number;
  payload: HotelsServicesCreateRequest;
};
const createService = ({hotelId, payload}: CreateServiceContext) =>
  request<HotelsServicesCreateResponse>(url(`hotels/${hotelId}/services`), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsServicesCreateResponse['data']>([1, 'data']));

export const servicesApi = {
  get: getServices,
  create: createService,
};

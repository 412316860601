import styled from '@emotion/styled';

import {request} from 'library/api';

import {Input} from 'library/components/input';
import useTranslation from 'next-translate/useTranslation';
import {cond, is, isEmpty, path, tryCatch, unless, when, equals} from 'ramda';
import type {Dispatch, ReactNode, SetStateAction, ChangeEvent} from 'react';
import {useRouter} from 'next/router';

import {useEffect, useRef, useState} from 'react';
import {IconName} from 'source/components/icon';

import {
  handleBusinessException,
  handleUnhandledException,
  throwBusinessException,
} from 'source/utilities/exceptions/business';

import {handleApiException} from 'source/utilities/exceptions/network';
import {LOADING_STYLES} from 'source/utilities/constants/css';
import {
  isApiException,
  isBusinessException,
  isUnhandledException,
} from 'source/utilities/guards/exceptions';
import {DEFAULT_PAGE} from 'source/utilities/constants/logic';
import {useDebounce} from 'source/utilities/hooks/use-debounce';
import Popup from 'reactjs-popup';
import InfiniteScroll from 'react-infinite-scroll-component';
import {css} from '@emotion/react';
import {CircularLoader} from '../loader';

const StyledPopup = styled(Popup)<{triggerWidth: number}>`
  &-content {
    width: ${({triggerWidth}) => triggerWidth}px !important;
    padding: 0;
    background-color: ${({theme}) => theme.palette.primaryBackground};
    border: ${({theme}) => theme.palette.border};
    box-shadow: ${({theme}) => theme.palette.boxShadow} !important;
    border-radius: 6px !important;
    overflow: hidden;
    box-sizing: border-box;
    color: ${({theme}) => theme.palette.fontDefault};
  }
`;

const Wrapper = styled.div``;

const StyledCircularLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InputLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  max-height: 40vh;
`;

const List = styled.ul<{loading: boolean}>`
  margin-top: 8px;
  width: 100%;
  list-style: none;
  z-index: 5;
  position: relative;

  ${({loading}) =>
    loading &&
    css`
      * {
        ${LOADING_STYLES}
      }
    `}
`;

interface Pagination {
  total: number;
  per_page?: number;
  current_page: number;
  last_page: number;
}

interface IProps<SearchResponse, Data> {
  selected?: string;
  skipSearch?: boolean;
  controlledPhrase?: string;
  onChange?: (value?: string) => void;
  disabled?: boolean;
  placeholder?: string;
  minimumCharacters?: number;
  allowSpaceBars?: boolean;
  debounceTimeout?: number;
  icon?: IconName;
  className?: string;
  resolver: (
    phrase: string,
    page?: number,
  ) => ReturnType<typeof request<SearchResponse>>;
  mapper?: (
    data: Awaited<ReturnType<typeof request<SearchResponse>>>,
  ) => Data[] | undefined;
  paginationMapper?: (
    data: Awaited<ReturnType<typeof request<SearchResponse>>>,
  ) => Pagination | undefined;
  children: (
    values: Data[],
    setSelected: Dispatch<SetStateAction<string>>,
  ) => ReactNode;
  withPagination?: boolean;
}

export const SearchInput = <SearchResponse, Data>({
  selected: initial = '',
  onChange,
  controlledPhrase,
  skipSearch,
  disabled = false,
  minimumCharacters = 3,
  debounceTimeout = 900,
  mapper = path<Data[]>([1, 'data']),
  paginationMapper,
  placeholder,
  icon,
  allowSpaceBars,
  resolver,
  children,
  className,
  withPagination = false,
}: IProps<SearchResponse, Data>) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(disabled);
  const [selected, setSelected] = useState(initial);
  const [innerPhrase, setPhrase] = useState(controlledPhrase || '');
  const [values, setValues] = useState<Data[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [isLast, setIsLast] = useState(false);
  const {t} = useTranslation('components');
  const phrase = controlledPhrase || innerPhrase;
  const triggerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;

    if (selected || selected !== value) {
      setSelected('');
    }

    const parsedValue = allowSpaceBars ? value : value.trimStart();

    setPhrase(parsedValue);
    onChange?.(parsedValue);
  };

  const handleSearch = () => {
    setInputDisabled(true);
    resolver(phrase, page)
      .then(
        unless(
          () => {
            const windowPathname = window.location.pathname;
            const isLanguageExistInPathname = windowPathname.includes(
              router.locale as string,
            );
            return equals(
              isLanguageExistInPathname
                ? `/${router.locale}${router.asPath}`
                : router.asPath,
              windowPathname,
            );
          },
          throwBusinessException(t('exceptions.not_pathname_equals')),
        ),
      )
      .then((response: any) => {
        if (!withPagination || !paginationMapper) {
          return response;
        }
        const pagination = paginationMapper(response);

        if (pagination && pagination?.current_page >= pagination?.last_page) {
          setIsLast(true);
        }

        return response;
      })
      .then(mapper)
      .then(
        unless(is(Array), throwBusinessException(t('exceptions.went_wrong'))),
      )
      .then(
        when(
          isEmpty,
          throwBusinessException(t('exceptions.not_found_query', {phrase})),
        ),
      )
      .then((newValues: Data[]) => {
        setValues((previous) =>
          withPagination ? [...previous, ...newValues] : newValues,
        );
      })
      .catch(
        tryCatch(
          cond([
            [isApiException, handleApiException],
            [isBusinessException, handleBusinessException],
            [isUnhandledException, handleUnhandledException],
          ]),
          () => {
            if (withPagination) {
              setIsLast(false);
              setPage(DEFAULT_PAGE);
            }

            setValues([]);
          },
        ),
      )
      .finally(() => {
        setLoading(false);
        setInputDisabled(false);

        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0);
      });
  };

  const handleInfiniteScroll = () => {
    if (!withPagination) {
      return;
    }

    setPage((previous) => previous + 1);
  };

  const debouncedHandleSearch = useDebounce(handleSearch, debounceTimeout);

  useEffect(() => {
    if (controlledPhrase !== undefined) {
      setPhrase(controlledPhrase);
      setSelected('');
    }

    setValues([]);

    if (withPagination) {
      setIsLast(false);
      setPage(DEFAULT_PAGE);
    }
  }, [controlledPhrase]);

  useEffect(() => {
    // Поиск при смене фразы в инпуте
    if (phrase.length < minimumCharacters) {
      return;
    }

    if (selected) {
      return;
    }

    if (skipSearch) {
      return;
    }

    setValues([]);

    if (withPagination) {
      setPage(DEFAULT_PAGE);
      setIsLast(false);
    }

    setLoading(true);

    debouncedHandleSearch();
  }, [phrase]);

  useEffect(() => {
    // Поиск при пагинации
    if (isLast) {
      return;
    }

    if (phrase.length < minimumCharacters) {
      return;
    }

    if (selected) {
      return;
    }

    if (skipSearch) {
      return;
    }

    setLoading(true);

    debouncedHandleSearch();
  }, [page]);

  return (
    <Wrapper ref={triggerRef}>
      <StyledPopup
        triggerWidth={triggerRef.current?.clientWidth || 0}
        on="click"
        arrow={false}
        position="bottom left"
        defaultOpen={false}
        closeOnDocumentClick
        closeOnEscape
        lockScroll
        open={open}
        disabled={loading}
        className={className}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={
          <InputWrapper>
            <Input
              ref={inputRef}
              type="text"
              label={null}
              autoComplete="off"
              icon={loading ? undefined : icon}
              placeholder={placeholder || t('placeholders.type_to_find_object')}
              value={selected || phrase}
              disabled={inputDisabled}
              onChange={handleInput}
            />
            {loading && <InputLoader size={16} />}
          </InputWrapper>
        }
      >
        {values.length > 0 && (
          <StyledInfiniteScroll
            next={loading ? () => {} : handleInfiniteScroll}
            loader={null}
            dataLength={values.length}
            hasMore={!isLast}
            scrollThreshold={0.8}
            height="fit-content"
          >
            <List loading={loading}>
              {loading && <StyledCircularLoader size={32} />}

              {phrase.length >= minimumCharacters &&
                !selected &&
                children(values, setSelected)}
            </List>
          </StyledInfiniteScroll>
        )}
      </StyledPopup>
    </Wrapper>
  );
};

import styled from '@emotion/styled';
import {IconButton} from 'library/components/icon-button';
import {Tooltip} from 'library/components/tooltip';
import {CrossIcon} from 'library/icons/cross-icon';
import {MainImageIcon} from 'library/icons/main-image-icon';
import {SelectedImageIcon} from 'library/icons/selected-image-icon';
import {SetMainImageIcon} from 'library/icons/set-main-image-icon';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import {CSSProperties, FC} from 'react';
import {Loader} from './loader';

const Wrapper = styled.li`
  position: relative;
  width: 100%;
  list-style: none;
  box-shadow: 0 4px 20px rgba(220, 220, 220, 0.4);
  transition: box-shadow 200ms ease-in-out;
  border-radius: 6px;
  cursor: pointer;

  .selected-image-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  &:hover {
    box-shadow: ${({theme}) => `0 0 0 2px ${theme.palette.primaryAccent}`};

    .set-main-image-icon {
      opacity: 1;
    }

    .delete-image-icon {
      opacity: 1;
    }
  }
`;

const ImageWrapper = styled.div<{isSelected: boolean}>`
  position: relative;
  height: 210px;
  border-radius: 6px;
  background-color: ${({isSelected}) =>
    isSelected ? 'rgba(255, 88, 118, 0.3)' : 'none'};

  img {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: none;
    opacity: ${({isSelected}) => (isSelected ? '0.3' : '1')};
  }
`;

const MainImageTrigger = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
`;

const SetMainImageTrigger = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0;
  z-index: 1;
`;

const DeleteImageTrigger = styled(IconButton)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  border: 2px solid ${({theme}) => theme.palette.primaryAccent};
  border-radius: 50%;
  padding: 8px;
  background: white;
  opacity: 0;
  z-index: 1;
`;

interface Props {
  innerRef?: (node: HTMLElement | null) => void;
  image: Image;
  style?: CSSProperties;
  loading: boolean;
  isSelected: boolean;
  isMainImage: boolean;
  setMainImage: () => void;
  onDeleteImage: (imageId: number) => void;
  onImageSelect: () => void;
  disabled?: boolean;
}

export const ImageCard: FC<Props> = ({
  innerRef,
  image,
  style,
  loading,
  isSelected,
  isMainImage,
  setMainImage,
  onDeleteImage,
  onImageSelect,
  disabled = false,
  ...properties
}) => {
  const {t} = useTranslation('photos');

  return (
    <Wrapper ref={innerRef} style={style} {...properties}>
      <Loader loading={loading} />
      {isSelected ? (
        <SelectedImageIcon className="selected-image-icon" />
      ) : null}
      {isMainImage ? (
        <Tooltip
          on="hover"
          position="top right"
          trigger={
            <MainImageTrigger>
              <MainImageIcon className="main-image-icon" />
            </MainImageTrigger>
          }
        >
          {t('main_image_tooltip')}
        </Tooltip>
      ) : null}
      <Tooltip
        on="hover"
        position="top right"
        trigger={
          // eslint-disable-next-line unicorn/no-negated-condition
          !disabled ? (
            <SetMainImageTrigger
              className="set-main-image-icon"
              onClick={setMainImage}
            >
              <SetMainImageIcon />
            </SetMainImageTrigger>
          ) : undefined
        }
      >
        {t('set_main_image_tooltip')}
      </Tooltip>
      <Tooltip
        on="hover"
        position="top right"
        trigger={
          // eslint-disable-next-line unicorn/no-negated-condition
          !disabled ? (
            <DeleteImageTrigger
              className="delete-image-icon"
              onClick={() => onDeleteImage(image.id)}
            >
              <CrossIcon />
            </DeleteImageTrigger>
          ) : undefined
        }
      >
        {t('delete_image_tooltip')}
      </Tooltip>
      <ImageWrapper
        isSelected={isSelected}
        onClick={() => {
          if (!disabled) {
            onImageSelect();
          }
        }}
      >
        <Image
          src={image.url}
          alt={t('image_alt')}
          fill
          priority
          sizes="(max-width: 480px) 100vw, (max-width: 900px) 50vw, (max-width: 1200px) 33vw, 25vw"
        />
      </ImageWrapper>
    </Wrapper>
  );
};

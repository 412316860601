import styled from '@emotion/styled';
import {Checkbox} from 'library/components/checkbox';
import {selectPolicies, setPaymentsMethods} from 'slices/policies/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
`;

const PaymentsCheckbox = styled(Checkbox)`
  margin-bottom: 17px;
`;

const PaymentsInHotel = () => {
  const dispatch = useAppDispatch();
  const {directories, policies} = useAppSelector(selectPolicies);

  const mobile = useDeviceDetection('mobile');

  const handleSelect = (
    methodOption: typeof policies.payment_methods[number],
    enabled: boolean
  ) => {
    const paymentsMethods = policies.payment_methods;
    if (paymentsMethods) {
      if (enabled) {
        dispatch(setPaymentsMethods([...paymentsMethods, methodOption]));
      }

      if (!enabled) {
        dispatch(
          setPaymentsMethods(
            paymentsMethods.filter(
              (method) => method.value !== methodOption.value
            )
          )
        );
      }
    }
  };

  return (
    <Wrapper>
      {directories?.paymentsMethods?.map((payment) => {
        return (
          <PaymentsCheckbox
            key={payment.title}
            label={payment.title}
            value={policies.payment_methods.some(
              (method) => method.value === payment.value
            )}
            isMobile={mobile}
            onChange={(enabled) => handleSelect(payment, enabled)}
          />
        );
      })}
    </Wrapper>
  );
};

export default PaymentsInHotel;

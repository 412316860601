import {format} from 'date-fns';
import {Translate} from 'next-translate';
import {
  findIndex,
  findLastIndex,
  flatten,
  isEmpty,
  prop,
  propEq,
  range,
  uniqBy,
} from 'ramda';
import {
  DEFAULT_INTEGRATION_EXTRA_GUEST_PRICE_MAX_GUESTS,
  DEFAULT_INTEGRATION_EXTRA_GUEST_PRICE_VALUE,
  DEFAULT_PRICE_VALUE,
  MIN_INTEGRATION_GUEST_PRICE_VALUE,
  MIN_PRICE,
} from 'slices/rate/lib/constants';
import {MOCK_RATE_CONSTRAINS_STAY} from 'slices/rate/lib/mocks';
import {store} from 'source/store';
import {isEqualObjects} from 'source/utilities/object';
import {
  getBackendDateFormat,
  getViewMonthsNameFromBackendDate,
} from 'source/utilities/dates';
import {setIsNeedSaving, setRatePrice} from '../store';
import {
  DayPrice,
  EDaysOfWeek,
  EIntegrationAdditionalGuestPriceInputs,
  FullWeekPriceKey,
  FullWeekPricesByGuest,
  IntegrationGuestPriceRoomType,
  Periods,
  PriceType,
  RateConstraintsStay,
  RateIsFirstEditRow,
  RatePrice,
  RatePriceGuestNumber,
  RatePrices,
  RateTableRoom,
  RequiredRoomId,
} from './types';

// rate_table
/**
 * Генерируем массив из полученных данных бэкэнда, в массив для таблицы
 * @param rate
 * @param rooms
 */
export const buildRateForTable = (
  rate: Rate,
  rooms: RoomsList,
): RateTableRoom[] => {
  const {prices, room_ids} = rate;

  if (isEmpty(prices) || isEmpty(rooms)) {
    return [];
  }

  // Находим уникальные roomId, на выходе получаем объекты с уникальным roomId
  const uniqueObjectsByRoomId = uniqBy(prop('room_id'), prices).filter(
    (uniqueRoom) => room_ids.includes(uniqueRoom.room_id),
  );

  // Получаем массив для каждой комнаты с числом гостей от 1 до max_guests_number
  const buildPriceWithGuestNumber = flatten(
    uniqueObjectsByRoomId.map((price) =>
      price.per_guests_number.map((pricePerGuest) => ({
        room_id: price.room_id,
        ...pricePerGuest,
      })),
    ),
  );

  /**
   * Добавляем элемент, который будет основным, и изменять все суммы, для номера
   * Он будет находится выше всех, и будет выполнять функцию изменения цен для всех комнат
   */
  uniqueObjectsByRoomId.map((room) => {
    const {room_id, per_guests_number} = room;
    const guestNumbers = per_guests_number.map(
      (guestNumber) => guestNumber.guests_number,
    );
    const firstIndex = findIndex(
      propEq('room_id', room_id),
      buildPriceWithGuestNumber,
    );
    const firstGuest = buildPriceWithGuestNumber[firstIndex];
    buildPriceWithGuestNumber.splice(firstIndex, 0, {
      guests_number: Math.max(...guestNumbers),
      room_id,
      values: firstGuest.values.map((value) => ({
        ...value,
        price: value.price,
      })),
    });
  });

  // Возвращаем массив с информацией по комнате
  return buildPriceWithGuestNumber.map((filteredRoom, index) => ({
    ...filteredRoom,
    roomInformation: rooms.find((room) => room.id === filteredRoom.room_id),
    isFirst:
      findIndex(
        propEq('room_id', filteredRoom.room_id),
        buildPriceWithGuestNumber,
      ) === index,
  }));
};

// integration_guest_price_table
/**
 * Генерируем массив из полученных данных бэкэнда, в массив для таблицы интеграцйии доплаты за гостя для реалти
 * @param rate
 * @param rooms
 */
export const buildIngrationPriceRateTable = (
  rate: Rate,
  rooms: RoomsList,
): IntegrationGuestPriceRoomType[] => {
  const {prices, room_ids, extra_guests_prices} = rate;

  if (isEmpty(prices) || isEmpty(rooms)) {
    return [];
  }

  return rooms
    .filter((room) => room_ids.includes(room.id))
    .map(({id, max_guests_number, name}) => {
      const currentExtraGuestsPrices = extra_guests_prices?.find(
        (extraGuestPrice) => extraGuestPrice?.room_id === id,
      );

      return {
        room_id: id,
        room_max_guests_number: max_guests_number,
        max_guests_number:
          currentExtraGuestsPrices?.max_guests_number ||
          DEFAULT_INTEGRATION_EXTRA_GUEST_PRICE_MAX_GUESTS,
        roomName: name,
        value:
          currentExtraGuestsPrices?.value ||
          DEFAULT_INTEGRATION_EXTRA_GUEST_PRICE_VALUE,
      };
    });
};

/**
 * Проверяем все ли ячейки в номере идентичны
 * @param roomId
 * @param ratesTable
 */
export const isEqualsRateTableValuesByRoomId = (
  roomId: Room['id'],
  ratesTable: ReturnType<typeof buildRateForTable>,
) => {
  const firstIndex = findIndex(propEq('room_id', roomId), ratesTable);
  const lastIndex = findLastIndex(propEq('room_id', roomId), ratesTable) + 1;
  const currentColumn = ratesTable.slice(firstIndex, lastIndex);

  const referenceValue = ratesTable[firstIndex].values[0].price;

  return currentColumn.every((item) =>
    item.values.every((value) => {
      return value.price === referenceValue && value.price > 0;
    }),
  );
};

/**
 * Находим ячейку (например субботу) и проверяем все субботы на совпадения в текущей комнате
 * @param roomId
 * @param day
 * @param ratesTable
 */
export const isEqualsRateTableValuesByDay = (
  roomId: Room['id'],
  day: EDaysOfWeek,
  ratesTable: ReturnType<typeof buildRateForTable>,
) => {
  const firstIndex = findIndex(propEq('room_id', roomId), ratesTable);
  const lastIndex = findLastIndex(propEq('room_id', roomId), ratesTable) + 1;
  const currentColumn = ratesTable.slice(firstIndex, lastIndex);

  const daysInColumn = currentColumn[0]?.values;
  const dayIndex = findIndex(propEq('day_of_week', day), daysInColumn);
  const days = currentColumn.map((column) => column.values[dayIndex]);

  const referenceValue = currentColumn[0].values[dayIndex].price;

  return days.every((column) => column.price === referenceValue);
};

export const buildFullWeekPriceKey = (
  roomId: RequiredRoomId,
  guestsNumber: RatePriceGuestNumber,
  isFirst: RateIsFirstEditRow,
): FullWeekPriceKey => `${roomId}_${guestsNumber}_${isFirst}`;

export const getFullWeekPrice = (
  table: ReturnType<typeof buildRateForTable>,
): FullWeekPricesByGuest => {
  return table.reduce((previousItem, currentItem) => {
    const {room_id, guests_number, isFirst} = currentItem;
    const key = buildFullWeekPriceKey(room_id, guests_number, isFirst);
    return {...previousItem, [key]: {price: 0}};
  }, {} as FullWeekPricesByGuest);
};

export const changePriceForDay = (
  dayId: DayPrice['day_of_week'],
  roomId: RatePrice['room_id'],
  guestNumber: number,
  priceAmount: DayPrice['price'],
  isFirst: boolean,
) => {
  const {
    rate: {prices},
  } = store.getState().rate;

  if (!prices || priceAmount < 0) {
    return;
  }

  const updatedPrice = prices.map((price) => {
    if (price.room_id === roomId) {
      const pricesPerGuest = price.per_guests_number;

      return {
        ...price,
        per_guests_number: pricesPerGuest.map((pricePerGuest) => {
          if (isFirst || pricePerGuest.guests_number === guestNumber) {
            const pricesPerDays = pricePerGuest.values;
            return {
              ...pricePerGuest,
              values: pricesPerDays.map((pricePerDay) => {
                if (pricePerDay.day_of_week === dayId) {
                  return {...pricePerDay, price: priceAmount || MIN_PRICE};
                }
                return pricePerDay;
              }),
            };
          }
          return pricePerGuest;
        }),
      };
    }
    return price;
  });

  store.dispatch(setRatePrice([...updatedPrice]));
};

export const changePriceForAllDaysByGuest = (
  roomId: RatePrice['room_id'],
  priceAmount: DayPrice['price'],
  guestNumber: number,
  isFirst: boolean,
) => {
  const {
    rate: {prices},
  } = store.getState().rate;

  if (!prices || priceAmount < 0) {
    return;
  }

  const updatedPrice = prices.map((price) => {
    if (price.room_id !== roomId) {
      return price;
    }

    const pricesPerGuest = price.per_guests_number;
    return {
      ...price,
      allDayPrices: priceAmount,
      per_guests_number: pricesPerGuest.map((pricePerGuest) => {
        const isRebuild =
          isFirst || pricePerGuest.guests_number === guestNumber;
        return isRebuild
          ? {
              ...pricePerGuest,
              values: pricePerGuest.values.map((pricePerDay) => ({
                ...pricePerDay,
                price: priceAmount || MIN_PRICE,
              })),
            }
          : pricePerGuest;
      }),
    };
  });

  store.dispatch(setRatePrice([...updatedPrice]));
};

// conditions
export const buildPrices = (prices: RatePrices) => {
  const {
    rooms: {data: rooms},
  } = store.getState();

  const daysOfWeek = Object.values(EDaysOfWeek).filter(
    (day) => typeof day !== 'string',
  );

  const build: RatePrices = rooms.map((room) => {
    const price = prices.find((price) => price.room_id === room.id);
    return {
      room_id: room.id,
      per_guests_number: range(1, room.max_guests_number + 1).map(
        (guestNumber, guestIndex) => ({
          guests_number: guestNumber,
          values: range(1, 8).map((day, dayIndex) => {
            const priceValue =
              price?.per_guests_number[guestIndex]?.values[dayIndex]?.price;
            return {
              id: -1,
              price: priceValue ?? DEFAULT_PRICE_VALUE,
              day_of_week: daysOfWeek[day - 1] as EDaysOfWeek,
            };
          }),
        }),
      ),
    };
  });

  return build;
};

export const cleanPrices = (prices: RatePrices, roomIds: Rate['room_ids']) => {
  return prices
    .filter((price) => roomIds.includes(price.room_id))
    .map((price) => ({
      ...price,
      per_guests_number: price.per_guests_number.map((pricePerGuest) => ({
        ...pricePerGuest,
        values: pricePerGuest.values.map((pricePerDay) => {
          if (pricePerDay.id !== -1) {
            return pricePerDay;
          }
          return {
            price: pricePerDay.price,
            day_of_week: pricePerDay.day_of_week,
          };
        }),
      })),
    }));
};

export const formatDateValueForInput = (date: Date) => {
  const dayName = format(date, 'EEEEEE');
  const monthsDay = format(date, 'LLLL');
  return `${
    dayName[0].toUpperCase() + dayName.slice(1)
  } ${date.getDate()}, ${monthsDay} ${date.getFullYear()}`;
};

export const checkMatchingPeriods = (periods: Periods) => {
  const visitedPairs = new Set();

  periods.forEach((period) => {
    const {from, to} = period;

    const pair = `${from}-${to}`;
    const reversePair = `${to}-${from}`;

    if (visitedPairs.has(reversePair)) {
      // Если найдено совпадение в обратном порядке, возвращаем true
      return true;
    }

    visitedPairs.add(pair);
  });

  // Если нет совпадений, возвращаем false
  return false;
};

export const getStayPeriods = (
  stay?: RateConstraintsStay,
): Exclude<RateConstraintsStay, undefined> => {
  if (!stay) {
    return MOCK_RATE_CONSTRAINS_STAY;
  }

  return stay;
};

let currentValue: Rate;

export const subscribeUpdateRateObject = () => {
  const previousValue = currentValue;
  const state = store.getState().rate;
  currentValue = state.rate;
  if (
    currentValue?.id === -1 ||
    previousValue?.id === -1 ||
    !previousValue ||
    !currentValue
  ) {
    return;
  }

  const isRatesUpdated = !isEqualObjects(previousValue, currentValue);

  if (isRatesUpdated && previousValue.id === currentValue.id) {
    store.dispatch(setIsNeedSaving(isRatesUpdated));
  }
};

export const getCurrentCheckboxValue = (
  currentValue: number,
  type: PriceType,
) => type.value === currentValue;

export const getViewedPeriodDate = (
  t: Translate,
  period?: string | null,
): string | undefined =>
  period
    ? getViewMonthsNameFromBackendDate(period)
    : t('about_rate.period.period_undefined');

export const getDateForRatePeriodRange = (date?: Date) =>
  date ? getBackendDateFormat(date) : undefined;

export const buildIntegrationGuestsPricesRooms = (
  extraGuestsPrices: Rate['extra_guests_prices'],
  newExtraGuestsPrice: IntegrationGuestPriceRoom,
  inputType: EIntegrationAdditionalGuestPriceInputs,
) => {
  const {room_id, value, max_guests_number} = newExtraGuestsPrice;
  let isGuestPriceAlreadyExist = false;

  const updatedExtraGuestsPrices = extraGuestsPrices.map((extraGuestPrice) => {
    if (room_id === extraGuestPrice.room_id) {
      isGuestPriceAlreadyExist = true;

      if (inputType === EIntegrationAdditionalGuestPriceInputs.GUESTS) {
        return {
          ...extraGuestPrice,
          max_guests_number: max_guests_number || null,
        };
      }

      return {
        ...extraGuestPrice,
        value,
      };
    }

    return extraGuestPrice;
  });

  return isGuestPriceAlreadyExist
    ? updatedExtraGuestsPrices
    : [...updatedExtraGuestsPrices, newExtraGuestsPrice];
};

export const parseExtraGuestsPricesPayload = (
  extraGuestsPrices: IntegrationGuestPriceRoom[],
): IntegrationGuestPriceRoomRequestPayload =>
  extraGuestsPrices
    .filter(
      (extraGuestPrice) =>
        extraGuestPrice.value > MIN_INTEGRATION_GUEST_PRICE_VALUE,
    )
    .map((extraGuestPrice) => ({
      ...extraGuestPrice,
      unit_type: extraGuestPrice.unit_type?.value,
    }));

// Проверить, чтобы везде указаны цены, если указано кол-во гостей
export const checkIfNoValuesInExtraGuestPrices = (
  extraGuestsPrices: IntegrationGuestPriceRoom[],
): boolean =>
  extraGuestsPrices.some(
    (extraGuestPrice) =>
      Number(extraGuestPrice.max_guests_number) > 0 &&
      Number(extraGuestPrice.value) === 0,
  );

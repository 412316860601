import {css} from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {FC, useEffect, useRef, useState} from 'react';
import {ArrowIcon} from '../arrow-link/arrow';
import {Input} from '../input';

const Wrapper = styled.div<{disabled?: boolean}>`
  position: relative;
  cursor: pointer;

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const InputWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledInput = styled(Input)`
  & input {
    user-select: none;
    pointer-events: none;
    padding-right: 30px;
  }
`;

const Arrow = styled(ArrowIcon)<{isOpen: boolean; disabled?: boolean}>`
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'unset')};
  transition: 300ms;
  position: absolute;
  right: 12px;
  top: 45%;
  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

const List = styled.ul`
  margin-top: 8px;
  width: 100%;
  max-height: 40vh;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background-color: #fff;
  list-style: none;
  overflow: auto;
  position: absolute;
  top: calc(100%);
  z-index: 5;
`;

const ListItem = styled.li`
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  :hover {
    background-color: #f8f9fb;
  }
`;

export type Option<T = any> = {
  value: T;
  label: string;
};

interface SelectProps {
  value: Option;
  options: Option[];
  setValue: (option: Option) => void;
  placeholder?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  onScrollEnd?: () => Promise<void>;
}

export const Select: FC<SelectProps> = ({
  value,
  setValue,
  options,
  placeholder,
  disabled,
  className,
  onScrollEnd,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleListScroll = async (event: React.UIEvent<HTMLUListElement>) => {
    const target = event.target as HTMLUListElement;
    if (
      target.scrollTop + target.clientHeight === target.scrollHeight &&
      onScrollEnd
    ) {
      await onScrollEnd();
    }
  };
  return (
    <Wrapper className={className} ref={ref} disabled={disabled}>
      <InputWrapper
        onClick={(e) => {
          if (!disabled && options.length > 0) {
            setIsOpen(!isOpen);
          }

          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <StyledInput
          placeholder={placeholder}
          label=""
          type="text"
          disabled={disabled}
          cursor="pointer"
          autoComplete="off"
          value={t(value.label)}
          onChange={() => {}}
        />
        <Arrow disabled={disabled} isOpen={isOpen} />
      </InputWrapper>
      {isOpen ? (
        <List ref={listRef} onScroll={handleListScroll}>
          {options.map((option) => (
            <ListItem
              key={option.value}
              onClick={() => {
                setValue(option);
                setIsOpen(!isOpen);
              }}
            >
              {t(option?.label)}
            </ListItem>
          ))}
        </List>
      ) : null}
    </Wrapper>
  );
};

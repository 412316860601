export const transformToNumber = (parameter?: string | string[] | number) => {
  if (typeof parameter === 'number') {
    return parameter;
  }

  return Number(Array.isArray(parameter) ? parameter[0] : parameter);
};

export enum ErrorType {
  ID = 'Invalid Id',
}

export const checkIdValidity = (parameter: number) => {
  if (Number.isNaN(parameter) || (!Number.isNaN(parameter) && !parameter)) {
    throw new TypeError(ErrorType.ID);
  }
};

import type {GetServerSideProps} from 'next';

import {andThen, assoc, call, pipeWith} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {
  handleException,
  handleResponseErrors,
} from 'source/utilities/exceptions/business';

import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {serverHeaders} from 'source/utilities/network/http';

import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {ErrorCodeLinkOptionsType} from 'source/utilities/exceptions/types';
import {HomePageProperties} from './lib/types';

export const getHomePageProperties: GetServerSideProps<
  HomePageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () => api.hotel.get({hotelId, headers});

  const requestRates = () => api.rates.get({hotelId, headers});

  const requestHotelModerationLast = () =>
    api.moderation.getLast({hotelId, headers});

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, hotelModerationLast, rates] = await Promise.all([
            requestHotel(),
            requestHotelModerationLast(),
            requestRates(),
          ]);

          const result = removeUndefined({
            ...properties,
            hotel,
            hotelModerationLast,
            rates,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const handleUpdateRatesPublicity = async (
  hotelId: number,
  payload: {public: boolean},
  errorCodeOptions: ErrorCodeLinkOptionsType,
  onSuccess?: (hotel: Hotel) => void,
  ...dispatchers: [LoadingDispatch, BooleanDispatch]
) => {
  const [setLoading, setOpenAcceptedBooking] = dispatchers;
  setLoading(true);
  api.hotel
    .updateRatesPublicity({hotelId, payload})
    .then((response) => {
      const isHotelData = response && 'id' in response;

      if (isHotelData && onSuccess) {
        onSuccess(response as Hotel);
      }

      if (!isHotelData && response) {
        handleResponseErrors(response, errorCodeOptions);
      }
    })
    .catch(handleException)
    .finally(() => {
      setLoading(false);
      setOpenAcceptedBooking(false);
    });
};

import {parseTimeUnitToTwoDigits} from 'source/utilities/time';

import {TIMER_INITIAL_COUNTDOWN, TIMER_START_STEP} from '../constants';

export const getCountdownTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${parseTimeUnitToTwoDigits(seconds)}`;
};

export const getCountdownInitialTime = (timersIndex: number): number =>
  timersIndex * TIMER_START_STEP + TIMER_INITIAL_COUNTDOWN;

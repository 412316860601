import {FC, createContext, useState} from 'react';

export type LoadingState = State<boolean>;

export const LoadingContext = createContext<LoadingState>([false, () => {}]);

export const LoadingProvider: FC<ProviderProperties<LoadingState>> = ({
  serverValue,
  ...properties
}) => {
  const state = useState<ContextValue<LoadingState>>(serverValue || false);
  return <LoadingContext.Provider value={state} {...properties} />;
};

import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { UppyHotelFileType } from 'types/logic';
import { Dispatcher } from 'types/utilities';
import Gallery from './gallery';
import Preview from './preview';

const Wrapper = styled.div``;

interface Props {
  roomId?: number;
  images: Images;
  onImageUpload: (images: Images) => void;
  onFileDeleteComplete: (imageId?: number) => void;
  onFilesUpdate: (files: UppyHotelFileType[]) => void;
  onFileDelete: (
    imageId: number,
    onComplete: () => void,
    setLoading: Dispatcher<boolean>
  ) => void;
  selectedImages: number[];
  onSelectImage: (imageId: number) => void;
  onRemoveImage: (imageId: number) => void;
  onMoveImages: (
    images: Images,
    activeIndex: number,
    overIndex: number
  ) => void;
  onReorderImages: (
    activeId: number,
    preHotelImageId: number,
    images: Images,
    activeIndex: number,
    overIndex: number
  ) => void;
  maxPhotos: number;
  minPhotos: number;
  onMainImageChange: (imageId: number, url: string) => Promise<void>;
  onDeleteComplete: (imageId: number) => void;
  onDeleteImage: (
    imageId: number,
    onComplete: () => void,
    setLoading: Dispatcher<boolean>
  ) => void;
  isMainImage: (imageId: number, roomId?: number) => boolean;
  onSelect: (images: Images) => void;
  onUnselect: () => void;
  onDeleteSelectedComplete: () => void;
  onDeleteSelected: (
    selectedImages: number[],
    onComplete: () => void,
    setLoading: Dispatcher<boolean>
  ) => void;
}

export const Photos: FC<Props> = ({
  roomId,
  images,
  maxPhotos,
  minPhotos,
  selectedImages,
  onDeleteComplete,
  onDeleteImage,
  onDeleteSelected,
  onDeleteSelectedComplete,
  onMainImageChange,
  onMoveImages,
  onRemoveImage,
  onReorderImages,
  onSelect,
  onSelectImage,
  onUnselect,
  isMainImage,
  onFileDeleteComplete,
  onFileDelete,
  onFilesUpdate,
  onImageUpload,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  return (
    <Wrapper>
      {isUploading ? (
        <Preview
          images={images}
          maxCount={maxPhotos}
          onFileDelete={onFileDelete}
          onFilesUpdate={onFilesUpdate}
          onImageUpload={onImageUpload}
          onFileDeleteComplete={onFileDeleteComplete}
          setIsUploading={setIsUploading}
          roomId={roomId}
        />
      ) : (
        <Gallery
          onDeleteComplete={onDeleteComplete}
          onDeleteImage={onDeleteImage}
          onDeleteSelected={onDeleteSelected}
          onDeleteSelectedComplete={onDeleteSelectedComplete}
          selectedImages={selectedImages}
          setIsUploading={setIsUploading}
          onSelectImage={onSelectImage}
          onSelect={onSelect}
          onUnselect={onUnselect}
          minPhotos={minPhotos}
          images={images}
          maxPhotos={maxPhotos}
          onMainImageChange={onMainImageChange}
          onMoveImages={onMoveImages}
          onRemoveImage={onRemoveImage}
          onReorderImages={onReorderImages}
          isMainImage={isMainImage}
          roomId={roomId}
        />
      )}
    </Wrapper>
  );
};

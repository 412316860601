import styled from '@emotion/styled';
import {css, keyframes} from '@emotion/react';
import {FC, ReactNode} from 'react';
import Popup from 'reactjs-popup';
import {PopupProps} from 'reactjs-popup/dist/types';

const slideFromTop = keyframes` 
  0% {
    transform: translate(-50%, -100%);
  }
  20% {
    transform: translate(-50%, -80%);
  }
  40% {
    transform: translate(-50%, -60%);
  }
  50% {
    transform: translate(-50%, -40%);
  }
  80% {
    transform: translate(-50%, -20%);
  }
  100% {
    transform: translate(-50%, 0);
  }
`;

const StyledPopup = styled(Popup)<{
  maxWidth?: number;
  minWidth?: number;
  mobileTopModal?: boolean;
}>`
  &-content {
    padding: 8px 10px;
    background: ${({theme}) => theme.palette.primaryBackground};
    border: ${({theme}) => `1px solid ${theme.palette.border}`};
    box-shadow: ${({theme}) => theme.palette.boxShadow} !important;
    border-radius: 6px !important;
    box-sizing: border-box;
    color: ${({theme}) => theme.palette.fontDefault};
    min-width: ${({minWidth}) => (minWidth ? `${minWidth}px` : 'unset')};
    max-width: ${({maxWidth}) => (maxWidth ? `${maxWidth}px` : 'unset')};
    width: fit-content;

    ${({mobileTopModal}) =>
      mobileTopModal &&
      css`
        position: absolute !important;
        left: 50%;
        top: 0;
        max-width: 600px;
        width: 100%;
        padding: 10px;

        -webkit-animation: ${slideFromTop} 0.2s forwards;
      `}
  }
`;

interface TooltipProps {
  on: PopupProps['on'];
  position: PopupProps['position'];
  trigger: PopupProps['trigger'];
  disabled?: PopupProps['disabled'];
  open?: PopupProps['open'];
  children: ReactNode;
  className?: string;
  minWidth?: number;
  maxWidth?: number;
  mobileTopModal?: boolean;
  onClose?: () => void;
}

export const Tooltip: FC<TooltipProps> = ({
  on,
  position,
  trigger,
  disabled,
  open,
  children,
  className,
  minWidth,
  maxWidth,
  mobileTopModal = false,
  onClose = () => {},
}) => {
  return (
    <StyledPopup
      open={open}
      className={className}
      on={on}
      modal={mobileTopModal}
      mobileTopModal={mobileTopModal}
      position={position}
      trigger={trigger}
      disabled={disabled}
      arrow={false}
      offsetY={10}
      closeOnDocumentClick
      maxWidth={maxWidth}
      onClose={onClose}
      minWidth={minWidth}
    >
      <span>{children}</span>
    </StyledPopup>
  );
};

import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {DatePicker} from 'library/components/date-picker';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {useState} from 'react';
import {DateRangeProps, RangeKeyDict} from 'react-date-range';

import {useAppDispatch, useAppSelector} from 'source/store';

import {
  parseRangeDateToBackendDateFormat,
  addCurrentYearDays,
} from 'source/utilities/dates';
import {
  formatDateToString,
  isRangeValid,
} from 'slices/calendar/lib/helpers/dates';
import {
  selectSelectedDates,
  setSelectedDates,
  setIsDatesLimitExceeded,
  setIsBeforeDatesLimitExceeded,
} from 'slices/calendar/store';
import {
  CALENDAR_PICKER_MONTH_COUNT,
  VIEW_MAX_DATES_LIMIT_TOUCH,
} from 'slices/calendar/lib/constants';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Text} from 'library/components/text';

const RANGE_KEY = 'selection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  padding: 10px;
`;

export const CalendarPicker = () => {
  const {t} = useTranslation('components');
  const router = useRouter();
  const dispatch = useAppDispatch();
  const selectedDates = useAppSelector(selectSelectedDates);
  const today = new Date();
  const maxEndDate = addCurrentYearDays(today);
  const isTouch = useDeviceDetection('touch');

  const [ranges, setRanges] = useState<DateRangeProps['ranges']>([
    {
      startDate: parseRangeDateToBackendDateFormat(selectedDates.from),
      endDate: parseRangeDateToBackendDateFormat(selectedDates.to),
      key: RANGE_KEY,
    },
  ]);

  const onChange = (range: RangeKeyDict) => {
    const {startDate, endDate} = range[RANGE_KEY];
    setRanges([{startDate, endDate, key: RANGE_KEY}]);
  };

  const onSelect = () => {
    if (ranges && ranges[0].startDate && ranges[0].endDate) {
      dispatch(
        setSelectedDates({
          from: formatDateToString(ranges[0].startDate),
          to: formatDateToString(ranges[0].endDate),
        }),
      );
      dispatch(setIsDatesLimitExceeded(false));
      dispatch(setIsBeforeDatesLimitExceeded(false));

      const triggerButton = document.querySelector<HTMLButtonElement>(
        '.calendar-picker-trigger',
      );

      if (triggerButton) {
        triggerButton.click();
      }
    }
  };

  return (
    <Wrapper>
      {isTouch && (
        <Text size="XS" align="center">
          {t('calendar:max_range', {count: VIEW_MAX_DATES_LIMIT_TOUCH})}
        </Text>
      )}

      <DatePicker
        ranges={ranges}
        onChange={onChange}
        weekdayDisplayFormat="EEEEEE"
        monthDisplayFormat="LLLL"
        minDate={today}
        maxDate={maxEndDate}
        months={CALENDAR_PICKER_MONTH_COUNT}
        locale={router.locale}
      />

      <StyledButton
        disabled={
          ranges &&
          ranges[0].startDate &&
          ranges[0].endDate &&
          !isRangeValid(ranges[0].startDate, ranges[0].endDate, isTouch)
        }
        onClick={onSelect}
      >
        {t('save')}
      </StyledButton>
    </Wrapper>
  );
};

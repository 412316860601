import {FC, useContext, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import Popup from 'reactjs-popup';
import useTranslation from 'next-translate/useTranslation';

import {isConstraintEquals} from 'slices/calendar/lib/helpers/rooms';
import {AVAILABILITY_REASON_OPTIONS} from 'slices/calendar/lib/constants';
import {EAvailabilityReason} from 'slices/calendar/lib/types';
import {useAppDispatch, useAppSelector} from 'source/store';
import {selectRoomConstraints, setRoomConstraints} from 'slices/calendar/store';
import {toValueOrEmptyArray} from 'source/utilities/array';
import {setInitialScrollsPosition} from 'slices/calendar/lib/helpers/ui';
import {CalendarRefContext} from 'source/context/calendar-ref';
import RoomConstraintTooltipContent from '../room-constraint-tooltip-content';

const Wrapper = styled.div<{
  width?: number;
  type: ClientCalendarRoomConstraint['type'];
}>`
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 5px 0;
  font-size: 10px;
  align-items: center;
  border-radius: 100px;
  position: relative;
  min-width: ${({width}) => width}px;
  color: ${({theme}) => theme.palette.fontAccent};
  cursor: pointer;

  background-color: ${({type, theme}) => {
    if (type === EAvailabilityReason.UNAVAILABLE) {
      return theme.palette.primaryAccent;
    }
    if (type === EAvailabilityReason.REPAIR) {
      return theme.palette.action;
    }
    if (type === EAvailabilityReason.QUOTA) {
      return theme.palette.wating;
    }
  }};

  &:hover {
    transition: 0.2s ease-in-out;
    opacity: 0.7;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: fit-content;
    max-width: 800px;
    padding: 24px 20px;
    border-radius: 6px;

    @media (max-width: 350px) {
      max-width: 95vw;
      padding: 20px 15px;
      width: 100%;
    }
  }
`;

interface IProps {
  width?: number;
  constraint: ClientCalendarRoomConstraint;
  roomId: number;
}

export const RoomConstraint: FC<IProps> = ({width, constraint, roomId}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('calendar');
  const [calendarRef] = useContext(CalendarRefContext);
  const [isOpen, setIsOpen] = useState(false);
  const storeRoomConstraints = useAppSelector(selectRoomConstraints);
  const roomConstraints = toValueOrEmptyArray(
    storeRoomConstraints?.[roomId]?.roomConstraints,
  );
  const initialRoomConstraints = toValueOrEmptyArray(
    storeRoomConstraints?.[roomId]?.initialRoomConstraints,
  );

  const title = t(
    AVAILABILITY_REASON_OPTIONS.find(
      (option) => option.value === constraint.type,
    )?.label as string,
  );

  const handleDeleteConstraint = () => {
    const newRoomConstraints = roomConstraints.map((previousItem) => {
      if (
        isConstraintEquals(constraint, previousItem) &&
        previousItem.id === constraint.id
      ) {
        return {...previousItem, is_delete: true};
      }

      return previousItem;
    });

    dispatch(
      setRoomConstraints({
        [roomId]: {
          roomConstraints: newRoomConstraints,
          initialRoomConstraints,
        },
      }),
    );
  };

  useEffect(() => {
    setInitialScrollsPosition(calendarRef);
  }, []);

  return (
    <>
      <Wrapper
        width={width}
        type={constraint.type}
        onClick={() => setIsOpen(true)}
      >
        {title}
      </Wrapper>
      <StyledPopup
        modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnEscape
        closeOnDocumentClick
        lockScroll
      >
        <RoomConstraintTooltipContent
          onClose={() => setIsOpen(false)}
          title={title}
          constraint={constraint}
          onDelete={handleDeleteConstraint}
        />
      </StyledPopup>
    </>
  );
};

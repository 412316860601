import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {format} from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import {FC, memo} from 'react';

import {Text} from 'library/components/text';
import {useAppSelector} from 'source/store';

import {formatMonthFromDate} from 'slices/calendar/lib/helpers/dates';
import {selectIsLoading} from 'slices/calendar/store';
import {TableButton} from '../../table-button';

const Wrapper = styled.div``;

const StyledMonthButton = styled(TableButton)<{
  selected?: boolean;
  disabled?: boolean;
}>`
  width: fit-content;
  padding: 4px 12px;

  ${({theme, selected}) =>
    selected &&
    css`
      color: ${theme.palette.fontPrimary};
      border-color: ${theme.palette.border_4};
    `}

  ${({disabled}) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;

interface IProps {
  onMonthClick: (date: Date) => void;
  date: Date;
}

const MonthCellComponent: FC<IProps> = ({onMonthClick, date}) => {
  const {t} = useTranslation('calendar');
  const loading = useAppSelector(selectIsLoading);

  const currentDateYear = format(date, 'yy');

  return (
    <Wrapper>
      <StyledMonthButton onClick={() => onMonthClick(date)} disabled={loading}>
        <Text size="XS">
          {`${t(`month.${formatMonthFromDate(date)}`)} ${currentDateYear}`}
        </Text>
      </StyledMonthButton>
    </Wrapper>
  );
};

export const MonthCell = memo(MonthCellComponent);

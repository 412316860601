import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {all, any, equals, map, not, pathOr, pipe, test, when} from 'ramda';
import {FC, FormEvent, useContext, useState} from 'react';
import {MAX_PHONE_LENGTH} from 'slices/general_info/lib/constants';
import {phoneBuilder} from 'slices/general_info/lib/helpers';
import {HotelContext} from 'source/context/hotel';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {patterns} from 'source/utilities/patterns';
import {isPlusFirstExist} from 'source/utilities/strings';
import {handleHotelUpdate} from '../../network';

const Wrapper = styled.div`
  padding: 20px 24px;
`;

const Title = styled(Text)`
  margin-bottom: 3px;
`;

const Subtitle = styled(Text)`
  margin-bottom: 11px;
  max-width: 420px;
`;

const StyledInput = styled(Input)`
  max-width: 440px;
  margin-bottom: 27px;
  & > input {
    height: 92px;
    padding-top: 0;
  }
`;

const Labeled = styled(LabeledComponent)`
  margin-top: 36px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 26px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const Cancel = styled(Button)`
  width: 172px;
  height: 58px;
  text-transform: uppercase;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Save = styled(Button)`
  width: 181px;
  height: 58px;
  text-transform: uppercase;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const EditPhoneContent: FC<{
  closeAction: () => void;
}> = ({closeAction}) => {
  const [hotel, setHotel] = useContext(HotelContext);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(phoneBuilder(hotel?.phone ?? ''));
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation();

  const formatted = all(
    equals(true),
    map((key) => test(patterns[key], input), ['phone'] as const),
  );

  const handleSave = (event: FormEvent<HTMLFormElement>) => {
    if (hotel) {
      handleHotelUpdate(
        hotel,
        {phone: Number(input)},
        () => {
          closeAction();
        },
        setLoading,
        setHotel,
      )(event);
    }
  };

  const handleOnChange = () =>
    pipe(
      pathOr('', ['target', 'value']),
      when(
        (value) => value.length <= MAX_PHONE_LENGTH,
        (value) => {
          setInput(
            `+${
              isPlusFirstExist(value) && value.length > 0
                ? value.slice(1, value.length)
                : value
            }`,
          );
        },
      ),
    );

  return (
    <Wrapper>
      <Title size={mobile ? 'boldM' : 'L'}>
        {t('hotel-info:edit_phone_title')}
      </Title>
      <Subtitle size="XS">{t('hotel-info:edit_phone_subtitle')}</Subtitle>
      <form onSubmit={handleSave}>
        <Labeled
          gap={10}
          text={t('hotel-info:contact_information.phone_label')}
          size="XS"
        >
          <StyledInput
            onChange={handleOnChange()}
            placeholder="+7XXXXXXXXXX"
            required
            type="text"
            pattern={patterns.compatible('phone')}
            patternMessage="+7XXXXXXXXXX"
            name="tel"
            maxLength={255}
            label={null}
            disabled={loading}
            onKeyDown={(event) => {
              if (event.code === 'Space') {
                event.preventDefault();
              }
            }}
            maxCount={MAX_PHONE_LENGTH}
            value={input}
          />
        </Labeled>
        <ButtonsWrapper>
          <Cancel type="button" buttonType="secondary" onClick={closeAction}>
            {t('components:cancel')}
          </Cancel>
          <Save
            type="submit"
            disabled={any(equals(true), [loading, not(formatted)])}
          >
            {loading ? t('components:loading') : t('components:save')}
          </Save>
        </ButtonsWrapper>
      </form>
    </Wrapper>
  );
};

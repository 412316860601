import {useRef} from 'react';

export function useDebounce<T extends (...arguments_: any[]) => any>(
  callback_: T,
  delay: number
) {
  const ref = useRef<NodeJS.Timer | null>(null);

  return (...arguments_: Parameters<T>) => {
    clearTimeout(ref.current!);
    ref.current = setTimeout(() => callback_(...arguments_), delay);
  };
}

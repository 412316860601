import {ApplicationProperties} from 'source/utilities/ui';

export interface FacilitiesPageProperties extends ApplicationProperties {
  facilities?: HotelFacilities;
  rawFacilities?: RawFacilities;
  hotel?: Hotel;
  services?: HotelServices;
  mealTypesDirectory: MealTypes;
}

export type FacilitiesState = {
  id: number;
  name: string;
  facilities: {
    id: number;
    name: string;
    enabled?: boolean;
  }[];
}[];

export interface IFacilitiesStore {
  facilities: HotelFacilities;
  rawFacilities: RawFacilities;
  services: HotelServices;
  mealTypesDirectory: MealTypes;
}

export type FacilitiesStateGroup = FacilitiesState[number];

export type FacilitiesStateFacility =
  FacilitiesStateGroup['facilities'][number];

export enum EServiceTypeValues {
  MEAL = 1,
  OTHER = 2,
}

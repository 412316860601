import styled from '@emotion/styled';
import {FC, ReactNode} from 'react';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {css} from '@emotion/react';
import {HorizontalDivier} from '../divider';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled.div<{gap?: number; mobile?: boolean}>`
  margin-bottom: ${({gap}) => gap}px;
  color: ${({color, theme}) => color || theme.palette.fontDefault};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;

  ${({mobile}) =>
    mobile &&
    css`
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
    `}
`;

interface Props {
  children?: ReactNode;
  gap?: number;
  className?: string;
}

export const SectionTitle: FC<Props> = ({children, className, gap}) => {
  const mobile = useDeviceDetection('mobile');

  return (
    <Wrapper className={className}>
      <StyledText mobile={mobile} gap={gap}>
        {children}
      </StyledText>
      <HorizontalDivier />
    </Wrapper>
  );
};

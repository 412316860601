import styled from '@emotion/styled';
import {Headline} from 'library/components/headline';
import {LabeledComponent} from 'library/components/labeled-component';
import {
  RowBody,
  RowBodyData,
  RowHead,
  RowHeadData,
  TableBoilerplate,
} from 'library/components/table';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {
  getHeaderLabeledTranslate,
  getHeaderTableHeadTranslate,
  parseBookingObject,
} from 'slices/booking/lib/helpers';
import {getViewMonthsNameFromBackendDate} from 'source/utilities/dates';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const Wrapper = styled.div``;

const StyledHotelName = styled(Headline)`
  margin-bottom: 20px;
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow: auto;
`;

const GuestsWishesWrapper = styled.div`
  margin: 34px 0px 34px 0px;
`;

const StyledLabeled = styled(LabeledComponent)`
  margin-bottom: 25px;
`;

const CancellationPolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px 10px 0px;
`;

const DescriptionBodyData = styled(RowBodyData)``;

const PaymentsBodyData = styled(RowBodyData)``;

const DetailInformationBodyData = styled(RowBodyData)``;

const VatBodyData = styled(RowBodyData)``;

interface Props {
  booking: Booking;
}

const HeaderInformation: FC<Props> = ({booking}) => {
  const {t} = useTranslation('booking');
  const mobile = useDeviceDetection('mobile');
  const {
    checkin,
    checkout,
    commission,
    currency,
    freeCancellationBefore,
    cancellationPolicy,
    amount,
    paymentMethod,
    amountOnSpot,
    vat,
    createdAt,
    hotelName,
    status,
    guests,
  } = parseBookingObject(booking);

  return (
    <Wrapper>
      <StyledHotelName level={mobile ? 'S' : 'M'}>
        {hotelName || ''}
      </StyledHotelName>
      <TableWrapper>
        <TableBoilerplate
          rowHeadChildren={
            <RowHead>
              <RowHeadData>
                {getHeaderTableHeadTranslate(t, 'description')}
              </RowHeadData>
              <RowHeadData>
                {getHeaderTableHeadTranslate(t, 'payment')}
              </RowHeadData>
              <RowHeadData>
                {getHeaderTableHeadTranslate(t, 'detail_information')}
              </RowHeadData>
              <RowHeadData>{getHeaderTableHeadTranslate(t, 'vat')}</RowHeadData>
            </RowHead>
          }
          rowBodyChildren={
            <RowBody>
              <DescriptionBodyData>
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'check_in.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">{`${
                    getViewMonthsNameFromBackendDate(checkin) ||
                    getHeaderLabeledTranslate(t, 'unknown')
                  }`}</Text>
                </StyledLabeled>
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'check_out.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">{`${
                    getViewMonthsNameFromBackendDate(checkout) ||
                    getHeaderLabeledTranslate(t, 'unknown')
                  }`}</Text>
                </StyledLabeled>
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'cancellation.label')}
                  gap={5}
                  size="M"
                >
                  {freeCancellationBefore && (
                    <>
                      <Text size="S">
                        {getHeaderLabeledTranslate(
                          t,
                          'cancellation.free_cancellation_title',
                        )}
                        <br />
                        {`${getViewMonthsNameFromBackendDate(
                          freeCancellationBefore,
                        )}`}
                      </Text>
                      <br />
                    </>
                  )}
                  {/* TODO - коммент оставлен 13.09.2023 (удалить если дата просмотра позже 13.11.2023) */}
                  {/* {!isEmpty(cancellation_policy) && ( */}
                  {/*  <Text size="S"> */}
                  {/*    {getHeaderLabeledTranslate( */}
                  {/*      t, */}
                  {/*      'cancellation.cancellation_policy_title' */}
                  {/*    )} */}
                  {/*  </Text> */}
                  {/* )} */}
                  {cancellationPolicy &&
                    cancellationPolicy
                      .filter((cancellation) => cancellation.amount > 0)
                      .map((cancellation) => (
                        <CancellationPolicyWrapper
                          key={`${cancellation.start}${cancellation.start}${cancellation.amount}`}
                        >
                          {cancellation.start && (
                            <Text size="S">{`${getHeaderLabeledTranslate(
                              t,
                              'cancellation.from',
                            )} ${getViewMonthsNameFromBackendDate(
                              cancellation.start,
                            )}`}</Text>
                          )}
                          {cancellation.end && (
                            <Text size="S">{`${getHeaderLabeledTranslate(
                              t,
                              'cancellation.to',
                            )} ${getViewMonthsNameFromBackendDate(
                              cancellation.end,
                            )}`}</Text>
                          )}
                          <Text size="S">
                            {`${getHeaderLabeledTranslate(
                              t,
                              'cancellation.fine',
                            )} ${cancellation.amount.toLocaleString()} ${currency}`}
                          </Text>
                        </CancellationPolicyWrapper>
                      ))}
                </StyledLabeled>
              </DescriptionBodyData>
              <PaymentsBodyData>
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'commission.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">{`${Number(
                    commission,
                  ).toLocaleString()} ${currency}`}</Text>
                </StyledLabeled>
                {/* TODO - коммент оставлен 13.09.2023 (удалить если дата просмотра позже 13.11.2023) */}
                {/* TODO - вернуть если скажет ПР (Тимур), это элемент отображения предоплаты */}
                {/* <StyledLabeled */}
                {/*  text={getHeaderLabeledTranslate(t, 'prepayment.label')} */}
                {/*  gap={5} */}
                {/*  size="M" */}
                {/* > */}
                {/*  {security_deposit?.required_prepayment_amount ? ( */}
                {/*    <Text size="S"> */}
                {/*      {`${security_deposit.required_prepayment_amount} ${currency}`} */}
                {/*    </Text> */}
                {/*  ) : ( */}
                {/*    <Text size="S"> */}
                {/*      {getHeaderLabeledTranslate(t, 'unknown')} */}
                {/*    </Text> */}
                {/*  )} */}
                {/* </StyledLabeled> */}
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'room_price.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">{`${amount.toLocaleString()} ${currency}`}</Text>
                </StyledLabeled>
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'payments_type.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">{`${
                    paymentMethod?.title ||
                    getHeaderLabeledTranslate(t, 'unknown')
                  }`}</Text>
                </StyledLabeled>
                {amountOnSpot ? (
                  <StyledLabeled
                    text={getHeaderLabeledTranslate(t, 'on_spot_amount.label')}
                    gap={5}
                    size="M"
                  >
                    <Text size="S">
                      {amountOnSpot.toLocaleString()} {currency}
                    </Text>
                  </StyledLabeled>
                ) : null}
              </PaymentsBodyData>
              <DetailInformationBodyData>
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'create_at.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">
                    {getViewMonthsNameFromBackendDate(createdAt) ||
                      getHeaderLabeledTranslate(t, 'unknown')}
                  </Text>
                </StyledLabeled>
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'order_status.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">
                    {status?.title || getHeaderLabeledTranslate(t, 'unknown')}
                  </Text>
                </StyledLabeled>
                <StyledLabeled
                  text={getHeaderLabeledTranslate(t, 'guess_amount.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">
                    {guests?.length || getHeaderLabeledTranslate(t, 'unknown')}
                  </Text>
                </StyledLabeled>
              </DetailInformationBodyData>
              <VatBodyData>
                <StyledLabeled
                  text={t('header_information.labeled.vat.label')}
                  gap={5}
                  size="M"
                >
                  <Text size="S">{`${
                    vat
                      ? `${vat} ${currency}`
                      : getHeaderLabeledTranslate(t, 'vat.no_vat')
                  }`}</Text>
                </StyledLabeled>
              </VatBodyData>
            </RowBody>
          }
        />
      </TableWrapper>
      <GuestsWishesWrapper>
        <StyledLabeled
          text={getHeaderLabeledTranslate(t, 'guests_wishes.label')}
          gap={5}
          size="M"
        >
          <Text size="S">
            {getHeaderLabeledTranslate(t, 'guests_wishes.no_wishes')}
          </Text>
        </StyledLabeled>
      </GuestsWishesWrapper>
    </Wrapper>
  );
};

export default HeaderInformation;

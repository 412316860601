import {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import Popup from 'reactjs-popup';
import {always} from 'ramda';
import {Icon} from 'source/components/icon';
import {useRouter} from 'next/router';

const StyledPopup = styled(Popup)`
  &-content {
    height: 100%;
    width: 100%;
    padding: 0;
    overflow: auto;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledModal = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Close = styled(Icon)`
  align-self: flex-end;
`;

const BurgerMobile: FC<{
  children: ReactNode;
}> = ({children}) => {
  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => setOpen(always(true)), []);
  const closeModal = useCallback(() => setOpen(always(false)), []);
  const router = useRouter();

  useEffect(() => {
    if (router) {
      router.events.on('routeChangeStart', closeModal);
    }
  }, [router, closeModal]);

  return (
    <>
      <StyledIcon
        name="burger-menu"
        width={36}
        height={36}
        onClick={openModal}
      />
      <StyledPopup
        modal
        open={open}
        onClose={closeModal}
        closeOnDocumentClick
        lockScroll
      >
        <StyledModal>
          <Close name="cross" width={30} height={30} onClick={closeModal} />
          {children}
        </StyledModal>
      </StyledPopup>
    </>
  );
};

export default BurgerMobile;

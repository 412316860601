import styled from '@emotion/styled';
import * as locales from 'date-fns/locale';
import {FC} from 'react';
import {DateRangePicker, DateRangePickerProps} from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {ELanguage} from 'source/utilities/language';

const StyledDateRangePicker = styled(DateRangePicker)`
  .rdrDateDisplayWrapper,
  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrStartEdge,
  .rdrInRange,
  .rdrEndEdge {
    background-color: ${({theme}) => theme.palette.primaryAccent};
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    border-radius: 0;
  }

  .rdrDayStartPriveiew,
  .rdrDayHovered,
  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview {
    border-radius: unset;
    border-color: ${({theme}) => theme.palette.primaryAccent};
  }

  .rdrDayDisabled .rdrInRange,
  .rdrDayDisabled .rdrStartEdge,
  .rdrDayDisabled .rdrEndEdge,
  .rdrDayDisabled .rdrSelected,
  .rdrDayDisabled .rdrDayStartPreview,
  .rdrDayDisabled .rdrDayInPreview,
  .rdrDayDisabled .rdrDayEndPreview {
    filter: unset;
  }

  .rdrDateRangePickerWrapper {
    transform-origin: left top;
    transform: scale(0.7);
  }

  .rdrDayPassive span {
    color: ${({theme}) => theme.palette.defaultBackground};
  }

  .rdrMonth {
    padding: 0;
  }
  /* Media */

  @media (max-width: 380px) {
    .rdrDateRangePickerWrapper {
      transform: scale(0.7);
    }
  }
`;

interface Props extends Omit<DateRangePickerProps, 'locale'> {
  locale?: string;
}

export const DatePicker: FC<Props> = ({
  locale = ELanguage.RU,
  ...properties
}) => (
  <StyledDateRangePicker
    locale={locales[locale as keyof typeof locales]}
    {...properties}
  />
);

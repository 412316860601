import styled from '@emotion/styled';
import {Icon} from 'source/components/icon';
import {FC} from 'react';
import {Text} from '../text';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextWrapper = styled.div``;

const Description = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
  margin-top: 5px;
`;

interface MessageProps {
  message: string;
  description: string;
}

export const ToastWithDescription: FC<MessageProps> = ({
  message,
  description,
}) => {
  return (
    <Wrapper>
      <Icon name="green-tick" width={19} height={19} />
      <TextWrapper>
        <Text size="boldM">{message}</Text>
        <Description size="boldS">{description}</Description>
      </TextWrapper>
    </Wrapper>
  );
};

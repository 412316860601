import styled from '@emotion/styled';
import DOMPurify from 'isomorphic-dompurify';
import {Badge} from 'library/components/badge';
import {Tooltip} from 'library/components/tooltip';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {getStatusBadgeParameters} from '../lib/helpers';
import {ModerationStatusEnum} from '../lib/types';

const ModerationStatusTrigger = styled.div`
    cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
    &-content {
        max-height: 500px;
        min-width: 300px;
        overflow: auto;
        overflow-x: hidden;
    }
`

interface Props {
  moderationField: ModerationField;
}

const FieldStatus: FC<Props> = ({moderationField}) => {
  const {t} = useTranslation('common');
  const mobile = useDeviceDetection('mobile');

  const status = moderationField?.status.value;
  const {textColor, backgroundColor, label} = getStatusBadgeParameters(status);

  if (status === ModerationStatusEnum.REQUESTED) {
    return (
      <StyledTooltip
        on="hover"
        position="top right"
        disabled={!moderationField.user_comment}
        trigger={
          <ModerationStatusTrigger>
            <Badge
              textColor={textColor}
              textSize={mobile ? 'XS' : 'S'}
              backgroundColor={backgroundColor}
            >
              {t(label)}
            </Badge>
          </ModerationStatusTrigger>
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(moderationField.user_comment),
          }}
        />
      </StyledTooltip>
    );
  }

  if (
    status === ModerationStatusEnum.APPROVED ||
    status === ModerationStatusEnum.DECLINED
  ) {
    return (
      <StyledTooltip
        on="hover"
        position="top right"
        disabled={!moderationField.admin_comment}
        trigger={
          <ModerationStatusTrigger>
            <Badge
              textColor={textColor}
              textSize={mobile ? 'XS' : 'S'}
              backgroundColor={backgroundColor}
            >
              {t(label)}
            </Badge>
          </ModerationStatusTrigger>
        }
      >
        {moderationField?.admin_comment}
      </StyledTooltip>
    );
  }

  return null;
};

export default FieldStatus;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';

interface IProfileState {
  notificationChannels: NotificationChannels | null;
}

const initialState: IProfileState = {
  notificationChannels: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setNotificationChannels: (
      state,
      action: PayloadAction<IProfileState['notificationChannels']>,
    ) => {
      state.notificationChannels = action.payload;
    },
    removeNotificationChannel: (
      state,
      action: PayloadAction<
        NonNullable<IProfileState['notificationChannels']>[number]['id']
      >,
    ) => {
      const {notificationChannels} = state;

      if (!notificationChannels) {
        return;
      }

      state.notificationChannels = notificationChannels.filter(
        (channel) => channel.id !== action.payload,
      );
    },
  },
});

export const selectNotificationChannels = (state: RootState) =>
  state.profile.notificationChannels;

export const {reset, setNotificationChannels, removeNotificationChannel} =
  profileSlice.actions;

export default profileSlice.reducer;

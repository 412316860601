import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {rootReducer} from './root-reducer';

export const store = configureStore({
  reducer: rootReducer,
  // TODO: remove when there are reducers in rootReducer. now it just removes warnings in console about non-serializable value
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: true,
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// typed useDispatch
export const useAppDispatch: () => AppDispatch = useDispatch;
// typed useSelector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

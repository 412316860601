import {FC, Dispatch, SetStateAction} from 'react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';

import {formatMoney} from 'source/utilities/strings';
import {RowBody, RowBodyData} from 'library/components/table';

import {RateHotelService} from 'slices/rate';
import {INCLUDED_TYPES_DUMMY} from 'slices/facilities/lib/constants';
import {
  getType,
  getIncludedTypes,
  getCurrentPriceTypeLabel,
} from 'slices/facilities/lib/helpers';
import {ServiceTableButtons} from '../service-table-buttons';

const StyledRowBodyData = styled(RowBodyData)`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface IProps {
  services: HotelServices;
  translationKey: string;
  addedServices: RateHotelService[];
  isRatePage: boolean;
  onEdit: Dispatch<SetStateAction<HotelService | null>>;
  onDelete: (service: HotelService) => void;
  onAdd: (service: HotelService) => void;
  hideServices?: boolean;
}

export const ServiceTableBody: FC<IProps> = ({
  services,
  translationKey,
  addedServices,
  isRatePage,
  onEdit,
  onDelete,
  onAdd,
  hideServices = false,
}) => {
  const {t} = useTranslation(translationKey);

  return (
    <>
      {services.map((service) => {
        if (!service) {
          return null;
        }

        const {id, price, name, type, include_types} = service;
        const formattedPrice = formatMoney(price);
        const currentType = getType(type, t, translationKey);
        const includedTypes = getIncludedTypes(
          INCLUDED_TYPES_DUMMY,
          include_types,
        );
        const isServiceAlreadyAdded = addedServices.some(
          (item) => item && item.hotel_service_id === id,
        );

        const currentPriceType = addedServices.find(
          (item) => item?.hotel_service_id === id,
        )?.price_type;
        const priceTypeLabel = getCurrentPriceTypeLabel(
          translationKey,
          t,
          currentPriceType,
        );

        return (
          <RowBody key={id}>
            <StyledRowBodyData isFirst>{name}</StyledRowBodyData>
            <StyledRowBodyData>{currentType}</StyledRowBodyData>
            <StyledRowBodyData>{includedTypes}</StyledRowBodyData>
            {!hideServices && (
              <StyledRowBodyData>{formattedPrice}</StyledRowBodyData>
            )}

            {addedServices.length > 0 && (
              <StyledRowBodyData>{priceTypeLabel}</StyledRowBodyData>
            )}
            <StyledRowBodyData isLast>
              <ButtonsWrapper>
                <ServiceTableButtons
                  isRatePage={isRatePage}
                  isServiceAlreadyAdded={isServiceAlreadyAdded}
                  onEdit={onEdit}
                  onAdd={onAdd}
                  onDelete={onDelete}
                  service={service}
                />
              </ButtonsWrapper>
            </StyledRowBodyData>
          </RowBody>
        );
      })}
    </>
  );
};

import {isNil} from 'ramda';
import {useContext} from 'react';
import {HotelContext} from 'source/context/hotel';

type BnovoIntegrationHookReturn = [
  boolean,
  Partial<Hotel['bnovo_integration']>,
];

/**
 * Возвращает интегрирован ли с Bnovo отель, и объект с данными
 * @return [hasIntegration, Hotel["bnovo_integration"]]
 */
export const useBnovoIntegration = (): BnovoIntegrationHookReturn => {
  const [hotel] = useContext(HotelContext);

  if (!hotel) {
    return [false, undefined];
  }

  const hasIntegration =
    hotel &&
    'bnovo_integration' in hotel &&
    !isNil(hotel.bnovo_integration) &&
    hotel.bnovo_integration.is_configured;

  return [hasIntegration, hotel?.bnovo_integration];
};

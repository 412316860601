import {IconName} from 'source/components/icon';

export enum NotificationChannelType {
  EMAIL = 1,
  TELEGRAM = 2,
}

export type NotificationChannelIcons = Record<
  NotificationChannelType,
  IconName
>;

import type {GetServerSideProps} from 'next';

import {andThen, assoc, call, pipeWith} from 'ramda';
import {INITIAL_FILTERS, INITIAL_PAGE} from 'slices/bookings/lib/constants';
import {getQueryObjectFromFilters} from 'slices/bookings/lib/helpers';
import {BookingsPageProperties} from 'slices/bookings/lib/types';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';

import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {clientHeaders, serverHeaders} from 'source/utilities/network/http';
import {removeUndefined} from 'source/utilities/object';
import {ApplicationProperties} from 'source/utilities/ui';
import {BookingsListParameters, BookingsListResponse} from 'types/api-scheme';

export const getBookingsPageProperties: GetServerSideProps<
  BookingsPageProperties
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const getBookings = () =>
    api.bookings.get({
      page: `${INITIAL_PAGE}`,
      headers,
      parameters: getQueryObjectFromFilters(INITIAL_FILTERS).parameters,
    });
  const getBookingPaymentMethods = () =>
    api.directories.getBookingPaymentMethods({headers});
  const getBookingHotelierStatuses = () =>
    api.directories.getBookingHotelierStatuses({headers});
  const getPaymentVerification = () =>
    api.directories.getBookingPaymentVerification({headers});
  const getBookingSorts = () => api.directories.getBookingSorts({headers});
  const getBookingStatuses = () =>
    api.directories.getBookingStatuses({headers});

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        const [
          bookings,
          bookingPaymentMethods,
          bookingHotelierStatuses,
          paymentVerification,
          bookingSorts,
          bookingStatuses,
        ] = await Promise.all([
          getBookings(),
          getBookingPaymentMethods(),
          getBookingHotelierStatuses(),
          getPaymentVerification(),
          getBookingSorts(),
          getBookingStatuses(),
        ]);
        const filteredProperties = removeUndefined({
          ...properties,
          bookings,
          bookingPaymentMethods,
          bookingHotelierStatuses,
          paymentVerification,
          bookingSorts,
          bookingStatuses,
        });
        return assoc('props', filteredProperties, {});
      },
    ]),
  ).catch(handleException);
};

export const loadBookings = async (
  page: number,
  parameters: BookingsListParameters,
  onSuccess: (bookings: BookingsListResponse) => void,
) => {
  const queryParameters = getQueryObjectFromFilters({...parameters, page});
  await api.bookings
    .get({
      page: queryParameters.page,
      headers: clientHeaders(),
      parameters: queryParameters.parameters,
    })
    .then((data) => {
      if (data) {
        onSuccess(data);
      }
    });
};

export const searchResolver = (phrase: string, page?: number) =>
  api.hotels.searchMyHotels({phrase, page});

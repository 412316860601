import {useContext} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';

import {UserContext} from 'source/context/user';
import {Routes} from 'source/utilities/network/url';
import {Text} from 'library/components/text';
import {NavigationLink} from 'library/components/navigation-link';
import UserBalance from '../user-balance';

const StyledText = styled(Text)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledNavigationLink = styled(NavigationLink)`
  margin-top: 12px;
  gap: 12px;
`;

const HotelierInfo = () => {
  const [user] = useContext(UserContext);
  const theme = useTheme();
  const {t} = useTranslation('common');

  return (
    <>
      <StyledText size="S" color={theme.palette.fontSecondary}>
        {user?.email}
      </StyledText>
      <UserBalance />
      <StyledNavigationLink href={Routes.profile} iconName="user-icon">
        {t('routing:profile')}
      </StyledNavigationLink>
    </>
  );
};

export default HotelierInfo;

import {FC, MouseEvent} from 'react';
import styled from '@emotion/styled';
import {AddImagesIcon} from 'library/icons/add-images-icon';
import {Headline} from 'library/components/headline';
import useTranslation from 'next-translate/useTranslation';
import {ButtonLink} from 'library/components/button-link';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 16px 20px 30px 20px;
  background: ${({theme}) => theme.palette.defaultBackground};
  height: 100%;
  &::after {
    position: absolute;
    content: '';
    width: 80%;
    height: 1px;
    bottom: 0;
    background: ${({theme}) => theme.palette.border};
  }
`;

const Title = styled(Headline)`
  margin-top: 2px;
  text-align: center;

  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 16px;
    line-height: 18px;
  }
  @media (min-width: 800px) and (max-width: 1000px) {
    font-size: 16px;
    line-height: 18px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

const Subtitle = styled.p`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${({theme}) => theme.palette.primaryAccent};

  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 14px;
  }

  @media (min-width: 800px) and (max-width: 1000px) {
    font-size: 16px;
    line-height: 18px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

const Link = styled(ButtonLink)`
  width: 100%;
  margin-bottom: 0;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: ${({theme}) => theme.palette.defaultBackground};
  text-transform: uppercase;

  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 14px;
  }
  @media (min-width: 800px) and (max-width: 1000px) {
    font-size: 14px;
  }
  @media (max-width: 700px) {
    font-size: 14px;
  }
  @media (max-width: 570px) {
    font-size: 12px;
  }
`;

const IconWrapper = styled.div`
  width: 51px;

  @media (min-width: 480px) and (max-width: 570px) {
    height: 35px;
    width: 35px;
    svg {
      height: 35px;
      width: 35px;
    }
  }
`;

type Props = {
  href: string;
};

const clickHandlerLink = (event: MouseEvent<HTMLAnchorElement>) =>
  event.stopPropagation();

export const AddImagesCard: FC<Props> = ({href}) => {
  const {t} = useTranslation('hotel-info');
  return (
    <Wrapper>
      <IconWrapper>
        <AddImagesIcon />
      </IconWrapper>
      <Title level="M">{t('add_image.title')}</Title>
      <Subtitle>{t('add_image.subtitle')}</Subtitle>
      <Link onClick={clickHandlerLink} href={href} variant="primary">
        {t('add_image.text_btn')}
      </Link>
    </Wrapper>
  );
};

import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {Headline} from 'library/components/headline';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useContext} from 'react';

import {DEFAULT_FIELD_VALUE} from 'slices/profile/lib/constants';
import {Icon} from 'source/components/icon';
import {UserContext} from 'source/context/user';

import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const ProfileRowsWrapper = styled.div``;

const ProfileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.palette.activeBackground};
  padding: 19px 0px;

  &:last-child {
    border-bottom: 1px solid ${({theme}) => theme.palette.activeBackground};
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }
`;

const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 8px;
`;

const HeadlineIcon = styled(Icon)`
  margin-right: 17px;
`;

const ProfileColumnFieldNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ProfileColumnFieldName = styled(Text)`
  width: 220px;
  @media (max-width: 480px) {
    margin-bottom: 7px;
  }
`;

const ProfileColumnFieldValue = styled(Text)`
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ChangeAction = styled(Text)`
  cursor: not-allowed;
  opacity: 0.5;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 21px;
`;

const ProfileInfo = () => {
  const {t} = useTranslation('profile');
  const [user] = useContext(UserContext);
  const theme = useTheme();
  const mobile = useDeviceDetection('mobile');
  const textSize = mobile ? 'XS' : 'S';

  const fields = [
    {
      label: 'fields.first_name',
      value: user?.first_name,
    },
    {
      label: 'fields.last_name',
      value: user?.last_name,
    },
    {
      label: mobile ? 'fields.short_email' : 'fields.email',
      value: user?.email,
    },
    {
      label: 'fields.phone',
      value: user?.phone,
    },
  ];

  return (
    <>
      <HeadlineWrapper>
        <HeadlineIcon name="profile" width={36} height={36} />
        <Headline level="L">{t('headline')}</Headline>
      </HeadlineWrapper>
      <ProfileRowsWrapper>
        <StyledHeadline level="M">{t('fields.headline')}</StyledHeadline>
        {fields.map((field) => {
          if (mobile) {
            return (
              <ProfileRow key={field.label}>
                <ProfileColumnFieldNameWrapper>
                  <ProfileColumnFieldName
                    color={theme.palette.fontSecondary}
                    size={textSize}
                  >
                    {t(field.label)}
                  </ProfileColumnFieldName>
                  <ChangeAction
                    color={theme.palette.fontPrimary}
                    size={textSize}
                  >
                    {t('change_field')}
                  </ChangeAction>
                </ProfileColumnFieldNameWrapper>
                <ProfileColumnFieldValue size={textSize}>
                  {field.value || DEFAULT_FIELD_VALUE}
                </ProfileColumnFieldValue>
              </ProfileRow>
            );
          }
          return (
            <ProfileRow key={field.label}>
              <ProfileColumnFieldName
                color={theme.palette.fontSecondary}
                size={textSize}
              >
                {t(field.label)}
              </ProfileColumnFieldName>
              <ProfileColumnFieldValue size={textSize}>
                {field.value || DEFAULT_FIELD_VALUE}
              </ProfileColumnFieldValue>
              <ChangeAction color={theme.palette.fontPrimary} size={textSize}>
                {t('change_field')}
              </ChangeAction>
            </ProfileRow>
          );
        })}
      </ProfileRowsWrapper>
    </>
  );
};

export default ProfileInfo;

import {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {clientHeaders, serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {RoomsPageProperties} from './lib/types';

export const getRoomsList = async (
  page: number,
  hotelId: number | string | string[],
  headers?: HeadersInit,
) =>
  api.rooms.get({
    hotelId: transformToNumber(hotelId),
    page: page.toString(),
    headers,
  });

export const getRoomsPageProperties: GetServerSideProps<
  RoomsPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () => api.hotel.get({hotelId, headers});

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, rooms] = await Promise.all([
            requestHotel(),
            getRoomsList(1, hotelId, headers),
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            rooms,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const deleteRoom = async (
  hotelId: number,
  roomId: number,
  onComplete: () => void,
) => {
  api.room
    .delete({hotelId, roomId, headers: clientHeaders()})
    .then(onComplete)
    .catch(handleException);
};

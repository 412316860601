import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';

import {Text} from 'library/components/text';
import HumanIcon from 'source/components/icon/assets/human.svg';
import {isNumber} from 'source/utilities/guards/types';
import {hasBnovoGuestNumberConstraints} from 'slices/calendar/lib/helpers/dates';

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RestrictionItem = styled(Text)`
  font-weight: 500;
  margin-left: 7px;
`;

const Item = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Human = styled(HumanIcon)`
  width: 15px;
  height: 15px;
`;

interface Props {
  bnovoConstraint?: CalendarBnovoConstraint | null;
  rateId: Rate['id'];
  dayDate: Date;
}

export const BnovoRestrictionTooltip: FC<Props> = ({
  bnovoConstraint,
  rateId,
  dayDate,
}) => {
  const {t} = useTranslation('calendar');

  if (!bnovoConstraint) {
    return null;
  }

  return (
    <TooltipContainer>
      {bnovoConstraint.by_guests_number?.map((guestNumber) => {
        const {
          guests_number,
          min_stay,
          min_stay_arrival,
          max_stay,
          closed,
          closed_arrival,
          closed_departure,
        } = guestNumber;

        if (!hasBnovoGuestNumberConstraints(guestNumber)) {
          return null;
        }

        return (
          <Item key={`bnovo_constraint_rate_${rateId}_date_${dayDate}`}>
            <IconWrapper>
              <RestrictionItem size="boldXS">
                {t('restriction_rules.bnovo.guest_number')}
              </RestrictionItem>
              <RestrictionItem size="boldXS">{`${guests_number}x`}</RestrictionItem>
              <Human />
            </IconWrapper>
            {isNumber(min_stay) && (
              <RestrictionItem size="XS">
                - {t('restriction_rules.bnovo.min_stay', {count: min_stay})}
              </RestrictionItem>
            )}
            {isNumber(min_stay_arrival) && (
              <RestrictionItem size="XS">
                -{' '}
                {t('restriction_rules.bnovo.min_stay_arrival', {
                  count: min_stay_arrival,
                })}
              </RestrictionItem>
            )}
            {isNumber(max_stay) && (
              <RestrictionItem size="XS">
                - {t('restriction_rules.bnovo.max_stay', {count: max_stay})}
              </RestrictionItem>
            )}
            {closed && (
              <RestrictionItem size="XS">
                - {t('restriction_rules.bnovo.closed')}
              </RestrictionItem>
            )}
            {closed_arrival && (
              <RestrictionItem size="XS">
                - {t('restriction_rules.bnovo.closed_arrival')}
              </RestrictionItem>
            )}
            {closed_departure && (
              <RestrictionItem size="XS">
                - {t('restriction_rules.bnovo.closed_departure')}
              </RestrictionItem>
            )}
          </Item>
        );
      })}
    </TooltipContainer>
  );
};

import {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';

import {ApplicationProperties} from 'source/utilities/ui';
import {LandmarksPageProperties} from './lib/types';

export const getLandmarksPageProperties: GetServerSideProps<
  LandmarksPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = api.hotel.get({
    hotelId,
    headers,
  });

  const requestHotelLandmarks = api.landmarks.get({
    hotelId,
    headers,
  });

  const requestLandmarksNear = api.landmarks.getNear({
    hotelId,
    headers,
  });

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, hotelLandmarks, landmarksNear] = await Promise.all([
            requestHotel,
            requestHotelLandmarks,
            requestLandmarksNear,
          ]);

          const result = removeUndefined({
            ...properties,
            hotel,
            hotelLandmarks,
            landmarksNear,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const saveLandmarks = (
  hotelId: Hotel['id'],
  landmarks: HotelLandmarks,
) => api.landmarks.save({hotelId, payload: {landmarks}}).catch(handleException);

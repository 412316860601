import type {ApplicationProperties} from 'source/utilities/ui';

export type IndividualEnterpreneurFields = Array<
  'name' | 'account' | 'address' | 'inn' | 'bik' | 'ogrnip'
>;
export type JuridicalPersonFields = Array<
  'name' | 'account' | 'address' | 'inn' | 'bik' | 'kpp' | 'ogrn'
>;
export type PhysicalPersonFields = Array<
  'name' | 'account' | 'address' | 'inn' | 'bik'
>;
export type SelfEmployedFields = Array<
  'name' | 'account' | 'address' | 'inn' | 'bik'
>;

export type InputKey =
  | 'address'
  | 'account'
  | 'inn'
  | 'bik'
  | 'kpp'
  | 'name'
  | 'ogrn'
  | 'document_flow'
  | 'ogrnip';

export interface RequisitesPageProperties extends ApplicationProperties {
  requisites?: Requisites | null;
  existingRequisites?: ExistingRequisites;
  hotel?: Hotel;
}

export enum VerificationPaymentStatus {
  Init = 1,
  New = 2,
  Paid = 3,
  Error = 4,
  Canceled = 5,
}

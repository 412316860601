import {useState, ChangeEvent, FC, useEffect} from 'react';
import Popup from 'reactjs-popup';
import useTranslation from 'next-translate/useTranslation';
import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';
import styled from '@emotion/styled';
import {css} from '@emotion/react';

import {useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {ArrowText} from 'library/components/arrow-text';
import {Button} from 'library/components/button';
import {Option, Select} from 'library/components/select';
import {Input} from 'library/components/input';
import {Text} from 'library/components/text';
import {Checkbox} from 'library/components/checkbox';
import {CircularLoader} from 'library/components/loader';
import {Headline} from 'library/components/headline';

import {
  INITIAL_ERRORS,
  MIN_SERVICE_PRICE,
  SERVICE_TYPES,
} from 'slices/facilities/lib/constants';
import {
  getMealCheckboxValue,
  getCurrentMealOptions,
  getCurrentTypeOption,
  getTranslatedOption,
  getTranslatedOptions,
  normalizeCreateServicePayload,
} from 'slices/facilities/lib/helpers';
import {EServiceTypeValues} from 'slices/facilities/lib/types';
import {
  selectMealTypesDirectory,
  selectServices,
} from 'slices/facilities/store';

import ServiceTable from '../service-table';

const PopupWrapper = styled.div<{isTouch: boolean}>`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 25px;
  overflow-y: scroll;
  height: 100%;

  ${({isTouch}) =>
    isTouch &&
    css`
      max-height: 80vh;
      padding-bottom: 15px;

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
    `}
`;

const Wrapper = styled.div`
  padding: 20px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 4px;
`;

const Title = styled(Headline)`
  margin-bottom: 10px;
`;

const RowTitle = styled(Text)<{isError?: boolean}>`
  min-width: max-content;

  ${({theme, isError}) =>
    isError &&
    css`
      color: ${theme.palette.fontDanger};
    `}
`;

const StyledPopup = styled(Popup)<{mobile: boolean}>`
  &-content {
    padding: 40px 25px 0 25px;
    width: 100%;
    max-width: 550px;

    ${({mobile}) =>
      mobile &&
      css`
        max-width: 95vw;
        padding: 25px 15px 0 15px;
      `}
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 62px;
  padding: 0;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledPopupButton = styled(Button)<{mobile: boolean}>`
  margin-bottom: 5px;
  &:last-of-type {
    margin-bottom: 0;
  }

  ${({mobile}) =>
    mobile &&
    css`
      width: 100%;
      padding: 15px;
    `}
`;

const CloseButton = styled(Button)<{mobile: boolean}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  top: 5px;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  padding: 0px;
  border: 0px;

  ${({mobile}) =>
    mobile &&
    css`
      width: 32px;
      height: 32px;
    `}
`;

const Message = styled.span`
  color: ${({theme}) => theme.palette.fontDanger};
`;

interface IProps {
  onCreate: (service: ClientHotelService) => void;
  onEdit: (setviceId: number, service: ClientHotelService) => void;
  onDelete: (service: HotelService) => void;
  loading: boolean;
  open: boolean;
  setOpen: BooleanDispatch;
}

const Services: FC<IProps> = ({
  onCreate,
  loading,
  open,
  setOpen,
  onEdit,
  onDelete,
}) => {
  const mobile = useDeviceDetection('mobile');
  const isTouch = useDeviceDetection('touch');
  const {t} = useTranslation('facilities');
  const [name, setName] = useState('');
  const [price, setPrice] = useState(MIN_SERVICE_PRICE);
  const [typeOption, setTypeOption] = useState(
    getTranslatedOption(SERVICE_TYPES[0], t),
  );
  const mealTypesDirectory = useAppSelector(selectMealTypesDirectory);
  const [mealTypes, setMealTypes] = useState<ClientIncludeTypes>([]);
  const [errors, setErrors] = useState(INITIAL_ERRORS);
  const [editService, setEditService] = useState<HotelService | null>(null);
  const [isEdited, setIsEdited] = useState(false);
  const services = useAppSelector(selectServices);
  const isMealType = typeOption.value === EServiceTypeValues.MEAL;

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);
    setPrice(Number(event.target.value));
  };

  const handleTypeChange = (checked: boolean, checkboxType: MealType) => {
    if (errors.type) {
      setErrors((previous) => ({...previous, type: false}));
    }

    setIsEdited(true);

    setMealTypes((previous) => {
      if (checked) {
        return [...previous, checkboxType.value];
      }

      return previous.filter((mealType) => mealType !== checkboxType.value);
    });
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);
    setName(event.target.value);
  };

  const handleSelectType = (option: Option) => {
    setIsEdited(true);
    setTypeOption(option);
  };

  const handleResetForm = () => {
    setOpen(false);
    setName('');
    setPrice(MIN_SERVICE_PRICE);
    setTypeOption(getTranslatedOption(SERVICE_TYPES[0], t));
    setMealTypes([]);
    setErrors(INITIAL_ERRORS);
    setEditService(null);
    setIsEdited(false);
  };

  const handleSubmitService = () => {
    const normalizedPayload = normalizeCreateServicePayload(
      mealTypes,
      typeOption.value,
      name,
      price,
    );

    if (!name) {
      setErrors((previous) => ({...previous, name: true}));
      return;
    }

    if (isMealType && normalizedPayload.include_types?.length === 0) {
      setErrors((previous) => ({...previous, type: true}));
      return;
    }

    if (editService) {
      onEdit(editService.id, normalizedPayload);
    } else {
      onCreate(normalizedPayload);
    }

    setOpen(false);
  };

  useEffect(() => {
    // При редактировании услуги сеттится editService и открывается модалка
    if (editService) {
      setOpen(true);
      const {name, price, type, include_types} = editService;
      const currentTypeOption = getCurrentTypeOption(type);
      const translatedOption = getTranslatedOption(currentTypeOption, t);

      setName(name || '');
      setPrice(price);
      setTypeOption(translatedOption);

      if (isMealType && include_types) {
        const mealTypes = getCurrentMealOptions(include_types);

        setMealTypes(mealTypes);
      }
    }
  }, [editService]);

  return (
    <ArrowText
      title={t('meal_and_services')}
      size={mobile ? 'boldS' : 'boldL'}
      parentState
    >
      <Wrapper>
        <StyledPopup
          modal
          open={open}
          onClose={handleResetForm}
          closeOnEscape
          closeOnDocumentClick
          mobile={mobile}
          lockScroll
        >
          <CloseButton
            onClick={() => setOpen(false)}
            disabled={loading}
            mobile={mobile}
          >
            <CloseIcon />
          </CloseButton>
          <Title level={mobile ? 'M' : 'L'}>
            {t(`${editService ? 'service_edit' : 'service_addition'}`)}
          </Title>
          <PopupWrapper isTouch={isTouch}>
            <Row>
              <Input
                value={name}
                type="text"
                placeholder={t('placeholder.name')}
                label={t('properties.name')}
                onChange={handleNameChange}
                force={errors.name ? 'required' : 'pristine'}
                required
              />
            </Row>
            <Row>
              <RowTitle size="S">{t('properties.type')}</RowTitle>
              <Select
                value={typeOption}
                options={getTranslatedOptions(SERVICE_TYPES, t)}
                setValue={handleSelectType}
              />
            </Row>
            {isMealType && (
              <Row>
                <RowTitle isError={errors.type} size="S">
                  {t('properties.include_types')}
                </RowTitle>

                {mealTypesDirectory?.map((mealType) => (
                  <Checkbox
                    key={`meal-type_${mealType.title}_${mealType.value}`}
                    label={mealType.title}
                    value={getMealCheckboxValue(mealType, mealTypes)}
                    isMobile={mobile}
                    onChange={(value) => handleTypeChange(value, mealType)}
                  />
                ))}

                {errors.type && <Message>{t('type_required')}</Message>}
              </Row>
            )}

            <Row>
              <Input
                value={String(price)}
                type="number"
                placeholder={t('placeholder.price')}
                label={t('properties.price')}
                onChange={handlePriceChange}
              />
            </Row>
            <Row>
              <StyledPopupButton
                hoverOpacity
                mobile={mobile}
                disabled={loading || !isEdited}
                onClick={handleSubmitService}
              >
                {loading ? (
                  <CircularLoader size={32} />
                ) : (
                  t(`${editService ? 'edit' : 'add'}`)
                )}
              </StyledPopupButton>
              <StyledPopupButton
                hoverOpacity
                disabled={loading}
                onClick={handleResetForm}
                mobile={mobile}
              >
                {t('cancel')}
              </StyledPopupButton>
            </Row>
          </PopupWrapper>
        </StyledPopup>
        <Row>
          <StyledButton
            disabled={loading}
            onClick={() => setOpen(true)}
            hoverOpacity
          >
            {loading ? <CircularLoader size={32} /> : t('add_new_service')}
          </StyledButton>
        </Row>
        <Row>
          <ServiceTable
            loading={loading}
            onEdit={setEditService}
            onDelete={onDelete}
            services={services}
            translationKey="facilities"
          />
        </Row>
      </Wrapper>
    </ArrowText>
  );
};

export default Services;

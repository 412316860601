import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsFacilitiesDetailResponse,
  HotelsFacilitiesPartialUpdateResponse,
} from 'types/api-scheme';

type GetFacilitiesContext = {
  hotelId: number;
  headers: Headers;
};
const getFacilities = ({hotelId, headers}: GetFacilitiesContext) =>
  request<HotelsFacilitiesDetailResponse>(url(`hotels/${hotelId}/facilities`), {
    method: 'GET',
    headers,
  }).then(path<HotelsFacilitiesDetailResponse['data']>([1, 'data']));

type ToggleFacilityContext = {
  hotelId: number;
  rawFacilityId: number;
  payload: {
    enabled?: boolean;
  };
};
const toggleFacility = ({
  hotelId,
  rawFacilityId,
  payload: {enabled},
}: ToggleFacilityContext) =>
  request<HotelsFacilitiesPartialUpdateResponse>(
    url(`hotels/${hotelId}/facilities/${rawFacilityId}`),
    {
      method: 'PATCH',
      headers: clientHeaders(),
      body: JSON.stringify({enabled}),
    },
  );

type SaveFacilityContext = {
  hotelId: Hotel['id'];
  payload: {
    facilities: HotelFacilities;
  };
};
const saveFacilities = ({hotelId, payload}: SaveFacilityContext) =>
  request<HotelsFacilitiesDetailResponse>(url(`hotels/${hotelId}/facilities`), {
    method: 'PATCH',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsFacilitiesDetailResponse['data']>([1, 'data']));

export const facilitiesApi = {
  get: getFacilities,
  save: saveFacilities,
  toggle: toggleFacility,
};

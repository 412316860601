import {useEffect, useState} from 'react';
import {
  APPLICATION_OS_TYPE_LOCAL_STORAGE_KEY,
  isClient,
} from '../guards/environment';
import {OperationSystems} from '../enums/os';

export const useWebviewOperationSystem = () => {
  const [applicationOs, setApplicationOs] = useState<OperationSystems | null>(
    null,
  );
  const localStorageApplicationOs =
    isClient() && localStorage.getItem(APPLICATION_OS_TYPE_LOCAL_STORAGE_KEY);

  useEffect(() => {
    const operationSystemEnumValues = Object.values(OperationSystems);
    const typedApplicationOs = localStorageApplicationOs as OperationSystems;

    if (
      typedApplicationOs &&
      operationSystemEnumValues.includes(typedApplicationOs)
    ) {
      setApplicationOs(typedApplicationOs);
    }
  }, [localStorageApplicationOs]);

  return applicationOs;
}

import {isEmpty} from 'ramda';
import {useContext} from 'react';
import {HotelContext} from 'source/context/hotel';

type BnovoIntegrationHookReturn = [boolean, RealtyIntegration[]];

/**
 * Возвращает интегрирована ли комната с Realty, и массив интеграций
 *  * @return [hasIntegration, RealtyIntegration[]]
 * (is_deleted - интеграция удалена, но в списке интеграций она все равно будет, чтобы понимать, что её можно восстановить)
 * У Realty каждый номер - объект, у нас же каждый объект - отель
 * Т.е. у нас каждая комната - новая интеграция с realty. После создания интеграции её можно удалить/восстановить
 * При создании интеграции мы можем выбрать один из созданных номеров или создать новый,
 * после создания интеграции добавить один и тот же номер нельзя, т.е. 1 номер = 1 интеграция
 */
export const useRealtyIntegration = (): BnovoIntegrationHookReturn => {
  const [hotel] = useContext(HotelContext);

  if (!hotel) {
    return [false, []];
  }

  const hasIntegrations =
    hotel &&
    'realty_integrations' in hotel &&
    !isEmpty(hotel.realty_integrations);

  const enabledIntegrations = hasIntegrations
    ? hotel.realty_integrations.filter(({is_deleted}) => !is_deleted)
    : [];

  const hasIntegration = enabledIntegrations.length > 0;

  return [hasIntegration, hotel.realty_integrations];
};

import styled from '@emotion/styled';
import {Badge} from 'library/components/badge';
import {Tooltip} from 'library/components/tooltip';
import {ExclamationMarkIcon} from 'library/icons/exclamation-mark';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {DEFAULT_VALUE_FOR_MODERATION_ID} from 'slices/moderation/lib/constants';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {getStatusBadgeParameters} from '../lib/helpers';
import {ModerationStatusEnum} from '../lib/types';

const ModerationStatusTrigger = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  hotelModerationLast?: HotelModeraionLast;
}

const HotelStatus: FC<Props> = ({hotelModerationLast}) => {
  const {t} = useTranslation('common');
  const mobile = useDeviceDetection('mobile');
  const status = hotelModerationLast?.status.value;
  const {textColor, backgroundColor, label} = getStatusBadgeParameters(status);

  if (hotelModerationLast?.id === DEFAULT_VALUE_FOR_MODERATION_ID) {
    return null;
  }

  if (status === ModerationStatusEnum.REQUESTED) {
    return (
      <Badge
        textColor={textColor}
        textSize={mobile ? 'XS' : 'S'}
        backgroundColor={backgroundColor}
      >
        {t(label)}
      </Badge>
    );
  }

  if (
    status === ModerationStatusEnum.APPROVED ||
    status === ModerationStatusEnum.DECLINED
  ) {
    return (
      <Tooltip
        on="hover"
        position="bottom left"
        minWidth={300}
        disabled={!hotelModerationLast?.admin_comment}
        trigger={
          <ModerationStatusTrigger>
            <Badge
              textColor={textColor}
              textSize={mobile ? 'XS' : 'S'}
              backgroundColor={backgroundColor}
            >
              {t(label)}
            </Badge>
            {status === ModerationStatusEnum.DECLINED ? (
              <ExclamationMarkIcon />
            ) : null}
          </ModerationStatusTrigger>
        }
      >
        {hotelModerationLast?.admin_comment}
      </Tooltip>
    );
  }

  return null;
};

export default HotelStatus;

import {
  __,
  all,
  allPass,
  any,
  assoc,
  equals,
  gt,
  lt,
  map,
  not,
  pathOr,
  pipe,
  prop,
} from 'ramda';
import {FormEvent, useCallback, useContext, useState, useEffect} from 'react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';

import {UserContext} from 'source/context/user';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {
  showSuccessMessage,
  showException,
} from 'source/utilities/exceptions/business';

import {Button} from 'library/components/button';
import {Input} from 'library/components/input';
import {Text} from 'library/components/text';

import {
  getNewPasswordInputType,
  getPasswordInputIcon,
  PASSWORD_INPUT_TYPE,
} from 'slices/password-reset';
import {changePassword} from 'slices/profile/network';
import {INITIAL_CHANGE_PASSWORD_STATE} from 'slices/profile/lib/constants';
import {ChangePasswordType} from 'slices/profile/lib/types';

const StyledInput = styled(Input)`
  width: 440px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 440px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ChangePasswordForm = () => {
  const {t} = useTranslation('profile');
  const [loading, setLoading] = useState(false);
  const [user] = useContext(UserContext);
  const mobile = useDeviceDetection('mobile');
  const [passwordInputType, setPasswordInputType] =
    useState<PASSWORD_INPUT_TYPE>(PASSWORD_INPUT_TYPE.PASSWORD);
  const [passwordRepeatInputType, setPasswordRepeatInputType] =
    useState<PASSWORD_INPUT_TYPE>(PASSWORD_INPUT_TYPE.PASSWORD);

  const [{password, password_repeat}, setFormdata] =
    useState<ChangePasswordType>(INITIAL_CHANGE_PASSWORD_STATE);

  const handleChange = useCallback(
    (key: keyof ChangePasswordType) =>
      pipe(pathOr('', ['target', 'value']), assoc(key), setFormdata),
    [],
  );

  const filled = all(
    allPass([gt(__, 0), lt(__, 256)]),
    map(prop('length'), [password, password_repeat]),
  );

  const passwordsEquals = password === password_repeat;

  const onSuccess = () => {
    showSuccessMessage(t('change_password_success'));
    setFormdata(INITIAL_CHANGE_PASSWORD_STATE);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (user) {
      setLoading(true);

      const payload = {
        new_password: password,
        first_name: user.first_name,
      };

      await changePassword(payload, onSuccess, showException, setLoading);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Text size={mobile ? 'boldS' : 'boldM'}>{t('change_password')}</Text>
      <StyledInput
        type={passwordInputType}
        name="password"
        minLength={8}
        maxLength={255}
        label={null}
        placeholder={t('components:placeholders.new_password_input')}
        value={password}
        disabled={loading}
        onChange={handleChange('password')}
        icon={getPasswordInputIcon(passwordInputType)}
        iconWidth={20}
        iconHeight={20}
        iconOnClick={() =>
          setPasswordInputType((previous) => getNewPasswordInputType(previous))
        }
      />
      <StyledInput
        type={passwordRepeatInputType}
        name="password_repeat"
        minLength={8}
        maxLength={255}
        label={null}
        shouldEqualValue={password}
        shouldEqualMessage={t('passwords_not_equal')}
        placeholder={t('components:placeholders.password_repeat_input')}
        value={password_repeat}
        disabled={loading}
        onChange={handleChange('password_repeat')}
        icon={getPasswordInputIcon(passwordRepeatInputType)}
        iconWidth={20}
        iconHeight={20}
        iconOnClick={() =>
          setPasswordRepeatInputType((previous) =>
            getNewPasswordInputType(previous),
          )
        }
      />
      <StyledButton
        type="submit"
        disabled={any(equals(true), [
          loading,
          not(filled),
          not(passwordsEquals),
        ])}
      >
        {t('save_new_password')}
      </StyledButton>
    </Form>
  );
};

export default ChangePasswordForm;

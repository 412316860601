import {
  assoc,
  find,
  findIndex,
  lensIndex,
  map,
  over,
  propEq,
  propOr,
} from 'ramda';
import {Translate} from 'next-translate';

import {textEquals} from 'source/utilities/filters';
import {Option} from 'library/components/select';

import {PRICE_TYPE_OPTIONS, EPriceType} from 'slices/rate';
import {
  SERVICE_TYPES,
  MEAL_TYPES_SEPARATOR,
  PRICE_TYPES_DUMMY,
  OTHER_TYPE_SERVICE_INCLUDE_TYPES,
} from './constants';
import {
  FacilitiesState,
  FacilitiesStateFacility,
  FacilitiesStateGroup,
  EServiceTypeValues,
} from './types';

export const getHotelFacilities = (
  hotelFacilities?: HotelFacilities,
  rawFacilities?: RawFacilities,
) => {
  if (!hotelFacilities || !rawFacilities) {
    return [];
  }

  const addEnabled = (facility: RawFacility) => {
    const isEnabled = propOr(
      false,
      'enabled',
      find(
        (facilityItem) => facilityItem.facility_id === facility.id,
        hotelFacilities,
      ),
    );
    return assoc('enabled', isEnabled, facility);
  };

  const updateFacilities = (item: RawFacilityGroup) => {
    const updatedFacilities = map(addEnabled, item.facilities);
    return assoc('facilities', updatedFacilities, item);
  };

  return map(updateFacilities, rawFacilities);
};

export const updateToggledFacility = (
  facilities: FacilitiesState,
  facility: FacilitiesStateFacility,
) => {
  const findFacilityIndexById = findIndex(propEq('id', facility.id));
  const updateFacility = assoc('enabled', !facility.enabled);

  const updateFacilities = (group: FacilitiesStateGroup) => {
    const indexToUpdate = findFacilityIndexById(group.facilities);
    if (indexToUpdate >= 0) {
      const updatedFacilities = over(
        lensIndex(indexToUpdate),
        updateFacility,
        group.facilities,
      );
      return assoc('facilities', updatedFacilities, group);
    }
    return group;
  };

  return map(updateFacilities, facilities);
};

export const facilityGroupFilter = (
  facilities: FacilitiesState,
  searchText: string,
) => {
  return facilities.filter((group: FacilitiesStateGroup) =>
    group.facilities.find((facility: FacilitiesStateFacility) =>
      textEquals(facility.name, searchText),
    ),
  );
};

export const facilityFilter = (
  group: FacilitiesStateGroup,
  searchText: string,
) => {
  return group.facilities.filter((facility: FacilitiesStateFacility) =>
    textEquals(facility.name, searchText),
  );
};

export const getMealCheckboxValue = (
  currentMealType: MealType,
  mealTypes: number[],
): boolean => mealTypes.includes(currentMealType.value);

export const normalizeCreateServicePayload = (
  mealTypes: ClientIncludeTypes,
  type: EServiceTypeValues,
  name: string,
  price: number,
) => {
  const include_types =
    type === EServiceTypeValues.MEAL
      ? mealTypes
      : OTHER_TYPE_SERVICE_INCLUDE_TYPES;

  return {name, price, include_types, type};
};

export const getType = (type: number, t: Translate, translationKey: string) =>
  t(
    `${translationKey}:${SERVICE_TYPES.find((item) => item.value === type)
      ?.label}`,
  );

export const getIncludedTypes = (
  defaultValue: string,
  includedTypes?: HotelServiceIncludeType,
) =>
  includedTypes && includedTypes.length > 0
    ? includedTypes.map((type) => type.title).join(MEAL_TYPES_SEPARATOR)
    : defaultValue;

export const getCurrentTypeOption = (type: number) =>
  SERVICE_TYPES.find((item) => item.value === type) || SERVICE_TYPES[0];

export const getCurrentMealOptions = (include_types: MealTypes) =>
  include_types.map((type) => type.value);

export const getCurrentPriceTypeLabel = (
  translationKey: string,
  t: Translate,
  priceType?: EPriceType,
) => {
  const currentPriceType = PRICE_TYPE_OPTIONS.find(
    (item) => item.value === priceType,
  );

  if (currentPriceType) {
    return t(`${translationKey}:${currentPriceType.label}`);
  }

  return PRICE_TYPES_DUMMY;
};

export const getTranslatedOptions = (options: Option[], t: Translate) => {
  return options.map((option) => ({
    label: t(option.label),
    value: option.value,
  }));
};

export const getTranslatedOption = (option: Option, t: Translate) => ({
  label: t(option.label),
  value: option.value,
});

import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {DateInput} from 'library/components/date-input';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {CircularLoader} from 'library/components/loader';
import {SearchInput} from 'library/components/search';
import {Select} from 'library/components/select';
import useTranslation from 'next-translate/useTranslation';
import {path} from 'ramda';
import {ChangeEvent, FC, useState} from 'react';
import {INITIAL_FILTERS, INITIAL_PAGE} from 'slices/bookings/lib/constants';
import {
  buildOptions,
  getLabel,
  getPlaceholder,
} from 'slices/bookings/lib/helpers';
import {searchResolver} from 'slices/bookings/network';
import {
  resetFilters,
  selectDirectories,
  selectFilters,
  setCheckin,
  setCheckout,
  setCreatedAt,
  setHotelId,
  setHotelName,
  setId,
  setLastName,
  setPaymentType,
  setStatus,
} from 'slices/bookings/store';
import {
  BookingsListParameters,
  HotelsMyShortListResponse,
} from 'types/api-scheme';
import {HotelSearchResult} from 'slices/bookings/ui/hotels-search-results';
import {useAppDispatch, useAppSelector} from 'source/store';
import {
  getViewFormatFromBackendDate,
  parseCalendarDates,
} from 'source/utilities/dates';
import {getDirectoryOptionByValue} from 'source/utilities/directories';

const Wrapper = styled.div`
  margin-bottom: 30px;
  @media (max-width: 480px) {
    margin-bottom: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 27px;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div``;

const StyledInput = styled(Input)``;
const StyledSelect = styled(Select)``;
const StyledDateInput = styled(DateInput)``;
const Label = styled(LabeledComponent)``;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: start;
  }
`;

const Clear = styled(Button)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const FindBookings = styled(Button)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const TEXT_SIZE = 'S';
const GAP = 10;

interface FiltersProps {
  onBookingsSearch: (
    page: number,
    filters: BookingsListParameters,
    redirect: boolean,
  ) => Promise<void>;
}

export const Filters: FC<FiltersProps> = ({onBookingsSearch}) => {
  const theme = useTheme();
  const {t} = useTranslation('bookings');
  const filters = useAppSelector(selectFilters);
  const directories = useAppSelector(selectDirectories);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const labelColor = theme.palette.fontSecondary;
  const skipSearch = !Number.isNaN(
    Number.parseInt(filters.hotel_name || '', 10),
  );
  const paymentVerificationOptions = buildOptions();
  const statusOptions = buildOptions(directories.bookingStatuses);
  const paymentOptions = buildOptions(directories.bookingPaymentMethods);

  const handleHotelName = (value?: string) => {
    const parsedValue = Number.parseInt(value || '', 10);
    if (Number.isNaN(parsedValue)) {
      dispatch(setHotelId(null));
      dispatch(setHotelName(value || null));
    } else {
      dispatch(setHotelId(parsedValue));
      dispatch(setHotelName(value || null));
    }
  };
  const handleBookingId = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    const parsedValue = Number.parseInt(value, 10);
    if (!Number.isNaN(parsedValue)) {
      dispatch(setId(parsedValue));
    } else if (!value) {
      dispatch(setId(null));
    }
  };

  return (
    <Wrapper>
      <Grid>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'booking_number')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <StyledInput
              placeholder={getPlaceholder(t, 'booking_number')}
              type="text"
              value={filters?.main_id?.toString() || ''}
              onChange={handleBookingId}
            />
          </Label>
        </GridItem>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'last_name')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <StyledInput
              placeholder={getPlaceholder(t, 'last_name')}
              type="text"
              value={filters.last_name || ''}
              onChange={(event) => {
                const {value} = event.target;
                dispatch(setLastName(value));
              }}
            />
          </Label>
        </GridItem>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'hotel_info')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <SearchInput<HotelsMyShortListResponse, ShortHotel>
              selected={filters.hotel_name || ''}
              controlledPhrase={filters.hotel_name || ''}
              resolver={searchResolver}
              skipSearch={skipSearch}
              onChange={handleHotelName}
              placeholder={getPlaceholder(t, 'hotel_info')}
              withPagination
              paginationMapper={path<ShortHotelsPagination>([1, 'meta'])}
            >
              {(hotels, setSelected) => (
                <HotelSearchResult
                  hotels={hotels}
                  showResults={!skipSearch}
                  setSelected={setSelected}
                  onHotelClick={(hotel) => {
                    dispatch(setHotelId(hotel.id));
                  }}
                />
              )}
            </SearchInput>
          </Label>
        </GridItem>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'created_at')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <StyledDateInput
              placeholder={t('all_dates')}
              setValue={(dates) => {
                const {startDate, endDate} = parseCalendarDates(dates);
                dispatch(setCreatedAt({from: startDate, to: endDate}));
              }}
              value={
                filters.created_at_from && filters.created_at_to
                  ? t('date_input', {
                      from: getViewFormatFromBackendDate(
                        filters.created_at_from,
                      ),
                      to: getViewFormatFromBackendDate(filters.created_at_to),
                    })
                  : t('all_dates')
              }
            />
          </Label>
        </GridItem>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'checkin')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <StyledDateInput
              placeholder={t('all_dates')}
              setValue={(dates) => {
                const {startDate, endDate} = parseCalendarDates(dates);
                dispatch(setCheckin({from: startDate, to: endDate}));
              }}
              value={
                filters.checkin_from && filters.checkin_to
                  ? t('date_input', {
                      from: getViewFormatFromBackendDate(filters.checkin_from),
                      to: getViewFormatFromBackendDate(filters.checkin_to),
                    })
                  : t('all_dates')
              }
            />
          </Label>
        </GridItem>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'checkout')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <StyledDateInput
              placeholder={t('all_dates')}
              setValue={(dates) => {
                const {startDate, endDate} = parseCalendarDates(dates);
                dispatch(setCheckout({from: startDate, to: endDate}));
              }}
              value={
                filters.checkout_from && filters.checkout_to
                  ? t('date_input', {
                      to: getViewFormatFromBackendDate(filters.checkout_from),
                      from: getViewFormatFromBackendDate(filters.checkout_to),
                    })
                  : t('all_dates')
              }
            />
          </Label>
        </GridItem>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'status')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <StyledSelect
              value={
                getDirectoryOptionByValue(filters.status, statusOptions) ||
                statusOptions[0]
              }
              setValue={(option) => {
                dispatch(setStatus(option.value));
              }}
              options={statusOptions}
            />
          </Label>
        </GridItem>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'payment')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <StyledSelect
              value={
                getDirectoryOptionByValue(
                  filters.payment_type,
                  paymentOptions,
                ) || paymentOptions[0]
              }
              setValue={(option) => {
                dispatch(setPaymentType(option.value));
              }}
              options={paymentOptions}
            />
          </Label>
        </GridItem>
        <GridItem>
          <Label
            color={labelColor}
            text={getLabel(t, 'verification')}
            gap={GAP}
            size={TEXT_SIZE}
          >
            <StyledSelect
              value={paymentVerificationOptions[0]}
              // TODO: when payment verification ready
              setValue={() => {}}
              options={paymentVerificationOptions}
            />
          </Label>
        </GridItem>
      </Grid>
      <ActionsWrapper>
        <Clear
          onClick={() => {
            dispatch(resetFilters());
            onBookingsSearch(INITIAL_PAGE, INITIAL_FILTERS, false);
          }}
          buttonType="secondary"
        >
          {t('clear')}
        </Clear>
        <FindBookings
          onClick={() => {
            setLoading(true);
            onBookingsSearch(INITIAL_PAGE, filters, true).finally(() =>
              setLoading(false),
            );
          }}
        >
          {loading ? (
            <CircularLoader size={20} variant="thin" />
          ) : (
            t('find_bookings')
          )}
        </FindBookings>
      </ActionsWrapper>
    </Wrapper>
  );
};

import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Headline} from 'library/components/headline';
import {Input} from 'library/components/input';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {
  __,
  all,
  allPass,
  any,
  assoc,
  equals,
  gt,
  lt,
  map,
  not,
  pathOr,
  pipe,
  prop,
  replace,
  test,
  when,
} from 'ramda';
import {FC, useCallback, useContext, useState} from 'react';
import {AuthorizationContext} from 'source/context/authorization';
import {UserContext} from 'source/context/user';
import {patterns} from 'source/utilities/patterns';
import {
  getNewPasswordInputType,
  getPasswordInputIcon,
} from 'slices/password-reset/lib/helpers';
import {PASSWORD_INPUT_TYPE} from 'slices/password-reset/lib/type';
import {passwordReset} from '../../../network';

const Form = styled.form`
  width: min(440px, 100%);
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledHeadline = styled(Headline)`
  font-size: max(17px, 0.8vw);
`;

interface PasswordResetFormProps {
  email: string;
}

const PasswordResetForm: FC<PasswordResetFormProps> = ({email}) => {
  const route = useRouter();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [, setAuthorization] = useContext(AuthorizationContext);
  const [, setUser] = useContext(UserContext);
  const [passwordInputType, setPasswordInputType] =
    useState<PASSWORD_INPUT_TYPE>(PASSWORD_INPUT_TYPE.PASSWORD);
  const [passwordRepeatInputType, setPasswordRepeatInputType] =
    useState<PASSWORD_INPUT_TYPE>(PASSWORD_INPUT_TYPE.PASSWORD);
  const [formdata, setFormdata] = useState<ClientPasswordResetFormData>({
    email,
    password: '',
    code: '',
    password_repeat: '',
  });

  const handleChange = useCallback(
    (key: keyof ClientPasswordResetFormData) =>
      pipe(
        pathOr('', ['target', 'value']),
        when(() => key === 'email', replace(/\s/g, '')),
        assoc(key),
        setFormdata,
      ),
    [],
  );

  const filled = all(
    allPass([gt(__, 0), lt(__, 256)]),
    map(prop('length'), [formdata.email, formdata.password]),
  );

  const formatted = all(
    equals(true),
    map((key) => test(patterns[key], formdata[key]), ['email'] as const),
  );

  const passwordsEquals = formdata.password === formdata.password_repeat;

  return (
    <Form
      onSubmit={passwordReset(
        route,
        formdata,
        t,
        setLoading,
        setUser,
        setAuthorization,
      )}
    >
      <StyledHeadline level={1}>{t('password-reset:subtitle')}</StyledHeadline>
      <Input
        required
        type={passwordInputType}
        name="password"
        minLength={8}
        maxLength={255}
        label={null}
        placeholder={t('components:placeholders.new_password_input')}
        value={formdata.password}
        disabled={loading}
        onChange={handleChange('password')}
        icon={getPasswordInputIcon(passwordInputType)}
        iconWidth={20}
        iconHeight={20}
        iconOnClick={() =>
          setPasswordInputType((previous) => getNewPasswordInputType(previous))
        }
      />
      <Input
        required
        type={passwordRepeatInputType}
        name="password_repeat"
        minLength={8}
        maxLength={255}
        label={null}
        shouldEqualValue={formdata.password}
        shouldEqualMessage={t('password-reset:passwords_not_equal')}
        placeholder={t('components:placeholders.password_repeat_input')}
        value={formdata.password_repeat}
        disabled={loading}
        onChange={handleChange('password_repeat')}
        icon={getPasswordInputIcon(passwordRepeatInputType)}
        iconWidth={20}
        iconHeight={20}
        iconOnClick={() =>
          setPasswordRepeatInputType((previous) =>
            getNewPasswordInputType(previous),
          )
        }
      />
      <Input
        required
        type="text"
        pattern={patterns.compatible('code')}
        patternMessage={t('components:patternMessages.code_example')}
        name="code"
        maxLength={6}
        label={null}
        autoComplete="false"
        placeholder={t('components:placeholders.code_input')}
        disabled={loading}
        value={formdata.code}
        onChange={handleChange('code')}
      />
      <Button
        type="submit"
        disabled={any(equals(true), [
          loading,
          not(filled),
          not(formatted),
          not(passwordsEquals),
        ])}
      >
        {t('components:next')}
      </Button>
    </Form>
  );
};

export default PasswordResetForm;

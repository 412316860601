import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useSyncExternalStore,
} from 'react';

import {BehaviorSubject} from 'rxjs';

import type {Step} from 'slices/registration/ui/form/steps/types';

interface RegistrationStore {
  id: string | null;
  steps: Step[];
  contacts: ContactsFormdata;
  hotel: HotelFormdata;
  services: Services;
}

export interface ApplicationSessionStore {
  registration: RegistrationStore;
}

export type SessionStoreDispatch = ReturnType<typeof createSessionStore>['set'];

const registration: RegistrationStore = {
  id: null,
  steps: [
    {name: 'contacts', active: true, passed: false},
    {name: 'hotel', active: false, passed: false},
    {name: 'services', active: false, passed: false},
  ],
  contacts: {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    code: '',
  },
  hotel: {
    raw_hotel_id: '',
    raw_provider_hotel_id: '',
    raw_hotel_name: '',
    id: '',
  },
  services: [],
};

export const initialSessionStore: ApplicationSessionStore = {
  registration,
};

const key = 'applicationStore';

const safelyReadStore = () => {
  try {
    const maybeStore = sessionStorage.getItem(key);

    if (maybeStore) {
      const value = JSON.parse(maybeStore);

      return value as ApplicationSessionStore;
    }

    return initialSessionStore;
  } catch {
    return initialSessionStore;
  }
};

const safelyWriteStore = (store: ApplicationSessionStore) => {
  try {
    const snapshot = JSON.stringify(store);

    sessionStorage.setItem(key, snapshot);
  } catch (error) {
    throw error;
  }
};

const createSessionStore = () => {
  const reference = useRef(
    new BehaviorSubject<ApplicationSessionStore>(initialSessionStore),
  );

  const get = useCallback(() => reference.current.value, []);

  const set = useCallback(
    (setter: (store: ApplicationSessionStore) => ApplicationSessionStore) => {
      const next = setter(reference.current.value);

      safelyWriteStore(next);

      reference.current.next(next);

      return next;
    },
    [],
  );

  const subscribe = useCallback((subscriber: () => void) => {
    const subscription = reference.current.subscribe(subscriber);

    return () => subscription.unsubscribe();
  }, []);

  return {
    get,
    set,
    subscribe,
  };
};

export const useSessionStore = <
  Selected extends unknown = ApplicationSessionStore,
>(
  selector?: (store: ApplicationSessionStore) => Selected,
) => {
  const store = useContext(SessionStoreContext);

  if (!store) {
    throw new Error('Store is not defined!');
  }

  const pick = useCallback(
    () => (selector ? selector(store.get()) : store.get()) as Selected,
    [selector],
  );

  const state = useSyncExternalStore(store.subscribe, pick, pick);

  useEffect(() => {
    store.set(() => safelyReadStore());
  }, []);

  return [state, store.set] as const;
};

const SessionStoreContext = createContext<ReturnType<
  typeof createSessionStore
> | null>(null);

export const SessionStoreProvider: FC<{children: ReactNode}> = ({children}) => (
  <SessionStoreContext.Provider value={createSessionStore()}>
    {children}
  </SessionStoreContext.Provider>
);

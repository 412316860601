import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Select} from 'library/components/select';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {pathOr, pipe, propOr, when} from 'ramda';
import {
  directoriesToOption,
  getCreateHotelierObjectTranslate,
  getLabel,
} from 'slices/create_hotelier_object/lib/helpers';
import {
  selectCreateHotelierObject,
  setEmail,
  setHotelTypeId,
  setPhone,
  setTranslations,
} from 'slices/create_hotelier_object/store';
import {MAX_PHONE_LENGTH} from 'slices/general_info/lib/constants';
import {useAppDispatch, useAppSelector} from 'source/store';
import {ELanguage} from 'source/utilities/language';
import {patterns} from 'source/utilities/patterns';
import {isPlusFirstExist} from 'source/utilities/strings';

const Wrapper = styled.div`
  min-width: 440px;
  @media (max-width: 480px) {
    min-width: 100%;
  }
`;

const StyledInput = styled(Input)`
  margin-bottom: 22px;
`;

const StyledSelect = styled(Select)``;

const Label = styled(LabeledComponent)`
  margin-bottom: 22px;
`;

const Headline = styled(Text)`
  margin-bottom: 22px;
`;

const DescriptionObject = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {t} = useTranslation('create_hotelier_object');
  const {address, email, phone, translations, hotel_type_id, directories} =
    useAppSelector(selectCreateHotelierObject);
  const firstTranslation = propOr(null, '0')(translations);
  const hotelName = pathOr('', ['name'])(firstTranslation);

  const selectTypes = directories?.hotelTypes?.find(
    (type) => type.id === hotel_type_id,
  );

  const handlePhoneChange = () =>
    pipe(
      pathOr('', ['target', 'value']),
      when(
        (value) => value.length <= MAX_PHONE_LENGTH,
        (value) => {
          dispatch(
            setPhone(
              `+${
                isPlusFirstExist(value) && value.length > 0
                  ? value.slice(1, value.length)
                  : value
              }`,
            ),
          );
        },
      ),
    );

  return (
    <Wrapper>
      <Headline size="S">
        {getCreateHotelierObjectTranslate(t, 'description_object.headline')}
      </Headline>
      <Label
        color={theme.palette.fontSecondary}
        text={getLabel(t, 'name.label')}
        gap={10}
        size="S"
      >
        <StyledInput
          type="text"
          value={hotelName}
          placeholder={getLabel(t, 'name.placeholder')}
          onChange={({target}) =>
            dispatch(
              setTranslations([{name: target.value, locale: ELanguage.RU}]),
            )
          }
        />
      </Label>
      <Label
        color={theme.palette.fontSecondary}
        text={getLabel(t, 'placement_type.label')}
        gap={10}
        size="S"
      >
        <StyledSelect
          value={{
            value: selectTypes?.id ?? getLabel(t, 'placement_type.placeholder'),
            label:
              selectTypes?.name ?? getLabel(t, 'placement_type.placeholder'),
          }}
          setValue={(option) => dispatch(setHotelTypeId(option.value))}
          options={directoriesToOption(directories?.hotelTypes)}
        />
      </Label>
      <Label
        color={theme.palette.fontSecondary}
        text={getLabel(t, 'email.label')}
        gap={10}
        size="S"
      >
        <StyledInput
          type="email"
          name="email"
          maxLength={255}
          pattern={patterns.compatible('email')}
          value={email}
          placeholder={getLabel(t, 'email.placeholder')}
          onChange={({target}) =>
            dispatch(setEmail(target.value.replace(/\s/g, '')))
          }
        />
      </Label>
      <Label
        color={theme.palette.fontSecondary}
        text={getLabel(t, 'phone.label')}
        gap={10}
        size="S"
      >
        <StyledInput
          type="tel"
          pattern={patterns.compatible('phone')}
          value={phone ? String(phone) : ''}
          placeholder={getLabel(t, 'phone.placeholder')}
          onChange={handlePhoneChange()}
        />
      </Label>
      <Label
        color={theme.palette.fontSecondary}
        text={getLabel(t, 'address.label')}
        gap={10}
        size="S"
      >
        <StyledInput
          type="text"
          value={address || ''}
          disabled={address.length === 0}
          placeholder={getLabel(t, 'address.placeholder')}
        />
      </Label>
    </Wrapper>
  );
};

export default DescriptionObject;

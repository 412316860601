import styled from '@emotion/styled';
import {Badge} from 'library/components/badge';
import {Checkbox} from 'library/components/checkbox';
import {LabeledComponent} from 'library/components/labeled-component';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useState} from 'react';
import {selectRooms} from 'slices/rooms/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {selectRate, setConstraintExcludeRooms} from '../../store';
import LimitationsAvailability from '../limitations-availability';

const Wrapper = styled.div``;

const LimitationContainer = styled.div`
  margin-bottom: 40px;
`;

const LimitationWrapper = styled.div`
  margin-bottom: 21px;
`;

const LimitationHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
`;

const LimitationSettingsButton = styled(Text)`
  color: ${({theme}) => theme.palette.fontPrimary};
  cursor: pointer;
`;

const LimitationListWrapper = styled.div``;

const LimitationItem = styled(Text)`
  font-weight: 500;
  margin: 7px 0px 0px 7px;
`;

const Label = styled(LabeledComponent)`
  margin-bottom: 41px;
`;

const Checkboxes = styled.div<{isMobile: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.palette.border};
  padding: ${({isMobile}) => (isMobile ? '17px 0px' : '30px 0px')};
  &:last-child {
    border-bottom: 1px solid ${({theme}) => theme.palette.border};
  }
`;

const StyledBadge = styled(Badge)`
  margin-bottom: 5px;
`;

const Limitations = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('rate');

  const {rate} = useAppSelector(selectRate);
  const rooms = useAppSelector(selectRooms);
  const {constraints, constraint_exclude_rooms, room_ids} = rate;
  const [openLimitationsAvailability, setOpenLimitationsAvailability] =
    useState<boolean>(false);

  const minDays = constraints?.stay?.day?.min_days ?? 0;
  const maxDays = constraints?.stay?.day?.max_days ?? 0;
  const hasAnyDays = minDays > 0 || maxDays > 0;

  const periodsIsValid = (constraints?.stay?.periods?.length ?? 0) > 0;
  const daysOfWeekIsValid =
    (constraints?.stay?.day?.days_of_week?.length ?? 0) > 0;

  const mobile = useDeviceDetection('mobile');

  const textSize = mobile ? 'S' : 'M';

  const handleToggleExcludeRooms = (isAdd: boolean, roomId: number) => {
    const updatedExcludeRooms = isAdd
      ? [...constraint_exclude_rooms, roomId]
      : constraint_exclude_rooms.filter(
          (excludeRoomId) => excludeRoomId !== roomId,
        );
    dispatch(setConstraintExcludeRooms(updatedExcludeRooms));
  };

  return (
    <Wrapper>
      <LimitationContainer>
        <LimitationWrapper>
          <LimitationHeaderWrapper>
            <Text size={textSize}>{t('limitation.availability_title')}</Text>
            <LimitationSettingsButton
              size="S"
              onClick={() => setOpenLimitationsAvailability(true)}
            >
              {t('limitation.configure')}
            </LimitationSettingsButton>
          </LimitationHeaderWrapper>
          <LimitationListWrapper>
            {periodsIsValid ? (
              <LimitationItem size="S">
                {t('limitation.date_restrictions')}
                {constraints?.stay?.periods.map((period) => (
                  <LimitationItem size="S" key={period.id}>
                    {t('limitation.from')} {period.from}
                    <span> </span>
                    {t('limitation.to')} {period.to}
                  </LimitationItem>
                ))}
              </LimitationItem>
            ) : (
              <LimitationItem size="S">
                {t('limitation.no_date_restrictions')}
              </LimitationItem>
            )}
            {daysOfWeekIsValid ? (
              <LimitationItem size="S">
                <>
                  {t('limitation.day_restrictions')}
                  {constraints?.stay?.day?.days_of_week.map((day) => (
                    <LimitationItem size="S" key={day}>
                      - {t(`limitation.rate.${day}`)}
                    </LimitationItem>
                  ))}
                </>
              </LimitationItem>
            ) : (
              <LimitationItem size="S">
                {t('limitation.no_day_restrictions')}
              </LimitationItem>
            )}
            {hasAnyDays ? (
              <LimitationItem size="S">
                {t('limitation.length_of_stay')}
                {minDays > 0 && (
                  <LimitationItem size="S">
                    {t('limitation.min_days')}
                    {t('components:days', {count: minDays})}
                  </LimitationItem>
                )}
                {maxDays > 0 && (
                  <LimitationItem size="S">
                    {t('limitation.max_days')}
                    {t('components:days', {count: maxDays})}
                  </LimitationItem>
                )}
              </LimitationItem>
            ) : (
              <LimitationItem size="S">
                {t('limitation.no_length_of_stay-restrictions')}
              </LimitationItem>
            )}
          </LimitationListWrapper>
        </LimitationWrapper>
        {/* TODO - привязать функционал с бэка */}
        {/* <LimitationWrapper> */}
        {/*  <LimitationHeaderWrapper> */}
        {/*    <Text size={textSize}>{t('limitation.guest_see_offer')}</Text> */}
        {/*    <LimitationSettingsButton size="S"> */}
        {/*      {t('limitation.configure')} */}
        {/*    </LimitationSettingsButton> */}
        {/*  </LimitationHeaderWrapper> */}
        {/*  <LimitationListWrapper> */}
        {/*    <LimitationItem size="S"> */}
        {/*      {t('limitation.no_date_restrictions')} */}
        {/*    </LimitationItem> */}
        {/*    <LimitationItem size="S"> */}
        {/*      {t('limitation.no_arrival_day_restrictions')} */}
        {/*    </LimitationItem> */}
        {/*  </LimitationListWrapper> */}
        {/* </LimitationWrapper> */}
      </LimitationContainer>
      <Label
        size="S"
        text={t('limitation.room_category')}
        gap={12}
        color={theme.palette.fontSecondary}
      >
        <StyledBadge
          textColor={theme.palette.defaultBackground}
          textSize={mobile ? 'XS' : 'S'}
          backgroundColor={theme.palette.primaryAccent}
        >
          {t('limitation.warning_information')}
        </StyledBadge>
        <Checkboxes isMobile={mobile}>
          <Text size={textSize}>{t('limitation.no_restrictions')}</Text>
          <Switch
            initialState={constraint_exclude_rooms.length === rooms.length}
            isActive={constraint_exclude_rooms.length === rooms.length}
            onSwitch={async (enabled) => {
              const updatedExcludeRooms = enabled
                ? rooms.map((room) => room.id)
                : [];
              dispatch(setConstraintExcludeRooms(updatedExcludeRooms));
            }}
          />
        </Checkboxes>
        {rooms
          .filter((room) => room_ids.includes(room.id))
          .map((room) => {
            return (
              <Checkbox
                key={room.id}
                value={constraint_exclude_rooms.includes(room.id)}
                label={room.name}
                onChange={(enabled) =>
                  handleToggleExcludeRooms(enabled, room.id)
                }
                style={{marginBottom: 10}}
              />
            );
          })}
      </Label>
      {/* TODO - привязать функционал с бэка */}
      {/* <Label */}
      {/*  size="S" */}
      {/*  text={t('limitation.additional_features')} */}
      {/*  gap={12} */}
      {/*  color={theme.palette.fontSecondary} */}
      {/* > */}
      {/*  <Checkboxes isMobile={mobile}> */}
      {/*    <Text size={textSize}>{t('limitation.nights_as_gift')}</Text> */}
      {/*    <Switch initialState onSwitch={async () => {}} /> */}
      {/*  </Checkboxes> */}
      {/*  <Checkboxes isMobile={mobile}> */}
      {/*    <Text size={textSize}>{t('limitation.welcome_gift_for_guests')}</Text> */}
      {/*    <Switch initialState onSwitch={async () => {}} /> */}
      {/*  </Checkboxes> */}
      {/* </Label> */}
      {/* <Label */}
      {/*  size="S" */}
      {/*  text={t('limitation.sales_channel')} */}
      {/*  gap={12} */}
      {/*  color={theme.palette.fontSecondary} */}
      {/* > */}
      {/*  <Checkboxes isMobile={mobile}> */}
      {/*    <Text size={textSize}> */}
      {/*      {t('limitation.for_all_channels_open_rate')} */}
      {/*    </Text> */}
      {/*    <Switch initialState onSwitch={async () => {}} /> */}
      {/*  </Checkboxes> */}
      {/* </Label> */}
      {openLimitationsAvailability && (
        <LimitationsAvailability
          open={openLimitationsAvailability}
          onClose={() => setOpenLimitationsAvailability(false)}
        />
      )}
      {/* <RemoveRateButton size="S" isMobile={mobile}> */}
      {/*  {t('limitation.disable_rate')} */}
      {/* </RemoveRateButton> */}
    </Wrapper>
  );
};

export default Limitations;

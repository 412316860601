import 'react-quill/dist/quill.snow.css';
import dynamic from 'next/dynamic';
import {DeltaStatic, Sources} from 'quill';
import {ComponentProps, FC} from 'react';
import {UnprivilegedEditor} from 'react-quill';

const QuillNoSSRWrapper = dynamic(import('react-quill'), {
  ssr: false,
  loading: () => <p>Loading ...</p>,
});

interface Props extends ComponentProps<typeof QuillNoSSRWrapper> {
  onChangeText?: (text: string) => void;
}

// @see https://quilljs.com/docs/
const TextEditor: FC<Props> = ({onChange, onChangeText, ...properties}) => {
  const handleSanitize = (
    value: string,
    delta: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => {
    if (onChange) {
      onChange(value, delta, source, editor);
      onChangeText?.(editor.getText());
    }
  };

  return (
    <QuillNoSSRWrapper
      theme="snow"
      modules={{
        toolbar: [
          ['bold', 'italic', 'underline'], // toggled buttons
          [{indent: '-1'}, {indent: '+1'}], // outdent/indent
        ],
      }}
      onChange={handleSanitize}
      {...properties}
    />
  );
};

export default TextEditor;

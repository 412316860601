import {FC, MouseEvent, MouseEventHandler} from 'react';
import Popup from 'reactjs-popup';
import styled from '@emotion/styled';
import {PopupProps} from 'reactjs-popup/dist/types';
import useTranslation from 'next-translate/useTranslation';
import {Text} from '../text';
import {Button} from '../button';

const Modal = styled(Popup)<{maxWidth: number}>`
  &-content {
    width: 100%;
    max-width: ${({maxWidth}) => (maxWidth ? `${maxWidth}px` : 'unset')};
    padding: 16px 20px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-shadow: ${({theme}) => theme.palette.boxShadow} !important;
    border-radius: 6px !important;
    box-sizing: border-box;
    @media (max-width: 350px) {
      padding: 16px 12px;
    }
  }
`;

const Wrapper = styled.div``;

const Title = styled(Text)`
  margin-bottom: 20px;
  width: 100%;
`;

const Pre = styled.pre`
  font-size: inherit;
  white-space: normal;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 350px) {
    gap: 8px;
  }
`;

const Cancel = styled(Button)`
  max-width: 140px;
  width: 100%;
`;

const Action = styled(Button)`
  max-width: 180px;
  width: 100%;
`;

interface ActionModalProps {
  title: string;
  actionText: string;
  action: (
    close: MouseEventHandler<HTMLButtonElement> | undefined,
    event: MouseEvent<HTMLButtonElement>,
  ) => void | Promise<void>;
  children?: PopupProps['trigger'];
  maxWidth: number;
  onClose?: () => void;
  open?: boolean;
}

export const ActionModal: FC<ActionModalProps> = ({
  title,
  actionText,
  action,
  maxWidth,
  children,
  open,
  onClose,
}) => {
  const {t} = useTranslation('components');
  return (
    <Modal
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      trigger={children}
      modal
    >
      {/* This ts-ignore can't be fixed because of Popup ReturnType in reactjs-popup lib */}
      {/* @ts-ignore */}
      {(close: MouseEventHandler<HTMLButtonElement> | undefined) => (
        <Wrapper>
          <Title size="M">
            <Pre>{title}</Pre>
          </Title>
          <ButtonWrapper>
            <Cancel
              buttonType="secondary"
              onClick={(event) => {
                if (close) {
                  close(event);
                }

                if (onClose) {
                  onClose();
                }
              }}
            >
              {t('cancel')}
            </Cancel>
            <Action onClick={(event) => action(close, event)}>
              {actionText}
            </Action>
          </ButtonWrapper>
        </Wrapper>
      )}
    </Modal>
  );
};

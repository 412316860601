import {request} from 'library/api';
import {path} from 'ramda';
import {DEFAULT_PAGE} from 'source/utilities/constants/logic';
import {HttpStatusCode} from 'source/utilities/enums/http';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  OrganizationsCreateRequest,
  HotelsVerificationDetailResponse,
  OrganizationsListResponse,
  HotelsVerificationInvoiceCreateResponse,
  HotelsVerificationCreateResponse,
  HotelsVerificationCreateRequest,
  OrganizationsCreateResponse,
  HotelsVerificationPaymentCreateResponse,
  OrganizationsDetailResponse,
} from 'types/api-scheme';

type GetRequisitesContext = {
  hotelId: number;
  headers: Headers;
};
const getRequisites = ({hotelId, headers}: GetRequisitesContext) =>
  request<HotelsVerificationDetailResponse>(
    url(`hotels/${hotelId}/organization`),
    {
      method: 'GET',
      headers,
    },
  ).then(path<HotelsVerificationDetailResponse['data']>([1, 'data']));

const getVerificationRequisites = ({hotelId, headers}: GetRequisitesContext) =>
  request<HotelsVerificationDetailResponse>(
    url(`hotels/${hotelId}/verification`),
    {
      method: 'GET',
      headers,
    },
  ).then(path<HotelsVerificationDetailResponse['data']>([1, 'data']));

type GetOrganizationsContext = {
  query: string;
  headers?: Headers;
  page?: number;
};
const getOrganizations = ({
  query,
  headers,
  page = DEFAULT_PAGE,
}: GetOrganizationsContext) => {
  return request<OrganizationsListResponse>(
    url('organizations', {search_query: query, page: String(page)}),
    {
      method: 'GET',
      headers: headers || clientHeaders(),
    },
  );
};

type GetOrganizationContext = {
  id: number;
  headers?: Headers;
};
const getOrganization = ({headers, id}: GetOrganizationContext) =>
  request<OrganizationsDetailResponse>(url(`organizations/${id}`), {
    method: 'GET',
    headers: headers || clientHeaders(),
  }).then(path<OrganizationsCreateResponse['data']>([1, 'data']));

type CreateRequisites = {
  payload: RequisitesFormData;
};

const createRequisites = ({payload}: CreateRequisites) =>
  request<OrganizationsCreateRequest>(url('organizations'), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<OrganizationsCreateResponse['data']>([1, 'data']));

type CreateVerificationContext = {
  hotelId: number;
  payload: HotelsVerificationCreateRequest;
};

const createVerification = ({hotelId, payload}: CreateVerificationContext) =>
  request<HotelsVerificationCreateResponse>(
    url(`hotels/${hotelId}/verification`),
    {
      method: 'POST',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    },
  ).then(path<HotelsVerificationCreateResponse['data']>([1, 'data']));

type GetInvoiceContext = {
  hotelId: number;
};

const getInvoice = ({hotelId}: GetInvoiceContext) =>
  request<HotelsVerificationInvoiceCreateResponse>(
    url(`hotels/${hotelId}/verification/invoice`),
    {method: 'POST', headers: clientHeaders()},
    {
      [HttpStatusCode.NOT_FOUND]: () => {
        return null;
      },
    },
  ).then(path<HotelsVerificationInvoiceCreateResponse['data']>([1]));

type CreateVerificationPaymentContext = {
  hotelId: number;
};

const createVerificationPayment = ({
  hotelId,
}: CreateVerificationPaymentContext) =>
  request<HotelsVerificationPaymentCreateResponse>(
    url(`hotels/${hotelId}/verification/payment`),
    {method: 'POST', headers: clientHeaders()},
  ).then(
    path<HotelsVerificationPaymentCreateResponse['data']['payment_url']>([
      1,
      'data',
      'payment_url',
    ]),
  );

export const organizationsApi = {
  getOrganization,
  getOrganizations,
  getInvoice,
  getRequisites,
  createRequisites,
  createVerification,
  createVerificationPayment,
  getVerificationRequisites,
};

import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {format} from 'date-fns';
import {Button} from 'library/components/button';
import {DatePicker} from 'library/components/date-picker';
import {Headline} from 'library/components/headline';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';
import {useRouter} from 'next/router';
import {isEmpty} from 'ramda';
import {FC, useState} from 'react';
import {RangeFocus, RangeKeyDict} from 'react-date-range';
import Popup from 'reactjs-popup';
import {
  DEFAULT_DAYS_VALUE,
  INITIAL_DAYS_INPUT_VALUE,
} from 'slices/rate/lib/constants';
import {Icon} from 'source/components/icon';
import {useAppDispatch, useAppSelector} from 'source/store';
import {showException} from 'source/utilities/exceptions/business';
import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {
  checkMatchingPeriods,
  formatDateValueForInput,
  getStayPeriods,
} from '../../lib/helpers';
import {LIMITATIONS_DAYS_OF_WEEK} from '../../lib/mocks';
import {EDaysOfWeek, FOCUSED_START_AND_END_INDEX} from '../../lib/types';
import {selectRate, setRateConstrainsStay} from '../../store';

const StyledPopup = styled(Popup)<{isMobile: boolean}>`
  &-content {
    background: ${({theme}) => theme.palette.defaultBackground};
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 4px 20px rgba(220, 220, 220, 0.4);
    border-radius: 6px;
  }
`;

const PopupWrapper = styled.div<{isTouch: boolean}>`
  overflow-y: scroll;
  max-height: 70vh;
  padding: 20px;
  max-width: 100%;

  ${({isTouch}) =>
    isTouch &&
    css`
      padding-bottom: 15px;

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
    `};
`;

const HeadingWrapper = styled.div`
  padding: 20px 0 0 20px;
`;

const Title = styled(Headline)`
  margin-bottom: 10px;
`;

const Label = styled(LabeledComponent)`
  border-radius: 100px;
  margin-bottom: 21px;
`;

const Checkboxes = styled.div<{isMobile: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.palette.border};
  padding: ${({isMobile}) => (isMobile ? '17px 0px' : '30px 0px')};
`;

const RemovePeriodButton = styled(Text)<{isMobile: boolean}>`
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 20px 0px 20px 0px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s ease-in-out;
  width: 100%;
  background: unset;
  color: ${({theme}) => theme.palette.fontSecondary};
`;

const CreatePeriodButton = styled(Button)<{isMobile: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 60px;
  margin-top: ${({isMobile}) => (isMobile ? '20px' : '40px')};
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease-in-out;
  border: 1px solid ${({theme}) => theme.palette.border};
  width: 100%;
`;

const CloseButton = styled(Button)<{isMobile: boolean}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  top: 5px;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  padding: 0px;
  border: 0px;

  div {
    width: 24px;
    height: 24px;
  }

  ${({isMobile}) =>
    isMobile &&
    css`
      width: 32px;
      height: 32px;
      top: 5px;
    `}
`;

const PeriodCalendar = styled(DatePicker)`
  margin-bottom: 10px;
  width: 100%;
  .rdrMonth {
    width: 100%;
  }
  .rdrCalendarWrapper {
    width: 100%;
  }
`;

const PeriodInput = styled.div`
  padding: 20px 40px 20px 20px;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  background-color: ${({theme}) => theme.palette.border_3};
`;

const DaysWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const DayButton = styled.div<{isActive: boolean; isMobile: boolean}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({isMobile}) => (isMobile ? '45px' : '55px')};
  height: ${({isMobile}) => (isMobile ? '45px' : '55px')};
  text-transform: uppercase;
  border: 1px solid ${({theme}) => theme.palette.border};
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 6px;
  margin-right: 9px;
  font-size: 14px;

  ${({isActive}) =>
    isActive &&
    css`
      &::before,
      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 4px; /* cross thickness */
        background-color: ${theme.palette.fontPrimary};
        opacity: 0.3;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    `}

  &:last-child {
    margin-right: 0;
  }
`;

const PeriodHide = styled(Text)`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  padding-right: 10px;
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

const LimitationsAvailability: FC<Props> = ({open, onClose}) => {
  const {t} = useTranslation('rate');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {rateSaving, rate} = useAppSelector(selectRate);
  const mobile = useDeviceDetection('mobile');
  const isTouch = useDeviceDetection('touch');
  const [selectPeriod, setSelectPeriod] = useState<number>(-1);
  const [stay, setStay] = useState({
    ...getStayPeriods(rate.constraints.stay),
  });
  const [focusedRange, setFocusedRange] = useState<RangeFocus>([0, 0]);
  const {day, periods = []} = stay;

  const isDaysOfWeekValid = (day?.days_of_week.length ?? 0) > 0;
  const minDay = day?.min_days ?? DEFAULT_DAYS_VALUE;
  const maxDay = day?.max_days ?? DEFAULT_DAYS_VALUE;

  const textSize = mobile ? 'XS' : 'S';

  const onChange = (range: RangeKeyDict[number]) => {
    const {startDate, endDate, key} = range;
    setStay((previous) => {
      const periods = [...previous.periods].map((period) => {
        const clone = {...period};

        if (Number(key) === clone.id) {
          clone.from = startDate ? format(startDate, 'yyyy-MM-dd') : clone.from;
          clone.to = endDate ? format(endDate, 'yyyy-MM-dd') : clone.to;
        }

        return clone;
      });

      if (focusedRange.includes(FOCUSED_START_AND_END_INDEX.ALL)) {
        setSelectPeriod(-1);
      }

      return {
        ...previous,
        periods,
      };
    });
  };

  const handleSelectDay = (dayId: EDaysOfWeek) => {
    let days_of_week = [...(day?.days_of_week || [])];
    const isExist = days_of_week.includes(dayId);

    if (isExist) {
      days_of_week = days_of_week.filter((day) => {
        return day !== dayId;
      });
    }

    if (!isExist) {
      days_of_week.push(dayId);
    }

    setStay((previous) => {
      if (previous.day) {
        const day = {...previous.day, days_of_week};
        return {...previous, day};
      }
      return {...previous};
    });
  };

  const handleCreateNewPeriod = () => {
    setStay((previous) => {
      const periods = [
        ...previous.periods,
        {
          id: previous.periods.length + 1,
          from: format(new Date(), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
          created_at: '',
          updated_at: '',
        },
      ];
      return {...previous, periods};
    });
  };

  const handleRemovePeriod = (id: number) => {
    setStay((previous) => ({
      ...previous,
      periods: previous.periods.filter((period) => period.id !== id),
    }));
    setSelectPeriod(-1);
  };

  const handleSave = () => {
    if (
      minDay > maxDay &&
      minDay !== DEFAULT_DAYS_VALUE &&
      maxDay !== DEFAULT_DAYS_VALUE
    ) {
      return showException(t('limitation.notify.min_max_day_error') as string);
    }

    if (maxDay !== DEFAULT_DAYS_VALUE && minDay === DEFAULT_DAYS_VALUE) {
      return showException(
        t('limitation.notify.min_days_required_if_exist_max') as string,
      );
    }

    if (checkMatchingPeriods(periods)) {
      return showException(t('limitation.notify.equals_periods') as string);
    }

    if (stay && stay.day) {
      dispatch(
        setRateConstrainsStay({
          ...stay,
          day: {
            ...stay.day,
            min_days: minDay === DEFAULT_DAYS_VALUE ? null : minDay,
            max_days: maxDay === DEFAULT_DAYS_VALUE ? null : maxDay,
          },
        }),
      );
    }

    onClose();
  };

  const handleSwitchStayDurationRestrictions = async () => {
    setStay((previous) => {
      if (previous.day) {
        const day = {...previous.day};

        day.min_days = null;
        day.max_days = null;

        return {...previous, day};
      }

      return {...previous};
    });
  };

  const handleSwitchDayRestrictions = (enabled: boolean) => {
    const isEmptyDaysOfWeek = isEmpty(day?.days_of_week ?? []);
    if (isEmptyDaysOfWeek) {
      showException(t('limitation.notify.select_days_of_week_need') as string, {
        closeButton: false,
      });
    }
    if (!isEmptyDaysOfWeek && !enabled) {
      setStay((previous) => {
        if (previous.day) {
          const day = {...previous.day};

          day.days_of_week = [];

          return {...previous, day};
        }

        return {...previous};
      });
    }
  };

  const handleChangeMinDays = (target: EventTarget & HTMLInputElement) => {
    setStay((previous) => {
      if (previous.day) {
        const day = {...previous.day};

        day.min_days = Number(target.value);

        return {...previous, day};
      }
      return {...previous};
    });
  };

  const handleChangeMaxDays = (target: EventTarget & HTMLInputElement) => {
    setStay((previous) => {
      if (previous.day) {
        const day = {...previous.day};

        day.max_days = Number(target.value);

        return {...previous, day};
      }
      return {...previous};
    });
  };

  return (
    <StyledPopup
      isMobile={mobile}
      open={open}
      onClose={onClose}
      closeOnDocumentClick
    >
      <HeadingWrapper>
        <CloseButton onClick={onClose} disabled={rateSaving} isMobile={mobile}>
          <CloseIcon />
        </CloseButton>
        <Title level={mobile ? 'M' : 'L'}>
          {t('limitation.availability.stay_dates')}
        </Title>
      </HeadingWrapper>

      <PopupWrapper isTouch={isTouch}>
        <Label
          size="S"
          text={t('limitation.availability.date_restrictions')}
          gap={12}
          color={theme.palette.fontSecondary}
        >
          <InputWrapper>
            {periods.map((period) => (
              <div key={period.id}>
                {selectPeriod === period.id ? (
                  <>
                    <PeriodHide
                      size={mobile ? 'XS' : 'S'}
                      onClick={() => setSelectPeriod(-1)}
                    >
                      {periods.length !== 1 && (
                        <>
                          <span>{t('limitation.availability.hide')}</span>
                          <Icon
                            name="cross"
                            width={15}
                            height={15}
                            style={{marginLeft: 10}}
                          />
                        </>
                      )}
                    </PeriodHide>
                    <PeriodCalendar
                      ranges={[
                        {
                          startDate: new Date(period.from),
                          endDate: new Date(period.to),
                          key: String(period.id),
                        },
                      ]}
                      onChange={(range) => onChange(range[String(period.id)])}
                      weekdayDisplayFormat="EEEEEE"
                      monthDisplayFormat="LLLL"
                      months={2}
                      showMonthAndYearPickers
                      locale={router.locale}
                      onRangeFocusChange={setFocusedRange}
                      focusedRange={focusedRange}
                      minDate={new Date()}
                    />
                  </>
                ) : (
                  <PeriodInput onClick={() => setSelectPeriod(period.id)}>
                    {`${formatDateValueForInput(
                      new Date(period.from),
                    )} - ${formatDateValueForInput(new Date(period.to))}`}
                  </PeriodInput>
                )}
                <RemovePeriodButton
                  isMobile={mobile}
                  size={mobile ? 'S' : 'M'}
                  onClick={() => handleRemovePeriod(period.id)}
                >
                  {t('limitation.availability.remove')}
                </RemovePeriodButton>
              </div>
            ))}
          </InputWrapper>
          {selectPeriod === -1 && (
            <CreatePeriodButton
              isMobile={mobile}
              onClick={handleCreateNewPeriod}
            >
              {t('limitation.availability.create')}
            </CreatePeriodButton>
          )}
        </Label>
        <Checkboxes isMobile={mobile}>
          <Text size={textSize}>
            {t('limitation.availability.day_restrictions')}
          </Text>
          <Switch
            initialState={isDaysOfWeekValid}
            isActive={!isEmpty(day?.days_of_week ?? [])}
            onSwitch={async (enabled) => handleSwitchDayRestrictions(enabled)}
          />
        </Checkboxes>
        <Label
          size="S"
          text={t('limitation.availability.available_days')}
          gap={12}
          color={theme.palette.fontSecondary}
        >
          <DaysWrapper>
            {LIMITATIONS_DAYS_OF_WEEK.map((dayItem) => (
              <DayButton
                key={dayItem.value}
                isActive={day?.days_of_week.includes(dayItem.value) ?? false}
                onClick={() => handleSelectDay(dayItem.value)}
                isMobile={mobile}
              >
                {t(dayItem.title)}
              </DayButton>
            ))}
          </DaysWrapper>
        </Label>
        <Checkboxes isMobile={mobile}>
          <Text size={textSize}>
            {t('limitation.availability.stay_duration_restrictions')}
          </Text>
          <Switch
            initialState={
              minDay > DEFAULT_DAYS_VALUE || maxDay > DEFAULT_DAYS_VALUE
            }
            isActive={
              minDay > DEFAULT_DAYS_VALUE || maxDay > DEFAULT_DAYS_VALUE
            }
            onSwitch={handleSwitchStayDurationRestrictions}
          />
        </Checkboxes>
        <Label
          size="S"
          text={t('limitation.availability.min_stay_days')}
          gap={12}
          color={theme.palette.fontSecondary}
        >
          <Input
            label=""
            type="number"
            value={
              minDay === DEFAULT_DAYS_VALUE
                ? INITIAL_DAYS_INPUT_VALUE
                : String(minDay)
            }
            placeholder={t('limitation.availability.enter_min_days')}
            onChange={({target}) => handleChangeMinDays(target)}
            noDecimals
          />
        </Label>
        <Label
          size="S"
          text={t('limitation.availability.max_stay_days')}
          gap={12}
          color={theme.palette.fontSecondary}
        >
          <Input
            label=""
            type="number"
            value={
              maxDay === DEFAULT_DAYS_VALUE
                ? INITIAL_DAYS_INPUT_VALUE
                : String(maxDay)
            }
            placeholder={t('limitation.availability.enter_max_days')}
            onChange={({target}) => handleChangeMaxDays(target)}
            noDecimals
          />
        </Label>
        <CreatePeriodButton isMobile={mobile} onClick={handleSave}>
          {t('limitation.availability.apply')}
        </CreatePeriodButton>
      </PopupWrapper>
    </StyledPopup>
  );
};

export default LimitationsAvailability;

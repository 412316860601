import {GoogleTagManager} from '@next/third-parties/google';
import {GOOGLE_TAG} from 'source/utilities/guards/environment';

export const GoogleTagManagerScript = () => {
  if (!GOOGLE_TAG) {
    return null;
  }

  return <GoogleTagManager gtmId={GOOGLE_TAG} />;
};

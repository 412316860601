import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {Button} from 'library/components/button';
import {CircularLoader} from 'library/components/loader';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {ActionButtonsType} from 'slices/calendar/lib/types';

const ActionButton = styled(Button)<{isSmall?: boolean}>`
  width: ${({isSmall}) => (isSmall ? 'max-content' : '200px')};
  height: ${({isSmall}) => (isSmall ? 40 : 50)}px;
  font-size: ${({isSmall}) => (isSmall ? 12 : 16)}px;
  padding: 12px 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 500px) {
    width: 100%;
    padding: 6px;
  }
`;

const ButtonWrapper = styled.div<{isSmall?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({isSmall}) => (isSmall ? 10 : 20)}px;

  @media (max-width: 500px) {
    gap: 5px;

    ${({isSmall}) =>
      isSmall &&
      css`
        flex-direction: column;
      `}
  }
`;

interface Props {
  isEdit: boolean;
  onSave: () => Promise<void> | void;
  onCancel: () => void;
  isSmall?: boolean;
  loading?: boolean;
  type?: ActionButtonsType;
  disabled?: boolean;
  submitDisabled?: boolean;
}

export const ActionButtons: FC<Props> = ({
  isEdit,
  onSave,
  onCancel,
  loading,
  type = 'save',
  isSmall = false,
  disabled = false,
  submitDisabled,
}) => {
  const {t} = useTranslation('components');

  if (!isEdit) {
    return null;
  }

  return (
    <ButtonWrapper isSmall={isSmall}>
      <ActionButton
        disabled={disabled || loading || submitDisabled}
        isSmall={isSmall}
        onClick={(event) => {
          event.stopPropagation();
          onSave();
        }}
      >
        {loading ? <CircularLoader size={32} /> : t(type)}
      </ActionButton>
      <ActionButton
        disabled={disabled || loading}
        isSmall={isSmall}
        onClick={(event) => {
          event.stopPropagation();
          onCancel();
        }}
      >
        {t('cancel')}
      </ActionButton>
    </ButtonWrapper>
  );
};

/* eslint-disable camelcase */
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import Popup from 'reactjs-popup';
import useTranslation from 'next-translate/useTranslation';
import {FC, useCallback, useEffect, useState} from 'react';
import {RangeKeyDict} from 'react-date-range';
import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';

import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Button} from 'library/components/button';
import {Text} from 'library/components/text';
import {HorizontalDivier} from 'library/components/divider';
import {CircularLoader} from 'library/components/loader';

import {ActiveTabType, MASS_EDIT_TABS} from 'slices/calendar/lib/types';

import {RANGE_KEY} from 'slices/calendar/lib/constants';
import {buildPricesDateRange} from 'slices/calendar/lib/helpers/dates';

import {ModalRangeCellsContent} from '../modal-range-cells-content';
import {AvailabilityModalContent} from '../availability-modal-content';
import {
  resetRangeSelectCells,
  selectRangeSelectCells,
  selectIsLoading,
  setMassEditModalFilters,
} from '../../store';

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 50px);

  @media (max-width: 800px) {
    width: calc(100% - 22px);
  }
`;

const TabButton = styled(Button)<{active: boolean; isTouch: boolean}>`
  background-color: transparent;
  color: ${({theme}) => theme.palette.fontDefault};
  font-weight: 500;
  width: 100%;
  font-size: 16px;
  padding: 0px 0px 10px 0px;
  border-radius: 0;
  border: none;

  border-bottom: ${({theme, active}) =>
    active ? `2px solid ${theme.palette.primaryAccent}` : 'none'};

  @media (max-width: 500px) {
    font-size: 14px;
  }

  ${({isTouch, theme}) =>
    isTouch &&
    css`
      &:hover {
        border-bottom: 2px solid ${theme.palette.primaryAccent};
      }
    `}
`;

const StyledHorizontalDivider = styled(HorizontalDivier)`
  width: calc(100% - 50px);

  @media (max-width: 600px) {
    width: calc(100% - 20px);
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: fit-content;
    width: 800px;
    border-radius: 6px;

    @media (max-width: 850px) {
      max-width: 95vw;
    }

    @media (max-width: 500px) {
      max-width: unset;
      width: 100vw;
    }
  }
`;

const Wrapper = styled.div<{disabled: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 25px 0 25px;

  ${({disabled}) =>
    disabled &&
    css`
      pointer-event: none;
      opacity: 0.2;
    `}

  @media (max-width: 700px) {
    padding: 25px 2px 0 2px;
  }
`;

const CloseButton = styled(Button)<{isMobile: boolean}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 10px;
  width: 52px;
  height: 52px;
  background: transparent;
  padding: 0px;
  border: none;
  color: ${({theme}) => theme.palette.primaryAccent};

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
    top: 5px;
    right: 0;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

interface Props {
  roomId: number;
}

const MassEditModal: FC<Props> = ({roomId}) => {
  const {t} = useTranslation('calendar');
  const dispatch = useAppDispatch();
  const rangeSelectCells = useAppSelector(selectRangeSelectCells);
  const today = new Date();
  const [currentRange, setCurrentRange] = useState({
    from: today,
    to: today,
  });
  const {prices} = rangeSelectCells;

  const mobile = useDeviceDetection('mobile');
  const loading = useAppSelector(selectIsLoading);
  const isTouch = useDeviceDetection('touch');

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<ActiveTabType>(
    MASS_EDIT_TABS.PRICE,
  );

  const handleCancel = useCallback(() => {
    dispatch(resetRangeSelectCells());
  }, []);

  const handleRangeChange = (range: RangeKeyDict) => {
    const currentRange = range[RANGE_KEY];

    if (currentRange && currentRange.startDate && currentRange.endDate) {
      setCurrentRange({from: currentRange.startDate, to: currentRange.endDate});
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setActiveTab(MASS_EDIT_TABS.PRICE);
    }
  }, [isOpen]);

  useEffect(() => {
    // При открытии актуализирует фильтры и дата пикер
    if (prices.length > 0 && isOpen && rangeSelectCells) {
      const {room, rateId, guestNumber} = rangeSelectCells;
      const pricesDateRange = buildPricesDateRange(prices);

      setCurrentRange({
        from: pricesDateRange.startDate,
        to: pricesDateRange.endDate,
      });

      dispatch(
        setMassEditModalFilters({
          guestNumber: [guestNumber],
          roomId: room,
          rateId,
        }),
      );
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(prices.length > 0);
  }, [prices]);

  return (
    <StyledPopup
      modal
      open={isOpen}
      onClose={() => dispatch(resetRangeSelectCells())}
      closeOnEscape
      closeOnDocumentClick
      lockScroll
    >
      {loading && (
        <LoaderWrapper>
          <CircularLoader size={64} variant="main" />
          <Text size="XS">{t('room_range.loading_data')}</Text>
        </LoaderWrapper>
      )}

      <Wrapper disabled={loading}>
        <CloseButton
          onClick={() => dispatch(resetRangeSelectCells())}
          isMobile={mobile}
        >
          <CloseIcon />
        </CloseButton>
        <>
          <Tabs>
            <TabButton
              isTouch={isTouch}
              active={activeTab === MASS_EDIT_TABS.PRICE}
              onClick={() => setActiveTab(MASS_EDIT_TABS.PRICE)}
            >
              {t('room_range.price_change')}
            </TabButton>
            <TabButton
              isTouch={isTouch}
              active={activeTab === MASS_EDIT_TABS.BOOKING}
              onClick={() => setActiveTab(MASS_EDIT_TABS.BOOKING)}
            >
              {t('room_range.close_booking')}
            </TabButton>
          </Tabs>
          <StyledHorizontalDivider />
          {activeTab === MASS_EDIT_TABS.PRICE ? (
            <ModalRangeCellsContent
              currentRange={currentRange}
              onCancel={handleCancel}
              onRangeChange={handleRangeChange}
              setIsOpen={setIsOpen}
            />
          ) : (
            <AvailabilityModalContent
              currentRange={currentRange}
              roomId={roomId}
              onCancel={handleCancel}
              onRangeChange={handleRangeChange}
            />
          )}
        </>
      </Wrapper>
    </StyledPopup>
  );
};

export default MassEditModal;

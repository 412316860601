import {ApplicationProperties} from 'source/utilities/ui';

export interface BookingPageProperties extends ApplicationProperties {
  booking?: Booking;
}

export interface BookingStoreInitialState {
  booking: Booking | null;
}

export enum GuestType {
  MAIN = 1,
  ADULT = 2,
}

export type Prices = NonNullable<Booking['our_booking']>['prices'];
export type Price = Prices[number];

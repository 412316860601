import styled from '@emotion/styled';

export const HorizontalDivier = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
`;

export const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: #ebebeb;
`;

import {FC, ReactNode} from 'react';
import {PopupProps} from 'reactjs-popup/dist/types';

import {Tooltip} from 'library/components/tooltip';

interface IProps {
  children: ReactNode;
  trigger: PopupProps['trigger'];
}

const MobileCalendarTooltip: FC<IProps> = ({children, trigger}) => {
  return (
    <Tooltip
      on="click"
      position="center center"
      mobileTopModal
      minWidth={300}
      trigger={trigger}
    >
      {children}
    </Tooltip>
  );
};

export default MobileCalendarTooltip;

/* eslint-disable camelcase */
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {NoContent} from 'library/components/no-content';
import useTranslation from 'next-translate/useTranslation';
import {isEmpty} from 'ramda';
import {FC, useContext, useEffect, useRef} from 'react';

import BnovoProvider from 'source/components/bnovo-provider';
import {RealtyProvider} from 'source/components/realty-provider';
import {CalendarRefContext} from 'source/context/calendar-ref';
import {HotelContext} from 'source/context/hotel';

import {ScrollProvider} from 'source/context/scroll';
import {Bitrix} from 'source/services/bitrix';
import {useAppDispatch, useAppSelector} from 'source/store';
import TravelLineProvider from 'source/components/travel-line-provider';
import {useTravelLineIntegration} from 'source/utilities/hooks/use-is-travel-line-integration';
import {
  reset,
  selectIsLoading,
  selectMassEditModalFilters,
  selectOpenedRooms,
  selectSelectedDates,
  setDateAvailabilities,
  setIsLoading,
  setRoomShorts,
} from './store';
import {Header} from './ui/header';
import MassEditModal from './ui/mass-edit-modal';
import {Room} from './ui/room';
import {fetchHotelRooms} from './network';
import {parseHotelRoomsToDateAvailabilities} from './lib/helpers/rooms';

const Wrapper = styled.div`
  max-width: 100vw;
`;

const Body = styled.div<{isLoading: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;

  ${({isLoading}) =>
    isLoading &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
`;

interface Props {
  roomsShorts?: HotelRoomsShorts;
  rates?: Rates;
}

const Content: FC<Props> = ({roomsShorts, rates}) => {
  const ref = useRef(null);
  const {t} = useTranslation('calendar');
  const [hotel] = useContext(HotelContext);
  const [hasTravelLineIntegration] = useTravelLineIntegration();
  const dispatch = useAppDispatch();
  const [, setCalendarRef] = useContext(CalendarRefContext);
  const isLoading = useAppSelector(selectIsLoading);
  const selectedDates = useAppSelector(selectSelectedDates);
  const openedRooms = useAppSelector(selectOpenedRooms);

  const {roomId} = useAppSelector(selectMassEditModalFilters);

  useEffect(() => {
    dispatch(reset());
    Bitrix.hideBitrix();

    return () => {
      dispatch(reset());
      Bitrix.showBitrix();
    };
  }, []);

  useEffect(() => {
    const {from, to} = selectedDates;

    if (!from || !to || !hotel) {
      return;
    }

    dispatch(setIsLoading(true));

    // При изменении дат обновляет date-availabilities (только закрытых номеров)
    // Открытые обновляются через обычный запрос получения данных вместе с ценами

    fetchHotelRooms(hotel.id, from, to, (hotelRooms) => {
      if (hotelRooms && !isEmpty(hotelRooms)) {
        dispatch(
          setDateAvailabilities(
            parseHotelRoomsToDateAvailabilities(hotelRooms, openedRooms),
          ),
        );
      }
    }).finally(() => dispatch(setIsLoading(false)));
  }, [selectedDates, hotel]);

  useEffect(() => {
    if (roomsShorts) {
      dispatch(setRoomShorts(roomsShorts));
    }
  }, [roomsShorts, dispatch]);

  useEffect(() => {
    if (ref.current) {
      setCalendarRef(ref.current);
    }

    return () => setCalendarRef(null);
  }, []);

  if (isEmpty(roomsShorts)) {
    return (
      <div>
        <NoContent
          subtitle={t('no_rooms')}
          buttonLabel={t('add_room')}
          href={`/manage/${hotel?.id}/rooms/add`}
        />
      </div>
    );
  }

  if (isEmpty(rates)) {
    return (
      <div>
        <NoContent
          subtitle={t('no_rates')}
          buttonLabel={t('add_rate')}
          href={`/manage/${hotel?.id}/rates`}
        />
      </div>
    );
  }

  return (
    <Wrapper ref={ref}>
      <TravelLineProvider>
        <BnovoProvider disableEvents={false}>
          <RealtyProvider disableEvents={false}>
            <ScrollProvider>
              {!hasTravelLineIntegration && (
                <>
                  <Header />
                  <Body isLoading={isLoading}>
                    <>
                      {roomsShorts?.map((room, index) => (
                        <Room
                          id={room.id}
                          key={room.id}
                          name={room.name}
                          roomIndex={index}
                        />
                      ))}
                      <MassEditModal roomId={roomId} />
                    </>
                  </Body>
                </>
              )}
            </ScrollProvider>
          </RealtyProvider>
        </BnovoProvider>
      </TravelLineProvider>
    </Wrapper>
  );
};

export default Content;

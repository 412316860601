import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsLandmarksDetailResponse,
  HotelsLandmarksNearDetailResponse,
} from 'types/api-scheme';

type GetLandmarksContext = {
  hotelId: number;
  headers: Headers;
};
const getLandmarks = ({hotelId, headers}: GetLandmarksContext) =>
  request<HotelsLandmarksDetailResponse>(url(`hotels/${hotelId}/landmarks`), {
    method: 'GET',
    headers,
  }).then(path<HotelsLandmarksDetailResponse['data']>([1, 'data']));

type GetNearLandmarksContext = {
  hotelId: number;
  headers: Headers;
};
const getNearLandmarks = ({hotelId, headers}: GetNearLandmarksContext) =>
  request<HotelsLandmarksNearDetailResponse>(
    url(`hotels/${hotelId}/landmarks/near`),
    {method: 'GET', headers}
  ).then(path<HotelsLandmarksNearDetailResponse['data']>([1, 'data']));

type SaveFacilityContext = {
  hotelId: Hotel['id'];
  payload: {
    landmarks: HotelLandmarks;
  };
};
const saveLandmarks = ({hotelId, payload}: SaveFacilityContext) =>
  request<HotelsLandmarksDetailResponse>(url(`hotels/${hotelId}/landmarks`), {
    method: 'PATCH',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsLandmarksDetailResponse>([1, 'data']));

export const landmarksApi = {
  get: getLandmarks,
  save: saveLandmarks,
  getNear: getNearLandmarks,
};

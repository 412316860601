import {useState, FC, ChangeEvent, KeyboardEvent, memo, useEffect} from 'react';
import styled from '@emotion/styled';
import {isNil} from 'ramda';

import {useAppDispatch, useAppSelector} from 'source/store';
import {
  setEditedCell,
  setUpdatedCells,
  selectUpdatedCells,
} from 'slices/calendar/store';

import {Input} from 'library/components/input';

import {buildLabelRoomPrice} from 'slices/calendar/lib/helpers/prices';
import {
  DEFAULT_EDITED_CELL,
  MIN_RATE_PRICE,
  CALENDAR_PRICE_PLACEHOLDER,
  CELL_WIDTH,
} from 'slices/calendar/lib/constants';
import {SelectedCell, GuestNumberPrice} from 'slices/calendar/lib/types';

const StyledInput = styled(Input)<{selected?: boolean}>`
  input {
    max-width: ${CELL_WIDTH}px;
    font-size: 12px;
    color: ${({theme, selected}) =>
      selected ? theme.palette.fontAccent : 'inherit'};
  }
`;

const Wrapper = styled.div``;

interface IProps {
  isRateEdit: boolean;
  rangeSelected: boolean;
  priceData: GuestNumberPrice;
  cell: SelectedCell;
  selected?: boolean;
}

const DataCellInputComponent: FC<IProps> = ({
  isRateEdit,
  rangeSelected,
  priceData,
  cell,
  selected,
}) => {
  const dispatch = useAppDispatch();
  const updatedCells = useAppSelector(selectUpdatedCells);
  const initialValue = isNil(priceData.price)
    ? CALENDAR_PRICE_PLACEHOLDER
    : String(priceData.price);
  const [cellPrice, setCellPrice] = useState(initialValue);

  const handleBlur = () => {
    dispatch(setEditedCell(DEFAULT_EDITED_CELL));

    if (initialValue === cellPrice) {
      return;
    }

    const newUpdatedCells = {
      ...updatedCells,
      [cell.room]: [{...cell, price: Number(cellPrice)}],
    };

    dispatch(setUpdatedCells(newUpdatedCells));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = Number(event.target.value);

    const newCellPrice =
      parsedValue > 0 ? event.target.value : String(MIN_RATE_PRICE);

    setCellPrice(newCellPrice);
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      handleBlur();
    }
  };

  useEffect(() => {
    if (initialValue !== cellPrice) {
      if (priceData.price) {
        setCellPrice(String(priceData.price));
        return;
      }

      if (isNil(priceData.price)) {
        setCellPrice(CALENDAR_PRICE_PLACEHOLDER);
      }

      setCellPrice('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceData.price]);

  const cellOutput = buildLabelRoomPrice(rangeSelected, priceData, cellPrice);

  return (
    <Wrapper>
      {isRateEdit && selected ? (
        <StyledInput
          type="number"
          label={null}
          autoComplete="off"
          value={cellPrice}
          transparent
          padding="0px"
          textCenter
          onBlur={handleBlur}
          onChange={handleChange}
          selected={selected}
          onKeyUp={handleEnterPress}
        />
      ) : (
        cellOutput
      )}
    </Wrapper>
  );
};

export const DataCellInput = memo(DataCellInputComponent);

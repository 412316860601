import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {INITIAL_FILTERS} from 'slices/bookings/lib/constants';
import {BookingsStore, CalendarDates} from 'slices/bookings/lib/types';
import {RootState} from 'source/store';
import {BookingsListParameters, BookingsListResponse} from 'types/api-scheme';

const initialState: BookingsStore = {
  bookings: null,
  filters: INITIAL_FILTERS,
  bookingPaymentMethods: [],
  bookingSorts: [],
  bookingHotelierStatuses: [],
  paymentVerification: [],
  bookingStatuses: [],
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    resetFilters: (state) => {
      state.filters = INITIAL_FILTERS;
    },
    setSortDirection: (
      state,
      action: PayloadAction<BookingsListParameters['sort_direction']>,
    ) => {
      state.filters.sort_direction = action.payload;
    },
    setSort: (state, action: PayloadAction<BookingsListParameters['sort']>) => {
      state.filters.sort = action.payload;
    },
    setBookings: (state, action: PayloadAction<BookingsListResponse>) => {
      state.bookings = action.payload;
    },
    setPaymentMethods: (
      state,
      action: PayloadAction<BookingPaymentMethods>,
    ) => {
      state.bookingPaymentMethods = action.payload;
    },
    setBookingStatuses: (state, action: PayloadAction<BookingStatuses>) => {
      state.bookingStatuses = action.payload;
    },
    setPaymentVerification: (
      state,
      action: PayloadAction<PaymentVerification>,
    ) => {
      state.paymentVerification = action.payload;
    },
    setBookingHotelierStatuses: (
      state,
      action: PayloadAction<BookingHotelierStatuses>,
    ) => {
      state.bookingHotelierStatuses = action.payload;
    },
    setBookingSorts: (state, action: PayloadAction<BookingSorts>) => {
      state.bookingSorts = action.payload;
    },
    setHotelId: (state, action: PayloadAction<number | null>) => {
      state.filters.hotel_id = action.payload;
    },
    setId: (state, action: PayloadAction<number | null>) => {
      state.filters.main_id = action.payload;
    },
    setHotelName: (state, action: PayloadAction<string | null>) => {
      state.filters.hotel_name = action.payload;
    },
    setCheckin: (state, action: PayloadAction<CalendarDates>) => {
      state.filters.checkin_from = action.payload.from;
      state.filters.checkin_to = action.payload.to;
    },
    setCheckout: (state, action: PayloadAction<CalendarDates>) => {
      state.filters.checkout_from = action.payload.from;
      state.filters.checkout_to = action.payload.to;
    },
    setCreatedAt: (state, action: PayloadAction<CalendarDates>) => {
      state.filters.created_at_from = action.payload.from;
      state.filters.created_at_to = action.payload.to;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.filters.last_name = action.payload;
    },
    setStatus: (
      state,
      action: PayloadAction<BookingsStore['filters']['status']>,
    ) => {
      state.filters.status = action.payload;
    },
    setPaymentType: (
      state,
      action: PayloadAction<BookingsStore['filters']['payment_type']>,
    ) => {
      state.filters.payment_type = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
  },
});

export const selectBookings = (state: RootState) => {
  return state.bookings.bookings;
};

export const selectFilters = (state: RootState) => {
  return state.bookings.filters;
};

export const selectDirectories = (state: RootState) => {
  return {
    bookingPaymentMethods: state.bookings.bookingPaymentMethods,
    paymentVerification: state.bookings.paymentVerification,
    bookingHotelierStatuses: state.bookings.bookingHotelierStatuses,
    bookingSorts: state.bookings.bookingSorts,
    bookingStatuses: state.bookings.bookingStatuses,
  };
};

export const {
  setBookings,
  setStatus,
  setPaymentType,
  setId,
  setHotelId,
  reset,
  setSort,
  setSortDirection,
  setLastName,
  setHotelName,
  setCheckin,
  resetFilters,
  setCreatedAt,
  setCheckout,
  setPage,
  setBookingHotelierStatuses,
  setPaymentMethods,
  setPaymentVerification,
  setBookingSorts,
  setBookingStatuses,
} = bookingsSlice.actions;
export default bookingsSlice.reducer;

import styled from '@emotion/styled';

import {stylable} from 'library/utilities/stylable';

const Wrapper = styled.main`
  padding: ${({theme}) => theme.paddings.main};
  min-height: calc(100vh - 171px);
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const Main = stylable(Wrapper);

export const SelectedImageIcon: React.FC<IconProps> = ({
  style,
  className,
  color,
}) => (
  <svg
    style={style}
    className={className}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="9.5" fill={color || '#FF5876'} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 17.3387C13.8292 17.3387 17.3387 13.8292 17.3387 9.5C17.3387 5.1708 13.8292 1.66129 9.5 1.66129C5.1708 1.66129 1.66129 5.1708 1.66129 9.5C1.66129 13.8292 5.1708 17.3387 9.5 17.3387ZM9.5 18.5C14.4706 18.5 18.5 14.4706 18.5 9.5C18.5 4.52944 14.4706 0.5 9.5 0.5C4.52944 0.5 0.5 4.52944 0.5 9.5C0.5 14.4706 4.52944 18.5 9.5 18.5Z"
      fill={color || '#FF5876'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 16.8387C13.5531 16.8387 16.8387 13.5531 16.8387 9.5C16.8387 5.44694 13.5531 2.16129 9.5 2.16129C5.44694 2.16129 2.16129 5.44694 2.16129 9.5C2.16129 13.5531 5.44694 16.8387 9.5 16.8387ZM19 9.5C19 14.7467 14.7467 19 9.5 19C4.25329 19 0 14.7467 0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5ZM17.3387 9.5C17.3387 13.8292 13.8292 17.3387 9.5 17.3387C5.1708 17.3387 1.66129 13.8292 1.66129 9.5C1.66129 5.1708 5.1708 1.66129 9.5 1.66129C13.8292 1.66129 17.3387 5.1708 17.3387 9.5ZM18.5 9.5C18.5 14.4706 14.4706 18.5 9.5 18.5C4.52944 18.5 0.5 14.4706 0.5 9.5C0.5 4.52944 4.52944 0.5 9.5 0.5C14.4706 0.5 18.5 4.52944 18.5 9.5Z"
      fill={color || '#FF5876'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.481 6.70114C13.7251 6.96932 13.7251 7.40414 13.481 7.67233L9.27045 12.2989C9.02637 12.567 8.63064 12.567 8.38656 12.2989C8.38038 12.2921 8.37436 12.2852 8.36849 12.2782L6.34713 10.0572C6.10304 9.78901 6.10304 9.35419 6.34712 9.086C6.59119 8.81781 6.98692 8.81781 7.231 9.08599L8.82887 10.8417L12.5971 6.70114C12.8412 6.43295 13.2369 6.43295 13.481 6.70114Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.34713 10.0572L5.97735 10.3937C5.55964 9.93478 5.55963 9.20842 5.97733 8.74946C6.41976 8.26333 7.15835 8.26332 7.60079 8.74945L8.82887 10.0988L12.2273 6.36461C12.6698 5.87847 13.4083 5.87846 13.8508 6.36459L13.481 6.70114C13.2369 6.43295 12.8412 6.43295 12.5971 6.70114L8.82887 10.8417L7.231 9.08599C6.98692 8.81781 6.59119 8.81781 6.34712 9.086C6.10304 9.35419 6.10304 9.78901 6.34713 10.0572ZM7.9928 12.6082L5.97735 10.3937L6.34713 10.0572L8.36849 12.2782C8.37436 12.2852 8.38038 12.2921 8.38656 12.2989L8.01678 12.6354C8.00863 12.6265 8.00064 12.6174 7.9928 12.6082ZM13.481 6.70114L13.8508 6.36459C14.2685 6.82355 14.2685 7.54991 13.8508 8.00887L9.64023 12.6354C9.19781 13.1215 8.45921 13.1215 8.01678 12.6354L8.38656 12.2989C8.63064 12.567 9.02637 12.567 9.27045 12.2989L13.481 7.67233C13.7251 7.40414 13.7251 6.96932 13.481 6.70114Z"
      fill="white"
    />
  </svg>
);

import {Theme} from '@emotion/react';
import {BOOKING_STATUSES, BookingsStore} from 'slices/bookings/lib/types';
import {SortsDirection} from 'source/utilities/business';

export const INITIAL_PAGE = 1;

export const INITIAL_FILTERS: BookingsStore['filters'] = {
  page: INITIAL_PAGE,
  main_id: null,
  hotel_id: null,
  last_name: null,
  created_at_from: null,
  created_at_to: null,
  checkin_from: null,
  checkin_to: null,
  checkout_from: null,
  checkout_to: null,
  status: null,
  hotel_name: null,
  sort_direction: SortsDirection.DESC,
  sort: 'created_at',
};

export const DEFAULT_SELECT_OPTION = {
  value: undefined,
  label: 'bookings:default_select_label',
};

export const HOTELIERS_VALUE = 'hoteliers';

export const BOOKING_STATUS_COLORS: Record<
  BOOKING_STATUSES,
  (theme: Theme) => string
> = {
  [BOOKING_STATUSES.BOOKED]: (theme: Theme) => theme.palette.wating,
  [BOOKING_STATUSES.CANCELLED]: (theme: Theme) => theme.palette.danger,
  [BOOKING_STATUSES.COMPLETED]: (theme: Theme) => theme.palette.success,
};

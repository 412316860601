import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';
import {useContext} from 'react';
import {Icon} from 'source/components/icon';
import {UserContext} from 'source/context/user';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  color: ${({theme}) => theme.palette.fontSecondary};
`;

const IconWrapper = styled.div``;

const UserButton = () => {
  const [user] = useContext(UserContext);

  const name =
    [user?.first_name, user?.last_name]
      .map((maybeString) => maybeString ?? '')
      .join(' ') || '';

  return (
    <StyledButton>
      <Tooltip
        on="hover"
        position="bottom left"
        trigger={
          <div>
            <StyledText size="S">{name}</StyledText>
          </div>
        }
      >
        {name}
      </Tooltip>

      <IconWrapper>
        <Icon name="user-icon" width={30} height={30} />
      </IconWrapper>
    </StyledButton>
  );
};

export default UserButton;

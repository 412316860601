import {
  EIntegrationAdditionalGuestPrice,
  EPriceType,
  EUnitRateType,
} from 'slices/rate/lib/types';

export const MAX_PRICE = 100000000;
export const MIN_PRICE = 0;
export const PERIOD_PICKER_MONTHS_COUNT = 1;
export const PERIOD_PICKER_MAX_INTERVAL = 365; // days
export const RANGE_KEY = 'period';
export const DEFAULT_MAX_TEXT_SIZE = 255

export const getMaxInputValueByUnitType = {
  [EUnitRateType.Currency]: 10000,
  [EUnitRateType.Night]: 50,
  [EUnitRateType.Percent]: 99,
};

export const MAX_INTEGRATION_GUEST_PRICE_VALUE = 100000;

export const MAX_INTEGRATION_GUEST_COUNT = 10;

export const MIN_INTEGRATION_GUEST_PRICE_VALUE = 0;

export const DEFAULT_PRICE_VALUE = 0;

export const MAX_DAYS_STAY = 50;

export const DEFAULT_DAYS_VALUE = 0;
export const INITIAL_DAYS_INPUT_VALUE = ' ';

export const PRICE_TYPE_OPTIONS = [
  {
    label: 'price_type_general',
    value: EPriceType.GENERAL,
  },
  {
    label: 'price_type_per_guest',
    value: EPriceType.PER_GUEST,
  },
  {
    label: 'price_per_day',
    value: EPriceType.PER_DAY,
  },
  {
    label: 'price_per_day_per_guest',
    value: EPriceType.PER_DAY_PER_GUEST,
  },
];

export const INTEGRATION_GUEST_PRICE_OPTIONS = [
  {
    value: EIntegrationAdditionalGuestPrice.CURRENCY,
    label: 'rate:common.money',
  },
];

export const DEFAULT_INTEGRATION_EXTRA_GUEST_PRICE_VALUE = 0;

export const DEFAULT_INTEGRATION_EXTRA_GUEST_PRICE_MAX_GUESTS = null;

import {path} from 'ramda';

import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {request} from 'library/api';
import {
  HotelsRealtyIntegrationsCopyableRoomsDetailResponse,
  HotelsRealtyIntegrationsCreateRequest,
  HotelsRealtyIntegrationsCreateResponse,
  HotelsRealtyIntegrationsDeleteResponse,
  HotelsRealtyIntegrationsDetailResponse,
  HotelsRealtyIntegrationsRestorePartialUpdateResponse,
} from 'types/api-scheme';

type GetIntegrationsContext = {
  hotelId: Hotel['id'];
};

const getIntegrations = ({hotelId}: GetIntegrationsContext) =>
  request<HotelsRealtyIntegrationsDetailResponse>(
    url(`hotels/${hotelId}/realty-integrations`),
    {
      method: 'GET',
      headers: clientHeaders(),
    },
  ).then(path<HotelsRealtyIntegrationsDetailResponse['data']>([1, 'data']));

type CreateIntegrationContext = {
  hotelId: Hotel['id'];
  payload: HotelsRealtyIntegrationsCreateRequest;
};

const createIntegration = ({hotelId, payload}: CreateIntegrationContext) =>
  request<HotelsRealtyIntegrationsCreateResponse>(
    url(`hotels/${hotelId}/realty-integrations`),
    {
      method: 'POST',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    },
  ).then(path<HotelsRealtyIntegrationsCreateResponse['data']>([1, 'data']));

type DeleteIntegrationContext = {
  hotelId: Hotel['id'];
  integrationId: RealtyIntegration['id'];
};

const deleteIntegration = ({
  hotelId,
  integrationId,
}: DeleteIntegrationContext) =>
  request<HotelsRealtyIntegrationsDeleteResponse>(
    url(`hotels/${hotelId}/realty-integrations/${integrationId}`),
    {
      method: 'DELETE',
      headers: clientHeaders(),
    },
  ).then(path<HotelsRealtyIntegrationsDeleteResponse['data']>([1, 'data']));

type GetCopyableRoomsContext = {
  hotelId: Hotel['id'];
};

const getCopyableRooms = ({hotelId}: GetCopyableRoomsContext) =>
  request<HotelsRealtyIntegrationsCopyableRoomsDetailResponse>(
    url(`hotels/${hotelId}/realty-integrations/copyable-rooms`),
    {
      method: 'GET',
      headers: clientHeaders(),
    },
  ).then(
    path<HotelsRealtyIntegrationsCopyableRoomsDetailResponse['data']>([
      1,
      'data',
    ]),
  );

type restoreIntegrationContext = {
  hotelId: Hotel['id'];
  integrationId: RealtyIntegration['id'];
};

const restoreIntegration = ({
  hotelId,
  integrationId,
}: restoreIntegrationContext) =>
  request<HotelsRealtyIntegrationsRestorePartialUpdateResponse>(
    url(`hotels/${hotelId}/realty-integrations/${integrationId}/restore`),
    {
      method: 'PATCH',
      headers: clientHeaders(),
    },
  ).then(
    path<HotelsRealtyIntegrationsRestorePartialUpdateResponse['data']>([
      1,
      'data',
    ]),
  );

export const realtyIntegrationApi = {
  get: getIntegrations,
  create: createIntegration,
  delete: deleteIntegration,
  restore: restoreIntegration,
  getCopyableRooms,
};

import React, {FC} from 'react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {Icon} from 'source/components/icon';
import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';
import Image from 'next/image';

const PreviewWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background: ${({theme}) => theme.palette.darkerBackground};
`;

const PreviewInfo = styled.div`
  display: flex;
  gap: 15px;
`;

const PreviewDelete = styled.div`
  cursor: pointer;
  padding: 20px 17px;

  svg path {
    stroke: ${({theme}) => theme.palette.primaryAccent};
  }
`;

const PreviewText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({theme}) => theme.palette.success};
  font-size: 16px;
  font-weight: 500;
`;

const PreviewImage = styled(Image)`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.palette.activeBackground};
`;

interface Props {
  id: Image['id'];
  url: Image['url'];
  deleteUploadedImage: (imageId: Image['id']) => void;
}

export const PreviewCard: FC<Props> = ({id, url, deleteUploadedImage}) => {
  const {t} = useTranslation('photos');
  return (
    <PreviewWrapper>
      <PreviewInfo>
        <PreviewImage alt={`image ${id}`} src={url} width={60} height={60} />
        <PreviewText>
          <Icon name="file-uploaded-check" width={10} height={8} />
          {t('file_uploaded')}
        </PreviewText>
      </PreviewInfo>
      <PreviewDelete onClick={() => deleteUploadedImage(id)}>
        <CloseIcon />
      </PreviewDelete>
    </PreviewWrapper>
  );
};

import {DirectoryType, OptionsType} from 'types/utilities';

export const transformDirectory = (options?: DirectoryType | null) => {
  if (!options) {
    return [];
  }
  return options.map((option) => {
    return {
      label: option.title,
      value: option.value,
    };
  });
};

export const getDirectoryOptionByValue = (value: any, options: OptionsType) => {
  const saveDirectories = options.find((option) => option.value === value);

  if (!saveDirectories) {
    const firstOption = options[0];
    return {
      value: firstOption?.value ?? '',
      label: firstOption?.label ?? '',
    };
  }

  return saveDirectories;
};

export const isNumberOptionEquals = (
  option: OptionsType[any] | DirectoryType[number],
  value: any,
) => {
  return Number(option?.value) === Number(value);
};

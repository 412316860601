export const dataTestId = {
  home: {
    title: 'home-title',
  },
  bookings: {
    title: 'bookings-title',
  },
  hotelReviews: {
    thumbnail: 'images-carousel-thumbnail',
    thumbnailPrev: 'images-carousel-prev',
    thumbnailNext: 'images-carousel-next',
  },
};

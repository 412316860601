/* eslint-disable no-param-reassign */
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {eachDayOfInterval, Interval} from 'date-fns';
import type {RootState} from 'source/store';
import {
  buildInitialSelectedDays,
  buildInitialMonthDates,
} from './lib/helpers/dates';
import {
  RangeSelectCells,
  SelectedCell,
  SelectedDatesType,
  UpdatedCellsType,
  NotAvailableDatesType,
  EditRoomsType,
  MassEditModalFiltersType,
  OpenedRoomsType,
  StoreRatesPricesType,
  StoreDateAvailabilitiesType,
  StoreRoomConstraintsType,
  SelectRangeType,
} from './lib/types';
import {
  DEFAULT_EDITED_CELL,
  DEFAULT_MASS_EDIT_MODAL_FILTERS,
  DEFAULT_RANGE_SELECTED_CELLS,
  DEFAULT_SELECTED_CELL,
  INITIAL_SELECT_RANGE_STATE,
} from './lib/constants';

type InitialState = {
  selectedDates: SelectedDatesType;
  selectedCell: SelectedCell;
  editedCell: SelectedCell;
  updatedCells: UpdatedCellsType;
  rangeSelectCells: RangeSelectCells;
  submitCells: UpdatedCellsType;
  roomShorts: HotelRoomsShorts;
  notAvailableDates: NotAvailableDatesType;
  isTimerEnabled: boolean;
  isShowDisabledRates: boolean;
  isLoading: boolean;
  isNeedLoadMoreDates: boolean;
  isNeedLoadBeforeDates: boolean;
  editRooms: EditRoomsType;
  isDatesLimitExceeded: boolean;
  isBeforeDatesLimitExceeded: boolean;
  massEditModalFilters: MassEditModalFiltersType;
  openedRooms: OpenedRoomsType;
  ratesPrices: StoreRatesPricesType;
  dateAvailabilities: StoreDateAvailabilitiesType;
  roomContraints: StoreRoomConstraintsType;
  selectedRange: SelectRangeType;
};

export const initialState: InitialState = {
  selectedDates: buildInitialSelectedDays(),
  selectedCell: DEFAULT_SELECTED_CELL,
  editedCell: DEFAULT_EDITED_CELL,
  updatedCells: {},
  rangeSelectCells: DEFAULT_RANGE_SELECTED_CELLS,
  submitCells: {},
  roomShorts: [],
  notAvailableDates: {},
  isTimerEnabled: false,
  isShowDisabledRates: false,
  isLoading: false,
  isNeedLoadMoreDates: false,
  isNeedLoadBeforeDates: false,
  editRooms: {},
  isDatesLimitExceeded: false,
  isBeforeDatesLimitExceeded: true,
  massEditModalFilters: DEFAULT_MASS_EDIT_MODAL_FILTERS,
  openedRooms: {},
  ratesPrices: {},
  dateAvailabilities: {},
  roomContraints: {},
  selectedRange: INITIAL_SELECT_RANGE_STATE,
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedDates: (
      state,
      action: PayloadAction<{from: string; to: string}>,
    ) => {
      state.selectedDates = {
        from: action.payload.from,
        to: action.payload.to,
      };
    },
    setSelectedCell: (state, action: PayloadAction<SelectedCell>) => {
      state.selectedCell = action.payload;
    },
    resetSelectedDates: (state) => {
      state.selectedDates = {...initialState.selectedDates};
    },
    resetRoomDataById: (state, action: PayloadAction<number>) => {
      const roomId = `${action.payload}`;

      if (state.roomContraints) {
        delete state.roomContraints[roomId];
      }
      if (state.ratesPrices) {
        delete state.ratesPrices[roomId];
      }
    },
    setRangeSelectCells: (state, action: PayloadAction<RangeSelectCells>) => {
      state.rangeSelectCells = action.payload;
    },
    resetRangeSelectCells: (state) => {
      state.rangeSelectCells = {...initialState.rangeSelectCells};
    },
    setEditedCell: (state, action: PayloadAction<SelectedCell>) => {
      state.editedCell = action.payload;
    },
    setUpdatedCells: (state, action: PayloadAction<UpdatedCellsType>) => {
      state.updatedCells = action.payload;
    },
    setSubmitCells: (state, action: PayloadAction<UpdatedCellsType>) => {
      state.submitCells = action.payload;
    },
    setRoomShorts: (state, action: PayloadAction<HotelRoomsShorts>) => {
      state.roomShorts = action.payload;
    },
    resetUpdatedCells: (state) => {
      state.updatedCells = {...initialState.updatedCells};
    },
    resetSubmitCells: (state) => {
      state.submitCells = {...initialState.submitCells};
    },
    setNotAvailableDates: (
      state,
      action: PayloadAction<NotAvailableDatesType>,
    ) => {
      state.notAvailableDates = action.payload;
    },
    setIsTimerEnabled: (state, action: PayloadAction<boolean>) => {
      state.isTimerEnabled = action.payload;
    },
    setIsShowDisabledRates: (state, action: PayloadAction<boolean>) => {
      state.isShowDisabledRates = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    // флаг при полном скролле вправо - для подгрузки данных
    setIsNeedLoadMoreDates: (state, action: PayloadAction<boolean>) => {
      state.isNeedLoadMoreDates = action.payload;
    },
    // флаг при полном скролле влево - для подгрузки данных
    setIsNeedLoadBeforeDates: (state, action: PayloadAction<boolean>) => {
      state.isNeedLoadBeforeDates = action.payload;
    },
    // обьект с boolean значениями(есть ли изменения в руме) и roomId ключами
    setEditRooms: (state, action: PayloadAction<EditRoomsType>) => {
      state.editRooms = action.payload;
    },
    // флаг для отключения подгрузки последующих данных - максимум год от текущей даты
    setIsDatesLimitExceeded: (state, action: PayloadAction<boolean>) => {
      state.isDatesLimitExceeded = action.payload;
    },
    // флаг для отключения подгрузки предыдущих данных - минимум сегодня
    setIsBeforeDatesLimitExceeded: (state, action: PayloadAction<boolean>) => {
      state.isBeforeDatesLimitExceeded = action.payload;
    },
    setMassEditModalFilters: (
      state,
      action: PayloadAction<MassEditModalFiltersType>,
    ) => {
      state.massEditModalFilters = action.payload;
    },
    resetMassEditModalFilters: (state) => {
      state.massEditModalFilters = {...initialState.massEditModalFilters};
    },
    setOpenedRooms: (state, action: PayloadAction<OpenedRoomsType>) => {
      state.openedRooms = action.payload;
    },
    setRatesPrices: (state, action: PayloadAction<StoreRatesPricesType>) => {
      state.ratesPrices = {
        ...state.ratesPrices,
        ...action.payload,
      };
    },
    setDateAvailabilities: (
      state,
      action: PayloadAction<StoreDateAvailabilitiesType>,
    ) => {
      state.dateAvailabilities = {
        ...state.dateAvailabilities,
        ...action.payload,
      };
    },
    setRoomConstraints: (
      state,
      action: PayloadAction<StoreRoomConstraintsType>,
    ) => {
      state.roomContraints = {
        ...state.roomContraints,
        ...action.payload,
      };
    },
    setSelectedRange: (state, action: PayloadAction<SelectRangeType>) => {
      state.selectedRange = action.payload;
    },
    resetSelectedRange: (state) => {
      state.selectedRange = {...initialState.selectedRange};
    },
  },
});

export const {
  reset,
  setSelectedDates,
  setSelectedCell,
  setRangeSelectCells,
  setEditedCell,
  setUpdatedCells,
  resetRangeSelectCells,
  setSubmitCells,
  setRoomShorts,
  resetSubmitCells,
  resetUpdatedCells,
  setNotAvailableDates,
  resetSelectedDates,
  setIsTimerEnabled,
  setIsShowDisabledRates,
  setIsLoading,
  setIsNeedLoadMoreDates,
  setIsNeedLoadBeforeDates,
  setEditRooms,
  setIsDatesLimitExceeded,
  setIsBeforeDatesLimitExceeded,
  setMassEditModalFilters,
  setOpenedRooms,
  setRatesPrices,
  setDateAvailabilities,
  setRoomConstraints,
  setSelectedRange,
  resetSelectedRange,
  resetMassEditModalFilters,
  resetRoomDataById,
} = calendarSlice.actions;

export const selectMonthsDates = createSelector(
  (state: RootState) => state,
  () => buildInitialMonthDates(),
);

export const selectDaysDates = createSelector(
  (state: RootState) => state.calendar.selectedDates,
  (selectedDates) => {
    const {from, to} = selectedDates;

    const interval: Interval = {
      start: new Date(from),
      end: new Date(to),
    };

    if (interval.start instanceof Date && interval.end instanceof Date) {
      const daysDates = eachDayOfInterval(interval);

      return daysDates.map((date, index) => ({
        id: index,
        date,
      }));
    }
    return [];
  },
);

export const selectSelectedDates = (state: RootState) => {
  return state.calendar.selectedDates;
};

export const selectMassEditModalFilters = (state: RootState) => {
  return (
    state.calendar.massEditModalFilters || initialState.massEditModalFilters
  );
};

export const selectOpenedRooms = (state: RootState) => {
  return state.calendar.openedRooms || initialState.openedRooms;
};

export const selectSelectedCell = (state: RootState) => {
  return state.calendar.selectedCell;
};

export const selectEditedCell = (state: RootState) => {
  return state.calendar.editedCell;
};

export const selectUpdatedCells = (state: RootState) => {
  return state.calendar.updatedCells;
};

export const selectSubmitCells = (state: RootState) => {
  return state.calendar.submitCells;
};

export const selectRoomShorts = (state: RootState) => {
  return state.calendar.roomShorts;
};

export const selectNotAvailableDates = (state: RootState) => {
  return state.calendar.notAvailableDates;
};

export const selectIsTimerEnabled = (state: RootState) => {
  return state.calendar.isTimerEnabled;
};

export const selectRangeSelectCells = (state: RootState) =>
  state.calendar.rangeSelectCells;

export const selectIsShowDisabledRates = (state: RootState) => {
  return state.calendar.isShowDisabledRates;
};

export const selectIsLoading = (state: RootState) => {
  return state.calendar.isLoading;
};

export const selectIsNeedLoadMoreDates = (state: RootState) => {
  return state.calendar.isNeedLoadMoreDates;
};

export const selectIsNeedLoadBeforeDates = (state: RootState) => {
  return state.calendar.isNeedLoadBeforeDates;
};

export const selectEditRooms = (state: RootState) => {
  return state.calendar.editRooms || initialState.editRooms;
};

export const selectIsDatesLimitExceeded = (state: RootState) => {
  return state.calendar.isDatesLimitExceeded;
};

export const selectIsBeforeDatesLimitExceeded = (state: RootState) => {
  return state.calendar.isBeforeDatesLimitExceeded;
};

export const selectRatesPrices = (state: RootState) => {
  return state.calendar.ratesPrices || initialState.ratesPrices;
};

export const selectDateAvailabilities = (state: RootState) => {
  return state.calendar.dateAvailabilities || initialState.dateAvailabilities;
};

export const selectRoomConstraints = (state: RootState) => {
  return state.calendar.roomContraints || initialState.roomContraints;
};

export const selectSelectedRange = (state: RootState) => {
  return state.calendar.selectedRange || initialState.selectedRange;
};

export default calendarSlice.reducer;

import {isNil} from 'ramda';
import {useContext} from 'react';
import {HotelContext} from 'source/context/hotel';

type TravelLineIntegrationHookReturn = [
  boolean,
  Partial<Hotel['travel_line_integration']>,
];

export const useTravelLineIntegration = (): TravelLineIntegrationHookReturn => {
  const [hotel] = useContext(HotelContext);

  if (!hotel) {
    return [false, undefined];
  }

  const hasIntegration =
    hotel &&
    'travel_line_integration' in hotel &&
    !isNil(hotel.travel_line_integration) &&
    hotel.travel_line_integration.is_configured;

  return [hasIntegration, hotel.travel_line_integration];
};

import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {FC, ReactNode, useEffect, useState, useRef} from 'react';

import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {LOADING_STYLES} from 'source/utilities/constants/css';
import {CircularLoader, LoaderVariant} from 'library/components/loader';
import {
  BottomArrowIconBig,
  BottomArrowIconSmall,
} from 'library/icons/bottom-arrow-icon';

import {Text, TextSize} from '../text';

const Wrapper = styled.div<{isLoading: boolean}>`
  display: flex;
  flex-direction: column;

  ${({isLoading}) =>
    isLoading &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
`;

const TitleWrapper = styled.div<{hasButtons?: boolean}>`
  display: flex;
  align-items: baseline;
  column-gap: 20px;

  ${({hasButtons}) =>
    hasButtons &&
    css`
      @media (max-width: 800px) {
        flex-direction: column;
        row-gap: 10px;
      }
    `}
`;

const Subtitle = styled(Text)`
  color: ${({theme}) => theme.palette.borderBlue};
`;

const StyledButton = styled.button<{isOpen: boolean; disabled: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border: none;
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  background: none;
  font-family: inherit;
  cursor: pointer;
  text-align: left;

  svg {
    transition: all 0.3s;
    transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : null)};
  }

  ${({disabled}) =>
    disabled &&
    css`
      ${LOADING_STYLES}
    `}
`;

interface Props {
  parentState?: boolean;
  dataId?: string | number;
  parentHandler?: (value: boolean) => void;
  changeStateAfterHandler?: boolean;
  title: string;
  children?: ReactNode;
  fixedChildren?: ReactNode;
  className?: string;
  loaderVariant?: LoaderVariant;
  size: TextSize;
  subtitle?: string;
  subtitleSize?: TextSize;
  scrollOnOpen?: boolean;
  buttons?: ReactNode;
  preventOpen?: boolean;
  onPreventOpen?: () => void;
  parentLoading?: boolean;
  disabled?: boolean;
}

export const ArrowText: FC<Props> = ({
  parentState = false,
  dataId,
  parentHandler = () => {},
  title,
  children,
  className,
  size,
  changeStateAfterHandler,
  loaderVariant,
  subtitle,
  subtitleSize,
  scrollOnOpen,
  buttons = null,
  preventOpen,
  onPreventOpen,
  parentLoading = false,
  fixedChildren = null,
  disabled = false,
}) => {
  const mobile = useDeviceDetection('mobile');
  const [isOpen, setIsOpen] = useState(parentState);
  const [isLoading, setLoading] = useState(parentLoading);
  const ref = useRef<HTMLDivElement>(null);

  const handleIsOpenChange = () => {
    if (disabled) {
      return;
    }

    setIsOpen((previous) => {
      const newIsOpen = !previous;

      if (newIsOpen && preventOpen) {
        onPreventOpen?.();
        return previous;
      }

      if (changeStateAfterHandler) {
        setLoading(true);
        Promise.resolve(parentHandler(newIsOpen)).then(() => {
          setLoading(false);
        });
      } else {
        parentHandler(newIsOpen);
      }

      return newIsOpen;
    });
  };

  useEffect(() => {
    setIsOpen(parentState);
  }, [parentState]);

  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  useEffect(() => {
    if (scrollOnOpen && ref && ref.current && isOpen) {
      ref.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [isOpen, scrollOnOpen]);

  return (
    <Wrapper
      data-id={dataId}
      className={className}
      ref={ref}
      isLoading={isLoading}
    >
      <StyledButton
        isOpen={isOpen}
        onClick={handleIsOpenChange}
        disabled={isLoading}
      >
        <TitleWrapper hasButtons={Boolean(buttons)}>
          <Text size={size}>{title}</Text>
          {subtitle && subtitleSize ? (
            <Subtitle size={subtitleSize}>{subtitle}</Subtitle>
          ) : null}
          {buttons}
        </TitleWrapper>
        {isLoading ? (
          <CircularLoader size={32} variant={loaderVariant} />
        ) : mobile ? (
          <BottomArrowIconSmall />
        ) : (
          <BottomArrowIconBig />
        )}
      </StyledButton>
      {fixedChildren}
      {isOpen && children}
    </Wrapper>
  );
};

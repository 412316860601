import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import styled from '@emotion/styled';

import {useAppDispatch, useAppSelector} from 'source/store';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';

import {selectRoomBabyBedAvailable, setBabyBedAvailable} from '../../store';
import {getTranslate} from '../../lib/helpers';

const BabyBedWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const BabyBedText = styled(Text)`
  margin-right: 12px;
`;

interface IProps {
  translateKey: string;
}

export const BabyBedSwitch: FC<IProps> = ({translateKey}) => {
  const babyBedAvailable = useAppSelector(selectRoomBabyBedAvailable);
  const dispatch = useAppDispatch();
  const {t} = useTranslation(translateKey);

  return (
    <BabyBedWrapper>
      <BabyBedText size="S">{getTranslate(t, 'add_baby_bed')}</BabyBedText>
      <Switch
        initialState={babyBedAvailable}
        onSwitch={async () => {
          dispatch(setBabyBedAvailable(!babyBedAvailable));
        }}
      />
    </BabyBedWrapper>
  );
};

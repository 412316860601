import {css, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {Badge} from 'library/components/badge';
import useTranslation from 'next-translate/useTranslation';
import {FC, PropsWithChildren} from 'react';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {useTravelLineIntegration} from 'source/utilities/hooks/use-is-travel-line-integration';

const Wrapper = styled.div``;

const InformationWrapper = styled.div``;

const StyledBadge = styled(Badge)`
  margin-bottom: 5px;
`;

const ChildrenWrapper = styled.div<{disableEvents: boolean}>`
  margin-top: 20px;

  ${({disableEvents}) =>
    disableEvents &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

interface Props extends PropsWithChildren {
  title?: string;
  subTitle?: string;
  disableEvents?: boolean;
}

const TravelLineProvider: FC<Props> = ({
  children,
  title,
  subTitle,
  disableEvents = true,
}) => {
  const theme = useTheme();
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation();
  const [hasIntegration] = useTravelLineIntegration();

  if (hasIntegration) {
    return (
      <Wrapper>
        <InformationWrapper>
          <StyledBadge
            textColor={theme.palette.defaultBackground}
            textSize={mobile ? 'XS' : 'S'}
            backgroundColor={theme.palette.primaryAccent}
          >
            {t(title || 'common:travel_line.error.use_travel_line_for_work_1')}
          </StyledBadge>
          <StyledBadge
            textColor={theme.palette.defaultBackground}
            textSize={mobile ? 'XS' : 'S'}
            backgroundColor={theme.palette.primaryAccent}
          >
            {t(
              subTitle || 'common:travel_line.error.use_travel_line_for_work_2',
            )}
          </StyledBadge>
        </InformationWrapper>
        <ChildrenWrapper disableEvents={disableEvents}>
          {children}
        </ChildrenWrapper>
      </Wrapper>
    );
  }

  return <Wrapper>{children}</Wrapper>;
};

export default TravelLineProvider;

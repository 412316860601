import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {FC, useState, useMemo, useEffect} from 'react';
import {DateRangeProps, RangeKeyDict} from 'react-date-range';
import useTranslation from 'next-translate/useTranslation';
import {addDays, isAfter, isBefore, isSameDay, subDays} from 'date-fns';
import {useRouter} from 'next/router';

import {useAppDispatch, useAppSelector} from 'source/store';
import {Icon} from 'source/components/icon';
import {
  addCurrentYearDays,
  isDatesWithinRange,
  parseRangeDateToBackendDateFormat,
} from 'source/utilities/dates';

import {Text} from 'library/components/text';
import {DatePicker} from 'library/components/date-picker';

import {
  CALENDAR_PICKER_MONTH_COUNT,
  HORIZONTAL_INFINITE_SCROLL_STEP,
  RANGE_KEY,
  VIEW_MAX_DATES_LIMIT_TOUCH,
} from 'slices/calendar/lib/constants';
import {
  formatDateValueForInput,
  formatDateToString,
  isRangeValid,
} from 'slices/calendar/lib/helpers/dates';
import {
  selectIsLoading,
  selectSelectedDates,
  setSelectedDates,
} from 'slices/calendar/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const Wrapper = styled.div<{noPadding?: boolean; disabled: boolean}>`
  margin-bottom: 15px;
  position: relative;

  ${({noPadding}) =>
    !noPadding &&
    css`
      padding: 0 22px;
      @media (max-width: 800px) {
        padding: 0 10px;
      }
    `}
`;

const PeriodHide = styled(Text)`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.palette.fontPrimary};
`;

const MaxDateRange = styled(PeriodHide)`
  padding-bottom: 5px;
  color: ${({theme}) => theme.palette.fontDefault};
`;

const StyledDatePicker = styled(DatePicker)`
  margin-bottom: 10px;
  width: 100%;

  .rdrMonth {
    width: 100%;
  }
  .rdrCalendarWrapper {
    width: 100%;
  }
`;

const DateInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  border-radius: 6px;
  background-color: ${({theme}) => theme.palette.border_3};
`;

interface Props {
  ranges: DateRangeProps['ranges'];
  setRanges: (ranges: DateRangeProps['ranges']) => void;
  onRangeChange?: (range: RangeKeyDict) => void;
  noPadding?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  className?: string;
}

export const CalendarMassEditPicker: FC<Props> = ({
  ranges,
  setRanges,
  onRangeChange,
  noPadding,
  minDate,
  maxDate,
  className,
}) => {
  const router = useRouter();
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);
  const {t} = useTranslation('calendar');
  const isTouch = useDeviceDetection('touch');
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectIsLoading);
  const selectedDates = useAppSelector(selectSelectedDates);
  const today = new Date();
  const maxEndDate = addCurrentYearDays(today);

  const parsedMinDate = useMemo(() => {
    const today = new Date();

    if (isTouch) {
      return new Date(selectedDates.from);
    }

    if (!minDate) {
      return today;
    }

    return isAfter(today, minDate) ? today : minDate;
  }, [minDate, selectedDates, isTouch]);

  const parsedMaxDate = useMemo(() => {
    const today = new Date();
    const maxEndDate = addCurrentYearDays(today);

    if (isTouch) {
      return new Date(selectedDates.to);
    }

    return maxDate || maxEndDate;
  }, [maxDate, selectedDates, isTouch]);

  const onChange = (range: RangeKeyDict) => {
    const {startDate, endDate} = range[RANGE_KEY];

    if (!startDate || !endDate) {
      return;
    }

    if (isTouch && !isRangeValid(startDate, endDate, isTouch)) {
      return;
    }

    setRanges([{startDate, endDate, key: RANGE_KEY}]);

    // При выборе диапазона закрывать календарь
    if (!isSameDay(startDate, endDate)) {
      setIsDatePickerOpened(false);
    }

    if (onRangeChange) {
      onRangeChange(range);
    }
  };

  useEffect(() => {
    const currentRange = ranges?.[0];

    if (!currentRange || isTouch) {
      return;
    }

    const {startDate, endDate} = currentRange;

    if (!startDate || !endDate) {
      return;
    }

    // Меняет диапазон дат в календаре только если выбранные даты не загружены
    if (
      !isDatesWithinRange(
        parseRangeDateToBackendDateFormat(selectedDates.from),
        parseRangeDateToBackendDateFormat(selectedDates.to),
        startDate,
        endDate,
      )
    ) {
      // Добавляется 45 дней вперед и 45 дней назад
      // идет проверка на превышение максимально допустимой даты в календаре
      const newEndDate = addDays(endDate, HORIZONTAL_INFINITE_SCROLL_STEP);
      const newFromDate = subDays(startDate, HORIZONTAL_INFINITE_SCROLL_STEP);

      const validatedFromDate = isBefore(newFromDate, today)
        ? today
        : newFromDate;
      const validatedEndDate = isAfter(newEndDate, maxEndDate)
        ? maxEndDate
        : newEndDate;

      dispatch(
        setSelectedDates({
          from: formatDateToString(validatedFromDate),
          to: formatDateToString(validatedEndDate),
        }),
      );
    }
  }, [ranges]);

  return (
    <Wrapper disabled={loading} noPadding={noPadding} className={className}>
      {isDatePickerOpened ? (
        <>
          {isTouch && (
            <MaxDateRange size="XS">
              {t('calendar:max_range', {count: VIEW_MAX_DATES_LIMIT_TOUCH})}
            </MaxDateRange>
          )}
          <PeriodHide size="XS" onClick={() => setIsDatePickerOpened(false)}>
            {t('room_range.hide')}
            <Icon
              name="cross"
              width={15}
              height={15}
              style={{marginLeft: 10}}
            />
          </PeriodHide>

          <StyledDatePicker
            ranges={ranges}
            onChange={onChange}
            weekdayDisplayFormat="EEEEEE"
            monthDisplayFormat="LLLL"
            months={CALENDAR_PICKER_MONTH_COUNT}
            showMonthAndYearPickers
            locale={router.locale}
            minDate={parsedMinDate}
            maxDate={parsedMaxDate}
          />
        </>
      ) : (
        <DateInput onClick={() => setIsDatePickerOpened(true)}>
          {formatDateValueForInput(ranges)}
          <Icon name="calendar" width={20} height={20} />
        </DateInput>
      )}
    </Wrapper>
  );
};

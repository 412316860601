import {getCurrentYearDays} from 'source/utilities/dates';

import {Option} from 'library/components/select';
import {
  SelectedCell,
  EAvailabilityReason,
  MassEditModalFiltersType,
  RangeSelectCells,
  SelectRangeType,
} from './types';

// значение при котором подсвечивается ячейка кол-ва номеров до сохранения ===
export const HIGHLIGHT_ROOMS_AVAILABILITY_NUMBER = 0;
export const CALENDAR_RANGE_DATE_FORMAT = 'yyyy-MM-dd';
export const CALENDAR_PRICE_PLACEHOLDER = '—';
export const CALENDAR_DEFAULT_PRICE = '?';
export const MIN_CLOSE_ROOMS_NUMBER = 1;
export const INITIAL_DAYS_RANGE = 44; // фактически это 45
export const MAX_DATES_LIMIT_TOUCH = 44; // фактически это 45
export const MONTH_CLICK_DAYS_COUNT = 7; // при клике на месяц выбирается текущий месяц и 7 дней до/после
export const VIEW_MAX_DATES_LIMIT_TOUCH = MAX_DATES_LIMIT_TOUCH + 1; // для вывода пользователю
export const MAX_DAYS_RANGE = getCurrentYearDays();
export const SCROLL_HEADER_RANGE_VALUE = 100; // При каждом изменении даты будет добавлять 100 скролла, чтобы не срабатывала прогрузка предыдущих дат
export const SCROLL_DEFAULT_SET_VALUE = -99999;
export const DEGREE_OF_ELEVATION = 2;
export const START_OF_MONTH_DAY = 1;
export const HORIZONTAL_INFINITE_SCROLL_OFFSET = 10;
export const HORIZONTAL_INFINITE_SCROLL_STEP = 45;
export const MIN_PRICE_VALUE = 1;
export const MONTH_CLICK_HORIZONTAL_OFFSET = 50;
export const CALENDAR_PICKER_MONTH_COUNT = 1;
export const INITIAL_SELECT_RANGE_STATE: SelectRangeType = {
  position: {x: -99999, y: -99999},
  to: 0,
  from: 0,
  rateId: -1,
  room: -1,
  guestNumber: -1,
};
export const DEFAULT_EDITED_CELL: SelectedCell = {
  room: 0,
  column: 0,
  row: 'rooms_count',
  price: 0,
  date: '',
  isDatePrice: false,
};
export const RANGE_KEY = 'selected_prices_date_range';
export const DEFAULT_RANGE_SELECTED_CELLS: RangeSelectCells = {
  rateId: -1,
  guestNumber: -1,
  prices: [],
  room: -1,
};
export const DEFAULT_SELECTED_CELL: SelectedCell = {
  room: 0,
  column: 0,
  row: 'cancellation',
};

export const MIN_RATE_PRICE = 1;

export const DEFAULT_CLOSE_ROOMS_OPTION = {
  label: `${MIN_CLOSE_ROOMS_NUMBER}`,
  value: MIN_CLOSE_ROOMS_NUMBER,
};

export const AVAILABILITY_REASON_OPTIONS = [
  {
    label: 'calendar:room_range.unavailable',
    value: EAvailabilityReason.UNAVAILABLE,
  },
  {
    label: 'calendar:room_range.repair',
    value: EAvailabilityReason.REPAIR,
  },
  {
    label: 'calendar:room_range.quota',
    value: EAvailabilityReason.QUOTA,
  },
];

export const CELL_GAP = 2;
export const CELL_WIDTH = 60;

// secs
export const TIMER_INITIAL_COUNTDOWN = 600;
export const TIMER_START_STEP = 30;
export const DEFAULT_MAX_SECONDS = -1;
export const TIMER_END_SECONDS = 0;

export const HUMAN_COUNTER_DIVIDER = 'x';
export const HUMAN_GUESTS_DIVIDER = ', ';
export const SCROLL_CLASSNAME = 'scroll';
export const MONTH_SCROLL_CLASSNAME = 'month-scroll';
export const DATES_SCROLL_CLASSNAME = 'dates-scroll';

export const DEFAULT_MASS_EDIT_MODAL_FILTERS: MassEditModalFiltersType = {
  roomId: -1,
  rateId: -1,
  guestNumber: [1],
};

export const DEFAULT_RATE_OPTION: Option = {
  value: null,
  label: 'calendar:not_choosed',
};
export const DEFAULT_GUEST_NUMBER_OPTION: Option = {label: '1', value: 1};

// сегодня и завтра при нажатии на открытие попапа масс эдита "кнопка +"
export const MOBILE_SELECT_DATES_INDEXES: {from: number; to: number} = {
  from: 0,
  to: 2,
};

export const DEFAULT_RATE_PERIOD = {from: null, to: null};
export const ALLOWED_OPENED_ROOMS_COUNT = 3;
export const DEFAULT_RATE_SELECT = null;
export const DEFAULT_GUEST_NUMBER = 1;

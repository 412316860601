import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC, useState} from 'react';
import {
  DEFAULT_BALANCE,
  MINIMAL_WITHDRAW_BALANCE,
} from 'slices/balance/lib/constants';
import {withdrawBalance} from 'slices/balance/network';
import {
  selectLoading,
  selectSelectedOrganization,
  setSelectedOrganization,
  setLoading,
} from 'slices/balance/store';
import {Icon} from 'source/components/icon';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {CircularLoader} from 'library/components/loader';
import {getMaxWithdrawAmount} from 'slices/balance/lib/utils';
import {MinimalAmountTooltip} from '../minimal-amount-tooltip';
import {SumInput} from './sum-input';

const Wrapper = styled.div`
  width: 480px;
  border-radius: 6px 0px 0px 0px;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const HeaderTitle = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
`;

const CloseButton = styled(Button)`
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  padding: 0;
  border: unset;

  &:hover {
    border: unset;
    transform: unset;
  }
`;

const Main = styled.div`
  margin-bottom: 24px;
`;

const AvailableWithdrawalWrapper = styled.div`
  margin-bottom: 13px;
`;

const AvailableWithdrawalTitle = styled(Text)``;

const AvailableWithdrawalAmount = styled(Text)``;

const Footer = styled.div``;

const ConfirmWithdrawalButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`;

interface Props {
  onClose: () => void;
}

export const BalanceWithdrawalContent: FC<Props> = ({onClose}) => {
  const {t} = useTranslation('balance');
  const isMobile = useDeviceDetection('mobile');
  const dispatch = useAppDispatch();

  const selectedOrganization = useAppSelector(selectSelectedOrganization);
  const loading = useAppSelector(selectLoading);

  const textSize = isMobile ? 'S' : 'M';
  const bigTextSize = isMobile ? 'M' : 'L';

  const [amount, setAmount] = useState('');
  const parsedAmount = Number(amount);

  const withdrawalDisabled = !amount || parsedAmount < MINIMAL_WITHDRAW_BALANCE;

  if (!selectedOrganization) {
    return null;
  }

  const availableAmount =
    selectedOrganization.balance?.amount || DEFAULT_BALANCE;

  const maxWithdrawAmount = getMaxWithdrawAmount(availableAmount);

  const handleWithdrawbalance = () => {
    dispatch(setLoading(true));

    withdrawBalance(t, selectedOrganization.id, parsedAmount).finally(() => {
      dispatch(setLoading(false));
      dispatch(setSelectedOrganization(null));
    });
  };

  return (
    <Wrapper>
      <Header>
        <HeaderTitle size={textSize}>
          {t('balance_withdrawal.modal.header.title')}
        </HeaderTitle>
        <CloseButton onClick={onClose}>
          <Icon name="close" width={20} height={20} />
        </CloseButton>
      </Header>
      <Main>
        <AvailableWithdrawalWrapper>
          <AvailableWithdrawalTitle size={bigTextSize}>
            {t('balance_withdrawal.modal.main.available_withdrawal_title')}
          </AvailableWithdrawalTitle>
          <AvailableWithdrawalAmount size={bigTextSize}>
            {t('balance_withdrawal.modal.main.available_withdrawal_amount', {
              amount: availableAmount,
              currency: t('common:units_payments.rub'),
            })}
          </AvailableWithdrawalAmount>
        </AvailableWithdrawalWrapper>
        <SumInput
          maxCount={maxWithdrawAmount}
          amount={amount}
          setAmount={setAmount}
        />
      </Main>
      <Footer>
        <MinimalAmountTooltip
          position="top center"
          disabled={!withdrawalDisabled}
          trigger={
            <div>
              <ConfirmWithdrawalButton
                onClick={handleWithdrawbalance}
                disabled={withdrawalDisabled || loading}
              >
                {loading ? (
                  <CircularLoader size={16} />
                ) : (
                  t('balance_withdrawal.modal.footer.confirm_button_label')
                )}
              </ConfirmWithdrawalButton>
            </div>
          }
        />
      </Footer>
    </Wrapper>
  );
};

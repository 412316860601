import {Option} from 'library/components/select';
import {Translate, TranslationQuery} from 'next-translate';
import {CreateHotelierObjectDirectories} from 'slices/create_hotelier_object/lib/types';
import {api} from 'source/utilities/api';
import {handleException} from 'source/utilities/exceptions/business';

export const getCreateHotelierObjectTranslate = (
  t: Translate,
  key: string,
  parameters?: TranslationQuery
) => t(`${key}`, parameters);

export const getLabel = (t: Translate, key: string) =>
  getCreateHotelierObjectTranslate(t, `labeled.${key}`);

export const directoriesToOption = (
  hotelTypes?: Required<CreateHotelierObjectDirectories>['directories']['hotelTypes']
): Option[] => {
  if (!hotelTypes) {
    return [];
  }

  return hotelTypes.map((type) => ({
    label: type.name,
    value: type.id,
  }));
};

export const createHotel = (
  payload: HotelCreateBody,
  onComplete: () => void,
  ...dispatchers: [LoadingDispatch]
) => {
  const [setLoading] = dispatchers;

  setLoading(true);

  api.hotel
    .create({payload})
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

import {Theme} from '@emotion/react';
import {StoreBalance} from 'slices/common';
import {DEFAULT_BALANCE, MAXIMUM_WITHDRAW_BALANCE} from './constants';

export const getAmountColor = (theme: Theme, amount: number) =>
  amount > DEFAULT_BALANCE ? theme.palette.success : theme.palette.fontDefault;

export const getHoldAmountColor = (theme: Theme, holdAmount: number) =>
  holdAmount > DEFAULT_BALANCE
    ? theme.palette.wating
    : theme.palette.fontDefault;

export const getMaxWithdrawAmount = (availableAmount: number) =>
  Math.min(availableAmount, MAXIMUM_WITHDRAW_BALANCE);

export const parsedBalance = (balance: User['balance']): StoreBalance => {
  return {amount: balance.amount, amountHold: balance.amount_hold};
};

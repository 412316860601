import {isBoolean} from '../guards/types';

export const parseNumberQueryParameter = (
  parameter?: string | string[],
  defaultValue?: number,
): number => {
  return parameter
    ? Array.isArray(parameter)
      ? Number.parseInt(parameter[0], 10)
      : Number.parseInt(parameter, 10)
    : defaultValue || 0;
};

export const parseBooleanQueryParameter = (
  parameter?: string | string[],
): boolean => {
  const value = Array.isArray(parameter) ? parameter[0] : parameter;

  if (!value) {
    return false;
  }

  return JSON.parse(value);
};

export const getQueryParameterFromBoolean = (
  key: string,
  parameter?: boolean,
) => isBoolean(parameter) && {[key]: JSON.stringify(parameter)};

export const parseStringQueryParameter = (
  parameter?: string | string[],
  defaultValue?: string,
): string => {
  return parameter
    ? Array.isArray(parameter)
      ? parameter[0]
      : parameter
    : defaultValue || '';
};

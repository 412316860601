import React, {useContext, createContext} from 'react';
import styled from '@emotion/styled';
import type {DraggableSyntheticListeners} from '@dnd-kit/core';

const CardsWrapper = styled.button`
  position: absolute;
  transform: rotate(90deg);
  bottom: -5px;
  left: 6px;
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 6px 0 6px 0;
  border: none;
  outline: none;
  appearance: none;
  background-color: ${({theme}) => theme.palette.dragBackground};
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: ${({theme}) => theme.palette.dragBackground};
  }

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: ${({theme}) => theme.palette.defaultBackground};
  }
`;

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

export const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

export const DragButton = () => {
  const {attributes, listeners, ref} = useContext(SortableItemContext);

  return (
    <CardsWrapper {...attributes} {...listeners} ref={ref}>
      <svg viewBox="0 0 20 20" width="12">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z" />
      </svg>
    </CardsWrapper>
  );
};

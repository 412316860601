import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {CircularLoader} from 'library/components/loader';
import useTranslation from 'next-translate/useTranslation';
import {loadMoreOrganizations} from 'slices/balance/network';
import {
  selectIsLast,
  selectLoading,
  selectOrganizations,
  selectPage,
  setIsLast,
  setLoading,
  setOrganizations,
  setPage,
} from 'slices/balance/store';
import {useAppDispatch, useAppSelector} from 'source/store';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

const StyledButton = styled(Button)`
  width: 205px;
`;

export const LoadMoreButton = () => {
  const {t} = useTranslation('balance');
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const organizations = useAppSelector(selectOrganizations);
  const page = useAppSelector(selectPage);
  const isLast = useAppSelector(selectIsLast);

  const onLoadMoreSuccess = (
    response: ExistingRequisites,
    nextPage: number,
  ) => {
    dispatch(setOrganizations([...organizations, ...response.items]));
    dispatch(setIsLast(nextPage === response.pagination.last_page));
    dispatch(setPage(nextPage));
  };

  const handleLoadMore = () => {
    dispatch(setLoading(true));
    const nextPage = page + 1;

    loadMoreOrganizations(nextPage, onLoadMoreSuccess).finally(() =>
      dispatch(setLoading(false)),
    );
  };

  if (isLast) {
    return null;
  }

  return (
    <Wrapper>
      <StyledButton onClick={handleLoadMore} disabled={loading}>
        {loading ? (
          <CircularLoader size={16} />
        ) : (
          t('balance_operations_table.load_more')
        )}
      </StyledButton>
    </Wrapper>
  );
};

import styled from '@emotion/styled';
import {CircularLoader} from 'library/components/loader';
import {FC} from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
`;

export const Loader: FC<{loading: boolean}> = ({loading}) => {
  if (loading) {
    return (
      <Wrapper>
        <CircularLoader />
      </Wrapper>
    );
  }

  return null;
};

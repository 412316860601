import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  UserNotificationsAttachingTelegramListResponse,
  UserNotificationsChannelsListResponse,
  UserNotificationsTelegramBotInfoListResponse,
} from 'types/api-scheme';

const attachTelegram = () =>
  request<UserNotificationsAttachingTelegramListResponse>(
    url('user/notifications/attaching/telegram'),
    {
      method: 'GET',
      headers: clientHeaders(),
    },
  ).then(
    path<UserNotificationsAttachingTelegramListResponse['data']>([1, 'data']),
  );

const detachTelegram = () =>
  request<UserNotificationsAttachingTelegramListResponse>(
    url('user/notifications/attaching/telegram'),
    {
      method: 'DELETE',
      headers: clientHeaders(),
    },
  );

type GetNotificationChannelsContext = {
  headers: Headers;
};

const getNotificationChannels = ({headers}: GetNotificationChannelsContext) =>
  request<UserNotificationsChannelsListResponse>(
    url('user/notifications/channels'),
    {
      method: 'GET',
      headers,
    },
  ).then(path<UserNotificationsChannelsListResponse['data']>([1, 'data']));

type DeleteNotificationChannel = {
  channelId: NotificationChannel['id'];
};
const deleteNotificationChannel = ({channelId}: DeleteNotificationChannel) =>
  request<UserNotificationsChannelsListResponse>(
    url(`user/notifications/channels/${channelId}`),
    {
      method: 'DELETE',
      headers: clientHeaders(),
    },
  );

const getTelegramBotInfo = () =>
  request<UserNotificationsTelegramBotInfoListResponse>(
    url('user/notifications/telegram-bot-info'),
    {
      method: 'GET',
      headers: clientHeaders(),
    },
  ).then(
    path<UserNotificationsTelegramBotInfoListResponse['data']>([1, 'data']),
  );

export const socialSubscribe = {
  attachTelegram,
  detachTelegram,
  getNotificationChannels,
  deleteNotificationChannel,
  getTelegramBotInfo,
};

import {
  removeNotificationChannel,
  setNotificationChannels,
} from 'slices/profile/store';
import {DURATION_POLLING_UPDATE_NOTIFICATION_CHANNELS} from 'slices/profile/ui/notification-channels/lib/constants';
import {store} from 'source/store';
import {api} from 'source/utilities/api';
import CLongPolling from 'source/utilities/long-polling';
import {clientHeaders} from 'source/utilities/network/http';

export const pollingUpdateSubscribeChannels = () => {
  const abortController = new AbortController();

  const updateChannels = async () => {
    api.socialSubscribe
      .getNotificationChannels({headers: clientHeaders()})
      .then((result) => {
        if (result) {
          store.dispatch(setNotificationChannels(result));
        }
      });
  };

  return new CLongPolling({
    duration: DURATION_POLLING_UPDATE_NOTIFICATION_CHANNELS,
    takeWhile: () => true,
    mergeMap: updateChannels,
    finalize: () => {},
    abortController,
  });
};

export const handleDeleteNotificationChannel = (
  channelId: NotificationChannel['id'],
  ...dispatchers: [LoadingDispatch]
) => {
  const [setLoading] = dispatchers;

  setLoading(true);

  api.socialSubscribe
    .deleteNotificationChannel({channelId})
    .then(() => {
      const {notificationChannels} = store.getState().profile;

      if (notificationChannels) {
        store.dispatch(removeNotificationChannel(channelId));
      }
    })
    .finally(() => setLoading(false));
};

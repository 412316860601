import {Theme} from '@emotion/react';
import {Translate} from 'next-translate';

const INITIALIZING = 1;
const NOT_ACTIVE = 2;
const ACTIVE = 3;
const PAUSED = 4;
const BANNED = 5;

export enum SortsDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum HotelStatusEnum {
  Initializing = 1,
  NotActive = 2,
  Active = 3,
  Paused = 4,
  Banned = 5,
}

export const getHotelStatus = (value?: number) => {
  if (!value) {
    return null;
  }
  switch (value) {
    case INITIALIZING: {
      return HotelStatusEnum.Initializing;
    }
    case NOT_ACTIVE: {
      return HotelStatusEnum.NotActive;
    }
    case ACTIVE: {
      return HotelStatusEnum.Active;
    }
    case PAUSED: {
      return HotelStatusEnum.Paused;
    }
    case BANNED: {
      return HotelStatusEnum.Banned;
    }
    default: {
      return HotelStatusEnum.Active;
    }
  }
};

const PERSON = 1;
const SELF_EMPLOYED = 2;
const INDIVIDUAL_ENTREPRENEUR = 3;
const JURIDICAL_PERSON = 4;

export enum LegalFormEnum {
  Person = PERSON,
  SelfEmployed = SELF_EMPLOYED,
  IndividualEntrepreneur = INDIVIDUAL_ENTREPRENEUR,
  JuridicalPerson = JURIDICAL_PERSON,
}

export const getLegalForm = (value?: number) => {
  if (!value) {
    return null;
  }
  switch (value) {
    case PERSON: {
      return LegalFormEnum.Person;
    }
    case SELF_EMPLOYED: {
      return LegalFormEnum.SelfEmployed;
    }
    case INDIVIDUAL_ENTREPRENEUR: {
      return LegalFormEnum.IndividualEntrepreneur;
    }
    case JURIDICAL_PERSON: {
      return LegalFormEnum.JuridicalPerson;
    }
    default: {
      return LegalFormEnum.IndividualEntrepreneur;
    }
  }
};

export enum CancellationUnitType {
  NIGHT = 1,
  PERCENT = 2,
  CURRENCY = 3,
}

export enum CancellationType {
  NIGHT = 1,
  HALF_COST = 2,
  FULL_COST = 3,
  OTHER = 4,
}

export enum CancellationValue {
  NIGHT = 1,
  HALF_COST = 50,
  FULL_COST = 100,
}

export const formatCancellationUnitType = (
  cancellationType?: number,
  unitType?: number,
  withoutDays?: boolean,
) => {
  if (cancellationType === CancellationType.OTHER) {
    switch (unitType) {
      case CancellationUnitType.PERCENT: {
        return 'common:units_payments.percent';
      }
      case CancellationUnitType.CURRENCY: {
        return 'common:units_payments.rub';
      }
      default: {
        return '';
      }
    }
  }

  switch (cancellationType) {
    case CancellationType.NIGHT: {
      return 'common:night';
    }
    case CancellationType.HALF_COST: {
      return 'common:units_payments.percent';
    }
    case CancellationType.FULL_COST: {
      return 'common:units_payments.percent';
    }
    default: {
      return '';
    }
  }
};

export const formatUnitValue = (
  cancellationType?: number,
  value?: number | null,
) => {
  switch (cancellationType) {
    case CancellationType.NIGHT: {
      return CancellationValue.NIGHT;
    }
    case CancellationType.HALF_COST: {
      return CancellationValue.HALF_COST;
    }
    case CancellationType.FULL_COST: {
      return CancellationValue.FULL_COST;
    }
    case CancellationType.OTHER: {
      return value;
    }
    default: {
      return '';
    }
  }
};

export const formatCancellation = (
  cancellationFine: CalendarRatesPrice['cancellation_fine'],
  translate: Translate,
  withoutDays?: boolean,
): string => {
  const days = `${withoutDays ? '' : `${cancellationFine?.days}/`}`;
  const unitValue = formatUnitValue(
    cancellationFine?.type,
    cancellationFine?.value,
  );
  const unitType = translate(
    formatCancellationUnitType(
      cancellationFine?.type,
      cancellationFine?.unit_type?.value,
      withoutDays,
    ),
  );

  return `${days}${unitValue}${unitType}`;
};

export const getStatusColor = (
  theme: Theme,
  status: HotelStatusEnum | null,
) => {
  if (!status) {
    return theme.palette.fontDefault;
  }
  const colors = {
    [HotelStatusEnum.Initializing]: theme.palette.action,
    [HotelStatusEnum.NotActive]: theme.palette.danger,
    [HotelStatusEnum.Active]: theme.palette.success,
    [HotelStatusEnum.Paused]: theme.palette.wating,
    [HotelStatusEnum.Banned]: theme.palette.danger,
  };
  return colors[status];
};

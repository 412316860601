import {css, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {
  RowBody,
  RowBodyData,
  RowHead,
  RowHeadData,
  TableBoilerplate,
} from 'library/components/table';
import {SubText, Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {
  DEFAULT_BALANCE,
  MINIMAL_WITHDRAW_BALANCE,
} from 'slices/balance/lib/constants';
import {
  selectLoading,
  selectOrganizations,
  setSelectedOrganization,
} from 'slices/balance/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {LOADING_STYLES} from 'source/utilities/constants/css';
import {CircularLoader} from 'library/components/loader';
import {getAmountColor, getHoldAmountColor} from 'slices/balance/lib/utils';
import {Link} from 'source/components/link';
import {buildBalanceDetailsRoute} from 'source/utilities/url';
import {MinimalAmountTooltip} from '../minimal-amount-tooltip';

const TableWrapper = styled.div<{isLoading: boolean}>`
  overflow: auto;
  position: relative;

  ${({isLoading}) =>
    isLoading &&
    css`
      ${LOADING_STYLES}

      * {
        pointer-events: none;
      }
    `}
`;

const StyledRowHeadData = styled(RowHeadData)`
  font-weight: 300;
`;

const StyledCircularLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

const WithdrawButton = styled(Button)`
  padding: 12px;
`;

const WithdrawWrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: end;
`;

const Address = styled(RowBodyData)`
  max-width: 250px;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
`;

const Name = styled(Address)`
  max-width: 300px;

  a {
    transition: color 0.3s ease-in-out;
    text-decoration: underline;

    &:hover {
      color: ${({theme}) => theme.palette.fontPrimary};
    }
  }
`;

export const BalanceOrganizationsTable = () => {
  const {t} = useTranslation('balance');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const organizations = useAppSelector(selectOrganizations);
  const isLoading = useAppSelector(selectLoading);

  return (
    <TableWrapper isLoading={isLoading}>
      {isLoading && <StyledCircularLoader size={64} variant="main" />}
      <TableBoilerplate
        rowHeadChildren={
          <RowHead>
            <StyledRowHeadData isFirst>
              {t('balance_operations_table.head.id')}
            </StyledRowHeadData>
            <StyledRowHeadData>
              {t('balance_operations_table.head.name')}
            </StyledRowHeadData>
            <StyledRowHeadData>
              {t('balance_operations_table.head.type')}
            </StyledRowHeadData>
            <StyledRowHeadData>
              {t('balance_operations_table.head.address')}
            </StyledRowHeadData>

            <StyledRowHeadData>
              {t('balance_operations_table.head.hold_amount')}
            </StyledRowHeadData>
            <StyledRowHeadData>
              {t('balance_operations_table.head.available_amount')}
            </StyledRowHeadData>
            <StyledRowHeadData isLast>
              {t('balance_operations_table.head.actions')}
            </StyledRowHeadData>
          </RowHead>
        }
        rowBodyChildren={
          <>
            {organizations?.map((organization) => {
              const {id, name, type, address, balance} = organization;
              const availableBalance = balance?.amount || DEFAULT_BALANCE;
              const holdBalance = balance?.amount_hold || DEFAULT_BALANCE;
              const isWithdrawDisabled =
                availableBalance <= MINIMAL_WITHDRAW_BALANCE;

              return (
                <RowBody key={`organization_row_${id}`}>
                  <RowBodyData isFirst>
                    <Text size="S" color={theme.palette.action}>
                      {id}
                    </Text>
                  </RowBodyData>
                  <Name>
                    <Link href={buildBalanceDetailsRoute(id)}>{name}</Link>
                  </Name>

                  <RowBodyData>{type.title}</RowBodyData>
                  <Address>{address}</Address>
                  <RowBodyData>
                    <SubText
                      size="S"
                      color={getHoldAmountColor(theme, holdBalance)}
                    >
                      {holdBalance}{' '}
                    </SubText>
                    <SubText size="S">{t('common:units_payments.rub')}</SubText>
                  </RowBodyData>
                  <RowBodyData>
                    <SubText
                      size="S"
                      color={getAmountColor(theme, availableBalance)}
                    >
                      {availableBalance}{' '}
                    </SubText>
                    <SubText size="S">{t('common:units_payments.rub')}</SubText>
                  </RowBodyData>
                  <RowBodyData isLast>
                    <WithdrawWrapper>
                      <MinimalAmountTooltip
                        position="bottom right"
                        disabled={!isWithdrawDisabled}
                        trigger={
                          <div>
                            <WithdrawButton
                              buttonType="action"
                              disabled={isWithdrawDisabled}
                              onClick={() =>
                                dispatch(setSelectedOrganization(organization))
                              }
                            >
                              {t('balance_operations_table.head.withdraw')}
                            </WithdrawButton>
                          </div>
                        }
                      />
                    </WithdrawWrapper>
                  </RowBodyData>
                </RowBody>
              );
            })}
          </>
        }
      />
    </TableWrapper>
  );
};

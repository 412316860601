import {Headline} from 'library/components/headline';
import useTranslation from 'next-translate/useTranslation';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Descriptions} from './ui/descriptions';
import {HotelInfo} from './ui/hotel-info';

const Content = () => {
  const {t} = useTranslation('general-info');
  const mobile = useDeviceDetection('mobile');

  return (
    <>
      <Headline level={mobile ? 'M' : 'XL'}>{t('headline')}</Headline>
      <HotelInfo />
      <Descriptions />
    </>
  );
};

export default Content;

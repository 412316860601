import {request} from 'library/api';
import {path} from 'ramda';
import {HotelStatusEnum} from 'source/utilities/business';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsDeleteResponse,
  HotelsDetailResponse,
  HotelsPublicRatesUpdateResponse,
  HotelsUpdateRequest,
  HotelsUpdateResponse,
  HotelsUpdateStatusCreateResponse,
} from 'types/api-scheme';

type CreateHotelContext = {
  payload: HotelCreateBody;
};
const createHotel = ({payload}: CreateHotelContext) =>
  request<HotelCreateBody>(url('hotels'), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  }).then(path<HotelsDetailResponse['data']>([1, 'data']));

type GetHotelContext = {
  hotelId: number;
  headers: Headers;
};
const getHotel = ({hotelId, headers}: GetHotelContext) =>
  request<HotelsDetailResponse>(url(`hotels/${hotelId}`), {
    method: 'GET',
    headers,
  }).then(path<HotelsDetailResponse['data']>([1, 'data']));

type UpdateHotelContext = {
  hotelId: number;
  payload: Partial<HotelsUpdateRequest>;
};
const updateHotel = ({hotelId, payload}: UpdateHotelContext) =>
  request<HotelsUpdateResponse>(url(`hotels/${hotelId}`), {
    method: 'PUT',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsUpdateResponse['data']>([1, 'data']));

type DeleteHotelContext = {
  hotelId: number;
};
const deleteHotel = ({hotelId}: DeleteHotelContext) =>
  request<HotelsDeleteResponse>(url(`/api/hotels/${hotelId}`), {
    method: 'DELETE',
    headers: clientHeaders(),
  });

type UpdateStatusContext = {
  hotelId: number;
  payload: {
    new_status: HotelStatusEnum;
  };
};

const updateStatus = ({hotelId, payload}: UpdateStatusContext) =>
  request(url(`hotels/${hotelId}/update-status`), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsUpdateStatusCreateResponse['data']>([1, 'data']));

type UpdateRatesPublicityContext = {
  hotelId: number;
  payload: {
    public: boolean;
  };
};

const updateRatesPublicity = ({
  hotelId,
  payload,
}: UpdateRatesPublicityContext) =>
  request<HotelsPublicRatesUpdateResponse>(
    url(`hotels/${hotelId}/public-rates`),
    {
      method: 'PUT',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    },
  ).then(path<HotelsPublicRatesUpdateResponse['data']>([1, 'data']));

export const hotelApi = {
  create: createHotel,
  get: getHotel,
  update: updateHotel,
  delete: deleteHotel,
  updateStatus,
  updateRatesPublicity,
};

import {authApi} from 'source/utilities/api/models/auth';
import {bookingApi} from 'source/utilities/api/models/booking';
import {bookingsApi} from 'source/utilities/api/models/bookings';
import {repliesApi} from 'source/utilities/api/models/conversation';
import {directoriesApi} from 'source/utilities/api/models/directories';
import {facilitiesApi} from 'source/utilities/api/models/facilities';
import {hotelApi} from 'source/utilities/api/models/hotel';
import {hotelImagesApi} from 'source/utilities/api/models/hotel-images';
import {hotelsApi} from 'source/utilities/api/models/hotels';
import {integrationApi} from 'source/utilities/api/models/integration';
import {landmarksApi} from 'source/utilities/api/models/landmarks';
import {moderationApi} from 'source/utilities/api/models/moderation';
import {organizationsApi} from 'source/utilities/api/models/organizations';
import {policyApi} from 'source/utilities/api/models/policy';
import {rateApi} from 'source/utilities/api/models/rate';
import {ratesApi} from 'source/utilities/api/models/rates';
import {ratingsApi} from 'source/utilities/api/models/ratings';
import {reviewsApi} from 'source/utilities/api/models/reviews';
import {roomApi} from 'source/utilities/api/models/room';
import {roomsApi} from 'source/utilities/api/models/rooms';
import {socialSubscribe} from 'source/utilities/api/models/social-subscribe';
import {manageCalendarApi} from './models/manage-calendar';
import {roomImagesApi} from './models/room-images';
import {serviceApi} from './models/service';
import {servicesApi} from './models/services';
import {userApi} from './models/user';
import {realtyIntegrationApi} from './models/realty-integration';
import {withdrawalsApi} from './models/withdrawals';
import {balanceApi} from './models/balance';

export const api = {
  hotels: hotelsApi,
  hotel: hotelApi,
  balance: balanceApi,
  bookings: bookingsApi,
  booking: bookingApi,
  facilities: facilitiesApi,
  hotelImages: hotelImagesApi,
  roomImages: roomImagesApi,
  landmarks: landmarksApi,
  organizations: organizationsApi,
  rates: ratesApi,
  rate: rateApi,
  reviews: reviewsApi,
  replies: repliesApi,
  ratings: ratingsApi,
  rooms: roomsApi,
  room: roomApi,
  moderation: moderationApi,
  policy: policyApi,
  directories: directoriesApi,
  auth: authApi,
  manageCalendar: manageCalendarApi,
  services: servicesApi,
  service: serviceApi,
  integration: integrationApi,
  // у реалти способ подключения интеграции отличается от обычного
  realtyIntegration: realtyIntegrationApi,
  user: userApi,
  socialSubscribe,
  withdrawals: withdrawalsApi,
};

import {
  differenceInDays,
  isAfter,
  isEqual,
  isWithinInterval,
  parseISO,
} from 'date-fns';
import {equals, pick, isNil, uniqBy, prop} from 'ramda';
import {DateRangeProps} from 'react-date-range';

import {
  parseBackendDateFormat,
  getBackendDateFormat,
} from 'source/utilities/dates';
import {getLastIndex, toValueOrEmptyArray} from 'source/utilities/array';

import {Option} from 'library/components/select';
import {
  CELL_GAP,
  CELL_WIDTH,
  MIN_CLOSE_ROOMS_NUMBER,
  DEFAULT_RATE_OPTION,
  DEFAULT_GUEST_NUMBER_OPTION,
  MOBILE_SELECT_DATES_INDEXES,
} from '../constants';
import {
  DayDatesType,
  EditRoomsType,
  EAvailabilityReason,
  ClientConstraintPropertiesType,
  ClientAvailableRoomsNumberType,
  SubmitAvailabilityType,
  StoreRatesPricesType,
  RangeSelectCells,
  StoreDateAvailabilitiesType,
  OpenedRoomsType,
} from '../types';
import {buildRangeDates} from './dates';

export const isRoomAvailabilityConstraint = (
  constraint: ClientCalendarRoomConstraint,
): boolean => constraint.type === EAvailabilityReason.ROOM_AVAILABILITY;

export const isRoomAvailabilityEqual = (
  roomConstraint: ClientCalendarRoomConstraint,
  date: Date,
): boolean =>
  isRoomAvailabilityConstraint(roomConstraint) &&
  isEqual(parseBackendDateFormat(roomConstraint.from), date) &&
  isEqual(parseBackendDateFormat(roomConstraint.to), date);

export const createRoomContraintsProperties = (
  constraint: ClientCalendarRoomConstraint,
  dayDates: DayDatesType,
): ClientConstraintPropertiesType => {
  const dateFrom = parseISO(constraint.from);
  const dateTo = parseISO(constraint.to);

  const calendarStartDay = dayDates[0].date;

  const calendarEndDay = dayDates[getLastIndex(dayDates)].date;

  const cellsBefore =
    dayDates.findIndex(({date}) => isEqual(dateFrom, date)) || 0;

  const startDateToCheck = isAfter(calendarStartDay, dateFrom)
    ? calendarStartDay
    : dateFrom;
  const endDateToCheck = isAfter(dateTo, calendarEndDay)
    ? calendarEndDay
    : dateTo;

  const diffInDays = differenceInDays(endDateToCheck, startDateToCheck);

  const constraintWidth = (CELL_WIDTH + CELL_GAP) * (diffInDays + 1) - CELL_GAP;
  const cellsAfter = getLastIndex(dayDates) - (diffInDays + cellsBefore);
  const beforeWidth = (CELL_WIDTH + CELL_GAP) * cellsBefore;
  const afterWidth = (CELL_WIDTH + CELL_GAP) * cellsAfter;

  return {beforeWidth, width: constraintWidth, afterWidth};
};

export const getCurrentDateAvailability = (
  availableDates: CalendarDateAvailabilities,
  formattedDate: string,
): CalendarDateAvailabilities[number] | undefined =>
  availableDates.find((availableDate) => availableDate.date === formattedDate);

export const buildAvailableRoomsNumber = (
  constraints: ClientCalendarRoomConstraints,
  availableDates: CalendarDateAvailabilities,
  dayDate: Date,
  customRoomsAvailability = -1,
): ClientAvailableRoomsNumberType => {
  const formattedDate = getBackendDateFormat(dayDate);
  const currentDateAvailability = getCurrentDateAvailability(
    availableDates,
    formattedDate,
  );
  // Общее кол-во - брони
  const leftRoomsNumber = currentDateAvailability?.left_rooms_number || 0;
  // Общее кол-во - брони - закрытия
  const customRoomsNumber = currentDateAvailability?.custom_rooms_number;
  // Текущий счётчик номеров включая закрытия
  let availableRoomsNumber = customRoomsNumber ?? leftRoomsNumber;
  // Максимальное значение, которое можно передать в room_availabilities
  let maxAvailableRoomsAvailabilities = leftRoomsNumber;
  let isChanged = false;

  // Обход по массиву закрытий бронирований и вычет/сложение значений
  // Важно: room_availability это constraint с type === 5, где rooms_closed_number - колво свободных номеров
  // eslint-disable-next-line no-restricted-syntax
  for (const constraint of constraints) {
    const closedRoomsNumber = constraint.rooms_closed_number;
    if (
      isWithinInterval(dayDate, {
        start: parseISO(constraint.from),
        end: parseISO(constraint.to),
      }) &&
      !isRoomAvailabilityConstraint(constraint)
    ) {
      // При удалении
      if (constraint.is_delete) {
        const releasedRoomsCount = isNil(closedRoomsNumber)
          ? leftRoomsNumber
          : closedRoomsNumber;
        availableRoomsNumber += releasedRoomsCount;
        isChanged = true;
      }

      // При добавлении обычных закрытий (не 5 типа) вычитываются только те, у которых нет id
      // потому что date_availabilities с бэка приходят уже с учетом закрытий
      // а нам нужно хранить локальные закрытия и подсчитывать их до нажатия на кнопку сохранить
      if (!constraint.is_delete) {
        if (isNil(closedRoomsNumber)) {
          maxAvailableRoomsAvailabilities = 0;
          availableRoomsNumber = 0;
        }
        if (!isNil(closedRoomsNumber)) {
          maxAvailableRoomsAvailabilities -= closedRoomsNumber;
          availableRoomsNumber = constraint.id
            ? availableRoomsNumber
            : availableRoomsNumber - closedRoomsNumber;
        }
        isChanged = true;
      }
    }
  }

  return {
    availableRoomsNumber,
    isChanged,
    hasFree: currentDateAvailability?.has_free,
    maxAvailableRoomsAvailabilities,
    computedRoomsAvailability: customRoomsAvailability,
  };
};

export const buildCustomRoomAvailabilityCount = (
  constraints: ClientCalendarRoomConstraints,
  dayDate: Date,
): number => {
  // проверяет есть ли на данную дату constraint с типом 5 и возвращает число
  let customRoomAvailabilities = -1;
  // eslint-disable-next-line no-restricted-syntax
  for (const constraint of constraints) {
    const closedRoomsNumber = constraint.rooms_closed_number ?? 0;
    if (isRoomAvailabilityEqual(constraint, dayDate)) {
      customRoomAvailabilities = closedRoomsNumber;
    }
  }

  return customRoomAvailabilities;
};

export const isConstraintEquals = (
  constraint: ClientCalendarRoomConstraints[number],
  compareConstraint: ClientCalendarRoomConstraints[number],
): boolean => {
  return equals(
    pick(['from', 'to', 'type', 'rooms_closed_number'], constraint),
    pick(['from', 'to', 'type', 'rooms_closed_number'], compareConstraint),
  );
};

export const buildNewNotAvailableDates = (
  previousNotAvailableDates: string[],
  daysDates: DayDatesType,
  roomConstraints: ClientCalendarRoomConstraints,
  availableDates: CalendarDateAvailabilities,
): string[] => {
  let outPutNotAvailableDates = previousNotAvailableDates;

  // eslint-disable-next-line no-restricted-syntax
  for (const item of daysDates) {
    const {availableRoomsNumber} = buildAvailableRoomsNumber(
      roomConstraints,
      availableDates,
      item.date,
    );

    const formattedDate = getBackendDateFormat(item.date);

    outPutNotAvailableDates =
      availableRoomsNumber > 0
        ? outPutNotAvailableDates.filter(
            (previousItem: string) => formattedDate !== previousItem,
          )
        : [...new Set([...outPutNotAvailableDates, formattedDate])];
  }

  return outPutNotAvailableDates;
};

export const buildMaxAvailableRoomsNumber = (
  ranges: DateRangeProps['ranges'],
  availableDates: CalendarDateAvailabilities,
  roomConstraints: ClientCalendarRoomConstraints,
): number => {
  const currentRange = ranges && ranges[0];

  if (currentRange && currentRange.startDate && currentRange.endDate) {
    const rangeDateArray = buildRangeDates(
      currentRange.startDate,
      currentRange.endDate,
    );

    const filteredAvailableDates = availableDates.filter((availableDate) =>
      rangeDateArray.includes(availableDate.date),
    );

    const roomsNumberArray = filteredAvailableDates
      .map((availableDate) => {
        const {availableRoomsNumber} = buildAvailableRoomsNumber(
          roomConstraints,
          availableDates,
          parseBackendDateFormat(availableDate.date),
        );

        return availableRoomsNumber;
      })
      .filter((value) => value > 0);

    return roomsNumberArray.length > 0
      ? Math.max(...roomsNumberArray)
      : MIN_CLOSE_ROOMS_NUMBER;
  }

  return MIN_CLOSE_ROOMS_NUMBER;
};

export const deleteRoomFromEdited = (
  editRooms: EditRoomsType,
  id: number | string,
): EditRoomsType => {
  const updatedEditRooms = {...editRooms};
  delete updatedEditRooms[id.toString()];

  return {...updatedEditRooms};
};

export const filterConstraintsById = (
  constraints: ClientCalendarRoomConstraints,
): ClientCalendarRoomConstraint[] => uniqBy(prop('id'), constraints);

export const getCurrentOptionByValue = (
  options: Option[],
  value: number,
): Option =>
  options.find(({value: optionValue}) => optionValue === value) || options[0];

export const buildRoomOptions = (roomShorts: HotelRoomsShorts): Option[] =>
  roomShorts
    .filter((room) => {
      const hasAnyRates = room.rates?.length > 0;
      const isAnyRateEnabled =
        hasAnyRates && room.rates?.some((rate) => rate.enabled);

      // номер открыт, есть хотя бы один действующий тариф
      return isAnyRateEnabled;
    })
    .map((room) => ({value: room.id, label: room.name}));

export const buildRateOptions = (
  ratePrices?: CalendarRatesPrices,
): Option[] => {
  if (!ratePrices) {
    return [DEFAULT_RATE_OPTION];
  }

  return ratePrices
    .filter((ratePriceItem) => ratePriceItem.rate.enabled)
    .map((ratePriceItem) => {
      const {name, id} = ratePriceItem.rate;

      return {
        label: name,
        value: id,
      };
    });
};

export const buildGuestNumberOptions = (
  rateId: number,
  ratePrices?: CalendarRatesPrices,
): Option[] => {
  if (!ratePrices) {
    return [DEFAULT_GUEST_NUMBER_OPTION];
  }

  const currentRatePrice = ratePrices.find(
    (ratePrice) => ratePrice.rate.id === rateId,
  );

  if (currentRatePrice) {
    return currentRatePrice.by_guests_number.map((guestNumber) => ({
      label: String(guestNumber.guests_number),
      value: guestNumber.guests_number,
    }));
  }

  return [DEFAULT_GUEST_NUMBER_OPTION];
};

export const buildNewRoomConstraintsOnRoomsNumberChange = (
  roomConstraints: ClientCalendarRoomConstraints,
  value: number,
  item: DayDatesType[number],
) => {
  const isRoomAvailabilityConstraintExists = roomConstraints?.some(
    (previousItem) => isRoomAvailabilityEqual(previousItem, item.date),
  );

  if (isRoomAvailabilityConstraintExists) {
    return roomConstraints.map((previousItem) => {
      if (isRoomAvailabilityEqual(previousItem, item.date)) {
        return {
          ...previousItem,
          rooms_closed_number: value,
        };
      }

      return previousItem;
    });
  }

  const submitAvailability: SubmitAvailabilityType = {
    from: getBackendDateFormat(item.date),
    to: getBackendDateFormat(item.date),
    rooms_closed_number: value,
    type: EAvailabilityReason.ROOM_AVAILABILITY,
    comment: '',
    is_delete: false,
  };

  return [...roomConstraints, submitAvailability];
};

export const buildRangeSelectCellsFromOpenedRoomWithPrices = (
  openedRoomsEntries: [string, boolean][],
  ratesPrices: StoreRatesPricesType,
): RangeSelectCells | null => {
  return openedRoomsEntries.reduce<RangeSelectCells | null>(
    (accumulator, [roomId]) => {
      const currentRoomRatesPrices = toValueOrEmptyArray(
        ratesPrices?.[roomId]?.ratesPrices,
      );

      if (currentRoomRatesPrices.length === 0) {
        return accumulator;
      }

      const appropriateRatePrice = currentRoomRatesPrices.find(
        (roomRatePrice) => {
          const hasPrices = roomRatePrice.by_guests_number[0].prices.length > 0;
          return roomRatePrice.rate.enabled && hasPrices;
        },
      );

      const {from, to} = MOBILE_SELECT_DATES_INDEXES;

      if (appropriateRatePrice && !accumulator) {
        const firstFindGuestNumber = appropriateRatePrice.by_guests_number[0];
        return {
          rateId: appropriateRatePrice.rate.id,
          guestNumber: firstFindGuestNumber.guests_number,
          prices: firstFindGuestNumber.prices.slice(from, to),
          room: +roomId,
        };
      }

      return accumulator;
    },
    null,
  );
};

export const checkHasAnyRoomEnabledRates = (
  roomShorts: HotelRoomsShorts,
): boolean => {
  const hasEnabledRate = roomShorts.some(
    (room) => room.rates?.some((rate) => rate.enabled),
  );

  return hasEnabledRate;
};

export const parseHotelRoomsToDateAvailabilities = (
  hotelRooms: HotelRooms,
  openedRooms: OpenedRoomsType,
): StoreDateAvailabilitiesType => {
  // Создаем сет из открытых roomId
  const openedRoomsArray = new Set(
    Object.entries(openedRooms)
      .filter(([, isOpened]) => isOpened)
      .map(([roomId]) => Number(roomId)),
  );

  // Возвращаем объект date-avialabilties для стора
  return hotelRooms
    .filter((hotelRoom) => !openedRoomsArray.has(hotelRoom.room.id))
    .reduce<StoreDateAvailabilitiesType>((accumulator, hotelRoom) => {
      return {...accumulator, [hotelRoom.room.id]: hotelRoom.availabilities};
    }, {});
};

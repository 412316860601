import type {ComponentProps} from 'react';

const getControlStatus = (
  value: string,
  required: ComponentProps<'input'>['required'],
  pattern: ComponentProps<'input'>['pattern'],
  shouldEqualValue?: string,
): ControlStatus => {
  if (required && value === '') {
    return 'required';
  }

  if (pattern && !new RegExp(pattern).test(value)) {
    return 'unformatted';
  }

  if (shouldEqualValue && shouldEqualValue !== value) {
    return 'not_equal';
  }

  if (value === '') {
    return 'empty';
  }

  return 'touched';
};

export const getInputStatus = (
  rendered: boolean,
  isChange: boolean,
  force: ControlStatus | undefined,
  value: string,
  required: ComponentProps<'input'>['required'],
  pattern: ComponentProps<'input'>['pattern'],
  shouldEqualValue?: string,
) => {
  if (!rendered || !isChange) {
    return force || 'pristine';
  }

  return getControlStatus(value, required, pattern, shouldEqualValue);
};

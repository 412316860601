import styled from '@emotion/styled';
import {isNil} from 'ramda';
import {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';
import {
  selectSelectedOrganization,
  setSelectedOrganization,
} from 'slices/balance/store';
import {BalanceWithdrawalContent} from 'slices/balance/ui/balance-withdrawal/modal';
import {useAppDispatch, useAppSelector} from 'source/store';

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledPopup = styled(Popup)`
  &-content {
    padding: 20px;
    width: fit-content;
    height: fit-content;
    border-radius: 6px;

    @media (max-width: 850px) {
      max-width: 95vw;
    }
  }
`;

export const BalanceWithdrawal = () => {
  const selectedOrganization = useAppSelector(selectSelectedOrganization);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isOrganizationSelected = !isNil(selectedOrganization);

    setIsOpen(isOrganizationSelected);
  }, [selectedOrganization]);

  return (
    <Wrapper>
      <StyledPopup
        modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          dispatch(setSelectedOrganization(null));
        }}
        closeOnEscape
        closeOnDocumentClick
        lockScroll
      >
        <BalanceWithdrawalContent
          onClose={() => {
            setIsOpen(false);
            dispatch(setSelectedOrganization(null));
          }}
        />
      </StyledPopup>
    </Wrapper>
  );
};

import styled from '@emotion/styled';
import {NoContent} from 'library/components/no-content';
import useTranslation from 'next-translate/useTranslation';
import {isEmpty} from 'ramda';
import {selectBooking} from 'slices/booking/store';
import CardInformation from 'slices/booking/ui/card-information';
import HeaderInformation from 'slices/booking/ui/header-information';
import {useAppSelector} from 'source/store';
import {Routes} from 'source/utilities/network/url';

const Wrapper = styled.div``;

const Content = () => {
  const {t} = useTranslation('booking');
  const {booking} = useAppSelector(selectBooking);
  if (isEmpty(booking) || !booking) {
    return (
      <NoContent
        buttonLabel={t('no_content.buttonLabel')}
        subtitle={t('no_content.subtitle')}
        href={Routes.bookings}
      />
    );
  }

  return (
    <Wrapper>
      <HeaderInformation booking={booking} />
      <CardInformation booking={booking} />
      {/* TODO - коммент оставлен 13.09.2023 (удалить если дата просмотра позже 13.11.2023) */}
      {/* <FooterInformation booking={booking} /> */}
    </Wrapper>
  );
};

export default Content;

import { found } from '@jridgewell/trace-mapping/dist/types/binary-search'
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import {Option} from 'library/components/select';
import {assoc} from 'ramda';
import { DEFAULT_DESCRIPTION_VALUE, initialPoliciesState } from 'slices/policies/lib/mocks'
import {PoliciesDirectories, RequiredTabsType} from 'slices/policies/lib/types';
import {RootState} from 'source/store';
import {removeNil} from 'source/utilities/object';

interface IPoliciesState {
  policies: Policies;
  directories: PoliciesDirectories;
  loadingSaving: boolean;
  isNeedCreatePolicies: boolean;
  isNeedSaving: boolean;
  tabsOpened: RequiredTabsType[];
  isNeedCheckTabs: boolean;
  isCheckPassed: boolean;
  isInitialized: boolean;
}

const initialState: IPoliciesState = {
  policies: {...initialPoliciesState},
  directories: {
    paymentsMethods: [],
    petsAllowedType: [],
  },
  loadingSaving: false,
  isNeedCreatePolicies: false,
  isNeedSaving: false,
  tabsOpened: [],
  isNeedCheckTabs: false,
  isCheckPassed: false,
  isInitialized: false,
};

export const policiesSlice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setPolicies: (state, action: PayloadAction<IPoliciesState['policies']>) => {
      const policies = action.payload;

      state.policies = {
        ...initialPoliciesState,
        ...removeNil(policies),
      };
    },
    setDirectories: (state, action: PayloadAction<PoliciesDirectories>) => {
      state.directories = action.payload;
    },
    setPoliciesTaxes: (
      state,
      action: PayloadAction<Policies['taxes_and_fees']>,
    ) => {
      state.policies.taxes_and_fees = action.payload;
    },
    setPaymentsMethods: (
      state,
      action: PayloadAction<Policies['payment_methods']>,
    ) => {
      state.policies.payment_methods = action.payload;
    },
    setPlacementTime: (
      state,
      action: PayloadAction<{
        key: string;
        value: string;
      }>,
    ) => {
      const {key, value} = action.payload;
      const {placement_time} = state.policies;

      if (placement_time) {
        const newPlacementTime = assoc(
          key as keyof Policies['placement_time'],
          value,
        );

        state.policies.placement_time = newPlacementTime(placement_time);
      }
    },
    setPlacementDescription: (
      state,
      action: PayloadAction<{value: string; locale: string}>,
    ) => {
      const {value, locale} = action.payload;
      const {placement_time} = state.policies;
      const foundDescription = placement_time?.description?.find(
        (description) => description.locale === locale,
      );
      
      if (foundDescription) {
        foundDescription.value = value;
      } else {
        placement_time?.description?.push({
          value,
          locale
        })
      }
    },
    setPlacementDescriptions: (
      state,
      action: PayloadAction<
        NonNullable<IPoliciesState['policies']['placement_time']>['description']
      >,
    ) => {
      if (state.policies.placement_time) {
        state.policies.placement_time.description = action.payload;
      }
    },
    setConfirmationInvoice: (
      state,
      action: PayloadAction<Policies['confirmation_invoice']>,
    ) => {
      state.policies.confirmation_invoice = action.payload;
    },
    clearPlacementDescription: (state) => {
      const {placement_time} = state.policies;
      if (placement_time) {
        placement_time.description =
          initialPoliciesState.placement_time?.description;
      }
    },
    setPetsDescription: (
      state,
      action: PayloadAction<{value: string; locale: string}>,
    ) => {
      const {value, locale} = action.payload;
      const {pets_allowed} = state.policies;
      const foundDescription = pets_allowed?.description?.find(
        (description) => description.locale === locale,
      );
      if (foundDescription) {
        foundDescription.value = value;
      }
    },
    setPetsDescriptions: (
      state,
      action: PayloadAction<
        NonNullable<IPoliciesState['policies']['pets_allowed']>['description']
      >,
    ) => {
      if (state.policies.pets_allowed) {
        state.policies.pets_allowed.description = action.payload;
      }
    },
    clearPetsDescription: (state) => {
      const {pets_allowed} = state.policies;
      if (pets_allowed) {
        pets_allowed.description =
          initialPoliciesState.pets_allowed?.description;
      }
    },
    setPetsType: (state, action: PayloadAction<Option>) => {
      const petsAllowed = state.policies.pets_allowed;
      if (petsAllowed) {
        petsAllowed.type = {
          value: action.payload.value,
          title: action.payload.label,
        };
      }
    },
    setPetsAmount: (state, action: PayloadAction<number>) => {
      const petsAllowed = state.policies.pets_allowed;
      if (petsAllowed) {
        petsAllowed.amount = action.payload;
      }
    },
    setDepositPaymentType: (state, action: PayloadAction<Option>) => {
      const {security_deposit} = state.policies;
      if (security_deposit) {
        security_deposit.type = {
          value: action.payload.value,
          title: action.payload.label,
        };
      }
    },
    setDepositPaymentAmount: (state, action: PayloadAction<number>) => {
      const {security_deposit} = state.policies;
      if (security_deposit) {
        security_deposit.amount = action.payload;
      }
    },
    setRegistrationFeeForeignCitizens: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.policies.registration_fee_foreign_citizens = action.payload;
    },
    setLoadingSaving: (state, action: PayloadAction<boolean>) => {
      state.loadingSaving = action.payload;
    },
    setIsNeedCreatePolicies: (state, action: PayloadAction<boolean>) => {
      state.isNeedCreatePolicies = action.payload;
    },
    setIsNeedSaving: (state, action: PayloadAction<boolean>) => {
      state.isNeedSaving = action.payload;
    },
    setTabsOpened: (state, action: PayloadAction<RequiredTabsType[]>) => {
      state.tabsOpened = action.payload;
    },
    setIsNeedCheckTabs: (state, action: PayloadAction<boolean>) => {
      state.isNeedCheckTabs = action.payload;
    },
    setIsCheckPassed: (state, action: PayloadAction<boolean>) => {
      state.isCheckPassed = action.payload;
    },
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
  },
});

export const selectPolicies = (state: RootState) => {
  return {
    policies: state.policies.policies,
    directories: state.policies.directories,
    loadingSaving: state.policies.loadingSaving,
    isNeedCreatePolicies: state.policies.isNeedCreatePolicies,
    isNeedSaving: state.policies.isNeedSaving,
    tabsOpened: state.policies.tabsOpened,
    isNeedCheckTabs: state.policies.isNeedCheckTabs,
    isCheckPassed: state.policies.isCheckPassed,
    isInitialized: state.policies.isInitialized,
  };
};

export const {
  reset,
  setPolicies,
  setPoliciesTaxes,
  setDirectories,
  setPaymentsMethods,
  setPlacementTime,
  clearPlacementDescription,
  setPlacementDescription,
  setPlacementDescriptions,
  setPetsDescription,
  setPetsDescriptions,
  clearPetsDescription,
  setPetsType,
  setLoadingSaving,
  setPetsAmount,
  setDepositPaymentAmount,
  setDepositPaymentType,
  setRegistrationFeeForeignCitizens,
  setIsNeedCreatePolicies,
  setIsNeedSaving,
  setTabsOpened,
  setIsNeedCheckTabs,
  setIsCheckPassed,
  setConfirmationInvoice,
  setIsInitialized,
} = policiesSlice.actions;

export default policiesSlice.reducer;

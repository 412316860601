import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {HorizontalDivier} from 'library/components/divider';
import {LabeledComponent} from 'library/components/labeled-component';
import {Select} from 'library/components/select';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC, useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'source/store';
import {getCountOption, getCountOptions} from 'source/utilities/object';

import {
  getBedOption,
  getBedTypesOptions,
  getLabelTranslate,
  getTranslate,
} from '../lib/helpers';
import {BedType, BedTypeOptions} from '../lib/types';
import {selectBeds, setBeds, setBedType} from '../store';

const Wrapper = styled.div``;

const AlternativeWrapper = styled.div`
  margin-top: 20px;
`;

const StyledSelect = styled(Select)`
  @media (max-width: 900px) {
    max-width: 440px;
  }
`;

const Title = styled(Text)`
  letter-spacing: 0.03em;
  margin-bottom: 21px;
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const AllotmentWrapper = styled.div`
  margin-top: 13px;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;
  }
`;

const InputsWrapper = styled.div<{addMargin?: boolean}>`
  display: flex;
  margin-bottom: ${({addMargin}) => addMargin && '20px'};
  & > div {
    width: 440px;
    margin-bottom: 21px;
  }
  column-gap: 25px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  @media (max-width: 480px) {
    & > div {
      width: 100%;
    }
  }
`;

const BedsWrapper = styled.div`
  & > div {
    margin-bottom: 21px;
  }
`;

const AlternativeText = styled(Text)`
  cursor: pointer;
  color: ${({theme}) => theme.palette.primaryAccent};
`;

const Allotment = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
`;

interface RoomProps {
  index: number;
  bedTypes?: BedTypes;
  translateKey: string;
}
export const Room: FC<RoomProps> = ({index, bedTypes, translateKey}) => {
  const bedTypesOptions: BedTypeOptions = useMemo(
    () => getBedTypesOptions(bedTypes),
    [bedTypes],
  );

  const dispatch = useAppDispatch();
  const {beds, addBeds, altBeds, altAddBeds} = useAppSelector((state) =>
    selectBeds(state, index),
  );

  const bedsCount = beds.length;
  const addBedsCount = addBeds.length;
  const altBedsCount = altBeds.length;
  const altAddBedsCount = altAddBeds.length;

  const bedsOptions = getCountOptions(1, 20);
  const addBedsOptions = getCountOptions(0, 20);
  const [hasAlternativeSet, setHasAlternativeSet] = useState(false);
  const theme = useTheme();
  const {t} = useTranslation(translateKey);

  const setBedsHandler = (bedsNumber: number, bedType: BedType) => {
    dispatch(setBeds({roomIndex: index, bedsNumber, bedType}));
  };

  const setBedTypeHandler = (
    bedIndex: number,
    type: number,
    bedType: BedType,
  ) => {
    dispatch(
      setBedType({
        roomIndex: index,
        bedIndex,
        type,
        bedType,
      }),
    );
  };

  useEffect(() => {
    if (!hasAlternativeSet && altBedsCount > 0) {
      setHasAlternativeSet(true);
    }
  }, [altBedsCount, hasAlternativeSet]);

  return (
    <Wrapper>
      <Title size="boldS">
        {getTranslate(t, 'room_title', {ordinal: index + 1})}
      </Title>
      <InputsWrapper>
        <LabeledComponent
          color={theme.palette.fontSecondary}
          size="S"
          gap={10}
          text={getLabelTranslate(t, 'beds')}
        >
          <StyledSelect
            value={getCountOption(bedsCount)}
            options={bedsOptions}
            setValue={(option) => {
              setBedsHandler(option.value, 'default');
            }}
          />
        </LabeledComponent>
        <LabeledComponent
          color={theme.palette.fontSecondary}
          size="S"
          gap={10}
          text={getLabelTranslate(t, 'add_beds')}
        >
          <StyledSelect
            value={getCountOption(addBedsCount)}
            options={addBedsOptions}
            setValue={(option) => {
              setBedsHandler(option.value, 'additional');
            }}
          />
        </LabeledComponent>
      </InputsWrapper>
      <BedsWrapper>
        {beds.map((bed, index_) => (
          <LabeledComponent
            key={`room-${index}-bed-${index_ + 1}`}
            color={theme.palette.fontSecondary}
            size="S"
            gap={10}
            text={getTranslate(t, 'bed_title', {ordinal: index_ + 1})}
          >
            <StyledSelect
              value={getBedOption(bed.directory_bed_type_id, bedTypesOptions)}
              options={bedTypesOptions}
              setValue={(option) => {
                setBedTypeHandler(index_, option.value, 'default');
              }}
            />
          </LabeledComponent>
        ))}
        {addBeds.map((bed, index_) => (
          <LabeledComponent
            key={`room-${index}-add-bed-${index_ + 1}`}
            color={theme.palette.fontSecondary}
            size="S"
            gap={10}
            text={getTranslate(t, 'add_bed_title', {ordinal: index_ + 1})}
          >
            <StyledSelect
              value={getBedOption(bed.directory_bed_type_id, bedTypesOptions)}
              options={bedTypesOptions}
              setValue={(option) => {
                setBedTypeHandler(index_, option.value, 'additional');
              }}
            />
          </LabeledComponent>
        ))}
      </BedsWrapper>
      <HorizontalDivier />
      <AllotmentWrapper>
        {/* Временно скрыто 01.11.2023
        <Allotment size="S">
          {getTranslate(t, 'allotment', {
            count: bedsCount + addBedsCount,
          })}
        </Allotment> */}
        {hasAlternativeSet ? null : (
          <AlternativeText
            onClick={() => {
              setHasAlternativeSet(true);
              setBedsHandler(1, 'alternative');
            }}
            size="S"
          >
            {getTranslate(t, 'add_beds_set')}
          </AlternativeText>
        )}
      </AllotmentWrapper>
      {hasAlternativeSet ? (
        <AlternativeWrapper>
          <Title size="boldS">{getTranslate(t, 'alternative_set_title')}</Title>
          <InputsWrapper>
            <LabeledComponent
              color={theme.palette.fontSecondary}
              size="S"
              gap={10}
              text={getLabelTranslate(t, 'beds')}
            >
              <StyledSelect
                value={getCountOption(altBedsCount)}
                options={bedsOptions}
                setValue={(option) => {
                  setBedsHandler(option.value, 'alternative');
                }}
              />
            </LabeledComponent>

            <LabeledComponent
              color={theme.palette.fontSecondary}
              size="S"
              gap={10}
              text={getLabelTranslate(t, 'add_beds')}
            >
              <StyledSelect
                value={getCountOption(altAddBedsCount)}
                options={addBedsOptions}
                setValue={(option) => {
                  setBedsHandler(option.value, 'additionalAlternative');
                }}
              />
            </LabeledComponent>
          </InputsWrapper>
          <BedsWrapper>
            {altBeds.map((bed, index_) => (
              <LabeledComponent
                key={`room-${index}-bed-${index_ + 1}`}
                color={theme.palette.fontSecondary}
                size="S"
                gap={10}
                text={getTranslate(t, 'bed_title', {ordinal: index_ + 1})}
              >
                <StyledSelect
                  value={getBedOption(
                    bed.directory_bed_type_id,
                    bedTypesOptions,
                  )}
                  options={bedTypesOptions}
                  setValue={(option) => {
                    setBedTypeHandler(index_, option.value, 'alternative');
                  }}
                />
              </LabeledComponent>
            ))}
            {altAddBeds.map((bed, index_) => (
              <LabeledComponent
                key={`room-${index}-add-bed-${index_ + 1}`}
                color={theme.palette.fontSecondary}
                size="S"
                gap={10}
                text={getTranslate(t, 'add_bed_title', {ordinal: index_ + 1})}
              >
                <StyledSelect
                  value={getBedOption(
                    bed.directory_bed_type_id,
                    bedTypesOptions,
                  )}
                  options={bedTypesOptions}
                  setValue={(option) => {
                    setBedTypeHandler(
                      index_,
                      option.value,
                      'additionalAlternative',
                    );
                  }}
                />
              </LabeledComponent>
            ))}
          </BedsWrapper>
          <HorizontalDivier />
          <AllotmentWrapper>
            {/* Временно скрыто 01.11.2023
            <Allotment size="S">
              {getTranslate(t, 'allotment', {
                count: altBedsCount + altAddBedsCount,
              })}
            </Allotment> */}
            <AlternativeText
              onClick={() => {
                setHasAlternativeSet(false);
                setBedsHandler(0, 'alternative');
                setBedsHandler(0, 'additionalAlternative');
              }}
              size="S"
            >
              {getTranslate(t, 'remove_beds_set')}
            </AlternativeText>
          </AllotmentWrapper>
        </AlternativeWrapper>
      ) : null}
    </Wrapper>
  );
};

import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Headline} from 'library/components/headline';
import {CircularLoader} from 'library/components/loader';
import {Text} from 'library/components/text';
import {BinIcon} from 'library/icons/bin-icon';
import useTranslation from 'next-translate/useTranslation';
import {isEmpty} from 'ramda';
import {useEffect, useRef, useState} from 'react';
import {selectNotificationChannels} from 'slices/profile/store';
import {NOTIFICATION_CHANNEL_ICONS} from 'slices/profile/ui/notification-channels/lib/constants';
import {
  handleDeleteNotificationChannel,
  pollingUpdateSubscribeChannels,
} from 'slices/profile/ui/notification-channels/network';
import {Icon} from 'source/components/icon';
import {useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import CLongPolling from 'source/utilities/long-polling';

const Wrapper = styled.div`
  width: 100%;
  margin: 0px 0px 54px 0px;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 21px;
`;

const InformationWrapper = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid ${({theme}) => theme.palette.activeBackground};
  padding: 19px 0px;

  &:last-child {
    border-bottom: 1px solid ${({theme}) => theme.palette.activeBackground};
  }
`;

const InformationIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InformationIcon = styled(Icon)`
  margin-right: 8px;
`;

const DeleteChannelButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.palette.border};
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.palette.fontPrimary};
    path {
      fill: ${({theme}) => theme.palette.defaultBackground};
    }
  }
`;

const DeleteIcon = styled(BinIcon)`
  width: 100%;
  height: 100%;
`;

const NotificationChannels = () => {
  const pollingNotificationChannelsRef = useRef<CLongPolling | null>(null);
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation('profile');
  const [isLoadingChannel, setIsLoadingChannel] = useState(false);
  const notificationChannels = useAppSelector(selectNotificationChannels);

  const textSize = mobile ? 'XS' : 'S';

  const createNotificationChannelsPolling = () => {
    const userPolling = pollingNotificationChannelsRef.current;

    if (!userPolling) {
      pollingNotificationChannelsRef.current = pollingUpdateSubscribeChannels();
      pollingNotificationChannelsRef.current?.start();
    }
  };

  const removeNotificationChannelsPolling = () => {
    const userPolling = pollingNotificationChannelsRef.current;

    if (userPolling) {
      userPolling.stop();
      userPolling.subscribe?.unsubscribe();
      pollingNotificationChannelsRef.current = null;
    }
  };

  useEffect(() => {
    createNotificationChannelsPolling();

    return removeNotificationChannelsPolling;
  }, []);

  if (!notificationChannels || isEmpty(notificationChannels)) {
    return null;
  }

  return (
    <Wrapper>
      <StyledHeadline level="M">
        {t('notification_channels.title')}
      </StyledHeadline>
      {notificationChannels.map((channel) => (
        <InformationWrapper key={channel.id}>
          <InformationIconWrapper>
            <InformationIcon
              name={NOTIFICATION_CHANNEL_ICONS[channel.channel_type]}
              width={27}
              height={27}
            />
            <Text size={textSize}>{channel.channel_name}</Text>
          </InformationIconWrapper>
          {isLoadingChannel ? (
            <CircularLoader size={28} />
          ) : (
            <DeleteChannelButton
              buttonType="secondary"
              onClick={() =>
                handleDeleteNotificationChannel(channel.id, setIsLoadingChannel)
              }
            >
              <DeleteIcon />
            </DeleteChannelButton>
          )}
        </InformationWrapper>
      ))}
    </Wrapper>
  );
};

export default NotificationChannels;

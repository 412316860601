import styled from '@emotion/styled';
import {CircularLoader} from 'library/components/loader';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';

const Wrapper = styled.div`
  margin: 15px 0px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UploadedText = styled(Text)`
  span:last-child {
    color: ${({theme}) => theme.palette.primaryAccent};
    font-weight: 500;
  }
`;

interface Props {
  isUploadStarted: boolean;
  isUploadInProgress: boolean;
  uploadStartedCount: number;
  completedCount: number;
  erroredCount: number;
}

const UploadProgress: FC<Props> = ({
  isUploadStarted,
  isUploadInProgress,
  uploadStartedCount,
  completedCount,
  erroredCount,
}) => {
  const {t} = useTranslation('photos');

  if (erroredCount) {
    return (
      <Wrapper>
        <UploadedText size="boldL">
          <span>{t('files_uploaded')}</span>{' '}
          <span>
            {t('files_upload_count', {completedCount, uploadStartedCount})}
          </span>
        </UploadedText>
      </Wrapper>
    );
  }

  if (isUploadInProgress) {
    return (
      <Wrapper>
        <LoaderWrapper>
          <CircularLoader />
          <Text size="boldL">
            {t('files_loading_count', {completedCount, uploadStartedCount})}
          </Text>
        </LoaderWrapper>
      </Wrapper>
    );
  }

  if (isUploadStarted && !isUploadInProgress) {
    return (
      <Wrapper>
        <UploadedText size="boldL">
          <span>{t('files_uploaded')}</span>{' '}
          <span>
            {t('files_upload_count', {completedCount, uploadStartedCount})}
          </span>
        </UploadedText>
      </Wrapper>
    );
  }

  return null;
};

export default UploadProgress;

import {createContext, FC, useEffect, useState} from 'react';

import {isMobile, isTablet} from 'source/utilities/guards/device-detection';

export type Utilities = {
  mobile: boolean;
  tablet: boolean;
  windowWidth: number;
};

export type UtilitiesState = State<Utilities>;

const initial = {
  mobile: false,
  tablet: false,
  windowWidth: 0,
};

export const UtilitiesContext = createContext<UtilitiesState>([
  initial,
  () => {},
]);

export const UtilitiesProvider: FC<ProviderProperties<UtilitiesState>> = ({
  serverValue,
  ...properties
}) => {
  const [state, setState] = useState<ContextValue<UtilitiesState>>(
    serverValue ?? initial,
  );

  useEffect(() => {
    const handleResize = (event?: globalThis.UIEvent) =>
      setState((previous) => ({
        ...previous,
        mobile: isMobile(window.navigator.userAgent),
        tablet: isTablet(window.navigator.userAgent),
        windowWidth: window.innerWidth,
      }));

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <UtilitiesContext.Provider value={[state, setState]} {...properties} />
  );
};

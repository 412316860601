import {GetServerSideProps} from 'next';
import {andThen, assoc, call, path, pipeWith} from 'ramda';
import {FormEvent} from 'react';
import {setRequisites, setUploadingRequisites} from 'slices/requisites/store';
import {store} from 'source/store';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import CLongPolling from 'source/utilities/long-polling';
import {clientHeaders, serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {OrganizationsListResponse} from 'types/api-scheme';
import {mapRequisites} from './lib/helpers';
import {RequisitesPageProperties} from './lib/type';

export const getOrganizationInvoice = async (
  hotelId: number | string,
): Promise<Blob> =>
  api.organizations.getInvoice({hotelId: transformToNumber(hotelId)});

export const searchExistingRequisites = (query: string, page?: number) =>
  api.organizations.getOrganizations({query, page, headers: clientHeaders()});

export const getRequisitesPageProperties: GetServerSideProps<
  RequisitesPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({
      hotelId,
      headers,
    });

  const requestRequisites = () =>
    api.organizations.getRequisites({
      hotelId,
      headers,
    });

  const requestExistingRequisites = () =>
    api.organizations
      .getOrganizations({
        query: '',
        headers,
      })
      .then(path<OrganizationsListResponse['data']>([1, 'data']));

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, requisites, existingRequisites] = await Promise.all([
            requestHotel(),
            requestRequisites(),
            requestExistingRequisites(),
          ]);

          if (!requisites) {
            const requisites =
              await api.organizations.getVerificationRequisites({
                hotelId,
                headers,
              });

            const result = removeUndefined({
              ...properties,
              hotel,
              requisites,
              existingRequisites,
            });

            return assoc('props', result, {});
          }

          const result = removeUndefined({
            ...properties,
            hotel,
            requisites,
            existingRequisites,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const handleCreateRequisites =
  (hotel: Hotel, formdata: Requisites, onComplete: (file: Blob) => void) =>
  (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.dispatch(setUploadingRequisites(true));
    api.organizations
      .createRequisites({payload: mapRequisites(formdata)})
      .then(
        (requisites) =>
          requisites &&
          api.organizations.createVerification({
            hotelId: hotel.id,
            payload: {organization_id: requisites.id},
          }),
      )
      .then(() => getOrganizationInvoice(hotel.id))
      .then(onComplete)
      .catch(handleException)
      .finally(() => store.dispatch(setUploadingRequisites(false)));
  };

export const handleVerifyRequisites =
  (hotel: Hotel, organizationId: number, onComplete: (file: Blob) => void) =>
  (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    store.dispatch(setUploadingRequisites(true));
    api.organizations
      .createVerification({
        hotelId: hotel.id,
        payload: {organization_id: organizationId},
      })

      .then(() => getOrganizationInvoice(hotel.id))
      .then(onComplete)
      .catch(handleException)
      .finally(() => store.dispatch(setUploadingRequisites(false)));
  };

export const pollingVerificationPaymentTransaction = (hotelId: Hotel['id']) => {
  const abortController = new AbortController();

  const updateRequisites = async () => {
    api.organizations
      .getVerificationRequisites({
        hotelId,
        headers: clientHeaders(),
      })
      .then((result) => {
        if (result) {
          store.dispatch(setRequisites(result));
        }
      });
  };

  return new CLongPolling({
    duration: 5000,
    takeWhile: () => true,
    mergeMap: updateRequisites,
    finalize: () => {},
    abortController,
  });
};

import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import type {Dispatch, FC, SetStateAction} from 'react';

import {Icon} from 'source/components/icon';

const Hotel = styled.li`
  padding: 4px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  p {
    transition: color 0.3s ease-in-out;
  }

  :hover {
    background-color: ${({theme}) => theme.palette.activeBackground};

    p {
      color: ${({theme}) => theme.palette.fontPrimary};
    }
  }
`;

interface ResultProperties {
  hotels: ShortHotels;
  showResults?: boolean;
  setSelected: Dispatch<SetStateAction<string>>;
  onHotelClick: (hotel: ShortHotel) => void;
}

export const HotelSearchResult: FC<ResultProperties> = ({
  hotels,
  showResults,
  setSelected,
  onHotelClick,
}) => {
  if (!showResults) {
    return null;
  }

  return (
    <>
      {hotels.map((hotel) => (
        <Hotel
          key={hotel.id}
          onClick={() => {
            onHotelClick(hotel);
            if (hotel.name) {
              setSelected(hotel.name);
            }
          }}
        >
          <Icon name="hotel-search-placemark" width={20} height={27} />
          <div>
            <Text size="S">{hotel.name}</Text>
          </div>
        </Hotel>
      ))}
    </>
  );
};

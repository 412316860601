import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Card} from 'library/components/card';
import {CircularLoader} from 'library/components/loader';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {Icon} from 'source/components/icon';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const InvoiceWrapper = styled(Card)<{isMobile: boolean}>`
  max-width: 440px;
  width: 100%;
  padding: 16px;
  margin-bottom: ${({isMobile}) => (isMobile ? '20px' : '0px')};
`;

const LoadingCard = styled(InvoiceWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingTitle = styled(Text)`
  letter-spacing: 0.03em;
  text-align: center;
  color: ${({theme}) => theme.palette.fontDefault};
  margin-top: 25px;
`;

const BoldSubtitle = styled(Text)`
  display: inline;
`;

const LoadingSubtitle = styled(Text)`
  text-align: center;
  color: ${({theme}) => theme.palette.fontSecondary};
  margin-top: 9px;
  letter-spacing: 0.03em;
`;

const Title = styled(Text)`
  letter-spacing: 0.03em;
  margin-top: 21px;
  color: ${({theme}) => theme.palette.fontDefault};
`;

const Subtitle = styled(Text)`
  letter-spacing: 0.03em;
  margin-top: 10px;
  color: ${({theme}) => theme.palette.fontDefault};
`;

const DownloadButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  height: 60px;
  padding: 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 500px) {
    height: fit-content;
    padding: 12px;
    flex-direction: column;
    svg {
      margin-bottom: 10px;
    }
    p {
      text-align: center;
    }
  }
`;

const ButtonText = styled(Text)`
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.primaryAccent};
  text-align: center;
  margin-left: 10px;
`;

interface InvoiceCardProps {
  isLoading: boolean;
  file?: Blob | null;
  name: string;
  className?: string;
}

export const InvoiceCard: FC<InvoiceCardProps> = ({
  isLoading,
  file,
  name,
  className,
}) => {
  const {t} = useTranslation('requisites');
  const mobile = useDeviceDetection('mobile');

  const handleOpenPDF = () => {
    if (file) {
      const pdfUrl = URL.createObjectURL(new Blob([file]));
      const linkElement = document.createElement('a');

      linkElement.href = pdfUrl;
      linkElement.download = `${name}-invoice.pdf`;

      document.body.append(linkElement);

      linkElement.click();

      linkElement.remove();
    }
  };

  return isLoading ? (
    <LoadingCard className={className} isMobile={mobile}>
      <CircularLoader size={64} />
      <LoadingTitle size="boldM">{t('loading_invoice_card')}</LoadingTitle>
      <LoadingSubtitle size="S">
        {t('loading_invoice_subtitle')}
      </LoadingSubtitle>
    </LoadingCard>
  ) : (
    <InvoiceWrapper className={className} isMobile={mobile}>
      <Icon name="exclamation-mark" width={19} height={19} />
      <Title size="boldM">{t('invoice_title')}</Title>
      <Subtitle size="S">
        <BoldSubtitle size="boldS">{t('invoice_subtitle_1')}</BoldSubtitle>
      </Subtitle>
      <DownloadButton
        type="button"
        onClick={handleOpenPDF}
        buttonType="secondary"
      >
        <Icon name="pdf-file" width={24} height={30} />
        <span>
          <ButtonText size="buttonXS">{t('download_invoice')}</ButtonText>
          <ButtonText size="buttonXS">{t('download_invoice_2')}</ButtonText>
        </span>
      </DownloadButton>
    </InvoiceWrapper>
  );
};

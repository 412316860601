import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {CircularLoader} from 'library/components/loader';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useContext, useEffect, useRef} from 'react';
import QRCode from 'react-qr-code';
import {verificationPaymentInvalidStatuses} from 'slices/requisites/lib/constants';
import {VerificationPaymentStatus} from 'slices/requisites/lib/type';
import {pollingVerificationPaymentTransaction} from 'slices/requisites/network';
import {
  selectVerificationPayment,
  setVerificationPaymentURL,
} from 'slices/requisites/store';
import {Icon} from 'source/components/icon';
import {HotelContext} from 'source/context/hotel';
import {UtilitiesContext} from 'source/context/utilities';
import {useAppDispatch, useAppSelector} from 'source/store';
import {api} from 'source/utilities/api';
import CLongPolling from 'source/utilities/long-polling';

const QRCodeWrapper = styled.div`
  margin-top: 25px;
`;

const SubTitle = styled(Text)`
  letter-spacing: 0.03em;
  margin-bottom: 20px;
  color: ${({theme}) => theme.palette.fontDefault};
`;

const Title = styled(SubTitle)`
  font-size: 18px;
  letter-spacing: 0.03em;
  margin-bottom: 20px;
  color: ${({theme}) => theme.palette.fontDefault};
`;

const QRWrapper = styled.div`
  width: fit-content;
`;

const StyledQR = styled(QRCode)`
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;

  @media (max-width: 900px) {
    max-width: 440px;
  }
`;

const StyledCircularLoader = styled(CircularLoader)`
  margin-bottom: 20px;
`;

const PaidInformationWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
`;

const PaidText = styled(Text)`
  margin-left: 10px;
`;

const InfoText = styled(Text)`
  margin-top: 20px;
  max-width: 256px;
`;

export const QrRequisitePayment = () => {
  const dispatch = useAppDispatch();
  const longPollingReference = useRef<CLongPolling | null>(null);

  const [utilities] = useContext(UtilitiesContext);
  const [hotel] = useContext(HotelContext);

  const {t} = useTranslation('requisites');
  const {requisitesExists, verification_payment} = useAppSelector(
    selectVerificationPayment,
  );

  const paymentURL = verification_payment?.payment_url;
  const verificationPaymentStatusValue = verification_payment?.status.value;

  const isVerificationPaymentHasError = !!(
    verification_payment &&
    verificationPaymentInvalidStatuses.includes(
      verification_payment.status.value,
    )
  );
  const isPaymentHasStatusPaid =
    verificationPaymentStatusValue === VerificationPaymentStatus.Paid;

  const handlePayment = () => {
    if (!paymentURL) {
      return;
    }

    const linkElement = document.createElement('a');

    linkElement.href = paymentURL;
    linkElement.target = '_blank';

    document.body.append(linkElement);

    linkElement.click();

    linkElement.remove();
  };

  const handleCreateVerificationPayment = () => {
    const isNotExistVerificationPayment = !!(
      hotel &&
      requisitesExists &&
      !verification_payment
    );

    const isNeedCreateVerificationPayment =
      isNotExistVerificationPayment || isVerificationPaymentHasError;

    if (hotel && isNeedCreateVerificationPayment) {
      api.organizations
        .createVerificationPayment({hotelId: hotel?.id})
        .then((paymentURL) => {
          dispatch(setVerificationPaymentURL(paymentURL));
        });
    }
  };

  useEffect(() => {
    handleCreateVerificationPayment();
  }, [hotel, requisitesExists, verification_payment]);

  useEffect(() => {
    const LongPolling = longPollingReference.current;

    if (hotel && !LongPolling && !isPaymentHasStatusPaid) {
      longPollingReference.current = pollingVerificationPaymentTransaction(
        hotel.id,
      );
      longPollingReference.current?.start();
    }

    return () => {
      const LongPolling = longPollingReference.current;

      if (LongPolling) {
        LongPolling.stop();
        LongPolling.subscribe?.unsubscribe();
        longPollingReference.current = null;
      }
    };
  }, [hotel, isPaymentHasStatusPaid]);

  if (isPaymentHasStatusPaid) {
    return (
      <PaidInformationWrapper>
        <Icon name="verified-mark" width={50} height={50} />
        <PaidText size="M">{t('qr_payment_success_title')}</PaidText>
      </PaidInformationWrapper>
    );
  }

  const isLoadingQR = !paymentURL || isVerificationPaymentHasError;

  if (utilities.windowWidth <= 900) {
    return (
      <QRCodeWrapper>
        <Title size="boldS">{t('npsk_payment_title')}</Title>
        {isLoadingQR ? (
          <StyledCircularLoader size={64} />
        ) : (
          <StyledButton onClick={handlePayment}>
            {t('payment_button_label')}
          </StyledButton>
        )}
      </QRCodeWrapper>
    );
  }

  return (
    <QRCodeWrapper>
      <Title size="boldS">{t('verification_payment_title')}</Title>
      <SubTitle size="boldS">{t('qr_payment_title')}</SubTitle>
      <QRWrapper>
        {isLoadingQR ? (
          <StyledCircularLoader size={64} />
        ) : (
          <StyledQR size={256} value={paymentURL} />
        )}
        {!isLoadingQR && (
          <StyledButton onClick={handlePayment}>
            {t('payment_button_label')}
          </StyledButton>
        )}
        <InfoText size="boldXS">{t('qr_payment_info_text')}</InfoText>
      </QRWrapper>
    </QRCodeWrapper>
  );
};

import type {GetServerSideProps} from 'next';
import {Translate} from 'next-translate';
import type {NextRouter} from 'next/router';
import {andThen, call, cond, identity, ifElse, pipeWith} from 'ramda';
import type {Dispatch, FormEvent, SetStateAction} from 'react';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {
  handleBusinessException,
  handlePropsException,
  handleUnhandledException,
  incorrectUser,
  showSuccessMessage,
} from 'source/utilities/exceptions/business';
import {handleApiException} from 'source/utilities/exceptions/network';
import {isUser} from 'source/utilities/guards/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {
  isApiException,
  isBusinessException,
  isUnhandledException,
} from 'source/utilities/guards/exceptions';
import {CookieNames, setCookie} from 'source/utilities/network/cookies';
import {serverHeaders} from 'source/utilities/network/http';
import {Routes, redirects} from 'source/utilities/network/url';
import type {ApplicationProperties} from 'source/utilities/ui';
import {FormStateDispatch, FormStateEnum} from './lib/type';

export const getPasswordResetPageProperties: GetServerSideProps<
  ApplicationProperties
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  return call(pipeWith(andThen, [() => checkUser(utilities, headers)]))
    .then((properties) => {
      if (properties?.authorized) {
        return {redirect: redirects.manage};
      }
      return {
        props: properties,
      };
    })
    .catch(handlePropsException);
};

export const passwordRequest =
  (
    formdata: PasswordRequestFormdata,
    setFormState: FormStateDispatch,
    translate: Translate,
    ...dispatchers: [LoadingDispatch, Dispatch<SetStateAction<string>>]
  ) =>
  (event: FormEvent<HTMLFormElement>) => {
    const [setLoading, setEmail] = dispatchers;

    event.preventDefault();

    setLoading(true);

    api.auth
      .requestPassword({payload: formdata})
      .then(() => {
        setFormState(FormStateEnum.PASSWORD_RESET);
        setEmail(formdata.email);
        showSuccessMessage(
          translate('password-reset:success_send_reset_code', {
            email: formdata.email,
          }),
        );
      })
      .catch(
        cond([
          [isApiException, handleApiException],
          [isBusinessException, handleBusinessException],
          [isUnhandledException, handleUnhandledException],
        ]),
      )
      .finally(() => setLoading(false));
  };

export const passwordReset =
  (
    router: NextRouter,
    formdata: ClientPasswordResetFormData,
    translate: Translate,
    ...dispatchers: [LoadingDispatch, UserDispatch, AuthorizationDispatch]
  ) =>
  (event: FormEvent<HTMLFormElement>) => {
    const [setLoading, setUser, setAuthorization] = dispatchers;
    const {email, code, password} = formdata;

    event.preventDefault();

    setLoading(true);

    api.auth
      .resetPassword({payload: {email, code, password}})
      .then(() =>
        api.auth
          .login({
            payload: {email, password},
          })
          .then(ifElse(isUser, identity, incorrectUser))
          .then((user) => {
            setAuthorization(true);
            setCookie(CookieNames.HOTELIER_AUTHORIZATION, user.api_token);
            setUser(user);
          })
          .then(() => {
            router.push(Routes.manage);
            showSuccessMessage(
              translate('password-reset:success_reset_password', {
                email,
              }),
            );
          })
          .catch(
            cond([
              [isApiException, handleApiException],
              [isBusinessException, handleBusinessException],
              [isUnhandledException, handleUnhandledException],
            ]),
          ),
      )
      .catch(
        cond([
          [isApiException, handleApiException],
          [isBusinessException, handleBusinessException],
          [isUnhandledException, handleUnhandledException],
        ]),
      )
      .finally(() => setLoading(false));
  };

import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useContext} from 'react';
import {HotelContext} from 'source/context/hotel';
import {getChannelManagersOptions} from '../lib/utils';
import {EIntegrations} from '../lib/types';

const Wrapper = styled.div`
  max-width: 440px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TravelLineIntegrationContent = () => {
  const {t} = useTranslation('integration');
  const [hotel] = useContext(HotelContext);

  const travelLineIntegration = hotel?.travel_line_integration;
  const isReady = travelLineIntegration?.is_ready;

  const travelLineIntegrationOption = getChannelManagersOptions(
    Boolean(hotel?.travel_line_integration),
  ).find(({value}) => value === EIntegrations.TRAVEL_LINE)!;

  return (
    <Wrapper>
      <InfoWrapper>
        {(!travelLineIntegration?.is_configured || !isReady) && (
          <Text size="S">
            {t('integration_in_process', {
              providerName: travelLineIntegrationOption?.label,
            })}
          </Text>
        )}
        {isReady && (
          <Text size="S">
            {t('last_synchronization_date', {
              providerName: travelLineIntegration?.synced_at,
            })}
          </Text>
        )}
      </InfoWrapper>
    </Wrapper>
  );
};

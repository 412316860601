import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {PopupProps} from 'reactjs-popup/dist/types';
import {MINIMAL_WITHDRAW_BALANCE} from 'slices/balance/lib/constants';

interface IProps {
  position: PopupProps['position'];
  trigger: PopupProps['trigger'];
  disabled?: PopupProps['disabled'];
}

export const MinimalAmountTooltip: FC<IProps> = ({
  trigger,
  disabled,
  position,
}) => {
  const {t} = useTranslation('balance');

  return (
    <Tooltip
      on="hover"
      position={position}
      minWidth={270}
      maxWidth={300}
      disabled={disabled}
      trigger={trigger}
    >
      <Text size="S">
        {t('balance_operations_table.notify.minimal_withdraw_amount', {
          amount: MINIMAL_WITHDRAW_BALANCE,
          currency: t('common:units_payments.rub'),
        })}
      </Text>
    </Tooltip>
  );
};

import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {Icon, IconName} from 'source/components/icon';
import {getActiveLink} from 'source/utilities/url';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: max-content;
  gap: 12px;
  text-decoration: none;
`;

const StyledIcon = styled(Icon)`
  min-width: 20px;
`;

const StyledText = styled(Text)<{isActive: boolean}>`
  color: ${({isActive}) => isActive && '#FF5876'};
  width: 100%;
  &:hover {
    color: ${({theme}) => theme.palette.primaryAccent};
    transition: 0.4s;
  }
`;

interface Props {
  href: string;
  iconName?: IconName;
  children: string;
  isLinkActive?: boolean;
  className?: string;
}

export const NavigationLink: FC<Props> = ({
  href,
  iconName,
  children,
  isLinkActive,
  className,
}) => {
  const router = useRouter();

  const isActive = getActiveLink(href, router.pathname, isLinkActive);

  return (
    <StyledLink href={href} className={className}>
      {iconName ? <StyledIcon name={iconName} width={20} height={20} /> : null}
      <StyledText size="S" isActive={isActive}>
        {children}
      </StyledText>
    </StyledLink>
  );
};

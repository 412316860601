import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const DescriptionWrapper = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(220, 220, 220, 0.4);
`;

const Description = styled(Text)``;

const MinimalPhotos = styled(Text)`
  color: ${({theme}) => theme.palette.primaryAccent};
`;

interface Props {
  roomId?: number;
  minPhotos: number | null;
}

const Header: FC<Props> = ({roomId, minPhotos}) => {
  const {t} = useTranslation('photos');
  const mobile = useDeviceDetection('mobile');

  return (
    <div>
      <DescriptionWrapper>
        <Description size={mobile ? 'XS' : 'S'}>
          {t(roomId ? 'room_gallery_description' : 'gallery_description')}
        </Description>
        {minPhotos && (
          <MinimalPhotos size={mobile ? 'XS' : 'S'}>
            {t('minimal_photos', {
              number: minPhotos,
            })}
          </MinimalPhotos>
        )}
      </DescriptionWrapper>
    </div>
  );
};

export default Header;

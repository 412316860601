import styled from '@emotion/styled';

import {properties} from 'library/utilities/css-properties';
import {kebab} from 'library/utilities/kebab';

const prefixed = properties.map((property) => `$${property}` as const);

export const stylable: CreateStylable = (factory) =>
  styled(factory)`
    ${(properties) =>
      prefixed.some((property) => properties[property]) &&
      prefixed
        .map((property) => [property, properties[property]] as const)
        .filter(([key, value]) => Boolean(value))
        .map(([key, value]) => [kebab(key.slice(1)), value])
        .map((pair) => `${pair.join(':')};`)}
  `;

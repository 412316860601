import {format} from 'date-fns';
import {isEmpty, isNil} from 'ramda';

import {getViewMonthWithYear} from 'source/utilities/dates';
import {toValueOrEmptyArray} from 'source/utilities/array';

import {
  CALENDAR_PRICE_PLACEHOLDER,
  CALENDAR_DEFAULT_PRICE,
  MIN_RATE_PRICE,
} from '../constants';
import {
  GroupedPrices,
  GuestNumberPrice,
  RangeSelectCells,
  RowsPriceForCountName,
  UpdatedCellType,
} from '../types';

export const buildPriceForCountName = (value: number): RowsPriceForCountName =>
  `price_for_count${value}`;

export const buildLabelRoomPrice = (
  rangeSelected: boolean,
  priceData: GuestNumberPrice,
  cellPrice: string,
): string => {
  if (rangeSelected) {
    return format(new Date(priceData.date), 'dd');
  }

  if (isNil(priceData.price)) {
    return CALENDAR_PRICE_PLACEHOLDER;
  }

  return Number(cellPrice) < MIN_RATE_PRICE
    ? CALENDAR_DEFAULT_PRICE
    : cellPrice;
};

export const sortPricesByMonths = (
  prices: RangeSelectCells['prices'],
): GroupedPrices => {
  return prices.reduce((accumulator: GroupedPrices, priceData) => {
    const monthWithYear = getViewMonthWithYear(priceData.date);

    const existingMonth = accumulator.find(
      (priceData) => priceData.monthWithYear === monthWithYear,
    );

    if (existingMonth) {
      existingMonth.prices.push(priceData);
    } else {
      accumulator.push({monthWithYear, prices: [priceData]});
    }

    return accumulator;
  }, []);
};

export const findPrices = (
  ratesPrices: CalendarRatesPrices,
  guestNumber: number,
  rateId: number,
): CalendarRatesPricesByGuestsNumber => {
  const currentRate = ratesPrices.find(
    (ratePrice) => ratePrice.rate.id === rateId,
  );

  const currentByGuestsNumberItem = currentRate?.by_guests_number.find(
    (element) => element.guests_number === guestNumber,
  );

  return toValueOrEmptyArray(currentByGuestsNumberItem?.prices);
};

export const buildUpdatedRatesPrices = (
  ratesPrices: CalendarRatesPrices,
  updatedCells: UpdatedCellType[],
  rate: number,
): CalendarRatesPrices => {
  return ratesPrices.map((ratePrice) => {
    if (ratePrice.rate.id !== rate) {
      return ratePrice;
    }
    // сет изменённых guest_number
    const changedGuestNumbers = new Set(
      updatedCells.map((cell) => cell.guestNumber),
    );

    // Проходимся по текущем ценам и заменяем price
    const newByGuestsNumber = ratePrice.by_guests_number.map(
      (byGuestsNumberItem) => {
        if (!changedGuestNumbers.has(byGuestsNumberItem.guests_number)) {
          return byGuestsNumberItem;
        }

        const newPrices = byGuestsNumberItem.prices.map((priceData) => {
          const currentCellUpdated = updatedCells.find(
            (cell) =>
              priceData.date === cell.date &&
              byGuestsNumberItem.guests_number === cell.guestNumber,
          );

          if (currentCellUpdated) {
            return {...priceData, price: currentCellUpdated.price};
          }

          return priceData;
        });

        return {...byGuestsNumberItem, prices: newPrices};
      },
    );

    return {...ratePrice, by_guests_number: newByGuestsNumber};
  });
};

export const parseCalendarData = <T>(array: T[]): T[] | [] =>
  isEmpty(array) ? [] : array;

import {StoreBalance} from 'slices/common';
import {AuthorizationState} from 'source/context/authorization';
import {UserState} from 'source/context/user';
import {Utilities, UtilitiesState} from 'source/context/utilities';

export const isMobile = (utilities: Utilities) => {
  return utilities.mobile || utilities.windowWidth <= 480;
};

export const isTablet = (utilities: Utilities) => {
  return (
    utilities.tablet ||
    (utilities.windowWidth > 480 && utilities.windowWidth < 900)
  );
};

export interface ApplicationProperties {
  authorized: ContextValue<AuthorizationState> | null;
  user: ContextValue<UserState> | null;
  balance: StoreBalance;
  utilities: ContextValue<UtilitiesState> | null;
}

export interface GetServerSideApplicationProps {
  props: ApplicationProperties;
}

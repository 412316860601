import styled from '@emotion/styled';
import CreateButton from 'slices/create_hotelier_object/ui/create-button';
import DescriptionObject from 'slices/create_hotelier_object/ui/description-object';
import SelectMap from 'slices/create_hotelier_object/ui/select-map';

const Wrapper = styled.div``;

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 50px;
  @media (max-width: 480px) {
    min-width: 100%;
    flex-direction: column;
  }
  @media (max-width: 1200px) {
    min-width: 100%;
    flex-direction: column;
  }
`;

const Content = () => {
  return (
    <Wrapper>
      <MainWrapper>
        <DescriptionObject />
        <SelectMap />
      </MainWrapper>
      <CreateButton />
    </Wrapper>
  );
};

export default Content;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';
import {DEFAULT_PAGE} from 'source/utilities/constants/logic';
import {BalanceDetailsStoreInitialState} from './lib/types';

const initialState: BalanceDetailsStoreInitialState = {
  withdrawals: [],
  organization: null,
  isLast: false,
  loading: false,
  page: DEFAULT_PAGE,
};

const balanceDetailsSlice = createSlice({
  name: 'balanceDetails',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setOrganization: (state, action: PayloadAction<ExistingRequisitesItem>) => {
      state.organization = action.payload;
    },
    setWithdrawals: (state, action: PayloadAction<Withdrawals>) => {
      state.withdrawals = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setIsLast: (state, action: PayloadAction<boolean>) => {
      state.isLast = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const selectOrganization = (state: RootState) => {
  return state.balanceDetails.organization;
};

export const selectWithdrawals = (state: RootState) => {
  return state.balanceDetails.withdrawals;
};

export const selectPage = (state: RootState) => {
  return state.balanceDetails.page;
};

export const selectIsLast = (state: RootState) => {
  return state.balanceDetails.isLast;
};

export const selectLoading = (state: RootState) => {
  return state.balanceDetails.loading;
};

export const {
  reset,
  setOrganization,
  setPage,
  setIsLast,
  setWithdrawals,
  setLoading,
} = balanceDetailsSlice.actions;

export default balanceDetailsSlice.reducer;

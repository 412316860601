import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {memo} from 'react';

import {Icon} from 'source/components/icon';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {useAppSelector} from 'source/store';
import {LOADING_STYLES} from 'source/utilities/constants/css';

import {selectIsLoading} from 'slices/calendar/store';

import {CalendarPicker} from '../../calendar-picker';
import {CalendarPopup} from '../../calendar-popup';
import {FilterButton} from '../../filter-button';

const FirstDataCell = styled.div<{mobile: boolean}>`
  min-width: ${({mobile}) => (mobile ? 60 : 90)}px;
`;

const StyledIcon = styled(Icon)`
  path {
    fill: ${({theme}) => theme.palette.fontAccent};
  }
`;

const CalendarTrigger = styled.div<{isLoading: boolean}>`
  cursor: pointer;

  ${({isLoading}) =>
    isLoading &&
    css`
      ${LOADING_STYLES}
    `}
`;

const CalendarButtonComponent = () => {
  const mobile = useDeviceDetection('mobile');
  const loading = useAppSelector(selectIsLoading);

  return (
    <FirstDataCell mobile={mobile}>
      <CalendarPopup
        on="click"
        position="bottom left"
        mobileTopModal={mobile}
        trigger={
          <CalendarTrigger
            className="calendar-picker-trigger"
            isLoading={loading}
          >
            <FilterButton variant="secondary" disabled={loading}>
              <StyledIcon name="calendar" width={22} height={21} />
            </FilterButton>
          </CalendarTrigger>
        }
      >
        <CalendarPicker />
      </CalendarPopup>
    </FirstDataCell>
  );
};

export const CalendarButton = memo(CalendarButtonComponent);

import {FC, useContext} from 'react';
import styled from '@emotion/styled';

import {
  RowBody,
  RowBodyData,
  RowHead,
  RowHeadData,
  TableBoilerplate,
} from 'library/components/table';
import {Link} from 'source/components/link';
import {Hint} from 'library/components/hint';
import {Button} from 'library/components/button';
import {Icon} from 'source/components/icon';

import useTranslation from 'next-translate/useTranslation';
import {HotelContext} from 'source/context/hotel';
import {Text} from 'library/components/text';
import {useTheme} from '@emotion/react';
import {showSuccessMessage} from 'source/utilities/exceptions/business';
import {CircularLoader} from 'library/components/loader';
import {buildEditRoomRoute} from 'source/utilities/url';
import {getRealtyIntegrationStatus} from '../lib/utils';
import {EIntergrationsLabels, ERealtyIntegrationStatuses} from '../lib/types';

const Wrapper = styled.div`
  position: relative;
`;

const RoomName = styled(Text)`
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ButtonsWrapper = styled(Row)`
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: ${({theme}) => theme.palette.fontAccent};
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.palette.border};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.palette.loadingBackground};
  z-index: 10;
`;

const AccomodationIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AccommodationId = styled.span`
    user-select: all;

`

const CopyText = styled(Text)`
  cursor: pointer;
`;

interface IProps {
  realtyIntegrations: RealtyIntegration[];
  handleSwitchIntegrationStatus: (integration: RealtyIntegration) => void;
  loading: boolean;
}

export const RealtyIntegrationTable: FC<IProps> = ({
  realtyIntegrations,
  handleSwitchIntegrationStatus,
  loading,
}) => {
  const {t} = useTranslation('integration');
  const [hotel] = useContext(HotelContext);
  const theme = useTheme();

  const handleCopy = (
    accomodationId: RealtyIntegration['accommodation_id'],
  ) => {
    if (!accomodationId) {
      return;
    }

    navigator.clipboard
      .writeText(accomodationId)
      .then(() => {
        showSuccessMessage(
          t('success_token_copy', {
            providerName: EIntergrationsLabels.REALTY,
          }),
        );
      })
      .catch(() => {
        showSuccessMessage(t('attemption_error_token_copy'));
      });
  };

  return (
    <Wrapper>
      {loading && (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      )}
      <TableBoilerplate
        rowHeadChildren={
          <RowHead>
            <RowHeadData isFirst>{t('room.name')}</RowHeadData>
            {/* TODO Скрыть (Очень большое количество отельеров, не могут настроить синхронизацию, потому что в Realty вставляют ID номера) */}
            {/* <RowHeadData>{t('room.id')}</RowHeadData> */}
            <RowHeadData>
              <Row>
                {t('room.accomodation_id')}
                <Hint
                  text={t('success_token_copy', {
                    providerName: EIntergrationsLabels.REALTY,
                  })}
                />
              </Row>
            </RowHeadData>
            <RowHeadData>{t('room.integration_status')}</RowHeadData>
            <RowHeadData isLast>{t('room.switch_integration')}</RowHeadData>
          </RowHead>
        }
        rowBodyChildren={
          realtyIntegrations.length > 0 && (
            <>
              {realtyIntegrations.map((integration) => {
                if (!integration.room) {
                  return null;
                }

                const {name, id} = integration.room;
                const {status, color} = getRealtyIntegrationStatus(
                  integration,
                  theme,
                );
                const isDeleted = status === ERealtyIntegrationStatuses.DELETED;
                const shouldDisplayLink = hotel && !isDeleted;

                return (
                  <RowBody key={integration.id}>
                    <RowBodyData isFirst>
                      {shouldDisplayLink ? (
                        <Link href={buildEditRoomRoute(hotel.id, id)}>
                          <RoomName
                            size="S"
                            color={theme.palette.primaryCircuit}
                          >
                            {name}
                          </RoomName>
                        </Link>
                      ) : (
                        <RoomName size="S" color={theme.palette.primaryCircuit}>
                          {name}
                        </RoomName>
                      )}
                    </RowBodyData>
                    {/* TODO Скрыть (Очень большое количество отельеров, не могут настроить синхронизацию, потому что в Realty вставляют ID номера) */}
                    {/* <RowBodyData>{id}</RowBodyData> */}
                    <RowBodyData>
                      <AccomodationIdWrapper>
                        <AccommodationId>
                          {integration.accommodation_id || ''}
                        </AccommodationId>
                        <CopyText
                          size="boldXS"
                          color={theme.palette.fontPrimary}
                          onClick={() =>
                            handleCopy(integration.accommodation_id)
                          }
                        >
                          {t('copy')}
                        </CopyText>
                      </AccomodationIdWrapper>
                    </RowBodyData>
                    <RowBodyData>
                      <Text color={color} size="boldXS">
                        {t(status)}
                      </Text>
                    </RowBodyData>
                    <RowBodyData isLast>
                      <ButtonsWrapper>
                        {integration.is_deleted ? (
                          <StyledButton
                            disabled={loading}
                            onClick={() =>
                              handleSwitchIntegrationStatus(integration)
                            }
                          >
                            <Icon name="restore" width={20} height={20} />
                          </StyledButton>
                        ) : (
                          <StyledButton
                            disabled={loading}
                            onClick={() =>
                              handleSwitchIntegrationStatus(integration)
                            }
                          >
                            <Icon name="trash-bin" width={24} height={24} />
                          </StyledButton>
                        )}
                      </ButtonsWrapper>
                    </RowBodyData>
                  </RowBody>
                );
              })}
            </>
          )
        }
      />
    </Wrapper>
  );
};

import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {ButtonLink} from 'library/components/button-link';
import {NoContent} from 'library/components/no-content';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {assocPath, isEmpty} from 'ramda';
import {useContext} from 'react';
import {selectRooms} from 'slices/rooms/store';
import {HotelContext} from 'source/context/hotel';
import {useLocalStore} from 'source/context/localstorage-store';
import {useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';
import {buildAddRoomRoute, buildRoomsRoute} from 'source/utilities/url';
import {useTravelLineIntegration} from 'source/utilities/hooks/use-is-travel-line-integration';
import {handleCreateNewRate} from './network';
import {selectIsWaitingCreate} from './store';
import RatesTable from './ui/rates-table';

const Wrapper = styled.div`
  margin-top: 40px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

const CreateRateButton = styled(Button)`
  text-transform: uppercase;
`;

const AddRoomButton = styled(ButtonLink)`
  text-transform: uppercase;
  padding: 20px 40px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
`;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwitchText = styled(Text)`
  margin-right: 11px;
`;

const Content = () => {
  const router = useRouter();
  const [localStore, setLocalStore] = useLocalStore();
  const [hotel] = useContext(HotelContext);
  const [hasRealtyIntegration] = useRealtyIntegration();
  const [hasTravelLineIntegration] = useTravelLineIntegration();

  const {t} = useTranslation('rates');
  const isWaitingCreate = useAppSelector(selectIsWaitingCreate);
  const roomList = useAppSelector(selectRooms);

  const mobile = useDeviceDetection('mobile');

  const textSize = mobile ? 'XS' : 'S';

  if (isEmpty(roomList) && hotel) {
    return (
      <NoContent
        buttonLabel={t('no_content.button_label')}
        subtitle={t('no_content.subtitle')}
        href={buildRoomsRoute(hotel?.id)}
      />
    );
  }

  return (
    <Wrapper>
      <ButtonsWrapper>
        {!hasRealtyIntegration && (
          <CreateRateButton
            disabled={isWaitingCreate || hasTravelLineIntegration}
            onClick={() =>
              handleCreateNewRate(t, router, hotel?.id, setLocalStore)
            }
          >
            {isWaitingCreate ? t('creating_rate') : t('create_rate_button')}
          </CreateRateButton>
        )}

        {hotel && roomList?.length === 0 && !hasRealtyIntegration ? (
          <AddRoomButton variant="primary" href={buildAddRoomRoute(hotel?.id)}>
            {t('add_room')}
          </AddRoomButton>
        ) : null}
      </ButtonsWrapper>

      <SwitchContainer>
        <SwitchWrapper>
          <SwitchText size={textSize}>{t('show_disabled')}</SwitchText>
          <Switch
            disabled={hasTravelLineIntegration}
            initialState={localStore.hasShowDisableRate}
            isActive={localStore.hasShowDisableRate}
            onSwitch={async (enabled) => {
              setLocalStore(assocPath(['hasShowDisableRate'], enabled));
            }}
          />
        </SwitchWrapper>
      </SwitchContainer>
      <RatesTable />
    </Wrapper>
  );
};

export default Content;

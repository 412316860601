import {GetServerSideProps} from 'next';
import {Translate} from 'next-translate';
import {NextRouter} from 'next/router';
import {andThen, assoc, assocPath, call, pipeWith, when} from 'ramda';
import {LocalStoreDispatch} from 'source/context/localstorage-store';
import {store} from 'source/store';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {
  handleException,
  handleResponseErrors,
  showException,
  showSuccessMessage,
} from 'source/utilities/exceptions/business';
import {throwRedirectException} from 'source/utilities/exceptions/network';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {isUnhandledException} from 'source/utilities/guards/exceptions';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {RatesPageProperties} from './lib/types';
import {setIsWaitingCreate, setRates} from './store';

export const getRatesPageProperties: GetServerSideProps<
  RatesPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestRooms = () =>
    api.rooms.getShorts({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestRates = () =>
    api.rates.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, rates, rooms] = await Promise.all([
            requestHotel(),
            requestRates(),
            requestRooms(),
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            rates,
            rooms,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const handleCreateNewRate = async (
  translate: Translate,
  router: NextRouter,
  hotelId?: number | null,
  ...dispatchers: [LocalStoreDispatch]
) => {
  const [setLocalStore] = dispatchers;

  if (!hotelId) {
    return;
  }

  const {
    rates: {rates},
    rooms: {data: rooms},
  } = store.getState();

  if ((Array.isArray(rooms) && rooms.length === 0) || !rooms) {
    // eslint-disable-next-line consistent-return
    return showException(translate('notify.not_found_rooms_create') as string);
  }

  store.dispatch(setIsWaitingCreate(true));

  api.rates
    .create({hotelId})
    .then(async (rate) => {
      store.dispatch(
        setRates([
          {
            ...rate,
          },
          ...rates,
        ]),
      );
      setLocalStore(assocPath(['hasShowDisableRate'], true));
      try {
        await router.push(`/manage/${hotelId}/rates/${rate?.id}?isNew=true`);
        showSuccessMessage(
          translate('notify.creating', {
            name: rate?.name,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    })
    .catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    )
    .finally(() => store.dispatch(setIsWaitingCreate(false)));
};

export const handleRateSwitch = async (
  hotelId: number,
  rateId: number,
  currentValue: boolean,
  onSuccess: (value: boolean) => void,
) => {
  const request = currentValue ? api.rate.disable : api.rate.enable;
  request({hotelId, rateId})
    .then((data) => {
      const isSuccess = data && 'enabled' in data;

      if (isSuccess) {
        onSuccess(data.enabled);
      }

      if (!isSuccess && data) {
        handleResponseErrors(data);
      }
    })
    .catch(handleException);
};

export const handleRateDelete = (
  hotelId: number,
  rateId: number,
  onSuccess: () => void,
) => {
  api.rate.delete({hotelId, rateId}).then(onSuccess).catch(handleException);
};

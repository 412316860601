import styled from '@emotion/styled';
import {FC, PropsWithChildren} from 'react';
import {PopupProps} from 'reactjs-popup/dist/types';

import {Tooltip} from 'library/components/tooltip';

const StyledPopup = styled(Tooltip)`
  &-content {
    width: fit-content !important;
  }
`;

interface Props extends PropsWithChildren {
  on: PopupProps['on'];
  position: PopupProps['position'];
  trigger: PopupProps['trigger'];
  mobileTopModal?: PopupProps['modal'];
  open?: PopupProps['open'];
  onClose?: PopupProps['onClose'];
}

export const CalendarPopup: FC<Props> = ({children, ...properties}) => {
  return <StyledPopup {...properties}>{children}</StyledPopup>;
};

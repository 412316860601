import styled from '@emotion/styled';
import {ArrowText} from 'library/components/arrow-text';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useEffect} from 'react';
import {reset, selectPolicies, setTabsOpened} from 'slices/policies/store';
import {ERequiredTabs, RequiredTabsType} from 'slices/policies/lib/types';
import {checkIsShouldOpenTab} from 'slices/policies/lib/helpers';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import TravelLineProvider from 'source/components/travel-line-provider';
import {useTravelLineIntegration} from 'source/utilities/hooks/use-is-travel-line-integration';
import AlienRegistration from './ui/alien-registration';
import CheckInOut from './ui/check-in-out';
import Deposit from './ui/deposit';
import PaymentsInHotel from './ui/payments';
import TaxesAndFees from './ui/taxes-and-fees';
import WithAnimals from './ui/with-animals';

const DropdownWrapper = styled.div`
  margin-bottom: 24px;
`;

const ChildrenWrapper = styled.div`
  margin-top: 24px;
`;

const HeaderSubtitle = styled(Text)`
  width: fit-content;
  letter-spacing: 0.03em;
  border-radius: 6px;
  margin: 19px 0px 48px 0px;
`;

export const Content = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('policies');
  const mobile = useDeviceDetection('mobile');
  const {tabsOpened, isNeedCheckTabs} = useAppSelector(selectPolicies);
  const [hasTravelLineIntegration] = useTravelLineIntegration();

  const arrowTextSize = mobile ? 'M' : 'L';

  const handleOpenTab = (key: RequiredTabsType) => {
    dispatch(setTabsOpened([...new Set([...tabsOpened, key])]));
  };

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <>
      <HeaderSubtitle size="S">{t('subtitle')}</HeaderSubtitle>
      <DropdownWrapper>
        <ArrowText
          title={t('dropdown.check-in-out')}
          size={arrowTextSize}
          parentHandler={() => handleOpenTab(ERequiredTabs.CHECK_IN_OUT)}
          parentState={checkIsShouldOpenTab(
            isNeedCheckTabs,
            ERequiredTabs.CHECK_IN_OUT,
            tabsOpened,
          )}
        >
          <ChildrenWrapper>
            <TravelLineProvider>
              <CheckInOut />
            </TravelLineProvider>
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>
      <DropdownWrapper>
        <ArrowText
          title={t('dropdown.payments-in-hotel')}
          size={arrowTextSize}
          parentHandler={() => handleOpenTab(ERequiredTabs.PAYMENTS)}
          parentState={checkIsShouldOpenTab(
            isNeedCheckTabs,
            ERequiredTabs.PAYMENTS,
            tabsOpened
          )}
        >
          <ChildrenWrapper>
            <PaymentsInHotel />
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>
      <DropdownWrapper>
        <ArrowText
          title={t('dropdown.with-animals')}
          size={arrowTextSize}
          parentHandler={() => handleOpenTab(ERequiredTabs.WITH_ANIMALS)}
          parentState={checkIsShouldOpenTab(
            isNeedCheckTabs,
            ERequiredTabs.WITH_ANIMALS,
            tabsOpened
          )}
        >
          <ChildrenWrapper>
            <WithAnimals />
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>
      <DropdownWrapper>
        <ArrowText
          title={t('dropdown.alien-registration')}
          size={arrowTextSize}
        >
          <ChildrenWrapper>
            <Deposit />
            <AlienRegistration />
            {!hasTravelLineIntegration && <TaxesAndFees />}
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>
    </>
  );
};

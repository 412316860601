/* eslint-disable camelcase */
import styled from '@emotion/styled';
import {FC, memo} from 'react';

import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

import {DayDatesType} from 'slices/calendar/lib/types';
import {
  CELL_WIDTH,
  HIGHLIGHT_ROOMS_AVAILABILITY_NUMBER,
} from 'slices/calendar/lib/constants';

import {isBoolean} from 'source/utilities/guards/types';
import useTranslation from 'next-translate/useTranslation';
import {checkHasAvailableRooms} from 'slices/calendar/lib/helpers/ui';
import {DataButton} from '../data-button';
import {RoomsNumberPopupContent} from '../../rooms-number-popup-content';
import {CalendarPopup} from '../../calendar-popup';

const DataCell = styled.div`
  min-width: ${CELL_WIDTH}px;
`;

interface IProps {
  changed: boolean;
  disabled: boolean;
  availableRoomsNumber: number;
  customRoomsAvailability: number;
  selected: boolean;
  onDatesAvailabilitiesClick: (
    roomId: number,
    columnIndex: number,
    popupRoomId: number,
  ) => void;
  id: number;
  index: number;
  dayDate: DayDatesType[number];
  outputValue: number;
  roomsNumberId: number | null;
  onRoomsAvailabilitiesPopupClose: () => void;
  onRoomsAvailabilitiesPopupApply: (
    value: number,
    item: DayDatesType[number],
  ) => void;
  maxFreeRooms: number;
  hasFree: CalendarDateAvailabilities[number]['has_free'];
}

const RoomAvailabilitiesCellComponent: FC<IProps> = ({
  changed,
  disabled,
  availableRoomsNumber,
  customRoomsAvailability,
  selected,
  onDatesAvailabilitiesClick,
  id,
  index,
  dayDate,
  outputValue,
  roomsNumberId,
  onRoomsAvailabilitiesPopupClose,
  onRoomsAvailabilitiesPopupApply,
  maxFreeRooms,
  hasFree,
}) => {
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation('calendar');
  const cellOutput = isBoolean(hasFree)
    ? t(hasFree ? 'has' : 'has_not')
    : outputValue;
  const hasAvailableRooms = checkHasAvailableRooms(
    availableRoomsNumber,
    customRoomsAvailability,
    hasFree,
  );

  const isYellowCell =
    customRoomsAvailability === HIGHLIGHT_ROOMS_AVAILABILITY_NUMBER ||
    !hasAvailableRooms;

  return (
    <DataCell>
      <DataButton
        changed={changed}
        disabled={disabled}
        yellow={isYellowCell}
        green={!isYellowCell}
        selected={selected}
        onClick={() => onDatesAvailabilitiesClick(id, index, dayDate.id)}
      >
        {cellOutput}
        {!isBoolean(hasFree) && (
          <CalendarPopup
            open={dayDate.id === roomsNumberId}
            onClose={onRoomsAvailabilitiesPopupClose}
            position="top center"
            on="click"
            trigger={<div />}
            mobileTopModal={mobile}
          >
            <RoomsNumberPopupContent
              onApply={(value) =>
                onRoomsAvailabilitiesPopupApply(value, dayDate)
              }
              maxFreeRooms={maxFreeRooms}
              initialValue={outputValue}
            />
          </CalendarPopup>
        )}
      </DataButton>
    </DataCell>
  );
};

export const RoomAvailabilitiesCell = memo(RoomAvailabilitiesCellComponent);

import styled from '@emotion/styled';
import { CircularLoader } from 'library/components/loader';
import { useContext } from 'react';
import { LoadingContext } from 'source/context/loading';

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({theme}) => theme.palette.fontAccent};
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const LoadingModal = () => {
  const [loading] = useContext(LoadingContext);
  if (!loading) {
    return null;
  }
  return (
    <LoadingWrapper>
      <LoaderWrapper>
        <CircularLoader size={64} />
      </LoaderWrapper>
    </LoadingWrapper>
  );
};

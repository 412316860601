import {useTheme} from '@emotion/react';
import useTranslation from 'next-translate/useTranslation';
import {forwardRef} from 'react';

import {Input, InputRef} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';

import {useAppDispatch, useAppSelector} from 'source/store';

import {selectRoomName, setName} from '../../store';
import {getLabelTranslate, getPlaceholderTranslate} from '../../lib/helpers';

interface IProps {
  translateKey: string;
  resetIsChange: boolean;
  disableAutoFocus?: boolean;
}

export const NameInput = forwardRef<InputRef, IProps>(
  ({translateKey, resetIsChange, disableAutoFocus = false}, forwardedRef) => {
    const theme = useTheme();
    const name = useAppSelector(selectRoomName);
    const dispatch = useAppDispatch();
    const {t} = useTranslation(translateKey);

    return (
      <LabeledComponent
        color={theme.palette.fontSecondary}
        size="S"
        gap={10}
        text={getLabelTranslate(t, 'name')}
        subText={t('required')}
        subTextSize="XS"
        subTextColor={theme.palette.danger}
      >
        <Input
          placeholder={getPlaceholderTranslate(t, 'name')}
          value={name}
          required
          autoFocus={!disableAutoFocus}
          resetIsChange={resetIsChange}
          ref={forwardedRef}
          onChange={(event) => dispatch(setName(event.target.value))}
          label={null}
          type="text"
        />
      </LabeledComponent>
    );
  },
);

NameInput.displayName = 'NameInput';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from 'source/store';

import {DEFAULT_HOTEL_MODERATION_LAST} from './lib/constants';
import {GeneralInfoStore} from './lib/types';

export const initialState: GeneralInfoStore = {
  hotelModerationLast: DEFAULT_HOTEL_MODERATION_LAST,
  rates: [],
  moderationDescriptionList: [],
};

const generalInfoSlice = createSlice({
  name: 'generalInfo',
  initialState,
  reducers: {
    setHotelModerationLast: (
      state,
      action: PayloadAction<HotelModeraionLast>
    ) => {
      state.hotelModerationLast = action.payload;
    },
    setRates: (state, action: PayloadAction<Rates>) => {
      state.rates = action.payload;
    },
    setModerationDescriptionList: (
      state,
      action: PayloadAction<ModerationFields>
    ) => {
      state.moderationDescriptionList = action.payload;
    },
  },
});

export const {setHotelModerationLast, setRates, setModerationDescriptionList} =
  generalInfoSlice.actions;

export const selectHotelModerationLast = (state: RootState) => {
  return state.generalInfo.hotelModerationLast;
};

export const selectRates = (state: RootState) => {
  return state.generalInfo.rates;
};

export const selectModerationDescriptionList = (state: RootState) => {
  return state.generalInfo.moderationDescriptionList;
};

export default generalInfoSlice.reducer;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {RootState} from 'source/store';
import {
  DirectoriesBedTypesListResponse,
  DirectoriesRoomFacilityGroupsListResponse,
} from 'types/api-scheme';

import {RoomCategories} from 'slices/add-room';

interface IIntegrationState {
  roomCategories?: RoomCategories;
  bedTypes?: DirectoriesBedTypesListResponse['data'];
  facilities?: DirectoriesRoomFacilityGroupsListResponse['data'];
  roomShorts?: HotelRoomsShorts;
  rates?: Rates;
}

const initialState: IIntegrationState = {
  roomCategories: [],
  bedTypes: [],
  facilities: [],
  roomShorts: [],
  rates: [],
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setRoomCategories: (state, action: PayloadAction<RoomCategories>) => {
      state.roomCategories = action.payload;
    },
    setBedTypes: (
      state,
      action: PayloadAction<DirectoriesBedTypesListResponse['data']>,
    ) => {
      state.bedTypes = action.payload;
    },
    setFacilities: (
      state,
      action: PayloadAction<DirectoriesRoomFacilityGroupsListResponse['data']>,
    ) => {
      state.facilities = action.payload;
    },
    setRoomShorts: (state, action: PayloadAction<HotelRoomsShorts>) => {
      state.roomShorts = action.payload;
    },
    setRates: (state, action: PayloadAction<Rates>) => {
      state.rates = action.payload;
    },
  },
});

export const selectRoomCategories = (state: RootState) =>
  state.integration.roomCategories;

export const selectBedTypes = (state: RootState) => state.integration.bedTypes;

export const selectFacilities = (state: RootState) =>
  state.integration.facilities;

export const selectRoomShorts = (state: RootState) =>
  state.integration.roomShorts;

export const selectRates = (state: RootState) => state.integration.rates;

export const {
  reset,
  setRoomCategories,
  setBedTypes,
  setFacilities,
  setRoomShorts,
  setRates,
} = integrationSlice.actions;

export default integrationSlice.reducer;

import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {toValueOrEmptyArray} from 'source/utilities/array';

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RestrictionItem = styled(Text)`
  font-weight: 500;
  margin-left: 7px;
`;

const Item = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

interface Props {
  constraints?: CalendarRatesConstraints;
}

export const RestrictionTooltip: FC<Props> = ({constraints}) => {
  const {t} = useTranslation('calendar');

  if (!constraints) {
    return null;
  }

  const {stay} = constraints;

  const periods = toValueOrEmptyArray(stay?.periods);
  const daysOfWeek = toValueOrEmptyArray(stay?.day?.days_of_week);
  const minDays = stay?.day?.min_days ?? 0;
  const maxDays = stay?.day?.max_days ?? 0;
  const hasAnyDays = minDays > 0 || maxDays > 0;

  return (
    <TooltipContainer>
      {periods.length > 0 ? (
        <Item>
          {t('restriction_rules.date')}
          {periods.map(({from, to}) => (
            <RestrictionItem size="XS" key={`${from}_${to}`}>
              {t('restriction_rules.from_to', {from, to})}
            </RestrictionItem>
          ))}
        </Item>
      ) : (
        <Item>{t('restriction_rules.no_date')}</Item>
      )}

      {daysOfWeek.length > 0 ? (
        <Item>
          {t('restriction_rules.days')}
          {daysOfWeek.map((day) => {
            const randomId = Math.random() * Date.now();

            return (
              <RestrictionItem size="XS" key={randomId}>
                - {t(`days.${day}`)}
              </RestrictionItem>
            );
          })}
        </Item>
      ) : (
        <Item>{t('restriction_rules.no_days')}</Item>
      )}

      {hasAnyDays ? (
        <Item>
          {t('restriction_rules.length_of_stay')}
          {minDays > 0 && (
            <RestrictionItem size="XS">
              • {t('restriction_rules.min_days')}
              {t('components:days', {count: minDays})}
            </RestrictionItem>
          )}
          {maxDays > 0 && (
            <RestrictionItem size="XS">
              • {t('restriction_rules.max_days')}
              {t('components:days', {count: maxDays})}
            </RestrictionItem>
          )}
        </Item>
      ) : (
        <Item>{t('restriction_rules.no_length_of_stay')}</Item>
      )}
    </TooltipContainer>
  );
};

import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsRatesCreateResponse,
  HotelsRatesDetailResponse,
} from 'types/api-scheme';

type GetRatesContext = {
  hotelId: number;
  headers: Headers;
};
const getRates = ({hotelId, headers}: GetRatesContext) =>
  request<HotelsRatesCreateResponse>(url(`hotels/${hotelId}/rates`), {
    method: 'GET',
    headers,
  }).then(path<Rates>([1, 'data']));

type CreateRateContext = {
  hotelId: number;
};
const createRate = ({hotelId}: CreateRateContext) =>
  request<HotelsRatesDetailResponse>(url(`hotels/${hotelId}/rates`), {
    method: 'POST',
    headers: clientHeaders(),
  }).then(path<Rate>([1, 'data']));

export const ratesApi = {
  get: getRates,
  create: createRate,
};

import {css, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {
  RowBody,
  RowBodyData,
  RowHead,
  RowHeadData,
  TableBoilerplate,
} from 'library/components/table';
import useTranslation from 'next-translate/useTranslation';
import {useAppSelector} from 'source/store';
import {LOADING_STYLES} from 'source/utilities/constants/css';
import {CircularLoader} from 'library/components/loader';
import {selectWithdrawals, selectLoading} from 'slices/balance_details/store';
import {getViewFormatFromBackendDate} from 'source/utilities/dates';
import {getStatusIndicatorColor} from 'slices/balance_details/lib/utils';
import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';

const TableWrapper = styled.div<{isLoading: boolean}>`
  overflow: auto;
  position: relative;

  ${({isLoading}) =>
    isLoading &&
    css`
      ${LOADING_STYLES}

      * {
        pointer-events: none;
      }
    `}
`;

const StyledRowHeadData = styled(RowHeadData)`
  font-weight: 300;
`;

const StyledCircularLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

const WithdrawalRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const WithdrawalColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StatusIndicator = styled.div<{color?: string}>`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  cursor: pointer;

  ${({color}) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

export const BalanceOperationsTable = () => {
  const {t} = useTranslation('balance_details');
  const theme = useTheme();
  const isLoading = useAppSelector(selectLoading);
  const withdrawals = useAppSelector(selectWithdrawals);

  return (
    <TableWrapper isLoading={isLoading}>
      {isLoading && <StyledCircularLoader size={64} variant="main" />}
      <TableBoilerplate
        rowHeadChildren={
          <RowHead>
            <StyledRowHeadData isFirst>
              {t('balance_operations_table.head.id_and_date')}
            </StyledRowHeadData>
            <StyledRowHeadData>
              {t('balance_operations_table.head.amount')}
            </StyledRowHeadData>
            <StyledRowHeadData isLast>
              {t('balance_operations_table.head.status')}
            </StyledRowHeadData>
          </RowHead>
        }
        rowBodyChildren={
          <>
            {withdrawals?.map((withdrawal) => {
              const {created_at, status, amount, id} = withdrawal;

              return (
                <RowBody key={`withdrawal_row_${id}`}>
                  <RowBodyData isFirst>
                    <WithdrawalRow>
                      <Tooltip
                        on="hover"
                        position="top left"
                        minWidth={200}
                        maxWidth={300}
                        trigger={
                          <StatusIndicator
                            color={getStatusIndicatorColor(theme, status)}
                          />
                        }
                      >
                        <Text size="S">{status.title}</Text>
                      </Tooltip>

                      <WithdrawalColumn>
                        <Text size="S">{id}</Text>
                        <Text size="S" color={theme.palette.fontSecondary}>
                          {getViewFormatFromBackendDate(created_at)}
                        </Text>
                      </WithdrawalColumn>
                    </WithdrawalRow>
                  </RowBodyData>
                  <RowBodyData>
                    {amount} {t('common:units_payments.rub')}
                  </RowBodyData>
                  <RowBodyData isLast>
                    <Text
                      size="S"
                      color={getStatusIndicatorColor(theme, status)}
                    >
                      {status.title}
                    </Text>
                  </RowBodyData>
                </RowBody>
              );
            })}
          </>
        }
      />
    </TableWrapper>
  );
};

import {request} from 'library/api';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsImagesDeleteManyPartialUpdateResponse,
  HotelsImagesUpdateOrderPartialUpdateResponse,
  HotelsImagesDeleteResponse,
} from 'types/api-scheme';

type DeleteImagesContext = {
  hotelId: number;
  roomId: number;
  payload: {
    image_ids: number[];
  };
};
const deleteImages = ({hotelId, roomId, payload}: DeleteImagesContext) =>
  request<HotelsImagesDeleteManyPartialUpdateResponse>(
    url(`hotels/${hotelId}/rooms/${roomId}/images/delete-many`),
    {
      method: 'PATCH',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    }
  );

type ReorderImagesContext = {
  hotelId: number;
  roomId: number;
  draggedImageId: number;
  payload: {
    preHotelImageId: number;
  };
};
const reorderImages = ({
  hotelId,
  roomId,
  draggedImageId,
  payload: {preHotelImageId},
}: ReorderImagesContext) =>
  request<HotelsImagesUpdateOrderPartialUpdateResponse>(
    url(
      `hotels/${hotelId}/rooms/${roomId}/images/${draggedImageId}/update-order`
    ),
    {
      method: 'PATCH',
      headers: clientHeaders(),
      body: JSON.stringify({
        pre_hotel_image_id: preHotelImageId || null,
      }),
    }
  );

type DeleteImageContext = {
  hotelId: number;
  imageId: number;
  roomId: number;
};
const deleteImage = ({hotelId, roomId, imageId}: DeleteImageContext) =>
  request<HotelsImagesDeleteResponse>(
    url(`hotels/${hotelId}/rooms/${roomId}/images/${imageId}`),
    {
      method: 'DELETE',
      headers: clientHeaders(),
    }
  );

export const roomImagesApi = {
  deleteMany: deleteImages,
  reorder: reorderImages,
  delete: deleteImage,
};

import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import Popup from 'reactjs-popup';

const Modal = styled(Popup)<{maxWidth: number}>`
  &-content {
    width: 100%;
    max-width: ${({maxWidth}) => (maxWidth ? `${maxWidth}px` : 'unset')};
    padding: 16px 20px;
    background: ${({theme}) => theme.palette.defaultBackground};
    border: 1px solid ${({theme}) => theme.palette.border};
    box-shadow: ${({theme}) => theme.palette.boxShadow} !important;
    border-radius: 6px !important;
    box-sizing: border-box;
    @media (max-width: 350px) {
      padding: 16px 12px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 40px;
`;

const Title = styled(Text)`
  margin-bottom: 20px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 15px;
  width: 130px;
`;

interface Props {
  onEnableIntegration: () => void;
  closeModal: () => void;
  open: boolean;
}

const ConfirmationIntegrationModal: FC<Props> = ({
  closeModal,
  open,
  onEnableIntegration,
}) => {
  const {t} = useTranslation('integration');

  return (
    <Modal
      onClose={closeModal}
      open={open}
      maxWidth={480}
      modal
      closeOnDocumentClick
    >
      <Title size="boldM">{t('modal.confirmation_title')}</Title>
      <ButtonsWrapper>
        <StyledButton buttonType="secondary" onClick={closeModal}>
          {t('modal.cancel')}
        </StyledButton>
        <StyledButton onClick={onEnableIntegration}>
          {t('modal.apply')}
        </StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

export default ConfirmationIntegrationModal;

import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {ArrowText} from 'library/components/arrow-text';
import {Button} from 'library/components/button';
import {Headline} from 'library/components/headline';
import {CircularLoader} from 'library/components/loader';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {equals} from 'ramda';
import {
  FC,
  MouseEvent,
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {HotelContext} from 'source/context/hotel';
import {useAppDispatch, useAppSelector} from 'source/store';
import {showSuccessMessage} from 'source/utilities/exceptions/business';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {
  DirectoriesRoomFacilityGroupsListResponse,
  HotelsRealtyIntegrationsCreateRequest,
  HotelsRoomsDetail2Response,
} from 'types/api-scheme';
import TravelLineProvider from 'source/components/travel-line-provider';
import {RoomCategories} from './lib/types';
import {createRoom, editRoom} from './network';
import {reset, selectIsValid, selectRoom, setRoom} from './store';
import {Amenities} from './ui/amenities';
import {RoomDescriptions} from './ui/room-description';

const Wrapper = styled.div<{isIntegrationPage: boolean}>`
  max-width: 905px;
  margin: 0 auto;
  width: 100%;
  margin-top: 55px;

  ${({isIntegrationPage}) =>
    !isIntegrationPage &&
    css`
      @media (max-width: 930px) {
        margin-top: 26px;
        padding: 0 20px;
      }
    `}
`;

const Title = styled(Headline)`
  margin-bottom: 39px;
`;

const EditTitleWrapper = styled.div`
  margin-bottom: 39px;
`;

const EditName = styled(Text)`
  color: ${({theme}) => theme.palette.primaryAccent};
  cursor: pointer;
  width: fit-content;
  margin-top: 10px;
`;

const EditTitle = styled(Headline)`
  width: 100%;
  word-break: break-word;
`;

const MainContent = styled.div`
  padding-bottom: 40px;
`;

const DescriptionText = styled(ArrowText)`
  margin-bottom: 14px;
`;

const AmenitiesText = styled(ArrowText)``;

const BottomContent = styled.div`
  width: 100vw;
  position: fixed;
  display: flex;
  z-index: 5;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
`;

const ButtonWrapper = styled.div`
  max-width: 100vw;
  width: 100%;
  height: fit-content;
  padding: 10px 0;
  display: flex;
  display: hidden;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.palette.border};
  box-sizing: border-box;
  border-radius: 6px 6px 0 0;
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const Save = styled(Button)`
  min-width: 246px;
  width: fit-content;
  height: 50px;
  padding: 12px 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 400px) {
    width: 100vw;
    border-radius: 0;
    border: none;
  }
`;

const ChildrenWrapper = styled.div`
  margin-top: 20px;
`

interface RoomContentProps {
  isEdit?: boolean;
  bedTypes?: BedTypes;
  roomCategories?: RoomCategories;
  facilities?: DirectoriesRoomFacilityGroupsListResponse['data'];
  room?: HotelsRoomsDetail2Response['data'] | Room;
  translateKey: string;
  type: 'add-room' | 'edit-room';
  onSave?: (room: HotelsRealtyIntegrationsCreateRequest) => void;
  submitButtonLabel?: string;
  className?: string;
  withouTitle?: boolean;
  parentLoading?: boolean;
  isIntegrationPage?: boolean;
  disableScrollOnOpen?: boolean;
}

const Content: FC<RoomContentProps> = ({
  isEdit,
  bedTypes,
  roomCategories,
  facilities,
  room: serverRoom,
  translateKey,
  type,
  onSave,
  submitButtonLabel,
  className,
  withouTitle = false,
  parentLoading = false,
  isIntegrationPage = false,
  disableScrollOnOpen = false,
}) => {
  const dispatch = useAppDispatch();
  const room = useAppSelector(selectRoom);
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const {t} = useTranslation(translateKey);
  const mobile = useDeviceDetection('mobile');
  const [hotel] = useContext(HotelContext);

  const [initialRoom, setInitialRoom] = useState(room);
  const [loading, setLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(true);
  const [amenitiesOpen, setAmenitiesOpen] = useState(true);
  const [resetIsChange, setResetIsChange] = useState(false);

  useEffect(() => {
    if (!isEdit) {
      dispatch(reset());
    }
    if (serverRoom) {
      dispatch(setRoom(serverRoom));
    }
    if (serverRoom && initialRoom.id !== serverRoom.id) {
      setInitialRoom(serverRoom);
    }
  }, [isEdit, serverRoom]);

  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  // проверяет наличие обязательных полей
  const isValid = useAppSelector(selectIsValid);

  const isEditValid =
    isValid && room.id === initialRoom.id && !equals(room, initialRoom);
  const showSubmitButton = isEdit ? isEditValid : isValid;

  const addRoom = (hotelId: number) => {
    createRoom(hotelId, setLoading, room, () => {
      showSuccessMessage(t('room_added'));
      router.push(`/manage/${hotelId}/rooms`);
      setSubmitClicked(true);
    });
  };

  const changeRoom = (hotelId: number, roomId: number) => {
    editRoom(hotelId, roomId, setLoading, room, (updateRoom) => {
      if (updateRoom) {
        dispatch(setRoom(updateRoom));
        showSuccessMessage(t('room_edited'));
        router.push(`/manage/${hotelId}/rooms`);
        setSubmitClicked(true);
      }
    });
  };

  const onEditNameClick = () => {
    setDescriptionOpen(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const submitForm: MouseEventHandler<HTMLButtonElement> | undefined = isEdit
    ? (event) => {
        event.preventDefault();
        if (hotel?.id && serverRoom?.id) {
          changeRoom(hotel.id, serverRoom.id);
        }
      }
    : (event) => {
        event.preventDefault();
        if (hotel?.id) {
          addRoom(hotel.id);
        }
      };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    if (onSave && isIntegrationPage) {
      onSave(room);

      setSubmitClicked(true);
      setResetIsChange(true);

      return;
    }

    submitForm(event);
  };

  useEffect(() => {
    if (!submitClicked) {
      return;
    }

    const timeout = setTimeout(() => {
      if (!isIntegrationPage) {
        return;
      }
      // Для страницы интеграции нам нужно сбрасывать стейт, т.к. нет редиректа после сабмита
      dispatch(reset());
      setResetIsChange(false);
      setSubmitClicked(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [submitClicked]);

  return (
    <Wrapper className={className} isIntegrationPage={isIntegrationPage}>
      {!withouTitle &&
        (isEdit ? (
          <EditTitleWrapper>
            <EditTitle level={mobile ? 'M' : 'XL'}>{room.name}</EditTitle>
            <EditName onClick={onEditNameClick} size="S">
              {t('edit_action')}
            </EditName>
          </EditTitleWrapper>
        ) : (
          <Title level={mobile ? 'M' : 'XL'}>{t('title')}</Title>
        ))}

      <MainContent>
        <DescriptionText
          parentState={descriptionOpen}
          parentHandler={setDescriptionOpen}
          title={t('room_description_title')}
          size="boldL"
          scrollOnOpen={!disableScrollOnOpen}
        >
          <ChildrenWrapper>
            <TravelLineProvider>
              <RoomDescriptions
                ref={inputRef}
                roomCategories={roomCategories}
                bedTypes={bedTypes}
                translateKey={translateKey}
                isIntegrationPage={isIntegrationPage}
                resetIsChange={resetIsChange}
                type={type}
              />
            </TravelLineProvider>
          </ChildrenWrapper>
        </DescriptionText>

        <AmenitiesText
          title={t('amenities_title')}
          parentState={amenitiesOpen}
          parentHandler={setAmenitiesOpen}
          size="boldL"
        >
          {facilities ? (
            <Amenities mobile={mobile} facilities={facilities} />
          ) : null}
        </AmenitiesText>
      </MainContent>
      <BottomContent>
        {showSubmitButton && !submitClicked ? (
          <ButtonWrapper>
            <Save onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <CircularLoader size={32} />
              ) : submitButtonLabel ? (
                t(submitButtonLabel)
              ) : (
                t(isEdit ? 'edit_room_button_label' : 'add_room_button_label')
              )}
            </Save>
          </ButtonWrapper>
        ) : null}
      </BottomContent>
    </Wrapper>
  );
};

export default Content;

import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsServicesUpdateRequest,
  HotelsServicesUpdateResponse,
  HotelsServicesDeleteResponse,
} from 'types/api-scheme';

type GetServiceContext = {
  hotelId: number;
  serviceId: number;
  headers?: HeadersInit;
};
const getService = ({hotelId, serviceId, headers}: GetServiceContext) =>
  request<HotelsServicesUpdateResponse>(
    url(`hotels/${hotelId}/services/${serviceId}`),
    {method: 'GET', headers}
  ).then(path<HotelsServicesUpdateResponse['data']>([1, 'data']));

type EditServiceContext = {
  hotelId: number;
  serviceId: number;
  payload: HotelsServicesUpdateRequest;
};
const editService = ({hotelId, serviceId, payload}: EditServiceContext) =>
  request<HotelsServicesUpdateResponse>(
    url(`hotels/${hotelId}/services/${serviceId}`),
    {
      method: 'PUT',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    }
  ).then(path<HotelsServicesUpdateResponse['data']>([1, 'data']));

type DeleteServiceContext = {
  hotelId: number;
  serviceId: number;
};
const deleteService = ({hotelId, serviceId}: DeleteServiceContext) =>
  request<HotelsServicesDeleteResponse>(
    url(`hotels/${hotelId}/services/${serviceId}`),
    {
      headers: clientHeaders(),
      method: 'DELETE',
    }
  ).then(path<HotelsServicesDeleteResponse['data']>([1, 'data']));

export const serviceApi = {
  get: getService,
  edit: editService,
  delete: deleteService,
};

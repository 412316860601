import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {DatePicker} from 'library/components/date-picker';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {FC, useState} from 'react';
import {DateRangeProps, RangeKeyDict} from 'react-date-range';
import {isRangeValid} from 'slices/calendar/lib/helpers/dates';
import {CALENDAR_RANGE_KEY} from 'source/utilities/constants/logic';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledButton = styled(Button)`
  padding: 10px;
`;

export interface CalendarProps {
  setDates: (dates: {startDate: Date; endDate: Date}) => void;
  onClose: () => void;
}

export const Calendar: FC<CalendarProps> = ({setDates, onClose}) => {
  const {t} = useTranslation('components');
  const router = useRouter();
  const isTouch = useDeviceDetection('touch');
  const [ranges, setRanges] = useState<DateRangeProps['ranges']>([
    {
      key: CALENDAR_RANGE_KEY,
      startDate: new Date(),
      endDate: new Date(),
    },
  ]);
  const firstRange = ranges && ranges[0];

  const onChange = (range: RangeKeyDict) => {
    const {startDate, endDate} = range[CALENDAR_RANGE_KEY];
    setRanges([{startDate, endDate, key: CALENDAR_RANGE_KEY}]);
  };

  const onSelect = () => {
    if (firstRange && firstRange.startDate && firstRange.endDate) {
      setDates({startDate: firstRange.startDate, endDate: firstRange.endDate});
      onClose();
    }
  };

  return (
    <Wrapper>
      <DatePicker
        ranges={ranges}
        onChange={onChange}
        weekdayDisplayFormat="EEEEEE"
        monthDisplayFormat="LLLL"
        months={2}
        showMonthAndYearPickers={false}
        locale={router.locale}
      />

      <StyledButton
        disabled={
          firstRange &&
          firstRange.startDate &&
          firstRange.endDate &&
          !isRangeValid(firstRange.startDate, firstRange.endDate, isTouch)
        }
        onClick={onSelect}
      >
        {t('save')}
      </StyledButton>
    </Wrapper>
  );
};

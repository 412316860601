import type {Redirect} from 'next';
import {isEmpty} from 'ramda';

export enum ExceptionTypes {
  Business = 'Business',
}

export type BusinessException = {
  type: ExceptionTypes.Business;
  message: string;
};

export type ApiException = readonly [Response, {message: string}];

export type RedirectException = {redirect: Redirect};

export type UnhandledException = any;

export const isBusinessException = (reason: any): reason is BusinessException =>
  reason instanceof Object &&
  'type' in reason &&
  'message' in reason &&
  typeof reason.message === 'string' &&
  reason.type === ExceptionTypes.Business;

export const isApiException = (reason: any): reason is ApiException =>
  Array.isArray(reason) &&
  reason[0] instanceof Response &&
  reason[1] instanceof Object &&
  'message' in reason[1] &&
  typeof reason[1].message === 'string';

export const isRedirectException = (reason: any): reason is RedirectException =>
  'redirect' in reason && 'destination' in reason.redirect;

/**
 * Проверяет инициализирована ли ошибка из-за неавторизированного запроса
 * @param reason - объект ошибки, получаемый из request.catch(reason => reason)
 */
export const isUnauthenticatedException = (reason: any) =>
  isApiException(reason) &&
  Array.isArray(reason) &&
  reason[0] instanceof Response &&
  'status' in reason[0] &&
  reason[0].status === 401;

/**
 * @description Helper function to use for example within `cond` function as the default case.
 */
export const isUnhandledException = (value: any): value is UnhandledException =>
  !isEmpty(value);

import {useTheme} from '@emotion/react';

import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {MAX_ROOMS_VALUE} from 'slices/add-room/lib/constants';

import {useAppDispatch, useAppSelector} from 'source/store';

import {patterns} from 'source/utilities/patterns';
import {
  getLabelTranslate,
  getNumberFromInputEvent,
  getPlaceholderTranslate,
  getTranslate,
} from '../../lib/helpers';
import {selectRoomsNumber, setRoomsNumber} from '../../store';

interface IProps {
  translateKey: string;
  resetIsChange: boolean;
}

export const RoomsNumberInput: FC<IProps> = ({translateKey, resetIsChange}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const roomsNumber = useAppSelector(selectRoomsNumber);
  const {t} = useTranslation(translateKey);

  return (
    <LabeledComponent
      color={theme.palette.fontSecondary}
      size="S"
      gap={10}
      text={getLabelTranslate(t, 'rooms_number')}
      subText={t('required')}
      subTextSize="XS"
      subTextColor={theme.palette.danger}
      textAlign="end"
    >
      <Input
        placeholder={getPlaceholderTranslate(t, 'rooms_number')}
        value={String(roomsNumber)}
        required
        resetIsChange={resetIsChange}
        pattern={patterns.compatible('positiveNumber')}
        errorMessage={getTranslate(t, 'enter_number_greater_zero')}
        maxCount={MAX_ROOMS_VALUE}
        onChange={(event) =>
          dispatch(setRoomsNumber(getNumberFromInputEvent(event)))
        }
        label={null}
        type="number"
      />
    </LabeledComponent>
  );
};

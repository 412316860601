import {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {RoomPageProperties} from './lib/types';

export const getRoomPageProperties: GetServerSideProps<
  RoomPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const roomId = transformToNumber(context?.params?.room_slug);

  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () => api.hotel.get({hotelId, headers});

  const requestBedTypes = () => api.directories.getBedTypes({headers});
  const requestRoomCategories = () =>
    api.directories.getRoomCategories({headers});

  const requestFacilities = () =>
    api.directories.getRoomFacilityGroups({headers});

  const requestRoom =
    roomId && hotelId
      ? () =>
          api.room.get({
            hotelId,
            roomId,
            headers,
          })
      : undefined;

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, bedTypes, roomCategories, facilities, room] =
            await Promise.all([
              requestHotel(),
              requestBedTypes(),
              requestRoomCategories(),
              requestFacilities(),
              requestRoom && requestRoom(),
            ]);

          const result = removeUndefined({
            ...properties,
            hotel,
            bedTypes,
            roomCategories,
            facilities,
            room,
          });
          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const createRoom = async (
  hotelId: number,
  setLoading: LoadingDispatch,
  room: Room,
  onComplete: () => void,
) => {
  setLoading(true);
  api.room
    .create({hotelId, payload: room})
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const editRoom = async (
  hotelId: number,
  roomId: number,
  setLoading: LoadingDispatch,
  room: Room,
  onComplete: (updateRoom?: Room) => void,
) => {
  setLoading(true);
  api.room
    .edit({hotelId, roomId, payload: room})
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

import styled from '@emotion/styled';
import {FC, ReactNode} from 'react';
import {PopupPosition} from 'reactjs-popup/dist/types';
import {Icon, IconName} from 'source/components/icon';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {SubText, Text, TextSize} from '../text';
import {Tooltip} from '../tooltip';

const Wrapper = styled.div``;

const HeaderWrapper = styled.div<{textAlign: string}>`
  display: flex;
  justify-content: space-between;
  align-items: ${({textAlign}) => textAlign};
`;

const IconWrapper = styled(Icon)`
  cursor: pointer;
`;

const InformationTooltip = styled(Tooltip)<{isMobile: boolean}>`
  &-content {
    min-width: ${({isMobile}) => (isMobile ? '80%' : '374px')};
  }
`;

const SubTitle = styled(SubText)`
  margin-left: 5px;
  white-space: nowrap;
`;

interface LabeledComponentProps {
  text: string;
  gap: number;
  children: ReactNode;
  className?: string;
  size: TextSize;
  color?: string;
  toolTip?: {
    icon: IconName;
    text: string;
    position?: PopupPosition;
  };
  subText?: string;
  subTextSize?: TextSize;
  textAlign?: string;
  subTextColor?: string;
}

export const LabeledComponent: FC<LabeledComponentProps> = ({
  children,
  gap,
  text,
  className,
  size,
  color,
  toolTip,
  subText,
  subTextSize,
  subTextColor,
  textAlign = 'start',
}) => {
  const mobile = useDeviceDetection('mobile');

  return (
    <Wrapper className={className}>
      <HeaderWrapper textAlign={textAlign}>
        <Text size={size} style={{marginBottom: gap, color}}>
          {text}
        </Text>
        {subText && (
          <SubTitle
            size={subTextSize || size}
            style={{marginBottom: gap}}
            color={subTextColor}
          >
            {subText}
          </SubTitle>
        )}
        {toolTip && (
          <InformationTooltip
            isMobile={mobile}
            on="hover"
            position={
              toolTip.position || (mobile ? 'left center' : 'bottom left')
            }
            trigger={
              <div>
                {toolTip.icon && (
                  <IconWrapper name={toolTip.icon} width={20} height={20} />
                )}
              </div>
            }
          >
            {toolTip.text}
          </InformationTooltip>
        )}
      </HeaderWrapper>
      {children}
    </Wrapper>
  );
};

import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsModerationLastDetailResponse,
  HotelsModerationFieldsDetailResponse,
  HotelsModerationFieldsRequestCreateRequest,
  HotelsModerationFieldsRequestCreateResponse,
} from 'types/api-scheme';
import {ModerationFieldsEnum} from 'slices/moderation';

type GetHotelModerationLastContext = {
  hotelId: number;
  headers?: HeadersInit;
};
const getHotelModerationLast = ({
  hotelId,
  headers,
}: GetHotelModerationLastContext) =>
  request<HotelsModerationLastDetailResponse>(
    url(`hotels/${hotelId}/moderation/last`),
    {
      method: 'GET',
      headers,
    }
  ).then(path<HotelsModerationLastDetailResponse['data']>([1, 'data']));

type GetDescriptionsContext = {
  hotelId: number;
  headers?: HeadersInit;
};
const getDescriptions = ({hotelId, headers}: GetDescriptionsContext) =>
  request<HotelsModerationFieldsDetailResponse>(
    url(`hotels/${hotelId}/moderation-fields`, {
      field_type: ModerationFieldsEnum.DESCRIPTION,
    }),
    {method: 'GET', headers}
  ).then(path<HotelsModerationFieldsDetailResponse['data']>([1, 'data']));

type CreateDescriptionContext = {
  hotelId: number;
  payload: {
    field_type: string;
    user_comment: string;
    locale: string;
  };
};
const createDescription = ({hotelId, payload}: CreateDescriptionContext) =>
  request<HotelsModerationFieldsRequestCreateRequest>(
    url(`hotels/${hotelId}/moderation-fields/request`),
    {
      method: 'POST',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    }
  ).then(
    path<HotelsModerationFieldsRequestCreateResponse['data']>([1, 'data'])
  );

export const moderationApi = {
  getLast: getHotelModerationLast,
  get: getDescriptions,
  create: createDescription,
};

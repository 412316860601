import * as Sentry from '@sentry/nextjs';
import type {Redirect} from 'next';
import Router from 'next/router';
import {path} from 'ramda';

import {toast} from 'react-toastify';

import {isClient, isServer} from 'source/utilities/guards/environment';
import type {
  ApiException,
  RedirectException,
} from 'source/utilities/guards/exceptions';

const showException = (message: string) => {
  if (isClient()) {
    toast.error(message);
  }

  if (isServer()) {
    console.error(message);
  }
};

/**
 * Вызывает ошибку, и возвращает объект с обязательными свойствами, для возврата их через .catch в getServerSideProps
 * @param redirect - объект с данными о редиректе (путь, статус код, и т.д.)
 * @param properties - свойства, передаваемые в компоненты с сервера (в основном используется для _app.tsx)
 */
export const throwRedirectException =
  (redirect: Redirect, properties?: object) => () => {
    if (isClient()) {
      Router.push(redirect.destination);
    }
    throw {redirect, props: properties || {}};
  };

export const handleApiException = <Exception extends ApiException>(
  exception: Exception,
) => {
  const errorMessage = path([1, 'message'], exception);

  showException(errorMessage);

  throw JSON.stringify(errorMessage, null, 2);
};

export const handleRedirectException = <Exception extends RedirectException>(
  exception: Exception,
) => exception;

export const throwSentryObjectError = (
  object?: object | null,
  message?: string,
) => {
  const stringifiedObject = JSON.stringify(object, null, 2);
  Sentry.captureException(
    message ? `${message}: ${stringifiedObject}` : stringifiedObject,
  );
};

export const sendSentryMessage = (message: string) => {
  Sentry.captureMessage(message);
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  CreateHotelierObjectDirectories,
  InitialCreateHotelierObjectState,
} from 'slices/create_hotelier_object/lib/types';
import {RootState} from 'source/store';

const initialState: InitialCreateHotelierObjectState = {
  hotel_type_id: null,
  address: '',
  email: '',
  lat: 0,
  lng: 0,
  phone: '',
  translations: [],
  directories: {},
};

const createHotelierObjectSlice = createSlice({
  name: 'create_hotelier_object',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setHotelTypeId: (state, action: PayloadAction<any>) => {
      state.hotel_type_id = action.payload;
    },
    setAddress: (state, action: PayloadAction<any>) => {
      state.address = action.payload;
    },
    setEmail: (state, action: PayloadAction<any>) => {
      state.email = action.payload;
    },
    setLat: (state, action: PayloadAction<any>) => {
      state.lat = action.payload;
    },
    setLng: (state, action: PayloadAction<any>) => {
      state.lng = action.payload;
    },
    setPhone: (state, action: PayloadAction<any>) => {
      state.phone = action.payload;
    },
    setTranslations: (state, action: PayloadAction<any>) => {
      state.translations = action.payload;
    },
    setDirectories: (
      state,
      action: PayloadAction<CreateHotelierObjectDirectories['directories']>
    ) => {
      state.directories = action.payload;
    },
  },
});

export const {
  reset,
  setHotelTypeId,
  setAddress,
  setEmail,
  setLat,
  setLng,
  setPhone,
  setTranslations,
  setDirectories,
} = createHotelierObjectSlice.actions;

export const selectCreateHotelierObject = (state: RootState) => ({
  ...state.createHotelierObject,
});

export default createHotelierObjectSlice.reducer;

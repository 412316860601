import {css, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {CircularLoader} from 'library/components/loader';

import {Select} from 'library/components/select';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {FC, useState} from 'react';
import {INITIAL_PAGE} from 'slices/bookings/lib/constants';
import {
  getStatusIndicatorColor,
  getTableBody,
  getTableHeader,
  parseProviderName,
} from 'slices/bookings/lib/helpers';

import {
  selectBookings,
  selectDirectories,
  selectFilters,
  setSort,
  setSortDirection,
} from 'slices/bookings/store';
import {Icon} from 'source/components/icon';
import {useAppDispatch, useAppSelector} from 'source/store';
import {getLastIndex} from 'source/utilities/array';
import {SortsDirection} from 'source/utilities/business';
import {parseCurrency} from 'source/utilities/currencies';
import {getViewFormatFromBackendDate} from 'source/utilities/dates';
import {
  getDirectoryOptionByValue,
  transformDirectory,
} from 'source/utilities/directories';
import {formatMoney} from 'source/utilities/strings';
import {BookingsListParameters} from 'types/api-scheme';

const Wrapper = styled.div``;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  border-top: 1px solid ${({theme}) => theme.palette.border};
  margin-top: 21px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.fontSecondary};
`;

const RowHead = styled.tr``;

const RowHeadData = styled.td<{isFirst?: boolean; isLast?: boolean}>`
  padding: ${({isFirst, isLast}) =>
    isLast ? '22px 0 22px 22px' : isFirst ? '22px 22px 22px 0px' : '22px 22px'};
  text-align: ${({isLast}) => (isLast ? 'end' : 'start')};
`;

const TableBody = styled.tbody`
  color: ${({theme}) => theme.palette.fontDefault};
`;

const RowBody = styled.tr`
  cursor: pointer;
  &:hover {
    background: ${({theme}) => theme.palette.border};
    transition: background-color 300ms linear;
  }
`;

const RowBodyData = styled.td<{
  isFirst?: boolean;
  hideBorder?: boolean;
  isLast?: boolean;
}>`
  font-family: ${({theme}) => theme.fontFamily.NOTO_SANS_MONO};
  padding: ${({isFirst, isLast}) =>
    isLast
      ? '22px 5px 22px 22px'
      : isFirst
      ? '22px 22px 22px 5px'
      : '22px 22px'};
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  font-weight: 300;
  font-size: 16px;
  white-space: nowrap;
  line-height: 24px;
  text-align: ${({isLast}) => (isLast ? 'end' : 'start')};
  &:last-child {
    border-right: 0;
  }
`;

const AgreementIndicator = styled.div<{color?: string}>`
  width: 12px;
  height: 12px;
  border-radius: 100%;

  ${({color}) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

const BookingNumberCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const BookingNumberWrapper = styled.div``;
const BookingNumber = styled(Text)`
  color: ${({theme}) => theme.palette.fontDefault};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`;
const BookingDate = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`;

const StyledSelect = styled(Select)`
  & input {
    height: 40px;
  }
`;

const Label = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: start;
  }
`;

const SortingWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  @media (max-width: 480px) {
    align-items: flex-end;
    -webkit-align-items: flex-end;
    justify-content: space-between;
  }
`;

const SortButton = styled.div`
  cursor: pointer;
  width: 46px;
  height: 40px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.palette.border_3};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AdditionalGuest = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;

const LoadMoreButton = styled(Button)``;

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const Empty = styled(Text)``;

interface BookingsTableProps {
  onBookingsSearch: (
    page: number,
    filters: BookingsListParameters,
  ) => Promise<void>;
}
export const BookingsTable: FC<BookingsTableProps> = ({onBookingsSearch}) => {
  const {t} = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const bookings = useAppSelector(selectBookings);
  const filters = useAppSelector(selectFilters);
  const directories = useAppSelector(selectDirectories);

  const haveMoreBookings = bookings
    ? bookings.meta.current_page !== bookings.meta.last_page
    : false;
  const isEmpty = bookings?.data.length === 0 && !haveMoreBookings;
  const sortOptions = transformDirectory(directories.bookingSorts);

  const handleSortDirection = () => {
    const sortDirection =
      filters.sort_direction === SortsDirection.ASC
        ? SortsDirection.DESC
        : SortsDirection.ASC;
    dispatch(setSortDirection(sortDirection));
    onBookingsSearch(INITIAL_PAGE, {...filters, sort_direction: sortDirection});
  };

  const handleLoadMore = () => {
    setLoading(true);
    onBookingsSearch(filters.page + 1, filters).finally(() =>
      setLoading(false),
    );
  };

  return (
    <Wrapper>
      <SortingWrapper>
        <SelectWrapper>
          <Label size="S">{t('bookings:sort_label')}</Label>
          <StyledSelect
            options={sortOptions}
            value={getDirectoryOptionByValue(filters.sort, sortOptions)}
            setValue={(option) => {
              dispatch(setSort(option.value));
              onBookingsSearch(INITIAL_PAGE, {...filters, sort: option.value});
            }}
          />
        </SelectWrapper>
        <SortButton onClick={handleSortDirection}>
          <Icon
            name={
              filters.sort_direction === SortsDirection.ASC
                ? 'sort-asc'
                : 'sort-desc'
            }
            width={26}
            height={15}
          />
        </SortButton>
      </SortingWrapper>
      <TableWrapper>
        <Table>
          <TableHead>
            <RowHead>
              <RowHeadData isFirst>
                {getTableHeader(t, 'booking_number')}
              </RowHeadData>
              <RowHeadData>{getTableHeader(t, 'hotel_name')}</RowHeadData>
              <RowHeadData>{getTableHeader(t, 'room_name')}</RowHeadData>
              <RowHeadData>{getTableHeader(t, 'checkin_checkout')}</RowHeadData>
              <RowHeadData>{getTableHeader(t, 'guests')}</RowHeadData>
              <RowHeadData>{getTableHeader(t, 'amount')}</RowHeadData>
              <RowHeadData>{getTableHeader(t, 'payment')}</RowHeadData>
              <RowHeadData isLast>{getTableHeader(t, 'status')}</RowHeadData>
            </RowHead>
          </TableHead>
          <TableBody>
            {bookings && bookings.data.length > 0
              ? bookings.data.map((booking) => {
                  const {
                    payment_type,
                    checkin,
                    checkout,
                    amount,
                    created_at,
                    currency,
                    guests,
                    hotel,
                    status,
                    main_id,
                    provider_booking,
                    room,
                  } = booking;

                  const formattedPrice = formatMoney(amount);
                  const additionalGuests = getLastIndex(guests);
                  const hasAdditionalGuests = additionalGuests > 0;
                  const providerName = parseProviderName(
                    provider_booking?.provider.title,
                    provider_booking?.provider.value,
                  );

                  return (
                    <RowBody
                      key={main_id}
                      onClick={() =>
                        router.push(
                          `bookings/${main_id}?hotel_id=${hotel?.id}`,
                        )
                      }
                    >
                      <RowBodyData isFirst>
                        <BookingNumberCellWrapper>
                          <AgreementIndicator
                            color={getStatusIndicatorColor(theme, status)}
                          />
                          <BookingNumberWrapper>
                            <BookingNumber size="S">{main_id}</BookingNumber>
                            {providerName ? (
                              <BookingNumber size="S">
                                {providerName}
                              </BookingNumber>
                            ) : null}
                            <BookingDate size="S">
                              {getViewFormatFromBackendDate(created_at)}
                            </BookingDate>
                          </BookingNumberWrapper>
                        </BookingNumberCellWrapper>
                      </RowBodyData>
                      <RowBodyData>{hotel?.name || '-'}</RowBodyData>
                      <RowBodyData>{room?.name || '-'}</RowBodyData>
                      <RowBodyData>
                        {getTableBody(t, 'checkin_checkout', {
                          checkin: getViewFormatFromBackendDate(checkin),
                          checkout: getViewFormatFromBackendDate(checkout),
                        })}
                      </RowBodyData>
                      <RowBodyData>
                        {guests[0].last_name}{' '}
                        {hasAdditionalGuests ? (
                          <AdditionalGuest size="S">
                            {getTableBody(t, 'guests', {
                              guests_number: additionalGuests,
                            })}
                          </AdditionalGuest>
                        ) : null}
                      </RowBodyData>
                      <RowBodyData>
                        {getTableBody(t, 'price', {
                          price: formattedPrice,
                          currency: parseCurrency(t, currency),
                        })}
                      </RowBodyData>
                      <RowBodyData>{payment_type.title}</RowBodyData>
                      <RowBodyData isLast>{status.title}</RowBodyData>
                    </RowBody>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableWrapper>
      {haveMoreBookings ? (
        <LoadMoreWrapper>
          <LoadMoreButton disabled={loading} onClick={handleLoadMore}>
            {loading ? <CircularLoader size={32} /> : t('bookings:load_more')}
          </LoadMoreButton>
        </LoadMoreWrapper>
      ) : null}
      {isEmpty ? (
        <EmptyWrapper>
          <Empty size="boldS">{t('bookings:not_found')}</Empty>
        </EmptyWrapper>
      ) : null}
    </Wrapper>
  );
};

import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsPolicyDetailResponse,
  HotelsPolicyPartialUpdateResponse,
  HotelsPolicyPartialUpdateRequest,
} from 'types/api-scheme';

type GetPolicyContext = {
  hotelId: number;
};
const getPolicy = ({hotelId}: GetPolicyContext) =>
  request<HotelsPolicyDetailResponse>(url(`hotels/${hotelId}/policy`), {
    method: 'GET',
    headers: clientHeaders(),
  }).then(path<HotelsPolicyDetailResponse['data']>([1, 'data']));

type CreatePolicyContext = {
  hotelId: number;
  payload: HotelsPolicyPartialUpdateRequest;
};
const createPolicy = ({hotelId, payload}: CreatePolicyContext) =>
  request<HotelsPolicyPartialUpdateResponse>(url(`hotels/${hotelId}/policy`), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsPolicyPartialUpdateResponse['data']>([1, 'data']));

type UpdatePolicyContext = {
  hotelId: number;
  payload: HotelsPolicyPartialUpdateRequest;
};
const updatePolicy = ({hotelId, payload}: UpdatePolicyContext) =>
  request<HotelsPolicyPartialUpdateResponse>(url(`hotels/${hotelId}/policy`), {
    method: 'PATCH',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsPolicyPartialUpdateResponse['data']>([1, 'data']));

export const policyApi = {
  get: getPolicy,
  create: createPolicy,
  update: updatePolicy,
};

import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {FC, useState} from 'react';

const Wrapper = styled.div<{isActive: boolean; disabled?: boolean}>`
  width: 36px;
  min-width: 36px;
  height: 21px;
  cursor: pointer;
  border-radius: 11px;
  background-color: ${({theme, isActive}) =>
    isActive ? theme.palette.primaryAccent : theme.palette.fontSecondary};
  transition: 0.3s;

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.4;
    `}
`;

const Knob = styled.div<{isActive: boolean}>`
  width: 18px;
  height: 18px;
  background-color: ${({theme}) => theme.palette.fontAccent};
  border-radius: 100%;
  transform: ${({isActive}) =>
    isActive ? 'translate(16px, 1px)' : 'translate(1.5px, 1px)'};
  transition: 0.3s;
`;

interface SwitchProps {
  onSwitch?: (...arguments_: any[]) => Promise<void>;
  className?: string;
  initialState?: boolean;
  isActive?: boolean;
  disabled?: boolean;
}

export const Switch: FC<SwitchProps> = ({
  onSwitch,
  className,
  initialState,
  isActive,
  disabled,
}) => {
  const [enabled, setEnabled] = useState(initialState || false);
  return (
    <Wrapper
      className={className}
      disabled={disabled}
      onClick={
        onSwitch
          ? () => {
              if (disabled) {
                return;
              }

              const result = onSwitch(!enabled);
              if (result instanceof Promise) {
                result.then(() => setEnabled(!enabled));
              } else {
                setEnabled(!enabled);
              }
            }
          : undefined
      }
      isActive={isActive ?? enabled}
    >
      <Knob isActive={isActive ?? enabled} />
    </Wrapper>
  );
};

import {Dispatch, SetStateAction} from 'react';

export enum FormStateEnum {
  PASSWORD_REQUEST = 'PASSWORD_REQUEST',
  PASSWORD_RESET = 'PASSWORD_RESET',
}

export enum PASSWORD_INPUT_TYPE {
  PASSWORD = 'password',
  TEXT = 'text',
}

export enum PASSWORD_ICON_TYPE {
  PASSWORD = 'eye',
  TEXT = 'eye_close',
}

export type FormStateDispatch = Dispatch<SetStateAction<FormStateEnum>>;

import {FC, createContext, useState} from 'react';

export type ScrollState = State<number>;

export const ScrollContext = createContext<ScrollState>([0, () => {}]);

export const ScrollProvider: FC<ServerlessProviderProperties<ScrollState>> = ({
  ...properties
}) => {
  const state = useState<ContextValue<ScrollState>>(0);

  return <ScrollContext.Provider value={state} {...properties} />;
};

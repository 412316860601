import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';

import {Text} from 'library/components/text';
import HumanIcon from 'source/components/icon/assets/human.svg';
import {isNumber} from 'source/utilities/guards/types';

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RestrictionTitle = styled(Text)`
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const RestrictionItem = styled(RestrictionTitle)`
  margin-left: 7px;
`;

const Item = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

const GuestNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Human = styled(HumanIcon)`
  width: 15px;
  height: 15px;
`;

interface Props {
  realtyConstraint?: CalendarRealtyConstraint | null;
  rateId: Rate['id'];
  dayDate: Date;
}

export const RealtyRestrictionTooltip: FC<Props> = ({
  realtyConstraint,
  rateId,
  dayDate,
}) => {
  const {t} = useTranslation('calendar');

  if (!realtyConstraint) {
    return null;
  }

  return (
    <TooltipContainer>
      {realtyConstraint.by_guests_number?.map((guestNumber) => {
        const {min_stay, guests_number} = guestNumber;

        if (!isNumber(min_stay)) {
          return null;
        }

        return (
          <Item key={`bnovo_constraint_rate_${rateId}_date_${dayDate}`}>
            <GuestNumberWrapper>
              <RestrictionTitle size="boldXS">
                {t('restriction_rules.bnovo.guest_number')}
              </RestrictionTitle>
              <RestrictionTitle size="boldXS">
                {`${guests_number}x`}
                <Human />
              </RestrictionTitle>
            </GuestNumberWrapper>
            {isNumber(min_stay) && (
              <RestrictionItem size="XS">
                - {t('restriction_rules.bnovo.min_stay', {count: min_stay})}
              </RestrictionItem>
            )}
          </Item>
        );
      })}
    </TooltipContainer>
  );
};

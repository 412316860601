import styled from '@emotion/styled';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Select} from 'library/components/select';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import {Textarea} from 'library/components/textarea';
import useTranslation from 'next-translate/useTranslation';
import {useState} from 'react';
import {
  buildPetsAllowedDescription,
  getPetsDirectoriesTypeOptions,
  getPetsPlacementTypeOption,
  isPetsDescriptionValueExist,
} from 'slices/policies/lib/helpers';
import {
  DEFAULT_DESCRIPTION_VALUE,
  initialPoliciesState,
} from 'slices/policies/lib/mocks';
import {
  clearPetsDescription,
  selectPolicies,
  setPetsAmount,
  setPetsDescription,
  setPetsDescriptions,
  setPetsType,
} from 'slices/policies/store';
import { DEFAULT_MAX_TEXT_SIZE, MAX_PRICE } from 'slices/rate/lib/constants'
import {CheckboxButton} from 'slices/rate/ui/checkbox-button';
import {useAppDispatch, useAppSelector} from 'source/store';
import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {ELanguage} from 'source/utilities/language';
import {EAccessWithAnimalType} from '../../lib/types';

const InputsWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({isMobile}) => (isMobile ? '100%' : '440px')};
`;

const InputContainer = styled.div<{isMobile: boolean}>`
  max-width: 420px;
  margin: ${({isMobile}) =>
    isMobile ? '0px 0px 20px 0px' : '0px 20px 0px 0px'};
`;

const CheckboxButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

const Label = styled(LabeledComponent)`
  margin-bottom: 26px;
`;

const SwitchInOutAroundClock = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 13px;
`;

const SwitchLabel = styled(Text)`
  margin-right: 18px;
`;

const WithAnimals = () => {
  const dispatch = useAppDispatch();
  const {
    directories,
    policies: {pets_allowed},
  } = useAppSelector(selectPolicies);
  const {t} = useTranslation('policies');

  const isDescriptionValueExist = isPetsDescriptionValueExist(
    pets_allowed?.description,
  );
  const description = buildPetsAllowedDescription(pets_allowed?.description);
  const petsPlacementTypeOption = getPetsPlacementTypeOption(
    directories.petsAllowedType || [],
    pets_allowed?.type,
  );

  const [selectLanguage, setSelectLanguage] = useState<
    (typeof ELanguage)[keyof typeof ELanguage]
  >(ELanguage.RU);

  const [isPlacementFree, setPlacementFree] = useState<boolean>(
    (pets_allowed?.amount ?? 0) <= 0,
  );
  const [openDescription, setOpenDescription] = useState<boolean>(
    isDescriptionValueExist,
  );

  const mobile = useDeviceDetection('mobile');

  const onDescriptionSwitch = async (enabled: boolean) => {
    const initialPetsDescription =
      initialPoliciesState.pets_allowed?.description;
    setOpenDescription(enabled);
    if (!enabled) {
      dispatch(clearPetsDescription());
    }
    if (
      enabled &&
      pets_allowed?.description?.length === 0 &&
      initialPetsDescription
    ) {
      dispatch(setPetsDescriptions(initialPetsDescription));
    }
  };

  return (
    <InputsWrapper isMobile={mobile}>
      <Label
        gap={11}
        size="S"
        color={theme.palette.fontSecondary}
        text={t('with-animals.accommodation-options')}
      >
        <InputContainer isMobile={mobile}>
          <Select
            value={petsPlacementTypeOption}
            options={getPetsDirectoriesTypeOptions(directories.petsAllowedType)}
            setValue={(option) => dispatch(setPetsType(option))}
          />
        </InputContainer>
      </Label>
      {petsPlacementTypeOption.value !== EAccessWithAnimalType.IMPOSSIBLE && (
        <>
          <InputContainer isMobile={mobile}>
            <SwitchInOutAroundClock>
              <SwitchLabel size="M">{t('with-animals.try-free')}</SwitchLabel>
              <Switch
                initialState={isPlacementFree}
                isActive={isPlacementFree}
                onSwitch={async (enabled) => {
                  setPlacementFree(enabled);
                  dispatch(setPetsAmount(enabled ? 0 : 1));
                }}
              />
            </SwitchInOutAroundClock>
          </InputContainer>
          {!isPlacementFree && (
            <Label
              gap={11}
              size="S"
              color={theme.palette.fontSecondary}
              text={t('with-animals.accommodation-fee')}
            >
              <InputContainer isMobile={mobile}>
                <Input
                  value={
                    pets_allowed?.amount === -1
                      ? ''
                      : String(pets_allowed?.amount)
                  }
                  onChange={({target}) =>
                    dispatch(setPetsAmount(Number(target.value)))
                  }
                  type="number"
                  placeholder={t('input-common.amount', {
                    currency: t('common:units_payments.rub'),
                  })}
                />
              </InputContainer>
            </Label>
          )}
          <InputContainer isMobile={mobile}>
            <SwitchInOutAroundClock>
              <SwitchLabel size="M">
                {t('with-animals.feature-placement')}
              </SwitchLabel>
              <Switch
                initialState={openDescription}
                isActive={openDescription}
                onSwitch={onDescriptionSwitch}
              />
            </SwitchInOutAroundClock>
          </InputContainer>
          {openDescription && (
            <>
              <InputContainer isMobile={mobile}>
                <CheckboxButtonWrapper>
                  <CheckboxButton
                    isSelect={selectLanguage === ELanguage.RU}
                    onClick={() => setSelectLanguage(ELanguage.RU)}
                  >
                    {t('check-in-out.placement-language.ru')}
                  </CheckboxButton>
                  <CheckboxButton
                    isSelect={selectLanguage === ELanguage.EN}
                    onClick={() => setSelectLanguage(ELanguage.EN)}
                  >
                    {t('check-in-out.placement-language.en')}
                  </CheckboxButton>
                </CheckboxButtonWrapper>
              </InputContainer>
              <InputContainer isMobile={mobile}>
                {description?.map((description) => {
                  if (description.locale === selectLanguage) {
                    return (
                      <Textarea
                        key={`pets-${description.locale}`}
                        value={
                          description?.value === DEFAULT_DESCRIPTION_VALUE
                            ? ''
                            : description.value ?? ''
                        }
                        onChange={({target: {value}}) => {
                          if (value.length <= DEFAULT_MAX_TEXT_SIZE) {
                            dispatch(
                              setPetsDescription({
                                value,
                                locale: description.locale,
                              }),
                            );
                          }
                        }}
                        maxCount={DEFAULT_MAX_TEXT_SIZE}
                        onClear={() =>
                          dispatch(
                            setPetsDescription({
                              value: '',
                              locale: description.locale,
                            }),
                          )
                        }
                        placeholder={t(
                          'with-animals.rules-placement-placeholder',
                        )}
                      />
                    );
                  }
                })}
              </InputContainer>
            </>
          )}
        </>
      )}
    </InputsWrapper>
  );
};

export default WithAnimals;

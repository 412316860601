import {useTheme} from '@emotion/react';
import {Dispatch, FC, SetStateAction, memo} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {LabeledComponent} from 'library/components/labeled-component';
import {Option, Select} from 'library/components/select';

import {useAppDispatch} from 'source/store';
import {UNDEFINED_CATEGORY} from '../../lib/constants';
import {setCategory} from '../../store';
import {
  getLabelTranslate,
  getPossibleSubCategories,
  getRoomTypeOption,
  getRoomTypesOptions,
} from '../../lib/helpers';
import {ClientRoomCategory, RoomCategories} from '../../lib/types';

interface IProps {
  translateKey: string;
  roomCategory: ClientRoomCategory;
  possibleRoomTypes: RoomCategories;
  roomCategories?: RoomCategories;
  setRoomCategory: Dispatch<SetStateAction<ClientRoomCategory>>;
  disabled?: boolean;
}

export const TypeSelect: FC<IProps> = ({
  roomCategory,
  possibleRoomTypes,
  translateKey,
  roomCategories,
  setRoomCategory,
  disabled = false,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {t} = useTranslation(translateKey);
  const roomTypesOptions = getRoomTypesOptions(possibleRoomTypes);

  const handleSelectRoomType = (option: Option) => {
    const newSubCategory =
      getPossibleSubCategories(
        roomCategory.category,
        option.value,
        roomCategories,
      )?.[0]?.id || UNDEFINED_CATEGORY;
    if (newSubCategory === UNDEFINED_CATEGORY) {
      dispatch(setCategory(option.value));
    } else {
      dispatch(setCategory(newSubCategory));
    }
    setRoomCategory({
      ...roomCategory,
      roomType: option.value,
      subCategory: newSubCategory,
    });
  };

  return (
    <LabeledComponent
      color={theme.palette.fontSecondary}
      size="S"
      gap={10}
      text={getLabelTranslate(t, 'type')}
    >
      <Select
        value={getRoomTypeOption(roomCategory.roomType, possibleRoomTypes)}
        options={roomTypesOptions}
        setValue={handleSelectRoomType}
        disabled={disabled}
      />
    </LabeledComponent>
  );
};

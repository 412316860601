import {replace} from 'ramda';
import {ZHILIBYLI_URL} from 'source/utilities/guards/environment';
import {Routes} from 'source/utilities/network/url';

export const isHotelPage = (pathname: string) =>
  pathname.startsWith(`${Routes.manage}/`) &&
  pathname.split(`${Routes.manage}/`)[1].length > 0 &&
  !pathname.includes(`${Routes.manage}/create`) &&
  !pathname.includes(`${Routes.manage}/profile`);

export const isErrorPage = (pathname: string) =>
  pathname.startsWith(Routes.notFoundPage) ||
  pathname.startsWith(Routes.serverErrorPage);

export const isNotRedirectPage = (pathname: string) =>
  pathname.startsWith(Routes.authorization) ||
  pathname.startsWith(Routes.registration) ||
  pathname.startsWith(Routes.home);

export const buildEditRoomRoute = (hotelId: Hotel['id'], roomId: Room['id']) =>
  `/manage/${hotelId}/rooms/${roomId}/edit`;

export const buildManageHomeRoute = (hotelId: Hotel['id']) =>
  `/manage/${hotelId}/home`;

export const buildAddRoomRoute = (hotelId: Hotel['id']) =>
  `/manage/${hotelId}/rooms/add`;

export const buildRoomsRoute = (hotelId: Hotel['id']) =>
  `/manage/${hotelId}/rooms`;

export const buildEditRateRoute = (hotelId: Hotel['id'], rateId?: Rate['id']) =>
  `/manage/${hotelId}/rates/${rateId}`;

export const buildBalanceDetailsRoute = (
  organizationId: ExistingRequisitesItem['id'],
) => `/balance/${organizationId}`;

export const getActiveLink = (
  href: string,
  pathname: string,
  isActive?: boolean,
) => {
  return isActive ?? replace(/\d+/, '[hotel_slug]', href) === pathname;
};

export const getBookingURLWithPath = (pathname: `/${string}`) =>
  `${ZHILIBYLI_URL}${pathname}`;

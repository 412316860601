import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {HorizontalDivier} from 'library/components/divider';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {pathOr, pipe, when} from 'ramda';
import {FC, FormEvent, useContext, useState} from 'react';
import {MAX_HOTEL_NAME_LENGTH} from 'slices/general_info/lib/constants';
import {HotelContext} from 'source/context/hotel';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {ELanguage} from 'source/utilities/language';
import {handleHotelUpdate} from '../../network';

const Wrapper = styled.div`
  padding: 20px 24px;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Title = styled(Text)`
  margin-bottom: 3px;
`;

const Subtitle = styled(Text)`
  margin-bottom: 11px;
  max-width: 420px;
`;

const StyledInput = styled(Input)`
  max-width: 440px;
  margin-bottom: 27px;
  & > input {
    height: 92px;
    padding-top: 0;
  }
`;

const HotelImage = styled.img`
  width: 100px;
  height: 100px;
  border: 1px solid ${({theme}) => theme.palette.border};
  border-radius: 6px;
  object-fit: cover;
  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const HotelImageTemplate = styled.div`
  min-width: 100px;
  min-height: 100px;
  background-color: ${({theme}) => theme.palette.fontSecondary};
  position: relative;
  border: 1px solid ${({theme}) => theme.palette.border};
  border-radius: 6px;
  object-fit: cover;
  @media (max-width: 480px) {
    min-width: 60px;
    min-height: 60px;
  }
`;

const HotelInfo = styled.div`
  padding: 12px 20px;
  @media (max-width: 480px) {
    padding: 12px 0;
  }
`;

const Preview = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 38px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const Labeled = styled(LabeledComponent)`
  margin-top: 36px;
`;

const LabeledDivider = styled(LabeledComponent)`
  margin-bottom: 16px;
`;

const HotelTitle = styled(Text)`
  margin-bottom: 10px;
`;

const Address = styled(Text)`
  color: ${({theme}) => theme.palette.primaryAccent};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 26px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const Cancel = styled(Button)`
  width: 172px;
  height: 58px;
  text-transform: uppercase;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Save = styled(Button)`
  width: 181px;
  height: 58px;
  text-transform: uppercase;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const EditNameContent: FC<{
  closeAction: () => void;
  setIsNameModerationRequestedState: (value: boolean) => void;
}> = ({closeAction, setIsNameModerationRequestedState}) => {
  const [hotel, setHotel] = useContext(HotelContext);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(hotel?.name || '');
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation();

  const handleSave = (event: FormEvent<HTMLFormElement>) => {
    if (hotel) {
      handleHotelUpdate(
        hotel,
        {translations: [{locale: ELanguage.RU, name: input}]},
        () => {
          closeAction();
          setIsNameModerationRequestedState(true);
        },
        setLoading,
        setHotel,
      )(event);
    }
  };

  return (
    <Wrapper>
      <Title size={mobile ? 'boldM' : 'L'}>
        {t('hotel-info:edit_name_title')}
      </Title>
      <Subtitle size="XS">{t('hotel-info:edit_name_subtitle')}</Subtitle>
      <form onSubmit={handleSave}>
        <Labeled gap={10} text={t('hotel-info:name_label')} size="XS">
          <StyledInput
            onChange={pipe(
              pathOr('', ['target', 'value']),
              when((value) => value.length <= MAX_HOTEL_NAME_LENGTH, setInput),
            )}
            label=""
            type="text"
            maxCount={MAX_HOTEL_NAME_LENGTH}
            value={input}
          />
        </Labeled>
        <LabeledDivider gap={11} text={t('hotel-info:preview')} size="XS">
          <HorizontalDivier />
        </LabeledDivider>
        <Preview>
          {hotel?.main_image?.url ? (
            <HotelImage src={hotel?.main_image?.url} alt="hotel-image" />
          ) : (
            <HotelImageTemplate />
          )}
          <HotelInfo>
            <HotelTitle size="boldXS">{input}</HotelTitle>
            <Address size="XS">{hotel?.address}</Address>
          </HotelInfo>
        </Preview>
        <ButtonsWrapper>
          <Cancel type="button" buttonType="secondary" onClick={closeAction}>
            {t('components:cancel')}
          </Cancel>
          <Save type="submit" disabled={loading}>
            {loading ? t('components:loading') : t('components:save')}
          </Save>
        </ButtonsWrapper>
      </form>
    </Wrapper>
  );
};

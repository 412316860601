import {useTheme, css} from '@emotion/react';
import styled from '@emotion/styled';
import {Badge} from 'library/components/badge';
import useTranslation from 'next-translate/useTranslation';
import {FC, PropsWithChildren} from 'react';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';

const Wrapper = styled.div``;

const InformationWrapper = styled.div``;

const StyledBadge = styled(Badge)`
  margin-bottom: 5px;
`;

const ChildrenWrapper = styled.div<{disableEvents: boolean}>`
  margin-top: 20px;

  ${({disableEvents}) =>
    disableEvents &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

interface IProps extends PropsWithChildren {
  title?: string;
  subTitle?: string;
  disableEvents?: boolean;
  className?: string;
  isIntegrationPage?: boolean;
}

export const RealtyProvider: FC<IProps> = ({
  children,
  title,
  subTitle,
  disableEvents = true,
  className,
  isIntegrationPage = false,
}) => {
  const theme = useTheme();
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation();
  const [hasIntegration] = useRealtyIntegration();

  if (isIntegrationPage) {
    return <>{children}</>;
  }

  if (hasIntegration) {
    return (
      <Wrapper className={className}>
        <InformationWrapper>
          <StyledBadge
            textColor={theme.palette.defaultBackground}
            textSize={mobile ? 'XS' : 'S'}
            backgroundColor={theme.palette.primaryAccent}
          >
            {t(title || 'common:realty.error.use_realty_for_work_1')}
          </StyledBadge>
          <StyledBadge
            textColor={theme.palette.defaultBackground}
            textSize={mobile ? 'XS' : 'S'}
            backgroundColor={theme.palette.primaryAccent}
          >
            {t(subTitle || 'common:realty.error.use_realty_for_work_2')}
          </StyledBadge>
        </InformationWrapper>
        <ChildrenWrapper disableEvents={disableEvents}>
          {children}
        </ChildrenWrapper>
      </Wrapper>
    );
  }

  return <Wrapper>{children}</Wrapper>;
};

export const RoomRealtyProvider = styled(RealtyProvider)`
  max-width: 905px;
  margin: 0 auto;
  width: 100%;
  margin-top: 55px;
  @media (max-width: 930px) {
    margin-top: 26px;
    padding: 0 20px;
  }
`;

import {Badge} from 'library/components/badge';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {HotelStatusEnum} from 'source/utilities/business';

interface HotelStatusProps {
  status: HotelStatusEnum;
}

const contentMap = {
  [HotelStatusEnum.Active]: {
    text: 'hotel-info:status.active',
    color: '#1e9e1e',
  },
  [HotelStatusEnum.Initializing]: {
    text: 'hotel-info:status.initializing',
    color: '#03DAC6',
  },
  [HotelStatusEnum.NotActive]: {
    text: 'hotel-info:status.not_active',
    color: '#FF4949',
  },
  [HotelStatusEnum.Paused]: {
    text: 'hotel-info:status.paused',
    color: '#ae7d0a',
  },
  [HotelStatusEnum.Banned]: {
    text: 'hotel-info:status.banned',
    color: '#FF4949',
  },
};

export const HotelStatus: FC<HotelStatusProps> = ({status}) => {
  const {t} = useTranslation('components');
  return (
    <Badge textColor="#fff" backgroundColor={contentMap[status].color}>
      {t(contentMap[status].text)}
    </Badge>
  );
};

import styled from '@emotion/styled';
import {Button} from 'library/components/button';

export const CheckboxButton = styled(Button)<{
  isSelect?: boolean;
  isMobile?: boolean;
}>`
  border-radius: 100px;
  padding: 12px 24px;
  margin: ${({isMobile}) =>
    isMobile ? '0px 10px 10px 0px' : '0px 10px 10px 0px'};
  font-weight: 500;
  background-color: ${({theme, isSelect}) =>
    isSelect ? theme.palette.fontPrimary : theme.palette.defaultBackground};
  border: 1px solid
    ${({theme, isSelect}) =>
      isSelect ? theme.palette.fontPrimary : theme.palette.border};
  color: ${({theme, isSelect}) =>
    isSelect ? theme.palette.defaultBackground : theme.palette.fontSecondary};
  &:last-child {
    margin-right: 0px;
  }
`;

import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsReviewsRepliesCreateResponse,
  HotelsReviewsRepliesDeleteResponse,
  HotelsReviewsRepliesPartialUpdateResponse,
} from 'types/api-scheme';

type CreateRepliesContext = {
  hotelId: number;
  reviewId: number;
  payload: {
    message: string;
  };
};
const createReplies = ({hotelId, reviewId, payload}: CreateRepliesContext) =>
  request<HotelsReviewsRepliesCreateResponse>(
    url(`hotels/${hotelId}/reviews/${reviewId}/replies`),
    {
      method: 'POST',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    },
  ).then(path<HotelsReviewsRepliesCreateResponse['data']>([1, 'data']));

type EditRelpliesContext = {
  hotelId: number;
  reviewId: number;
  replyId: number;
  payload: {
    message: string;
  };
};
const editReplies = ({
  hotelId,
  reviewId,
  replyId,
  payload,
}: EditRelpliesContext) =>
  request<HotelsReviewsRepliesPartialUpdateResponse>(
    url(`hotels/${hotelId}/reviews/${reviewId}/replies/${replyId}`),
    {
      method: 'PATCH',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    },
  ).then(path<HotelsReviewsRepliesPartialUpdateResponse['data']>([1, 'data']));

type DeleteRepliesContext = {
  hotelId: number;
  reviewId: number;
  conversationId: number;
};
const deleteReplies = ({
  hotelId,
  reviewId,
  conversationId,
}: DeleteRepliesContext) =>
  request<HotelsReviewsRepliesDeleteResponse>(
    url(`hotels/${hotelId}/reviews/${reviewId}/replies/${conversationId}`),
    {
      method: 'DELETE',
      headers: clientHeaders(),
    },
  );

export const repliesApi = {
  create: createReplies,
  edit: editReplies,
  delete: deleteReplies,
};

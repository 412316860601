import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';
import {DEFAULT_PAGE} from 'source/utilities/constants/logic';
import {BalanceStoreInitialState} from './lib/types';

const initialState: BalanceStoreInitialState = {
  organizations: [],
  page: DEFAULT_PAGE,
  isLast: false,
  selectedOrganization: null,
  loading: false,
};

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setOrganizations: (
      state,
      action: PayloadAction<ExistingRequisitesItems>,
    ) => {
      state.organizations = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setIsLast: (state, action: PayloadAction<boolean>) => {
      state.isLast = action.payload;
    },
    setSelectedOrganization: (
      state,
      action: PayloadAction<ExistingRequisitesItem | null>,
    ) => {
      state.selectedOrganization = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const selectOrganizations = (state: RootState) => {
  return state.balance.organizations;
};

export const selectSelectedOrganization = (state: RootState) => {
  return state.balance.selectedOrganization;
};

export const selectPage = (state: RootState) => {
  return state.balance.page;
};

export const selectIsLast = (state: RootState) => {
  return state.balance.isLast;
};

export const selectLoading = (state: RootState) => {
  return state.balance.loading;
};

export const {
  reset,
  setOrganizations,
  setPage,
  setIsLast,
  setSelectedOrganization,
  setLoading,
} = balanceSlice.actions;

export default balanceSlice.reducer;

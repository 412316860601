import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {ExpandableTextEditor} from 'library/components/expanded-text-editor';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useContext, useEffect, useState} from 'react';
import {setModerationDescriptionList} from 'slices/general_info';
import {
  EMPTY_DESCRIPTION_INPUT_VALUE,
  MIN_MODERATION_DESCRIPTION_VALUE,
} from 'slices/general_info/lib/constants';
import {HotelContext} from 'source/context/hotel';
import {useAppDispatch} from 'source/store';
import {showSuccessMessage} from 'source/utilities/exceptions/business';
import {sendModerationDescription} from '../network';

const Wrapper = styled.div`
  margin-top: 15px;
`;

const StyledExpandableTextarea = styled(ExpandableTextEditor)`
  .ql-container {
    height: 220px;
  }
`;

const TextAreaWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

const SendButton = styled(Button)`
  text-transform: uppercase;
  margin-top: 20px;
`;

const Counter = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
`;

const ModerationRequest = () => {
  const {t} = useTranslation();
  const [hotel] = useContext(HotelContext);
  const [openDescription, setOpenDescription] = useState(!hotel?.description);
  const [htmlInner, setHTMLInner] = useState('');
  const [text, setText] = useState('');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (hotel?.id) {
      sendModerationDescription(
        hotel,
        htmlInner,
        (moderationField?: ModerationField) => {
          if (moderationField) {
            setHTMLInner('');
            dispatch(setModerationDescriptionList([moderationField]));
            showSuccessMessage(t('components:changes_requested'));
          }
        },
        setLoading,
      );
    }
  };

  useEffect(
    () => setOpenDescription(!hotel?.description),
    [hotel?.description],
  );

  useEffect(() => {
    if (htmlInner === EMPTY_DESCRIPTION_INPUT_VALUE) {
      setHTMLInner('');
    }
  }, [htmlInner]);

  return (
    <Wrapper>
      <TextAreaWrapper>
        <StyledExpandableTextarea
          defaultHTMLInner={htmlInner}
          htmlInner={htmlInner}
          setHTMLInner={setHTMLInner}
          setText={setText}
          parentState={openDescription}
          parentHandler={setOpenDescription}
          buttonText={t(
            hotel?.description
              ? 'components:want_to_request_changes_exist'
              : 'components:want_to_request_changes',
          )}
          disabled={loading}
        />
      </TextAreaWrapper>

      {openDescription ? (
        <>
          <Counter size="S">
            {t('general-info:min_description_information', {
              count: MIN_MODERATION_DESCRIPTION_VALUE,
            })}
          </Counter>
          <SendButton
            onClick={onSubmit}
            disabled={
              loading || text.trim().length < MIN_MODERATION_DESCRIPTION_VALUE
            }
          >
            {t('components:request_changes')}
          </SendButton>
        </>
      ) : null}
    </Wrapper>
  );
};

export default ModerationRequest;

import styled from '@emotion/styled';
import {HorizontalDivier} from 'library/components/divider';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {Logout} from 'slices/user/content';
import {HotelierInfo, MenuPopup, UserButton} from '../ui';

const Trigger = styled.div`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
`;

const InfoWrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledDivider = styled(HorizontalDivier)`
  margin-top: 8px;
`;

export const UserPopup = () => {
  const {t} = useTranslation();

  return (
    <MenuPopup
      trigger={
        <Trigger>
          <UserButton />
        </Trigger>
      }
      closeOnDocumentClick
      arrow={false}
      offsetY={20}
      position="bottom right"
    >
      <InfoWrapper>
        <Text size="S">{t('common:hotelier')}:</Text>
        <HotelierInfo />
        <StyledDivider />
      </InfoWrapper>
      <Logout />
    </MenuPopup>
  );
};

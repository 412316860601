import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {HotelsReviewsDetailResponse} from 'types/api-scheme';

type GetHotelReviewsContext = {
  hotelId: number;
  page?: string;
  headers?: Headers;
};
const getHotelReviews = ({hotelId, page, headers}: GetHotelReviewsContext) =>
  request<HotelsReviewsDetailResponse>(
    url(`hotels/${hotelId}/reviews`, {...(page && {page})}),
    {
      method: 'GET',
      headers: headers || clientHeaders(),
    }
  ).then(path<HotelsReviewsDetailResponse>([1]));

export const reviewsApi = {
  get: getHotelReviews,
};

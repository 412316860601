import Script from 'next/script';
import {setIsBitrixSetup} from 'slices/bitrix/store';
import {Bitrix} from 'source/services/bitrix';
import {useAppDispatch} from 'source/store';
import {BITRIX_SIDE_BUTTON_SCRIPT} from 'source/utilities/guards/environment';

export const BitrixScript = () => {
  const dispatch = useAppDispatch();

  return (
    <Script
      src={BITRIX_SIDE_BUTTON_SCRIPT}
      onLoad={() => {
        Bitrix.initBitrix();
        dispatch(setIsBitrixSetup(true));
      }}
    />
  );
};

import styled from '@emotion/styled';
import {FC} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Badge} from 'library/components/badge';
import {Option} from 'library/components/select';

import {EIntegrations} from '../lib/types';

const StyledBadge = styled(Badge)`
  margin-bottom: 15px;
`;

interface IProps {
  selectIntegrationType: Option<EIntegrations>;
}

export const NeedConfigureBadge: FC<IProps> = ({selectIntegrationType}) => {
  const {t} = useTranslation('integration');
  const mobile = useDeviceDetection('mobile');

  return (
    <StyledBadge
      textColor={theme.palette.defaultBackground}
      textSize={mobile ? 'XS' : 'S'}
      backgroundColor={theme.palette.primaryAccent}
    >
      {t('need_integration', {
        providerName: selectIntegrationType.label,
      })}
    </StyledBadge>
  );
};

import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {Checkbox} from 'library/components/checkbox';
import {Text} from 'library/components/text';
import {FC, memo} from 'react';
import {useAppDispatch, useAppSelector} from 'source/store';
import {DirectoriesRoomFacilityGroupsListResponse} from 'types/api-scheme';
import {selectAmenities, toggleFacility} from '../store';

const Wrapper = styled.div`
  width: 100%;
  padding-top: 22px;
  @media (max-width: 480px) {
    padding-top: 0;
  }
`;

const GroupsWrapper = styled.div`
  columns: 2;
  break-inside: avoid;
  column-gap: 25px;
  @media (max-width: 900px) {
    columns: 1;
  }
`;

const GroupWrapper = styled.div`
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  &:last-child {
    margin-bottom: 16px;
  }
  @media (max-width: 480px) {
    &:first-child {
      margin-top: 16px;
    }
  }
`;

const GroupTitile = styled(Text)`
  margin-bottom: 15px;
  color: ${({theme}) => theme.palette.fontPrimary};
  @media (max-width: 480px) {
    border-bottom: 1px solid ${({theme}) => theme.palette.border};
    padding-bottom: 15px;
  }
`;

const AmenityWrapper = styled.div`
  margin-bottom: 16px;
  width: fit-content;
`;

const AmenityCheckbox = styled(Checkbox)<{value: boolean}>`
  & label {
    color: ${({theme}) => theme.palette.fontDefault};
  }
  & > div {
    border: ${({value}) => value && 'none'};
  }
  @media (max-width: 480px) {
    margin-left: 40px;
  }
`;

const MobileAmenityWrapper = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  padding-bottom: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const MobileAmenityTitle = styled(Text)``;

interface AmenitiesProps {
  mobile: boolean;
  facilities: DirectoriesRoomFacilityGroupsListResponse['data'];
}

const AmenitiesComponent: FC<AmenitiesProps> = ({mobile, facilities}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const amenities = useAppSelector(selectAmenities);
  const isAmenityToggled = (amenityId: number) =>
    amenities?.some(({id}) => id === amenityId);

  return (
    <Wrapper>
      <GroupsWrapper>
        {facilities.map((group) => (
          <GroupWrapper key={group.name}>
            <GroupTitile size="S">{group.name}</GroupTitile>
            {group.facilities.map((amenity) =>
              mobile ? (
                <MobileAmenityWrapper key={amenity.name}>
                  <MobileAmenityTitle size="XS">
                    {amenity.name}
                  </MobileAmenityTitle>
                  <AmenityCheckbox
                    value={isAmenityToggled(amenity.id) || false}
                    onChange={() => {
                      if (amenity.id) {
                        dispatch(toggleFacility(amenity.id));
                      }
                    }}
                    color={theme.palette.fontSecondary}
                  />
                </MobileAmenityWrapper>
              ) : (
                <AmenityWrapper key={amenity.name}>
                  <AmenityCheckbox
                    value={isAmenityToggled(amenity.id) || false}
                    onChange={() => {
                      if (amenity.id) {
                        dispatch(toggleFacility(amenity.id));
                      }
                    }}
                    label={amenity.name}
                    color={theme.palette.fontSecondary}
                  />
                </AmenityWrapper>
              ),
            )}
          </GroupWrapper>
        ))}
      </GroupsWrapper>
    </Wrapper>
  );
};

export const Amenities = memo(AmenitiesComponent);

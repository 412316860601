import styled from '@emotion/styled';
import {BalanceDetailsInformation} from './ui/balance-details-information';
import {BalanceOperationsTable} from './ui/balance-operations-table';
import {LoadMoreButton} from './ui/load-more-button';
import {BalanceDetailsPolling} from './ui/balance-details-polling';

const Wrapper = styled.div`
  margin-top: 40px;
`;

export const Content = () => {
  return (
    <Wrapper>
      <BalanceDetailsInformation />
      <BalanceOperationsTable />
      <LoadMoreButton />
      <BalanceDetailsPolling />
    </Wrapper>
  );
};

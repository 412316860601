import styled from '@emotion/styled';
import TextEditor from 'library/components/text-editor';
import {BottomArrowIconSmall} from 'library/icons/bottom-arrow-icon';
import useTranslation from 'next-translate/useTranslation';
import {useEffect, useState} from 'react';
import {Text} from '../text';

type ExpandableTextEditorProps = {
  placeholder?: string;
  buttonText: string;
  htmlInner?: string;
  defaultHTMLInner?: string;
  setHTMLInner: (text: string) => void;
  setText?: (text: string) => void;
  parentState?: boolean;
  parentHandler?: (value: boolean) => void;
  disabled: boolean;
};

const Wrapper = styled.div`
  position: relative;
`;

const StyledButton = styled.button<{isExpanded: boolean}>`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;

  svg {
    transition: all 0.3s;
    transform: ${({isExpanded}) => (isExpanded ? 'rotate(180deg)' : null)};
  }
`;

const StyledText = styled(Text)`
  margin-right: 10px;
  color: ${({theme}) => theme.palette.fontPrimary};
`;

export const ExpandableTextEditor = ({
  placeholder = 'enter_text',
  buttonText,
  htmlInner,
  defaultHTMLInner,
  setHTMLInner,
  setText,
  parentState = false,
  parentHandler,
  disabled,
  ...properties
}: ExpandableTextEditorProps) => {
  const [isExpanded, setIsExpanded] = useState(parentState);
  const {t} = useTranslation('components');

  useEffect(() => {
    setIsExpanded(parentState);
  }, [parentState]);

  useEffect(() => {
    parentHandler?.(isExpanded);
  }, [isExpanded]);

  return (
    <Wrapper>
      <StyledButton
        isExpanded={isExpanded}
        onClick={() => setIsExpanded((previous) => !previous)}
      >
        <StyledText size="S">{buttonText}</StyledText>
        <BottomArrowIconSmall />
      </StyledButton>
      {isExpanded && (
        <TextEditor
          placeholder={t(placeholder)}
          onChange={setHTMLInner}
          onChangeText={setText}
          readOnly={disabled}
          value={htmlInner}
          defaultValue={defaultHTMLInner}
          {...properties}
        />
      )}
    </Wrapper>
  );
};

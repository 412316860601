import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Checkbox} from 'library/components/checkbox';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {RadioButton} from 'library/components/radio';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import {CrossIcon} from 'library/icons/cross-icon';
import useTranslation from 'next-translate/useTranslation';

import {getViewedPeriodDate} from 'slices/rate/lib/helpers';
import {selectRooms} from 'slices/rooms/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import BnovoProvider from 'source/components/bnovo-provider';
import {RealtyProvider} from 'source/components/realty-provider';
import {selectRate, setRateName, setRatePeriod, setRoomIds} from '../../store';
import {RatePeriodPicker} from '../rate-period-picker';

const Wrapper = styled.div`
  max-width: 440px;
`;

const Label = styled(LabeledComponent)`
  border-radius: 100px;
  margin-bottom: 22px;
`;

const Radio = styled(RadioButton)`
  margin-bottom: 16px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const PeriodDateWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
`;

const PeriodText = styled(Text)`
  min-width: 125px;
`;

const Checkboxes = styled.div<{isMobile: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.palette.border};
  padding: ${({isMobile}) => (isMobile ? '17px 0px' : '20px 0px')};

  &:last-child {
    border-bottom: 1px solid ${({theme}) => theme.palette.border};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`;

const StyledDeleteButton = styled(Button)`
  width: 30px;
  height: 30px;
  padding: 8px;
  background-color: ${({theme}) => theme.palette.fontAccent};
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.palette.border};
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  margin-right: 10px;

  &:hover {
    background-color: ${({theme}) => theme.palette.fontPrimary};

    path {
      fill: ${({theme}) => theme.palette.defaultBackground};
      stroke: ${({theme}) => theme.palette.defaultBackground};
    }
  }
`;

const InformationAboutRate = () => {
  const {t} = useTranslation('rate');
  const mobile = useDeviceDetection('mobile');

  const dispatch = useAppDispatch();
  const rooms = useAppSelector(selectRooms);

  const {rate} = useAppSelector(selectRate);
  const {name, room_ids, period} = rate;

  const textSize = mobile ? 'S' : 'M';

  const handleToggleRoomUsing = (isAdd: boolean, roomId: number) => {
    const updateRoomIds = isAdd
      ? [...room_ids, roomId]
      : room_ids.filter((excludeRoomId) => excludeRoomId !== roomId);
    dispatch(setRoomIds(updateRoomIds));
  };

  return (
    <Wrapper>
      <Label
        size="S"
        text={t('about_rate.rate_name.title')}
        gap={12}
        color={theme.palette.fontSecondary}
      >
        <Input
          label=""
          type="text"
          value={name}
          onChange={({target}) => dispatch(setRateName(target.value))}
        />
      </Label>
      <RealtyProvider>
        <Label
          size="S"
          text={t('about_rate.rate_type.title')}
          gap={12}
          color={theme.palette.fontSecondary}
        >
          <Radio
            label={t('about_rate.rate_type.independent_rate_radio')}
            value
            textSize="XS"
          />
        </Label>
        <Checkboxes isMobile={mobile}>
          <Text size={textSize}>{t('limitation.select_all')}</Text>
          <Switch
            initialState={room_ids?.length === rooms?.length}
            isActive={room_ids?.length === rooms?.length}
            onSwitch={async (enabled) => {
              const updateRoomIds = enabled ? rooms.map((room) => room.id) : [];
              dispatch(setRoomIds(updateRoomIds));
            }}
          />
        </Checkboxes>

        <Label
          size="S"
          text={t('about_rate.rooms_use.title')}
          gap={12}
          color={theme.palette.fontSecondary}
        >
          {rooms?.map((room) => {
            return (
              <StyledCheckbox
                key={room.id}
                value={
                  Array.isArray(room_ids) ? room_ids.includes(room.id) : false
                }
                label={room.name}
                onChange={(enabled) => handleToggleRoomUsing(enabled, room.id)}
              />
            );
          })}
        </Label>

        <BnovoProvider>
          <Label
            size="S"
            text={t('about_rate.period.title')}
            gap={12}
            color={theme.palette.fontSecondary}
          >
            <PeriodWrapper>
              <RatePeriodPicker />
              <>
                {period.from ? (
                  <PeriodDateWrapper>
                    <PeriodText size={mobile ? 'XS' : 'S'}>
                      {t('about_rate.period.from')}
                    </PeriodText>
                    {getViewedPeriodDate(t, period.from)}
                    <StyledDeleteButton
                      onClick={() =>
                        dispatch(setRatePeriod({...period, from: null}))
                      }
                    >
                      <CrossIcon />
                    </StyledDeleteButton>
                  </PeriodDateWrapper>
                ) : null}

                {period.to ? (
                  <PeriodDateWrapper>
                    <PeriodText size={mobile ? 'XS' : 'S'}>
                      {t('about_rate.period.to')}
                    </PeriodText>
                    {getViewedPeriodDate(t, period.to)}
                    <StyledDeleteButton
                      onClick={() =>
                        dispatch(setRatePeriod({...period, to: null}))
                      }
                    >
                      <CrossIcon />
                    </StyledDeleteButton>
                  </PeriodDateWrapper>
                ) : null}
              </>
            </PeriodWrapper>
          </Label>
        </BnovoProvider>
      </RealtyProvider>
    </Wrapper>
  );
};

export default InformationAboutRate;

import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {SubText, Text} from 'library/components/text';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import {
  DEFAULT_BALANCE,
  getAmountColor,
  getHoldAmountColor,
} from 'slices/balance';
import {selectOrganization} from 'slices/balance_details/store';
import {useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const BalanceInformationWrapper = styled.div`
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const BalanceDetailsInformation = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const isMobile = useDeviceDetection('mobile');
  const textSize = isMobile ? 'S' : 'M';
  const organization = useAppSelector(selectOrganization);
  const totalAvailableAmount = organization?.balance?.amount || DEFAULT_BALANCE;
  const totalHoldAmount = organization?.balance?.amount_hold || DEFAULT_BALANCE;

  return (
    <BalanceInformationWrapper>
      <Text size={textSize}>
        {t('balance_details:balance_information.organization_name', {
          name: organization?.name || '',
        })}
      </Text>
      <Text size={textSize}>
        <Trans
          i18nKey="balance_details:balance_information.amount_available"
          values={{
            amount: totalAvailableAmount,
            currency: t('common:units_payments.rub'),
          }}
          components={[
            <SubText
              color={getAmountColor(theme, totalAvailableAmount)}
              size={textSize}
              key={0}
            />,
          ]}
        />
      </Text>
      <Text size={textSize}>
        <Trans
          i18nKey="balance_details:balance_information.amount_hold"
          values={{
            amount: totalHoldAmount,
            currency: t('common:units_payments.rub'),
          }}
          components={[
            <SubText
              color={getHoldAmountColor(theme, totalHoldAmount)}
              size={textSize}
              key={0}
            />,
          ]}
        />
      </Text>
    </BalanceInformationWrapper>
  );
};

import {Option} from 'library/components/select';
import {Translate} from 'next-translate';
import {isEmpty} from 'ramda';
import {ChangeEvent} from 'react';
import {
  DEFAULT_ADDITIONAL_BED,
  DEFAULT_ALTERNATIVE_ADDITIONAL_BED,
  DEFAULT_ALTERNATIVE_BED,
  DEFAULT_BED,
  MAX_FLOORS,
  SUB_CATEGORY_INDEX,
} from './constants';
import {
  BedType,
  BedTypeOptions,
  Category,
  RoomCategories,
  RoomType,
  SubCategory,
} from './types';

export const getTranslate = (t: Translate, key: string, parameters?: Object) =>
  t(`room_description.${key}`, parameters);

export const getLabelTranslate = (t: Translate, key: string) =>
  getTranslate(t, `label.${key}`);

export const getPlaceholderTranslate = (t: Translate, key: string) =>
  getTranslate(t, `placeholder.${key}`);

export const getBedTypesOptions = (bedTypes?: BedTypes) => {
  if (!bedTypes) {
    return [];
  }
  return bedTypes.map((type) => {
    return {
      label: type.name,
      value: type.id,
    };
  });
};

export const getPossibleRoomTypes = (
  categoryId?: number,
  roomCategories?: RoomCategories,
) => {
  if (!categoryId || !roomCategories) {
    return [];
  }
  return (
    roomCategories.find((category) => category.id === categoryId)?.children ||
    []
  );
};

export const getPossibleSubCategories = (
  categoryId?: number,
  roomTypeId?: number,
  roomCategories?: RoomCategories,
) => {
  if (!categoryId || !roomTypeId || !roomCategories) {
    return [];
  }
  return (
    roomCategories
      .find((category) => category.id === categoryId)
      ?.children?.find((roomType) => roomType.id === roomTypeId)?.children || []
  );
};

export const getSubCategoriesOptions = (subCategories?: SubCategory[]) => {
  if (!subCategories) {
    return [];
  }
  return subCategories.map((subCategory) => {
    return {label: subCategory.name, value: subCategory.id};
  });
};

export const getRoomTypesOptions = (roomTypes?: RoomType[]) => {
  if (!roomTypes) {
    return [];
  }
  return roomTypes.map((roomType) => {
    return {
      label: roomType.name,
      value: roomType.id,
    };
  });
};

export const getCategoriesOptions = (categories?: Category[]) => {
  if (!categories) {
    return [];
  }
  return categories.map((category) => {
    return {
      label: category.name,
      value: category.id,
    };
  });
};

export const getCategoryOption = (
  categoryId?: number,
  roomCategories?: RoomCategories,
) => {
  if (!categoryId || !roomCategories) {
    return {
      value: 0,
      label: '',
    };
  }
  const category = roomCategories.find(
    (roomCategory) => roomCategory.id === categoryId,
  );
  return {
    label: category?.name || '',
    value: category?.id || 0,
  };
};

export const getRoomTypeOption = (
  roomTypeId?: number,
  roomTypes?: RoomType[],
) => {
  if (!roomTypeId || !roomTypes) {
    return {
      value: 0,
      label: '',
    };
  }
  const roomType = roomTypes.find((roomType) => roomType.id === roomTypeId);
  return {
    label: roomType?.name || '',
    value: roomType?.id || 0,
  };
};

export const getSubCategoryOption = (
  subCategoryId?: number,
  subCategories?: SubCategory[],
) => {
  if (!subCategoryId || !subCategories) {
    return {
      value: 0,
      label: '',
    };
  }
  const subCategory = subCategories.find(
    (subCategory) => subCategory.id === subCategoryId,
  );
  return {
    label: subCategory?.name || '',
    value: subCategory?.id || 0,
  };
};

export const getBedOption = (
  bedType?: number,
  bedTypesOptions?: BedTypeOptions,
) => {
  if (!bedType || !bedTypesOptions) {
    return {
      value: 0,
      label: '',
    };
  }
  const bedOption = bedTypesOptions.find((option) => option.value === bedType);
  return {
    label: bedOption?.label || '',
    value: bedOption?.value || 0,
  };
};

export const isDefaultBed = (bed: Bed) =>
  !bed.is_additional && !bed.is_alternative;
export const isNotDefaultBed = (bed: Bed) => !isDefaultBed(bed);
export const isAdditionalBed = (bed: Bed) =>
  bed.is_additional && !bed.is_alternative;
export const isNotAdditionalBed = (bed: Bed) => !isAdditionalBed(bed);
export const isAlternativeBed = (bed: Bed) =>
  !bed.is_additional && bed.is_alternative;
export const isNotAlternativeBed = (bed: Bed) => !isAlternativeBed(bed);
export const isAlternativeAdditionalBed = (bed: Bed) =>
  bed.is_additional && bed.is_alternative;
export const isNotAlternativeAdditionalBed = (bed: Bed) =>
  !isAlternativeAdditionalBed(bed);

export const getBedFilters = (bedType: BedType) => {
  switch (bedType) {
    case 'default': {
      return {
        isBedWithTrait: isDefaultBed,
        isBedWithoutTrait: isNotDefaultBed,
      };
    }
    case 'additional': {
      return {
        isBedWithTrait: isAdditionalBed,
        isBedWithoutTrait: isNotAdditionalBed,
      };
    }
    case 'alternative': {
      return {
        isBedWithTrait: isAlternativeBed,
        isBedWithoutTrait: isNotAlternativeBed,
      };
    }
    case 'additionalAlternative': {
      return {
        isBedWithTrait: isAlternativeAdditionalBed,
        isBedWithoutTrait: isNotAlternativeAdditionalBed,
      };
    }
    default: {
      return {
        isBedWithTrait: isDefaultBed,
        isBedWithoutTrait: isNotDefaultBed,
      };
    }
  }
};

export const getDefaultBed = (bedType: BedType) => {
  switch (bedType) {
    case 'default': {
      return DEFAULT_BED;
    }
    case 'additional': {
      return DEFAULT_ADDITIONAL_BED;
    }
    case 'alternative': {
      return DEFAULT_ALTERNATIVE_BED;
    }
    case 'additionalAlternative': {
      return DEFAULT_ALTERNATIVE_ADDITIONAL_BED;
    }
    default: {
      return DEFAULT_BED;
    }
  }
};

export const buildRoomCategory = (
  roomCategoryId?: Room['room_category_id'],
  roomParentCategoryIds?: Room['room_parent_category_ids'],
  roomCategories?: RoomCategories,
) => {
  if (isEmpty(roomCategories) || !roomCategories) {
    return {category: 0, roomType: 0, subCategory: 0};
  }

  const {id: directoriesId, children} = roomCategories[0];

  if (isEmpty(roomParentCategoryIds) || !roomParentCategoryIds) {
    return {
      category: directoriesId,
      roomType: children?.[0]?.id,
      subCategory: children?.[0]?.children?.[0]?.id,
    };
  }

  const categoryIds = [...roomParentCategoryIds, roomCategoryId];
  const [categoryId, roomTypeId, subCategoryId] = categoryIds;

  const isSubCategoryExist = categoryIds.length >= SUB_CATEGORY_INDEX;

  return {
    category: categoryId ?? directoriesId,
    roomType: roomTypeId ?? children?.[0]?.id,
    subCategory: isSubCategoryExist ? subCategoryId : -1,
  };
};

export const getRoomFloorsValues = (floors: Room['floors']): Option[] => {
  if (!floors) {
    return [];
  }

  return floors.map((floor) => {
    return {label: `${floor}`, value: floor};
  });
};

export const getRoomFloorsCountOptions = (): Option[] =>
  Array.from({length: MAX_FLOORS + 1}).map((_, index) => ({
    label: `${index}`,
    value: index,
  }));

export const getNumberFromInputEvent = (
  event: ChangeEvent<HTMLInputElement>,
) => {
  const parsedNumber = Number.parseInt(event.target.value, 10);
  const newValue = Number.isNaN(parsedNumber) ? 0 : parsedNumber;

  return newValue;
};

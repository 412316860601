import {request} from 'library/api';
import {path} from 'ramda';
import {url} from 'source/utilities/network/url';
import {getQueryParameterFromBoolean} from 'source/utilities/network/router';
import {
  HotelsRoomsDetailResponse,
  HotelsRoomsImagesDetailResponse,
  HotelsRoomsShortsDetailResponse,
} from 'types/api-scheme';

type GetRoomsContext = {
  hotelId: number;
  page: string;
  headers?: HeadersInit;
};
const getRooms = ({hotelId, page, headers}: GetRoomsContext) =>
  request<HotelsRoomsDetailResponse>(url(`hotels/${hotelId}/rooms`, {page}), {
    method: 'GET',
    headers,
  }).then(path<HotelsRoomsDetailResponse>([1]));

type GetRoomsShortsContext = {
  hotelId: number;
  headers?: HeadersInit;
  hasRates?: boolean;
};
const getRoomsShorts = ({hotelId, headers, hasRates}: GetRoomsShortsContext) =>
  request<HotelsRoomsShortsDetailResponse>(
    url(`hotels/${hotelId}/rooms/shorts`, {
      ...getQueryParameterFromBoolean('has_rates', hasRates),
    }),
    {
      method: 'GET',
      headers,
    },
  ).then(path<HotelsRoomsShortsDetailResponse['data']>([1, 'data']));

type GetRoomImagesContext = {
  hotelId: number;
  roomId: number;
  headers?: HeadersInit;
};
const getRoomImages = ({hotelId, roomId, headers}: GetRoomImagesContext) =>
  request<HotelsRoomsImagesDetailResponse>(
    url(`hotels/${hotelId}/rooms/${roomId}/images`),
    {
      method: 'GET',
      headers,
    },
  ).then(path<RoomImages>([1, 'data']));

export const roomsApi = {
  get: getRooms,
  getShorts: getRoomsShorts,
  getImages: getRoomImages,
};

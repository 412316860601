import styled from '@emotion/styled';

import {useRouter} from 'next/router';
import {ComponentProps, FC, useContext} from 'react';

import {AuthorizationContext} from 'source/context/authorization';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

import {isHotelPage} from 'source/utilities/url';
import {AuthorizedHotelScenario} from './scenarios/authorized-hotel';
import {AuthorizedManageScenario} from './scenarios/authorized-manage';
import {UnauthorizedScenario} from './scenarios/unauthorized';

const Wrapper = styled.header`
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
`;

interface HeaderProperties extends ComponentProps<'header'> {}

export const Header: FC<HeaderProperties> = (properties) => {
  const router = useRouter();
  const [authorized] = useContext(AuthorizationContext);
  const mobile = useDeviceDetection('mobile');
  const tablet = useDeviceDetection('tablet');

  return (
    <Wrapper {...properties}>
      {authorized ? (
        isHotelPage(router.pathname) ? (
          <AuthorizedHotelScenario mobile={mobile} tablet={tablet} />
        ) : (
          <AuthorizedManageScenario mobile={mobile} tablet={tablet} />
        )
      ) : (
        <UnauthorizedScenario mobile={mobile} tablet={tablet} />
      )}
    </Wrapper>
  );
};

/* eslint-disable consistent-return */
import {GetServerSideProps} from 'next';
import {Translate} from 'next-translate';
import {andThen, assoc, call, isEmpty, pipeWith, when} from 'ramda';
import {DEFAULT_DAYS_VALUE} from 'slices/rate/lib/constants';
import {
  checkIfNoValuesInExtraGuestPrices,
  cleanPrices,
  parseExtraGuestsPricesPayload,
} from 'slices/rate/lib/helpers';
import {store} from 'source/store';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {
  handleException,
  showException,
  showSuccessMessage,
} from 'source/utilities/exceptions/business';
import {throwRedirectException} from 'source/utilities/exceptions/network';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {isUnhandledException} from 'source/utilities/guards/exceptions';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeNil, removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {HotelsRatesUpdateRequest} from 'types/api-scheme';
import {ECancellationRateType, RatePageProperties} from './lib/types';
import {setIsNeedSaving, setRate, setRateActivating} from './store';

export const getRatePageProperties: GetServerSideProps<
  RatePageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const rateId = transformToNumber(context?.params?.rate_slug);

  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestRooms = () =>
    api.rooms.getShorts({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestRate = () =>
    api.rate
      .get({
        hotelId,
        rateId,
        headers,
      })
      .catch(
        when(isUnhandledException, (error) => {
          console.error(error);
          return throwRedirectException(redirects.manage);
        }),
      );

  const requestPaidServices = () =>
    api.services.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.log(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);
          checkIdValidity(rateId);

          const [hotel, rate, rooms, paidServices] = await Promise.all([
            requestHotel(),
            requestRate(),
            requestRooms(),
            requestPaidServices(),
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            rate,
            rooms,
            paidServices,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

// saving rates
export const handleSaveRates = async (
  translate: Translate,
  hotelId?: Hotel['id'],
  enabled?: boolean,
  savingStateHandler?: (value: boolean) => void,
  hasIntegration?: boolean,
) => {
  if (!hotelId) {
    return;
  }

  const {
    rate: {rate},
    rooms: {data: rooms},
  } = store.getState();

  const {
    id,
    prices = [],
    parent_id,
    name = '',
    cancellation_type,
    payment_settings,
    cancellation_fine,
    constraint_exclude_rooms = [],
    constraints,
    room_ids,
    services,
    period,
    extra_guests_prices,
  } = rate;

  if (!cancellation_type) {
    return handleException({
      message: translate('notify.error_saving'),
    });
  }

  if ((Array.isArray(rooms) && rooms.length === 0) || !rooms) {
    return showException(translate('notify.not_found_rooms') as string);
  }

  const isNonePositivePrices = cleanPrices(prices, room_ids).some((price) =>
    price.per_guests_number.some((per_guest_number) =>
      per_guest_number.values.some(
        (value) => value.price === DEFAULT_DAYS_VALUE,
      ),
    ),
  );

  if (isNonePositivePrices && !hasIntegration) {
    return showException(translate('notify.zero_price_count') as string);
  }

  if (isEmpty(room_ids) && !hasIntegration) {
    return showException(translate('notify.select_room_ids') as string);
  }

  if (checkIfNoValuesInExtraGuestPrices(extra_guests_prices)) {
    return showException(translate('notify.enter_extra_prices') as string);
  }

  if (savingStateHandler) {
    savingStateHandler(true);
  }

  // Генерация нового объекта, потому что в данном кейсе нельзя отправлять отдельно что либо
  // PUT и GET данные отличаются, такие как price, constraints, cancellation_fine, cancellation_type
  const payload: HotelsRatesUpdateRequest = removeNil({
    id,
    parent_id,
    name,
    cancellation_type: cancellation_type.value,
    parent_constraints_type: null,
    room_ids,
    prices: cleanPrices(prices, room_ids),
    payment_settings: payment_settings
      ? {
          ...payment_settings,
          required_prepayment_unit_type:
            payment_settings?.required_prepayment_unit_type?.value,
        }
      : undefined,
    constraints,
    cancellation_fine:
      cancellation_type.value === ECancellationRateType.FlexibleRules
        ? {
            ...cancellation_fine,
            unit_type: cancellation_fine?.unit_type
              ?.value as Required<HotelsRatesUpdateRequest>['cancellation_fine']['unit_type'],
          }
        : undefined,
    constraint_exclude_rooms,
    services,
    extra_guests_prices: parseExtraGuestsPricesPayload(extra_guests_prices),
    period,
  });

  return api.rate
    .update({hotelId, id, payload})
    .then(() => {
      showSuccessMessage(
        translate(enabled ? 'notify.success' : 'notify.success_with_enable'),
      );
    })
    .catch(handleException)
    .finally(() => {
      if (savingStateHandler) {
        savingStateHandler(false);
      }
      store.dispatch(setIsNeedSaving(false));
    });
};

export const handleEnableRate = async (
  translate: Translate,
  hotelId?: Hotel['id'],
) => {
  if (!hotelId) {
    return;
  }

  const {rate} = store.getState().rate;

  store.dispatch(setRateActivating(true));

  return api.rate
    .enable({hotelId, rateId: rate.id})
    .then((data) => {
      if (data && 'enabled' in data) {
        store.dispatch(
          setRate({
            ...rate,
            enabled: data.enabled ?? false,
          }),
        );
        if (data.enabled) {
          showSuccessMessage(translate('notify.tariff_activate'));
        }
      } else if (data && data.prices.length > 0) {
        showException(data.prices[0].message);
      } else if (data && data.fines.length > 0) {
        showException(data.fines[0].message);
      }
    })
    .catch(handleException)
    .finally(() => {
      store.dispatch(setRateActivating(false));
    });
};

import {
  add,
  addDays,
  differenceInDays,
  format,
  isAfter,
  max,
  min,
  parseISO,
  eachMonthOfInterval,
  startOfMonth,
  isThisMonth,
  startOfDay,
  isSameDay,
  subDays,
  isBefore,
  addMonths,
  sub,
} from 'date-fns';
import {isEmpty, range, update} from 'ramda';
import {DateRangeProps} from 'react-date-range';

import {
  getBackendDateFormat,
  addCurrentYearDays,
  getViewMonthsName,
} from 'source/utilities/dates';
import {toValueOrEmptyArray} from 'source/utilities/array';

import {EDaysOfWeek} from 'slices/rate/lib/types';

import {isNumber} from 'source/utilities/guards/types';
import {
  DEGREE_OF_ELEVATION,
  MAX_DAYS_RANGE,
  INITIAL_DAYS_RANGE,
  CALENDAR_RANGE_DATE_FORMAT,
  MAX_DATES_LIMIT_TOUCH,
  MONTH_CLICK_DAYS_COUNT,
} from '../constants';
import {
  RangeSelectCells,
  SelectedDatesType,
  MonthDatesType,
  IsDateInConstraintType,
  PricesDateRangeType,
  NewEndDateType,
  ScrollDirectionType,
} from '../types';

export const isRangeValid = (
  from: Date,
  to: Date,
  isTouch: boolean,
): boolean => {
  const difference = differenceInDays(to, from);
  const appropriateLimit = isTouch ? MAX_DATES_LIMIT_TOUCH : MAX_DAYS_RANGE;

  return difference <= appropriateLimit;
};

export const formatDateToString = (date: Date): string => {
  return format(date, 'yyyy/MM/dd');
};

export const buildInitialSelectedDays = (): SelectedDatesType => {
  const from = new Date();
  const to = add(from, {days: INITIAL_DAYS_RANGE});
  return {
    from: formatDateToString(from),
    to: formatDateToString(to),
  };
};

export const buildInitialMonthDates = (): MonthDatesType => {
  const start = new Date();
  const end = addCurrentYearDays(start);

  const monthsDates = eachMonthOfInterval({start, end});

  const outputMonthDates = update(0, startOfDay(start), monthsDates);

  return outputMonthDates.map((date) => ({
    id: `monthDate_${date}`,
    date,
  }));
};

export const buildSelectedDaysFromMonthDate = (
  date: Date,
): SelectedDatesType => {
  // Если текущий месяц - выбираем дату - сегодня
  const startOfFromMonth = startOfMonth(date);
  const from = isThisMonth(date)
    ? date
    : subDays(startOfFromMonth, MONTH_CLICK_DAYS_COUNT);
  // добавляем 7 дней после и 7 дней до начала месяца
  const startOfToMonth = addMonths(startOfFromMonth, 1);
  const to = addDays(startOfToMonth, MONTH_CLICK_DAYS_COUNT);
  const today = new Date();

  // проверяем чтобы до >= сегодня и после <= максимальная дата
  const maxEndDate = addCurrentYearDays(today);
  const isFromLimitExceeded = isBefore(from, today);
  const isToLimitExceeded = isAfter(to, maxEndDate);

  const outputTo = isToLimitExceeded ? maxEndDate : to;
  const outputFrom = isFromLimitExceeded ? today : from;

  return {
    from: formatDateToString(outputFrom),
    to: formatDateToString(outputTo),
  };
};

export const formatMonthFromDate = (date: Date): string => {
  return format(date, 'M');
};

export const buildRangeDates = (from: Date, to: Date): string[] => {
  const startOfFromDay = startOfDay(from);
  const days = Math.abs(differenceInDays(startOfFromDay, to));
  return range(0, days + 1).map((index) =>
    format(addDays(startOfFromDay, index), CALENDAR_RANGE_DATE_FORMAT),
  );
};

export const isDateInConstrainStayPeriod = (
  stay: CalendarRatesConstraintsStay,
  currentDate: Date,
): IsDateInConstraintType => {
  const days: string[] = [];
  const periods = toValueOrEmptyArray(stay?.periods);
  const hasMinDays = stay?.day?.min_days;

  periods.forEach((period) =>
    days.push(...buildRangeDates(new Date(period.from), new Date(period.to))),
  );

  const dayOfWeek = Number(format(currentDate, 'i'));

  const isConstrainDay = stay?.day?.days_of_week?.includes(
    (DEGREE_OF_ELEVATION ** dayOfWeek) as EDaysOfWeek,
  );

  const isConstrainPeriod = days.includes(
    format(currentDate, CALENDAR_RANGE_DATE_FORMAT),
  );

  if (isConstrainDay || isConstrainPeriod) {
    return {isDateBlocked: true, hasConstraint: true};
  }

  if (hasMinDays) {
    return {isDateBlocked: false, hasConstraint: true};
  }

  return {isDateBlocked: false, hasConstraint: false};
};

export const hasBnovoGuestNumberConstraints = (
  guestNumber: CalendarBnovoConstraintByGuestNumber,
): boolean => {
  const {
    min_stay,
    min_stay_arrival,
    max_stay,
    closed,
    closed_arrival,
    closed_departure,
  } = guestNumber;

  return (
    isNumber(min_stay) ||
    isNumber(min_stay_arrival) ||
    isNumber(max_stay) ||
    closed ||
    closed_arrival ||
    closed_departure
  );
};

export const hasNoIntegrationConstraints = (
  constraints: CalendarRealtyConstraints | CalendarBnovoConstraints,
  hasIntegration: boolean,
): boolean => {
  return !constraints || constraints?.length === 0 || !hasIntegration;
};

export const getBnovoConstraint = (
  hasBnovoIntegration: boolean,
  bnovoConstraints: CalendarBnovoConstraints,
  dayDate: Date,
): CalendarBnovoConstraint | null => {
  if (hasNoIntegrationConstraints(bnovoConstraints, hasBnovoIntegration)) {
    return null;
  }

  const currentConstraint = bnovoConstraints?.find((constraint) =>
    isSameDay(new Date(constraint.date), new Date(dayDate)),
  );

  const hasAnyConstraintValue = currentConstraint?.by_guests_number?.some(
    (guestNumber) => hasBnovoGuestNumberConstraints(guestNumber),
  );

  return currentConstraint && hasAnyConstraintValue ? currentConstraint : null;
};

export const getRealtyConstraint = (
  hasRealtyIntegration: boolean,
  realtyConstraints: CalendarRealtyConstraints,
  dayDate: Date,
): CalendarRealtyConstraint | null => {
  if (hasNoIntegrationConstraints(realtyConstraints, hasRealtyIntegration)) {
    return null;
  }

  const currentConstraint = realtyConstraints?.find((constraint) =>
    isSameDay(new Date(constraint.date), new Date(dayDate)),
  );

  const hasAnyConstraintValue = currentConstraint?.by_guests_number?.some(
    (guestNumber) => isNumber(guestNumber.min_stay),
  );

  return currentConstraint && hasAnyConstraintValue ? currentConstraint : null;
};

export const buildPricesDateRange = (
  prices: RangeSelectCells['prices'],
): PricesDateRangeType => {
  const dates = prices.map((priceData) => parseISO(priceData.date));
  const startDate = min(dates);
  const endDate = max(dates);

  return {startDate, endDate};
};

export const formatDateValueForInput = (
  ranges: DateRangeProps['ranges'],
): string => {
  if (
    !ranges ||
    isEmpty(ranges) ||
    !ranges[0].startDate ||
    !ranges[0].endDate
  ) {
    return '';
  }

  return `${getViewMonthsName(ranges[0].startDate)} - ${getViewMonthsName(
    ranges[0].endDate,
  )}`;
};

export const formatDateValueForRow = (date: string) => {
  const formattedDate = new Date(date);

  return format(formattedDate, 'dd,EEEEEE').toLocaleUpperCase();
};

export const createDateMap = (rangeDateArray: string[]) =>
  rangeDateArray.reduce((accumulator: Record<string, boolean>, date) => {
    accumulator[date] = true;
    return accumulator;
  }, {});

export const buildNewDatesOnScroll = (
  from: string,
  to: string,
  step: number,
  direction: ScrollDirectionType,
): NewEndDateType => {
  const today = new Date();
  const maxEndDate = addCurrentYearDays(today);

  const toDate = new Date(to);
  const fromDate = new Date(from);

  // При прогрузке предыдущих дат
  if (direction === ScrollDirectionType.LEFT) {
    const newToDate = new Date(to);
    const fromDateWithStep = sub(fromDate, {days: step + 1});

    const newFromDate = isBefore(fromDateWithStep, today)
      ? today
      : fromDateWithStep;

    const isBeforeLimitExceeded = isBefore(fromDateWithStep, today);

    return {
      newFrom: getBackendDateFormat(newFromDate),
      newTo: getBackendDateFormat(newToDate),
      isLimitExceeded: isBeforeLimitExceeded,
      preventInitialLoad: false,
    };
  }

  // При прогрузке будущих дат
  const newFromDate = new Date(from);
  const toDateWithStep = add(toDate, {days: step + 1});
  const isLimitExceeded = isAfter(toDateWithStep, maxEndDate);

  const newToDate = isLimitExceeded ? maxEndDate : toDateWithStep;
  const preventInitialLoad = isAfter(newFromDate, newToDate);

  return {
    newFrom: getBackendDateFormat(newFromDate),
    newTo: getBackendDateFormat(newToDate),
    isLimitExceeded,
    preventInitialLoad,
  };
};

export const validateDateOnExceed = (comparedDate: Date, maxEndDate: Date) =>
  isAfter(comparedDate, maxEndDate) ? maxEndDate : comparedDate;

import styled from '@emotion/styled';
import {HorizontalDivier} from 'library/components/divider';
import {NavigationLink} from 'library/components/navigation-link';
import useTranslation from 'next-translate/useTranslation';
import {NextRouter, useRouter} from 'next/router';
import {FC, useContext} from 'react';
import Actions from 'source/components/header/ui/actions';
import {IconName} from 'source/components/icon';
import {HotelContext} from 'source/context/hotel';
import {UtilitiesContext} from 'source/context/utilities';
import {Routes} from 'source/utilities/network/url';
import {HotelPopup} from '../../popups/hotel';
import {SupportPopup} from '../../popups/support';
import {
  BurgerMobile,
  BurgerTablet,
  HotelButton,
  LinksDropdown,
  LinksGroup,
  Logo,
} from '../../ui';

export interface AnchorGroup {
  title: string;
  iconName: IconName;
  links: Anchor[];
}

export type Anchors = Array<Anchor | AnchorGroup>;

const MAX_TABLE_ANCHORS = 3;

const anchors = (hotelId: number, router: NextRouter): Anchors => [
  {
    title: 'home',
    href: `/manage/${hotelId}/home`,
    iconName: 'home-page',
  },
  {
    title: 'rates-group',
    iconName: 'rates-page',
    links: [
      {
        title: 'calendar',
        href: `/manage/${hotelId}/calendar`,
      },
      {
        title: 'rates',
        href: `/manage/${hotelId}/rates`,
      },
      {
        title: 'integration',
        href: `/manage/${hotelId}/integration`,
      },
    ],
  },
  {
    title: 'object-group',
    iconName: 'hotels-page',
    links: [
      {
        title: 'general-info',
        href: `/manage/${hotelId}/general-info`,
      },
      {
        title: 'facilities',
        href: `/manage/${hotelId}/facilities`,
      },
      {
        title: 'landmarks',
        href: `/manage/${hotelId}/landmarks`,
      },
      {
        title: 'photos',
        href: `/manage/${hotelId}/photos`,
      },
      {
        title: 'rooms',
        href: `/manage/${hotelId}/rooms`,
        isLinkActive:
          !!router.query.room_slug ||
          router.pathname === '/manage/[hotel_slug]/rooms',
      },
      // TODO - временно скрыто, вернуть когда скажет Тимур
      // {
      //   title: 'profile',
      //   href: `/manage/${hotelId}/profile`,
      // },
      {
        title: 'policies',
        href: `/manage/${hotelId}/policies`,
      },
    ],
  },
  {
    title: 'messages-group',
    iconName: 'messages-page',
    links: [
      {
        title: 'messages',
        href: `/manage/${hotelId}/messages`,
      },
    ],
  },
  {
    title: 'hotel-reviews-group',
    iconName: 'reviews-page',
    links: [
      {
        title: 'hotel-reviews',
        href: `/manage/${hotelId}/hotel-reviews`,
      },
    ],
  },
  {
    title: 'finance-group',
    iconName: 'finance-page',
    links: [
      {
        title: 'requisites',
        href: `/manage/${hotelId}/requisites`,
      },
      {
        title: 'payments',
        href: `/manage/${hotelId}/payments`,
      },
    ],
  },
];

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BurgerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`;

const BurgerNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 30px;

  @media (max-width: 1200px) {
    margin: 0px 20px;
    a p {
      font-size: 13px;
    }
    a svg {
      height: 25px;
      width: 25px;
    }
    button p {
      font-size: 12px;
    }
  }
`;

const StyledDivider = styled(HorizontalDivier)`
  margin-bottom: 20px;
`;

const PopupButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const AuthorizedHotelScenario: FC<{
  mobile: boolean;
  tablet: boolean;
}> = ({mobile, tablet}) => {
  const {t} = useTranslation('routing');
  const [utilities] = useContext(UtilitiesContext);
  const [hotel] = useContext(HotelContext);
  const router = useRouter();

  if (mobile) {
    return (
      <StyledContent>
        <Logo />
        <PopupButtonsWrapper>
          <SupportPopup />
          <BurgerMobile>
            <BurgerInfo>
              <HotelButton />
              <NavigationLink href={Routes.profile} iconName="user-icon">
                {t('profile')}
              </NavigationLink>
              <NavigationLink href={Routes.manage} iconName="rates-page">
                {t('manage')}
              </NavigationLink>
              <NavigationLink href={Routes.bookings} iconName="hotels-page">
                {t('bookings')}
              </NavigationLink>
              <Actions />
            </BurgerInfo>
            <BurgerNavigation>
              {hotel?.id
                ? anchors(hotel.id, router).map((item) => {
                    const isAnchrorGroup = 'links' in item;
                    const anchor = item as Anchor;
                    const group = item as AnchorGroup;
                    return isAnchrorGroup ? (
                      <LinksGroup
                        key={group.title}
                        title={group.title}
                        iconName={group.iconName}
                        links={group.links}
                      />
                    ) : (
                      <NavigationLink
                        key={anchor.title}
                        href={anchor.href}
                        iconName={anchor.iconName}
                      >
                        {t(anchor.title)}
                      </NavigationLink>
                    );
                  })
                : null}
            </BurgerNavigation>
          </BurgerMobile>
        </PopupButtonsWrapper>
      </StyledContent>
    );
  }

  if (tablet || utilities.windowWidth < 1500) {
    return (
      <StyledContent>
        <Logo />
        <Navigation>
          {hotel?.id
            ? anchors(hotel.id, router).map((item, index) => {
                if (
                  index >= MAX_TABLE_ANCHORS ||
                  utilities.windowWidth <= 700
                ) {
                  return null;
                }
                const isAnchrorGroup = 'links' in item;
                const anchor = item as Anchor;
                const group = item as AnchorGroup;
                return isAnchrorGroup ? (
                  <LinksDropdown
                    key={group.title}
                    title={group.title}
                    iconName={group.iconName}
                    links={group.links}
                  />
                ) : (
                  <NavigationLink
                    key={anchor.title}
                    href={anchor.href}
                    iconName={anchor.iconName}
                  >
                    {t(anchor.title)}
                  </NavigationLink>
                );
              })
            : null}
        </Navigation>
        <PopupButtonsWrapper>
          <SupportPopup />
          <BurgerTablet>
            <BurgerInfo>
              <HotelButton />
              <NavigationLink href={Routes.profile} iconName="user-icon">
                {t('profile')}
              </NavigationLink>
              <NavigationLink href={Routes.manage} iconName="rates-page">
                {t('manage')}
              </NavigationLink>
              <NavigationLink href={Routes.bookings} iconName="hotels-page">
                {t('bookings')}
              </NavigationLink>
              <Actions />
            </BurgerInfo>
            <StyledDivider />
            <BurgerNavigation>
              {hotel?.id
                ? anchors(hotel.id, router).map((item) => {
                    const isAnchrorGroup = 'links' in item;
                    const anchor = item as Anchor;
                    const group = item as AnchorGroup;
                    return isAnchrorGroup ? (
                      <LinksGroup
                        key={group.title}
                        title={group.title}
                        iconName={group.iconName}
                        links={group.links}
                      />
                    ) : (
                      <NavigationLink
                        key={anchor.title}
                        href={anchor.href}
                        iconName={anchor.iconName}
                      >
                        {t(anchor.title)}
                      </NavigationLink>
                    );
                  })
                : null}
            </BurgerNavigation>
          </BurgerTablet>
        </PopupButtonsWrapper>
      </StyledContent>
    );
  }

  return (
    <StyledContent>
      <Logo />

      <Navigation>
        {hotel?.id
          ? anchors(hotel.id, router).map((item) => {
              const isAnchrorGroup = 'links' in item;
              const anchor = item as Anchor;
              const group = item as AnchorGroup;
              return isAnchrorGroup ? (
                <LinksDropdown
                  key={group.title}
                  title={group.title}
                  iconName={group.iconName}
                  links={group.links}
                />
              ) : (
                <NavigationLink
                  key={anchor.title}
                  href={anchor.href}
                  iconName={anchor.iconName}
                >
                  {t(anchor.title)}
                </NavigationLink>
              );
            })
          : null}
      </Navigation>
      <PopupButtonsWrapper>
        <SupportPopup />
        <HotelPopup />
      </PopupButtonsWrapper>
    </StyledContent>
  );
};

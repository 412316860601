import {useTheme} from '@emotion/react';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';

import {LabeledComponent} from 'library/components/labeled-component';

import {getLabelTranslate} from '../../lib/helpers';
import Floors from '../floors';

interface IProps {
  translateKey: string;
}

export const FloorsSelect: FC<IProps> = ({translateKey}) => {
  const theme = useTheme();
  const {t} = useTranslation(translateKey);

  return (
    <LabeledComponent
      color={theme.palette.fontSecondary}
      size="S"
      gap={10}
      text={getLabelTranslate(t, 'floors')}
    >
      <Floors />
    </LabeledComponent>
  );
};

/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';
import {HotelsRoomsDetailResponse} from 'types/api-scheme';

const initialState: HotelsRoomsDetailResponse = {
  data: [],
  meta: {
    current_page: 0,
    total: 0,
    last_page: 0,
  },
};

export const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    addRooms: (
      state,
      action: PayloadAction<HotelsRoomsDetailResponse['data']>
    ) => {
      state.data = [...state.data, ...action.payload];
    },
    setMeta: (
      state,
      action: PayloadAction<HotelsRoomsDetailResponse['meta']>
    ) => {
      state.meta = action.payload;
    },
    setRooms: (
      state,
      action: PayloadAction<HotelsRoomsDetailResponse['data']>
    ) => {
      state.data = action.payload;
    },
    setRoomMainImage: (
      state,
      action: PayloadAction<{roomId: number; image: string}>
    ) => {
      const {roomId, image} = action.payload;
      const roomIndex = state.data.findIndex((room) => room.id === roomId);
      if (roomIndex >= 0) {
        state.data[roomIndex].main_image = image;
      }
    },
  },
});

export const {reset, setRooms, addRooms, setMeta, setRoomMainImage} =
  roomsSlice.actions;

export const selectIsLastPage = (state: RootState) => {
  return state.rooms.meta.current_page === state.rooms.meta.last_page;
};

export const selectRooms = (state: RootState) => {
  return state.rooms.data;
};

export default roomsSlice.reducer;

import type {GetServerSideProps} from 'next';

import {andThen, assoc, call, cond, pipeWith} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {
  handleBusinessException,
  handleException,
  handleUnhandledException,
} from 'source/utilities/exceptions/business';

import {
  handleApiException,
  handleRedirectException,
} from 'source/utilities/exceptions/network';

import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {
  isApiException,
  isBusinessException,
  isRedirectException,
  isUnhandledException,
} from 'source/utilities/guards/exceptions';
import {clientHeaders, serverHeaders} from 'source/utilities/network/http';

import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {RoomPhotosPageProperties} from './lib/types';

export const getRoomPhotosPageProperties: GetServerSideProps<
  RoomPhotosPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const roomId = transformToNumber(context?.params?.room_slug);

  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({
      hotelId,
      headers,
    });
  const requestImages = () => api.rooms.getImages({hotelId, roomId, headers});
  const requestRooms = () => api.rooms.getShorts({hotelId, headers});

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, images, rooms] = await Promise.all([
            requestHotel(),
            requestImages() as Promise<RoomImages>,
            requestRooms(),
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            images,
            rooms,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(
    cond([
      [isApiException, handleApiException],
      [isRedirectException, handleRedirectException],
      [isBusinessException, handleBusinessException],
      [isUnhandledException, handleUnhandledException],
    ]),
  );
};

export const deleteImages = (
  roomId: number,
  selectedImages: number[],
  onComplete: () => void,
  setLoading: LoadingDispatch,
  hotel?: Hotel | null,
) => {
  if (!hotel) {
    return;
  }
  const options = {hotelId: hotel.id, payload: {image_ids: selectedImages}};

  setLoading(true);
  api.roomImages
    .deleteMany({roomId, ...options})
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const deleteImage = (
  hotel: Hotel,
  roomId: number,
  imageId: number,
  onComplete: () => void,
  ...dispatchers: [LoadingDispatch]
) => {
  const options = {hotelId: hotel.id, imageId};
  const [setLoading] = dispatchers;

  setLoading(true);
  api.roomImages
    .delete({...options, roomId})
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const reorderImages = async (
  hotel: Hotel,
  roomId: number,
  draggedImageId: number,
  preHotelImageId: number,
) => {
  const options = {
    hotelId: hotel.id,
    draggedImageId,
    payload: {preHotelImageId},
  };
  api.roomImages.reorder({roomId, ...options}).catch((error) => {
    handleApiException(error);
  });
};

export const getRoomImages = async (
  hotelId: number,
  roomId: number,
  onComplete?: (images: RoomImages) => void,
) => {
  const headers = clientHeaders();

  await api.rooms.getImages({hotelId, roomId, headers}).then((images) => {
    if (images && onComplete) {
      onComplete(images);
    }
  });
};

export const changeRoomMainImage = async (
  roomId: number,
  imageId: number,
  onComplete: () => void,
  hotel?: Hotel | null,
) => {
  if (!hotel) {
    return;
  }
  await api.room
    .edit({hotelId: hotel.id, roomId, payload: {main_image_id: imageId}})
    .then(onComplete)
    .catch(handleException);
};

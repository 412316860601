/* eslint-disable react/jsx-no-useless-fragment */
import {FC, Dispatch, SetStateAction} from 'react';
import styled from '@emotion/styled';

import {CrossIcon} from 'library/icons/cross-icon';
import {PencilIcon} from 'library/icons/pencil-icon';
import {Button} from 'library/components/button';

const PlusIcon = styled(CrossIcon)`
  transform: rotate(45deg);
`;

const StyledButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 8px;
  background-color: ${({theme}) => theme.palette.fontAccent};
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.palette.border};
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  margin-right: 10px;

  &:hover {
    background-color: ${({theme}) => theme.palette.fontPrimary};
    path {
      fill: ${({theme}) => theme.palette.defaultBackground};
      stroke: ${({theme}) => theme.palette.defaultBackground};
    }
  }
`;

interface IProps {
  isRatePage: boolean;
  isServiceAlreadyAdded: boolean;
  onEdit?: Dispatch<SetStateAction<HotelService | null>>;
  onDelete?: (service: HotelService) => void;
  onAdd?: (service: HotelService) => void;
  service: HotelService;
}

export const ServiceTableButtons: FC<IProps> = ({
  isRatePage,
  isServiceAlreadyAdded,
  onEdit = () => {},
  onAdd = () => {},
  onDelete = () => {},
  service,
}) => {
  return (
    <>
      {isRatePage ? (
        <div>
          {isServiceAlreadyAdded ? (
            <StyledButton onClick={() => onDelete && onDelete(service)}>
              <CrossIcon />
            </StyledButton>
          ) : (
            <StyledButton onClick={() => onAdd && onAdd(service)}>
              <PlusIcon />
            </StyledButton>
          )}
        </div>
      ) : (
        <>
          <StyledButton onClick={() => onEdit && onEdit(service)}>
            <PencilIcon />
          </StyledButton>
          <StyledButton onClick={() => onDelete && onDelete(service)}>
            <CrossIcon />
          </StyledButton>
        </>
      )}
    </>
  );
};

// check-in-out
import {Option} from 'library/components/select';
import {range} from 'ramda';
import {ELanguage} from 'source/utilities/language';
import {END_OF_THE_DAY} from './constants';

export const MAX_VALUE_DEPOSIT = 100000000;

export const buildStartTimeOptions = () => {
  const build: Option[] = [];

  range(0, 24).forEach((index) => {
    const time = `${index <= 9 ? '0' : ''}${index}:00`;

    build.push({
      label: time,
      value: time,
    });
  });

  // 00:00 => 23:00
  return build;
};

export const buildEndTimeOptions = () => {
  const build: Option[] = [];

  range(0, 25).forEach((index) => {
    const time = `${index <= 9 ? '0' : ''}${index}:00`;

    if (index === 0) {
      return;
    }

    if (index === 24) {
      build.push({
        label: END_OF_THE_DAY,
        value: END_OF_THE_DAY,
      });

      return;
    }

    build.push({
      label: time,
      value: time,
    });
  });

  // 01:00 => 00:00
  return build;
};

export const START_TIME_OPTIONS: Option[] = buildStartTimeOptions();
export const END_TIME_OPTIONS: Option[] = buildEndTimeOptions();
export const DEFAULT_DESCRIPTION_VALUE = null;
export const DEFAULT_SECURITY_DEPOSIT_VALUE = undefined;

export const initialPoliciesState: Policies = {
  pets_allowed: {
    type: {
      value: 1,
      title: '',
    },
    amount: 0,
    description: [
      {
        value: DEFAULT_DESCRIPTION_VALUE,
        locale: ELanguage.RU,
      },
      {
        value: DEFAULT_DESCRIPTION_VALUE,
        locale: ELanguage.EN,
      },
    ],
  },
  payment_methods: [],
  confirmation_invoice: false,
  placement_time: {
    description: [
      {
        value: DEFAULT_DESCRIPTION_VALUE,
        locale: ELanguage.RU,
      },
      {
        value: DEFAULT_DESCRIPTION_VALUE,
        locale: ELanguage.EN,
      },
    ],
    checkin_start: '',
    checkin_end: '',
    checkout_start: '',
    checkout_end: '',
  },
  registration_fee_foreign_citizens: 0,
  taxes_and_fees: [],
  security_deposit: {
    amount: 0,
    type: undefined,
  },
};

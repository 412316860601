import {HttpStatusCodeHandlers} from 'library/api/interceptors/catch/status-code/lib/constants/handlers';
import type {IHttpStatusCodeHandlers} from 'library/api/interceptors/catch/status-code/lib/types';
import {HttpStatusCode} from 'source/utilities/enums/http';
import {
  throwRedirectException,
  throwSentryObjectError,
} from 'source/utilities/exceptions/network';
import {isServer as isServerMethod} from 'source/utilities/guards/environment';
import {
  isApiException,
  isUnhandledException,
} from 'source/utilities/guards/exceptions';
import {redirects} from 'source/utilities/network/url';

export const interceptHttpsStatusCodes = (
  parameters: HttpRequestParameters,
  customHandlers?: Partial<IHttpStatusCodeHandlers>,
) => {
  const [response] = parameters;
  const status = response.status as HttpStatusCode;
  const handlers = {...HttpStatusCodeHandlers, ...customHandlers};
  const handler = handlers[status];
  const isServer = isServerMethod();

  if (handler) {
    handler({
      parameters,
      isServer,
    });
    throwSentryObjectError(
      {...response},
      `Handler was called that triggered due to an API error with the status - ${status}`,
    );
  }

  if (
    !handler &&
    isApiException(parameters) &&
    isUnhandledException(parameters)
  ) {
    if (!isServer) {
      throw parameters;
    }
    throwSentryObjectError(
      {...response},
      `Error interceptor could not handle it, and redirected the user to the server error page - ${status}`,
    );

    throwRedirectException(redirects.serverErrorPage)();
  }
};

import {FC, ReactNode} from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  background: ${({theme}) => theme.palette.fontAccent};
  box-shadow: 0px 4px 20px rgba(220, 220, 220, 0.4);
  border-radius: 6px;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
`;

interface CardProps {
  className?: string;
  children: ReactNode;
}

export const Card: FC<CardProps> = ({className, children}) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {useContext} from 'react';
import {HeaderButton} from 'source/components/header/ui';
import {HotelContext} from 'source/context/hotel';
import {HOSTNAME, isClient} from 'source/utilities/guards/environment';

const Wrapper = styled.div``;

const ViewOnMain = () => {
  const {t} = useTranslation('common');
  const [hotel] = useContext(HotelContext);

  const hotelLink = hotel?.main_hotel_links?.find((link) =>
    link.url.includes(HOSTNAME.replace('**.', '')),
  );

  return (
    <Wrapper>
      {hotelLink && hotelLink.url && (
        <HeaderButton
          iconName="search"
          highlightText
          onClick={() => {
            if (isClient()) {
              window.open(hotelLink.url, '_blank');
            }
          }}
        >
          {t('view_on_main')}
        </HeaderButton>
      )}
    </Wrapper>
  );
};

export default ViewOnMain;

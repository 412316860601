import {
  format,
  parseISO,
  addDays,
  getDaysInYear,
  isWithinInterval,
  parse,
} from 'date-fns';
import {capitalizeFirstLetter} from 'source/utilities/strings';

// from yyyy-MM-dd to Date
export const parseBackendDateFormat = (date: string) => {
  return parseISO(date);
};

// from yyyy/MM/dd to Date
export const parseRangeDateToBackendDateFormat = (date: string) => {
  return parse(date, 'yyyy/MM/dd', new Date());
};

// from Date to dd.MM.yyyy
export const getViewFormatFromDate = (date: Date) => {
  return format(date, 'dd.MM.yyyy');
};

// from Date to dd.MM.yyyy
export const getViewFormatFromDateWithShortYears = (date: Date) => {
  return format(date, 'dd.MM.yy');
};

// from yyyy-MM-dd to dd.MM.YYYY
export const getViewFormatFromBackendDate = (date: string) => {
  const parsedDate = parseISO(date);
  return getViewFormatFromDate(parsedDate);
};

// from yyyy-MM-dd to yyyy/MM/dd
export const getSlashDateFormat = (date: string) => {
  return format(new Date(date), 'yyyy/MM/dd');
};

// from Date to yyyy-MM-dd
export const getBackendDateFormat = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

// parse dates from calendar component
export const parseCalendarDates = (dates: {startDate: Date; endDate: Date}) => {
  return {
    startDate: getBackendDateFormat(dates.startDate),
    endDate: getBackendDateFormat(dates.endDate),
  };
};

// returns amount of days in the current year
export const getCurrentYearDays = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  return getDaysInYear(year);
};

// adds amount of days in the current year(amount depends on leap or non-leap year) to the date
export const addCurrentYearDays = (date: Date) => {
  const daysToAdd = getCurrentYearDays();

  return addDays(date, daysToAdd);
};

// checks is compareRange within the dates range
export const isDatesWithinRange = (
  from: Date,
  to: Date,
  compareFrom: Date,
  compareTo: Date,
) => {
  const isFromWithinRange = isWithinInterval(compareFrom, {
    start: from,
    end: to,
  });
  const isToWithinRange = isWithinInterval(compareTo, {start: from, end: to});

  return isFromWithinRange && isToWithinRange;
};

// from Date to dd mothsName (oct.) yyyy
// 10 окт. 2023
export const getViewMonthsName = (date: Date) =>
  `${format(date, 'dd')} ${format(date, 'LLL')} ${format(date, 'y')}`;

// from yyyy-MM-dd to dd mothsName (oct.) yyyy
// 10 окт. 2023
export const getViewMonthsNameFromBackendDate = (date: string) =>
  getViewMonthsName(parseISO(date));

// from yyyy-MM-dd to dayName, day
// Вт 11
export const getViewDayWithName = (date: string) =>
  `${capitalizeFirstLetter(format(parseISO(date), 'EEEEEE'))} ${format(
    parseISO(date),
    'dd',
  )}`;

// from yyyy/MM/dd to monthaName, year
// Октябрь 2023
export const getViewMonthWithYear = (date: string) => {
  return `${capitalizeFirstLetter(format(parseISO(date), 'LLLL'))} ${format(
    parseISO(date),
    'yyyy',
  )}`;
};

import {request} from 'library/api';
import {path} from 'ramda';
import {RoomCategories} from 'slices/add-room/lib/types';
import {url} from 'source/utilities/network/url';
import {
  DirectoriesBedTypesListResponse,
  DirectoriesBookingHotelierStatusesListResponse,
  DirectoriesBookingPaymentTypesListResponse,
  DirectoriesBookingPaymentVerificationListResponse,
  DirectoriesBookingSortsListResponse,
  DirectoriesBookingStatusesListResponse,
  DirectoriesDepositPaymentTypesListResponse,
  DirectoriesRoomFacilityGroupsListResponse,
  DirectoriesPaymentMethodsListResponse,
  DirectoriesPetsAllowedTypeListResponse,
  DirectoriesTaxesAndFeesCalculatingListResponse,
  DirectoriesTaxesAndFeesListResponse,
  RawHotelTypesListResponse,
  DirectoriesMealTypesListResponse,
  DirectoriesHotelFacilityGroupsListResponse,
} from 'types/api-scheme';

type GetPaymentMethodsContext = {
  headers: Headers;
};
const getPaymentMethods = ({headers}: GetPaymentMethodsContext) =>
  request<DirectoriesPaymentMethodsListResponse>(
    url('directories/payment-methods'),
    {
      method: 'GET',
      headers,
    },
  ).then(path<DirectoriesPaymentMethodsListResponse['data']>([1, 'data']));

type GetPetsAllowedTypeContext = {
  headers: Headers;
};
const getPetsAllowedType = ({headers}: GetPetsAllowedTypeContext) =>
  request<DirectoriesPetsAllowedTypeListResponse>(
    url('directories/pets-allowed-type'),
    {
      method: 'GET',
      headers,
    },
  ).then(path<DirectoriesPetsAllowedTypeListResponse['data']>([1, 'data']));

type GetDepositPaymentTypesContext = {
  headers: Headers;
};
const getDepositPaymentTypes = ({headers}: GetDepositPaymentTypesContext) =>
  request<DirectoriesDepositPaymentTypesListResponse>(
    url('directories/deposit-payment-types'),
    {
      method: 'GET',
      headers,
    },
  ).then(path<DirectoriesDepositPaymentTypesListResponse['data']>([1, 'data']));

type GetTaxesAndFeesContext = {
  headers: Headers;
};
const getTaxesAndFees = ({headers}: GetTaxesAndFeesContext) =>
  request<DirectoriesTaxesAndFeesListResponse>(
    url('directories/taxes-and-fees'),
    {
      method: 'GET',
      headers,
    },
  ).then(path<DirectoriesTaxesAndFeesListResponse['data']>([1, 'data']));

type GetTaxesAndFeesCalculatingContext = {
  headers: Headers;
};
const getTaxesAndFeesCalculating = ({
  headers,
}: GetTaxesAndFeesCalculatingContext) =>
  request<DirectoriesTaxesAndFeesCalculatingListResponse>(
    url('directories/taxes-and-fees-calculating'),
    {
      method: 'GET',
      headers,
    },
  ).then(
    path<DirectoriesTaxesAndFeesCalculatingListResponse['data']>([1, 'data']),
  );

type GetBedTypesContext = {
  headers: Headers;
};
const getBedTypes = ({headers}: GetBedTypesContext) =>
  request<DirectoriesBedTypesListResponse>(url('directories/bed-types'), {
    method: 'GET',
    headers,
  }).then(path<DirectoriesBedTypesListResponse['data']>([1, 'data']));

type GetRoomCategoriesContext = {
  headers: Headers;
};
const getRoomCategories = ({headers}: GetRoomCategoriesContext) =>
  request<RoomCategories>(url('directories/room-categories'), {
    method: 'GET',
    headers,
  }).then(path<RoomCategories>([1, 'data']));

type GetRoomFacilityGroupsContext = {
  headers: Headers;
};
const getRoomFacilityGroups = ({headers}: GetRoomFacilityGroupsContext) =>
  request<DirectoriesRoomFacilityGroupsListResponse>(
    url('directories/room-facility-groups'),
    {
      method: 'GET',
      headers,
    },
  ).then(path<DirectoriesRoomFacilityGroupsListResponse['data']>([1, 'data']));

type GetHotelFacilityGroupsContext = {
  headers: Headers;
};

const getHotelFacilityGroups = ({headers}: GetHotelFacilityGroupsContext) =>
  request<DirectoriesHotelFacilityGroupsListResponse>(
    url('directories/hotel-facility-groups'),
    {
      method: 'GET',
      headers,
    },
  ).then(path<DirectoriesHotelFacilityGroupsListResponse['data']>([1, 'data']));

type GetBookingPaymentMethodsContext = {
  headers: Headers;
};

const getBookingPaymentMethods = ({headers}: GetBookingPaymentMethodsContext) =>
  request<DirectoriesBookingPaymentTypesListResponse>(
    url('directories/booking-payment-types'),
    {headers},
  ).then(path<BookingPaymentMethods>([1, 'data']));

type GetBookingPaymentVerificationContext = {
  headers: Headers;
};

const getBookingPaymentVerification = ({
  headers,
}: GetBookingPaymentVerificationContext) =>
  request<DirectoriesBookingPaymentVerificationListResponse>(
    url('directories/booking-payment-verification'),
    {headers},
  ).then(path<PaymentVerification>([1, 'data']));

type GetBookingSortsContext = {
  headers: Headers;
};

const getBookingSorts = ({headers}: GetBookingSortsContext) =>
  request<DirectoriesBookingSortsListResponse>(
    url('directories/booking-sorts'),
    {headers},
  ).then(path<BookingSorts>([1, 'data']));

type GetBookingHotelierStatusesContext = {
  headers: Headers;
};

const getBookingHotelierStatuses = ({
  headers,
}: GetBookingHotelierStatusesContext) =>
  request<DirectoriesBookingHotelierStatusesListResponse>(
    url('directories/booking-hotelier-statuses'),
    {
      headers,
    },
  ).then(path<BookingHotelierStatuses>([1, 'data']));

type GetBookingStatusesContext = {
  headers: Headers;
};

const getBookingStatuses = ({headers}: GetBookingStatusesContext) =>
  request<DirectoriesBookingStatusesListResponse>(
    url('directories/booking-statuses'),
    {
      headers,
    },
  ).then(path<BookingStatuses>([1, 'data']));

type GetHotelTypesContext = {
  headers: Headers;
};

const getHotelTypes = ({headers}: GetHotelTypesContext) =>
  request<RawHotelTypesListResponse>(url('raw/hotel-types'), {
    headers,
  }).then(path<HotelTypes>([1, 'data']));

type GetMealTypesContext = {
  headers: Headers;
};

const getMealTypes = ({headers}: GetMealTypesContext) =>
  request<DirectoriesMealTypesListResponse>(url('directories/meal-types'), {
    headers,
  }).then(path<MealTypes>([1, 'data']));

export const directoriesApi = {
  getPaymentMethods,
  getPetsAllowedType,
  getDepositPaymentTypes,
  getTaxesAndFees,
  getTaxesAndFeesCalculating,
  getBedTypes,
  getRoomCategories,
  getRoomFacilityGroups,
  getHotelFacilityGroups,
  getBookingPaymentMethods,
  getBookingPaymentVerification,
  getBookingSorts,
  getBookingHotelierStatuses,
  getBookingStatuses,
  getHotelTypes,
  getMealTypes,
};

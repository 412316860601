import {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import Popup from 'reactjs-popup';
import useTranslation from 'next-translate/useTranslation';
import {CloseIcon} from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';

import {useAppSelector, useAppDispatch} from 'source/store';
import {showException} from 'source/utilities/exceptions/business';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {Checkbox} from 'library/components/checkbox';
import {Button} from 'library/components/button';
import {Text} from 'library/components/text';

import ServiceTable from 'slices/facilities/ui/service-table';
import {EServiceTypeValues} from 'slices/facilities';
import {selectRate, setRateServices} from 'slices/rate/store';
import {EPriceType, PriceType} from 'slices/rate/lib/types';
import {PRICE_TYPE_OPTIONS} from 'slices/rate/lib/constants';
import {getCurrentCheckboxValue} from 'slices/rate/lib/helpers';
import BnovoProvider from 'source/components/bnovo-provider';

const Wrapper = styled.div``;

const StyledPopup = styled(Popup)`
  &-content {
    padding: 40px 25px 0 25px;
    width: 100%;
    max-width: 300px;

    @media (max-width: 850px) {
      max-width: 95vw;
    }
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const CheckboxTitle = styled(Text)`
  margin-bottom: 10px;
`;

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 25px;
  overflow-y: scroll;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  top: 5px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 0px;
  border: 0px;
`;

const HotelServices = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('rate');
  const mobile = useDeviceDetection('mobile');

  const rate = useAppSelector(selectRate);
  const {paidServices, rateSaving} = rate;
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<HotelService | null>(
    null,
  );
  const [priceType, setPriceType] = useState<PriceType>(PRICE_TYPE_OPTIONS[0]);
  const otherServices = Array.isArray(rate?.rate?.services?.others)
    ? rate.rate.services.others
    : [];
  const mealService = rate?.rate?.services?.meal;

  const addedServices = [
    ...otherServices,
    ...(mealService ? [mealService] : []),
  ];

  const handleAddService = () => {
    if (!selectedService) {
      return;
    }
    const previousRateServices = rate.rate.services;

    const normalizedService = {
      hotel_service_id: selectedService.id,
      price_type: priceType.value,
    };

    if (selectedService.type === EServiceTypeValues.MEAL) {
      if (previousRateServices.meal) {
        const message = t('meal_already_exists');
        showException(message);
        return;
      }

      dispatch(
        setRateServices({
          ...previousRateServices,
          meal: normalizedService,
        }),
      );
    } else {
      dispatch(
        setRateServices({
          ...previousRateServices,
          others: [normalizedService, ...(previousRateServices.others || [])],
        }),
      );
    }

    setSelectedService(null);
  };

  const handleDeleteService = (service: HotelService) => {
    const previousRateServices = rate.rate.services;
    const isMealType = service.type === EServiceTypeValues.MEAL;

    if (isMealType) {
      dispatch(
        setRateServices({
          ...previousRateServices,
          meal: undefined,
        }),
      );
    }

    if (!isMealType) {
      const newPreviousRateServices = previousRateServices.others.filter(
        (item) => service.id !== item.hotel_service_id,
      );

      dispatch(
        setRateServices({
          ...previousRateServices,
          others: newPreviousRateServices,
        }),
      );
    }

    setSelectedService(null);
  };

  const handleClickAddService = (service: HotelService) => {
    setSelectedService(service);
  };

  useEffect(() => {
    setOpen(Boolean(selectedService));
  }, [selectedService]);

  return (
    <Wrapper>
      <StyledPopup
        modal
        open={open}
        onClose={() => setSelectedService(null)}
        closeOnEscape
        closeOnDocumentClick
        lockScroll
      >
        <CloseButton onClick={() => setOpen(false)} disabled={rateSaving}>
          <CloseIcon />
        </CloseButton>
        <PopupWrapper>
          <CheckboxWrapper>
            <CheckboxTitle size="L">{t('payment_charged')}</CheckboxTitle>
            <Checkbox
              label={t('price_type_general')}
              value={getCurrentCheckboxValue(EPriceType.GENERAL, priceType)}
              isMobile={mobile}
              onChange={() => setPriceType(PRICE_TYPE_OPTIONS[0])}
            />
            <Checkbox
              label={t('price_type_per_guest')}
              value={getCurrentCheckboxValue(EPriceType.PER_GUEST, priceType)}
              isMobile={mobile}
              onChange={() => setPriceType(PRICE_TYPE_OPTIONS[1])}
            />
            <Checkbox
              label={t('price_per_day')}
              value={getCurrentCheckboxValue(EPriceType.PER_DAY, priceType)}
              isMobile={mobile}
              onChange={() => setPriceType(PRICE_TYPE_OPTIONS[2])}
            />
            <Checkbox
              label={t('price_per_day_per_guest')}
              value={getCurrentCheckboxValue(
                EPriceType.PER_DAY_PER_GUEST,
                priceType,
              )}
              isMobile={mobile}
              onChange={() => setPriceType(PRICE_TYPE_OPTIONS[3])}
            />
          </CheckboxWrapper>
          <ButtonsWrapper>
            <StyledButton hoverOpacity onClick={handleAddService}>
              {t('add_into_rate')}
            </StyledButton>
            <StyledButton hoverOpacity onClick={() => setSelectedService(null)}>
              {t('cancel')}
            </StyledButton>
          </ButtonsWrapper>
        </PopupWrapper>
      </StyledPopup>
      <BnovoProvider
        disableEvents={false}
        title={t('services.bnovo_integration.title')}
        subTitle={t('services.bnovo_integration.subtitle')}
      >
        <ServiceTable
          services={paidServices}
          loading={rateSaving}
          translationKey="rate"
          isRatePage
          onAdd={handleClickAddService}
          addedServices={addedServices}
          onDelete={handleDeleteService}
        />
      </BnovoProvider>
    </Wrapper>
  );
};

export default HotelServices;

import {css} from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {memo, FC} from 'react';

import {useAppSelector} from 'source/store';

import {selectIsLoading} from 'slices/calendar/store';

import {TableButton} from '../../table-button';

const Wrapper = styled.div`
  padding-top: 5px;
`;

const StyledMonthButton = styled(TableButton)<{
  selected?: boolean;
}>`
  width: fit-content;
  min-width: 90px;
  padding: 4px 12px;

  ${({theme, selected}) =>
    selected &&
    css`
      color: ${theme.palette.fontPrimary};
      border-color: ${theme.palette.border_4};
    `}

  ${({disabled}) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;

interface IProps {
  onResetSelectedDates: () => void;
}

const TodayCellComponent: FC<IProps> = ({onResetSelectedDates}) => {
  const {t} = useTranslation('calendar');
  const loading = useAppSelector(selectIsLoading);

  return (
    <Wrapper>
      <StyledMonthButton onClick={onResetSelectedDates} disabled={loading}>
        {t('today')}
      </StyledMonthButton>
    </Wrapper>
  );
};

export const TodayCell = memo(TodayCellComponent);

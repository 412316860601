import styled from '@emotion/styled';
import {IconButton} from 'library/components/icon-button';
import {useRouter} from 'next/router';
import {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import {Icon} from 'source/components/icon';
import MenuPopup from '../menu-popup';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const BurgerTablet: FC<{
  children: ReactNode;
}> = ({children}) => {
  const [open, setOpen] = useState(false);
  const closeDropdown = useCallback(() => setOpen(false), []);
  const openDropdown = useCallback(() => setOpen(true), []);
  const router = useRouter();

  useEffect(() => {
    if (router) {
      router.events.on('routeChangeStart', closeDropdown);
    }
  }, [router, closeDropdown]);

  return (
    <MenuPopup
      trigger={
        <IconButton>
          <StyledIcon
            name="burger-menu"
            width={36}
            height={36}
            onClick={openDropdown}
          />
        </IconButton>
      }
      onOpen={openDropdown}
      onClose={closeDropdown}
      open={open}
      closeOnDocumentClick
      arrow={false}
      offsetY={20}
      position="bottom right"
    >
      {children}
    </MenuPopup>
  );
};

export default BurgerTablet;

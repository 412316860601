export {type GeneralInfoPageProperties} from './lib/types';
export {getGeneralInfoPageProperties} from './network';
export {default as GeneralInfoContent} from './content';
export {
  default as generalInfoSlice,
  setHotelModerationLast,
  setRates,
  setModerationDescriptionList,
  selectRates,
  selectHotelModerationLast,
  selectModerationDescriptionList,
} from './store';
export {DEFAULT_HOTEL_MODERATION_LAST} from './lib/constants';

import {STATUS_BADGE_OBJECT} from './constants';
import {ModerationStatusEnum} from './types';

export const getStatusBadgeParameters = (status?: ModerationStatusEnum) => {
  switch (status) {
    case ModerationStatusEnum.REQUESTED: {
      return STATUS_BADGE_OBJECT[ModerationStatusEnum.REQUESTED];
    }
    case ModerationStatusEnum.APPROVED: {
      return STATUS_BADGE_OBJECT[ModerationStatusEnum.APPROVED];
    }
    case ModerationStatusEnum.DECLINED: {
      return STATUS_BADGE_OBJECT[ModerationStatusEnum.DECLINED];
    }
    default: {
      return STATUS_BADGE_OBJECT[ModerationStatusEnum.REQUESTED];
    }
  }
};

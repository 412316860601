import {transliterateWords} from 'source/utilities/constants/transliterate-words';
import {ELanguage} from 'source/utilities/language';

export function formatErrorMessage(
  messageTemplate: string,
  fieldName: string,
): string {
  return messageTemplate.replace('{field}', fieldName);
}

export const isPlusFirstExist = (value: string) => value.trim()[0] === '+';

export const formatMoney = (
  amount: string | number = '',
  locale: string = ELanguage.RU,
  options: {round?: number} = {},
) => {
  let value = (
    typeof amount === 'number' && options.round
      ? amount % 1 === 0 // Целые неокругляем
        ? amount
        : amount.toFixed(options.round)
      : amount
  )
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

  const array = value.split('.');
  if (array.length > 1) {
    value = `${array[0]}.${array[1].replace(/ /g, '')}`;
  }

  return locale === ELanguage.RU ? value.replace('.', ',') : value;
};

export const capitalizeFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const buildFullName = (user: User | null) =>
  user
    ? [user.first_name, user.last_name]
        .map((name) => name ?? '')
        .join(' ')
        .trim()
    : '';

export const transliterate = (word: string) =>
  [...word]
    .map(function (char) {
      return (
        transliterateWords[char as keyof typeof transliterateWords] || char
      );
    })
    .join('');

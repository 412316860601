import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {LabeledComponent} from 'library/components/labeled-component';
import useTranslation from 'next-translate/useTranslation';
import {FC, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'source/store';
import {SearchInput} from 'library/components/search';
import {path} from 'ramda';
import {OrganizationsListResponse} from 'types/api-scheme';
import {getLegalForm} from '../lib/helpers';
import {
  selectExistingRequisitesInfo,
  setRequisitesLabel,
  setLegalForm,
  setRequisites,
} from '../store';
import {searchExistingRequisites} from '../network';
import {OrganizationsSearchResult} from './requisites-list';

const RequisitesWrapper = styled(LabeledComponent)`
  margin-bottom: 20px;
  max-width: 440px;
`;

interface ExistingRequisitesProps {
  serverRequisites?: Requisites | null;
}

export const ExistingRequisites: FC<ExistingRequisitesProps> = ({
  serverRequisites,
}) => {
  const {t} = useTranslation('requisites');
  const theme = useTheme();
  const {
    requisitesExist,
    requisitesLabel,
    isRequisitesChosen,
    uploadingRequisites,
  } = useAppSelector(selectExistingRequisitesInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setRequisitesLabel(serverRequisites?.name || ''));
  }, []);

  useEffect(() => {
    if (!isRequisitesChosen) {
      dispatch(setRequisitesLabel(''));
    }
  }, [isRequisitesChosen]);

  if (requisitesExist) {
    return null;
  }

  const handleOrganizationInput = (value?: string) => {
    dispatch(setRequisitesLabel(value || ''));
  };

  const onOrganizationClick = (organization: ExistingRequisitesItem) => {
    dispatch(setRequisites(organization));
    const legalForm = getLegalForm(organization.type.value);
    if (legalForm) {
      dispatch(setLegalForm(legalForm.value));
    }
  };

  return (
    <RequisitesWrapper
      text={t('requisites')}
      size="S"
      gap={10}
      color={theme.palette.fontSecondary}
    >
      <SearchInput<OrganizationsListResponse, ExistingRequisitesItem>
        selected={requisitesLabel || ''}
        resolver={searchExistingRequisites}
        mapper={path<ExistingRequisitesItem[]>([1, 'data', 'items'])}
        disabled={uploadingRequisites}
        controlledPhrase={requisitesLabel || ''}
        onChange={handleOrganizationInput}
        minimumCharacters={0}
        withPagination
        placeholder={t('search_requisites')}
        paginationMapper={path<ExistingRequisitesPagination>([
          1,
          'data',
          'pagination',
        ])}
      >
        {(organizations, setSelected) => (
          <OrganizationsSearchResult
            organizations={organizations}
            setSelected={setSelected}
            onOrganizationClick={onOrganizationClick}
          />
        )}
      </SearchInput>
    </RequisitesWrapper>
  );
};

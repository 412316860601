import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {stylable} from 'library/utilities/stylable';

export const STYLE_BY_TEXT_SIZE = {
  buttonXS: css`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  XS: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  `,
  boldXS: css`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  `,
  S: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  `,
  boldS: css`
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  `,
  M: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  `,
  boldM: css`
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  `,
  L: css`
    font-size: 25px;
    font-weight: 500;
    line-height: 33px;
  `,
  boldL: css`
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
  `,
  XL: css`
    font-size: 37px;
    font-weight: 500;
    line-height: 46px;
  `,
  boldXL: css`
    font-size: 37px;
    font-weight: 700;
    line-height: 46px;
  `,
  XXL: css`
    font-size: 44px;
    font-weight: 500;
    line-height: 55px;
  `,
  boldXXL: css`
    font-size: 44px;
    font-weight: 700;
    line-height: 55px;
  `,
};

export type TextSize = keyof typeof STYLE_BY_TEXT_SIZE;

interface StyledProperties {
  size: TextSize;
  color?: string;
  align?: string;
}

const Wrapper = styled.p<StyledProperties>`
  ${({size}) => STYLE_BY_TEXT_SIZE[size]};
  color: ${({color, theme}) => color || theme.palette.fontDefault};
  text-align: ${({align}) => align || 'unset'};
`;

const SubWrapper = styled.span<StyledProperties>`
  ${({size}) => STYLE_BY_TEXT_SIZE[size]};
  color: ${({color, theme}) => color || theme.palette.fontDefault};
`;

export const Text = stylable(Wrapper);
export const SubText = stylable(SubWrapper);

import styled from '@emotion/styled';
import {Checkbox} from 'library/components/checkbox';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Select} from 'library/components/select';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {assoc, isEmpty, map, propEq, when} from 'ramda';
import {useEffect} from 'react';
import {
  INITIAL_CALCULATION_FROM_DAYS,
  INITIAL_TAX_AMOUNT,
  MAX_CALCULATION_FROM_DAYS,
  MAX_TAX_AMOUNT,
} from 'slices/policies/lib/constants';
import {
  getTaxedAndFeesCalculationOption,
  getTaxedAndFeesCalculationOptions,
  getTaxedAndFeesTypeOption,
  getTaxedAndFeesTypeOptions,
  isCalculationTypeExistCheckDays,
} from 'slices/policies/lib/helpers';
import {
  selectPolicies,
  setIsNeedSaving,
  setPoliciesTaxes,
} from 'slices/policies/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {theme} from 'source/utilities/global-style';
import {isClient} from 'source/utilities/guards/environment';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const TaxWrapper = styled.div`
  letter-spacing: 0.03em;
  cursor: pointer;
  margin: 20px 0px 20px 0px;
  padding: 20px 0px 20px 0px;
  border-top: 1px solid ${({theme}) => theme.palette.border};
`;

const InputsWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({isMobile}) => (isMobile ? '100%' : '440px')};
`;

const CheckBoxWrapper = styled.div<{isMobile: boolean}>`
  margin: ${({isMobile}) =>
    isMobile ? '20px 0px 20px 0px' : '20px 0px 20px 0px'};
`;

const InputContainer = styled.div<{isMobile: boolean}>`
  width: 100%;
  margin: ${({isMobile}) =>
    isMobile ? '0px 0px 20px 0px' : '0px 20px 20px 0px'};
  &:last-child {
    margin: 0;
  }
`;

const RemoveTaxButton = styled(Text)<{isMobile: boolean}>`
  width: fit-content;
  letter-spacing: 0.03em;
  cursor: pointer;
  margin: ${({isMobile}) => (isMobile ? 'auto' : '0px')};
`;

const AddTaxButton = styled(Text)`
  width: fit-content;
  letter-spacing: 0.03em;
  cursor: pointer;
  margin-top: 40px;
  border-radius: 6px;
  padding: 21px 61px;
  border: 1px solid ${({theme}) => theme.palette.border};
`;

const Label = styled(LabeledComponent)``;

const TaxesAndFees = () => {
  const dispatch = useAppDispatch();
  const {policies, directories, isNeedSaving} = useAppSelector(selectPolicies);
  const {taxes_and_fees} = policies;
  const {t} = useTranslation('policies');

  const mobile = useDeviceDetection('mobile');

  const handleAddNewTax = () => {
    const defaultTaxTypeDirectory = directories?.taxesAndFees?.[0];
    const defaultTaxCalculationDirectory =
      directories?.taxesAndFeesCalculating?.[0];

    if (!defaultTaxTypeDirectory || !defaultTaxCalculationDirectory) {
      return;
    }

    if (!taxes_and_fees) {
      return;
    }

    const tax = [...taxes_and_fees];
    const randomId = Date.now();

    dispatch(
      setPoliciesTaxes([
        ...tax,
        {
          id: randomId,
          amount: INITIAL_TAX_AMOUNT,
          tax_and_fee_type: {
            value: defaultTaxTypeDirectory.value,
            title: defaultTaxTypeDirectory.title,
          },
          use_for_child: false,
          calculation_from_days: null,
          calculation_type: {
            value: defaultTaxCalculationDirectory.value,
            title: defaultTaxCalculationDirectory.title,
          },
        },
      ]),
    );
    // setPoliciesTemplateValues(); // раскомментить, если нужно, чтобы при добавлении налога выключался свич - "Заезд и выезд возможен круглосуточно".
    if (isClient()) {
      /**
       * таймаут для того, что бы успели данные которые в сторе сохранится и отобразиться, после чего страница увеличилась, и оно вниз по новому сайзу страницу пошло
       * почему window? Потому что document.body.scrollTop = value не работает
       */
      setTimeout(() => {
        window.scrollTo({
          behavior: 'smooth',
          top: document.body.scrollHeight,
        });
      }, 0);
    }
  };

  const handleUpdateValueInTax = (
    id: number,
    propertyName: keyof (typeof policies.taxes_and_fees)[number],
    value: unknown,
  ) => {
    if (taxes_and_fees) {
      const updateTax = map(
        when(propEq('id', id), assoc(propertyName, value)),
        taxes_and_fees,
      ) as typeof taxes_and_fees;

      dispatch(setPoliciesTaxes([...updateTax]));
    }
  };

  const handleDeleteTax = (id: number) => {
    const updatedTax = taxes_and_fees.filter((tax) => tax.id !== id);
    dispatch(setPoliciesTaxes(updatedTax));
  };

  useEffect(() => {
    if (!isNeedSaving) {
      const updatedTax = taxes_and_fees.filter((tax) => !isEmpty(tax.amount));
      dispatch(setPoliciesTaxes(updatedTax));
      dispatch(setIsNeedSaving(false));
    }
  }, [isNeedSaving]);

  return (
    <div>
      {taxes_and_fees.map((tax) => {
        return (
          <TaxWrapper key={tax.id}>
            <InputsWrapper isMobile={mobile}>
              <InputContainer isMobile={mobile}>
                <Label
                  size="S"
                  gap={11}
                  color={theme.palette.fontSecondary}
                  text={t('taxes-and-fees.type-of-tax')}
                >
                  <Select
                    value={getTaxedAndFeesTypeOption(
                      directories?.taxesAndFees || [],
                      tax.tax_and_fee_type,
                    )}
                    options={getTaxedAndFeesTypeOptions(
                      directories.taxesAndFees,
                    )}
                    setValue={(option) => {
                      handleUpdateValueInTax(tax.id, 'tax_and_fee_type', {
                        title: option.label,
                        value: option.value,
                      });
                    }}
                  />
                </Label>
              </InputContainer>
              <InputContainer isMobile={mobile}>
                <Label
                  size="S"
                  gap={11}
                  color={theme.palette.fontSecondary}
                  text={t('taxes-and-fees.how-calculate-tax')}
                >
                  <Select
                    value={getTaxedAndFeesCalculationOption(
                      directories?.taxesAndFeesCalculating || [],
                      tax.calculation_type,
                    )}
                    options={getTaxedAndFeesCalculationOptions(
                      directories.taxesAndFeesCalculating,
                    )}
                    setValue={(option) => {
                      handleUpdateValueInTax(tax.id, 'calculation_type', {
                        value: option.value,
                        title: option.label,
                      });
                    }}
                  />
                </Label>
              </InputContainer>
              {isCalculationTypeExistCheckDays(
                tax?.calculation_type?.value,
              ) && (
                <InputContainer isMobile={mobile}>
                  <Label
                    size="S"
                    gap={11}
                    color={theme.palette.fontSecondary}
                    text={t('taxes-and-fees.calculation_from_days')}
                  >
                    <Input
                      required
                      maxCount={MAX_CALCULATION_FROM_DAYS}
                      value={String(
                        tax.calculation_from_days ||
                          INITIAL_CALCULATION_FROM_DAYS,
                      )}
                      noDecimals
                      onChange={({target}) =>
                        handleUpdateValueInTax(
                          tax.id,
                          'calculation_from_days',
                          Number(target.value),
                        )
                      }
                      type="number"
                      placeholder={t(
                        'taxes-and-fees.calculation_from_days_placeholder',
                      )}
                    />
                  </Label>
                </InputContainer>
              )}
              <InputContainer isMobile={mobile}>
                <Label
                  size="S"
                  gap={11}
                  color={theme.palette.fontSecondary}
                  text={t('taxes-and-fees.board-size')}
                >
                  <Input
                    required
                    maxCount={MAX_TAX_AMOUNT}
                    value={String(tax.amount)}
                    onChange={({target}) =>
                      handleUpdateValueInTax(
                        tax.id,
                        'amount',
                        Number(target.value),
                      )
                    }
                    type="number"
                    placeholder={t('input-common.amount', {
                      currency: t('common:units_payments.rub'),
                    })}
                  />
                </Label>
              </InputContainer>
            </InputsWrapper>
            <InputsWrapper isMobile={mobile}>
              <CheckBoxWrapper isMobile={mobile}>
                <Checkbox
                  label={t('taxes-and-fees.check-box-placeholder')}
                  value={tax.use_for_child}
                  isMobile={mobile}
                  onChange={(value) => {
                    handleUpdateValueInTax(tax.id, 'use_for_child', value);
                  }}
                />
              </CheckBoxWrapper>
              <RemoveTaxButton
                size="S"
                onClick={() => handleDeleteTax(tax.id)}
                isMobile={mobile}
              >
                {t('taxes-and-fees.remove-taxed')}
              </RemoveTaxButton>
            </InputsWrapper>
          </TaxWrapper>
        );
      })}
      <AddTaxButton size="M" onClick={handleAddNewTax}>
        {t('taxes-and-fees.add-taxed')}
      </AddTaxButton>
    </div>
  );
};

export default TaxesAndFees;

import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {DragEvent, MouseEvent, FC} from 'react';

import {
  GuestNumberPrice,
  SelectedCell,
  SelectRangeType,
} from 'slices/calendar/lib/types';

import {blinkAnimation} from 'slices/calendar/lib/helpers/ui';
import {TableButton} from 'slices/calendar/ui/table-button';
import {buildPriceForCountName} from 'slices/calendar/lib/helpers/prices';
import {CELL_WIDTH} from 'slices/calendar/lib/constants';
import {DataCellInput} from '../data-cell-input';

const DataCell = styled.div<{disabled?: boolean}>`
  min-width: ${CELL_WIDTH}px;

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledDataButton = styled(TableButton)<{
  grey?: boolean;
  selected?: boolean;
  rangeSelected?: boolean;
  updated?: boolean;
  disabled?: boolean;
}>`
  ${({disabled, theme}) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${theme.palette.notAvailableCell};
      opacity: 0.7;
    `}

  ${({theme, selected, updated}) =>
    (selected || updated) &&
    css`
      background-color: ${theme.palette.fontPrimary};
      color: ${theme.palette.fontAccent};
      border-color: ${theme.palette.border_4};
      font-weight: 300;
      animation: ${blinkAnimation} 0.1s;
    `}

  ${({theme, rangeSelected}) =>
    rangeSelected &&
    css`
      background-color: ${theme.palette.borderBlue};
      color: ${theme.palette.fontAccent};
      font-weight: 300;
      animation: ${blinkAnimation} 0.1s;
    `}

  ${({theme, grey}) =>
    grey &&
    css`
      background-color: ${theme.palette.darkerBackground};
    `}
`;

interface IProps {
  room: number;
  index: number;
  rateId: number;
  handleDragOver: (
    buildCell: SelectedCell,
    selectedCell: SelectedCell,
    selectedRange: SelectRangeType,
  ) => void;
  handleDragEnd: (selectedRange: SelectRangeType) => void;
  disabled: boolean;
  updated: boolean;
  selected: boolean;
  rangeSelected: boolean;
  handleSelectStart: (
    event: DragEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>,
    buildCell: SelectedCell,
    guestNumber: number,
  ) => void;
  guestNumber: number;
  handleCellClick: (cell: SelectedCell) => void;
  handleCellDoubleClick: (cell: SelectedCell) => void;
  isRateEdit: boolean;
  priceData: GuestNumberPrice;
  isRateSelected: boolean;
  selectedRange: SelectRangeType;
  selectedCell: SelectedCell;
}

export const PriceButton: FC<IProps> = ({
  room,
  index,
  rateId,
  handleDragOver,
  handleDragEnd,
  disabled,
  updated,
  selected,
  rangeSelected,
  handleSelectStart,
  guestNumber,
  handleCellClick,
  handleCellDoubleClick,
  isRateEdit,
  priceData,
  isRateSelected,
  selectedRange,
  selectedCell,
}) => {
  const buildCell: SelectedCell = {
    room,
    column: index,
    row: buildPriceForCountName(guestNumber),
    rate: rateId,
    price: priceData.price,
    date: priceData.date,
    isDatePrice: priceData.is_date_price,
    guestNumber,
  };

  return (
    <DataCell disabled={disabled}>
      <StyledDataButton
        draggable
        disabled={disabled}
        updated={updated}
        selected={selected}
        rangeSelected={rangeSelected}
        onDragStart={(event) =>
          handleSelectStart(event, buildCell, guestNumber)
        }
        onDragOver={() =>
          handleDragOver(buildCell, selectedCell, selectedRange)
        }
        onDragEnd={() => handleDragEnd(selectedRange)}
        onClick={() => handleCellClick(buildCell)}
        onDoubleClick={() => handleCellDoubleClick(buildCell)}
      >
        <DataCellInput
          isRateEdit={isRateEdit}
          rangeSelected={rangeSelected}
          priceData={priceData}
          cell={buildCell}
          selected={isRateSelected}
        />
      </StyledDataButton>
    </DataCell>
  );
};

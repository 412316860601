import styled from '@emotion/styled';
import {CSSProperties, FC} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {ArrowIcon} from '../arrow-link/arrow';
import {Text} from '../text';

interface BackProps {
  action: () => void;
  className?: string;
  style?: CSSProperties;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
`;

const StyledArrow = styled(ArrowIcon)`
  transform: rotate(90deg);
  width: 11px;
  height: 11px;
`;

const StyledText = styled(Text)`
  color: #ff5876;
`;

export const BackButton: FC<BackProps> = ({action, style, className}) => {
  const {t} = useTranslation('components');
  return (
    <Wrapper onClick={action} style={style} className={className}>
      <StyledArrow color="#ff5876" />
      <StyledText size="XS">{t('back')}</StyledText>
    </Wrapper>
  );
};

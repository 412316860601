import {TFunction} from 'types/utilities';
import {isClient} from '../utilities/guards/environment';

export interface IBitrix {
  SiteButton: {
    buttons: {
      list: {
        id: string;
        onclick: TFunction;
        node: {
          onclick: TFunction;
          href: string;
        };
      }[];
    };
    show: TFunction;
    hide: TFunction;
    shadow: {
      hide: TFunction;
    };
    wm: {
      hide: TFunction;
    };
  };
  LiveChat: {
    openLiveChat: TFunction;
    closeLiveChat: TFunction;
  };
}

const BITRIX_MOBILE_BODY_CLASSNAME = 'bx-livechat-mobile-state';
const BITRIX_BUTTON_CLASSNAME = '.b24-widget-button-inner-container';

export class Bitrix {
  private static bitrix: IBitrix | null = null;

  public static initBitrix() {
    if (this.bitrix) {
      return;
    }

    if (isClient() && 'BX' in window) {
      this.bitrix = window.BX as IBitrix;
    } else {
      // eslint-disable-next-line prettier/prettier
      throw new Error('Bitrix can\'t be initiazlied')
    }
  }

  public static hasBeenSetup() {
    return this.bitrix !== null;
  }

  public static hideBitrix() {
    if (this.bitrix) {
      this.bitrix?.SiteButton?.shadow.hide();
      this.bitrix?.SiteButton?.wm.hide();
      this.bitrix?.LiveChat?.closeLiveChat();
      this.bitrix?.SiteButton?.hide();
      document.body.classList.remove(BITRIX_MOBILE_BODY_CLASSNAME);
    }
  }

  public static showBitrix() {
    if (this.bitrix) {
      this.bitrix?.SiteButton?.show();
    }
  }

  public static showBitrixMenu() {
    if (this.bitrix) {
      const buttonButton = document.querySelectorAll<HTMLButtonElement>(
        BITRIX_BUTTON_CLASSNAME,
      )[0];
      buttonButton.click();
    }
  }

  public static showBitrixLiveChat() {
    if (this.bitrix) {
      this.bitrix?.LiveChat?.openLiveChat();
    }
  }

  public static closeBitrixLiveChat() {
    if (this.bitrix) {
      this.bitrix?.LiveChat?.closeLiveChat();
    }
  }
}

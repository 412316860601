import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {CircularLoader} from 'library/components/loader';
import useTranslation from 'next-translate/useTranslation';
import {loadMoreWithdrawals} from 'slices/balance_details/network';
import {
  selectLoading,
  selectIsLast,
  selectPage,
  setIsLast,
  setLoading,
  setPage,
  setWithdrawals,
  selectWithdrawals,
  selectOrganization,
} from 'slices/balance_details/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {WithdrawalsListResponse} from 'types/api-scheme';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

const StyledButton = styled(Button)`
  width: 205px;
`;

export const LoadMoreButton = () => {
  const {t} = useTranslation('balance_details');
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const withdrawals = useAppSelector(selectWithdrawals);
  const page = useAppSelector(selectPage);
  const organization = useAppSelector(selectOrganization);
  const isLast = useAppSelector(selectIsLast);

  const onLoadMoreSuccess = (
    response: WithdrawalsListResponse['data'],
    nextPage: number,
  ) => {
    dispatch(setWithdrawals([...withdrawals, ...response.items]));
    dispatch(setIsLast(nextPage === response.pagination.last_page));
    dispatch(setPage(nextPage));
  };

  const handleLoadMore = () => {
    if (!organization) {
      return;
    }

    dispatch(setLoading(true));
    const nextPage = page + 1;

    loadMoreWithdrawals(nextPage, organization.id, onLoadMoreSuccess).finally(
      () => dispatch(setLoading(false)),
    );
  };

  if (isLast) {
    return null;
  }

  return (
    <Wrapper>
      <StyledButton onClick={handleLoadMore} disabled={loading}>
        {loading ? (
          <CircularLoader size={16} />
        ) : (
          t('balance_operations_table.load_more')
        )}
      </StyledButton>
    </Wrapper>
  );
};

import styled from '@emotion/styled';
import {EditButton} from 'library/components/edit-button';
import {FC, PropsWithChildren} from 'react';
import Popup from 'reactjs-popup';
import {Icon} from 'source/components/icon';

const StyledPopup = styled(Popup)`
  &-content {
    background: #ffffff;
    width: 460px;
    box-shadow: 0px 4px 20px rgba(220, 220, 220, 0.4);
    border-radius: 6px;
    padding: 0;
  }
`;

const EditButtonStyled = styled(EditButton)``;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

interface EditNameModalProps extends PropsWithChildren {
  title: string;
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const EditModal: FC<EditNameModalProps> = ({
  children,
  title,
  open,
  openModal,
  closeModal,
}) => {
  return (
    <>
      <EditButtonStyled onClick={openModal}>{title}</EditButtonStyled>
      <StyledPopup modal open={open} onClose={closeModal} closeOnDocumentClick>
        <StyledIcon onClick={closeModal} name="cross" width={30} height={30} />
        {children}
      </StyledPopup>
    </>
  );
};

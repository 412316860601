import {FC, Dispatch, SetStateAction, useContext} from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import useTranslation from 'next-translate/useTranslation';

import {HotelContext} from 'source/context/hotel';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {TableBoilerplate} from 'library/components/table';
import {NoContent} from 'library/components/no-content';

import {RateHotelService} from 'slices/rate';
import {useBnovoIntegration} from 'source/utilities/hooks/use-is-bnovo-integration';
import {ServiceTableHead} from '../service-table-head';
import {ServiceTableBody} from '../service-table-body';

const TableWrapper = styled.div<{disabled: boolean; mobile: boolean}>`
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: 21px;
  overflow-x: scroll;

  ${({mobile}) =>
    mobile &&
    css`
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
    `}

  ${({disabled}) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

const StyledNoContent = styled(NoContent)`
  margin: 0;
`;

const NoContentWrapper = styled.div``;

interface Props {
  loading: boolean;
  translationKey: string;
  services: HotelServices;
  onEdit?: Dispatch<SetStateAction<HotelService | null>>;
  onDelete?: (service: HotelService) => void;
  onAdd?: (service: HotelService) => void;
  isRatePage?: boolean;
  addedServices?: RateHotelService[];
}

const ServiceTable: FC<Props> = ({
  loading,
  translationKey,
  services,
  onEdit = () => {},
  onAdd = () => {},
  onDelete = () => {},
  isRatePage = false,
  addedServices = [],
}) => {
  const {t} = useTranslation(translationKey);
  const mobile = useDeviceDetection('mobile');

  const [hasBnovoIntegration] = useBnovoIntegration();
  const [hotel] = useContext(HotelContext);
  const hideServices = isRatePage && hasBnovoIntegration;

  if (!services || services?.length === 0) {
    return (
      <TableWrapper disabled={loading} mobile={mobile}>
        <NoContentWrapper>
          {isRatePage ? (
            <StyledNoContent
              buttonLabel={t('no_content.add_service_label')}
              subtitle={t('no_content.add_service_subtitle')}
              href={`/manage/${hotel?.id}/facilities?isAddService=true`}
            />
          ) : (
            <>{t('no_services')}</>
          )}
        </NoContentWrapper>
      </TableWrapper>
    );
  }

  return (
    <TableWrapper disabled={loading} mobile={mobile}>
      <TableBoilerplate
        rowHeadChildren={
          <ServiceTableHead
            translationKey={translationKey}
            addedServices={addedServices}
            isRatePage={isRatePage}
            hideServices={hideServices}
          />
        }
        rowBodyChildren={
          <ServiceTableBody
            services={services}
            translationKey={translationKey}
            addedServices={addedServices}
            isRatePage={isRatePage}
            onEdit={onEdit}
            onDelete={onDelete}
            onAdd={onAdd}
            hideServices={hideServices}
          />
        }
      />
    </TableWrapper>
  );
};

export default ServiceTable;

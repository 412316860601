import {reset} from 'slices/profile/store';
import {DURATION_POLLING_UPDATE_USER} from 'slices/profile/ui/social-subscribe/lib/constants';
import {store} from 'source/store';
import {api} from 'source/utilities/api';
import {isSafari} from 'source/utilities/browser-detected';
import {handleException} from 'source/utilities/exceptions/business';
import CLongPolling from 'source/utilities/long-polling';
import {clientHeaders} from 'source/utilities/network/http';

export const pollingSubscribeUser = (setUser: UserDispatch) => {
  const abortController = new AbortController();

  const updateUser = async () => {
    api.auth
      .get({headers: clientHeaders(), signal: abortController.signal})
      .then((user) => {
        if (user?.telegram_user_id) {
          setUser(user);
        }
      });
  };

  return new CLongPolling({
    duration: DURATION_POLLING_UPDATE_USER,
    takeWhile: () => true,
    mergeMap: updateUser,
    finalize: () => {},
    abortController,
  });
};

export const handleDetachToTelegram = (
  ...dispatchers: [BooleanDispatch, UserDispatch]
) => {
  const [setIsLoadingTelegramLink, setUser] = dispatchers;

  setIsLoadingTelegramLink(true);

  api.socialSubscribe
    .detachTelegram()
    .then(() => {
      setUser((previous) => {
        if (!previous) {
          return previous;
        }

        const clone = {...previous};

        if (clone?.telegram_user_id) {
          clone.telegram_user_id = null;
        }

        return clone;
      });
      store.dispatch(reset());
    })
    .finally(() => {
      setIsLoadingTelegramLink(false);
    });
};

export const handleAttachToTelegram = (...dispatchers: [BooleanDispatch]) => {
  const [setIsLoadingTelegramLink] = dispatchers;

  setIsLoadingTelegramLink(true);

  api.socialSubscribe
    .attachTelegram()
    .then((response) => {
      if (response) {
        if (isSafari()) {
          window.location.assign(response.link);
          return;
        }

        window.open(response.link);
      }
    })
    .catch(handleException)
    .finally(() => setIsLoadingTelegramLink(false));
};

import {css} from '@emotion/react';
import {theme} from 'source/utilities/global-style';

export const BUTTON_STYLES_BY_TYPE = {
  action: css`
    color: ${theme.palette.fontAccent};
    &:hover {
      border-color: ${theme.palette.fontInvert};
    }
  `,
  secondary: css`
    color: ${theme.palette.fontDefault};
    border-color: ${theme.palette.fontInvert};
    background-color: ${theme.palette.fontAccent};
    &:hover {
      border-color: ${theme.palette.primaryAccent};
    }
  `,
};

export const BUTTON_STYLES_BY_SIZE = {
  XS: css`
    padding: 15px 30px;
  `,
  S: css`
    padding: 20px 40px;
  `,
  M: css`
    padding: 20px 40px;
  `,
  L: css`
    padding: 20px 40px;
  `,
  XL: css`
    padding: 20px 40px;
  `,
  XXL: css`
    padding: 20px 40px;
  `,
};

import styled from '@emotion/styled';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  GeolocationControl,
  Map,
  Placemark,
  SearchControl,
} from '@pbe/react-yandex-maps';
import {YMapsApi} from '@pbe/react-yandex-maps/typings/util/typing';
import {Input} from 'library/components/input';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useState} from 'react';
import {
  DEFAULT_YANDEX_MAP_COORDS,
  DEFAULT_YANDEX_MAP_ZOOM,
} from 'slices/create_hotelier_object/lib/constants';
// eslint-disable-next-line import/no-cycle
import {
  selectCreateHotelierObject,
  setAddress,
  setLat,
  setLng,
} from 'slices/create_hotelier_object/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {ObjectManager} from 'yandex-maps';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  @media (max-width: 480px) {
    min-width: 100%;
  }
`;

const Headline = styled(Text)`
  margin-bottom: 22px;
`;

const MapWrapper = styled.div`
  width: 542px;
  height: 440px;
  margin-bottom: 54px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CoordsWrapper = styled.div``;

const CoordsDetail = styled(Input)``;

const SelectMap = () => {
  const {t} = useTranslation('create_hotelier_object');

  const dispatch = useAppDispatch();
  const {lat, lng} = useAppSelector(selectCreateHotelierObject);

  const [ymap, setYmap] = useState<YMapsApi | undefined>();
  const [placeMark, setPlaceMark] = useState<ymaps.Placemark | undefined>();

  const handleSetCoordsAndAddress = (coords?: number[] | null) => {
    if (placeMark && ymap && coords) {
      placeMark.geometry?.setCoordinates(coords);
      ymap.geocode(coords).then((response) => {
        const object = response.geoObjects.get(
          0
        ) as typeof response.geoObjects & YandexMapGeoCodeActions;

        const address = `${object.getCountry()}, ${object.getAddressLine()}`;
        const [lat, lng] = coords;
        dispatch(setAddress(address));
        dispatch(setLat(lat));
        dispatch(setLng(lng));
      });
    }
  };

  const handleOnSearch = (ref: ymaps.Map) => {
    const searchControl = ref as unknown as ymaps.control.SearchControl;
    searchControl?.events?.add('resultselect', (event) => {
      const index = event.get('index');
      searchControl
        ?.getResult(index)
        .then((response) =>
          handleSetCoordsAndAddress(
            (response as unknown as ymaps.Placemark).geometry?.getCoordinates()
          )
        );
    });
  };

  return (
    <Wrapper>
      <Headline size="S">{t('select_map.headline')}</Headline>
      <MapWrapper>
        <Map
          modules={['geocode']}
          onLoad={(yandexMapInstance) => setYmap(yandexMapInstance)}
          defaultState={{
            center: DEFAULT_YANDEX_MAP_COORDS,
            zoom: DEFAULT_YANDEX_MAP_ZOOM,
          }}
          options={{
            suppressMapOpenBlock: true,
          }}
          width="100%"
          height="100%"
          instanceRef={(mapObject) => {
            if (mapObject) {
              mapObject.events.add('click', (event) =>
                handleSetCoordsAndAddress(event.get('coords'))
              );
            }
          }}
        >
          <GeolocationControl options={{float: 'left'}} />
          <SearchControl
            options={{float: 'right', noPlacemark: true}}
            instanceRef={handleOnSearch}
          />
          <Placemark
            geometry={DEFAULT_YANDEX_MAP_COORDS}
            instanceRef={(placeMarkObject) => {
              if (placeMarkObject) {
                setPlaceMark(placeMarkObject as unknown as ymaps.Placemark);
              }
            }}
          />
        </Map>
      </MapWrapper>
      <CoordsWrapper>
        <CoordsDetail
          required
          value={lat && lng ? `${lat}, ${lng}` : ''}
          placeholder={t('select_map.coords_placeholder')}
          type="text"
          disabled={!(lat && lng)}
        />
      </CoordsWrapper>
    </Wrapper>
  );
};

export default SelectMap;

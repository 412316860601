import {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {clientHeaders, serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {ApplicationProperties} from 'source/utilities/ui';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {DEFAULT_PAGE} from 'source/utilities/constants/logic';
import {
  OrganizationsCreateResponse,
  WithdrawalsListResponse,
} from 'types/api-scheme';
import CLongPolling from 'source/utilities/long-polling';
import {BalanceDetailsPageProperties} from './lib/types';
import {LONG_POLLING_INTERVAL} from './lib/constants';

export const getBalanceDetailsPageProperties: GetServerSideProps<
  BalanceDetailsPageProperties
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const userAgent = context.req.headers['user-agent'];
  const organizationId = transformToNumber(context?.params?.organization_slug);

  const requestOrganization = () =>
    api.organizations.getOrganization({id: organizationId, headers});

  const requestWithdrawals = () =>
    api.withdrawals.get({organizationId, page: DEFAULT_PAGE, headers});

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(organizationId);

          const [organization, withdrawals] = await Promise.all([
            requestOrganization(),
            requestWithdrawals(),
          ]);

          const filteredProperties = removeUndefined({
            ...properties,
            organization,
            initialPagination: withdrawals?.pagination,
            withdrawals: withdrawals?.items,
          });

          return assoc('props', filteredProperties, {});
        } catch {
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const getOrganization = (organizationId: number, headers: Headers) =>
  api.organizations.getOrganization({id: organizationId, headers});

export const getWithdrawals = (
  organizationId: number,
  page: number,
  headers: Headers,
) => api.withdrawals.get({organizationId, page, headers});

export const pollingBalanceDetails = (
  page: number,
  organizationId: number,
  onStart: () => void,
  onComplete: (
    organization: OrganizationsCreateResponse['data'],
    withdrawals: WithdrawalsListResponse['data'],
  ) => void,
) => {
  const headers = clientHeaders();
  const abortController = new AbortController();

  const updateUserBalance = async () => {
    onStart();

    const [organization, withdrawals] = await Promise.all([
      getOrganization(organizationId, headers),
      getWithdrawals(organizationId, page, headers),
    ]);

    if (organization && withdrawals) {
      onComplete(organization, withdrawals);
    }
  };

  return new CLongPolling({
    duration: LONG_POLLING_INTERVAL,
    takeWhile: () => true,
    mergeMap: updateUserBalance,
    finalize: () => {},
    abortController,
  });
};

export const loadMoreWithdrawals = async (
  nextPage: number,
  organizationId: number,
  onSuccess: (
    response: WithdrawalsListResponse['data'],
    nextPage: number,
  ) => void,
) =>
  api.withdrawals.get({page: nextPage, organizationId}).then((response) => {
    if (response) {
      onSuccess(response, nextPage);
    }
  });

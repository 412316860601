import {GetServerSideProps} from 'next';
import {Translate} from 'next-translate';
import {andThen, assoc, call, difference, isEmpty, pipeWith, when} from 'ramda';
import {
  INITIAL_CALCULATION_FROM_DAYS,
  REQUIRED_TABS,
} from 'slices/policies/lib/constants';
import {
  isCalculationTypeExistCheckDays,
  removeEmptyValuesInDescription,
} from 'slices/policies/lib/helpers';
import {DEFAULT_SECURITY_DEPOSIT_VALUE} from 'slices/policies/lib/mocks';
import {
  setIsCheckPassed,
  setIsNeedCheckTabs,
  setIsNeedCreatePolicies,
  setIsNeedSaving,
  setLoadingSaving,
  setPolicies,
} from 'slices/policies/store';
import {store} from 'source/store';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {
  handleException,
  showException,
  showSuccessMessage,
} from 'source/utilities/exceptions/business';
import {throwRedirectException} from 'source/utilities/exceptions/network';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {isUnhandledException} from 'source/utilities/guards/exceptions';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {HotelsPolicyPartialUpdateRequest} from 'types/api-scheme';
import {
  EAccessWithAnimalType,
  PoliciesDirectories,
  PoliciesPageProperties,
} from './lib/types';

export const getPoliciesPageProperties: GetServerSideProps<
  PoliciesPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestDirectoriesPaymentMethods = () =>
    api.directories.getPaymentMethods({headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestDirectoriesPetsAllowedType = () =>
    api.directories.getPetsAllowedType({headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestDirectoriesDepositPaymentTypes = () =>
    api.directories.getDepositPaymentTypes({headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestDirectoriesTaxesAndFees = () =>
    api.directories.getTaxesAndFees({headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestDirectoriesTaxesAndFeesCalculating = () =>
    api.directories.getTaxesAndFeesCalculating({headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );
  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [
            hotel,
            paymentsMethods,
            petsAllowedType,
            depositPaymentTypes,
            taxesAndFees,
            taxesAndFeesCalculating,
          ] = await Promise.all([
            requestHotel(),
            requestDirectoriesPaymentMethods(),
            requestDirectoriesPetsAllowedType(),
            requestDirectoriesDepositPaymentTypes(),
            requestDirectoriesTaxesAndFees(),
            requestDirectoriesTaxesAndFeesCalculating(),
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            directories: {
              paymentsMethods,
              petsAllowedType,
              depositPaymentTypes,
              taxesAndFees,
              taxesAndFeesCalculating,
            } as PoliciesDirectories,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const handleSavePolicies = (
  translate: Translate,
  hotelId?: Hotel['id'],
) => {
  if (!hotelId) {
    return;
  }
  const {policies, isNeedCreatePolicies, tabsOpened, isCheckPassed} =
    store.getState().policies;
  const {
    pets_allowed,
    payment_methods,
    placement_time,
    registration_fee_foreign_citizens,
    taxes_and_fees,
    security_deposit,
    confirmation_invoice,
  } = policies;

  const notOpenedTabs = difference(REQUIRED_TABS, tabsOpened);

  if (isNeedCreatePolicies && notOpenedTabs.length > 0 && !isCheckPassed) {
    store.dispatch(setIsNeedCheckTabs(true));
    store.dispatch(setIsCheckPassed(true));
    showException(translate('notify.required_tabs'));

    return;
  }

  if (payment_methods && payment_methods.length === 0) {
    showException(translate('notify.payments_required') as string);
    return;
  }

  store.dispatch(setLoadingSaving(true));

  const payload: HotelsPolicyPartialUpdateRequest = {
    placement_time: {
      ...placement_time,
      description: removeEmptyValuesInDescription(placement_time?.description),
    },
    confirmation_invoice,
    payment_methods: payment_methods.map((method) => method.value),
    pets_allowed:
      pets_allowed?.type?.value === EAccessWithAnimalType.IMPOSSIBLE
        ? {
            type: EAccessWithAnimalType.IMPOSSIBLE,
          }
        : {
            type: pets_allowed?.type?.value,
            amount: pets_allowed?.amount ?? 0,
            description: removeEmptyValuesInDescription(
              pets_allowed?.description,
            ),
          },
    registration_fee_foreign_citizens: registration_fee_foreign_citizens ?? 0,
    taxes_and_fees:
      taxes_and_fees.length > 0
        ? taxes_and_fees
            .filter((tax) => !isEmpty(tax.amount))
            .map((tax) => ({
              ...tax,
              amount: tax.amount || 0,
              tax_and_fee_type: tax?.tax_and_fee_type?.value,
              calculation_type: tax?.calculation_type?.value,
              calculation_from_days: isCalculationTypeExistCheckDays(
                tax?.calculation_type?.value,
              )
                ? tax.calculation_from_days || INITIAL_CALCULATION_FROM_DAYS
                : null,
            }))
        : [],
    security_deposit:
      security_deposit?.type?.value === DEFAULT_SECURITY_DEPOSIT_VALUE
        ? undefined
        : {
            amount: security_deposit?.amount ?? 0,
            type: security_deposit?.type?.value,
          },
  };

  (isNeedCreatePolicies
    ? api.policy.create({hotelId, payload})
    : api.policy.update({hotelId, payload})
  )
    .then(() => {
      store.dispatch(setIsNeedSaving(false));
      store.dispatch(setIsNeedCreatePolicies(false));
      showSuccessMessage(translate('notify.success'));
    })
    .catch(handleException)
    .finally(() => {
      store.dispatch(setLoadingSaving(false));
    });
};

export const fetchPolicies = (hotelId: Hotel['id']) => {
  return api.policy
    .get({hotelId})
    .then((policies) => {
      store.dispatch(setPolicies(policies as Policies));
      store.dispatch(setIsNeedSaving(false));
      store.dispatch(setIsNeedCreatePolicies(false));
    })
    .catch(() => {
      store.dispatch(setIsNeedCreatePolicies(true));
    });
};

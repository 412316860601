import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';
import useTranslation from 'next-translate/useTranslation';
import {isEmpty} from 'ramda';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {MAX_PRICE} from 'slices/rate/lib/constants';
import {EDaysOfWeek, FullWeekPriceKey} from 'slices/rate/lib/types';
import {selectRooms} from 'slices/rooms/store';
import {Icon} from 'source/components/icon';
import HumanIcon from 'source/components/icon/assets/human.svg';
import {useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {useBnovoIntegration} from 'source/utilities/hooks/use-is-bnovo-integration';
import {
  buildFullWeekPriceKey,
  buildRateForTable,
  changePriceForAllDaysByGuest,
  changePriceForDay,
  getFullWeekPrice,
  isEqualsRateTableValuesByDay,
  isEqualsRateTableValuesByRoomId,
} from '../../lib/helpers';
import {selectRate} from '../../store';

const TableWrapper = styled.div`
  height: 100%;
  overflow: scroll;
  padding: 0;
`;

const Table = styled.table`
  border-collapse: collapse;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.fontSecondary};
`;

const RowHead = styled.tr``;

const RowHeadData = styled.td<{isFirst?: boolean}>`
  padding-bottom: 20px;
  text-align: ${({isFirst}) => (isFirst ? 'start' : 'center')};
`;

const TableBody = styled.tbody`
  color: ${({theme}) => theme.palette.fontDefault};
`;

const RowBody = styled.tr``;

const RowBodyData = styled.td<{isFirst?: boolean}>`
  padding: 22px 10px;
  border-bottom: 1px solid ${({theme}) => theme.palette.border};
  border-right: 1px solid ${({theme}) => theme.palette.border};
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Noto Sans Mono';
  text-align: ${({isFirst}) => (isFirst ? 'start' : 'center')};
  text-wrap: ${() => 'nowrap'};
  &:last-child {
    border-right: 0px;
  }
`;

const RowBodyInput = styled.input<{isMobile: boolean}>`
  width: ${({isMobile}) => (isMobile ? 'auto' : '100%')};
  text-align: center;
  border: 0px;
  outline: none;
  background: none;
  font-family: 'Noto Sans Mono';
  color: ${({theme}) => theme.palette.fontDefault};
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
`;

const CategoryTitle = styled.div`
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const IconWrapper = styled.div<{isFirst?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({isFirst}) => (isFirst ? 30 : 0)}px;
  margin-right: 10px;
`;

const HumanCounter = styled.span`
  margin-right: 4px;
`;

const Human = styled(HumanIcon)`
  width: 15px;
  height: 15px;
`;

const DropdownIcon = styled(Icon)<{isOpen: boolean}>`
  cursor: pointer;
  transform: rotate(180deg);
  transition: 0.3s ease-in-out all;
  margin-left: 20px;

  ${({isOpen}) =>
    isOpen &&
    css`
      transform: rotate(0deg);
    `}
`;

const RateTable = () => {
  const [hasBnovoIntegration] = useBnovoIntegration();

  const {t} = useTranslation('rate');

  const {rate} = useAppSelector(selectRate);
  const rooms = useAppSelector(selectRooms);
  const ratesTable = useMemo(
    () => buildRateForTable(rate, rooms),
    [rate, rooms],
  );

  const [fullWeekPrice, setFullWeekPrice] = useState(() =>
    getFullWeekPrice(ratesTable),
  );

  const [openRows, setOpenRows] = useState<(typeof rate)['room_ids']>([]);

  useEffect(() => {
    setOpenRows(
      ratesTable
        .filter(
          (item) => !isEqualsRateTableValuesByRoomId(item.room_id, ratesTable),
        )
        .map((item) => item.room_id),
    );
  }, [rate.room_ids]);

  const triggerAllDaysChanged = (key: FullWeekPriceKey, price: number) => {
    setFullWeekPrice({
      ...getFullWeekPrice(ratesTable),
      [key]: {price},
    });
  };

  const mobile = useDeviceDetection('mobile');
  if (!Array.isArray(rate?.prices)) {
    return null;
  }

  const handleDayPriceChange = (
    event: ChangeEvent<HTMLInputElement>,
    roomId: number,
    guestsNumber: number,
    dayOfWeek: number,
    isFirst: boolean,
  ) => {
    const value = Number(event.target.value);
    if (value > MAX_PRICE || Number.isNaN(value)) {
      event.preventDefault();
      return;
    }
    changePriceForDay(
      dayOfWeek as EDaysOfWeek,
      roomId,
      guestsNumber,
      value,
      isFirst,
    );
    setFullWeekPrice(getFullWeekPrice(ratesTable));
  };

  const handleRatePriceChange = (
    event: ChangeEvent<HTMLInputElement>,
    roomId: number,
    guestsNumber: number,
    isFirst: boolean,
  ) => {
    const value = Number(event.target.value);
    if (value > MAX_PRICE || Number.isNaN(value)) {
      event.preventDefault();
      return;
    }

    triggerAllDaysChanged(
      buildFullWeekPriceKey(roomId, guestsNumber, isFirst),
      value,
    );

    changePriceForAllDaysByGuest(roomId, value, guestsNumber, isFirst);
  };

  const handleOpenRow = (roomId: Required<Room>['id']) => {
    setOpenRows((previous) =>
      previous.includes(roomId)
        ? previous.filter((id) => id !== roomId)
        : [...previous, roomId],
    );
  };

  if (isEmpty(ratesTable)) {
    return (
      <Text size="M">
        {t(
          isEmpty(rate.room_ids) ? 'error.empty_room_ids' : 'error.empty_table',
        )}
      </Text>
    );
  }

  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <RowHead>
            <RowHeadData isFirst>{t('rate_table.room_categories')}</RowHeadData>
            <RowHeadData>{t('rate_table.monday')}</RowHeadData>
            <RowHeadData>{t('rate_table.tuesday')}</RowHeadData>
            <RowHeadData>{t('rate_table.wednesday')}</RowHeadData>
            <RowHeadData>{t('rate_table.thursday')}</RowHeadData>
            <RowHeadData>{t('rate_table.friday')}</RowHeadData>
            <RowHeadData>{t('rate_table.saturday')}</RowHeadData>
            <RowHeadData>{t('rate_table.sunday')}</RowHeadData>
            <RowHeadData>{t('rate_table.all_days')}</RowHeadData>
          </RowHead>
        </TableHead>
        <TableBody>
          {ratesTable
            .filter(({room_id, isFirst}) => {
              const isOpen = openRows.includes(room_id);
              return (isFirst && !isOpen) || isOpen;
            })
            .map(
              ({room_id, isFirst, roomInformation, values, guests_number}) => {
                const key: FullWeekPriceKey = buildFullWeekPriceKey(
                  room_id,
                  guests_number,
                  isFirst,
                );
                const fullPrice = fullWeekPrice[key];
                const roomName = roomInformation?.name ?? '';
                return (
                  <RowBody key={key}>
                    <RowBodyData isFirst={isFirst}>
                      <CategoryWrapper>
                        {isFirst && (
                          <Tooltip
                            on="hover"
                            position="bottom left"
                            trigger={
                              <div>
                                <CategoryTitle>{roomName}</CategoryTitle>
                              </div>
                            }
                          >
                            {roomName}
                          </Tooltip>
                        )}
                        <IconWrapper isFirst={isFirst}>
                          <HumanCounter>{guests_number ?? 0}x</HumanCounter>
                          <Human />
                          {isFirst && (
                            <DropdownIcon
                              isOpen={openRows.includes(room_id)}
                              onClick={() => handleOpenRow(room_id)}
                              name="arrow-pink"
                              width={22}
                              height={22}
                            />
                          )}
                        </IconWrapper>
                      </CategoryWrapper>
                    </RowBodyData>
                    {values.map((priceByDay) => {
                      const {day_of_week, price, id} = priceByDay;
                      const valueKey = `${room_id}-${guests_number}-${day_of_week}-${isFirst}-${id}`;
                      const priceForValue = price > 0 ? String(price) : '';
                      const isEqualsByDay = isEqualsRateTableValuesByDay(
                        room_id,
                        day_of_week,
                        ratesTable,
                      );
                      const isEqualsByRoomId = isEqualsRateTableValuesByRoomId(
                        room_id,
                        ratesTable,
                      );
                      return (
                        <RowBodyData key={valueKey}>
                          {isFirst ? (
                            <RowBodyInput
                              isMobile={mobile}
                              type="number"
                              value={isEqualsByDay ? priceForValue : ''}
                              defaultValue={
                                isEqualsByRoomId ? priceForValue : ''
                              }
                              disabled={hasBnovoIntegration}
                              onChange={(event) =>
                                handleDayPriceChange(
                                  event,
                                  room_id,
                                  guests_number,
                                  day_of_week,
                                  isFirst,
                                )
                              }
                            />
                          ) : (
                            <RowBodyInput
                              isMobile={mobile}
                              type="number"
                              value={price}
                              disabled={hasBnovoIntegration}
                              onChange={(event) =>
                                handleDayPriceChange(
                                  event,
                                  room_id,
                                  guests_number,
                                  day_of_week,
                                  isFirst,
                                )
                              }
                            />
                          )}
                        </RowBodyData>
                      );
                    })}
                    <RowBodyData>
                      <RowBodyInput
                        type="number"
                        isMobile={mobile}
                        disabled={hasBnovoIntegration}
                        value={String(fullPrice?.price || '')}
                        onChange={(event) =>
                          handleRatePriceChange(
                            event,
                            room_id,
                            guests_number,
                            isFirst,
                          )
                        }
                      />
                    </RowBodyData>
                  </RowBody>
                );
              },
            )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default RateTable;

import {FC} from 'react';

export const ArrowIcon: FC<IconProps> = ({
  onClick,
  color,
  style,
  className,
}) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      className={className}
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22954 7.37407L13.4233 1.18017"
        stroke={color || '#9292AA'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.23139 7.37408L1.00028 1.14286"
        stroke={color || '#9292AA'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

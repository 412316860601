import styled from '@emotion/styled';
import Popup from 'reactjs-popup';

const MenuPopup = styled(Popup)`
  &-content {
    width: 320px !important;
    padding: 16px 20px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-shadow: ${({theme}) => theme.palette.boxShadow} !important;
    border-radius: 6px !important;
    box-sizing: border-box;
  }
`;

export default MenuPopup;

import {compose, isEmpty, not, path, pickBy} from 'ramda';
import {ChangeEvent} from 'react';
import {LegalFormEnum} from 'source/utilities/business';
import {isFunction} from 'source/utilities/guards/types';
import {isValidINN} from 'source/utilities/inn';
import {patterns} from 'source/utilities/patterns';
import {legalForms, requiredFields} from './constants';

export const getLegalForm = (value: LegalFormEnum) => {
  return legalForms.find((form) => form.value === value) || legalForms[0];
};

export const getRequisitesOptions = (
  requisites?: ExistingRequisites | null,
) => {
  if (!requisites) {
    return [];
  }
  return requisites.items.map((value) => {
    return {
      value,
      label: value.name,
    };
  });
};

export const mapRequisites = (requisites: Requisites): RequisitesFormData => {
  const {
    type,
    name,
    account,
    inn,
    bik,
    address,
    kpp,
    ogrn,
    ogrnip,
    document_flow,
  } = requisites;

  const filterFalsyProps = pickBy(compose(not, isEmpty));
  return filterFalsyProps({
    type: type.value,
    name,
    account,
    inn,
    bik,
    address,
    kpp,
    ogrn,
    ogrnip,
    document_flow,
  });
};

const check = (
  key: string,
  value: Requisites[keyof Requisites],
  form: LegalFormEnum,
) => {
  const stringValue = String(value);
  const validationPatterns: Record<string, RegExp | (() => boolean)> = {
    account:
      form === LegalFormEnum.Person ? patterns.account_2 : patterns.account,
    address: patterns.testNotEmpty,
    bik: patterns.bik,
    inn: () => isValidINN(stringValue),
    kpp: patterns.kpp,
    name: patterns.testNotEmpty,
    ogrn: patterns.ogrn,
    ogrnip: patterns.ogrnip,
  };

  if (key in validationPatterns) {
    const validation = validationPatterns[key];
    if (isFunction(validation)) {
      return validation();
    }
    return validation.test(stringValue);
  }

  return true;
};

export const runFieldChecks = (requisites: Requisites, form: LegalFormEnum) =>
  Object.entries(requisites)
    .filter(([key]) => requiredFields[form].includes(key))
    .map(([key, value]) => check(key, value, form))
    .every(Boolean);

export const getValue = (event: ChangeEvent<HTMLInputElement>) => {
  return path(['target', 'value'], event);
};

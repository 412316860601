import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BookingStoreInitialState} from 'slices/booking/lib/types';
import {RootState} from 'source/store';

const initialState: BookingStoreInitialState = {
  booking: null,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setBooking: (state, action: PayloadAction<Booking>) => {
      state.booking = action.payload;
    },
  },
});

export const selectBooking = (state: RootState) => ({
  booking: state.booking.booking,
});

export const {reset, setBooking} = bookingSlice.actions;

export default bookingSlice.reducer;

import styled from '@emotion/styled';
import {HorizontalDivier} from 'library/components/divider';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {Routes} from 'source/utilities/network/url';
import Actions from 'source/components/header/ui/actions';
import {NavigationLink} from 'library/components/navigation-link';
import {HotelButton, HotelierInfo, MenuPopup} from '../ui';

const Trigger = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 250px;
`;

const InfoWrapper = styled.div`
  margin-bottom: 12px;
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledDivider = styled(HorizontalDivier)`
  margin-top: 12px;
`;

export const HotelPopup = () => {
  const {t} = useTranslation();

  return (
    <MenuPopup
      trigger={
        <Trigger>
          <HotelButton />
        </Trigger>
      }
      closeOnDocumentClick
      arrow={false}
      offsetY={20}
      position="bottom right"
    >
      <InfoWrapper>
        <Text size="S">{t('common:hotelier')}:</Text>
        <HotelierInfo />
        <StyledDivider />
      </InfoWrapper>
      <StyledList>
        <NavigationLink href={Routes.manage} iconName="rates-page">
          {t('routing:manage')}
        </NavigationLink>
        <NavigationLink href={Routes.bookings} iconName="hotels-page">
          {t('routing:bookings')}
        </NavigationLink>
        <Actions />
      </StyledList>
    </MenuPopup>
  );
};

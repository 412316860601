import {FC} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {RowHead, RowHeadData} from 'library/components/table';

import {RateHotelService} from 'slices/rate';

interface IProps {
  translationKey: string;
  addedServices: RateHotelService[];
  isRatePage: boolean;
  hideServices?: boolean;
}

export const ServiceTableHead: FC<IProps> = ({
  translationKey,
  addedServices = [],
  isRatePage,
  hideServices = false,
}) => {
  const {t} = useTranslation(translationKey);

  return (
    <RowHead>
      <RowHeadData isFirst>{t('properties.name')}</RowHeadData>
      <RowHeadData>{t('properties.type')}</RowHeadData>
      <RowHeadData>{t('properties.include_types')}</RowHeadData>
      {!hideServices && <RowHeadData>{t('properties.price')}</RowHeadData>}
      {addedServices.length > 0 && (
        <RowHeadData>{t('properties.price_type')}</RowHeadData>
      )}
      <RowHeadData isLast>
        {t(isRatePage ? 'add_and_delete' : 'edit_and_delete')}
      </RowHeadData>
    </RowHead>
  );
};

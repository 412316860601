import {ApplicationProperties} from 'source/utilities/ui';

export interface CalendarPageProperties extends ApplicationProperties {
  hotel?: Hotel;
  roomsShorts?: HotelRoomsShorts;
  rates?: Rates;
}

export type RowsPriceForCountName = `price_for_count${number}`;

export type RowsName =
  | 'rooms_count'
  | 'restrictions'
  | RowsPriceForCountName
  | 'cancellation';

export type SelectedCell = {
  room: number;
  column: number;
  row: RowsName;
  rate?: number;
  price?: number | null;
  date?: string;
  isDatePrice?: boolean;
  guestNumber?: number | null;
};

export type GuestNumberPrice = CalendarRatesPricesByGuestsNumberItem & {
  isChanged?: boolean;
};

export type RangeSelectCells = {
  rateId: number;
  guestNumber: number;
  prices: GuestNumberPrice[];
  room: number;
};

export type SelectedDatesType = {
  from: string;
  to: string;
};

export type DayDatesType = {
  id: number;
  date: Date;
}[];

export type MonthDatesType = {
  id: string;
  date: Date;
}[];

export type ClientConstraintPropertiesType = {
  beforeWidth: number;
  width: number;
  afterWidth: number;
};

export type ClientAvailableRoomsNumberType = {
  availableRoomsNumber: number;
  isChanged: boolean;
  hasFree: CalendarDateAvailabilities[number]['has_free'];
  maxAvailableRoomsAvailabilities: number;
  computedRoomsAvailability: number;
};

export type IsDateInConstraintType = {
  isDateBlocked: boolean;
  hasConstraint: boolean;
};

export type PricesDateRangeType = {
  startDate: Date;
  endDate: Date;
};

export type NewEndDateType = {
  newFrom: string;
  newTo: string;
  isLimitExceeded: boolean;
  preventInitialLoad: boolean;
};

export type UpdatedCellType = {
  room: number;
  rate?: number;
  price?: number;
  date?: string;
  isDatePrice?: boolean;
  guestNumber?: number;
};

export type UpdatedCellsType = Record<string, UpdatedCellType[]>;

export enum EAvailabilityReason {
  UNAVAILABLE = 1,
  REPAIR = 2,
  QUOTA = 3,
  ROOM_AVAILABILITY = 5,
}

export type SubmitAvailabilityType = {
  from: string;
  to: string;
  rooms_closed_number: number | null;
  type: EAvailabilityReason;
  comment?: string;
  is_delete: boolean;
  id?: number;
};

export type NotAvailableDatesType = Record<string, string[]>;

export type ActionButtonsType = 'apply' | 'save' | 'save_changes';

export type GroupedPrices = {
  monthWithYear: string;
  prices: {
    date: string;
    price?: number | null;
    is_date_price: boolean;
  }[];
}[];

export type GroupedPrice = GroupedPrices[number];

export type ActiveTabType = 'price-change' | 'close-booking';

export type CellsMapType = Record<string, UpdatedCellType>;

export type EditRoomsType = Record<string, boolean>;

export enum ETimerCountAction {
  INCREMENT = 'increment',
  DECREMENT = 'decrement',
}

export type SelectRangeType = {
  position: {x?: number; y?: number};
  to: number;
  from: number;
  rateId: number;
  room: number;
  guestNumber: number;
};

export enum ELoadMoreTranslateKey {
  LIMIT_EXCEEDED = 'infinite_scroll.limit_exceeded',
  SCROLL_OR_PRESS = 'infinite_scroll.scroll_or_press',
  EDIT_OR_CANCEL = 'infinite_scroll.edit_or_cancel_changes',
}

export enum ELoadBeforeTranslateKey {
  LIMIT_EXCEEDED = 'before_scroll.limit_exceeded',
  SCROLL_OR_PRESS = 'before_scroll.scroll_or_press',
  EDIT_OR_CANCEL = 'before_scroll.edit_or_cancel_changes',
}

export enum ScrollDirectionType {
  LEFT = 'left',
  RIGHT = 'right',
}

export type MassEditModalFiltersType = {
  roomId: number;
  rateId: number;
  guestNumber: number[];
};
export type OpenedRoomsType = Record<string, boolean>;

export type StoreRatesPricesType = Record<
  string,
  {
    ratesPrices: CalendarRatesPrices;
    initialRatesPrices: CalendarRatesPrices;
  }
>;

export type StoreRoomConstraintsType = Record<
  string,
  {
    roomConstraints: ClientCalendarRoomConstraints;
    initialRoomConstraints: ClientCalendarRoomConstraints;
  }
>;

export type StoreDateAvailabilitiesType = Record<
  string,
  CalendarDateAvailabilities
>;

export enum MASS_EDIT_TABS {
  PRICE = 'price-change',
  BOOKING = 'close-booking',
}

import {FC, useState, useEffect, useRef, Dispatch, SetStateAction} from 'react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {clearInterval, setInterval} from 'worker-timers';

import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {SubText} from 'library/components/text';

import {
  getCountdownTime,
  getCountdownInitialTime,
} from 'slices/calendar/lib/helpers/time';
import {
  DEFAULT_MAX_SECONDS,
  TIMER_END_SECONDS,
} from 'slices/calendar/lib/constants';

const Wrapper = styled.div``;

const StyledAccent = styled(SubText)`
  color: ${({theme}) => theme.palette.primaryAccent};
`;

interface IProps {
  setIsTimerUpdate: Dispatch<SetStateAction<boolean>>;
  isTimerUpdate: boolean;
  roomId: number;
  isEdit: boolean;
  roomIndex: number;
}

const UpdateTimer: FC<IProps> = ({
  setIsTimerUpdate,
  isTimerUpdate,
  roomId,
  isEdit,
  roomIndex,
}) => {
  const {t} = useTranslation('calendar');
  const mobile = useDeviceDetection('mobile');
  const maxSeconds = useRef(DEFAULT_MAX_SECONDS);
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);
  const [seconds, setSeconds] = useState(maxSeconds.current);

  const handleSetInterval = () => {
    setSeconds(maxSeconds.current);

    intervalId.current = setInterval(() => {
      setSeconds((previousSeconds) =>
        previousSeconds > 0 ? previousSeconds - 1 : previousSeconds,
      );
    }, 1000);
  };

  const handleClearInterval = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  useEffect(() => {
    if (isTimerUpdate || isEdit) {
      handleClearInterval();
      return;
    }

    if (!intervalId.current && !isEdit) {
      maxSeconds.current = getCountdownInitialTime(roomIndex - 1);

      handleSetInterval();
    }

    return () => handleClearInterval();
  }, [isTimerUpdate, isEdit, roomId]);

  useEffect(() => {
    if (seconds === TIMER_END_SECONDS) {
      setIsTimerUpdate(true);

      handleClearInterval();
    }
  }, [seconds, setIsTimerUpdate, roomId]);

  if (seconds < TIMER_END_SECONDS) {
    return null;
  }

  return (
    <Wrapper>
      {seconds > 0 ? (
        <>
          <SubText size={mobile ? 'XS' : 'S'}>
            {t('room_range.until_data_update')}
          </SubText>
          <StyledAccent size={mobile ? 'XS' : 'S'}>
            {getCountdownTime(seconds)}
          </StyledAccent>
        </>
      ) : (
        <>{t('room_range.updating_data')}</>
      )}
    </Wrapper>
  );
};

export default UpdateTimer;

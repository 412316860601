import {RoomCategories} from 'slices/add-room';
import {ApplicationProperties} from 'source/utilities/ui';
import {
  DirectoriesBedTypesListResponse,
  DirectoriesRoomFacilityGroupsListResponse,
} from 'types/api-scheme';

export enum EIntegrations {
  BNOVO = 'bnovo',
  REALTY = 'realty',
  TRAVEL_LINE = 'travelline',
}

export enum EIntergrationsLabels {
  BNOVO = 'Bnovo',
  REALTY = 'RealtyCalendar',
  TRAVEL_LINE = 'TravelLine',
}

export enum ERealtyIntegrationStatuses {
  DISABLED = 'statuses.disabled',
  IN_PROCESS = 'statuses.in_process',
  ENABLED = 'statuses.enabled',
  DELETED = 'statuses.deleted',
}

export interface IntegrationPageProperties extends ApplicationProperties {
  hotel?: Hotel;
  roomCategories?: RoomCategories;
  bedTypes?: DirectoriesBedTypesListResponse['data'];
  facilities?: DirectoriesRoomFacilityGroupsListResponse['data'];
  roomShorts?: HotelRoomsShorts;
  rates?: Rates;
}

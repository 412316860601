import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {useState} from 'react';
import {RangeKeyDict, Range} from 'react-date-range';
import {addDays} from 'date-fns';

import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {parseBackendDateFormat} from 'source/utilities/dates';
import {Button} from 'library/components/button';
import {DatePicker} from 'library/components/date-picker';
import {Tooltip} from 'library/components/tooltip';

import {selectRate, setRatePeriod} from 'slices/rate/store';
import {
  PERIOD_PICKER_MAX_INTERVAL,
  PERIOD_PICKER_MONTHS_COUNT,
  RANGE_KEY,
} from 'slices/rate/lib/constants';
import {getDateForRatePeriodRange} from 'slices/rate/lib/helpers';

const StyledPopup = styled(Tooltip)`
  &-content {
    width: fit-content !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Trigger = styled.div`
  color: ${({theme}) => theme.palette.fontAccent};
  background: ${({theme}) => theme.palette.fontPrimary};
  max-width: 280px;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-weight: 700;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  padding: 10px;
`;

export const RatePeriodPicker = () => {
  const {t} = useTranslation('rate');
  const router = useRouter();
  const dispatch = useAppDispatch();

  const today = new Date();
  const mobile = useDeviceDetection('mobile');
  const {rate} = useAppSelector(selectRate);
  const {from, to} = rate.period;

  const [ranges, setRanges] = useState<Range[]>([
    {
      startDate: from ? parseBackendDateFormat(from) : today,
      endDate: to ? parseBackendDateFormat(to) : today,
      key: RANGE_KEY,
    },
  ]);

  const handleChange = (range: RangeKeyDict) => {
    const {startDate, endDate} = range[RANGE_KEY];
    setRanges([{startDate, endDate, key: RANGE_KEY}]);
  };

  const handleApply = () => {
    const {startDate, endDate} = ranges[0];

    dispatch(
      setRatePeriod({
        from: getDateForRatePeriodRange(startDate),
        to: getDateForRatePeriodRange(endDate),
      }),
    );

    const triggerButton = document.querySelector<HTMLButtonElement>(
      '.calendar-picker-trigger',
    );

    if (triggerButton) {
      triggerButton.click();
    }
  };

  return (
    <StyledPopup
      on="click"
      position="top left"
      mobileTopModal={mobile}
      trigger={
        <Trigger className="calendar-picker-trigger">
          {t('about_rate.period.change_rate_period')}
        </Trigger>
      }
    >
      <Wrapper>
        <DatePicker
          ranges={ranges}
          onChange={handleChange}
          weekdayDisplayFormat="EEEEEE"
          monthDisplayFormat="LLLL"
          minDate={today}
          maxDate={addDays(
            ranges[0].startDate || today,
            PERIOD_PICKER_MAX_INTERVAL,
          )}
          months={PERIOD_PICKER_MONTHS_COUNT}
          locale={router.locale}
        />
        <StyledButton hoverOpacity onClick={handleApply}>
          {t('apply')}
        </StyledButton>
      </Wrapper>
    </StyledPopup>
  );
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'source/store';

interface InitialState {
  isBitrixSetup: boolean;
}

const initialState: InitialState = {
  isBitrixSetup: false,
};

const bitrixSlice = createSlice({
  name: 'bitrix',
  initialState,
  reducers: {
    setIsBitrixSetup: (state, action: PayloadAction<boolean>) => {
      state.isBitrixSetup = action.payload;
    },
  },
});

export const selectIsBitrixSetup = (state: RootState) =>
  state.bitrix.isBitrixSetup;

export const {setIsBitrixSetup} = bitrixSlice.actions;

export default bitrixSlice.reducer;

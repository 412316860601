import {
  assoc,
  find,
  findIndex,
  is,
  lensIndex,
  map,
  over,
  propEq,
  whereEq,
} from 'ramda';
import {Translate} from 'next-translate';
import {
  LandmarksState,
  LandmarksStateGroup,
  LandmarksStateLandmark,
} from './types';

export const getHotelLandmarks = (
  hotelLandmarks: HotelLandmarks,
  landmarksNear: LandmarksNear
) => {
  const addEnabled = (landmark: LandmarksNearLandmark) => {
    const isEnabled = find(
      whereEq({
        landmark_id: landmark.id,
        landmark_group_id: landmark.landmark_group_id,
      }),
      hotelLandmarks,
    );
    return assoc('enabled', isEnabled, landmark);
  };

  const updateLandmarks = (item: LandmarksNearGroup) => {
    const updatedLandmarks = map(addEnabled, item.landmarks);
    return assoc('landmarks', updatedLandmarks, item);
  };

  return map(updateLandmarks, landmarksNear);
};

export const updateToggledLandmark = (
  landmarks: LandmarksState,
  landmark: LandmarksStateLandmark
) => {
  const findLandmarkIndexById = findIndex(propEq('id', landmark.id));
  const updateLandmark = assoc('enabled', !landmark.enabled);

  const updateLandmarks = (group: LandmarksStateGroup) => {
    const indexToUpdate = findLandmarkIndexById(group.landmarks);
    const isValidGroup = group.id === landmark.landmark_group_id;

    if (indexToUpdate >= 0 && isValidGroup) {
      const updatedLandmarks = over(
        lensIndex(indexToUpdate),
        updateLandmark,
        group.landmarks
      );
      return assoc('landmarks', updatedLandmarks, group);
    }
    return group;
  };

  return map(updateLandmarks, landmarks);
};

export const formatDistance = (
  distanceInMeters: number | null | undefined,
  t: Translate
) => {
  if (is(Number, distanceInMeters)) {
    if (distanceInMeters < 1000) {
      return t('common:meters', {value: distanceInMeters});
    }
    const distanceInKilometers = distanceInMeters / 1000;
    return t('common:kilometers', {value: distanceInKilometers.toFixed(1)});
  }
  return 'нет данных';
};

import {useTheme} from '@emotion/react';

export const UpdateIcon: React.FC<IconProps> = ({style, className, color}) => {
  const theme = useTheme();

  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <line
        x1="1.13137"
        y1="10.084"
        x2="4.07257"
        y2="13.0252"
        stroke={color || theme.palette.border}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <line
        x1="0.8"
        y1="-0.8"
        x2="4.95949"
        y2="-0.8"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 8.14062 10.084)"
        stroke={color || theme.palette.border}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <line
        x1="13.8312"
        y1="8.29512"
        x2="13.8313"
        y2="12.7618"
        stroke={color || theme.palette.border}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <line
        x1="13.9204"
        y1="12.9189"
        x2="17.0788"
        y2="16.0774"
        stroke={color || theme.palette.border}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1719 22.8697C20.9156 22.8697 25.5719 18.2135 25.5719 12.4697C25.5719 6.72597 20.9156 2.06973 15.1719 2.06973C9.42811 2.06973 4.77188 6.72597 4.77188 12.4697C4.77188 12.5431 4.77264 12.6164 4.77415 12.6895H3.17385C3.17253 12.6164 3.17188 12.5431 3.17188 12.4697C3.17188 5.84231 8.54446 0.469727 15.1719 0.469727C21.7993 0.469727 27.1719 5.84231 27.1719 12.4697C27.1719 19.0971 21.7993 24.4697 15.1719 24.4697C11.4037 24.4697 8.04126 22.7329 5.84127 20.0162H8.01569C9.88007 21.7847 12.3993 22.8697 15.1719 22.8697Z"
        fill={color || theme.palette.border}
      />
    </svg>
  );
};

import styled from '@emotion/styled';
import {FC, ReactNode} from 'react';
import {PencilIcon} from 'library/icons/pencil-icon';
import {Text} from '../text';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
`;

const StyledText = styled(Text)`
  color: #ff5876;
  margin-left: 8px;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-family: inherit;
  border: none;
  background: none;
  cursor: pointer;
`;

interface EditButtonProps {
  children?: ReactNode;
  onClick: () => void;
  className?: string;
}

export const EditButton: FC<EditButtonProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      <StyledButton onClick={onClick}>
        <PencilIcon />
        <StyledText size="S">{children}</StyledText>
      </StyledButton>
    </Wrapper>
  );
};

import {Logout} from 'slices/user/content';
import ViewOnMain from 'source/components/header/ui/actions/view-on-main';

const Actions = () => {
  return (
    <>
      <ViewOnMain />
      <Logout />
    </>
  );
};

export default Actions;

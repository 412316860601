import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {stylable} from 'library/utilities/stylable';

const styles = {
  1: css`
    font-size: max(22px, 1.8vw);
    font-weight: 700;
  `,
  2: css`
    font-size: max(20px, 1vw);
    font-weight: 700;
  `,
  S: css`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  M: css`
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
  `,
  L: css`
    font-size: 37px;
    font-weight: 700;
    line-height: 46px;
  `,
  XL: css`
    font-size: 44px;
    font-weight: 700;
    line-height: 55px;
  `,
  XXL: css`
    font-size: 55px;
    font-weight: 700;
    line-height: 70px;
  `,
  XXXL: css`
    font-size: 72px;
    font-weight: 700;
    line-height: 91px;
  `,
};

interface StyledProperties {
  level: keyof typeof styles;
}

const Wrapper = styled.h1<StyledProperties>`
  color: #5b5b6b;

  ${({level}) => styles[level]}
`;

export type HeadlineLevel = keyof typeof styles;
export const Headline = stylable(Wrapper);

import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {ActionModal} from 'library/components/action-modal';
import {BackButton} from 'library/components/back-button';
import {Badge} from 'library/components/badge';
import {Button} from 'library/components/button';
import {HorizontalDivier} from 'library/components/divider';
import {EditButton} from 'library/components/edit-button';
import {Hint} from 'library/components/hint';
import {LabeledComponent} from 'library/components/labeled-component';
import {CircularLoader} from 'library/components/loader';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useContext, useState} from 'react';
import Popup from 'reactjs-popup';
import {selectHotelModerationLast, selectRates} from 'slices/general_info';
import {phoneBuilder} from 'slices/general_info/lib/helpers';
import {EditEmailContent} from 'slices/general_info/ui/modal/edit-email-content';
import {EditPhoneContent} from 'slices/general_info/ui/modal/edit-phone-content';
import {handleUpdateRatesPublicity} from 'slices/home/network';
import {ModerationHotelStatus} from 'slices/moderation';
import {HotelStatus} from 'source/components/hotel/status';
import {Link} from 'source/components/link';
import {HotelContext} from 'source/context/hotel';
import {useAppSelector} from 'source/store';
import {getHotelStatus, HotelStatusEnum} from 'source/utilities/business';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {changeHotelStatus} from '../network';
import {EditAddressContent} from './modal/edit-address-content';
import {EditModal} from './modal/edit-modal';
import {EditNameContent} from './modal/edit-name-content';

const HotelTitleWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  align-items: ${({isMobile}) => (isMobile ? 'start' : 'center')};
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  gap: ${({isMobile}) => (isMobile ? '5px' : '20px')};
  margin-top: 20px;
`;

const ModalHeaderWrapper = styled.div`
  margin-bottom: 34px;
`;

const ModalWrapper = styled.div`
  margin-bottom: 10px;
`;
const InactivateText = styled(Text)`
  color: #ff5876;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: start;
  }
`;

const ActivateRatesButton = styled(Button)`
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 15px;

  @media (max-width: 480px) {
    margin-left: 0;
  }
`;

const Labeled = styled(LabeledComponent)`
  margin-bottom: 12px;
`;

const StyledHint = styled(Hint)`
  margin-bottom: 14px;
`;

const StyledHorizontalDivider = styled(HorizontalDivier)`
  margin-bottom: 13px;
`;

const AddressWrapper = styled(LabeledComponent)`
  margin-bottom: 10px;
`;

const Address = styled(Text)`
  margin-bottom: 5px;
`;

const Id = styled(Text)`
  margin-top: 20px;
  color: ${({theme}) => theme.palette.borderBlue};
`;

const LocationWrapper = styled(LabeledComponent)``;

const Location = styled(Text)``;

const EditButtonStyled = styled(EditButton)`
  margin: 10px 0px 34px 0px;
`;

const InvoiceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InvoiceLink = styled(Link)`
  color: ${({theme}) => theme.palette.primaryAccent};
  margin-left: 12px;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const ContactText = styled(Text)`
  margin-bottom: 10px;
`;

const AcceptedPopup = styled(Popup)<{isMobile: boolean}>`
  &-content {
    background: ${({theme}) => theme.palette.defaultBackground};
    width: 800px;
    box-shadow: 0px 4px 20px rgba(220, 220, 220, 0.4);
    border-radius: 6px;
    padding: 20px;
    max-height: 80vh;
    overflow: auto;
  }
`;

const AcceptActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AcceptActionButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    margin-right: 0;
  }
  &:last-child {
    margin-left: 20px;
  }
`;

const RequestInformationText = styled(Text)`
  margin-bottom: 10px;
`;

const StartBookingInformation = styled(Text)`
  opacity: 0.7;
  margin-bottom: 10px;
  width: 55%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const StyledBackButton = styled(BackButton)`
  margin: 20px 0px 5px 0px;
`;

export const HotelInfo = () => {
  const theme = useTheme();
  const mobile = useDeviceDetection('mobile');
  const [hotel, setHotel] = useContext(HotelContext);
  const status = getHotelStatus(hotel?.status?.value);
  const [editName, setEditName] = useState(false);
  const [address, setAddress] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [phoneEdit, setPhoneEdit] = useState(false);
  const [isRatesPublicityLoading, setIsRatesPublicityLoading] = useState(false);
  const [openAcceptedBooking, setOpenAcceptedBooking] = useState(false);

  const rates = useAppSelector(selectRates);
  const hotelModerationLast = useAppSelector(selectHotelModerationLast);

  const [isNameModerationRequestedState, setIsNameModerationRequestedState] =
    useState(false);
  const {t} = useTranslation();

  /**
   * @see https://conf.booking.beer/pages/viewpage.action?pageId=1638447
   */
  const isRenderOpenBookingButton = Boolean(
    !hotel?.public_rates &&
      hotel?.verified &&
      rates.length > 0 &&
      status &&
      [
        HotelStatusEnum.NotActive,
        HotelStatusEnum.Active,
        HotelStatusEnum.Paused,
      ].includes(status),
  );

  const isRenderActiveBookingButton = Boolean(
    hotel?.verified &&
      hotel?.public_rates &&
      rates.length > 0 &&
      status === HotelStatusEnum.Paused,
  );

  const isRenderInactiveBookingButton = Boolean(
    hotel?.verified && hotel?.public_rates && status === HotelStatusEnum.Active,
  );

  const handleActivateRates = async () => {
    if (hotel?.id) {
      await handleUpdateRatesPublicity(
        hotel.id,
        {public: true},
        {
          code: 'organization_not_exists',
          link: `/manage/${hotel.id}/requisites`,
        },
        (hotel) => {
          setHotel(hotel);
        },
        setIsRatesPublicityLoading,
        setOpenAcceptedBooking,
      );
    }
  };

  if (mobile) {
    if (editName) {
      return (
        <>
          <StyledBackButton action={() => setEditName(false)} />
          <EditNameContent
            closeAction={() => setEditName(false)}
            setIsNameModerationRequestedState={
              setIsNameModerationRequestedState
            }
          />
        </>
      );
    }

    if (address) {
      return (
        <>
          <StyledBackButton action={() => setAddress(false)} />
          <EditAddressContent closeAction={() => setAddress(false)} />
        </>
      );
    }
  }

  return (
    <>
      <AcceptedPopup
        isMobile={mobile}
        open={openAcceptedBooking}
        onClose={() => setOpenAcceptedBooking(false)}
        closeOnDocumentClick
      >
        <RequestInformationText size="S">
          - {t('hotel-info:request_detail_information_1')}
        </RequestInformationText>
        <RequestInformationText size="S">
          - {t('hotel-info:request_detail_information_2')}
        </RequestInformationText>
        <RequestInformationText size="S">
          - {t('hotel-info:request_detail_information_3')}
        </RequestInformationText>
        <RequestInformationText size="S">
          - {t('hotel-info:request_detail_information_4')}
        </RequestInformationText>
        <AcceptActionsWrapper>
          <AcceptActionButton onClick={handleActivateRates}>
            {isRatesPublicityLoading ? (
              <CircularLoader variant="thin" size={20} />
            ) : (
              t('hotel-info:open_booking')
            )}
          </AcceptActionButton>
          <AcceptActionButton
            disabled={isRatesPublicityLoading}
            onClick={() => setOpenAcceptedBooking(false)}
          >
            {t('hotel-info:back_booking')}
          </AcceptActionButton>
        </AcceptActionsWrapper>
      </AcceptedPopup>
      <Id size={mobile ? 'boldXS' : 'boldM'}>
        {t('hotel-info:id', {id: hotel?.id})}
      </Id>
      <HotelTitleWrapper isMobile={mobile}>
        <Text size={mobile ? 'boldXS' : 'boldL'}>{hotel?.name}</Text>
        {isNameModerationRequestedState ? (
          <Badge
            textColor={theme.palette.fontAccent}
            textSize={mobile ? 'XS' : 'S'}
            backgroundColor={theme.palette.fontPrimary}
          >
            {t('common:moderation_requested')}
          </Badge>
        ) : (
          <ModerationHotelStatus hotelModerationLast={hotelModerationLast} />
        )}
      </HotelTitleWrapper>
      {mobile ? (
        <EditButtonStyled onClick={() => setEditName(true)}>
          {t('components:edit_name')}
        </EditButtonStyled>
      ) : (
        <ModalHeaderWrapper>
          <EditModal
            title={t('components:edit_name')}
            open={editName}
            openModal={() => setEditName(true)}
            closeModal={() => setEditName(false)}
          >
            <EditNameContent
              closeAction={() => setEditName(false)}
              setIsNameModerationRequestedState={
                setIsNameModerationRequestedState
              }
            />
          </EditModal>
        </ModalHeaderWrapper>
      )}
      {hotel && status && status !== HotelStatusEnum.Initializing ? (
        <StatusWrapper>
          <Labeled size="XS" text={t('hotel-info:status_label')} gap={6}>
            <InvoiceWrapper>
              <HotelStatus status={status} />
              {!hotel.verified && (
                <InvoiceLink href={`/manage/${hotel.id}/requisites`}>
                  {t('hotel-info:unverified')}
                </InvoiceLink>
              )}
            </InvoiceWrapper>
          </Labeled>
        </StatusWrapper>
      ) : null}
      {hotel && isRenderInactiveBookingButton ? (
        <StyledHint
          hintOnQuestion
          text={t('hotel-info:pausing_hint')}
          minWidth={320}
        >
          <ActionModal
            maxWidth={440}
            title={t('hotel-info:pausing_modal_title')}
            action={(close, event) => {
              changeHotelStatus(hotel, HotelStatusEnum.Paused, setHotel);
              if (close) {
                close(event);
              }
            }}
            actionText={t('components:yes')}
          >
            <InactivateText size="S">
              {t('hotel-info:inactivate')}
            </InactivateText>
          </ActionModal>
        </StyledHint>
      ) : null}
      {hotel && isRenderActiveBookingButton ? (
        <StyledHint
          hintOnQuestion
          text={t('hotel-info:unpausing_hint')}
          maxWidth={440}
        >
          <ActionModal
            maxWidth={440}
            title={t('hotel-info:unpausing_modal_title')}
            action={(close, event) => {
              changeHotelStatus(hotel, HotelStatusEnum.Active, setHotel);
              if (close) {
                close(event);
              }
            }}
            actionText={t('components:yes')}
          >
            <InactivateText size="S">{t('hotel-info:activate')}</InactivateText>
          </ActionModal>
        </StyledHint>
      ) : null}
      {isRenderOpenBookingButton && (
        <>
          {!hotel?.is_our && (
            <StartBookingInformation size="S">
              {t('hotel-info:attention_label')}
            </StartBookingInformation>
          )}
          <ActivateRatesButton onClick={() => setOpenAcceptedBooking(true)}>
            {isRatesPublicityLoading ? (
              <CircularLoader variant="thin" size={20} />
            ) : (
              t('hotel-info:open_booking_request')
            )}
          </ActivateRatesButton>
        </>
      )}
      <StyledHorizontalDivider />
      <AddressWrapper size="XS" gap={1} text={t('hotel-info:address_label')}>
        <Address size={mobile ? 'boldXS' : 'M'}>{hotel?.address}</Address>
        {mobile ? (
          <EditButtonStyled onClick={() => setAddress(true)}>
            {t('components:edit_address')}
          </EditButtonStyled>
        ) : (
          <ModalWrapper>
            <EditModal
              title={t('components:edit_address')}
              open={address}
              openModal={() => setAddress(true)}
              closeModal={() => setAddress(false)}
            >
              <EditAddressContent closeAction={() => setAddress(false)} />
            </EditModal>
          </ModalWrapper>
        )}
      </AddressWrapper>
      <StyledHorizontalDivider />
      <LabeledComponent
        size="XS"
        gap={1}
        text={t('hotel-info:contact_information.email_label')}
      >
        <ContactText size={mobile ? 'boldXS' : 'M'}>
          {hotel?.email ?? t('hotel-info:contact_information.email_not_found')}
        </ContactText>
        <ModalWrapper>
          <EditModal
            title={t('components:edit_email')}
            open={emailEdit}
            openModal={() => setEmailEdit(true)}
            closeModal={() => setEmailEdit(false)}
          >
            <EditEmailContent closeAction={() => setEmailEdit(false)} />
          </EditModal>
        </ModalWrapper>
      </LabeledComponent>
      <StyledHorizontalDivider />
      <LabeledComponent
        size="XS"
        gap={1}
        text={t('hotel-info:contact_information.phone_label')}
      >
        <ContactText size={mobile ? 'boldXS' : 'M'}>
          {hotel?.phone
            ? phoneBuilder(hotel.phone)
            : t('hotel-info:contact_information.phone_not_found')}
        </ContactText>
        <ModalWrapper>
          <EditModal
            title={t('components:edit_phone')}
            open={phoneEdit}
            openModal={() => setPhoneEdit(true)}
            closeModal={() => setPhoneEdit(false)}
          >
            <EditPhoneContent closeAction={() => setPhoneEdit(false)} />
          </EditModal>
        </ModalWrapper>
      </LabeledComponent>
      <StyledHorizontalDivider />
      <LocationWrapper size="XS" gap={1} text={t('hotel-info:location_label')}>
        <Location size={mobile ? 'boldXS' : 'M'}>
          {hotel?.lat}, {hotel?.lng}
        </Location>
      </LocationWrapper>
    </>
  );
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IFacilitiesStore} from 'slices/facilities/lib/types';
import {RootState} from 'source/store';

const initialState: IFacilitiesStore = {
  facilities: [],
  rawFacilities: [],
  services: [],
  mealTypesDirectory: [],
};

const facilitiesSlice = createSlice({
  name: 'facilities',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setFacilities: (state, action: PayloadAction<HotelFacilities>) => {
      state.facilities = action.payload;
    },
    setRawFacilities: (state, action: PayloadAction<RawFacilities>) => {
      state.rawFacilities = action.payload;
    },
    setServices: (state, action: PayloadAction<HotelServices>) => {
      state.services = action.payload;
    },
    setMealTypesDirectory: (state, action: PayloadAction<MealTypes>) => {
      state.mealTypesDirectory = action.payload;
    },
  },
});

export const selectFacilities = (state: RootState) =>
  state.facilities.facilities;

export const selectRawFacilities = (state: RootState) =>
  state.facilities.rawFacilities;

export const selectServices = (state: RootState) => state.facilities.services;

export const selectMealTypesDirectory = (state: RootState) =>
  state.facilities.mealTypesDirectory;

export const {
  reset,
  setFacilities,
  setRawFacilities,
  setServices,
  setMealTypesDirectory,
} = facilitiesSlice.actions;

export default facilitiesSlice.reducer;

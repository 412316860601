import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {useState, useEffect, FC} from 'react';
import {DateRangeProps, RangeKeyDict} from 'react-date-range';
import useTranslation from 'next-translate/useTranslation';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

import {Select, Option} from 'library/components/select';
import {Text} from 'library/components/text';
import {Textarea} from 'library/components/textarea';
import {getCountOptions} from 'source/utilities/object';
import {getLastIndex, toValueOrEmptyArray} from 'source/utilities/array';

import {
  selectRangeSelectCells,
  resetRangeSelectCells,
  selectDateAvailabilities,
  selectRoomConstraints,
  setRoomConstraints,
  selectNotAvailableDates,
} from 'slices/calendar/store';
import {
  buildPricesDateRange,
  buildRangeDates,
} from 'slices/calendar/lib/helpers/dates';
import {buildMaxAvailableRoomsNumber} from 'slices/calendar/lib/helpers/rooms';
import {SubmitAvailabilityType} from 'slices/calendar/lib/types';
import {
  RANGE_KEY,
  DEFAULT_CLOSE_ROOMS_OPTION,
  AVAILABILITY_REASON_OPTIONS,
  MIN_CLOSE_ROOMS_NUMBER,
} from 'slices/calendar/lib/constants';

import {eachDayOfInterval, parseISO} from 'date-fns';
import {buildDateCellId} from 'slices/calendar/lib/helpers/ui';
import {ActionButtons} from '../action-buttons';
import {CalendarMassEditPicker} from '../calendar-mass-edit-picker';
import {CalendarMassEditFilters} from '../calendar-mass-edit-filters';

const ContentWrapper = styled.div<{isTouch: boolean}>`
  height: 45vh;
  min-height: 45vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px 22px;
  width: 100%;

  @media (max-width: 750px) {
    padding: 20px 10px 10px 10px;
  }

  ${({isTouch}) =>
    isTouch &&
    css`
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
    `}
`;

const Row = styled.div<{columns?: number}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  width: 100%;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
`;

const RowTitle = styled(Text)<{isFirst?: boolean}>`
  min-width: max-content;
  margin-right: ${({isFirst}) => (isFirst ? '0px' : '22px')};
`;

const StyledTextarea = styled(Textarea)`
  background-color: ${({theme}) => theme.palette.border_3};
  padding: 15px;
  width: 100%;
  font-size: 14px;
`;

const CheckBoxWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const ButtonsWrapper = styled.div`
  padding: 10px 0;
  @media (max-width: 600px) {
    padding: 10px 0 20px 0;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  font-size: 14px;

  input {
    font-size: 14px;
    padding: 15px;
  }
`;

const RangeNotAvailableText = styled(Text)<{visible: boolean}>`
  margin-bottom: 10px;
  color: ${({theme}) => theme.palette.fontDanger};
  visibility: hidden;
  opacity: 0;

  ${({visible}) =>
    visible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

const StyledCalendarMassEditFilters = styled(CalendarMassEditFilters)`
  padding: 0;
`;

const StyledCalendarMassEditPicker = styled(CalendarMassEditPicker)`
  margin-bottom: 5px;
`;

interface IProps {
  onCancel: () => void;
  onRangeChange?: (range: RangeKeyDict) => void;
  roomId: number;
  currentRange: {
    from: Date;
    to: Date;
  };
}

export const AvailabilityModalContent: FC<IProps> = ({
  onCancel,
  onRangeChange,
  roomId,
  currentRange,
}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('calendar');
  const mobile = useDeviceDetection('mobile');
  const isTouch = useDeviceDetection('touch');
  const rangeSelectCells = useAppSelector(selectRangeSelectCells);
  const storeDatesAvailabilities = useAppSelector(selectDateAvailabilities);
  const storeRoomConstraints = useAppSelector(selectRoomConstraints);
  const roomConstraints = toValueOrEmptyArray(
    storeRoomConstraints?.[roomId]?.roomConstraints,
  );
  const initialRoomConstraints = toValueOrEmptyArray(
    storeRoomConstraints?.[roomId]?.initialRoomConstraints,
  );
  const storeNotAvailableDates = useAppSelector(selectNotAvailableDates);
  const notAvailableDates = storeNotAvailableDates?.[roomId];

  const initialPriceRangeDate = buildPricesDateRange(rangeSelectCells.prices);
  const [reason, setReason] = useState(AVAILABILITY_REASON_OPTIONS[0]);
  const [comment, setComment] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [closeRoomsOptions, setCloseRoomsOptions] = useState<Option[]>([
    DEFAULT_CLOSE_ROOMS_OPTION,
  ]);
  const [currentCloseRoomsOption, setCurrentCloseRoomsOption] =
    useState<Option>(closeRoomsOptions[0]);

  const [ranges, setRanges] = useState<DateRangeProps['ranges']>([
    {...initialPriceRangeDate, key: RANGE_KEY},
  ]);

  const handleApply = () => {
    const currentRange = ranges && ranges[0];

    if (currentRange && currentRange.startDate && currentRange.endDate) {
      const {startDate, endDate} = currentRange;

      // По id ищем ячейку даты (первую из диапазона изменений) и скроллим к ней
      const updatedDateCellElement = document.querySelector(
        buildDateCellId(startDate, true),
      );

      if (updatedDateCellElement) {
        updatedDateCellElement.scrollIntoView({
          inline: 'center',
          block: 'nearest',
        });
      }

      const rangeDateArray = buildRangeDates(startDate, endDate);

      const submitAvailability: SubmitAvailabilityType = {
        from: rangeDateArray[0],
        to: rangeDateArray[getLastIndex(rangeDateArray)],
        rooms_closed_number: currentCloseRoomsOption.value,
        type: reason.value,
        comment,
        is_delete: false,
      };

      dispatch(
        setRoomConstraints({
          [roomId]: {
            roomConstraints: [submitAvailability, ...roomConstraints],
            initialRoomConstraints,
          },
        }),
      );

      dispatch(resetRangeSelectCells());
    }
  };

  useEffect(() => {
    const roomConstraints = toValueOrEmptyArray(
      storeRoomConstraints?.[roomId]?.roomConstraints,
    );
    const datesAvailabilities = toValueOrEmptyArray(
      storeDatesAvailabilities?.[roomId],
    );
    // Вычисляет кол-во номеров, которые можно закрыть - берется макс. доступное кол-во номеров
    const maxRoomsNumber = buildMaxAvailableRoomsNumber(
      ranges,
      datesAvailabilities,
      roomConstraints,
    );

    const newCloseRoomsOptions = getCountOptions(
      MIN_CLOSE_ROOMS_NUMBER,
      maxRoomsNumber,
    );

    setCloseRoomsOptions(newCloseRoomsOptions);
  }, [ranges, roomConstraints, roomId, storeDatesAvailabilities]);

  useEffect(() => {
    // При смене опций берем первую
    setCurrentCloseRoomsOption(closeRoomsOptions[0]);
  }, [closeRoomsOptions]);

  useEffect(() => {
    if (!notAvailableDates) {
      return;
    }

    const currentRange = ranges && ranges[0];

    if (currentRange && currentRange.startDate && currentRange.endDate) {
      const {startDate, endDate} = currentRange;

      // парсим массив недоступных дат из string > Date
      const parsedNotAvailableDates = notAvailableDates.map((date) =>
        parseISO(date),
      );
      // создаем интервал выбранных дат (Date[])
      const currentRangeInterval = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });

      // отключаем кнопку если интервал содержит только недоступные даты
      const isSaveDisabled = currentRangeInterval.every((date) => {
        return parsedNotAvailableDates.some(
          (notAvailableDate) => notAvailableDate.getTime() === date.getTime(),
        );
      });

      setSaveDisabled(isSaveDisabled);
    }
  }, [notAvailableDates, ranges]);

  useEffect(() => {
    // Актуализация пикера при переключении между вкладками
    const {from, to} = currentRange;

    setRanges([{startDate: from, endDate: to, key: RANGE_KEY}]);
  }, [currentRange]);

  if (rangeSelectCells.prices.length <= 0) {
    return null;
  }

  return (
    <>
      <ContentWrapper isTouch={isTouch}>
        <StyledCalendarMassEditPicker
          noPadding
          ranges={ranges}
          setRanges={setRanges}
          onRangeChange={onRangeChange}
        />
        <RangeNotAvailableText size="XS" visible={saveDisabled}>
          {t('room_range.range_not_available')}
        </RangeNotAvailableText>

        <StyledCalendarMassEditFilters withoutRateFilters />
        <Row>
          <RowTitle size="XS">{t('room_range.reason')}</RowTitle>
          <StyledSelect
            value={reason}
            options={AVAILABILITY_REASON_OPTIONS}
            setValue={(option) => setReason(option)}
          />
        </Row>
        <Row>
          <RowTitle size="XS">{t('room_range.rooms_count')}</RowTitle>
          <CheckBoxWrapper isMobile={mobile}>
            <StyledSelect
              value={currentCloseRoomsOption}
              options={closeRoomsOptions}
              setValue={(option) => setCurrentCloseRoomsOption(option)}
            />
          </CheckBoxWrapper>
        </Row>
        <Row>
          <RowTitle size="XS">{t('room_range.comment')}</RowTitle>
          <StyledTextarea
            noPadding
            noBorder
            maxWidth
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </Row>
      </ContentWrapper>
      <ButtonsWrapper>
        <ActionButtons
          isEdit
          type="apply"
          onSave={handleApply}
          onCancel={onCancel}
          submitDisabled={saveDisabled}
        />
      </ButtonsWrapper>
    </>
  );
};

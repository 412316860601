import {Option} from 'library/components/select';
import {GetServerSideProps} from 'next';
import {Translate} from 'next-translate';
import {andThen, assoc, assocPath, call, pipeWith, when} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {
  handleException,
  showSuccessMessage,
} from 'source/utilities/exceptions/business';
import {throwRedirectException} from 'source/utilities/exceptions/network';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {isUnhandledException} from 'source/utilities/guards/exceptions';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {HotelsRealtyIntegrationsCreateRequest} from 'types/api-scheme';
import CLongPolling from 'source/utilities/long-polling';
import {
  IntegrationPageProperties,
  EIntegrations,
  EIntergrationsLabels,
} from './lib/types';
import {LONG_POLLING_INTERVAL} from './lib/constants';

export const getIntegrationPageProperties: GetServerSideProps<
  IntegrationPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const utilities = {
    mobile: isMobile(context.req.headers['user-agent']),
    tablet: isTablet(context.req.headers['user-agent']),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestBedTypes = () => api.directories.getBedTypes({headers});
  const requestRoomsShorts = () =>
    api.rooms.getShorts({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestRates = () =>
    api.rates.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );
  const requestRoomCategories = () =>
    api.directories.getRoomCategories({headers});

  const requestFacilities = () =>
    api.directories.getRoomFacilityGroups({headers});

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [
            hotel,
            bedTypes,
            roomCategories,
            facilities,
            roomShorts,
            rates,
          ] = await Promise.all([
            requestHotel(),
            requestBedTypes(),
            requestRoomCategories(),
            requestFacilities(),
            requestRoomsShorts(),
            requestRates(),
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            bedTypes,
            roomCategories,
            facilities,
            roomShorts,
            rates,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const handleEnableIntegration = (
  hotelId: Hotel['id'],
  integrationOption: Option<EIntegrations>,
  translate: Translate,
  ...dispatchers: [LoadingDispatch, HotelDispatch]
) => {
  const [setLoading, setHotel] = dispatchers;

  setLoading(true);

  api.integration
    .enable({hotelId, integrationType: integrationOption.value})
    .then((response) => {
      if (response && response.token) {
        setHotel(assocPath(['bnovo_integration'], response));
        showSuccessMessage(
          translate('success_enabled', {
            providerName: integrationOption.label,
          }),
        );
      }
    })
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const handleDisableIntegration = (
  hotelId: Hotel['id'],
  integrationOption: Option<EIntegrations>,
  translate: Translate,
  ...dispatchers: [LoadingDispatch, HotelDispatch]
) => {
  const [setLoading, setHotel] = dispatchers;

  setLoading(true);

  api.integration
    .disable({hotelId, integrationType: integrationOption.value})
    .then(() => {
      setHotel(assocPath(['bnovo_integration'], null));
      showSuccessMessage(
        translate('success_disable', {
          providerName: integrationOption.label,
        }),
      );
    })
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const handleFetchCopyableRooms = (
  hotelId: Hotel['id'],
  onComplete: (data?: CopyableIntegrationRooms) => void,
  ...dispatchers: [LoadingDispatch]
) => {
  const [setLoading] = dispatchers;

  setLoading(true);

  api.realtyIntegration
    .getCopyableRooms({hotelId})
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const handleFetchRealtyIntegrations = (
  hotelId: Hotel['id'],
  onComplete: (data?: RealtyIntegration[]) => void,
  setLoading: LoadingDispatch,
) => {
  setLoading(true);

  api.realtyIntegration
    .get({hotelId})
    .then(onComplete)
    .catch(handleException)
    .finally(() => {
      setLoading(false);
    });
};

export const pollingRealtyIntegrations = (
  hotelId: Hotel['id'],
  onComplete: (data?: RealtyIntegration[]) => void,
) => {
  const abortController = new AbortController();

  const updateRealtyIntegrations = async () => {
    api.realtyIntegration
      .get({hotelId})
      .then(onComplete)
      .catch(handleException);
  };

  return new CLongPolling({
    duration: LONG_POLLING_INTERVAL,
    takeWhile: () => true,
    mergeMap: updateRealtyIntegrations,
    finalize: () => {},
    abortController,
  });
};

export const handleCreateRealtyIntegration = (
  hotelId: Hotel['id'],
  payload: HotelsRealtyIntegrationsCreateRequest,
  translate: Translate,
  onComplete: (data: RealtyIntegration) => void,
  ...dispatchers: [LoadingDispatch]
) => {
  const [setLoading] = dispatchers;

  setLoading(true);

  api.realtyIntegration
    .create({hotelId, payload})
    .then((response) => {
      if (response && response.accommodation_id) {
        onComplete(response);
        showSuccessMessage(
          translate('success_enabled', {
            providerName: EIntergrationsLabels.REALTY,
          }),
        );
      }
    })
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const handleDeleteRealtyIntegrations = (
  hotelId: Hotel['id'],
  integrationId: RealtyIntegration['id'],
  translate: Translate,
  onComplete: (id: RealtyIntegration['id']) => void,
  ...dispatchers: [LoadingDispatch]
) => {
  const [setLoading] = dispatchers;

  setLoading(true);

  api.realtyIntegration
    .delete({hotelId, integrationId})
    .then(() => {
      onComplete(integrationId);
      showSuccessMessage(
        translate('success_disable', {
          providerName: EIntergrationsLabels.REALTY,
        }),
      );
    })
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const handleRestoreRealtyIntegration = (
  hotelId: Hotel['id'],
  integrationId: RealtyIntegration['id'],
  translate: Translate,
  onComplete: (id: RealtyIntegration['id']) => void,
  ...dispatchers: [LoadingDispatch]
) => {
  const [setLoading] = dispatchers;

  setLoading(true);

  api.realtyIntegration
    .restore({hotelId, integrationId})
    .then(() => {
      onComplete(integrationId);
      showSuccessMessage(
        translate('success_restore', {
          providerName: EIntergrationsLabels.REALTY,
        }),
      );
    })
    .catch(handleException)
    .finally(() => setLoading(false));
};

import styled from '@emotion/styled';
import {Headline} from 'library/components/headline';
import {CircularLoader} from 'library/components/loader';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  handleAttachToTelegram,
  handleDetachToTelegram,
  pollingSubscribeUser,
} from 'slices/profile/ui/social-subscribe/network';
import {Icon} from 'source/components/icon';
import {Link} from 'source/components/link';
import {UserContext} from 'source/context/user';
import {api} from 'source/utilities/api';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import CLongPolling from 'source/utilities/long-polling';
import {UserNotificationsTelegramBotInfoListResponse} from 'types/api-scheme';

const Wrapper = styled.div`
  width: 100%;
  margin: 54px 0px 54px 0px;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 21px;
`;

const SocialInformationWrapper = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid ${({theme}) => theme.palette.activeBackground};
  padding: 19px 0px;

  &:last-child {
    border-bottom: 1px solid ${({theme}) => theme.palette.activeBackground};
  }
`;

const SocialInformationTitle = styled(Text)``;

const SocialInformationSubTitle = styled(Text)``;

const SocialInformationIconWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const SocialInformationIconTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
`;

const SocialInformationIcon = styled(Icon)`
  margin-right: 8px;
  min-width: 25px;
  min-height: 25px;
`;

const SocialInformationActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTelegramBotLink = styled(Link)`
  margin-right: 20px;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SocialSubscribe = () => {
  const pollingUserRef = useRef<CLongPolling | null>(null);
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation('profile');

  const textSize = mobile ? 'XS' : 'S';

  const [user, setUser] = useContext(UserContext);
  const [isLoadingTelegramLink, setIsLoadingTelegramLink] = useState(false);
  const [telegramBotInfo, setTelegramBotInfo] = useState<
    UserNotificationsTelegramBotInfoListResponse['data'] | null
  >(null);

  const isTelegramAttached = Boolean(user?.telegram_user_id);

  const handleClickTelegram = isTelegramAttached
    ? handleDetachToTelegram
    : handleAttachToTelegram;

  const createUserPolling = () => {
    const userPolling = pollingUserRef.current;

    if (!userPolling) {
      pollingUserRef.current = pollingSubscribeUser(setUser);
      pollingUserRef.current?.start();
    }
  };

  const removeUserPolling = () => {
    const userPolling = pollingUserRef.current;

    if (userPolling) {
      userPolling.stop();
      userPolling.subscribe?.unsubscribe();
      pollingUserRef.current = null;
    }
  };

  useEffect(() => {
    createUserPolling();

    api.socialSubscribe.getTelegramBotInfo().then((response) => {
      if (response) {
        setTelegramBotInfo(response);
      }
    });

    return removeUserPolling;
  }, []);

  return (
    <Wrapper>
      <StyledHeadline level="M">{t('social_subscribe.title')}</StyledHeadline>
      <SocialInformationWrapper>
        <SocialInformationIconWrapper>
          <SocialInformationIcon name="email" width={27} height={27} />
          <SocialInformationTitle size={textSize}>
            {t('social_subscribe.email.title')}
          </SocialInformationTitle>
        </SocialInformationIconWrapper>
        <SocialInformationSubTitle size={textSize}>
          {t(
            `social_subscribe.email.${mobile ? 'short_subtitle' : 'subtitle'}`,
          )}
        </SocialInformationSubTitle>
      </SocialInformationWrapper>
      <SocialInformationWrapper>
        <SocialInformationIconWrapper>
          <SocialInformationIcon name="telegram" width={27} height={27} />
          <SocialInformationIconTextWrapper>
            <SocialInformationTitle size={textSize}>
              {t('social_subscribe.telegram.title')}
            </SocialInformationTitle>
            {isTelegramAttached && telegramBotInfo && mobile && (
              <StyledTelegramBotLink href={telegramBotInfo.url} target="_blank">
                {telegramBotInfo.url}
              </StyledTelegramBotLink>
            )}
          </SocialInformationIconTextWrapper>
        </SocialInformationIconWrapper>
        <SocialInformationActionWrapper>
          {isTelegramAttached && telegramBotInfo && !mobile && (
            <StyledTelegramBotLink href={telegramBotInfo.url} target="_blank">
              {telegramBotInfo.url}
            </StyledTelegramBotLink>
          )}
          {isLoadingTelegramLink ? (
            <CircularLoader size={28} />
          ) : (
            <Switch
              initialState={isTelegramAttached}
              isActive={isTelegramAttached}
              onSwitch={async () => {
                handleClickTelegram(setIsLoadingTelegramLink, setUser);
              }}
            />
          )}
        </SocialInformationActionWrapper>
      </SocialInformationWrapper>
    </Wrapper>
  );
};

export default SocialSubscribe;

import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import {FC, MouseEvent, ReactNode} from 'react';
import {Icon, IconName} from 'source/components/icon';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  width: max-content;
  gap: 12px;
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;
`;

const StyledText = styled(Text)<{highlightText?: boolean}>`
  &:hover {
    color: ${({highlightText, theme}) =>
      highlightText ? theme.palette.primaryAccent : 'inherit'};
    transition: 0.3s;
  }
`;

interface Props {
  iconName?: IconName;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  highlightText?: boolean;
  children: ReactNode;
}

const HeaderButton: FC<Props> = ({
  iconName,
  onClick,
  children,
  highlightText,
}) => (
  <StyledButton onClick={onClick}>
    {iconName ? <Icon name={iconName} width={20} height={20} /> : null}
    <StyledText highlightText={highlightText} size="S">
      {children}
    </StyledText>
  </StyledButton>
);

export default HeaderButton;

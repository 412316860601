import {EServiceTypeValues} from './types';

export const OTHER_TYPE_SERVICE_INCLUDE_TYPES = [];

export const SERVICE_TYPES = [
  {
    label: 'meal',
    value: EServiceTypeValues.MEAL,
  },
  {
    label: 'other',
    value: EServiceTypeValues.OTHER,
  },
];

export const MIN_SERVICE_PRICE = 0;

export const INITIAL_ERRORS = {
  name: false,
  type: false,
};

export const MEAL_TYPES_SEPARATOR = ', ';

export const INCLUDED_TYPES_DUMMY = '—';
export const PRICE_TYPES_DUMMY = '—';

import {Option} from 'library/components/select';
import {EIntegrations, EIntergrationsLabels} from './types';

export const CHANNEL_MANAGERS_OPTIONS: Option<EIntegrations>[] = [
  {
    label: EIntergrationsLabels.BNOVO,
    value: EIntegrations.BNOVO,
  },
  {
    label: EIntergrationsLabels.REALTY,
    value: EIntegrations.REALTY,
  },
];

export const ADD_NEW_ROOM_OPTION = {
  label: 'create_new_room',
  value: -1,
};

export const REALTY_TIMER_COUNTDOWN = 600;
export const TIMER_END_SECONDS = 0;
export const LONG_POLLING_INTERVAL = 5000;

import {Theme} from '@emotion/react';
import {WITHDRAWAL_STATUSES} from './types';

export const LAYOUT_MAX_WIDTH = 1600;

export const WITHDRAWAL_STATUS_COLORS: Record<
  WITHDRAWAL_STATUSES,
  (theme: Theme) => string
> = {
  [WITHDRAWAL_STATUSES.CALCULATING]: (theme: Theme) => theme.palette.action,
  [WITHDRAWAL_STATUSES.ERROR]: (theme: Theme) => theme.palette.danger,
  [WITHDRAWAL_STATUSES.IN_PROCESS]: (theme: Theme) => theme.palette.wating,
  [WITHDRAWAL_STATUSES.CANCELLED]: (theme: Theme) => theme.palette.danger,
  [WITHDRAWAL_STATUSES.COMPLETED]: (theme: Theme) => theme.palette.success,
};

export const LONG_POLLING_INTERVAL = 5000;

import {Option} from 'library/components/select';
import {
  either,
  filter,
  findIndex,
  identity,
  ifElse,
  is,
  isEmpty,
  isNil,
  map,
  not,
  pipe,
  toPairs,
  whereEq,
} from 'ramda';

export const removeUndefined = <T>(object: T): T => {
  return Object.fromEntries(
    toPairs(object as Object).filter(([, value]) => value !== undefined),
  ) as T;
};

/**
 * @param object
 * @example
 * ```typescript
 * removeNil(null); //=> true
 * removeNil(undefined); //=> true
 * removeNil(0); //=> false
 * removeNil([]); //=> false
 * ```
 */
export const removeNil = <T>(object: T): T => {
  const remove = ifElse(
    either(is(Array), is(Object)),
    pipe(
      filter(pipe(isNil, not)),
      map((item) => removeNil(item)),
    ),
    identity,
  );
  return remove(object) as T;
};

export const removeAllEmptyValues = <T>(object: T): T => {
  const remove = ifElse(
    either(is(Array), is(Object)),
    pipe(
      filter(pipe(either(isNil, isEmpty), not)),
      map((item) => removeAllEmptyValues(item)),
    ),
    identity,
  );
  return remove(object) as T;
};

export const checkIsEmptyObject = <T>(object: T): boolean => isEmpty(object);

export const getEnumValues = <T>(object: T): T => {
  return Object.values(object as Object).filter(
    (item) => typeof item !== 'string',
  ) as T;
};

export const isEqualObjects = <T, K>(object1: T, object2: K): boolean => {
  if (!object1 || !object2) {
    return false;
  }

  return findIndex(whereEq(object1))([object2]) !== -1;
};

export const getCountOptions = (min: number, max: number): Option[] => {
  return Array.from({length: max - min + 1}).map((_, index) => {
    return {
      value: index + min,
      label: `${index + min}`,
    };
  });
};

export const getCountOption = (n: number) => {
  return {
    value: n,
    label: `${n}`,
  };
};

import NextLink from 'next/link';
import styled from '@emotion/styled';
import {css} from '@emotion/react';

const styles = {
  primary: css`
    background-color: #ff5876;
  `,
  secondary: css`
    color: #5b5b6b;
    background-color: #fff;
    border: 1px solid #ebebeb;
  `,
  blue: css`
    background-color: #0057d2;
  `,
};

type ButtonLinkProps = {
  href: string;
  target?: string;
  rel?: string;
  variant: 'primary' | 'secondary' | 'blue';
  mobile?: boolean;
};

export const ButtonLink = styled(NextLink)<ButtonLinkProps>`
  display: inline-block;
  padding: 20px;
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  transition: 200ms ease-in-out;

  ${({variant}) => styles[variant]}
`;

import {useState} from 'react';
import {PasswordRequestForm, PasswordResetForm} from './ui/form';
import {FormStateEnum} from './lib/type';

const Content = () => {
  const [formState, setFormState] = useState(FormStateEnum.PASSWORD_REQUEST);
  const [email, setEmail] = useState('');
  if (formState === FormStateEnum.PASSWORD_RESET) {
    return <PasswordResetForm email={email} />;
  }

  return (
    <PasswordRequestForm setEmail={setEmail} setFormState={setFormState} />
  );
};

export default Content;

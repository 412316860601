import Router from 'next/router';
import {api} from 'source/utilities/api';
import {CookieNames, deleteCookie} from 'source/utilities/network/cookies';
import {Routes} from 'source/utilities/network/url';
import {UserDisableConfirmCreateRequest} from 'types/api-scheme';

export const disableProfile = async (
  onComplete: () => void,
  onError: (message: string) => void,
  setLoading: LoadingDispatch,
) =>
  api.user
    .getDisableProfileCode()
    .then(onComplete)
    .catch((error) => onError(error.message))
    .finally(() => setLoading(false));

export const confirmDisableProfile = async (
  payload: UserDisableConfirmCreateRequest,
  onComplete: () => void,
  onError: (message: string) => void,
  setLoading: LoadingDispatch,
  setUser: UserDispatch,
) => {
  api.user
    .confirmDisableProfileCode({
      payload,
    })
    .then(() => {
      onComplete();
      setUser(null);
      deleteCookie(CookieNames.TEMPORARY_AUTHORIZATION);
      deleteCookie(CookieNames.HOTELIER_AUTHORIZATION);
      Router.push(Routes.home);
    })
    .catch((error) => {
      onError(error[1].message);
    })
    .finally(() => setLoading(false));
};

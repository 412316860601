import {FC, useContext, useEffect, useRef} from 'react';
import CLongPolling from 'source/utilities/long-polling';
import {api} from 'source/utilities/api';
import {handleException} from 'source/utilities/exceptions/business';
import {clientHeaders} from 'source/utilities/network/http';
import {useRouter} from 'next/router';
import {BalancesTotalListResponse} from 'types/api-scheme';
import {useAppDispatch} from 'source/store';
import {setBalance} from 'slices/common';
import {Routes} from 'source/utilities/network/url';
import {AuthorizationContext} from './authorization';

const LONG_POLLING_INTERVAL = 5000;
const balancePollingPages = new Set([Routes.balance, Routes.balanceDetails]);

const pollingUserBalance = (
  headers: Headers,
  onComplete: (data?: BalancesTotalListResponse['data']) => void,
) => {
  const abortController = new AbortController();

  const updateUserBalance = async () => {
    api.balance.get(headers).then(onComplete).catch(handleException);
  };

  return new CLongPolling({
    duration: LONG_POLLING_INTERVAL,
    takeWhile: () => true,
    mergeMap: updateUserBalance,
    finalize: () => {},
    abortController,
  });
};

interface IProps {
  serverValue?: User['balance'];
}

export const BalanceProvider: FC<IProps> = ({serverValue}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const shouldEnableBalancePolling = balancePollingPages.has(
    router.pathname as Routes,
  );

  const [authorized] = useContext(AuthorizationContext);

  const longPollingReference = useRef<CLongPolling | null>(null);

  useEffect(() => {
    if (!serverValue) {
      return;
    }

    const parsedBalance = {
      amount: serverValue?.amount,
      amountHold: serverValue?.amount_hold,
    };

    dispatch(setBalance(parsedBalance || null));
  }, [serverValue]);

  useEffect(() => {
    // Каждые 5 секунд обновляет баланс юзера
    const LongPolling = longPollingReference.current;
    const headers = clientHeaders();

    if (authorized && !LongPolling && shouldEnableBalancePolling) {
      longPollingReference.current = pollingUserBalance(headers, (data) => {
        if (data) {
          const {amount, amount_hold: amountHold} = data;

          dispatch(setBalance({amount, amountHold}));
        }
      });
      longPollingReference.current?.start();
    }

    return () => {
      const LongPolling = longPollingReference.current;

      if (LongPolling) {
        LongPolling.stop();
        LongPolling.subscribe?.unsubscribe();
        longPollingReference.current = null;
      }
    };
  }, [authorized, router.pathname, shouldEnableBalancePolling]);

  return null;
};

import {useTheme} from '@emotion/react';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';

import {useAppDispatch, useAppSelector} from 'source/store';
import {LabeledComponent} from 'library/components/labeled-component';

import {Select} from 'library/components/select';
import {getCountOption, getCountOptions} from 'source/utilities/object';
import {selectRoomBathrooms, setBathNumber} from '../../store';
import {getLabelTranslate} from '../../lib/helpers';

interface IProps {
  translateKey: string;
}

export const BathroomsSelect: FC<IProps> = ({translateKey}) => {
  const theme = useTheme();
  const bathRooms = useAppSelector(selectRoomBathrooms);
  const dispatch = useAppDispatch();
  const {t} = useTranslation(translateKey);
  const bathRoomsOptions = getCountOptions(0, 10);

  return (
    <LabeledComponent
      color={theme.palette.fontSecondary}
      size="S"
      gap={10}
      text={getLabelTranslate(t, 'bathrooms')}
    >
      <Select
        value={getCountOption(bathRooms)}
        options={bathRoomsOptions}
        setValue={(option) => dispatch(setBathNumber(option.value))}
      />
    </LabeledComponent>
  );
};

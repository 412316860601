import {IHttpHandlerActions} from 'library/api/interceptors/catch/status-code/lib/types';
import {showException} from 'source/utilities/exceptions/business';
import {throwRedirectException} from 'source/utilities/exceptions/network';
import {isUnauthenticatedException} from 'source/utilities/guards/exceptions';
import {redirects} from 'source/utilities/network/url';

export const HttpHandlerActions: IHttpHandlerActions = {
  UNAUTHORIZED: ({parameters}) => {
    if (isUnauthenticatedException(parameters)) {
      throwRedirectException(redirects.authorization, {
        authorized: false,
        user: null,
        utilities: {},
      })();
    }
  },
  TOO_MANY_REQUESTS: ({isServer}) => {
    if (isServer) {
      throwRedirectException(redirects.home)();
    } else {
      showException('too_many_request');
    }
  },
  NOT_FOUND: ({parameters, isServer}) => {
    if (isServer) {
      throwRedirectException(redirects.notFoundPage)();
    } else {
      throw parameters;
    }
  },
};

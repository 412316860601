import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {isWeekend, format} from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import {FC, memo} from 'react';

import {LOADING_STYLES} from 'source/utilities/constants/css';
import {Text} from 'library/components/text';

import {formatMonthFromDate} from 'slices/calendar/lib/helpers/dates';
import {blinkAnimation, buildDateCellId} from 'slices/calendar/lib/helpers/ui';
import {START_OF_MONTH_DAY} from 'slices/calendar/lib/constants';

import {selectIsLoading} from 'slices/calendar/store';
import {useAppSelector} from 'source/store';
import {TableButton} from '../../table-button';

const Wrapper = styled.div<{isLoading: boolean}>`
  ${({isLoading}) =>
    isLoading &&
    css`
      ${LOADING_STYLES}
    `}
`;

const StyledDateButton = styled(TableButton)<{
  selected: boolean;
  weekend: boolean;
}>`
  ${({theme, selected}) =>
    selected &&
    css`
      color: ${theme.palette.fontPrimary};
      border-color: ${theme.palette.border_4};
      font-weight: 700;
      animation: ${blinkAnimation} 0.3s;
    `}

  ${({theme, weekend}) =>
    weekend &&
    css`
      background-color: ${theme.palette.notAvailableCell};
    `}
  }
`;
interface IProps {
  selected: boolean;
  date: Date;
}

const DateCellComponent: FC<IProps> = ({selected, date}) => {
  const {t} = useTranslation('calendar');
  const loading = useAppSelector(selectIsLoading);

  return (
    <Wrapper isLoading={loading} id={buildDateCellId(date)}>
      <StyledDateButton selected={selected} weekend={isWeekend(date)}>
        {format(date, 'dd')}
      </StyledDateButton>
      {date.getDate() === START_OF_MONTH_DAY ? (
        <Text size="XS" align="center">
          {t(`month.${formatMonthFromDate(date)}`)}
        </Text>
      ) : null}
    </Wrapper>
  );
};

export const DateCell = memo(DateCellComponent);

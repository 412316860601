import {ApplicationProperties} from 'source/utilities/ui';

export interface BalanceDetailsPageProperties extends ApplicationProperties {
  organization?: ExistingRequisitesItem;
  withdrawals?: Withdrawals;
  initialPagination?: WithdrawalsPagination;
}

export interface BalanceDetailsStoreInitialState {
  withdrawals: Withdrawals;
  organization: ExistingRequisitesItem | null;
  loading: boolean;
  page: number;
  isLast: boolean;
}

export enum WITHDRAWAL_STATUSES {
  CALCULATING = 1,
  ERROR = 2,
  IN_PROCESS = 3,
  CANCELLED = 4,
  COMPLETED = 5,
}

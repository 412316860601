import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RateStatePayload, TableRate} from 'slices/rates/lib/types';
import {RootState} from 'source/store';

interface IRatesState {
  rates: TableRate[];
  isWaitingCreate: boolean;
  isShowDisabled: boolean;
}

const initialState: IRatesState = {
  rates: [],
  isWaitingCreate: false,
  isShowDisabled: true,
};

export const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setRates: (state, action: PayloadAction<TableRate[]>) => {
      state.rates = action.payload;
    },
    setIsWaitingCreate: (state, action: PayloadAction<boolean>) => {
      state.isWaitingCreate = action.payload;
    },
    removeByRateId: (state, action: PayloadAction<number>) => {
      const rateToRemove = action.payload;
      state.rates = state.rates.filter((rate) => rate.id !== rateToRemove);
    },
    setRateState: (state, action: PayloadAction<RateStatePayload>) => {
      const {id, enabled} = action.payload;
      const rateIndex = state.rates.findIndex((rate) => rate.id === id);
      if (rateIndex > -1) {
        const rate = state.rates[rateIndex];
        state.rates[rateIndex] = {...rate, enabled};
      }
    },
  },
});

export const selectRates = (state: RootState) => state.rates.rates;

export const selectIsWaitingCreate = (state: RootState) =>
  state.rates.isWaitingCreate;

export const {
  reset,
  setRateState,
  setRates,
  setIsWaitingCreate,
  removeByRateId,
} = ratesSlice.actions;

export default ratesSlice.reducer;

import styled from '@emotion/styled';
import {NavigationLink} from 'library/components/navigation-link';
import {Text} from 'library/components/text';
import {BottomArrowIconSmall} from 'library/icons/bottom-arrow-icon';
import useTranslation from 'next-translate/useTranslation';
import {FC, useState} from 'react';
import {Icon, IconName} from 'source/components/icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.button<{isOpen: boolean}>`
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;

  .arrow-icon {
    transition: all 0.3s;
    transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'unset')};
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`;

interface GroupProps {
  iconName: IconName;
  title: string;
  links: Anchor[];
}

const LinksGroup: FC<GroupProps> = ({iconName, title, links}) => {
  const {t} = useTranslation('routing');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      <StyledButton
        isOpen={isOpen}
        onClick={() => setIsOpen((previous) => !previous)}
      >
        <Icon name={iconName} width={20} height={20} />
        <Text size="S">{t(title)}</Text>
        <BottomArrowIconSmall className="arrow-icon" />
      </StyledButton>
      {isOpen ? (
        <LinksWrapper>
          {links.map((link) => (
            <NavigationLink key={link.href} href={link.href}>
              {t(link.title)}
            </NavigationLink>
          ))}
        </LinksWrapper>
      ) : null}
    </Wrapper>
  );
};

export default LinksGroup;

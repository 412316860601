import {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith, when} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {throwRedirectException} from 'source/utilities/exceptions/network';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {isUnhandledException} from 'source/utilities/guards/exceptions';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {
  HotelsServicesCreateRequest,
  HotelsServicesCreateResponse,
  HotelsServicesUpdateResponse,
} from 'types/api-scheme';
import {FacilitiesPageProperties} from './lib/types';

export const getFacilitiesPageProperties: GetServerSideProps<
  FacilitiesPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestFacilities = () =>
    api.facilities.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestRawFacilities = () =>
    api.directories.getHotelFacilityGroups({headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestServices = () =>
    api.services.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.log(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestMealTypesDirectory = () =>
    api.directories.getMealTypes({headers}).catch(
      when(isUnhandledException, (error) => {
        console.log(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [
            hotel,
            facilities,
            rawFacilities,
            services,
            mealTypesDirectory,
          ] = await Promise.all([
            requestHotel(),
            requestFacilities(),
            requestRawFacilities(),
            requestServices(),
            requestMealTypesDirectory(),
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            facilities,
            rawFacilities,
            services,
            mealTypesDirectory,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const saveFacility = (
  hotelId: Hotel['id'],
  facilities: HotelFacilities,
) =>
  api.facilities.save({hotelId, payload: {facilities}}).catch(handleException);

export const createService = (
  hotelId: Hotel['id'],
  payload: HotelsServicesCreateRequest,
  onComplete: (data?: HotelsServicesCreateResponse['data']) => void,
  ...dispatchers: [LoadingDispatch, BooleanDispatch]
) => {
  const [setLoading, setIsPopupOpen] = dispatchers;

  api.services
    .create({hotelId, payload})
    .then(onComplete)
    .catch(handleException)
    .finally(() => {
      setLoading(false);
      setIsPopupOpen(false);
    });
};

export const editService = (
  hotelId: Hotel['id'],
  serviceId: number,
  payload: ClientHotelService,
  onComplete: (data?: HotelsServicesUpdateResponse['data']) => void,
  ...dispatchers: [LoadingDispatch, BooleanDispatch]
) => {
  const [setLoading, setIsPopupOpen] = dispatchers;

  return api.service
    .edit({hotelId, serviceId, payload})
    .then(onComplete)
    .catch(handleException)
    .finally(() => {
      setLoading(false);
      setIsPopupOpen(false);
    });
};

export const deleteService = (
  hotelId: Hotel['id'],
  serviceId: number,
  onComplete: () => void,
  ...dispatchers: [LoadingDispatch, BooleanDispatch]
) => {
  const [setLoading, setIsPopupOpen] = dispatchers;

  return api.service
    .delete({hotelId, serviceId})
    .then(onComplete)
    .catch(handleException)
    .finally(() => {
      setLoading(false);
      setIsPopupOpen(false);
    });
};

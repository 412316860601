import styled from '@emotion/styled';
import {FC, PropsWithChildren} from 'react';
import {Text, TextSize} from '../text';

const StyledBadge = styled(Text)<{backgroundColor: string}>`
  padding: 2px 5px;
  border-radius: 6px;
  width: fit-content;
  height: min-content;
  background-color: ${({backgroundColor}) => backgroundColor};
  text-align: center;
`;

interface BadgeProps extends PropsWithChildren {
  textSize?: TextSize;
  textColor: string;
  backgroundColor: string;
  className?: string;
}

export const Badge: FC<BadgeProps> = ({
  textSize = 'S',
  textColor,
  backgroundColor,
  children,
  className,
}) => {
  return (
    <StyledBadge
      className={className}
      size={textSize}
      color={textColor}
      backgroundColor={backgroundColor}
    >
      {children}
    </StyledBadge>
  );
};

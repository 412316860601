import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {HotelsManageCalendarPartialUpdateRequest} from 'types/api-scheme';

type GetCalendarDataContext = {
  hotelId: number;
  roomId: number;
  headers?: Headers;
  from: string;
  to: string;
};
const getCalendarData = ({
  hotelId,
  roomId,
  headers,
  from,
  to,
}: GetCalendarDataContext) =>
  request<ManageCalendar>(
    url(`hotels/${hotelId}/rooms/${roomId}/calendar`, {from, to}),
    {
      method: 'GET',
      headers: headers || clientHeaders(),
    },
  ).then(path<ManageCalendar>([1, 'data']));

type SaveCalendarChangesContext = {
  hotelId: number;
  payload: HotelsManageCalendarPartialUpdateRequest;
};
const saveChanges = ({payload, hotelId}: SaveCalendarChangesContext) =>
  request<UpdateManageCalendar>(url(`hotels/${hotelId}/manage-calendar`), {
    method: 'PATCH',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<UpdateManageCalendar>([1, 'data']));

type GetRoomAvailabilitiesContext = {
  hotelId: number;
  roomId: number;
  headers?: Headers;
  from: string;
  to: string;
};
const getRoomAvailabilities = ({
  hotelId,
  roomId,
  headers,
  from,
  to,
}: GetRoomAvailabilitiesContext) =>
  request<RoomAvailabilities>(
    url(`hotels/${hotelId}/rooms/${roomId}/room-availabilities`, {from, to}),
    {
      method: 'GET',
      headers: headers || clientHeaders(),
    },
  ).then(path<RoomAvailabilities>([1, 'data']));

type GetHotelRoomsContext = {
  hotelId: number;
  headers?: Headers;
  from: string;
  to: string;
};
const getHotelRooms = ({hotelId, headers, from, to}: GetHotelRoomsContext) =>
  request<HotelRooms>(url(`hotels/${hotelId}/rooms/calendar`, {from, to}), {
    method: 'GET',
    headers: headers || clientHeaders(),
  }).then(path<HotelRooms>([1, 'data']));

export const manageCalendarApi = {
  get: getCalendarData,
  save: saveChanges,
  getRoomAvailabilities,
  getHotelRooms,
};

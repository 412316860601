import styled from '@emotion/styled';
import {ButtonLink} from 'library/components/button-link';
import {Text} from 'library/components/text';
import {FC} from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0px;
`;

const StyledButton = styled(ButtonLink)`
  min-width: 280px;
  width: fit-content;
  text-transform: uppercase;
  height: 58px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

interface Props {
  buttonLabel: string;
  subtitle: string;
  href: string;
  className?: string;
}

export const NoContent: FC<Props> = ({
  buttonLabel,
  subtitle,
  href,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <StyledButton variant="primary" href={href}>
        {buttonLabel}
      </StyledButton>
      <Text size="S">{subtitle}</Text>
    </Wrapper>
  );
};

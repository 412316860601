import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Headline} from 'library/components/headline';
import {Input} from 'library/components/input';
import useTranslation from 'next-translate/useTranslation';
import {
  __,
  all,
  allPass,
  any,
  assoc,
  equals,
  gt,
  lt,
  map,
  not,
  pathOr,
  pipe,
  prop,
  replace,
  test,
  when,
} from 'ramda';
import type {Dispatch, FC, SetStateAction} from 'react';
import {useCallback, useState} from 'react';
import {FormStateDispatch} from 'slices/password-reset/lib/type';
import {patterns} from 'source/utilities/patterns';
import {passwordRequest} from '../../../network';

const Form = styled.form`
  width: min(440px, 100%);
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledHeadline = styled(Headline)`
  font-size: max(17px, 0.8vw);
`;

interface Props {
  setFormState: FormStateDispatch;
  setEmail: Dispatch<SetStateAction<string>>;
}

const PasswordRequestForm: FC<Props> = ({setFormState, setEmail}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formdata, setFormdata] = useState<PasswordRequestFormdata>({
    email: '',
  });

  const handleChange = useCallback(
    (key: keyof AuthorizationFormdata) =>
      pipe(
        pathOr('', ['target', 'value']),
        when(() => key === 'email', replace(/\s/g, '')),
        assoc(key),
        setFormdata
      ),
    []
  );

  const filled = all(
    allPass([gt(__, 0), lt(__, 256)]),
    map(prop('length'), [formdata.email])
  );

  const formatted = all(
    equals(true),
    map((key) => test(patterns[key], formdata[key]), ['email'] as const)
  );

  return (
    <Form
      onSubmit={passwordRequest(
        formdata,
        setFormState,
        t,
        setLoading,
        setEmail
      )}
    >
      <StyledHeadline level={1}>{t('password-reset:subtitle')}</StyledHeadline>
      <Input
        required
        type="email"
        pattern={patterns.compatible('email')}
        patternMessage={t('components:patternMessages.email_example')}
        name="email"
        maxLength={255}
        label={null}
        placeholder={t('components:placeholders.email_input')}
        value={formdata.email}
        disabled={loading}
        onKeyDown={(event) => {
          if (event.code === 'Space') {
            event.preventDefault();
          }
        }}
        onChange={handleChange('email')}
      />
      <Button
        type="submit"
        disabled={any(equals(true), [loading, not(filled), not(formatted)])}
      >
        {t('components:next')}
      </Button>
    </Form>
  );
};

export default PasswordRequestForm;

import styled from '@emotion/styled';

import {HorizontalDivier} from 'library/components/divider';
import {NavigationLink} from 'library/components/navigation-link';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {FC} from 'react';

import {Logout} from 'slices/user/content';
import {SupportPopup} from '../../popups/support';
import {UserPopup} from '../../popups/user';
import {
  BurgerMobile,
  BurgerTablet,
  HotelierInfo,
  Logo,
  UserButton,
} from '../../ui';

const anchors: Anchor[] = [
  {
    title: 'manage',
    href: '/manage',
  },
  {
    title: 'bookings',
    href: '/bookings',
  },
  {
    title: 'balance',
    href: '/balance',
  },
];

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;
`;

const BurgerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`;

const BurgerNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 30px;
  width: 100%;
  max-width: 1420px;
`;

const StyledDivider = styled(HorizontalDivier)`
  margin-bottom: 20px;
`;

const PopupButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const AuthorizedManageScenario: FC<{
  mobile: boolean;
  tablet: boolean;
}> = ({mobile, tablet}) => {
  const {t} = useTranslation('routing');

  const router = useRouter();
  const isRegistrationPage = router.asPath.includes('registration');
  const navigation = isRegistrationPage
    ? []
    : anchors.map(({title, href}) => (
        <NavigationLink key={title} href={href}>
          {t(title)}
        </NavigationLink>
      ));

  if (mobile) {
    return (
      <StyledContent>
        <Logo />
        <PopupButtonsWrapper>
          <SupportPopup />
          <BurgerMobile>
            <BurgerInfo>
              <UserButton />
              <HotelierInfo />
              <Logout />
            </BurgerInfo>
            <BurgerNavigation>{navigation}</BurgerNavigation>
          </BurgerMobile>
        </PopupButtonsWrapper>
      </StyledContent>
    );
  }

  if (tablet) {
    return (
      <StyledContent>
        <Logo />

        <PopupButtonsWrapper>
          <SupportPopup />
          <BurgerTablet>
            <BurgerInfo>
              <UserButton />
              <HotelierInfo />
              <Logout />
            </BurgerInfo>
            <StyledDivider />
            <BurgerNavigation>{navigation}</BurgerNavigation>
          </BurgerTablet>
        </PopupButtonsWrapper>
      </StyledContent>
    );
  }

  return (
    <StyledContent>
      <Logo />
      <Navigation>{navigation}</Navigation>
      <PopupButtonsWrapper>
        <SupportPopup />
        <UserPopup />
      </PopupButtonsWrapper>
    </StyledContent>
  );
};

import {ApplicationProperties} from 'source/utilities/ui';

export interface RatePageProperties extends ApplicationProperties {
  hotel?: Hotel;
  rate?: Rate;
  rooms?: RoomsList;
  paidServices?: HotelServices;
}

export type RateConstraintsStay = Rate['constraints']['stay'];
export type RateConstraintExcludeRooms = Rate['constraint_exclude_rooms'];
export type RateHotelServices = Rate['services'];
export type RateHotelService = RateHotelServices['meal'];
export type RatePrices = Rate['prices'];
export type RatePrice = RatePrices[number];
export type GuestPrices = RatePrice['per_guests_number'];
export type GuestPrice = GuestPrices[number];
export type DayPrices = GuestPrice['values'];
export type DayPrice = DayPrices[number];
export type Periods = Required<Rate['constraints']>['stay']['periods'];

export interface IRateState {
  rate: Rate;
  rateSaving: boolean;
  rateActivating: boolean;
  savingPaymentMethods: boolean;
  isNeedSaving: boolean;
  paidServices: HotelServices;
}

export enum ECancellationRateType {
  // без штрафа
  WithoutFineForNoShow = 1,
  // невозвратный тариф
  NonRefundable = 2,
  // гибкие условия - если выбран этот тип, то заполняется
  FlexibleRules = 3,
}

export enum EDiscountRateType {
  // скидка
  Discount = 1,
  // наценка
  Margin = 2,
}

export enum ECalculationRateType {
  // общая
  General = 1,
  // на каждого гостя
  PerGuest = 2,
}

export enum EUnitRateType {
  Night = 1,
  Percent = 2,
  Currency = 3,
}

export enum EUnitConditionsType {
  OneNight = 1,
  FiftyPercents = 2,
  FullAmount = 3,
  Other = 4,
}

export enum EDaysOfWeek {
  Monday = 2,
  Tuesday = 4,
  Wednesday = 8,
  Thursday = 16,
  Friday = 32,
  Saturday = 64,
  Sunday = 128,
}

export enum FOCUSED_START_AND_END_INDEX {
  NONE = 0,
  ALL = 1,
}

export enum REQUIRED_PREPAYMENT_AMOUNT {
  MIN = 0,
  MAX = 1000,
}

export type RequiredRoomId = Required<Room>['id'];
export type RatePricePerGuestsNumber =
  Rate['prices'][number]['per_guests_number'][number];
export type RatePriceGuestNumber = RatePricePerGuestsNumber['guests_number'];
export type RatePriceValues = RatePricePerGuestsNumber['values'];
export type RateIsFirstEditRow = boolean;

export type FullWeekPriceKey =
  `${RequiredRoomId}_${RatePriceGuestNumber}_${RateIsFirstEditRow}`;

export interface FullWeekPricesByGuest {
  [key: FullWeekPriceKey]: {price: number};
}

export enum EPriceType {
  GENERAL = 1,
  PER_GUEST = 2,
  PER_DAY = 3,
  PER_DAY_PER_GUEST = 4,
}

export type PriceType = {
  label: string;
  value: EPriceType;
};

export type RateTableRoom = {
  room_id: number;
  guests_number: number;
  roomInformation: RoomsList[number] | undefined;
  isFirst: boolean;
  values: RatePriceValues;
};

export type IntegrationGuestPriceRoomType = {
  room_id: number;
  room_max_guests_number: number;
  max_guests_number?: number | null;
  roomName: string;
  value: number;
};

export enum EIntegrationAdditionalGuestPrice {
  CURRENCY = 2,
}

export enum EIntegrationAdditionalGuestPriceInputs {
  GUESTS = 'guests',
  PRICE = 'price',
}

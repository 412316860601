import styled from '@emotion/styled';
import {CheckIcon} from 'library/icons/check-icon';
import React from 'react';
import {FontKeys} from 'source/utilities/global-style';

const Wrapper = styled.div<{color?: string}>`
  color: ${({theme, color}) => color || theme.palette.fontPrimary};
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const Box = styled.div<{
  active: boolean;
  color?: string;
  rounded?: boolean;
  icon?: boolean;
}>`
  width: 18px;
  min-width: 18px;
  min-height: 18px;
  height: 18px;
  border: ${({theme, color}) =>
    `2px solid ${color || theme.palette.primaryAccent}`};
  background-color: ${({active, theme}) =>
    active ? theme.palette.primaryAccent : 'transparent'};
  pointer-events: none;
  border-radius: 2px;
  margin-right: ${({icon}) => icon && '10px'};
  display: flex;
  justify-content: center;
  align-items: center;
  content: ' ';
  border-radius: ${({rounded}) => rounded && '100%'};
`;

const MobileBox = styled.div<{active: boolean}>`
  position: relative;
  width: 40px;
  height: 21px;
  min-width: 40px;
  min-height: 21px;
  border-radius: 18px;
  background-color: ${({active, theme}) =>
    active ? theme.palette.primaryAccent : theme.palette.fontDefault};
  margin-right: 15px;
  transition: all 0.2s ease-in-out;
  content: ' ';
  &:before {
    margin-top: 1px;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #eee;
    content: ' ';

    transition: all 0.2s ease-in-out;
    ${({active}) =>
      active ? 'transform: translateX(120%);' : 'transform: translateX(0);'};
  }
`;

const BoxWrapper = styled.div<{rounded?: boolean}>`
  width: 18px;
  min-width: 18px;
  min-height: 18px;
  height: 18px;
  border: ${({theme, color}) =>
    `2px solid ${color || theme.palette.primaryAccent}`};
  pointer-events: none;
  border-radius: 2px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  content: ' ';
  border-radius: ${({rounded}) => rounded && '100%'};
`;

const StyledCheckBox = styled(CheckIcon)`
  width: 70%;
  height: 70%;
`;

export const Label = styled.label<{
  color?: string;
  font?: FontKeys;
  hide?: boolean;
  bold?: boolean;
}>`
  font-size: ${({theme, font = 'small'}) => theme.font[font]};
  color: ${({color, theme}) => color || theme.palette.fontDefault};
  ${({bold}) => bold && 'font-weight: 700'};
  ${({hide}) =>
    !!hide &&
    `
    visibility: hidden;
    content: ' ';
    min-height: 16px;
  `}
  cursor: pointer;
`;

type Props = {
  label?: string;
  color?: string;
  onChange?: (value: boolean) => void;
  value: boolean;
  isMobile?: boolean;
  className?: string;
  icon?: boolean;
  rounded?: boolean;
  style?: React.CSSProperties;
};

export const Checkbox: React.FC<Props> = ({
  label,
  onChange,
  value = false,
  color,
  className,
  isMobile,
  rounded,
  icon = true,
  style,
}) => {
  const clickHandler = () => {
    if (onChange) {
      onChange(!value);
    }
  };

  return (
    <Wrapper
      color={color}
      onClick={clickHandler}
      className={className}
      style={style}
    >
      {isMobile ? (
        <MobileBox active={value} />
      ) : icon ? (
        <Box icon={icon} rounded={rounded} color={color} active={value}>
          {value && <StyledCheckBox />}
        </Box>
      ) : (
        <BoxWrapper rounded={rounded}>
          <Box icon={icon} rounded={rounded} color={color} active={value} />
        </BoxWrapper>
      )}
      {label && <Label color={color}>{label}</Label>}
    </Wrapper>
  );
};

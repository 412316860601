import {store} from 'source/store';
import {UppyHotelFileType} from 'types/logic';
import {append, without} from 'ramda';
import {arrayMove} from '@dnd-kit/sortable';
import {removeUndefined} from 'source/utilities/object';
import {Dispatcher} from 'types/utilities';
import {setFiles, setPhotos, setSelectedPhotos, sortImages} from '../store';
import {changeHotelMainImage, deleteImage, reorderImages} from '../network';

export const transformHotelImages = (images: HotelImages): Images => {
  return images.map((image) => {
    return removeUndefined({
      ...image,
      relationId: image.hotel_id,
      hotel_id: undefined,
    });
  });
};

export const onImageUpload = (newImages: Images) => {
  const {images} = store.getState().photos;
  const result = new Set([...(images || []), ...newImages]);
  store.dispatch(setPhotos([...result]));
};

export const onFileDeleteComplete = (imageId?: number) => {
  const {images} = store.getState().photos;
  store.dispatch(
    setPhotos(images?.filter((image) => image.id !== imageId) || [])
  );
};

export const onFilesUpdate = (files?: UppyHotelFileType[]) => {
  store.dispatch(setFiles(files || []));
  store.dispatch(sortImages());
};

export const onSelectImage = (imageId: number) => {
  const {selectedImages} = store.getState().photos;
  store.dispatch(setSelectedPhotos(append(imageId, selectedImages || [])));
};

export const onRemoveImage = (imageId: number) => {
  const {selectedImages} = store.getState().photos;
  store.dispatch(setSelectedPhotos(without([imageId], selectedImages || [])));
};

export const onMoveImages = (
  images: Images,
  activeIndex: number,
  overIndex: number
) => {
  store.dispatch(setPhotos(arrayMove(images, activeIndex, overIndex)));
};

export const onSelect = (images: Images) => {
  store.dispatch(setSelectedPhotos(images?.map((image) => image.id) || []));
};

export const onUnselect = () => {
  store.dispatch(setSelectedPhotos([]));
};

export const isMainImage = (imageId: number, hotel?: Hotel | null) => {
  if (!hotel || !hotel.main_image?.id) {
    return false;
  }
  return imageId === hotel.main_image.id;
};

export const onDeleteSelectedComplete = () => {
  const {images, selectedImages} = store.getState().photos;
  store.dispatch(
    setPhotos(
      images?.filter((image) => !selectedImages?.includes(image.id)) || []
    )
  );
  store.dispatch(setSelectedPhotos([]));
};

export const onReorderImages = (
  activeId: number,
  preHotelImageId: number,
  images: Images,
  activeIndex: number,
  overIndex: number,
  hotel?: Hotel | null
) => {
  if (hotel) {
    reorderImages(hotel, activeId, preHotelImageId).then(() => {
      store.dispatch(setPhotos(arrayMove(images, activeIndex, overIndex)));
    });
  }
};

export const onImageDelete = (
  imageId: number,
  onComplete: () => void,
  setLoading: Dispatcher<boolean>,
  hotel?: Hotel | null
) => {
  if (hotel) {
    const {selectedImages} = store.getState().photos;
    deleteImage(hotel, imageId, onComplete, setLoading);
    store.dispatch(
      setSelectedPhotos(selectedImages?.filter((id) => id !== imageId) || [])
    );
  }
};

export const onMainImageChange = (
  imageId: number,
  onComplete: () => void,
  setHotel: HotelDispatch,
  hotel?: Hotel | null
) => {
  if (!hotel) {
    return;
  }
  changeHotelMainImage(hotel, imageId, onComplete, setHotel);
};

import {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith, when} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {throwRedirectException} from 'source/utilities/exceptions/network';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {isUnhandledException} from 'source/utilities/guards/exceptions';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {HotelsManageCalendarPartialUpdateRequest} from 'types/api-scheme';
import {isNumber} from 'source/utilities/guards/types';
import {buildSubmitCellsMapByRate} from './lib/helpers/ui';
import {parseCalendarData} from './lib/helpers/prices';
import {
  CalendarPageProperties,
  SubmitAvailabilityType,
  UpdatedCellType,
} from './lib/types';

export const getCalendarPageProperties: GetServerSideProps<
  CalendarPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestRoomsShorts = () =>
    api.rooms.getShorts({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  const requestRates = () =>
    api.rates.get({hotelId, headers}).catch(
      when(isUnhandledException, (error) => {
        console.error(error);
        return throwRedirectException(redirects.manage);
      }),
    );

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, roomsShorts, rates] = await Promise.all([
            requestHotel(),
            requestRoomsShorts(),
            requestRates(),
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            roomsShorts,
            rates,
          });
          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const fetchCalendarData = async (
  hotelId: number,
  roomId: number,
  from: string,
  to: string,
  onComplete: (calendarData?: ManageCalendar) => void,
) =>
  api.manageCalendar
    .get({hotelId, roomId, from, to})
    .then(onComplete)
    .catch(handleException);

export const fetchRoomAvailabilities = async (
  hotelId: number,
  roomId: number,
  from: string,
  to: string,
  onComplete: (roomAvailabilities?: RoomAvailabilities) => void,
) =>
  api.manageCalendar
    .getRoomAvailabilities({hotelId, roomId, from, to})
    .then(onComplete)
    .catch(handleException);

export const updateCalendar = async (
  hotelId: number,
  roomId: number,
  from: string,
  to: string,
  submitAvailabilities: SubmitAvailabilityType[],
  submitCells: UpdatedCellType[],
  onComplete: (data?: UpdateManageCalendar) => void,
  onFinally: () => void,
) => {
  const datePrices: UpdateManageCalendarPriceRequest = [];

  if (submitCells?.length > 0) {
    const submitCellsByRate = buildSubmitCellsMapByRate(submitCells);

    // Подгатавливаем данные к отправке на бэкенд
    Object.entries(submitCellsByRate).forEach(([rateId, prices]) => {
      datePrices.push({
        rate_id: Number(rateId),
        prices: prices.map((priceData) => ({
          guests_number: priceData.guestNumber,
          date: priceData.date,
          price: priceData.price,
        })),
      });
    });
  }

  const submitAvailabilitiesToDelete = submitAvailabilities
    .filter(({is_delete}) => is_delete)
    .map(({id}) => id)
    .filter(isNumber);

  const submitAvailabilitiesToAdd = submitAvailabilities.filter(
    ({is_delete}) => !is_delete,
  );

  const normalizedPayload: HotelsManageCalendarPartialUpdateRequest = {
    period: {
      from,
      to,
    },
    calendar_items: [
      {
        room_id: roomId,
        store: {
          date_prices: parseCalendarData(datePrices),
          room_constraints: parseCalendarData(submitAvailabilitiesToAdd),
        },
        delete: {
          room_constraint_ids: parseCalendarData(submitAvailabilitiesToDelete),
        },
      },
    ],
  };

  api.manageCalendar
    .save({hotelId, payload: normalizedPayload})
    .then(onComplete)
    .catch(handleException)
    .finally(onFinally);
};

export const fetchHotelRooms = async (
  hotelId: number,
  from: string,
  to: string,
  onComplete: (hotelRooms?: HotelRooms) => void,
) =>
  api.manageCalendar
    .getHotelRooms({hotelId, from, to})
    .then(onComplete)
    .catch(handleException);

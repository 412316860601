import {LegalFormEnum} from 'source/utilities/business';
import {
  IndividualEnterpreneurFields,
  JuridicalPersonFields,
  PhysicalPersonFields,
  SelfEmployedFields,
  VerificationPaymentStatus,
} from './type';

export const INITIAL_REQUISITES_ID = -1;

export const initialRequisites: Requisites = {
  id: INITIAL_REQUISITES_ID,
  type: {
    title: '',
    value: LegalFormEnum.IndividualEntrepreneur as Requisites['type']['value'],
  },
  name: '',
  account: '',
  inn: '',
  bik: '',
  address: '',
  kpp: '',
  ogrn: '',
  ogrnip: '',
  verification_payment: undefined,
};

// first page is loaded in getServerSideProps
export const DEFAULT_EXISTING_REQUISITES_PAGE = 2;

export const legalForms = [
  {
    value: LegalFormEnum.IndividualEntrepreneur,
    label: 'requisites:forms.entrepreneur',
  },
  {
    value: LegalFormEnum.JuridicalPerson,
    label: 'requisites:forms.juri',
  },
  {value: LegalFormEnum.Person, label: 'requisites:forms.person'},
  {
    value: LegalFormEnum.SelfEmployed,
    label: 'requisites:forms.self_employed',
  },
];

const individualEnterpreneurFields: IndividualEnterpreneurFields = [
  'name',
  'account',
  'address',
  'inn',
  'bik',
];
const juridicalPersonFields: JuridicalPersonFields = [
  'name',
  'account',
  'address',
  'inn',
  'bik',
  'kpp',
];
const physicalPersonFields: PhysicalPersonFields = [
  'name',
  'account',
  'address',
  'inn',
];
const selfEmployedFields: SelfEmployedFields = [
  'name',
  'account',
  'address',
  'inn',
  'bik',
];

export const requiredFields: {
  [key in LegalFormEnum]: string[];
} = {
  [LegalFormEnum.IndividualEntrepreneur]: individualEnterpreneurFields,
  [LegalFormEnum.JuridicalPerson]: juridicalPersonFields,
  [LegalFormEnum.Person]: physicalPersonFields,
  [LegalFormEnum.SelfEmployed]: selfEmployedFields,
};

export const personFields = [LegalFormEnum.Person, LegalFormEnum.SelfEmployed];

export const verificationPaymentInvalidStatuses = [
  VerificationPaymentStatus.Error,
  VerificationPaymentStatus.Canceled,
];

export const SHOW_INVOICE_ROLES = [
  LegalFormEnum.IndividualEntrepreneur,
  LegalFormEnum.JuridicalPerson,
];

import {request} from 'library/api';
import {path} from 'ramda';
import {DEFAULT_PAGE} from 'source/utilities/constants/logic';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsFromRawCreateResponse,
  HotelsMyListResponse,
  HotelsMyShortListResponse,
  RawHotelsListResponse,
} from 'types/api-scheme';

type GetHotelsContext = {
  page: string;
  headers?: Headers;
};
const getHotels = ({page, headers}: GetHotelsContext) =>
  request<HotelsMyListResponse>(url('hotels/my', {page: page || '1'}), {
    method: 'GET',
    headers: headers || clientHeaders(),
  }).then(path<HotelsMyListResponse>([1]));

type AddHotelContext = {
  payload: Partial<HotelFormdata>;
};
const addHotel = ({payload}: AddHotelContext) =>
  request<HotelsFromRawCreateResponse>(url('hotels/from/raw'), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
    credentials: 'include',
  }).then(path([1]));

type SearchHotelContext = {
  phrase: string;
};
const searchHotel = ({phrase = ''}: SearchHotelContext) =>
  request<RawHotelsListResponse>(url('raw/hotels', {phrase}), {
    headers: clientHeaders(),
  });

type SearchMyHotelsContext = {
  phrase: string;
  page?: number;
};

const searchMyHotels = ({
  phrase = '',
  page = DEFAULT_PAGE,
}: SearchMyHotelsContext) =>
  request<HotelsMyShortListResponse>(
    url('hotels/my/short', {phrase, page: String(page)}),
    {
      headers: clientHeaders(),
    },
  );

export const hotelsApi = {
  get: getHotels,
  add: addHotel,
  search: searchHotel,
  searchMyHotels,
};

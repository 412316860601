import styled from '@emotion/styled';
import {Text, TextSize} from 'library/components/text';
import React, {FC} from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
`;

const Radio = styled.button<{isActive: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid
    ${({theme, isActive}) =>
      isActive ? theme.palette.fontPrimary : theme.palette.fontSecondary};
  border-radius: 100px;
  background-color: white;

  &:disabled {
    border: 2px solid ${({theme}) => theme.palette.fontSecondary};
  }
`;

const RadioCircle = styled.div<{isActive: boolean}>`
  display: ${({isActive}) => (isActive ? 'block' : 'none')};
  min-width: 10px;
  min-height: 10px;
  background-color: ${({theme}) => theme.palette.fontPrimary};
  border-radius: 100px;

  &:disabled {
    background-color: ${({theme}) => theme.palette.fontSecondary};
  }
`;

const Label = styled(Text)`
  margin-left: 7px;
`;

interface Props {
  textSize: TextSize;
  label: string;
  value: boolean;
  style?: React.CSSProperties;
  className?: string;
}

export const RadioButton: FC<Props> = ({
  textSize,
  label,
  value,
  style,
  className,
}) => {
  return (
    <Wrapper style={style} className={className}>
      <Radio isActive={value}>
        <RadioCircle isActive={value} />
      </Radio>
      <Label size={textSize}>{label}</Label>
    </Wrapper>
  );
};

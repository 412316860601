import React, {useEffect, useState} from 'react';
import type {FC, ReactNode} from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import styled from '@emotion/styled';
import type {Active, DragEndEvent} from '@dnd-kit/core';
import {SortableContext, sortableKeyboardCoordinates} from '@dnd-kit/sortable';
import {SortableOverlay} from './sortable-overlay';

const SortableListWrapper = styled.ul`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  gap: var(--gap);
  --gap: 20px;
  --columns: 4;
  margin-top: 24px;
  list-style: none;

  @media (max-width: 990px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 690px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

interface Props {
  images: Images;
  onImageDragEnd: (event: DragEndEvent) => void;
  renderItem(image: Image): ReactNode;
}

export const SortableListMobile: FC<Props> = ({
  images,
  onImageDragEnd,
  renderItem,
}) => {
  const [active, setActive] = useState<Active | null>(null);
  const [activeItem, setActiveItem] = useState<Image | null>(null);

  useEffect(() => {
    if (images) {
      const image = images.find((image) => image.id === active?.id);
      setActiveItem(image || null);
    }
  }, [active, images]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({active}) => {
        setActive(active);
      }}
      onDragEnd={onImageDragEnd}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={images?.map((item: Image) => item.id)}>
        <SortableListWrapper role="application">
          {images
            ? images.map((image) => (
                <React.Fragment key={image.id}>
                  {renderItem(image)}
                </React.Fragment>
              ))
            : null}
        </SortableListWrapper>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
};

import styled from '@emotion/styled';
import {ButtonHTMLAttributes, FC, MouseEvent, useRef} from 'react';
import {CELL_WIDTH} from 'slices/calendar/lib/constants';

const TableCell = styled.button`
  width: ${CELL_WIDTH}px;
  height: 45px;
  padding: 12px 0px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.palette.fontDefault};
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.palette.border};
  border-radius: 20px;
  background-color: ${({theme}) => theme.palette.defaultBackground};
  transition: 200ms ease-in-out;
  cursor: pointer;
  font-family: ${({theme}) => theme.fontFamily.NOTO_SANS_MONO};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

type ButtonProps = {} & ButtonHTMLAttributes<HTMLButtonElement>;

export const TableButton: FC<ButtonProps> = ({
  onClick,
  onDoubleClick,
  ...attributes
}) => {
  const touchCount = useRef(0);

  const handleResetTouchCount = () => {
    touchCount.current = 0;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
      touchCount.current += 1;
    }
    if (touchCount.current === 2) {
      handleResetTouchCount();
      if (onDoubleClick) {
        onDoubleClick(event);
      }
    }
  };
  return (
    <TableCell
      onClick={handleClick}
      onMouseOut={() => handleResetTouchCount()}
      type="button"
      {...attributes}
    />
  );
};

import {Translate} from 'next-translate';
import {Theme} from '@emotion/react';
import {
  BOOKING_STATUS_COLORS,
  DEFAULT_SELECT_OPTION,
  HOTELIERS_VALUE,
} from 'slices/bookings/lib/constants';
import {BookingsParameters} from 'source/utilities/api/models/bookings';
import {transformDirectory} from 'source/utilities/directories';
import {removeUndefined} from 'source/utilities/object';
import {BookingsListParameters} from 'types/api-scheme';
import {DirectoryType, TranslateOptions} from 'types/utilities';
import {BOOKING_STATUSES} from './types';

export const getQueryObjectFromFilters = (
  filters: BookingsListParameters,
): {
  page: string;
  parameters: BookingsParameters;
} => {
  const parameters = removeUndefined<BookingsParameters>({
    main_id: filters.main_id?.toString() || undefined,
    hotel_id: filters.hotel_id?.toString() || undefined,
    last_name: filters.last_name || undefined,
    created_at_from: filters.created_at_from || undefined,
    created_at_to: filters.created_at_to || undefined,
    checkin_from: filters.checkin_from || undefined,
    checkin_to: filters.checkin_to || undefined,
    checkout_from: filters.checkout_from || undefined,
    checkout_to: filters.checkout_to || undefined,
    status: filters.status?.toString() || undefined,
    sort: filters.sort || undefined,
    sort_direction: filters.sort_direction || undefined,
    payment_type: filters.payment_type?.toString() || undefined,
  });
  return {
    page: filters.page.toString(),
    parameters,
  };
};

export const buildOptions = (options?: DirectoryType | null) => {
  if (!options || (options && options.length === 0)) {
    return [DEFAULT_SELECT_OPTION];
  }

  return [DEFAULT_SELECT_OPTION, ...transformDirectory(options)];
};

export const getTableHeader = (t: Translate, key: string) => {
  return t(`bookings:table.header.${key}`);
};
export const getTableBody = (
  t: Translate,
  key: string,
  options: TranslateOptions,
) => {
  return t(`bookings:table.body.${key}`, options);
};

export const getLabel = (t: Translate, key: string) => {
  return t(`label.${key}`);
};
export const getPlaceholder = (t: Translate, key: string) => {
  return t(`placeholder.${key}`);
};

export const parseProviderName = (title?: string, value?: string) => {
  if (value === HOTELIERS_VALUE) {
    return null;
  }
  return title;
};

export const getStatusIndicatorColor = (
  theme: Theme,
  status: Booking['status'],
): string => {
  if (status.value in BOOKING_STATUSES) {
    const statusValue = status.value as BOOKING_STATUSES;

    return BOOKING_STATUS_COLORS[statusValue](theme);
  }

  return theme.palette.danger;
};

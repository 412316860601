import styled from '@emotion/styled';
import DOMPurify from 'isomorphic-dompurify';
import {SectionTitle} from 'library/components/section-title';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useContext} from 'react';
import {selectModerationDescriptionList} from 'slices/general_info';
import {ModerationFieldStatus} from 'slices/moderation';
import {HotelContext} from 'source/context/hotel';
import {useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import ModerationRequest from './moderation-request';

const Wrapper = styled.div`
  margin-top: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const TitleDescription = styled.div`
  margin-top: 20px;
`;

const Subtitle = styled(SectionTitle)`
  margin-top: 40px;
`;

const HotelDescription = styled.div`
  padding: 15px 0px;
`;

export const Descriptions = () => {
  const [hotel] = useContext(HotelContext);
  const moderationDescriptionList = useAppSelector(
    selectModerationDescriptionList,
  );
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation();
  const moderationDescription = moderationDescriptionList
    ? moderationDescriptionList[0]
    : null;

  return (
    <Wrapper>
      <TitleWrapper>
        <Text size={mobile ? 'boldS' : 'boldM'}>
          {t('general-info:descriptions.title')}
        </Text>
      </TitleWrapper>
      <TitleDescription>
        <Text size="S">{t('general-info:descriptions.title_description')}</Text>
      </TitleDescription>
      <Subtitle gap={12}>
        <TitleWrapper>
          {t('general-info:descriptions.subtitle')}
          {moderationDescription ? (
            <ModerationFieldStatus moderationField={moderationDescription} />
          ) : null}
        </TitleWrapper>
      </Subtitle>
      <HotelDescription>
        {hotel?.description ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(hotel?.description),
            }}
          />
        ) : (
          <Text size="S">{t('components:no_description')}</Text>
        )}
      </HotelDescription>
      <ModerationRequest />
    </Wrapper>
  );
};

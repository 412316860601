import styled from '@emotion/styled';
import {NavigationLink} from 'library/components/navigation-link';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {FC, useCallback, useEffect, useState} from 'react';
import Popup from 'reactjs-popup';
import {IconName} from 'source/components/icon';
import HeaderButton from '../header-button';

const StyledPopup = styled(Popup)`
  &-content {
    width: fit-content !important;
    padding: 16px 20px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-shadow: ${({theme}) => theme.palette.boxShadow} !important;
    border-radius: 6px !important;
    box-sizing: border-box;
  }
`;

const Trigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const StyledLink = styled(NavigationLink)`
  width: 100%;
`;

interface DropdownProps {
  iconName: IconName;
  title: string;
  links: Anchor[];
}

const LinksDropdown: FC<DropdownProps> = ({iconName, title, links}) => {
  const [open, setOpen] = useState(false);
  const closeDropdown = useCallback(() => setOpen(false), []);
  const openDropdown = useCallback(() => setOpen(true), []);
  const {t} = useTranslation('routing');
  const router = useRouter();

  useEffect(() => {
    if (router) {
      router.events.on('routeChangeStart', closeDropdown);
    }
  }, [router, closeDropdown]);

  return (
    <StyledPopup
      trigger={
        <Trigger>
          <HeaderButton highlightText iconName={iconName}>
            {t(title)}
          </HeaderButton>
        </Trigger>
      }
      onOpen={openDropdown}
      onClose={closeDropdown}
      open={open}
      closeOnDocumentClick
      arrow={false}
      offsetY={20}
      offsetX={15}
      position="bottom left"
    >
      <LinksWrapper>
        {links.map((link) => (
          <StyledLink
            key={link.href}
            href={link.href}
            isLinkActive={link.isLinkActive}
          >
            {t(link.title)}
          </StyledLink>
        ))}
      </LinksWrapper>
    </StyledPopup>
  );
};

export default LinksDropdown;

import {Theme} from '@emotion/react';
import {WITHDRAWAL_STATUSES} from './types';
import {WITHDRAWAL_STATUS_COLORS} from './constants';

export const getStatusIndicatorColor = (
  theme: Theme,
  status: Withdrawal['status'],
): string => {
  if (status.value in WITHDRAWAL_STATUSES) {
    const statusValue = status.value as WITHDRAWAL_STATUSES;

    return WITHDRAWAL_STATUS_COLORS[statusValue](theme);
  }

  return theme.palette.fontDefault;
};


    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getLandingPageProperties as __Next_Translate_old_getServerSideProps__19483489fe6__ } from 'slices/landing';
import Image from 'next/image';
import styled from '@emotion/styled';
import { ButtonLink } from 'library/components/button-link';
import { Headline } from 'library/components/headline';
import { SeoProvider } from 'library/components/seo-provider';
import { Text } from 'library/components/text';
import type { NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import { Content } from 'source/components/content';
import { Icon } from 'source/components/icon';
import { Link } from 'source/components/link';
import { Main } from 'source/components/main';
import { Routes } from 'source/utilities/network/url';
import type { ApplicationProperties } from 'source/utilities/ui';
import { getBookingURLWithPath } from 'source/utilities/url';
import { dataTestId } from 'testing/data-testid';
import homeMobile from 'source/components/illustration/assets/home-mobile.webp';
const StyledContent = styled(Content) `
  flex-direction: column;
  padding: 3%;

  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;
const FirstSection = styled.section `
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 12px;
  }
`;
const Registration = styled.div `
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 12px;

  @media (max-width: 1200px) {
    align-items: center;
    width: 100%;
  }
`;
const StyledHeadline = styled(Headline) `
  @media (max-width: 1200px) {
    font-size: 26px;
    line-height: 34px;
  }
`;
const RegistrationText = styled(Text) `
  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 23px;
  }
`;
const RegistrationLink = styled(ButtonLink) `
  width: 336px;
  margin-top: 30px;

  @media (max-width: 480px) {
    width: 100%;
    margin-top: 30px;
  }
`;
const Mobile = styled.div `
  display: flex;
  align-items: center;
  width: 50%;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-top: 30px;
  }
`;
const Calendar = styled.div `
  display: flex;
  align-items: center;
  align-self: flex-end;

  @media (max-width: 1200px) {
    align-self: center;
  }
`;
const CalendarText = styled.div `
  margin-left: 25px;
`;
const Marker = styled(Link) `
  align-self: flex-start;

  @media (max-width: 1200px) {
    align-self: flex-end;
  }
`;
const ImageWrapper = styled.div `
  max-width: 453px;
  width: 100%;
`;
const HomeMobileImage = styled(Image) `
  background: transparent;
  max-width: 100%;
  height: auto;
`;
const SecondSection = styled.section `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 20px 0px;
  margin: 150px 0px;
  background-image: repeating-linear-gradient(
      -5deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 37px,
      #e6e6e6 37px
    ),
    repeating-linear-gradient(
      85deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 37px,
      #e6e6e6 37px
    ),
    repeating-linear-gradient(
      175deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 37px,
      #e6e6e6 37px
    ),
    repeating-linear-gradient(
      265deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 37px,
      #e6e6e6 37px
    );
  background-size:
    0 0,
    100% 1px,
    0 0,
    100% 1px;
  background-position:
    0 0,
    0 0,
    100% 0,
    0 100%;
  background-repeat: no-repeat;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    margin: 50px 0px;
  }
`;
const RatingCount = styled(Text) `
  font-size: 72px;
  line-height: 91px;

  @media (max-width: 1200px) {
    font-size: 44px;
    line-height: 34px;
  }
`;
const RatingText = styled(Text) `
  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 34px;
  }
`;
const ThirdSection = styled.section `
  display: flex;
  gap: 115px;
  margin-bottom: 150px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
  }
`;
const Rental = styled.div `
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 25px;

  @media (max-width: 1200px) {
    order: 2;
    width: 100%;
  }
`;
const RentalTitle = styled(Text) `
  @media (max-width: 1200px) {
    font-size: 26px;
    line-height: 34px;
  }
`;
const RentalLink = styled(ButtonLink) `
  width: 400px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;
const Support = styled.div `
  display: flex;
  gap: 50px;

  @media (max-width: 1200px) {
    order: 1;
    flex-direction: column;
    gap: 20px;
  }
`;
const SupportTitle = styled(Text) `
  margin: 30px 0px 8px;
`;
const FourthSection = styled.section `
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
const Steps = styled.div `
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 45%;

  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;
const StepsTitle = styled(Text) `
  @media (max-width: 1200px) {
    font-size: 26px;
    line-height: 34px;
  }
`;
const Step = styled.div `
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 1200px) {
    gap: 20px;
  }
`;
const StepNumber = styled(Text) `
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 44px;
  line-height: 55px;
  color: #ff5876;
  background-image: repeating-linear-gradient(
      -5deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 35px,
      #e6e6e6 35px
    ),
    repeating-linear-gradient(
      85deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 35px,
      #e6e6e6 35px
    ),
    repeating-linear-gradient(
      175deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 35px,
      #e6e6e6 35px
    ),
    repeating-linear-gradient(
      265deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 35px,
      #e6e6e6 35px
    );
  background-size:
    1px 100%,
    100% 1px,
    1px 100%,
    100% 1px;
  background-position:
    0 0,
    0 0,
    100% 0,
    0 100%;
  background-repeat: no-repeat;
`;
const StepText = styled(Text) `
  flex: 1 1 auto;

  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 23px;
  }
`;
const StepTextLink = styled(Link) `
  font-size: 20px;
  color: #ff5876;
  text-decoration: underline;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;
const Questions = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
  padding: 100px;
  background-image: repeating-linear-gradient(
      -5deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 37px,
      #e6e6e6 37px
    ),
    repeating-linear-gradient(
      85deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 37px,
      #e6e6e6 37px
    ),
    repeating-linear-gradient(
      175deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 37px,
      #e6e6e6 37px
    ),
    repeating-linear-gradient(
      265deg,
      #e6e6e6,
      #e6e6e6 21px,
      transparent 21px,
      transparent 37px,
      #e6e6e6 37px
    );
  background-size:
    1px 100%,
    100% 1px,
    1px 100%,
    100% 1px;
  background-position:
    0 0,
    0 0,
    100% 0,
    0 100%;
  background-repeat: no-repeat;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 30px 0px;
    background-size:
      0 0,
      100% 1px,
      0 0,
      100% 1px;
  }
`;
const QuestionsTitle = styled(Text) `
  @media (max-width: 1200px) {
    font-size: 26px;
    line-height: 33px;
  }
`;
const SupportLink = styled(ButtonLink) `
  width: 203px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;
export interface LandingPageProperties extends ApplicationProperties {
}
const LandingPage: NextPage<LandingPageProperties> = () => {
    const { t } = useTranslation('index');
    return (<SeoProvider config={{
            title: t('seo.title'),
            description: t('seo.description'),
            keywords: t('seo.keywords'),
            author: t('seo.author'),
        }}>
      <Main>
        <StyledContent>
          <FirstSection>
            <Registration>
              <StyledHeadline data-testid={dataTestId.home.title} level="XXL">
                {t('title')}
              </StyledHeadline>
              <Icon name="home-dashed-airplane" width="100%" height={30}/>
              <RegistrationText size="L">{t('subtitle')}</RegistrationText>
              <RegistrationLink href={Routes.registration} variant="primary">
                {t('register')}
              </RegistrationLink>
            </Registration>
            <Mobile>
              <Calendar>
                <Icon name="home-calendar" width={57} height={57}/>
                <CalendarText>
                  <Text size="boldM">{t('bookings_1')}</Text>
                  <Text size="S" color="#9292aa">
                    {t('bookings_2')}
                  </Text>
                </CalendarText>
              </Calendar>
              <ImageWrapper>
                <HomeMobileImage src={homeMobile} alt={t('title')}/>
              </ImageWrapper>
              <Marker href={Routes.registration}>
                <Icon name="home-marker" width={57} height={57}/>
              </Marker>
            </Mobile>
          </FirstSection>
          <SecondSection>
            <div>
              <RatingCount size="boldL" color="#ff5876">
                {t('market_1')}
              </RatingCount>
              <RatingText size="M">{t('market_2')}</RatingText>
            </div>
            <div>
              <RatingCount size="boldL" color="#ff5876">
                {t('tourists_1')}
              </RatingCount>
              <RatingText size="M">{t('tourists_2')}</RatingText>
            </div>
            <div>
              <RatingCount size="boldL" color="#ff5876">
                {t('hotels_1')}
              </RatingCount>
              <RatingText size="M">{t('hotels_2')}</RatingText>
            </div>
          </SecondSection>
          <ThirdSection>
            <Rental>
              <RentalTitle size="boldXL">{t('rent_with_us')}</RentalTitle>
              <RentalLink href={Routes.registration} variant="primary">
                {t('rent_with_us_link')}
              </RentalLink>
            </Rental>
            <Support>
              <div>
                <Icon name="home-support" width={80} height={80}/>
                <SupportTitle size="boldM">{t('support_title')}</SupportTitle>
                <Text size="S">{t('support_text')}</Text>
              </div>
              <div>
                <Icon name="home-graphics" width={80} height={80}/>
                <SupportTitle size="boldM">{t('sales_title')}</SupportTitle>
                <Text size="S">{t('sales_text')}</Text>
              </div>
              <div>
                <Icon name="home-settings" width={80} height={80}/>
                <SupportTitle size="boldM">{t('settings_title')}</SupportTitle>
                <Text size="S">{t('settings_text')}</Text>
              </div>
            </Support>
          </ThirdSection>
          <FourthSection>
            <Steps>
              <Icon name="home-airplane" width={95} height={45}/>
              <StepsTitle size="boldXXL">{t('how_to_start')}</StepsTitle>
              <Step>
                <StepNumber size="boldXXL" color="#ff5876">
                  1
                </StepNumber>
                <StepText size="boldM">
                  <StepTextLink href={Routes.registration}>
                    {t('step_1_link')}
                  </StepTextLink>{' '}
                  {t('step_1_text')}
                </StepText>
              </Step>
              <Step>
                <StepNumber size="boldXXL" color="#ff5876">
                  2
                </StepNumber>
                <StepText size="boldM">{t('step_2_text')}</StepText>
              </Step>
              <Step>
                <StepNumber size="boldXXL" color="#ff5876">
                  3
                </StepNumber>
                <StepText size="boldM">{t('step_3_text')}</StepText>
              </Step>
            </Steps>
            <Questions>
              <Icon name="home-question" width={80} height={80}/>
              <QuestionsTitle size="boldXXL">{t('questions')}</QuestionsTitle>
              <Text size="boldM">{t('write_us')}</Text>
              <SupportLink href={getBookingURLWithPath('/support')} variant="blue">
                {t('write_us_action')}
              </SupportLink>
            </Questions>
          </FourthSection>
        </StyledContent>
      </Main>
    </SeoProvider>);
};
export default LandingPage;
export {} from 'slices/landing';

    async function __Next_Translate__getServerSideProps__19483489fe6__(ctx) {
      const res = await __Next_Translate_old_getServerSideProps__19483489fe6__(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19483489fe6__ as getServerSideProps }
  
import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsRoomsCreateResponse,
  HotelsRoomsDeleteResponse,
  HotelsRoomsDetail2Response,
} from 'types/api-scheme';

type GetRoomContext = {
  hotelId: number;
  roomId: number;
  headers?: HeadersInit;
};
const getRoom = ({hotelId, roomId, headers}: GetRoomContext) =>
  request<HotelsRoomsDetail2Response>(
    url(`hotels/${hotelId}/rooms/${roomId}`),
    {method: 'GET', headers},
  ).then(path<HotelsRoomsDetail2Response['data']>([1, 'data']));

type EditRoomContext = {
  hotelId: number;
  roomId: number;
  payload: Partial<Room>;
};
const editRoom = ({hotelId, roomId, payload}: EditRoomContext) =>
  request<HotelsRoomsCreateResponse>(url(`hotels/${hotelId}/rooms/${roomId}`), {
    method: 'PUT',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsRoomsDetail2Response['data']>([1, 'data']));

type CreateRoomContext = {
  hotelId: number;
  payload: Room;
};
const createRoom = ({hotelId, payload}: CreateRoomContext) =>
  request<HotelsRoomsCreateResponse>(url(`hotels/${hotelId}/rooms`), {
    method: 'POST',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  });

type DeleteRoomContext = {
  hotelId: number;
  roomId: number;
  headers?: HeadersInit;
};
const deleteRoom = ({hotelId, roomId, headers}: DeleteRoomContext) =>
  request<HotelsRoomsDeleteResponse>(url(`hotels/${hotelId}/rooms/${roomId}`), {
    headers,
    method: 'DELETE',
  }).then(path<HotelsRoomsDeleteResponse['data']>([1, 'data']));

export const roomApi = {
  get: getRoom,
  edit: editRoom,
  create: createRoom,
  delete: deleteRoom,
};

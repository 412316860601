export {getRatePageProperties, handleSaveRates} from './network';
export {default as RateContent} from './content';
export {
  default as rateSlice,
  selectRate,
  setIsNeedSaving,
  setRate,
} from './store';
export {PRICE_TYPE_OPTIONS} from './lib/constants';
export {
  type RatePageProperties,
  ECancellationRateType,
  EPriceType,
  EIntegrationAdditionalGuestPrice,
  type RateHotelService,
} from './lib/types';
export {buildPrices} from './lib/helpers';

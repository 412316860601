export const CrossIcon: React.FC<IconProps> = ({style, className, color}) => (
  <svg
    style={style}
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9688 12.9608L7.00746 6.99951M6.9649 7.03827L1.00361 12.9996"
      stroke={color || '#FF5876'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12.9688 1.03822L7.00746 6.99951M6.9649 6.96075L1.00361 0.999468"
      stroke={color || '#FF5876'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

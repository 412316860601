import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Option} from 'library/components/select';
import {getMaxInputValueByUnitType} from 'slices/rate/lib/constants';
import {RootState} from 'source/store';
import {removeNil} from 'source/utilities/object';
import {RATE_INITIAL_STATE} from './lib/mocks';
import {
  ECancellationRateType,
  EUnitConditionsType,
  EUnitRateType,
  IRateState,
  RateConstraintExcludeRooms,
  RateConstraintsStay,
  RateHotelServices,
} from './lib/types';

const initialState: IRateState = {
  rate: {...RATE_INITIAL_STATE},
  rateSaving: false,
  rateActivating: false,
  savingPaymentMethods: false,
  isNeedSaving: false,
  paidServices: [],
};

export const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    /** Изменение состояния загрузки сохранения данных на бэк * */
    setRateSaving: (state, action: PayloadAction<IRateState['rateSaving']>) => {
      state.rateSaving = action.payload;
    },
    setSavingPaymentMethods: (
      state,
      action: PayloadAction<IRateState['savingPaymentMethods']>,
    ) => {
      state.savingPaymentMethods = action.payload;
    },
    setRateActivating: (
      state,
      action: PayloadAction<IRateState['rateActivating']>,
    ) => {
      state.rateActivating = action.payload;
    },
    /** Устанавливает список платных услуг  * */
    setPaidServices: (
      state,
      action: PayloadAction<IRateState['paidServices']>,
    ) => {
      state.paidServices = action.payload;
    },
    /** Состояние нужно ли сохранение * */
    setIsNeedSaving: (
      state,
      action: PayloadAction<IRateState['isNeedSaving']>,
    ) => {
      state.isNeedSaving = action.payload;
    },
    /** Устанавливаем данные объекта, полученных с сервера * */
    setRate: (state, action: PayloadAction<IRateState['rate']>) => {
      state.rate = {...RATE_INITIAL_STATE, ...removeNil(action.payload)};
    },
    /** Изменяет данные для таблицы, в которой хранятся номера, сумма, кол-во человек * */
    setRatePrice: (
      state,
      action: PayloadAction<IRateState['rate']['prices']>,
    ) => {
      const prices = action.payload;
      if (prices) {
        state.rate.prices = prices;
      }
    },
    /** Устанавливает тип условия отмены * */
    setCancellationType: (
      state,
      action: PayloadAction<ECancellationRateType>,
    ) => {
      const {rate} = state;
      const fine = rate.cancellation_fine;

      const cancellation_type = {
        title: rate.cancellation_type?.title || '',
        value: action.payload,
      };

      if (
        cancellation_type.value === ECancellationRateType.FlexibleRules &&
        fine
      ) {
        fine.type = EUnitConditionsType.OneNight;
      }

      state.rate = {
        ...rate,
        cancellation_type,
      };
    },
    /** Устанавливает тип оплаты для отмены * */
    setCancellationUnitType: (state, action: PayloadAction<Option>) => {
      const {rate} = state;
      const fine = rate.cancellation_fine;
      if (fine) {
        fine.unit_type = {
          value: action.payload.value,
          title: action.payload.label,
        };
        fine.value =
          getMaxInputValueByUnitType[action.payload.value as EUnitRateType];
      }
    },
    setConditionType: (state, action: PayloadAction<EUnitConditionsType>) => {
      const {rate} = state;
      const fine = rate.cancellation_fine;
      if (fine) {
        fine.type = action.payload;
        if (fine.type === EUnitConditionsType.Other) {
          fine.value = getMaxInputValueByUnitType[EUnitRateType.Currency];
        }
      }
    },
    /** Устанавливает услуги включенные в тариф  * */
    setRateServices: (state, action: PayloadAction<RateHotelServices>) => {
      state.rate.services = action.payload;
    },
    /** Устанавливает сумму, если требуется ( проценты, либо сумма ) * */
    setCancellationValue: (state, action: PayloadAction<number>) => {
      const fine = state.rate.cancellation_fine;
      if (fine) {
        const maxWritingValue =
          getMaxInputValueByUnitType[fine.unit_type?.value as EUnitRateType];
        const newValue = action.payload;
        if (newValue <= maxWritingValue) {
          fine.value = action.payload;
        }
      }
    },
    /** Устанавливает количество дней, за которые наступает штраф * */
    setCancellationDays: (state, action: PayloadAction<number>) => {
      const fine = state.rate.cancellation_fine;
      if (fine) {
        fine.days = action.payload;
      }
    },
    /** Устанавливает имя тарифа * */
    setRateName: (state, action: PayloadAction<string>) => {
      state.rate.name = action.payload;
    },
    /** Устанавливает сумму размера предоаты * */
    setRequiredPrepaymentAmount: (state, action: PayloadAction<number>) => {
      if (state.rate.payment_settings) {
        state.rate.payment_settings.required_prepayment_amount = action.payload;
      }
    },
    setRequiredPrepaymentUnitType: (state, action: PayloadAction<Option>) => {
      if (state.rate.payment_settings) {
        state.rate.payment_settings.required_prepayment_unit_type = {
          value: action.payload.value,
          title: action.payload.label,
        };
      }
    },
    /** Устанавливает нужна ли банковская карта для способа оплаты * */
    setRequiredCardWarranty: (state, action: PayloadAction<boolean>) => {
      if (state.rate.payment_settings) {
        state.rate.payment_settings.required_card_warranty = action.payload;
      }
    },
    setRateConstrainsStay: (
      state,
      action: PayloadAction<RateConstraintsStay | null>,
    ) => {
      const stay = action.payload;
      const {constraints} = state.rate;
      if (constraints && stay) {
        constraints.stay = stay;
      }
    },
    setConstraintExcludeRooms: (
      state,
      action: PayloadAction<RateConstraintExcludeRooms>,
    ) => {
      state.rate.constraint_exclude_rooms = action.payload;
    },
    setRoomIds: (state, action: PayloadAction<Rate['room_ids']>) => {
      state.rate.room_ids = action.payload;
    },
    setRateIntegrationGuestPrices: (
      state,
      action: PayloadAction<IntegrationGuestPriceRoom[]>,
    ) => {
      state.rate.extra_guests_prices = action.payload;
    },
    setRatePeriod: (state, action: PayloadAction<Rate['period']>) => {
      state.rate.period = action.payload;
    },
  },
});

export const selectRate = (state: RootState) => {
  return {
    rate: state.rate.rate,
    rooms: state.rooms.data,
    paidServices: state.rate.paidServices,
    rateSaving: state.rate.rateSaving,
    rateActivating: state.rate.rateActivating,
    savingPaymentMethods: state.rate.savingPaymentMethods,
    isNeedSaving: state.rate.isNeedSaving,
  };
};

export const {
  reset,
  setRate,
  setRatePrice,
  setRateActivating,
  setRateSaving,
  setIsNeedSaving,
  setCancellationType,
  setSavingPaymentMethods,
  setConditionType,
  setCancellationUnitType,
  setCancellationValue,
  setCancellationDays,
  setRateName,
  setRequiredPrepaymentAmount,
  setRequiredPrepaymentUnitType,
  setRequiredCardWarranty,
  setRateConstrainsStay,
  setConstraintExcludeRooms,
  setRateServices,
  setPaidServices,
  setRoomIds,
  setRatePeriod,
  setRateIntegrationGuestPrices,
} = rateSlice.actions;

export default rateSlice.reducer;

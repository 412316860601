import styled from '@emotion/styled';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useEffect, useState} from 'react';
import {
  selectPolicies,
  setIsNeedSaving,
  setRegistrationFeeForeignCitizens,
} from 'slices/policies/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const InputsWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({isMobile}) => (isMobile ? '100%' : '440px')}; ;
`;

const InputContainer = styled.div<{isMobile: boolean}>`
  width: 100%;
  margin: ${({isMobile}) =>
    isMobile ? '0px 0px 20px 0px' : '0px 20px 0px 0px'};
`;

const SwitchInOutAroundClock = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 14px;
`;

const SwitchLabel = styled(Text)`
  background: !important;
  margin-right: 18px;
`;

const Label = styled(LabeledComponent)``;

const AlienRegistration = () => {
  const dispatch = useAppDispatch();
  const {policies, isNeedSaving} = useAppSelector(selectPolicies);
  const {registration_fee_foreign_citizens} = policies;
  const {t} = useTranslation('policies');

  const mobile = useDeviceDetection('mobile');

  const [isRegistrationFeeForeign, setIsRegistrationFeeForeign] =
    useState<boolean>((registration_fee_foreign_citizens ?? 0) > 0);

  useEffect(() => {
    if (!isNeedSaving) {
      setIsRegistrationFeeForeign((registration_fee_foreign_citizens ?? 0) > 0);
      dispatch(setIsNeedSaving(false));
    }
  }, [isNeedSaving]);

  return (
    <InputsWrapper isMobile={mobile}>
      <InputContainer isMobile={mobile}>
        <SwitchInOutAroundClock>
          <SwitchLabel size="M">
            {t('alien-registration.charge-registration')}
          </SwitchLabel>
          <Switch
            initialState={isRegistrationFeeForeign}
            isActive={isRegistrationFeeForeign}
            onSwitch={async (enabled) => {
              setIsRegistrationFeeForeign(enabled);
              if (!enabled) {
                dispatch(setRegistrationFeeForeignCitizens(0));
              }
            }}
          />
        </SwitchInOutAroundClock>
      </InputContainer>
      {isRegistrationFeeForeign && (
        <InputContainer isMobile={mobile}>
          <Label
            size="S"
            gap={11}
            color={theme.palette.fontSecondary}
            text={t('alien-registration.board-size')}
          >
            <Input
              required
              value={
                (registration_fee_foreign_citizens ?? 0) <= 0
                  ? ''
                  : String(registration_fee_foreign_citizens)
              }
              onChange={({target}) =>
                dispatch(
                  setRegistrationFeeForeignCitizens(Number(target.value))
                )
              }
              type="number"
              placeholder={t('input-common.amount', {
                currency: t('common:units_payments.rub'),
              })}
            />
          </Label>
        </InputContainer>
      )}
    </InputsWrapper>
  );
};

export default AlienRegistration;

import styled from '@emotion/styled';
import {BackButton} from 'library/components/back-button';
import {Button} from 'library/components/button';
import useTranslation from 'next-translate/useTranslation';
import {FC, useState} from 'react';
import {loadBookings} from 'slices/bookings/network';
import {selectBookings, setBookings, setPage} from 'slices/bookings/store';
import {BookingsTable} from 'slices/bookings/ui/bookings-table';
import {Filters} from 'slices/bookings/ui/filters';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {BookingsListParameters} from 'types/api-scheme';

const Wrapper = styled.div``;
const Back = styled(BackButton)`
  margin-bottom: 20px;
`;
const Return = styled(Button)`
  margin-top: 20px;
  width: 100%;
  text-transform: uppercase;
`;

interface Props {}

const Content: FC<Props> = () => {
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation('bookings');
  const bookings = useAppSelector(selectBookings);
  const [mobileBookingsTableShow, setMobileBookingsTableShow] = useState(false);
  const dispatch = useAppDispatch();

  const handleSearch = async (
    page: number,
    filters: BookingsListParameters,
  ) => {
    dispatch(setPage(page));
    await loadBookings(page, filters, ({data, meta}) => {
      if (bookings && data && meta) {
        dispatch(
          setBookings(
            page > 1 ? {data: [...bookings.data, ...data], meta} : {data, meta},
          ),
        );
      }
    });
  };

  if (mobile && mobileBookingsTableShow) {
    return (
      <Wrapper>
        <Back action={() => setMobileBookingsTableShow(false)} />
        <BookingsTable onBookingsSearch={handleSearch} />
        <Return
          onClick={() => setMobileBookingsTableShow(false)}
          buttonType="secondary"
        >
          {t('back')}
        </Return>
      </Wrapper>
    );
  }

  if (mobile && !mobileBookingsTableShow) {
    return (
      <Wrapper>
        <Filters
          onBookingsSearch={async (page, filters, redirect) => {
            if (redirect) {
              await handleSearch(page, filters);
              setMobileBookingsTableShow(true);
            }
          }}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Filters onBookingsSearch={handleSearch} />
      <BookingsTable onBookingsSearch={handleSearch} />
    </Wrapper>
  );
};

export default Content;

// Алгоритм проверки контрольного числа
// 10-значный ИНН
// Вычислить сумму произведений цифр ИНН (с 1-й по 9-ю) на следующие коэффициенты — 2, 4, 10, 3, 5, 9, 4, 6, 8.
// Вычислить остаток от деления полученной суммы на 11.
// Сравнить младший разряд полученного остатка от деления с младшим разрядом ИНН. Если они равны, то ИНН верный.
// 12-значный ИНН
// Вычислить 1-ю контрольную цифру:
// Вычислить сумму произведений цифр ИНН (с 1-й по 10-ю) на следующие коэффициенты — 7, 2, 4, 10, 3, 5, 9, 4, 6, 8.
// Вычислить младший разряд остатка от деления полученной суммы на 11.
// Вычислить 2-ю контрольную цифру:
// Вычислить сумму произведений цифр ИНН (с 1-й по 11-ю) на следующие коэффициенты — 3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8.
// Сравнить 1-ю контрольную цифру с 11-й цифрой ИНН и сравнить 2-ю контрольную цифру с 12-й цифрой ИНН. Если они равны, то ИНН верный.
/** link to this coefficients magic: @see http://www.kholenkov.ru/data-validation/inn/  */

const calculateCheckSum = (inn: string, coefficients: number[]): number => {
  return (
    (coefficients.reduce((checkSum, coefficient, index) => {
      return checkSum + coefficient * Number.parseInt(inn[index], 10);
    }, 0) %
      11) %
    10
  );
};

const validateInnCheckSum = (inn: string) => {
  const innLength = inn.length;

  switch (innLength) {
    case 10: {
      const weightsForTenDigits = [2, 4, 10, 3, 5, 9, 4, 6, 8];
      return calculateCheckSum(inn, weightsForTenDigits) === Number(inn[9]);
    }
    case 12: {
      const weightsForTwelveDigitsFirst = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
      const weightsForTwelveDigitsSecond = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
      return (
        calculateCheckSum(inn, weightsForTwelveDigitsFirst) ===
          Number(inn[10]) &&
        calculateCheckSum(inn, weightsForTwelveDigitsSecond) === Number(inn[11])
      );
    }
    default: {
      return false;
    }
  }
};

export const isValidINN = (value?: string | null) => {
  if (!value || /\D/.test(value)) {
    // checking for non-digit characters
    return false;
  }

  const {length} = value;

  if (![10, 12].includes(length)) {
    // INN can be only 10 and 12 length long
    return false;
  }

  if (!validateInnCheckSum(value)) {
    // invalid checksum
    return false;
  }

  return true;
};

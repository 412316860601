import styled from '@emotion/styled';
import getT from 'next-translate/getT';
import Router from 'next/router';
import {Link} from 'source/components/link';
import {Text} from '../text';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const LinkText = styled(Text)`
  color: ${({theme}) => theme.palette.primaryAccent};
  text-decoration: underline;
`;

interface MessageProps {
  message: string;
  link: string;
}

export const ToastMessageWithLink = async ({message, link}: MessageProps) => {
  const {locale} = Router;
  const linkText = await getT(locale, 'components').then((t) =>
    t('go_to_link')
  );
  return (
    <Wrapper>
      <Text size="boldM">{message}</Text>
      <Link href={link}>
        <LinkText size="boldS">{linkText}</LinkText>
      </Link>
    </Wrapper>
  );
};

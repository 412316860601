import styled from '@emotion/styled';
import {css, useTheme} from '@emotion/react';
import {FC, TextareaHTMLAttributes} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {Text} from '../text';
import {TextButton} from '../text-button';

const Wrapper = styled.div<{
  noPadding?: boolean;
  noBorder?: boolean;
  maxWidth?: boolean;
}>`
  position: relative;
  padding: ${({noPadding}) => (noPadding ? '0px' : '20px')};
  border: ${({theme, noBorder}) =>
    noBorder ? 'none' : `1px solid ${theme.palette.border}`};
  border-radius: 6px;

  &:focus-within {
    border-color: ${({theme}) => theme.palette.primaryAccent};
  }

  ${({maxWidth}) =>
    maxWidth &&
    css`
      width: 100%;
    `}
`;

const StyledArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  cursor: initial;
  resize: none;

  &:disabled {
    background: none;
  }
`;

const Counter = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
  position: absolute;
  bottom: 3px;
  right: 13px;
`;

const ClearButtonWrapper = styled.div`
  position: absolute;
  bottom: 3px;
  left: 13px;
`;

type Props = {
  value: string;
  maxCount?: number;
  onClear?: () => void;
  noPadding?: boolean;
  noBorder?: boolean;
  maxWidth?: boolean;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea: FC<Props> = ({
  value,
  maxCount,
  onClear,
  noPadding,
  noBorder,
  maxWidth,
  ...properties
}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <Wrapper noPadding={noPadding} noBorder={noBorder} maxWidth={maxWidth}>
      <StyledArea value={value} {...properties} />
      {maxCount ? (
        <Counter size="XS">
          {value?.length}/{maxCount}
        </Counter>
      ) : null}
      {onClear ? (
        <ClearButtonWrapper>
          <TextButton onClick={onClear} disabled={value.length === 0}>
            <Text size="S" color={theme.palette.primaryAccent}>
              {t('components:clear')}
            </Text>
          </TextButton>
        </ClearButtonWrapper>
      ) : null}
    </Wrapper>
  );
};

import {useAppDispatch, useAppSelector} from 'source/store';
import {
  selectWithdrawals,
  selectOrganization,
  setLoading,
  setWithdrawals,
  setOrganization,
  selectPage,
} from 'slices/balance_details/store';
import {useEffect, useRef} from 'react';
import CLongPolling from 'source/utilities/long-polling';
import {WITHDRAWAL_STATUSES} from 'slices/balance_details/lib/types';
import {pollingBalanceDetails} from 'slices/balance_details/network';

export const BalanceDetailsPolling = () => {
  const longPollingReference = useRef<CLongPolling | null>(null);

  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectOrganization);
  const withdrawals = useAppSelector(selectWithdrawals);

  const hasCalculatingWithdrawals = withdrawals.find(
    (withdrawal) => withdrawal.status.value === WITHDRAWAL_STATUSES.CALCULATING,
  );

  const page = useAppSelector(selectPage);

  const handleSetLoading = () => {
    dispatch(setLoading(true));
  };

  useEffect(() => {
    // Если есть операции со статусом CALCULATING, обновляет баланс организации и список операций каждые 5 секунд
    const LongPolling = longPollingReference.current;

    if (hasCalculatingWithdrawals && !LongPolling && organization) {
      longPollingReference.current = pollingBalanceDetails(
        page,
        organization.id,
        handleSetLoading,
        (organization, withdrawals) => {
          dispatch(setOrganization(organization));
          dispatch(setWithdrawals(withdrawals.items));
          dispatch(setLoading(false));
        },
      );

      longPollingReference.current?.start();
    }

    return () => {
      const LongPolling = longPollingReference.current;

      if (LongPolling) {
        LongPolling.stop();
        LongPolling.subscribe?.unsubscribe();
        longPollingReference.current = null;
      }
    };
  }, [hasCalculatingWithdrawals, page, organization]);

  return null;
};

import {range} from 'ramda';
import {FC} from 'react';

import {useAppSelector} from 'source/store';

import {selectRoomDescription} from '../store';
import {Room} from './room';

interface IProps {
  bedTypes?: BedTypes;
  translateKey: string;
}

export const RoomList: FC<IProps> = ({bedTypes, translateKey}) => {
  const {bedRooms} = useAppSelector(selectRoomDescription);

  return (
    <div>
      {range(1, bedRooms + 1).map((index) => (
        <Room
          bedTypes={bedTypes}
          key={index}
          index={index - 1}
          translateKey={translateKey}
        />
      ))}
    </div>
  );
};

import {all, equals, isEmpty, map} from 'ramda';

/**
 * Получает аргументы через запятую, и проверяет их на пустоту
 * @param properties - параметры для проверки на пустоту
 * @example
 * isFilledProperties([1, 2, 3]);           //=> true
 * isFilledProperties([]);                  //=> false
 * isFilledProperties('');                  //=> false
 * isFilledProperties(null);                //=> true
 * isFilledProperties({});                  //=> false
 * isFilledProperties({length: 0});         //=> true
 * isFilledProperties(Uint8Array.from([])); //=> false
 */
export const isFilledProperties = (...properties: unknown[]) =>
  all(
    equals(true),
    map((key) => !isEmpty(key), [...properties])
  );

import {useTheme} from '@emotion/react';
import {FC} from 'react';

export const ExclamationMarkIcon: FC<IconProps> = ({
  style,
  className,
  color,
}) => {
  const theme = useTheme();
  return (
    <svg
      style={style}
      className={className}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.5"
        cy="10.5"
        r="9.395"
        stroke={color || theme.palette.danger}
        strokeWidth="2.21"
      />
      <path
        d="M10.842 14.8515C10.6553 15.0382 10.4267 15.1315 10.156 15.1315C9.88533 15.1315 9.652 15.0382 9.456 14.8515C9.26933 14.6555 9.176 14.4222 9.176 14.1515C9.176 13.8809 9.26933 13.6475 9.456 13.4515C9.652 13.2555 9.88533 13.1575 10.156 13.1575C10.4267 13.1575 10.6553 13.2555 10.842 13.4515C11.038 13.6475 11.136 13.8809 11.136 14.1515C11.136 14.4222 11.038 14.6555 10.842 14.8515ZM10.842 11.8695H9.484L9.176 5.21953H11.164L10.842 11.8695Z"
        fill={color || theme.palette.danger}
      />
      <path
        d="M10.842 14.8515L10.5379 14.5475L10.842 14.8515ZM9.456 14.8515L9.14462 15.1481L9.15185 15.1557L9.15945 15.1629L9.456 14.8515ZM9.456 13.4515L9.15185 13.1474L9.14462 13.155L9.456 13.4515ZM10.842 13.4515L10.5305 13.7482L10.5379 13.7556L10.842 13.4515ZM10.842 11.8695V12.2995H11.2517L11.2715 11.8903L10.842 11.8695ZM9.484 11.8695L9.05446 11.8894L9.07345 12.2995H9.484V11.8695ZM9.176 5.21953V4.78953H8.72562L8.74646 5.23942L9.176 5.21953ZM11.164 5.21953L11.5935 5.24033L11.6153 4.78953H11.164V5.21953ZM10.5379 14.5475C10.4342 14.6512 10.3159 14.7015 10.156 14.7015V15.5615C10.5375 15.5615 10.8765 15.4252 11.1461 15.1556L10.5379 14.5475ZM10.156 14.7015C9.99575 14.7015 9.8686 14.6507 9.75255 14.5402L9.15945 15.1629C9.4354 15.4257 9.77492 15.5615 10.156 15.5615V14.7015ZM9.76738 14.555C9.65686 14.4389 9.606 14.3118 9.606 14.1515H8.746C8.746 14.5326 8.88181 14.8721 9.14462 15.1481L9.76738 14.555ZM9.606 14.1515C9.606 13.9913 9.65686 13.8641 9.76738 13.7481L9.14462 13.155C8.88181 13.4309 8.746 13.7705 8.746 14.1515H9.606ZM9.76006 13.7556C9.87759 13.6381 10.0024 13.5875 10.156 13.5875V12.7275C9.76825 12.7275 9.42641 12.873 9.15194 13.1475L9.76006 13.7556ZM10.156 13.5875C10.309 13.5875 10.4251 13.6373 10.5306 13.7481L11.1534 13.155C10.8856 12.8738 10.5443 12.7275 10.156 12.7275V13.5875ZM10.5379 13.7556C10.6555 13.8731 10.706 13.9979 10.706 14.1515H11.566C11.566 13.7638 11.4205 13.4219 11.1461 13.1475L10.5379 13.7556ZM10.706 14.1515C10.706 14.3051 10.6555 14.4299 10.5379 14.5475L11.1461 15.1556C11.4205 14.8811 11.566 14.5393 11.566 14.1515H10.706ZM10.842 11.4395H9.484V12.2995H10.842V11.4395ZM9.91354 11.8496L9.60554 5.19964L8.74646 5.23942L9.05446 11.8894L9.91354 11.8496ZM9.176 5.64953H11.164V4.78953H9.176V5.64953ZM10.7345 5.19873L10.4125 11.8487L11.2715 11.8903L11.5935 5.24033L10.7345 5.19873Z"
        fill={color || theme.palette.danger}
      />
    </svg>
  );
};

import styled from '@emotion/styled';
import {format, parseISO} from 'date-fns';
import {Headline} from 'library/components/headline';
import {LabeledComponent} from 'library/components/labeled-component';
import {
  RowBody,
  RowBodyData,
  RowHead,
  RowHeadData,
  TableBoilerplate,
} from 'library/components/table';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {isEmpty} from 'ramda';
import {FC} from 'react';
import {
  getGuestDetailInformation,
  getHeaderLabeledTranslate,
  getInformationAboutCheckDate,
  getMainGuestInformation,
  parseBookingObject,
} from 'slices/booking/lib/helpers';
import {Link} from 'source/components/link';
import {getViewDayWithName} from 'source/utilities/dates';
import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  border-top: 1px solid ${({theme}) => theme.palette.border};
  margin-top: 21px;
`;

const Wrapper = styled.div`
  margin: 20px 0px 60px 0px;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 10px;
`;

const DateWrapper = styled.div`
  display: flex;
`;

const GuestInformationWrapper = styled.div``;

const GuestMain = styled(Text)`
  margin-bottom: 5px;
`;

const GuestHotelInformation = styled(Text)`
  margin-bottom: 20px;
`;

const CheckInOutInformation = styled(Text)`
  margin-bottom: 20px;
`;

const Guest = styled(Text)`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
`;

const MainGuestNumber = styled(Link)`
  font-size: 14px;
  margin-left: 5px;
`;

interface Props {
  booking: Booking;
}

const CardInformation: FC<Props> = ({booking}) => {
  const {t} = useTranslation('booking');
  const mobile = useDeviceDetection('mobile');
  const {
    currency,
    prices,
    roomName,
    vat,
    guests,
    checkin,
    checkout,
    rateName,
    ourBooking,
  } = parseBookingObject(booking);

  return (
    <Wrapper>
      <StyledHeadline level={mobile ? 'S' : 'M'}>
        {t('card_information.headline', {
          roomName,
        })}
      </StyledHeadline>
      {!isEmpty(guests) && (
        <GuestInformationWrapper>
          <GuestMain size={mobile ? 'S' : 'M'}>
            {getMainGuestInformation(guests)}
          </GuestMain>
          <GuestHotelInformation size={mobile ? 'XS' : 'S'}>
            {booking.hotel?.name || ''}
          </GuestHotelInformation>
          <CheckInOutInformation size={mobile ? 'XS' : 'S'}>
            {getInformationAboutCheckDate(checkin, checkout, t)}
          </CheckInOutInformation>
          <LabeledComponent
            color={theme.palette.fontSecondary}
            text={t('card_information.guests', {count: guests.length})}
            gap={5}
            size="S"
          >
            {guests.map((guest, index) => (
              <Guest
                key={`${guest.last_name}/${guest.main_id}`}
                size={mobile ? 'M' : 'XS'}
              >
                <Text size={mobile ? 'M' : 'XS'}>
                  {getGuestDetailInformation(index + 1, guest)}
                </Text>
                {index === 0 && guest.phone && (
                  <MainGuestNumber href={`tel:${guest.phone}`}>
                    {`- ${guest.phone}`}
                  </MainGuestNumber>
                )}
              </Guest>
            ))}
          </LabeledComponent>
        </GuestInformationWrapper>
      )}
      <TableWrapper>
        <TableBoilerplate
          rowHeadChildren={
            prices && (
              <RowHead>
                <RowHeadData>
                  {t('card_information.table_head.date')}
                </RowHeadData>
                <RowHeadData>
                  {t('card_information.table_head.rate')}
                </RowHeadData>
                <RowHeadData>
                  {t('card_information.table_head.price', {currency})}
                </RowHeadData>
                <RowHeadData>
                  {t('card_information.table_head.commission_amount', {
                    currency,
                  })}
                </RowHeadData>
                <RowHeadData isLast>
                  {t('card_information.table_head.vat', {currency})}
                </RowHeadData>
              </RowHead>
            )
          }
          rowBodyChildren={
            <>
              {prices &&
                prices.map((price) => (
                  <RowBody
                    key={`${price.date}/${price.price}/${price.commission_amount}`}
                  >
                    <RowBodyData>
                      {price.date ? (
                        <DateWrapper>
                          <Text size="boldS">{`${getViewDayWithName(
                            price.date,
                          )}`}</Text>
                          <Text size="S" $opacity="0.8">
                            {`, ${format(
                              parseISO(price.date),
                              'LLLL',
                            )} ${parseISO(price.date).getFullYear()}`}
                          </Text>
                        </DateWrapper>
                      ) : (
                        getHeaderLabeledTranslate(t, 'unknown')
                      )}
                    </RowBodyData>
                    <RowBodyData>{rateName}</RowBodyData>
                    <RowBodyData>{price.price}</RowBodyData>
                    <RowBodyData>{price.commission_amount}</RowBodyData>
                    <RowBodyData />
                  </RowBody>
                ))}
              {prices ? (
                <RowBody>
                  <RowBodyData>
                    <strong>{t('card_information.table_body.total')}</strong>
                  </RowBodyData>
                  <RowBodyData />
                  <RowBodyData>
                    <strong>{ourBooking?.amount || ''}</strong>
                  </RowBodyData>
                  <RowBodyData>
                    <strong>{ourBooking?.commission_amount || ''}</strong>
                  </RowBodyData>
                  <RowBodyData isLast>
                    <strong>{vat ?? 0}</strong>
                  </RowBodyData>
                </RowBody>
              ) : null}
            </>
          }
        />
      </TableWrapper>
    </Wrapper>
  );
};

export default CardInformation;

import {useRouter} from 'next/router';
import {equals} from 'ramda';
import {createContext, FC, useContext, useEffect, useState} from 'react';
import {isClient, isServer} from 'source/utilities/guards/environment';
import {CookieNames, getCookie} from 'source/utilities/network/cookies';
import {isErrorPage, isNotRedirectPage} from 'source/utilities/url';
import {LoadingContext} from './loading';

export type UserState = NullableState<User>;

export const UserContext = createContext<UserState>([null, () => {}]);

export const UserProvider: FC<ProviderProperties<UserState>> = ({
  serverValue,
  ...properties
}) => {
  const state = useState<ContextValue<UserState>>(serverValue);
  const [user, setUser] = state;
  const [, setLoading] = useContext(LoadingContext);
  const authorizationCookie = isServer()
    ? ''
    : getCookie(CookieNames.HOTELIER_AUTHORIZATION);

  const router = useRouter();

  useEffect(() => {
    if (!isErrorPage(router.pathname) && !isNotRedirectPage(router.pathname)) {
      if (authorizationCookie && !state[0]) {
        setLoading(true);
        router.reload();
      }
      if (isClient() && !authorizationCookie && user) {
        setLoading(true);
        router.reload();
      }
    }
  }, [user, serverValue, authorizationCookie]);

  useEffect(() => {
    if (
      !isErrorPage(router.pathname) &&
      !isNotRedirectPage(router.pathname) &&
      !equals(user, serverValue)
    ) {
      setUser(serverValue);
    }
  }, [serverValue]);

  return <UserContext.Provider value={state} {...properties} />;
};

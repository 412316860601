import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsRatesCreateResponse,
  HotelsRatesDeleteResponse,
  HotelsRatesDisablePartialUpdateResponse,
  HotelsRatesEnablePartialUpdateResponse,
  HotelsRatesUpdateRequest,
  HotelsRatesUpdateResponse,
} from 'types/api-scheme';

type GetRateContext = {
  hotelId: number;
  rateId: number;
  headers: Headers;
};
const getRate = ({hotelId, rateId, headers}: GetRateContext) =>
  request<HotelsRatesCreateResponse>(url(`hotels/${hotelId}/rates/${rateId}`), {
    method: 'GET',
    headers,
  }).then(path<Rate>([1, 'data']));

type UpdateRateContext = {
  hotelId: number;
  id: number;
  payload: HotelsRatesUpdateRequest;
};
const updateRate = ({hotelId, id, payload}: UpdateRateContext) =>
  request<HotelsRatesUpdateResponse>(url(`hotels/${hotelId}/rates/${id}`), {
    method: 'PUT',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<HotelsRatesUpdateResponse['data']>([1, 'data']));

type EnableRateContext = {
  hotelId: number;
  rateId: number;
};
const enableRate = ({hotelId, rateId}: EnableRateContext) =>
  request<HotelsRatesEnablePartialUpdateResponse>(
    url(`hotels/${hotelId}/rates/${rateId}/enable`),
    {
      method: 'PATCH',
      headers: clientHeaders(),
      body: JSON.stringify({
        enabled: true,
      }),
    },
  ).then(path<HotelsRatesEnablePartialUpdateResponse['data']>([1, 'data']));

type DisableRateContext = {
  hotelId: number;
  rateId: number;
};

const disableRate = ({hotelId, rateId}: DisableRateContext) =>
  request<HotelsRatesDisablePartialUpdateResponse>(
    url(`hotels/${hotelId}/rates/${rateId}/disable`),
    {
      method: 'PATCH',
      headers: clientHeaders(),
      body: JSON.stringify({
        enabled: false,
      }),
    },
  ).then(path<HotelsRatesDisablePartialUpdateResponse['data']>([1, 'data']));

type DeleteRateContext = {
  hotelId: number;
  rateId: number;
};

const deleteRate = ({hotelId, rateId}: DeleteRateContext) =>
  request<HotelsRatesDeleteResponse>(url(`hotels/${hotelId}/rates/${rateId}`), {
    method: 'DELETE',
    headers: clientHeaders(),
  }).then(path<HotelsRatesDeleteResponse['data']>([1, 'data']));

export const rateApi = {
  get: getRate,
  update: updateRate,
  enable: enableRate,
  disable: disableRate,
  delete: deleteRate,
};

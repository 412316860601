import {request} from 'library/api';
import {path} from 'ramda';
import {clientHeaders} from 'source/utilities/network/http';
import {url} from 'source/utilities/network/url';
import {
  HotelsImagesDeleteManyPartialUpdateResponse,
  HotelsImagesDeleteResponse,
  HotelsImagesDetailResponse,
  HotelsImagesUpdateOrderPartialUpdateResponse,
} from 'types/api-scheme';

type GetImagesContext = {
  hotelId: number;
  headers: Headers;
};
const getImages = ({hotelId, headers}: GetImagesContext) =>
  request<HotelsImagesDetailResponse>(url(`hotels/${hotelId}/images`), {
    method: 'GET',
    headers,
  }).then(path<HotelImages>([1, 'data']));

type DeleteImagesContext = {
  hotelId: number;
  payload: {
    image_ids: number[];
  };
};
const deleteImages = ({hotelId, payload}: DeleteImagesContext) =>
  request<HotelsImagesDeleteManyPartialUpdateResponse>(
    url(`hotels/${hotelId}/images/delete-many`),
    {
      method: 'PATCH',
      headers: clientHeaders(),
      body: JSON.stringify(payload),
    }
  );

type ReorderImagesContext = {
  hotelId: number;
  draggedImageId: number;
  payload: {
    preHotelImageId: number;
  };
};
const reorderImages = ({
  hotelId,
  draggedImageId,
  payload: {preHotelImageId},
}: ReorderImagesContext) =>
  request<HotelsImagesUpdateOrderPartialUpdateResponse>(
    url(`hotels/${hotelId}/images/${draggedImageId}/update-order`),
    {
      method: 'PATCH',
      headers: clientHeaders(),
      body: JSON.stringify({
        pre_hotel_image_id: preHotelImageId || null,
      }),
    }
  );

type DeleteImageContext = {
  hotelId: number;
  imageId: number;
};
const deleteImage = ({hotelId, imageId}: DeleteImageContext) =>
  request<HotelsImagesDeleteResponse>(
    url(`hotels/${hotelId}/images/${imageId}`),
    {
      method: 'DELETE',
      headers: clientHeaders(),
    }
  );

export const hotelImagesApi = {
  get: getImages,
  deleteMany: deleteImages,
  reorder: reorderImages,
  delete: deleteImage,
};

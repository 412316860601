import styled from '@emotion/styled';
import {useContext, useEffect} from 'react';
import {HotelContext} from 'source/context/hotel';
import {UtilitiesContext} from 'source/context/utilities';
import {useAppDispatch, useAppSelector} from 'source/store';
import {SHOW_INVOICE_ROLES, personFields} from '../lib/constants';
import {getOrganizationInvoice} from '../network';
import {selectInvoiceInfo, setFile, setLoading} from '../store';
import {InvoiceCard} from './invoice-card';
import {QrRequisitePayment} from './qr-requisite-payment';

const MobilePaymentWrapper = styled.div`
  margin-bottom: 12px;
`;

export const Invoice = () => {
  const [hotel] = useContext(HotelContext);
  const [utilities] = useContext(UtilitiesContext);
  const {isLoading, file, requisitesExists, requisites, legalForm} =
    useAppSelector(selectInvoiceInfo);

  const dispatch = useAppDispatch();
  const shouldShowInvoice = SHOW_INVOICE_ROLES.includes(legalForm);

  const showNPSKPayment = !hotel?.verified && personFields.includes(legalForm);

  useEffect(() => {
    if (hotel && hotel.id && !file) {
      dispatch(setLoading(true));
      getOrganizationInvoice(hotel.id)
        .then((blob) => dispatch(setFile(blob)))
        .finally(() => dispatch(setLoading(false)));
    }
  }, [hotel?.id, file]);

  if (hotel?.verified || !requisitesExists) {
    return null;
  }

  if (utilities.windowWidth <= 900) {
    return (
      <MobilePaymentWrapper>
        {shouldShowInvoice && (
          <InvoiceCard
            isLoading={isLoading}
            file={file}
            name={requisites?.name}
          />
        )}

        {showNPSKPayment && <QrRequisitePayment />}
      </MobilePaymentWrapper>
    );
  }

  return (
    <div>
      {!hotel?.verified && requisitesExists && shouldShowInvoice ? (
        <InvoiceCard isLoading={isLoading} file={file} name={requisites.name} />
      ) : null}
      {showNPSKPayment && <QrRequisitePayment />}
    </div>
  );
};

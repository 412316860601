import {request} from 'library/api';
import {path} from 'ramda';
import {url} from 'source/utilities/network/url';
import {BookingsDetailResponse} from 'types/api-scheme';

type GetBookingContext = {
  bookingId: number;
  hotelId: number;
  headers?: Headers;
};

const getBooking = ({bookingId, hotelId, headers}: GetBookingContext) =>
  request<BookingsDetailResponse>(
    url(`bookings/${bookingId}`, {hotel_id: String(hotelId)}),
    {
      method: 'GET',

      headers,
    },
  ).then(path<Booking>([1, 'data']));

export const bookingApi = {
  get: getBooking,
};

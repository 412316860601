import type {GetServerSideProps} from 'next';
import {andThen, assoc, call, pipeWith} from 'ramda';
import {api} from 'source/utilities/api';

import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';
import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import type {ApplicationProperties} from 'source/utilities/ui';
import {UserMePartialUpdateRequest} from 'types/api-scheme';

export const getProfilePageProperties: GetServerSideProps<
  ApplicationProperties
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestNotificationChannels = () =>
    api.socialSubscribe.getNotificationChannels({headers});

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          const [notificationChannels] = await Promise.all([
            requestNotificationChannels(),
          ]);

          const result = removeUndefined({
            ...properties,
            notificationChannels,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const changePassword = async (
  payload: UserMePartialUpdateRequest,
  onComplete: () => void,
  onError: (message: string) => void,
  setLoading: LoadingDispatch,
) =>
  api.user
    .edit({
      payload,
    })
    .then(onComplete)
    .catch((error) => onError(error.message))
    .finally(() => setLoading(false));

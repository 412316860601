import styled from '@emotion/styled';
import {stylable} from 'library/utilities/stylable';

const component = styled.div`
  max-width: ${({theme}) => theme.content.bound};
  min-height: 100%;
  align-self: center;
  display: flex;
`;

export const Content = stylable(component);

import {useTheme} from '@emotion/react';
import {Dispatch, FC, SetStateAction, memo} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {LabeledComponent} from 'library/components/labeled-component';
import {Option, Select} from 'library/components/select';

import {useAppDispatch} from 'source/store';
import {UNDEFINED_CATEGORY} from '../../lib/constants';
import {setCategory} from '../../store';
import {
  getCategoriesOptions,
  getCategoryOption,
  getLabelTranslate,
  getPossibleRoomTypes,
  getPossibleSubCategories,
} from '../../lib/helpers';
import {ClientRoomCategory, RoomCategories} from '../../lib/types';

interface IProps {
  translateKey: string;
  roomCategory: ClientRoomCategory;
  roomCategories?: RoomCategories;
  setRoomCategory: Dispatch<SetStateAction<ClientRoomCategory>>;
  disabled?: boolean;
}

export const CategorySelect: FC<IProps> = ({
  roomCategory,
  translateKey,
  roomCategories,
  setRoomCategory,
  disabled = false,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {t} = useTranslation(translateKey);
  const categoryOptions = getCategoriesOptions(roomCategories);

  const handleSelectSubCategory = (option: Option) => {
    const newRoomType = getPossibleRoomTypes(option.value, roomCategories)?.[0]
      ?.id;

    const newSubCategory =
      getPossibleSubCategories(option.value, newRoomType, roomCategories)?.[0]
        ?.id || UNDEFINED_CATEGORY;
    if (newSubCategory === UNDEFINED_CATEGORY) {
      dispatch(setCategory(newRoomType));
    } else {
      dispatch(setCategory(newSubCategory));
    }
    setRoomCategory({
      category: option.value,
      roomType: newRoomType,
      subCategory: newSubCategory,
    });
  };

  return (
    <LabeledComponent
      color={theme.palette.fontSecondary}
      size="S"
      gap={10}
      text={getLabelTranslate(t, 'category')}
    >
      <Select
        value={getCategoryOption(roomCategory.category, roomCategories)}
        options={categoryOptions}
        setValue={handleSelectSubCategory}
        disabled={disabled}
      />
    </LabeledComponent>
  );
};

import {ApplicationProperties} from 'source/utilities/ui';
import {BookingsListParameters, BookingsListResponse} from 'types/api-scheme';

export interface BookingsPageProperties extends ApplicationProperties {
  bookings?: BookingsListResponse;
  bookingPaymentMethods?: BookingPaymentMethods;
  bookingHotelierStatuses?: BookingHotelierStatuses;
  paymentVerification?: PaymentVerification;
  bookingSorts?: BookingSorts;
  bookingStatuses?: BookingStatuses;
}

export interface BookingsStore {
  bookings: BookingsListResponse | null;
  filters: BookingsListParameters & {
    hotel_name?: string | null;
  };
  bookingPaymentMethods?: BookingPaymentMethods;
  bookingHotelierStatuses?: BookingHotelierStatuses;
  paymentVerification?: PaymentVerification;
  bookingSorts?: BookingSorts;
  bookingStatuses?: BookingStatuses;
}

export enum BOOKING_STATUSES {
  BOOKED = 5,
  CANCELLED = 6,
  COMPLETED = 7,
}

export type CalendarDates = {from: string; to: string};

import styled from '@emotion/styled';
import {useTheme, css} from '@emotion/react';
import {memo} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {useAppDispatch, useAppSelector} from 'source/store';

import {Question} from 'library/components/hint/question';
import {Tooltip} from 'library/components/tooltip';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import {ExclamationMarkIcon} from 'library/icons/exclamation-mark';

import {
  selectIsShowDisabledRates,
  setIsShowDisabledRates,
  setIsLoading,
  selectIsLoading,
} from 'slices/calendar/store';

import {FilterButton} from '../filter-button';
import MobileCalendarTooltip from '../mobile-calendar-tooltip';

const FirstDataCell = styled.div`
  min-width: 90px;

  @media (max-width: 480px) {
    min-width: unset;
  }
`;

const StyledFilterButton = styled(FilterButton)<{isShowDisabledRates: boolean}>`
  ${({theme, isShowDisabledRates}) =>
    isShowDisabledRates &&
    css`
      background-color: ${theme.palette.fontPrimary};
    `}
`;

const QuestionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.palette.fontAccent};
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 5px;
  height: 44px;
`;

const StyledText = styled(Text)`
  white-space: break-spaces;

  @media (max-width: 480px) {
    max-width: 175px;
  }
`;

const DisabledRatesSwitcherComponent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isShowDisabledRates = useAppSelector(selectIsShowDisabledRates);
  const {t} = useTranslation('calendar');
  const mobile = useDeviceDetection('mobile');
  const isLoading = useAppSelector(selectIsLoading);

  const handleSwitchDisabledRates = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsShowDisabledRates(!isShowDisabledRates));
  };

  return (
    <>
      {!mobile && (
        <FirstDataCell>
          <StyledFilterButton
            disabled={isLoading}
            onClick={handleSwitchDisabledRates}
            isShowDisabledRates={isShowDisabledRates}
          >
            <ExclamationMarkIcon
              color={
                isShowDisabledRates
                  ? theme.palette.fontAccent
                  : theme.palette.fontPrimary
              }
            />
          </StyledFilterButton>
        </FirstDataCell>
      )}
      <Wrapper>
        {mobile ? (
          <MobileCalendarTooltip
            trigger={
              <QuestionWrapper>
                <Question />
              </QuestionWrapper>
            }
          >
            <Text size="XS" align="start">
              {t('room_range.tooltip.show_disabled_rates_explanation')}
            </Text>
          </MobileCalendarTooltip>
        ) : (
          <Tooltip
            on="hover"
            position="right center"
            trigger={
              <QuestionWrapper>
                <Question />
              </QuestionWrapper>
            }
            minWidth={250}
          >
            <Text size="XS" align="start">
              {t('room_range.tooltip.show_disabled_rates_explanation')}
            </Text>
          </Tooltip>
        )}

        <StyledText size="XS" align="start">
          {t('room_range.show_disabled_rates')}
        </StyledText>
        <Switch
          disabled={isLoading}
          initialState={isShowDisabledRates}
          onSwitch={async () => handleSwitchDisabledRates()}
          isActive={isShowDisabledRates}
        />
      </Wrapper>
    </>
  );
};

export const DisabledRatesSwitcher = memo(DisabledRatesSwitcherComponent);

import {combineReducers} from '@reduxjs/toolkit';
import {roomSlice} from 'slices/add-room';
import {bookingSlice} from 'slices/booking';
import {bookingsSlice} from 'slices/bookings';
import {calendarSlice} from 'slices/calendar';
import {createHotelierObjectSlice} from 'slices/create_hotelier_object';
import {facilitiesSlice} from 'slices/facilities';
import {generalInfoSlice} from 'slices/general_info';
import {integrationSlice} from 'slices/integration';
import {landmarksSlice} from 'slices/landmarks';
import {photosSlice} from 'slices/photos';
import {policiesSlice} from 'slices/policies';
import {profileSlice} from 'slices/profile';
import {rateSlice} from 'slices/rate';
import {ratesSlice} from 'slices/rates';
import {requisitesSlice} from 'slices/requisites';
import {roomPhotosSlice} from 'slices/room-photos';
import {roomsSlice} from 'slices/rooms';
import {bitrixSlice} from 'slices/bitrix';
import {balanceSlice} from 'slices/balance';
import {balanceDetailsSlice} from 'slices/balance_details';
import {commonSlice} from 'slices/common';

export const rootReducer = combineReducers({
  calendar: calendarSlice,
  room: roomSlice,
  rooms: roomsSlice,
  roomPhotos: roomPhotosSlice,
  rate: rateSlice,
  rates: ratesSlice,
  requisites: requisitesSlice,
  photos: photosSlice,
  policies: policiesSlice,
  generalInfo: generalInfoSlice,
  facilities: facilitiesSlice,
  landmarks: landmarksSlice,
  bookings: bookingsSlice,
  booking: bookingSlice,
  createHotelierObject: createHotelierObjectSlice,
  integration: integrationSlice,
  profile: profileSlice,
  bitrix: bitrixSlice,
  balance: balanceSlice,
  balanceDetails: balanceDetailsSlice,
  common: commonSlice,
});

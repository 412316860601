import styled from '@emotion/styled';
import {useAppDispatch, useAppSelector} from 'source/store';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';
import useTranslation from 'next-translate/useTranslation';
import {useContext} from 'react';
import {HotelContext} from 'source/context/hotel';
import {selectStateLandmarks, onSwitchLandmark} from 'slices/landmarks/store';
import {formatDistance} from './lib/helpers';
import {LandmarksStateGroup, LandmarksStateLandmark} from './lib/types';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const HeaderSubtitle = styled(Text)`
  width: fit-content;
  padding: 20px;
  margin-bottom: 48px;
  letter-spacing: 0.03em;
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(220, 220, 220, 0.4);
`;

const Landmark = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 15px 10px 15px 10px;
  transition: 0.3s background-color ease-in-out;
  border-bottom: 1px solid ${({theme}) => theme.palette.activeBackground};
  &:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.palette.hoverBackground_08};
    border-radius: 3px;
  }
`;

const LandmarkHeader = styled(Text)`
  margin: 15px 0px 15px 0px;
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const StyledText = styled(Text)`
  cursor: pointer;
`;

const Content = () => {
  const {t} = useTranslation('landmarks');
  const dispatch = useAppDispatch();
  const [hotel] = useContext(HotelContext);
  const stateLandmarks = useAppSelector(selectStateLandmarks);

  const onSwitchComplete = (landmark: LandmarksStateLandmark) => {
    dispatch(onSwitchLandmark(landmark));
  };

  const onLandmarkSwitch = async (landmark: LandmarksStateLandmark) => {
    if (hotel?.id) {
      onSwitchComplete(landmark);
    }
  };

  return (
    <Wrapper>
      <HeaderSubtitle size="S">{t('subtitle')}</HeaderSubtitle>
      {stateLandmarks && stateLandmarks?.length > 0
        ? stateLandmarks.map((group: LandmarksStateGroup) => (
            <div key={group.id}>
              <LandmarkHeader size="M">{group.name}</LandmarkHeader>
              {group?.landmarks?.map((landmark: LandmarksStateLandmark) => (
                <div key={landmark.id}>
                  <Landmark>
                    <Text size="S">{landmark.name}</Text>
                    <SwitchWrapper>
                      <Tooltip
                        on="hover"
                        position="top right"
                        trigger={
                          <StyledText size="XS">
                            {formatDistance(landmark.distance, t)}
                          </StyledText>
                        }
                      >
                        <Text size="XS">{landmark.address}</Text>
                      </Tooltip>
                      <Switch
                        initialState={landmark.enabled}
                        isActive={!!landmark.enabled}
                        onSwitch={() => onLandmarkSwitch(landmark)}
                      />
                    </SwitchWrapper>
                  </Landmark>
                </div>
              ))}
            </div>
          ))
        : null}
    </Wrapper>
  );
};

export default Content;
